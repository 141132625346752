import gql from 'graphql-tag';

import { RepairCostFragment } from './fragments';

export default gql`
  mutation updateRepairCost(
    $id: UUID!
    $cost: ServiceCostInput!
    $isDefault: Boolean
    $active: Boolean
  ) {
    updateRepairCost(
      id: $id
      cost: $cost
      isDefault: $isDefault
      active: $active
    ) {
      ...RepairCostFragment
    }
  }
  ${RepairCostFragment}
`;
