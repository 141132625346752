export type FormType = {
  vinOrStockNumber: string | null;
  refId: string | null;
  serviceCode: string | null;
  messageType: string | null;
  createdDate: [Date | null, Date | null];
  status: string | null;
  tenant: string | null;
  errorCode: string | null;
  errorText: string | null;
  direction: string | null;
  reviewed: boolean | null;
};

export const enum FilterNames {
  VIN_OR_STOCK_NUMBER = 'vs',
  REF_ID = 'ri',
  SERVICE_CODE = 'sc',
  MESSAGE_TYPE = 'mt',
  CREATED_DATE = 'cd',
  STATUS = 'st',
  TENANT = 'tn',
  ERROR_CODE = 'ec',
  ERROR_TEXT = 'et',
  DIRECTION = 'dt',
  REVIEWED = 'rw',
}
