import cns from 'classnames';

import { BeautyShotTranslation } from 'graphqlTypes';

import cn from '../VehicleHeader.module.scss';

type Props = {
  beautyShot?: BeautyShotTranslation;
  className?: string;
};

const BeautyShot = ({ beautyShot, className }: Props) => {
  if (!beautyShot) return null;

  return (
    <div
      className={cns(className, cn.beautyShot, 'beautyShot')}
      data-qa-selector="entryCheckBeautyShot"
    >
      {__('qualityCheck.beautyShots')} {beautyShot.translation}
    </div>
  );
};

export default BeautyShot;
