import { initGraphQLTracker } from './graphqlTracker';
import { initUITracker } from './uiTracker';

type User = {
  user?: {
    email: string;
  };
};

type ConfigArgs = {
  apiOrigin: string;
  useGetUser(): User;
};
class Config {
  readonly apiOrigin;

  readonly useGetUser;

  constructor({ apiOrigin, useGetUser }: ConfigArgs) {
    this.apiOrigin = apiOrigin;
    this.useGetUser = useGetUser;
  }
}

let loggerConfig: ConfigArgs;

export const getLoggerConfig = () => {
  if (!loggerConfig) {
    throw new Error(
      "Logger config has not been initialized. Call 'loggerInit' before.",
    );
  }
  return loggerConfig;
};

export const loggerInit = (config: ConfigArgs) => {
  loggerConfig = loggerConfig || new Config(config);
  initUITracker();
  initGraphQLTracker();
};
