import { useQuery } from '@apollo/react-hooks';

import { FilterType } from 'helpers/listFilterPersistor/types';
import { GET_FILTERS } from './gql/getListFilters';

export const useGetFilterPersistor = () => {
  const { data } = useQuery(GET_FILTERS);

  return (filtersFor: FilterType) => data.currentFilter[filtersFor];
};
