import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

type FormType = {
  comment: string;
};

type Props = {
  comment: string;
  qaSelector: string;
  onChange: (comment: string) => void;
  onCancel: () => void;
};

const CommentEditInlineForm = ({
  comment,
  qaSelector,
  onChange,
  onCancel,
}: Props) => {
  const { register, reset, handleSubmit } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: { comment },
  });

  const onCancelClick = () => {
    reset();
    onCancel();
  };

  const onSubmit = (formValue: FormType) => {
    onChange(formValue.comment);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Control
        {...register('comment')}
        as="textarea"
        data-qa-selector={`${qaSelector}-input`}
      />
      <div className="mt-3 d-flex justify-content-end">
        <Button
          variant="link"
          className="pl-0"
          data-qa-selector={`${qaSelector}-cancel`}
          onClick={onCancelClick}
        >
          {__('action.cancel')}
        </Button>
        <Button data-qa-selector={`${qaSelector}-submit`} type="submit">
          {__('action.save')}
        </Button>
      </div>
    </Form>
  );
};

export default CommentEditInlineForm;
