import cns from 'classnames';

import Filter from 'components/Filter';
import cn from 'components/Filter/Filter.module.scss';

const OverviewFilter = () => {
  return (
    <Filter>
      <div className={cn.row}>
        <div className={cn.inputsContainer}>
          <Filter.EmailInput />
          <Filter.NameInput />
        </div>
        <Filter.Button className={cns(cn.col, cn.button)} />
      </div>
    </Filter>
  );
};

export default OverviewFilter;
