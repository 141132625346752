import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import Divider from 'components/Divider';
import { FormFilterNames } from 'components/Filter/constants';
import Filter from 'components/Filter/Filter';
import cn from 'components/Filter/Filter.module.scss';
import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import TableInfoPagination from 'components/TableInfoPagination';
import TableInfo from 'components/TableInfoPagination/TableInfo';
import { states } from 'constants/state';
import TaskList from './components/TaskList';
import useTaskListSearchParams from './hooks/useTaskListSearchParams';

const BadgeArea = () => {
  const { watch } = useFormContext();
  if (!watch(FormFilterNames.STATE)?.length) return null;

  return (
    <div className={cns(cn.col, cn.button)}>
      <div className={cn.badgesContainer}>
        <Divider className="mt-3" />
        <Filter.StateBadges />
        <Divider className="mb-3" />
      </div>
    </div>
  );
};

const validStates: string[] = [
  states.REFURBISHMENT_AUTHORIZED,
  states.REFURBISHMENT_STARTED,
  states.REFURBISHMENT_COMPLETED,
];

const filterOptions = (
  options: {
    label: string;
    value: string;
  }[],
) => options.filter(({ value }) => validStates.includes(value));

const TaskListRoute = () => {
  const { data, loading, refetch, setFilterValues } = useTaskListSearchParams();

  const pageInfo = data?.pageInfo;

  return (
    <PageContainer>
      <PageHeader
        title={__('refurbishment.task.list.title')}
        className="justify-content-start align-items-baseline text-primary"
        actions={
          <TableInfo
            results={data?.pageInfo.totalEntityCount || ''}
            className="ml-3 text-body"
          />
        }
      />

      <Filter onChange={setFilterValues}>
        <div className={cn.row}>
          <div className={cn.inputsContainer}>
            <Filter.RefVinStockInput />
            <Filter.StateSelect
              transformOptions={filterOptions}
              hideSelectedOptionsInput
            />
          </div>

          <Filter.Button className={cns(cn.col, cn.button)} />
        </div>

        <BadgeArea />
      </Filter>

      <TaskList data={data} loading={loading} refetch={refetch} />

      {!!pageInfo && (
        <TableInfoPagination
          totalPageCount={pageInfo.totalPageCount}
          page={pageInfo.page}
        />
      )}
    </PageContainer>
  );
};

export default TaskListRoute;
