import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryEntryCheckArgs } from 'graphqlTypes';
import ENTRY_CHECK from './gql/entryCheck';

const useGetEntryCheck = (refurbishmentId: string = '') => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'entryCheck'>,
    QueryEntryCheckArgs
  >(ENTRY_CHECK, {
    variables: { refurbishmentId },
    fetchPolicy: 'cache-and-network',
    skip: !refurbishmentId,
  });

  return {
    data: get(['entryCheck'], data),
    ...rest,
  };
};

export default useGetEntryCheck;
