import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';

import ApolloProviderClient from 'components/ApolloProviderClient';
import ErrorFallback from 'components/ErrorFallback';
import Header from 'components/Layout/Header';
import { Notifications } from 'components/Notifications';
import ImageStorageProvider from 'modules/adImage/components/ImageStorageProvider';
import { AuthProvider } from 'modules/auth';
import useAuthorizedUser from 'modules/auth/useAuthorizedUser';
import { DeveloperModeProvider } from 'modules/developerMode';
import { DynamicTranslateProvider, RouteLanguage } from 'modules/i18n';
import { loggerInit } from 'modules/logger';
import { NotificationProvider } from 'modules/notification';
import { RootRoute } from './routes';

import './styles/index.scss';

import './setupUrls';
import './setupFileUploadUrls';
import './setupPasswordUrls';

const apiOrigin =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '';

const apiRoute = '/api';

loggerInit({ apiOrigin, useGetUser: useAuthorizedUser });

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Helmet>
        <title>Refurbishment</title>
      </Helmet>
      <AuthProvider apiOrigin={apiOrigin} apiRoute={apiRoute}>
        <NotificationProvider
          renderNotifications={(notifications, { expire, remove }) => (
            <Notifications
              list={notifications}
              onClose={expire}
              onRemove={remove}
            />
          )}
        >
          <ApolloProviderClient>
            <Router>
              <RouteLanguage>
                <DeveloperModeProvider>
                  <DynamicTranslateProvider>
                    <>
                      <Header />
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <ImageStorageProvider>
                          <RootRoute />
                        </ImageStorageProvider>
                      </ErrorBoundary>
                    </>
                  </DynamicTranslateProvider>
                </DeveloperModeProvider>
              </RouteLanguage>
            </Router>
          </ApolloProviderClient>
        </NotificationProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(<App />);
