import { isNaN, keys, pickBy } from 'lodash/fp';

export default pickBy(value => {
  if (typeof value === 'object') {
    return keys(value || {}).length > 0;
  }

  if (typeof value === 'number') {
    return !isNaN(value);
  }

  return value;
});
