import gql from 'graphql-tag';

export default gql`
  mutation completeEntryCheck($id: UUID!) {
    completeEntryCheck(id: $id) {
      id
      state
      refurbishment {
        id
        state
      }
    }
  }
`;
