import React from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import Divider from 'components/Divider';
import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import { ACL, Role } from 'modules/acl';
import {
  useAssignGroups,
  useAssignUserPartnerWorkshopConnectionPermissions,
  useGetUser,
} from 'modules/api/users';
import useUpdateUser from 'modules/api/users/useUpdateUser';
import { ErrorType, useHandleError } from 'modules/errorHandler';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { getUrl } from 'modules/routeUtils';
import Groups from '../components/Groups';
import Info from '../components/Info';
import ProductionLocations from '../components/ProductionLocations';
import {
  detailUserToForm,
  formToUserUpdateInfo,
  userToDetail,
} from '../mapData';
import { UserUpdateInfo } from '../types';

const DetailRoute = () => {
  const { error: errorNotification } = useNotification();
  const { logError } = useLogger();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetUser(id);
  const [updateUser] = useUpdateUser();
  const [
    assignPartnerWorkshopConnections,
    { loading: partnerWorkshopConnectionsLoading },
  ] = useAssignUserPartnerWorkshopConnectionPermissions();
  const [assignGroups] = useAssignGroups();
  const setError = useHandleError();
  const { success } = useNotification();

  if (loading) return <LoadingIndicator />;

  if (error) {
    setError(ErrorType.UnexpectedError);
    return null;
  }

  if (!id || !data) return null;

  const userData = userToDetail(data);
  const partnerWorkshopConnections =
    userData.userPartnerWorkshopConnections?.filter(
      (group): group is UserPartnerWorkshopConnection => !!group,
    ) || [];

  const handleOnInfoSubmit = async (info: UserUpdateInfo) => {
    try {
      await updateUser({
        id,
        input: info,
      });
      success(__('user.edit.successMessage'));
    } catch (e) {
      logError({ error });
    }
  };

  const handleOnGroupsChange = (groupIds: string[]) => {
    if (!groupIds.length) {
      errorNotification(__('user.new.missingGroups'));
      return;
    }
    assignGroups({ userId: id, groupIds });
  };

  const handleOnPartnerWorkshopConnectionsChange = (
    input: UserPartnerWorkshopConnectionInput,
  ) => {
    return assignPartnerWorkshopConnections({
      userId: id,
      input,
    });
  };

  return (
    <PageContainer>
      <Helmet>
        <title>{data.email}</title>
      </Helmet>
      <PageHeader
        title={userData.email}
        data-qa-selector="username_header"
        actions={
          <ACL roles={Role.USERS_ADMIN}>
            <Button
              as={Link}
              to={getUrl('USER_DUPLICATE', { id })}
              variant="outline-primary"
              data-qa-selector="duplicate_user"
            >
              {__('user.detail.duplicateUser')}
            </Button>
          </ACL>
        }
      />
      <Divider expand={false} className="mb-4" />
      <Info
        defaultValues={detailUserToForm(userData)}
        isEdit
        submitButton={buttonProps => (
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              data-qa-selector="save_changes"
              {...buttonProps}
            >
              {__('action.save')}
            </Button>
          </div>
        )}
        onSubmit={formValues =>
          handleOnInfoSubmit(formToUserUpdateInfo(formValues))
        }
      />
      <ACL roles={Role.USERS_ADMIN}>
        <Divider expand={false} className="my-4" />
        <ProductionLocations
          partnerWorkshopConnections={partnerWorkshopConnections}
          loading={partnerWorkshopConnectionsLoading}
          onChange={handleOnPartnerWorkshopConnectionsChange}
        />
      </ACL>
      <ACL roles={Role.USERS_ADMIN}>
        <Divider expand={false} className="my-4" />
        <Groups
          initialData={userData.groupIds}
          onChange={handleOnGroupsChange}
        />
      </ACL>
    </PageContainer>
  );
};

export default DetailRoute;
