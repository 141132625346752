import React from 'react';
import { Form } from 'react-bootstrap';

import Text from 'components/UI/Text';

type Props = {
  register: any; // TODO define correct typing
};

const ActiveOnlyFilter = ({ register }: Props) => (
  <div className="d-flex align-items-center">
    <Form.Check
      {...register('activeOnly')}
      type="checkbox"
      id="activeOnly"
      custom
      data-qa-selector="activeOnlyFilter"
    />
    <Text weight="bold" className="ml-1">
      {__('repairCostAndMethod.activeOnly')}
    </Text>
  </div>
);

export default ActiveOnlyFilter;
