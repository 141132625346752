import { Form } from 'react-bootstrap';

import Badge from 'components/Badge';
import FieldError from 'components/Form/FieldError';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import {
  PartnerType,
  PartnerWorkshopConnectionRefurbishmentResponse,
} from 'graphqlTypes';

type Props = {
  partnerWorkshopConnections?:
    | PartnerWorkshopConnectionRefurbishmentResponse[]
    | null;
  name: string;
  control: any;
  errors: any;
  onChange?(value: string): void;
  disabled?: boolean;
  hideLabel?: boolean;
  className?: string;
};

const WorkshopPartnerControl = ({
  partnerWorkshopConnections,
  name,
  control,
  errors,
  onChange,
  disabled = false,
  hideLabel = false,
  className = '',
}: Props) => {
  const options =
    partnerWorkshopConnections?.map(connection => ({
      label: `${connection.repairWorkshop.name} - ${connection.repairPartner.name}`,
      value: connection.id,
    })) || [];

  const partnerTypes: Record<string, PartnerType> =
    partnerWorkshopConnections?.reduce(
      (acc, connection) => ({
        ...acc,
        [connection.id]: connection.repairPartner.type,
      }),
      {},
    ) || {};

  const partnerBadge = (
    children: React.ReactNode,
    { value }: { value: string },
  ) => {
    const label =
      partnerTypes[value] === PartnerType.External
        ? __('partner.external')
        : __('partner.internal');
    const color =
      partnerTypes[value] === PartnerType.External ? 'warning' : 'info';

    return (
      <div className="d-flex justify-content-between">
        <span>{children}</span>
        <Badge color={color}>{label}</Badge>
      </div>
    );
  };

  return (
    <div className={className}>
      <Form.Group controlId="repairWorkshopPartner">
        {!hideLabel && (
          <Form.Label>
            {__('refurbishmentDefaults.repairWorkshopPartner')}
          </Form.Label>
        )}
        <ReactSelectInput
          name={name}
          options={options}
          dataQa={`${name}-select`}
          dataQaOption={`${name}-option`}
          rules={{
            required: __('field.required'),
          }}
          onChange={value => {
            if (onChange) onChange(value);
          }}
          control={control}
          disabled={disabled}
          isSearchable={false}
          renderSingleValue={partnerBadge}
          renderOption={partnerBadge}
        />
        <FieldError name={name} errors={errors} />
      </Form.Group>
    </div>
  );
};

export default WorkshopPartnerControl;
