import React from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';

import FieldError from 'components/Form/FieldError';
import PageContainer from 'components/Layout/PageContainer';
import { useAuth } from 'modules/auth';
import { useLogger } from 'modules/logger';
import { getUrl } from 'modules/routeUtils';

interface FormData {
  username: string;
  password: string;
  global?: string;
}

const Login = () => {
  const { logError } = useLogger();
  const { info, signInByUsernameAndPassword } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLoginErrors = (e: any, passwordBasedLogin: boolean) => {
    let type = null;
    let message = null;

    if (e.result) {
      ({ error: type, error_description: message } = e.result);
    } else {
      type = 'some_error';
      message = 'some error occurred';
    }

    if (type === 'invalid_grant' && message === 'Bad credentials') {
      setError('password', {
        type: 'invalidCredentials',
        message: __('error.invalidCredentials'),
      });
    } else if (type === 'invalid_grant' && message === 'User is disabled') {
      navigate(getUrl('DISABLED_USER'));
    } else if (type === 'access_denied' && message === 'Invalid login method') {
      setError('password', {
        type: 'invalidLoginMethod',
        message: passwordBasedLogin
          ? __('error.useSSOLoginMethod')
          : __('error.usePasswordLoginMethod'),
      });
    } else {
      setError('global', { type, message });
      logError({ error: e });
    }
  };

  const onSubmit = async ({ username, password }: FormData) => {
    try {
      await signInByUsernameAndPassword({ username, password });
    } catch (e) {
      handleLoginErrors(e, true);
    }
  };

  if (info.isAuthorized) {
    const from = location.state?.from || { pathname: '/' };
    return <Navigate to={from} />;
  }

  return (
    <PageContainer>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formLogInEmail">
              <Form.Label>{__('login.emailAddress')}</Form.Label>
              <Form.Control
                type="email"
                {...register('username', {
                  required: true,
                  onChange: () => !isEmpty(errors) && clearErrors(),
                })}
              />
            </Form.Group>

            <Form.Group controlId="formLogInPassword">
              <Form.Label>{__('login.password')}</Form.Label>
              <Form.Control
                type="password"
                {...register('password', {
                  required: true,
                  onChange: () => !isEmpty(errors) && clearErrors(),
                })}
              />
              <FieldError name="password" errors={errors} />
            </Form.Group>

            {errors.global ? (
              <Alert variant="danger">
                [{errors.global.type}]: {errors.global.message}
              </Alert>
            ) : null}

            <Row>
              <Col>
                <Link
                  to={getUrl('RESET_PASSWORD')}
                  data-qa-selector="forgot_password"
                >
                  {__('login.forgotPassword')}
                </Link>
              </Col>
              <Col>
                <div className="text-right">
                  <Button variant="primary" type="submit">
                    {__('action.login')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Login;
