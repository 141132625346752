import { memoize } from 'lodash/fp';

const isBrowser = !!global.window;

export const setItem = (...args) => {
  if (isBrowser) {
    return window.localStorage.setItem(...args);
  }
};

export const getItem = (...args) => {
  if (isBrowser) {
    return window.localStorage.getItem(...args);
  }
};

export const removeItem = (...args) => {
  if (isBrowser) {
    return window.localStorage.removeItem(...args);
  }
};

const parseJSONString = memoize(string => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return undefined;
  }
});

export const getJSONItem = (key, ...args) =>
  parseJSONString(getItem(key, ...args));

export const setJSONItem = (key, value) => {
  try {
    return setItem(key, JSON.stringify(value));
  } catch (e) {
    return undefined;
  }
};
