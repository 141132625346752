import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationCreateRefurbishmentPartnerTaskEtaArgs,
  RefurbishmentPartnerTaskEtaCreateInput,
} from 'graphqlTypes';
import CREATE_REFURBISHMENT_PARTNER_TASK_ETA from './gql/createRefurbishmentPartnerTaskEta';

const useCreateRefurbishmentPartnerTaskEta = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createRefurbishmentPartnerTaskEta'>,
    MutationCreateRefurbishmentPartnerTaskEtaArgs
  >(CREATE_REFURBISHMENT_PARTNER_TASK_ETA);

  const createRefurbishmentPartnerTaskEta = (
    input: RefurbishmentPartnerTaskEtaCreateInput,
  ) =>
    mutation({
      variables: { refurbishmentPartnerTaskEtaCreateInput: { ...input } },
    });

  return [
    createRefurbishmentPartnerTaskEta,
    {
      data: data?.createRefurbishmentPartnerTaskEta,
      ...rest,
    },
  ] as const;
};

export default useCreateRefurbishmentPartnerTaskEta;
