const processRequest = async options => {
  const { provider, sendRequest, receiveResponse } = options;

  let res;

  try {
    res = await sendRequest(provider);
  } catch (err) {
    res = err;
  }

  res = receiveResponse(res, options);

  return res;
};

export default processRequest;
