import { Role, useHasRole } from 'modules/acl';
import {
  useRefurbishmentTaskItemsExcludingCosts,
  useRefurbishmentTaskItemsIncludingCosts,
} from 'modules/api/refurbishmentTasks';

export const useRefurbishmentTaskItems = (taskId?: string) => {
  const userHasReadWithCostsRole = useHasRole(Role.TASK_COST_READ);

  const [
    { data: dataWithCosts, loading: loadingWithCosts, error: errorWithCosts },
  ] = useRefurbishmentTaskItemsIncludingCosts(taskId, userHasReadWithCostsRole);

  const [
    {
      data: dataWithoutCosts,
      loading: loadingWithoutCosts,
      error: errorWithoutCosts,
    },
  ] = useRefurbishmentTaskItemsExcludingCosts(taskId, userHasReadWithCostsRole);

  return {
    data: dataWithCosts || dataWithoutCosts,
    loading: loadingWithCosts || loadingWithoutCosts,
    error: errorWithCosts || errorWithoutCosts,
  };
};
