import { createContext } from 'react';
import { noop } from 'lodash';

import { FeatureFlag } from 'modules/feature';

const DeveloperModeContext = createContext<{
  developerMode: boolean;
  flagsStatus: Partial<Record<FeatureFlag, boolean>>;
  switchFlag: (flag: FeatureFlag) => void;
}>({
  developerMode: false,
  flagsStatus: {},
  switchFlag: noop,
});

export default DeveloperModeContext;
