import gql from 'graphql-tag';

export const CommentFragment = gql`
  fragment CommentFragment on Comment {
    id
    refurbishmentId
    comment
    createdBy {
      id
      firstName
      lastName
      userType
    }
    createdOn
    updatedOn
  }
`;
