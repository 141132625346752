import { useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';

import { Query, QueryIsCarReadyForExitCheckArgs } from 'graphqlTypes';
import IS_CAR_READY_FOR_EXIT_CHECK from './gql/isCarReadyForExitCheck';

const useIsCarReadyForExitCheck = (adId?: string) => {
  const { data } = useQuery<
    Pick<Query, 'isCarReadyForExitCheck'>,
    QueryIsCarReadyForExitCheckArgs
  >(IS_CAR_READY_FOR_EXIT_CHECK, {
    variables: { adId },
    skip: adId == null,
  });

  return {
    readyForExitCheck: getOr(
      false,
      ['isCarReadyForExitCheck', 'readyForExitCheck'],
      data,
    ),
  };
};

export default useIsCarReadyForExitCheck;
