import { useMutation } from '@apollo/react-hooks';

import {
  CreateImportTaskInput,
  Mutation,
  MutationCreateRepairCostImportTaskArgs,
} from 'graphqlTypes';
import CREATE_REPAIR_COST_IMPORT_TASK from './gql/createRepairCostImportTask';

const useCreateRepairCostImportTask = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createRepairCostImportTask'>,
    MutationCreateRepairCostImportTaskArgs
  >(CREATE_REPAIR_COST_IMPORT_TASK);

  const createRepairCostImportTask = (
    createImportTaskInput: CreateImportTaskInput,
  ) => mutation({ variables: { createImportTaskInput } });

  return [
    createRepairCostImportTask,
    {
      createRepairCostImportTaskData: data?.createRepairCostImportTask,
      ...rest,
    },
  ] as const;
};

export default useCreateRepairCostImportTask;
