import React from 'react';
import { Link } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import PageContainer from 'components/Layout/PageContainer';
import Text from 'components/UI/Text';
import Title from 'components/UI/Title';
import { getUrl } from 'modules/routeUtils';

import cn from './DisabledUserRoute.module.scss';

const DisabledUserRoute = () => {
  return (
    <PageContainer>
      <div className={cn.wrapper} data-qa-selector="disabledUserErrorPage">
        <Title level="h1">{__('error.disabledUser.header')}</Title>

        <div className={cn.warningIcon}>
          <WarningAmberIcon fontSize="inherit" />
        </div>

        <Text tag="p" className={cn.message} qaSelector="message">
          {__('error.disabledUser.message')}
        </Text>

        <Link to={getUrl('LOGIN')} data-qa-selector="backToLogin">
          {__('action.backToLogin')}
        </Link>
      </div>
    </PageContainer>
  );
};

export default DisabledUserRoute;
