import gql from 'graphql-tag';

import { QualityCheckApprovalVehicleItemPayloadFragment } from './fragments';

export default gql`
  mutation updateQualityCheckApprovalVehicleItem(
    $itemId: UUID!
    $vehicleItemUpdateInput: VehicleItemUpdateInput!
  ) {
    updateQualityCheckApprovalVehicleItem(
      itemId: $itemId
      vehicleItemUpdateInput: $vehicleItemUpdateInput
    ) {
      id
      data {
        ...QualityCheckApprovalVehicleItemPayloadFragment
      }
    }
  }
  ${QualityCheckApprovalVehicleItemPayloadFragment}
`;
