import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryUserArgs } from 'graphqlTypes';
import USER from './gql/user';

const useGetUser = (id: string = '') => {
  const { data, ...rest } = useQuery<Pick<Query, 'user'>, QueryUserArgs>(USER, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    data: get(['user'], data),
    ...rest,
  };
};

export default useGetUser;
