import { useFormContext } from 'react-hook-form';
import { Option } from 'types';

import BadgeItem from 'components/Filter/BadgeItem';
import { statesTranslated as exitCheckStatesTranslated } from 'constants/exitCheck';
import { ALL_STATES, statesTranslated } from 'constants/state';
import createOptions from 'helpers/createOptions';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const stateOptions = createOptions({
  ...statesTranslated,
  ...exitCheckStatesTranslated,
}).reduce<Record<string, Option>>(
  (acc, item) => ({ ...acc, [item.value]: item }),
  {},
);

const StateBadges = () => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error("'StateBadges' must be used inside a 'Filter' component.");
  }
  const { watch, getValues, setValue } = formMethods;

  const stateValues = watch(FormFilterNames.STATE) as string[] | undefined;

  if (!stateValues || !stateValues?.length) return null;

  let options: Option[];
  if (
    Array.isArray(stateValues) &&
    stateValues.length === 1 &&
    stateValues.includes(ALL_STATES)
  ) {
    options = [
      {
        label: __('refurbishment.task.list.status'),
        value: ALL_STATES,
      },
    ];
  } else {
    options = stateValues
      .filter(s => s !== ALL_STATES)
      .map(option => stateOptions[option]);
  }

  return (
    <div className={cn.badges} data-qa-selector="activeBadgeStateFilters">
      {options.map(option => (
        <BadgeItem
          key={option.value}
          option={option}
          qaSelector="stateFilter"
          onRemove={state => {
            const values = getValues();
            setValue(
              FormFilterNames.STATE,
              values[FormFilterNames.STATE]?.filter(
                (item: string) => item !== state,
              ),
            );
          }}
        />
      ))}
    </div>
  );
};

export default StateBadges;
