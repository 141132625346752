import cns from 'classnames';

import { PartnerWorkshopConnectionRefurbishmentResponse } from 'graphqlTypes';

export type SelectedWorkshopProps = {
  partnerWorkshopConnections?:
    | PartnerWorkshopConnectionRefurbishmentResponse[]
    | null;
  className?: string;
};

export const SelectedWorkshop = ({
  partnerWorkshopConnections,
  className,
}: SelectedWorkshopProps) => {
  const currentConnections = partnerWorkshopConnections || [];
  const selectedWorkshop = currentConnections?.[0]?.mainWorkshop.name;

  if (!selectedWorkshop) return null;

  return (
    <div className={cns(className, 'selectedWorkshop')}>
      <span>
        {__('vehicleHeader.mainWorkshop')}:{' '}
        <strong data-qa-selector="mainWorkshop">{selectedWorkshop}</strong>
      </span>
    </div>
  );
};
