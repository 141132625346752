import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';

import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import { Group } from 'graphqlTypes';
import { useGetGroupsById } from 'modules/api/groups';
import { ErrorType, useHandleError } from 'modules/errorHandler';
import EditGroup from './components/EditGroup';
import Members from './components/Members';
import Roles from './components/Roles';
import mapData from './mapData';

const renderDetails = (id: string, data: Group) => {
  if (isEmpty(data)) return null;

  const { name, description, roles, users } = mapData(data);

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <PageHeader title={name} data-qa-selector="group_name" />
      <EditGroup id={id} name={name} description={description} />
      <p data-qa-selector="group_name_description">{description}</p>
      <Roles id={id} roles={roles} />
      <Members id={id} members={users} />
    </>
  );
};

const DetailRoute = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetGroupsById(id);
  const setError = useHandleError();

  if (error) {
    setError(ErrorType.UnexpectedError);
    return null;
  }

  if (!id || !data) {
    return null;
  }

  return (
    <PageContainer>
      {loading && <LoadingIndicator />}
      {!loading && data && renderDetails(id, data)}
    </PageContainer>
  );
};

export default DetailRoute;
