import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationCancelRepairMethodImportArgs } from 'graphqlTypes';
import CANCEL_REPAIR_METHOD_IMPORT from './gql/cancelRepairMethodImport';

const useCancelRepairMethodImport = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'cancelRepairMethodImport'>,
    MutationCancelRepairMethodImportArgs
  >(CANCEL_REPAIR_METHOD_IMPORT);

  const cancelRepairMethodImport = (id: string) =>
    mutation({ variables: { id } });

  return [
    cancelRepairMethodImport,
    {
      data: data?.cancelRepairMethodImport,
      ...rest,
    },
  ] as const;
};

export default useCancelRepairMethodImport;
