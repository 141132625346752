import gql from 'graphql-tag';

export default gql`
  mutation updateTaskItemStateAndRefurbishmentState(
    $taskItemId: UUID!
    $refurbishmentTaskItemState: RefurbishmentTaskItemState!
  ) {
    updateTaskItemStateAndRefurbishmentState(
      taskItemId: $taskItemId
      refurbishmentTaskItemState: $refurbishmentTaskItemState
    ) {
      taskItemId
      refurbishmentTaskItemState
    }
  }
`;
