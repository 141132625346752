import { components, ControlProps } from 'react-select';

const createControl = (selector: string) => (props: ControlProps<any>) => {
  return (
    <div data-qa-selector={selector}>
      <components.Control {...props} />
    </div>
  );
};

export default createControl;
