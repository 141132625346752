import React from 'react';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { ControllerProps, FieldValues } from 'react-hook-form';

import { formatSymbol } from 'modules/i18n';
import DecimalInput from '../DecimalInput';

import cn from './PriceInputCurrency.module.scss';

type Props<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    currencyCode: string;
  };

const PriceInputCurrency = <T extends FieldValues>({
  currencyCode,
  className,
  ...rest
}: Props<T>) => {
  return (
    <InputGroup className={className}>
      <InputGroup.Prepend>
        <InputGroup.Text>{formatSymbol(currencyCode)}</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control {...rest} as={DecimalInput} className={cn.input} />
    </InputGroup>
  );
};

export default PriceInputCurrency;
