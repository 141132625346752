import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import cns from 'classnames';
import { isEqual } from 'lodash/fp';

import createClearIndicator from './createClearIndicator';
import createControl from './createControl';
import createLoadingIndicator from './createLoadingIndicator';
import createMenu from './createMenu';
import createMultiValueContainer from './createMultiValueContainer';
import createOption, { CreateReactSelectOptionParams } from './createOption';
import createSingleValue, {
  CreateSingleValueParams,
} from './createSingleValue';

import cn from './ReactSelectInput.module.scss';

export type ReactSelectInputProps<T extends FieldValues = FieldValues> = Omit<
  ControllerProps<T>,
  'render' | 'onChange'
> &
  Omit<ReactSelectProps, 'name' | 'isDisabled' | 'onChange'> & {
    isInvalid?: boolean;
    dataQa: string;
    dataQaOption: string;
    dataQaMultiValueContainer?: string;
    dataQaOptionHandler?: (value: any) => string;
    dataQaClearContainer?: string;
    renderOption?: CreateReactSelectOptionParams['renderOption'];
    renderSingleValue?: CreateSingleValueParams['renderSingleValue'];
    disabled?: boolean;
    onChange?: (value: any) => void;
    equalFn?: (value1: any, value2: any) => boolean;
    transformSelectedOptions?: (newValue: any, previousValue: any) => any;
  };

const ReactSelectInput = <T extends FieldValues>({
  name,
  options = [],
  control,
  rules,
  dataQa,
  dataQaOption,
  dataQaMultiValueContainer,
  dataQaClearContainer,
  dataQaOptionHandler,
  renderOption,
  renderSingleValue,
  components,
  isInvalid,
  className,
  styles,
  disabled,
  onChange,
  equalFn,
  transformSelectedOptions = (newValue: any) => newValue,
  defaultValue,
  shouldUnregister,
  ...rest
}: ReactSelectInputProps<T>) => {
  const replacedComponents = {
    ...components,
    Option: createOption({ dataQaOption, dataQaOptionHandler, renderOption }),
    Control: createControl(dataQa),
    Menu: createMenu(`${dataQa}-menu`),
    LoadingIndicator: createLoadingIndicator(`${dataQa}-loadingIndicator`),
    SingleValue: createSingleValue({ renderSingleValue }),
  };

  if (dataQaMultiValueContainer) {
    replacedComponents.MultiValueContainer = createMultiValueContainer(
      dataQaMultiValueContainer,
      dataQaOptionHandler,
    );
  }

  if (rest.isMulti && !dataQaMultiValueContainer) {
    throw new Error(
      'You must set dataQaMultiValueContainer if you set isMulti as true',
    );
  }

  if (dataQaClearContainer) {
    replacedComponents.ClearIndicator = createClearIndicator(
      dataQaClearContainer,
    );
  }

  const invalidClass = isInvalid ? cn.isInvalid : '';

  const valueFilter = (values: any[] | any) => {
    const selectValue = options[
      rest.isMulti ? 'filter' : 'find'
    ]((option: any) =>
      [values]
        ?.flat()
        ?.find(value =>
          equalFn ? equalFn(value, option.value) : isEqual(value, option.value),
        ),
    );
    return selectValue || '';
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, onChange: onFieldChange, ...field } }) => (
        <ReactSelect
          options={options}
          components={replacedComponents}
          className={cns(className, cn.select, invalidClass)}
          classNamePrefix="react-select"
          styles={
            styles ?? {
              menuPortal: (existingStyles: any) => ({
                ...existingStyles,
                zIndex: 9999,
              }),
            }
          }
          menuPortalTarget={document.body}
          {...rest}
          {...field}
          onChange={(values: any | any[]) => {
            if (Array.isArray(values)) {
              const mappedValues = transformSelectedOptions(
                values?.map((option: any) => option.value),
                value,
              );
              if (onChange) onChange(mappedValues);
              return onFieldChange(mappedValues);
            }
            const onChangeValue = transformSelectedOptions(
              values && values?.value !== undefined ? values.value : null,
              value,
            );
            if (onChange) onChange(onChangeValue);
            return onFieldChange(onChangeValue);
          }}
          value={valueFilter(value)}
          defaultValue={valueFilter(value)}
          isDisabled={disabled}
        />
      )}
    />
  );
};

export default ReactSelectInput;
