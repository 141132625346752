import {
  DamageTypePayload,
  EntryCheckEntityType,
  EntryCheckItem,
} from 'graphqlTypes';
import { PayloadType } from 'routes/RefurbishmentRoute/types';

export const filterEntity = (entityType: EntryCheckEntityType) => (
  item: EntryCheckItem,
) => item.entityType === entityType;

export const filterPayloads = (payloadTypes: PayloadType[]) => (
  item: EntryCheckItem,
) =>
  payloadTypes.includes(
    (item?.values?.[0]?.originalValue?.payloadType ||
      item?.values?.[0]?.proposedValue?.payloadType) as PayloadType,
  );

export const filterAreas = (
  areas: string[],
  subAreas: string[],
  items: EntryCheckItem[],
) => {
  const entityIds = items
    .filter(filterPayloads([PayloadType.DamageType]))
    .reduce((acc: string[], item) => {
      const { entityId } = item;

      if (!entityId) return acc;

      const {
        area: { key: area },
        subArea: { key: subArea },
      } = (item?.values?.[0]?.originalValue ||
        item?.values?.[0]?.proposedValue) as DamageTypePayload;

      if (
        areas.includes(area) &&
        (subAreas?.length === 0 || subAreas.includes(subArea))
      ) {
        acc.push(entityId);
      }

      return acc;
    }, []);

  return items.filter(({ entityId }) => entityIds.includes(String(entityId)));
};
