import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { Query, QuerySearchInformMessagesArgs } from 'graphqlTypes';
import SEARCH_INFORM_MESSAGES from './gql/searchInformMessages';

const useSearchInformMessages = (
  variables: QuerySearchInformMessagesArgs,
  options: QueryHookOptions<
    Pick<Query, 'searchInformMessages'>,
    QuerySearchInformMessagesArgs
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'searchInformMessages'>,
    QuerySearchInformMessagesArgs
  >(SEARCH_INFORM_MESSAGES, {
    variables,
    fetchPolicy: 'network-only',
    ...options,
  });

  return {
    data: data?.searchInformMessages,
    ...rest,
  };
};

export default useSearchInformMessages;
