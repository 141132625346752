import gql from 'graphql-tag';

import { DamageItemPayloadFragment } from './fragments';

export default gql`
  mutation updateQualityCheckDamageItem(
    $itemId: UUID!
    $damageItemUpdateInput: DamageItemUpdateInput!
  ) {
    updateQualityCheckDamageItem(
      itemId: $itemId
      damageItemUpdateInput: $damageItemUpdateInput
    ) {
      id
      data {
        payloadType
        ... on DamageItemPayload {
          damageState: state
        }
        ...DamageItemPayloadFragment
      }
    }
  }
  ${DamageItemPayloadFragment}
`;
