import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationAssignGroupsArgs } from 'graphqlTypes';
import ASSIGN_GROUPS from './gql/assignGroups';

const useAssignGroups = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'assignGroups'>,
    MutationAssignGroupsArgs
  >(ASSIGN_GROUPS);

  const assignGroups = (variables: MutationAssignGroupsArgs) => {
    mutation({
      variables,
    });
  };

  return [assignGroups, options] as const;
};

export default useAssignGroups;
