import { RefurbishmentTaskItemIncludingCost } from 'graphqlTypes';

type Props = {
  subService: RefurbishmentTaskItemIncludingCost;
  children: React.ReactNode;
};

export const ServiceWorkSubService = ({ subService, children }: Props) => {
  return (
    <div
      className="d-flex justify-content-between"
      data-qa-selector="service-work-sub-service-container"
    >
      <span>{subService.service?.serviceData.name.translation}</span>
      <span>{children}</span>
    </div>
  );
};
