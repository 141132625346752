import {
  REFURBISHMENT_NUMBER_LENGTH,
  SHORT_VIN_NUMBER_LENGTH,
  STOCK_NUMBER_LENGTH,
  VIN_NUMBER_LENGTH,
} from 'constants/refurbishment';

export const vinStockRefRegExp = new RegExp(
  `^[a-zA-Z0-9]{${REFURBISHMENT_NUMBER_LENGTH}}$|^[a-zA-Z0-9]{${STOCK_NUMBER_LENGTH}}$|^[a-zA-Z0-9]{${VIN_NUMBER_LENGTH}}$|^[a-zA-Z0-9]{${SHORT_VIN_NUMBER_LENGTH}}$`,
);

export const stockRefRegExp = new RegExp(
  `^[a-zA-Z0-9]{${REFURBISHMENT_NUMBER_LENGTH}}$|^[a-zA-Z0-9]{${STOCK_NUMBER_LENGTH}}$`,
);
