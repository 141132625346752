import gql from 'graphql-tag';

import { ServiceItemPayloadFragment } from './fragments';

export default gql`
  mutation updateQualityCheckServiceItem(
    $itemId: UUID!
    $serviceItemUpdateInput: ServiceItemUpdateInput!
  ) {
    updateQualityCheckServiceItem(
      itemId: $itemId
      serviceItemUpdateInput: $serviceItemUpdateInput
    ) {
      id
      phaseName
      data {
        payloadType
        ... on ServiceItemPayload {
          serviceState: state
        }
        ...ServiceItemPayloadFragment
      }
      revisions {
        id
        propertyPath
        state
        createdBy {
          id
          firstName
        }
      }
      createdOn
    }
  }
  ${ServiceItemPayloadFragment}
`;
