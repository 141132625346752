import { Control } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { useGetInformTenants } from 'modules/api/informMessage';
import { FormType } from './types';

type Props = {
  control: Control<FormType, any>;
  name: keyof FormType;
  form: string;
};

const Tenant = ({ control, name, form }: Props) => {
  const { data, loading } = useGetInformTenants();

  const options = [
    { label: __('monitoring.filter.allOption'), value: '' },
    ...(data?.map(option => ({
      label: option.name,
      value: option.id,
    })) || []),
  ];

  return (
    <ReactSelectInput
      control={control}
      name={name}
      form={form}
      options={options}
      isLoading={loading}
      dataQa="tenant"
      dataQaOption="tenantOption"
    />
  );
};

export default Tenant;
