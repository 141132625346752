import gql from 'graphql-tag';

export default gql`
  query brakeDiskConditions($brakeTypeId: UUID!) {
    brakeDiskConditions(brakeTypeId: $brakeTypeId) {
      id
      recordKey
      rangeStartInPercentInclusive
      rangeEndInPercentExclusive
    }
  }
`;
