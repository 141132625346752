import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateQualityCheckVehicleItemArgs,
  VehicleItemUpdateInput,
} from 'graphqlTypes';
import UPDATE_QUALITY_CHECK_VEHICLE_ITEM from './gql/updateQualityCheckVehicleItem';

const useUpdateQualityCheckVehicleItem = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckVehicleItem'>,
    MutationUpdateQualityCheckVehicleItemArgs
  >(UPDATE_QUALITY_CHECK_VEHICLE_ITEM);

  const updateQualityCheckVehicleItem = (
    itemId: string,
    vehicleItemUpdateInput: VehicleItemUpdateInput,
  ) => {
    return mutation({
      variables: {
        itemId,
        vehicleItemUpdateInput,
      },
    });
  };

  return [updateQualityCheckVehicleItem, options] as const;
};

export default useUpdateQualityCheckVehicleItem;
