import { Button } from 'react-bootstrap';

import FormButtonModal from 'components/Form/FormButtonModal/FormButtonsModal';
import { ImportTask } from 'graphqlTypes';
import { saveToDiskCsvFile } from 'helpers/saveToDiskCsvFile';
import { usePromiseDownloadRepairMethodImportTaskValidationResult } from 'modules/api/costTable';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { ImportRepairMethodPhase } from './ImportRepairMethodModal';
import ImportRepairMethodStatistics from './ImportRepairMethodStatistics';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairMethodPhase>>;
  repairMethodImportTaskData?: ImportTask;
};

const ImportRepairMethodReUpload = ({
  closeModal,
  setActivePhase,
  repairMethodImportTaskData,
}: Props) => {
  const { logError } = useLogger();
  const { error } = useNotification();
  const downloadRepairMethodImportTaskValidationResult = usePromiseDownloadRepairMethodImportTaskValidationResult();

  const reUploadFile = () => setActivePhase(ImportRepairMethodPhase.UPLOADING);

  const downloadCsvLogFile = async () => {
    try {
      const id = repairMethodImportTaskData?.id || '';
      const csv = await downloadRepairMethodImportTaskValidationResult(id);

      saveToDiskCsvFile(csv, `${id}.csv`);
    } catch (errorData) {
      logError({ error: errorData });
      error(__('repairMethod.failedCsvLogDownload'));
    }
  };

  return (
    <>
      <h3>{__('repairMethod.reUploadFile')}</h3>
      <ImportRepairMethodStatistics
        statistics={repairMethodImportTaskData?.statistics}
      />
      <Button variant="link" className="p-0 mt-3" onClick={downloadCsvLogFile}>
        {__('repairMethod.downloadValidationLog')}
      </Button>
      <FormButtonModal>
        <Button
          variant="secondary"
          onClick={() => {
            setActivePhase(ImportRepairMethodPhase.UPLOADING);
            closeModal();
          }}
          data-qa-selector="closeImportRepairMethodModal"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="ml-4"
          data-qa-selector="reUploadFileRepairMethodModal"
          onClick={reUploadFile}
        >
          {__('repairMethod.reUploadFileAction')}
        </Button>
      </FormButtonModal>
    </>
  );
};

export default ImportRepairMethodReUpload;
