import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import SPEED_INDEXES from './gql/speedIndexes';

const useSpeedIndexes = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'speedIndexes'>, null>(
    SPEED_INDEXES,
  );

  return {
    data: get('speedIndexes', data),
    ...rest,
  };
};

export default useSpeedIndexes;
