import gql from 'graphql-tag';

export const ExitCheckDocumentFragment = gql`
  fragment ExitCheckDocumentFragment on Document {
    id
    attachedTo
    createdOn
    state
    sourceType
    url
    fileName
    description
    type
    createdBy {
      id
      firstName
      lastName
      refurbishmentProvidersData {
        location {
          id
          name
        }
        partner {
          id
          name
        }
      }
    }
  }
`;

export const ExitCheckCommentFragment = gql`
  fragment ExitCheckCommentFragment on ExitCheckComment {
    id
    exitCheckId
    comment
    createdBy {
      id
      firstName
      lastName
      email
      userType
    }
    createdOn
    updatedOn
  }
`;

export const ExitCheckPhotoFragment = gql`
  fragment ExitCheckPhotoFragment on ExitCheckPhoto {
    id
    exitCheckId
    type
    imageUrl
  }
`;
