import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationUpdatePartnerArgs } from 'graphqlTypes';
import UPDATE_PARTNER_DETAILS from './gql/updatePartner';

const useUpdatePartnerDetails = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updatePartner'>,
    MutationUpdatePartnerArgs
  >(UPDATE_PARTNER_DETAILS);

  const updatePartnerDetails = (partnerInput: MutationUpdatePartnerArgs) => {
    return mutation({
      variables: partnerInput,
    });
  };

  return [
    updatePartnerDetails,
    {
      data,
      ...rest,
    },
  ] as const;
};

export default useUpdatePartnerDetails;
