import gql from 'graphql-tag';

export default gql`
  mutation updateComment($id: UUID!, $comment: String!) {
    updateComment(id: $id, comment: $comment) {
      comment {
        id
      }
    }
  }
`;
