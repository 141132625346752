import {
  AreBrakeHosesInGoodConditionPayload,
  AreRimsAndTiresLicensedForThisCarPayload,
  AreSpringsOkPayload,
  AreSupportArmsOkPayload,
  BrakeDiskConditionPayload,
  BrakePadConditionPayload,
  BrakeTypePayload,
  DoesBallJointWorkPayload,
  DoesBrakeFluidCapExistsPayload,
  DoesDustCoverExistsPayload,
  DoesRimCenterCapExistsPayload,
  IsBrakeCaliperInGoodConditionPayload,
  IsBumpStopOkPayload,
  IsControlArmOkPayload,
  IsRimInGoodConditionPayload,
  IsShockAbsorberOkPayload,
  IsStabilizerBearingOkPayload,
  IsStrutBearingOkPayload,
  IsTireDirectionCorrectPayload,
  IsTireInGoodConditionPayload,
  IsTirePressureCorrectPayload,
  IsWheelBearingOkPayload,
} from 'graphqlTypes';
import { EntryCheckItem, PayloadType } from 'routes/RefurbishmentRoute/types';

export type SuspensionGraphQlJsonPayload =
  | AreSpringsOkPayload
  | AreSupportArmsOkPayload
  | DoesBallJointWorkPayload
  | DoesDustCoverExistsPayload
  | IsBumpStopOkPayload
  | IsControlArmOkPayload
  | IsShockAbsorberOkPayload
  | IsStabilizerBearingOkPayload
  | IsStrutBearingOkPayload
  | IsWheelBearingOkPayload;

export type BrakeCheckpointsGraphQlJsonPayload =
  | BrakeTypePayload
  | DoesBrakeFluidCapExistsPayload
  | AreBrakeHosesInGoodConditionPayload
  | IsBrakeCaliperInGoodConditionPayload
  | BrakePadConditionPayload
  | BrakeDiskConditionPayload;

export type BrakeCheckpointsYesNoGraphQlJsonPayload =
  | DoesBrakeFluidCapExistsPayload
  | AreBrakeHosesInGoodConditionPayload
  | IsBrakeCaliperInGoodConditionPayload;

export type BrakeCheckpointsSelectGraphQlJsonPayload = Omit<
  BrakePadConditionPayload | BrakeDiskConditionPayload,
  'condition'
> & { brakeCondition: string };

export type RimTireGraphQlJsonPayload =
  | IsRimInGoodConditionPayload
  | DoesRimCenterCapExistsPayload
  | IsTireInGoodConditionPayload
  | IsTireDirectionCorrectPayload
  | IsTirePressureCorrectPayload
  | AreRimsAndTiresLicensedForThisCarPayload;

export const WheelsRimTirePayloadTypes = [
  PayloadType.IsRimInGoodCondition,
  PayloadType.DoesRimCenterCapExists,
  PayloadType.IsTireInGoodCondition,
  PayloadType.IsTireDirectionCorrect,
  PayloadType.IsTirePressureCorrect,
];

export const WheelsSuspensionPayloadTypes = [
  PayloadType.IsWheelBearingOk,
  PayloadType.AreSpringsOk,
  PayloadType.IsShockAbsorberOk,
  PayloadType.DoesBallJointWork,
  PayloadType.DoesDustCoverExist,
  PayloadType.IsBumpStopOk,
  PayloadType.IsStrutBearingOk,
  PayloadType.AreSupportArmsOk,
  PayloadType.IsControlArmOk,
  PayloadType.IsStabilizerBearingOk,
];

export const BrakeCheckpointsYesNoPayloadTypes = [
  PayloadType.DoesFluidCapExists,
  PayloadType.AreBrakeHosesInGoodCondition,
  PayloadType.IsBrakeCaliperInGoodCondition,
];

export const BrakeCheckpointsSelectPayloadTypes = [
  PayloadType.BrakePadCondition,
  PayloadType.BrakeDiskCondition,
];

export const BrakeCheckpointsPayloadTypes = [
  PayloadType.BrakeType,
  ...BrakeCheckpointsYesNoPayloadTypes,
  ...BrakeCheckpointsSelectPayloadTypes,
];

export const getCheckpoints = (
  entryCheckItems: EntryCheckItem[],
): EntryCheckItem[] => {
  const checkpointPayloadTypes = [
    ...WheelsRimTirePayloadTypes,
    ...WheelsSuspensionPayloadTypes,
    ...BrakeCheckpointsPayloadTypes,
  ];

  return entryCheckItems.filter(item =>
    checkpointPayloadTypes.includes(item.payloadType),
  );
};

export default getCheckpoints;
