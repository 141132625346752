export enum Step {
  Services = 'Services',
  Front = 'Front',
  Right = 'Right',
  Back = 'Back',
  Left = 'Left',
  Roof = 'Roof',
  Interior = 'Interior',
  Underbody = 'Underbody',
  Engine = 'Engine',
  Wheels = 'Wheels',
  VehicleServices = 'VehicleServices',
}
