import Text from 'components/UI/Text';
import {
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import AddProductionLocation from './AddProductionLocation';
import ProductionLocationsTable from './ProductionLocationsTable';

type Props = {
  partnerWorkshopConnections: UserPartnerWorkshopConnection[];
  loading?: boolean;
  onChange(values: UserPartnerWorkshopConnectionInput): Promise<any>;
};

const ProductionLocations = ({
  partnerWorkshopConnections,
  loading = false,
  onChange,
}: Props) => {
  return (
    <>
      <Text size="l" weight="bold" tag="h3">
        {__('user.production.locations')}
      </Text>
      <ProductionLocationsTable
        partnerWorkshopConnections={partnerWorkshopConnections}
        loading={loading}
        onChange={onChange}
      />
      <AddProductionLocation
        userPartnerWorkshopConnections={partnerWorkshopConnections}
        loading={loading}
        onChange={onChange}
      />
    </>
  );
};

export default ProductionLocations;
