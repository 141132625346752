import gql from 'graphql-tag';

import { PartnerWorkshopConnectionRefurbishmentResponseFragment } from './fragments';

export default gql`
  mutation updateRefurbishment($id: UUID!, $input: RefurbishmentUpdateInput!) {
    updateRefurbishment(id: $id, input: $input) {
      refurbishmentDetails {
        partnerWorkshopConnections {
          ...PartnerWorkshopConnectionRefurbishmentResponseFragment
        }
      }
    }
  }
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
`;
