import { useQuery } from '@apollo/react-hooks';

import { Query, QuerySearchRepairCostImportTasksArgs } from 'graphqlTypes';
import QUERY from './gql/searchRepairCostImportTasks';

const useSearchRepairCostImportTasks = (
  variables: QuerySearchRepairCostImportTasksArgs,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'searchRepairCostImportTasks'>,
    QuerySearchRepairCostImportTasksArgs
  >(QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.searchRepairCostImportTasks,
    ...rest,
  };
};

export default useSearchRepairCostImportTasks;
