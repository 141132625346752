import { countries, locales } from './constants';

const mapToOptions = (map: Map<string, string>) =>
  [...map].map(item => ({
    value: item[0],
    label: item[1],
  }));

export const countryOptions = mapToOptions(countries);
export const localeOptions = mapToOptions(locales);
