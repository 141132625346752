import { PartnerDetails } from 'graphqlTypes';
import { ACL, Role } from 'modules/acl';
import { partnerTypeTranslation } from 'routes/PartnerRoute/partnerTranslation';
import EditPartner from './EditPartner';

const PartnerSearchRow = ({ item }: { item: PartnerDetails }) => {
  const { address, city, postcode } = item;

  const addressConcat = `${address ? `${address}, ` : ''}${
    city ? `${city}, ` : ''
  }${postcode ? `${postcode}` : ''}`;

  const partnerType =
    (item.partnerType && partnerTypeTranslation[item.partnerType]) || '';

  return (
    <tr key={item.id} data-qa-selector={`row-${item.id}`} data-qa-id={item.id}>
      <td data-qa-selector="partnerId">{item.id}</td>
      <td data-qa-selector="partnerName">{item.name}</td>
      <td data-qa-selector="partnerContactName">{item.contactName}</td>
      <td data-qa-selector="partnerPhone">{item.phone}</td>
      <td data-qa-selector="partnerType">{partnerType}</td>
      <td data-qa-selector="partnerEmail">{item.partnerEmail}</td>
      <td data-qa-selector="partnerEmail1">{item.email1}</td>
      <td data-qa-selector="partnerEmail2">{item.email2}</td>
      <td data-qa-selector="partnerAddress">{addressConcat}</td>
      <td data-qa-selector="partnerCountry">{item.country}</td>
      <td data-qa-selector="attribute2Value">
        {item.active ? __('partners.active') : __('partners.notActive')}
      </td>

      <ACL roles={Role.PARTNER_WRITE}>
        <td data-qa-selector="attribute2Value">
          <EditPartner item={item} />
        </td>
      </ACL>
    </tr>
  );
};

export default PartnerSearchRow;
