import { useQuery } from '@apollo/react-hooks';

import { Query, QueryPartnerSearchArgs } from 'graphqlTypes';
import PARTNER_SEARCH from './gql/partnerSearch';

const usePartnerSearch = (variables: QueryPartnerSearchArgs) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'partnerSearch'>,
    QueryPartnerSearchArgs
  >(PARTNER_SEARCH, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return {
    data,
    ...rest,
  };
};

export default usePartnerSearch;
