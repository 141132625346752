import { useEffect, useRef } from 'react';

const useMergeRefs = <T>(refs: React.MutableRefObject<T | null>[]) => {
  const mergedRef = useRef<T | null>(null);

  useEffect(() => {
    refs.forEach(ref => {
      // eslint-disable-next-line no-param-reassign
      ref.current = mergedRef.current;
    });
  }, [mergedRef.current]);

  return mergedRef;
};

export default useMergeRefs;
