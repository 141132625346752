import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationStartRepairCostImportArgs } from 'graphqlTypes';
import START_REPAIR_COST_IMPORT from './gql/startRepairCostImport';

const useStartRepairCostImport = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'startRepairCostImport'>,
    MutationStartRepairCostImportArgs
  >(START_REPAIR_COST_IMPORT);

  const startRepairCostImport = (id: string) => mutation({ variables: { id } });

  return [
    startRepairCostImport,
    {
      startRepairCostImportData: data?.startRepairCostImport,
      ...rest,
    },
  ] as const;
};

export default useStartRepairCostImport;
