import { setupApi } from 'modules/apiRest';

setupApi(
  {
    UPLOAD_FILE: (url, formData, file) => ({
      method: 'POST',
      url,
      attach: { ...formData, file },
    }),
  },
  {
    formatUrl: ({ url }) => url,
  },
);
