import { get, mapValues } from 'lodash/fp';

import createApi from './providers/axiosProvider';

const createEndpoints = (api, routes) =>
  mapValues(getRequest => (...args) => api(getRequest(...args)), routes);

let endpoints = {};

export const setupApi = (routes, apiOptions) => {
  const apiTransport = createApi(apiOptions);

  const newEndpoints = createEndpoints(apiTransport, routes);

  endpoints = { ...endpoints, ...newEndpoints };
};

export const sendReq = (endpointName, ...args) => {
  const value = get(endpointName, endpoints);

  if (value === undefined) {
    throw new Error(`The endpoint name '${endpointName}' is not configured!`);
  }

  return value(...args);
};
