import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FieldError from 'components/Form/FieldError';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import RequiredFieldIndicator from 'components/Form/RequiredFieldIndicator';
import { EntryCheckItemValueState } from 'graphqlTypes';
import { useUpdateEntryCheckItemValues } from 'modules/api/entryCheck';
import { useLogger } from 'modules/logger';
import { SelectOption } from 'routes/ExitCheckRoute/types';
import { EntryCheckItem } from 'routes/RefurbishmentRoute/types';

type Props = {
  entryCheckItem?: EntryCheckItem;
  label: string;
  required?: boolean;
  name: string;
  value?: string | null;
  options: SelectOption[];
  disabled: boolean;
  loading?: boolean;
  updateEntryCheckItemValueField: string;
  onChange?(value: string): void;
};

const BrakeSelectItem = ({
  entryCheckItem,
  label,
  required,
  name,
  value,
  options,
  disabled,
  loading,
  updateEntryCheckItemValueField,
  onChange,
}: Props) => {
  const { logError } = useLogger();
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<Record<string, string | undefined | null>>({
    defaultValues: { [name]: value },
  });
  const [
    updateEntryCheckItemValue,
    { loading: updateLoading },
  ] = useUpdateEntryCheckItemValues();

  useEffect(() => {
    if (!value) setValue(name, null);
  }, [value]);

  if (!entryCheckItem) return null;

  const handleChange = async (formValue: any) => {
    const { id } = entryCheckItem.values[0];

    updateEntryCheckItemValue([
      {
        id,
        value: {
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: {
            ...entryCheckItem.values[0].originalValue,
            [updateEntryCheckItemValueField]: formValue,
          },
        },
      },
    ]).catch(error => {
      logError({ error });
    });

    if (onChange) onChange(formValue);
  };

  return (
    <Form.Group>
      {required ? (
        <RequiredFieldIndicator>
          <Form.Label>{label}</Form.Label>
        </RequiredFieldIndicator>
      ) : (
        <Form.Label>{label}</Form.Label>
      )}
      <ReactSelectInput
        name={name}
        dataQa={name}
        dataQaOption={`${name}Option`}
        placeholder={__('action.select')}
        options={options ?? []}
        control={control}
        onChange={handleChange}
        disabled={disabled || loading || updateLoading}
      />
      <FieldError name={name} errors={errors} />
    </Form.Group>
  );
};

export default BrakeSelectItem;
