import React from 'react';
import IconClear from '@mui/icons-material/Clear';
import IconDone from '@mui/icons-material/Done';

import IconButton from 'components/UI/IconButton';
import {
  RefurbishmentTaskItemIncludingCost,
  RefurbishmentTaskItemState,
} from 'graphqlTypes';

type ServiceWorkItem = RefurbishmentTaskItemIncludingCost & {
  subServiceItems: RefurbishmentTaskItemIncludingCost[];
};

interface Props {
  item: ServiceWorkItem;
}

const TaskListServiceWorkChecker = ({ item }: Props) => {
  const isDeclined =
    item.state === RefurbishmentTaskItemState.Declined ||
    item.subServiceItems.some(
      ({ state }) => state === RefurbishmentTaskItemState.Declined,
    );
  const isDone =
    item.state === RefurbishmentTaskItemState.Done ||
    item.subServiceItems.every(
      ({ state }) => state === RefurbishmentTaskItemState.Done,
    );

  return (
    <div>
      <IconButton
        selected={isDeclined}
        icon={IconClear}
        className="ml-4"
        readOnly
        disabled
        data-qa-selector="buttonDecline"
      />

      <IconButton
        icon={IconDone}
        selected={isDone}
        disabled
        readOnly
        data-qa-selector="buttonApprove"
      />
    </div>
  );
};

export default TaskListServiceWorkChecker;
