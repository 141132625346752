import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash/fp';
import qs from 'qs';

import { useSearchInformMessages } from 'modules/api/informMessage';

const useFilterInformMessages = () => {
  const [filterValues, setFilterValuesInner] = useState<null | Record<
    string,
    any
  >>(null);

  const { search } = useLocation();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

  const { p, sortField, sortDir } = searchParams;

  const page = Math.max(Number(p) - 1 || 0, 0);

  const sort =
    sortField && sortDir
      ? {
          [sortField]: sortDir,
        }
      : null;

  const { data, loading, refetch } = useSearchInformMessages(
    {
      page,
      pageSize: 20,
      filter: filterValues,
      sort,
    },
    {
      skip: !filterValues || !sort,
      pollInterval: 60000,
    },
  );

  const setFilterValues = (values: object) => {
    setFilterValuesInner(values);
    if (isEqual(filterValues, values)) refetch();
  };

  return { data, loading, setFilterValues };
};

export default useFilterInformMessages;
