import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryBrakeDiskConditionsArgs } from 'graphqlTypes';
import BRAKE_DISK_CONDITIONS from './gql/brakeDiskConditions';

const useBrakeDiskConditions = (brakeTypeId: string | undefined) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'brakeDiskConditions'>,
    QueryBrakeDiskConditionsArgs
  >(BRAKE_DISK_CONDITIONS, {
    variables: {
      brakeTypeId: brakeTypeId || '',
    },
    skip: !brakeTypeId,
  });

  return {
    data: get('brakeDiskConditions', data),
    ...rest,
  };
};

export default useBrakeDiskConditions;
