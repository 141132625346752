import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import cns from 'classnames';

import { WorkshopFilter } from 'components/CostTableFilter';
import ServiceTypeSelectControl from 'components/ServiceTypeSelectControl';
import {
  Location,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
  ServiceData,
  Workshop,
} from 'graphqlTypes';
import { addSwPrefixToSubServiceOptions } from 'routes/CostTableRoute/helpers/addSwPrefixToSubServiceOptions';

import cn from './CostTableSearch.module.scss';

type FormData = {
  mainWorkshop?: Workshop;
  serviceType?: {
    name: RefurbishmentServiceName;
    type: RefurbishmentServiceType;
  };
  displaySubServices?: boolean;
};

type Props = {
  allLocations: Array<Location>;
  allServiceTypes: Array<ServiceData>;
};

const CostTableSearch = ({ allLocations, allServiceTypes }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const mainWorkshopBranchId = parseInt(searchParams.get('w') || '0', 10);
  const serviceTypeName = searchParams.get('t') || '';
  const displaySubServices = !!searchParams.get('s');

  const mainWorkshop = allLocations.find(
    location => location.id === mainWorkshopBranchId,
  );
  const serviceType = allServiceTypes.find(
    item => item.name.key === serviceTypeName,
  );

  const { handleSubmit, control, setValue, register, watch } = useForm<
    FormData
  >({
    defaultValues: {
      mainWorkshop: mainWorkshop
        ? {
            branchId: mainWorkshop.id,
            name: mainWorkshop.name,
          }
        : undefined,
      serviceType: serviceType
        ? {
            name: serviceType.name.key,
            type: serviceType.type,
          }
        : undefined,
      displaySubServices,
    },
  });

  const disableDisplaySubServices =
    watch('serviceType')?.name !== RefurbishmentServiceName.ServiceWork;

  const handleOnSubmit = (values: FormData) => {
    if (values.mainWorkshop) {
      searchParams.set('w', `${values.mainWorkshop.branchId}`);
      searchParams.delete('p');
    } else {
      searchParams.delete('w');
    }
    if (values.serviceType) {
      searchParams.set('t', values.serviceType.name);
      searchParams.delete('p');
    } else {
      searchParams.delete('t');
    }
    if (values.displaySubServices) {
      searchParams.set('s', 'true');
      searchParams.delete('p');
    } else {
      searchParams.delete('s');
    }

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  const handleOnServiceChange = () => {
    searchParams.delete('s');
    setValue('displaySubServices', false);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={cn.searchInputContainer}
    >
      <div className={cn.row}>
        <div className={cn.searchField}>
          <WorkshopFilter
            name="mainWorkshop"
            qaSelector="mainWorkshopFilter"
            placeholder={__('costTable.mainWorkshop')}
            control={control}
          />
        </div>

        <div className={cn.searchField}>
          <ServiceTypeSelectControl
            name="serviceType"
            control={control}
            serviceType={[
              RefurbishmentServiceType.Vehicle,
              RefurbishmentServiceType.ServiceBookMainService,
              RefurbishmentServiceType.ServiceBookSubService,
              RefurbishmentServiceType.VehicleServiceCost,
              RefurbishmentServiceType.Overhead,
            ]}
            placeholder={__('costTable.serviceType')}
            required={false}
            useReactSelect
            optionMapping={addSwPrefixToSubServiceOptions}
            onChange={handleOnServiceChange}
          />
        </div>

        <div className={cns(cn.searchField, cn.displaySubServices)}>
          <Form.Check
            {...register('displaySubServices')}
            id="displaySubServices"
            label={__('costTable.displaySubServices')}
            custom
            disabled={disableDisplaySubServices}
          />
        </div>

        <Button variant="primary" type="submit" data-qa-selector="searchButton">
          {__('action.search')}
        </Button>
      </div>
    </form>
  );
};

export default CostTableSearch;
