import qs from 'qs';

import { setupApi } from 'modules/apiRest';
import assert from './assert';
import {
  WRONG_API_ORIGIN_OPTION,
  WRONG_API_ROUTE_OPTION,
} from './assertMessages';

const CLIENT_ID = 'refurbishment';

const GRANT_TYPE_GOOGLE_OAUTH2_TOKEN = 'googleOauth2Token';
const GRANT_TYPE_PASSWORD = 'password';
const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';

const buildEndpoint = (body, headers = {}) => ({
  method: 'POST',
  url: '/v1/auth/oauth/token',
  body: qs.stringify({
    client_id: CLIENT_ID,
    ...body,
  }),
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...headers,
  },
});

export default ({ apiOrigin, apiRoute }) => {
  assert(apiOrigin === undefined, WRONG_API_ORIGIN_OPTION);
  assert(!apiRoute, WRONG_API_ROUTE_OPTION);

  setupApi(
    {
      AUTH_BY_GOOGLE_CREDENTIALS: ({ accessToken, idToken }) =>
        buildEndpoint({
          grant_type: GRANT_TYPE_GOOGLE_OAUTH2_TOKEN,
          google_access_token: accessToken,
          google_refresh_token: idToken,
        }),

      AUTH_BY_USERNAME_AND_PASSWORD: (username, password) =>
        buildEndpoint({
          username,
          password,
          grant_type: GRANT_TYPE_PASSWORD,
        }),

      REFRESH_TOKEN: ({ accessToken, refreshToken }) =>
        buildEndpoint(
          {
            refresh_token: refreshToken,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
          },
          {
            authorization: `Bearer ${accessToken}`,
          },
        ),
    },
    {
      formatUrl: ({ url }) => `${apiOrigin}${apiRoute}${url}`,
    },
  );
};
