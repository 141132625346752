import React from 'react';

import {
  RefurbishmentServiceName,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import {
  AddOptions,
  carParts,
} from 'routes/RefurbishmentRoute/components/WheelCheckpoints/mapData';
import {
  EntryCheckItem,
  PayloadType,
  WheelPosition,
} from 'routes/RefurbishmentRoute/types';
import BrakeYesNoItem from './Common/BrakeYesNoItem';

type Props = {
  items?: EntryCheckItem[];
  disabled: boolean;
  position: WheelPosition;
  setAddOption(otpion: AddOptions): void;
};

const BrakeFluidCapExists = ({
  items,
  disabled,
  position,
  setAddOption,
}: Props) => {
  const entryCheckItem = items?.find(
    item => item.payloadType === PayloadType.DoesFluidCapExists,
  );

  if (!entryCheckItem) return null;

  return (
    <BrakeYesNoItem
      entryCheckItem={entryCheckItem}
      label={__('entryCheck.brakeCheckpoints.brakeFluidCapExists')}
      required
      disabled={disabled}
      qaSelector="brakeFluid"
      onDecline={() =>
        setAddOption({
          addDamage: {
            carPart: carParts.brakeLines[position],
            name: {
              name: RefurbishmentServiceName.Replace,
              type: RefurbishmentServiceType.Damage,
            },
          },
        })
      }
    />
  );
};

export default BrakeFluidCapExists;
