import React, { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import {
  BeautyShot,
  CompositeType,
  EntryCheckEntityType,
  EntryCheckItem,
  EntryCheckItemValue,
  EntryCheckItemValueState,
  TirePressureUnit,
} from 'graphqlTypes';
import useAdImageUploadPromise from 'helpers/adImageUpload/useAdImageUploadPromise';
import { useUpdateEntryCheckItemValues } from 'modules/api/entryCheck';
import { useNotification } from 'modules/notification';
import { BrakeType } from 'routes/RefurbishmentRoute/components/WheelCheckpoints/BrakeCheckpoints/BrakeCheckpoints';
import {
  BrakeCheckpointsPayloadTypes,
  WheelsRimTirePayloadTypes,
  WheelsSuspensionPayloadTypes,
} from 'routes/RefurbishmentRoute/helpers/mapData/getCheckpoints';
import {
  DamageArea,
  DamageSubArea,
  PayloadType,
  SliceKey,
} from 'routes/RefurbishmentRoute/types';
import { filterAreas, filterEntity, filterPayloads } from '../helpers';

const filterBySection = ({
  items,
  section,
}: {
  items: EntryCheckItem[];
  section: SliceKey;
}) => {
  switch (section) {
    case SliceKey.Details:
      return [
        ...items
          .filter(filterEntity(EntryCheckEntityType.Vehicle))
          .filter(
            filterPayloads([
              PayloadType.FuelType,
              PayloadType.Mileage,
              PayloadType.WasInCommercialUse,
              PayloadType.AccidentCar,
            ]),
          ),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Refurbishment))
          .filter(filterPayloads([PayloadType.KeysCount])),
      ];
    case SliceKey.ServiceBook:
      return items
        .filter(filterEntity(EntryCheckEntityType.Refurbishment))
        .filter(filterPayloads([PayloadType.DoesTheCarRequireAService]));
    case SliceKey.Inspection:
      return items
        .filter(filterEntity(EntryCheckEntityType.Inspection))
        .filter(
          filterPayloads([
            PayloadType.InspectionNeeded,
            PayloadType.InspectionLastDate,
            PayloadType.InspectionLastMileage,
            PayloadType.InspectionLastDueDate,
            PayloadType.InspectionDoneBeforeEntryCheck,
            PayloadType.InspectionDidCarPassInspection,
          ]),
        );
    case SliceKey.Front:
      return filterAreas([DamageArea.Body], [DamageSubArea.BodyFront], items);
    case SliceKey.Right:
      return filterAreas(
        [DamageArea.Body],
        [
          DamageSubArea.BodyRight,
          DamageSubArea.FrontRight,
          DamageSubArea.BackRight,
        ],
        items,
      );
    case SliceKey.Back:
      return filterAreas([DamageArea.Body], [DamageSubArea.BodyBack], items);
    case SliceKey.Left:
      return filterAreas(
        [DamageArea.Body],
        [
          DamageSubArea.BodyLeft,
          DamageSubArea.FrontLeft,
          DamageSubArea.BackLeft,
        ],
        items,
      );
    case SliceKey.Roof:
      return filterAreas([DamageArea.Body], [DamageSubArea.BodyRoof], items);
    case SliceKey.Interior:
      return [
        ...filterAreas([DamageArea.Interior], [], items),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Vehicle))
          .filter(
            filterPayloads([
              PayloadType.Smoker,
              PayloadType.OriginalUpholstery,
            ]),
          ),
      ];
    case SliceKey.Underbody:
      return [
        ...filterAreas([DamageArea.Underbody, DamageArea.Engine], [], items),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Vehicle))
          .filter(filterPayloads([PayloadType.OriginalEngine])),
      ];
    case SliceKey.Engine:
      return items.filter(filterEntity(EntryCheckEntityType.Engine));
    case SliceKey.Wheels:
      return [
        ...filterAreas(
          [DamageArea.Tires],
          [
            DamageSubArea.BodyRight,
            DamageSubArea.FrontRight,
            DamageSubArea.BackRight,
            DamageSubArea.BodyLeft,
            DamageSubArea.FrontLeft,
            DamageSubArea.BackLeft,
          ],
          items,
        ),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Damage))
          .filter(filterPayloads([PayloadType.BrakeDamage])),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Vehicle))
          .filter(
            filterPayloads([
              PayloadType.WheelSeason,
              PayloadType.WheelProfile,
              PayloadType.WheelRimType,
              PayloadType.WheelSize,
              PayloadType.WheelRimType,
              PayloadType.WheelRimSize,
              PayloadType.WheelSpeedIndexPayload,
              PayloadType.WheelLoadIndexPayload,
              PayloadType.WheelManufacturerPayload,
              PayloadType.WheelTirePressure,
              PayloadType.WheelDotPayload,
              PayloadType.WheelImagePayload,
              PayloadType.AreRimsAndTiresLicensedForThisCar,
              ...WheelsRimTirePayloadTypes,
              ...WheelsSuspensionPayloadTypes,
              ...BrakeCheckpointsPayloadTypes,
            ]),
          ),
      ];
    case SliceKey.VehicleServices:
      return [
        ...items.filter(filterEntity(EntryCheckEntityType.Service)),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Refurbishment))
          .filter(filterPayloads([PayloadType.BeautyShot])),
      ];
    case SliceKey.BudgetCompletion:
    case SliceKey.Summary:
    default:
      return items;
  }
};

const fillMissingData = (items: EntryCheckItemValue[]) =>
  items.map(value => {
    const payloadType = value.originalValue?.payloadType;

    switch (payloadType) {
      case PayloadType.WheelSeason:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelSeasonPosition,
            season: (value.originalValue as any).season.key || 2205,
          },
        };

      case PayloadType.WheelProfile:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelProfilePosition,
            depth: (value.originalValue as any).depth || 4,
            unit: 'MM',
          },
        };

      case PayloadType.WheelRimType:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelRimTypePosition,
            rimType: (value.originalValue as any).rimType.key || 2202,
          },
        };

      case PayloadType.WheelSize:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelSizePosition,
            height: 20,
            value: '145',
            unit: 'MM',
          },
        };

      case PayloadType.WheelRimSize:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelRimSizePosition,
            value: 13,
            unit: 'IN',
          },
        };

      case PayloadType.WheelSpeedIndexPayload:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelSpeedIndexPosition,
            speedIndex: 'K (110 km/h)',
          },
        };

      case PayloadType.WheelLoadIndexPayload:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelLoadIndexPosition,
            loadIndex: '50',
          },
        };

      case PayloadType.WheelManufacturerPayload:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelManufacturerPosition,
            manufacturer: 'Achilles',
          },
        };

      case PayloadType.WheelTirePressure:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).position,
            valueMinorUnits: 250,
            unit: TirePressureUnit.Bar,
          },
        };

      case PayloadType.WheelDotPayload:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelDotPosition,
            dot: (value.originalValue as any).dot || '1212',
          },
        };

      case PayloadType.WheelImagePayload:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Declined,
          proposedValue: {
            ...value.originalValue,
            position: (value.originalValue as any).wheelImagePosition,
            imageUrl: (value.originalValue as any).wheelImageUrl || null,
          },
        };

      case PayloadType.IsRimInGoodCondition:
      case PayloadType.DoesRimCenterCapExists:
      case PayloadType.IsTireInGoodCondition:
      case PayloadType.IsTireDirectionCorrect:
      case PayloadType.IsTirePressureCorrect:
      case PayloadType.IsWheelBearingOk:
      case PayloadType.AreSpringsOk:
      case PayloadType.IsShockAbsorberOk:
      case PayloadType.DoesBallJointWork:
      case PayloadType.DoesDustCoverExist:
      case PayloadType.IsBumpStopOk:
      case PayloadType.IsStrutBearingOk:
      case PayloadType.AreSupportArmsOk:
      case PayloadType.IsControlArmOk:
      case PayloadType.IsStabilizerBearingOk:
      case PayloadType.AreRimsAndTiresLicensedForThisCar:
      case PayloadType.DoesFluidCapExists:
      case PayloadType.AreBrakeHosesInGoodCondition:
      case PayloadType.IsBrakeCaliperInGoodCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: {
            ...value.originalValue,
            value: true,
          },
        };

      case PayloadType.BrakeType:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: {
            ...value.originalValue,
            brakeType: BrakeType.DISK,
          },
        };

      case PayloadType.BrakePadCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: {
            ...value.originalValue,
            condition:
              'brake_pad_condition_greater_than_or_equal_0_and_less_than_25_percent',
          },
        };

      case PayloadType.BrakeDiskCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: {
            ...value.originalValue,
            condition: 'disk_greater_than_or_equal_0_and_less_than_25_percent',
          },
        };

      case PayloadType.BeautyShot:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: {
            ...value.originalValue,
            beautyShot: BeautyShot.AfterRefurbishment,
          },
        };

      case PayloadType.KeysCount:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            keysCount: 1,
          },
        };

      case PayloadType.InspectionNeeded:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            inspectionNeeded: false,
          },
        };

      case PayloadType.InspectionLastDate:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            lastDate: null,
          },
        };

      case PayloadType.InspectionLastMileage:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            lastDistance: null,
            lastUnit: null,
          },
        };

      case PayloadType.InspectionLastDueDate:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            lastDueDate: null,
          },
        };

      case PayloadType.InspectionDoneBeforeEntryCheck:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            doneBeforeEntryCheck: null,
          },
        };

      case PayloadType.InspectionDidCarPassInspection:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            didCarPassInspection: null,
          },
        };

      case PayloadType.DoesHoodRelease:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            doesHoodRelease: true,
          },
        };
      case PayloadType.BlanketInsulatorAvailable:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            hasBlanketInsulator: true,
          },
        };
      case PayloadType.OilCoolerCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isOilCoolerConditionOk: true,
          },
        };
      case PayloadType.OilLevel:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            oilLevelType: 'MIDDLE',
          },
        };
      case PayloadType.Battery:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            age: {
              week: '1',
              year: '2023',
            },
            chargeLoad: '100',
            qualityType: 'GOOD',
            voltage: '12',
          },
        };
      case PayloadType.AlternatorCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isAlternatorConditionOk: true,
          },
        };
      case PayloadType.WindshieldFluidLevel:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isWindshieldFluidLevelOk: true,
          },
        };
      case PayloadType.PowerSteeringCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isPowerSteeringConditionOk: true,
          },
        };
      case PayloadType.IgnitionSystemOperationCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isIgnitionSystemOperationConditionOk: 'YES',
          },
        };
      case PayloadType.EngineRunning:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            doesEngineRunning: true,
          },
        };
      case PayloadType.EngineSound:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            comment: null,
            doesEngineSoundNormalNoise: true,
            engineSoundType: null,
          },
        };
      case PayloadType.Intercooler:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isIntercoolerConditionOk: true,
          },
        };
      case PayloadType.FuelSystem:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isFuelSystemConditionOk: true,
          },
        };
      case PayloadType.VBelt:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isVBeltConditionOk: true,
          },
        };
      case PayloadType.Cambelt:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isCambeltConditionOk: 'YES',
          },
        };
      case PayloadType.TimingChain:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isTimingChainConditionOk: 'YES',
          },
        };
      case PayloadType.AcSystemLeaks:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            hasACSystemLeaks: true,
          },
        };
      case PayloadType.AacCondenser:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isCondenserConditionOk: true,
          },
        };
      case PayloadType.AbsOpticalCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isABSOpticalOk: true,
          },
        };
      case PayloadType.BrakeFluid:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            brakeFluidLevel: 'MIDDLE',
            condition: 2,
          },
        };
      case PayloadType.BrakeMasterCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isBrakeMasterOk: true,
          },
        };
      case PayloadType.Coolant:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            coolantLevel: 'MIDDLE',
            temperature: '-25',
          },
        };
      case PayloadType.CoolantRecoveryCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isCoolantRecoveryOk: true,
          },
        };
      case PayloadType.CoolantHosesCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isCoolantHosesOk: true,
          },
        };
      case PayloadType.RadiatorCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isRadiatorOk: true,
          },
        };
      case PayloadType.RadiatorFanCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isRadiatorFanOk: true,
          },
        };
      case PayloadType.WaterLeaksCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            hasWaterLeaks: true,
          },
        };
      case PayloadType.WaterPumpCondition:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            isWaterPumpOk: true,
          },
        };

      case PayloadType.DoesTheCarRequireAService:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          originalValue: {
            ...value.originalValue,
            checkValue: false,
          },
        };

      default:
        return {
          id: value.id,
          state: EntryCheckItemValueState.Confirmed,
          proposedValue: null,
        };
    }
  });

const fillMissingImages = async (
  items: ReturnType<typeof fillMissingData>,
  uploadFile: Function,
) => {
  const wheelImage = await fetch(
    process.env.NODE_ENV === 'development'
      ? `/mockData/wheelImage.jpg`
      : `/${__LOCALE__}/mockData/wheelImage.jpg`,
  )
    .then(result => result.arrayBuffer())
    .then(buffer => new File([buffer], 'image.jpg', { type: 'image/jpeg' }));

  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (item.proposedValue && (item.proposedValue as any).imageUrl === null) {
      // eslint-disable-next-line no-await-in-loop
      const result = await uploadFile(wheelImage);
      (item.proposedValue as any).imageUrl = result?.[0]?.url || null;
    }
  }
  return items;
};

interface Props extends ButtonProps {
  items: EntryCheckItem[];
  section: SliceKey;
  adId: string;
}

const ApproveEntryCheckItems = ({ items, section, adId, ...rest }: Props) => {
  const [updateEntryCheckItemValue] = useUpdateEntryCheckItemValues();
  const { info } = useNotification();
  const { uploadFile } = useAdImageUploadPromise({
    adId,
    compositeType: CompositeType.Wheel,
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const filteredItems = filterBySection({ items, section });
    const values = filteredItems.reduce((acc: EntryCheckItemValue[], item) => {
      acc.push(
        ...item.values.filter(
          ({ state, originalValue }) =>
            state === EntryCheckItemValueState.Created ||
            originalValue?.payloadType === PayloadType.BeautyShot ||
            originalValue?.payloadType ===
              PayloadType.DoesTheCarRequireAService,
        ),
      );
      return acc;
    }, []);

    const filledValues = fillMissingData(values);
    const filledValuesImages = await fillMissingImages(
      filledValues,
      uploadFile,
    );

    const inputs = filledValuesImages.map(
      ({ id, state, originalValue, proposedValue }) => {
        if (proposedValue) {
          return {
            id,
            value: { state, proposedValue },
          };
        }

        return {
          id,
          value: { state, originalValue },
        };
      },
    );

    if (inputs.length === 0) {
      info('Nothing to approve in this section.');
      setLoading(false);
      return;
    }

    try {
      await updateEntryCheckItemValue(inputs);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Button
      onClick={handleClick}
      variant="outline-danger"
      {...rest}
      disabled={loading}
    >
      {loading ? 'Loading' : 'Approve All'}
    </Button>
  );
};

export default ApproveEntryCheckItems;
