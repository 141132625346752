import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryEntryCheckAuthorizationArgs } from 'graphqlTypes';
import ENTRY_CHECK_AUTHORIZATION from './gql/entryCheckAuthorization';

const useGetEntryCheckForAuthorization = (refurbishmentId: string = '') => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'entryCheckAuthorization'>,
    QueryEntryCheckAuthorizationArgs
  >(ENTRY_CHECK_AUTHORIZATION, {
    variables: { refurbishmentId },
    fetchPolicy: 'cache-and-network',
    skip: !refurbishmentId,
  });

  return {
    data: get(['entryCheckAuthorization'], data),
    ...rest,
  };
};

export default useGetEntryCheckForAuthorization;
