import { useQuery } from '@apollo/react-hooks';

import { Query, QueryDocumentCategoriesArgs } from 'graphqlTypes';
import DOCUMENT_CATEGORIES from './gql/documentCategories';

const TOP_CATEGORY = 'REFURBISHMENT';
const CATEGORY_NAME = 'REFURBISHMENT PROVIDER';

const useGetDocumentCategories = (id: string, country: string) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'documentCategories'>,
    QueryDocumentCategoriesArgs
  >(DOCUMENT_CATEGORIES, {
    variables: { country, topCategory: TOP_CATEGORY },
    fetchPolicy: 'cache-first',
  });

  return {
    data: data?.documentCategories?.categories?.find(
      ({ name }) => name === CATEGORY_NAME,
    ),
    ...rest,
  };
};

export default useGetDocumentCategories;
