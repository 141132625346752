import gql from 'graphql-tag';

export const VehicleServiceCostFragment = gql`
  fragment VehicleServiceCostFragment on VehicleServiceCost {
    id
    refurbishmentPartner {
      id
      name
    }
    mainWorkshop {
      id
      name
    }
    location {
      id
      name
    }
    vehicleServiceName
    serviceCost {
      totalPriceMinorUnits
      laborRateMinorUnits
      laborTimeHours
      materialCostMinorUnits
      sparePartCostMinorUnits
      consumableCostMinorUnits
      laborTotalCostMinorUnits
      currencyCode
    }
    active
    isDefault
    isAutoCreate
  }
`;

export const RepairCostFragment = gql`
  fragment RepairCostFragment on RepairCost {
    id
    area
    part
    repairMethod
    mainWorkshop {
      id
      name
    }
    repairWorkshop {
      id
      name
    }
    repairPartner {
      id
      name
    }
    cost {
      currencyCode
      laborRateMinorUnits
      laborTimeHours
      materialCostMinorUnits
      sparePartCostMinorUnits
      consumableCostMinorUnits
      laborTotalCostMinorUnits
      totalPriceMinorUnits
    }
    isDefault
    active
  }
`;

export const RepairMethodFragment = gql`
  fragment RepairMethodFragment on RepairMethod {
    id
    mainWorkshop {
      id
      name
    }
    area
    damageType
    part
    attribute1Name
    attribute1Value
    attribute2Name
    attribute2Value
    quantityThreshold
    repairMethodBelowThreshold
    repairMethodEqualOrAboveThreshold
    active
  }
`;

export const RepairCostImportTaskBasicFragment = gql`
  fragment RepairCostImportTaskBasicFragment on ImportTask {
    id
    name
    uri
    type
    state
    createdOn
    statistics {
      total
      created
      updated
      duplicated
      failed
    }
    createdBy {
      firstName
      lastName
    }
  }
`;
