import {
  PartnerCreateInput,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
  Workshop,
} from 'graphqlTypes';

export enum CostTableRowMode {
  NEW,
  EDIT,
  DISPLAY,
  DISABLED,
}

export type RowFormData = {
  laborTime: number;
  laborRate: number;
  totalPriceMinorUnits: number;
  consumableCost: number;
  sparePartCost: number;
  active: boolean;
  isDefault: boolean;
  isAutoCreate: boolean;
};

export type CostTableWorkshop = Workshop & {
  countryCode: string;
};

export type NewRowFormData = RowFormData & {
  refurbishmentPartner?: PartnerCreateInput;
  mainWorkshop: CostTableWorkshop;
  location?: Workshop;
  vehicleServiceName: {
    name: RefurbishmentServiceName;
    type: RefurbishmentServiceType;
  };
  currencyCode: string;
};
