import { MutationFunctionOptions } from '@apollo/react-common';
import { useMutation } from '@apollo/react-hooks';
import { PureQueryOptions } from 'apollo-client';

import {
  Mutation,
  MutationUpdateQualityCheckVehicleInspectionItemArgs,
} from 'graphqlTypes';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';
import UPDATE_VEHICLE_INSPECTION from './gql/updateQualityCheckVehicleInspectionItem';

type UpdateMutation = Pick<Mutation, 'updateQualityCheckVehicleInspectionItem'>;

type UpdateArgs = MutationFunctionOptions<
  UpdateMutation,
  MutationUpdateQualityCheckVehicleInspectionItemArgs
> & { refurbishmentId: string };

const useUpdateQualityCheckVehicleInspectionItem = () => {
  const [mutation, options] = useMutation(UPDATE_VEHICLE_INSPECTION);
  const updateVehicleInspection = ({
    refurbishmentId,
    ...rest
  }: UpdateArgs) => {
    const refetchQueries: (string | PureQueryOptions)[] =
      rest.refetchQueries && !(rest.refetchQueries instanceof Function)
        ? rest.refetchQueries
        : [];

    mutation({
      ...rest,
      refetchQueries: refetchQueries.concat([
        {
          query: REFURBISHMENT_DETAILS,
          variables: {
            id: refurbishmentId,
          },
        },
      ]),
    });
  };

  return [updateVehicleInspection, options] as const;
};

export default useUpdateQualityCheckVehicleInspectionItem;
