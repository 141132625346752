import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import cns from 'classnames';

import cn from './CustomModal.module.scss';

export type ModalSize = 'sm' | 'ml' | 'lg' | 'xl';

type Props = Omit<ModalProps, 'size'> & {
  size?: ModalSize;
  className?: string;
  children: React.ReactNode;
  qaSelector?: string;
};

const CustomModal = ({
  className,
  size,
  children,
  qaSelector,
  dialogClassName,
  ...rest
}: Props) => {
  const sizeClass = size === 'ml' ? `modal-${size}` : '';

  return (
    <Modal
      {...rest}
      size={size === 'ml' ? undefined : size}
      className={cns(className, cn.modal)}
      dialogClassName={cns(dialogClassName, sizeClass)}
      data-qa-selector={qaSelector}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
