import { useMemo, useRef } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, from as ApolloLinkFrom } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';
import introspectionQueryResultData from 'graphqlFragmentTypes.json';
import { get, keys, map, set } from 'lodash/fp';
import PropTypes from 'prop-types';

import { initialState } from 'constants/appstate';
import { useAuth } from 'modules/auth';
import { LOCALE } from 'modules/i18n';
import { useNotification } from 'modules/notification';
import promiseToObservable from './promiseToObservable';

const typeDefs = gql`
  extend type Query {
    getCurrentMainWorkshop: CurrentMainWorkshop
    getCurrentFilter: CurrentFilter
  }

  type CurrentMainWorkshop {
    branchId: Int!
    name: String!
  }

  type CurrentFilter {
    refurbishment: String!
    exitcheck: String!
  }
`;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const dataIdFromObject = object => {
  // eslint-disable-next-line no-underscore-dangle
  switch (object.__typename) {
    case 'Vehicle':
      return `Vehicle:${object.vin}${object.stockNumber}`;
    default:
      return defaultDataIdFromObject(object);
  }
};

const useApolloClient = ({ resolvers }) => {
  const { info, tryToRefreshAuthOncePerTime } = useAuth();
  const notify = useNotification();
  const infoRef = useRef();

  infoRef.current = info;

  const memoizedClient = useMemo(() => {
    const cache = new InMemoryCache({ fragmentMatcher, dataIdFromObject });

    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_API,
      fetch: (uri, options) => {
        return fetch(uri, {
          ...options,
          keepalive: true,
        });
      },
    });

    const batchHttpLink = new BatchHttpLink({
      uri: process.env.REACT_APP_API,
    });

    const frontendVersion = process.env.REACT_APP_INFO_VERSION || 'unknown';

    const versionLink = new ApolloLink((operation, forward) => {
      const oldHeaders = operation.getContext().headers;

      operation.setContext({
        headers: {
          ...oldHeaders,
          'x-carol-frontend-version': frontendVersion,
        },
      });

      return forward(operation).map(data => {
        const { response } = operation.getContext();

        const backendVersion =
          response?.headers?.get('x-carol-backend-version') || 'unknown';

        if (
          backendVersion !== 'unknown' &&
          frontendVersion !== backendVersion
        ) {
          notify.error(__('new.version.available'));
        }

        return data;
      });
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        const {
          disableGQLErrorNotifications,
          response,
        } = operation.getContext();

        const traceId = response?.headers?.get('x-b3-traceid');

        if (!disableGQLErrorNotifications && graphQLErrors) {
          graphQLErrors.forEach(({ message }) => {
            let msg = message || __('error.unexpected');
            if (traceId) {
              msg += `. Trace id: ${traceId}`;
            }
            notify.error(msg);
          });
        }

        if (networkError) {
          const error = get(['result', 'error'], networkError) || networkError;

          switch (error) {
            case 'Unauthorized':
            case 'invalid_token': {
              return promiseToObservable(tryToRefreshAuthOncePerTime()).flatMap(
                ({ access_token: token } = {}) => {
                  if (!token) {
                    return;
                  }

                  operation.token = token; // eslint-disable-line no-param-reassign

                  return forward(operation);
                },
              );
            }
            default:
              notify.error(
                `[Network error] ${networkError}. Trace id: ${traceId}`,
              );
          }
        }
      },
    );

    const authLink = new ApolloLink((operation, forward) => {
      const token = operation.token || get(['current', 'accessToken'], infoRef);

      const oldHeaders = operation.getContext().headers;

      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: token ? `Bearer ${token}` : '',
        },
      });

      return forward(operation);
    });

    const localeLink = new ApolloLink((operation, forward) => {
      const oldHeaders = operation.getContext().headers;

      operation.setContext({
        headers: {
          ...oldHeaders,
          'Accept-Language': LOCALE,
        },
      });

      return forward(operation);
    });

    const translationLink = new ApolloLink((operation, forward) =>
      forward(operation).map(response => {
        let data = get(['data'], response);
        const errors = get('errors', response);
        const translations = get(['extensions', 'translations'], response);

        if (data && translations) {
          map(key => {
            data = set(key, {
              key: get(key, data),
              translation: translations[key],
            })(data);
          })(keys(translations));
        }

        return { data, errors };
      }),
    );

    const link = ApolloLinkFrom([
      versionLink,
      errorLink,
      authLink,
      localeLink,
      translationLink,
    ]).split(
      operation => !!operation.getContext()?.disableBatch,
      httpLink,
      batchHttpLink,
    );

    const client = new ApolloClient({
      cache,
      link,
      typeDefs,
      resolvers,
    });

    cache.writeData({
      data: initialState,
    });

    return client;
  }, [info.isAuthorized]);

  return memoizedClient;
};

const ApolloProviderClient = ({ children, resolvers }) => {
  const client = useApolloClient({ resolvers });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

ApolloProviderClient.propTypes = {
  children: PropTypes.node.isRequired,
  resolvers: PropTypes.object,
};

ApolloProviderClient.defaultProps = {
  resolvers: {},
};

export default ApolloProviderClient;
