import gql from 'graphql-tag';

import { RepairCostFragment } from './fragments';

export default gql`
  query repairCostSearch(
    $page: Int!
    $pageSize: Int!
    $filter: RepairCostFilter
    $sort: RepairCostSortInput
  ) {
    repairCostSearch(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...RepairCostFragment
      }
      pageInfo {
        page
        pageSize
        totalPageCount
        totalEntityCount
      }
    }
  }
  ${RepairCostFragment}
`;
