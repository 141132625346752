import React from 'react';
import { Button, Collapse } from 'react-bootstrap';
import cns from 'classnames';

import arrowDown from 'images/arrow-down.svg';
import arrowUp from 'images/arrow-up.svg';

import cn from './CollapsableContent.module.scss';

type Props = {
  name: string;
  children: React.ReactNode;
  header: React.ReactNode;
  collapsed: boolean;
  className?: string;
  dataQaSelector?: string;
  toggleClicked?: () => void;
};

/**
 * @deprecated use `CollapsableSection`
 */
const CollapsableContent = ({
  name,
  children,
  header,
  collapsed,
  className,
  dataQaSelector,
  toggleClicked,
}: Props) => {
  const collapseId = `collapsableSection-${name}`;
  const toggleImg = collapsed ? arrowDown : arrowUp;

  return (
    <div className={className} data-qa-selector={dataQaSelector}>
      <div className="d-flex align-items-baseline">
        {header}
        <Button
          variant="link"
          aria-controls={collapseId}
          aria-expanded={!collapsed}
          className={cns(cn.whiteSpace, 'button-collapse')}
          onClick={() => toggleClicked && toggleClicked()}
          data-qa-selector={`${collapseId}-button`}
        >
          <img src={toggleImg} alt="" />
        </Button>
      </div>
      <Collapse in={!collapsed}>
        <div id={collapseId}>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsableContent;
