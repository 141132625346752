import { components, MultiValueGenericProps } from 'react-select';

const createReactSelectMultiValueContainer = (
  selector: string,
  dataQaOptionHandler?: (value: any) => string,
) => (props: MultiValueGenericProps) => {
  const {
    data: { value },
  } = props;

  let v = value;

  if (dataQaOptionHandler) {
    v = dataQaOptionHandler(value);
  } else if (v instanceof Object && v.name) {
    v = v.name;
  }

  return (
    <div data-qa-selector={`${selector}_${v}`}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

export default createReactSelectMultiValueContainer;
