import { createContext } from 'react';
import { noop } from 'lodash';

type ContextData = {
  urlToObjectUrl: Map<string, string>;
  storeUploadedImage(url: string, objectUrl: string): void;
  bucketName: string;
  cdn: string;
};

const ImageStorageContext = createContext<ContextData>({
  urlToObjectUrl: new Map<string, string>(),
  storeUploadedImage: noop,
  bucketName: '',
  cdn: '',
});

export default ImageStorageContext;
