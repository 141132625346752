import gql from 'graphql-tag';

export default gql`
  query qualityChecks($refurbishmentId: UUID!) {
    qualityChecks(refurbishmentId: $refurbishmentId) {
      id
      state
      createdOn
      tags
      phases {
        id
        name
        state
        createdOn
        updatedBy {
          firstName
          lastName
        }
      }
    }
  }
`;
