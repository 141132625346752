import {
  GroupedPartnerWorkshopConnection,
  PartnerWorkshopConnection,
} from 'graphqlTypes';
import { PartnerWorkshopItem } from '../../types';

const mapData = (input: Array<GroupedPartnerWorkshopConnection>) => {
  const items = input?.[0].groupedPartnerWorkshopConnections.reduce(
    (
      acc: Array<PartnerWorkshopItem>,
      current: Array<PartnerWorkshopConnection>,
    ) => {
      const { mainWorkshop } = current[0];
      const { repairWorkshop } = current[0];
      const refurbishmentPartner = current.map(
        (connection: PartnerWorkshopConnection) => ({
          idConnection: connection.id,
          ...connection.repairPartner,
        }),
      );

      acc.push({
        mainWorkshop,
        repairWorkshop,
        repairPartners: refurbishmentPartner,
      });

      return acc;
    },
    [],
  );

  return items;
};

export default mapData;
