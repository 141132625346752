import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import BRAKE_TYPES from './gql/brakeTypes';

const useBrakeTypes = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'brakeTypes'>, null>(
    BRAKE_TYPES,
  );

  return {
    data: get('brakeTypes', data),
    ...rest,
  };
};

export default useBrakeTypes;
