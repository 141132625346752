import { Route, Routes } from 'react-router-dom';

import { Role, RoleAccess } from 'modules/acl';
import { TaskItemRoute } from './TaskItemRoute';
import TaskListRoute from './TaskListRoute';

const RefurbishmentTaskRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RoleAccess roles={Role.TASK_READ}>
            <TaskListRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id"
        element={
          <RoleAccess roles={Role.TASK_READ}>
            <TaskItemRoute />
          </RoleAccess>
        }
      />
    </Routes>
  );
};

export default RefurbishmentTaskRoute;
