// .env example: REACT_APP_ENABLE_FEATURE_XYZ=YES
export enum FeatureFlag {
  DEVELOPER_MODE = 'REACT_APP_ENABLE_DEVELOPER_MODE',
  CLIENT_SIDE_LOGS = 'REACT_APP_ENABLE_CLIENT_SIDE_LOGS',
  IMPROVED_TIRE_DATA_ACCURACY = 'REACT_APP_ENABLE_IMPROVED_TIRE_DATA_ACCURACY',
  CAT_SCAN = 'REACT_APP_ENABLE_CAT_SCAN',
}

// .env example: REACT_APP_ENABLE_FEATURE_XYZ_LOCATIONS=1,2,3
export enum LocationFlag {
  CAT_SCAN_LOCATIONS = 'REACT_APP_ENABLE_CAT_SCAN_LOCATIONS',
}
