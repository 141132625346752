import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import LOAD_INDEXES from './gql/loadIndexes';

const useLoadIndexes = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'loadIndexes'>, null>(
    LOAD_INDEXES,
  );

  return {
    data: get('loadIndexes', data),
    ...rest,
  };
};

export default useLoadIndexes;
