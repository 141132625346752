export const enum EntryCheckPhase {
  Import = 'import',
  EntryCheck = 'entryCheck',
}

export const enum EntryCheckAuthPhase {
  Import = 'import',
  EntryCheck = 'entryCheck',
  EntryCheckAuth = 'entryCheckAuth',
}

export const enum EntryCheckPostAuthPhase {
  BeforePostAuth = 'beforePostAuth',
  EntryCheckPostAuth = 'entryCheckPostAuth',
}

export const enum QualityCheckPhase {
  Import = 'import',
  QualityCheckReview = 'qualityCheckReview',
}

export const enum QualityCheckApprovalPhase {
  Import = 'import',
  QualityCheckReview = 'qualityCheckReview',
  QualityCheckApproval = 'qualityCheckApproval',
}
