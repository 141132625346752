import gql from 'graphql-tag';

import { PartnerWorkshopConnectionRefurbishmentResponseFragment } from 'modules/api/refurbishments/gql/fragments';

export default gql`
  mutation addRefurbishmentPartnerWorkshopConnections(
    $refurbishmentId: UUID!
    $refurbishmentPartnerWorkshopConnectionAdditionInput: RefurbishmentPartnerWorkshopConnectionAdditionInput!
  ) {
    addRefurbishmentPartnerWorkshopConnections(
      refurbishmentId: $refurbishmentId
      refurbishmentPartnerWorkshopConnectionAdditionInput: $refurbishmentPartnerWorkshopConnectionAdditionInput
    ) {
      ...PartnerWorkshopConnectionRefurbishmentResponseFragment
    }
  }
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
`;
