import Text from 'components/UI/Text';

type Props = {
  stockNumber: string;
  refId: string;
  vin: string;
  make: string;
  model: string;
  subModel: string;
};

const VehicleInfo = ({
  stockNumber,
  refId,
  vin,
  make,
  model,
  subModel,
}: Props) => {
  const formattedModel = `${make} ${model} ${subModel}`;

  return (
    <div className="vehicleInfo">
      <div>
        <Text weight="bold" size="l" qaSelector="entryCheckStockNumber">
          {stockNumber} - {formattedModel}
        </Text>
      </div>

      <div className="mt-2">
        <div>
          <Text>Refurbishment nº: </Text>
          <Text weight="bold" qaSelector="entryCheckRefurbishmentNumber">
            {refId}
          </Text>
        </div>
        <div>
          <Text>VIN: </Text>
          <Text weight="bold" qaSelector="entryCheckVin">
            {vin}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default VehicleInfo;
