import React from 'react';
import { Table } from 'react-bootstrap';

import { ImportTask, PageInfo } from 'graphqlTypes';
import ImportRow from './ImportRow';

type Props = {
  data: {
    items: ImportTask[];
    pageInfo: PageInfo;
  };
  onDownloadFile: (id: string) => void;
};

const ImportHistoryTable = ({ data, onDownloadFile }: Props) => {
  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>{__('repairCostAndMethod.fileName')}</th>
          <th>{__('repairCostAndMethod.uploadedOn')}</th>
          <th>{__('repairCostAndMethod.userName')}</th>
          <th>{__('repairCostAndMethod.downloadLink')}</th>
        </tr>
      </thead>
      <tbody>
        {data.items.map((item, index) => (
          <ImportRow
            key={item.id}
            item={item}
            order={data.pageInfo.page * data.pageInfo.pageSize + index + 1}
            onDownloadFile={onDownloadFile}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ImportHistoryTable;
