import { useQuery } from '@apollo/react-hooks';

import { Query, QueryProductionOverviewArgs } from 'graphqlTypes';
import PRODUCTION from './gql/production';

const useGetProduction = (variables: QueryProductionOverviewArgs) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'productionOverview'>,
    QueryProductionOverviewArgs
  >(PRODUCTION, {
    variables,
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.productionOverview,
    ...rest,
  };
};

export default useGetProduction;
