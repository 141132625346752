import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  Item,
  ItemExtended,
  Mutation,
  MutationUpdateQualityCheckServiceItemArgs,
  RefurbishmentServiceState,
  ServiceItemPayload,
  ServiceItemUpdateInput,
} from 'graphqlTypes';
import { getRefurbishmentItemIdFromCache } from './common/getRefurbishmentItemIdFromCache';
import { ItemExtendedFragmentQC, ItemFragmentQC } from './gql/fragments';
import QUALITY_CHECK_REVIEW_ITEMS from './gql/qualityCheckReviewItems';
import UPDATE_QUALITY_CHECK_SERVICE_ITEM from './gql/updateQualityCheckServiceItem';

const useUpdateQualityCheckServiceItem = (reviewId?: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckServiceItem'>,
    MutationUpdateQualityCheckServiceItemArgs
  >(UPDATE_QUALITY_CHECK_SERVICE_ITEM);
  const { cache } = useApolloClient();

  const updateQualityCheckServiceItem = (
    itemId: string,
    serviceItemUpdateInput: ServiceItemUpdateInput,
  ) => {
    const item = cache.readFragment<Item>({
      id: `Item:${itemId}`,
      fragment: ItemFragmentQC,
      fragmentName: 'ItemFragmentQC',
    });

    const refurbishmentItemId = reviewId
      ? getRefurbishmentItemIdFromCache(cache, reviewId)
      : undefined;

    return mutation({
      variables: {
        itemId,
        serviceItemUpdateInput,
      },
      update(updateCache, { data }) {
        const itemExtended = updateCache.readFragment<ItemExtended>({
          id: `ItemExtended:${itemId}`,
          fragment: ItemExtendedFragmentQC,
          fragmentName: 'ItemExtendedFragmentQC',
        });

        if (
          itemExtended &&
          itemExtended.attachedTo &&
          data?.updateQualityCheckServiceItem
        ) {
          updateCache.writeFragment<ItemExtended>({
            id: `ItemExtended:${itemId}`,
            fragment: ItemExtendedFragmentQC,
            fragmentName: 'ItemExtendedFragmentQC',
            data: {
              ...itemExtended,
              ...data.updateQualityCheckServiceItem,
              // eslint-disable-next-line no-underscore-dangle
              __typename: itemExtended.__typename,
              data: {
                ...itemExtended.data,
                ...data.updateQualityCheckServiceItem.data,
              } as ServiceItemPayload,
            },
          });
        }
      },
      optimisticResponse: item
        ? {
            updateQualityCheckServiceItem: {
              ...item,
              data: {
                ...item.data,
                serviceState: serviceItemUpdateInput.state,
                stateComment: serviceItemUpdateInput.stateComment,
              } as ServiceItemPayload & {
                serviceState: RefurbishmentServiceState;
              },
            },
          }
        : undefined,
      refetchQueries: refurbishmentItemId
        ? [
            {
              query: QUALITY_CHECK_REVIEW_ITEMS,
              variables: {
                ids: [refurbishmentItemId],
              },
            },
          ]
        : undefined,
    });
  };

  return [updateQualityCheckServiceItem, options] as const;
};

export default useUpdateQualityCheckServiceItem;
