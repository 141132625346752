import gql from 'graphql-tag';

import FRAGMENT_USER_FIELDS from './fragmentUserFields';

export default gql`
  query user($id: UUID!) {
    user(id: $id) {
      ...userFields
    }
  }
  ${FRAGMENT_USER_FIELDS}
`;
