import { PartnerWorkshopConnectionRefurbishmentResponse } from 'graphqlTypes';
import useVehicleServiceCostSearchPromise from 'modules/api/costTable/usePromiseVehicleServiceCostSearch';

const useGetDefaultServiceCosts = () => {
  const getVehicleServiceCostPromise = useVehicleServiceCostSearchPromise();

  const getDefaultVehicleServiceCosts = async (
    serviceName?: string,
    partnerWorkshopConnection?: PartnerWorkshopConnectionRefurbishmentResponse | null,
  ) => {
    const vehicleServiceCostData = await getVehicleServiceCostPromise({
      page: 0,
      pageSize: 10,
      filter: {
        locationId: partnerWorkshopConnection?.repairWorkshop.branchId,
        partnerId:
          partnerWorkshopConnection?.repairPartner.refurbishmentPartnerId,
        vehicleServiceName: serviceName,
      },
    });

    if (vehicleServiceCostData) {
      const defaultCostItem = vehicleServiceCostData.items.find(
        item => item?.active && item?.isDefault,
      )?.serviceCost;
      if (defaultCostItem) {
        return defaultCostItem;
      }
      return vehicleServiceCostData.items.find(item => item?.active)
        ?.serviceCost;
    }
  };

  return getDefaultVehicleServiceCosts;
};

export default useGetDefaultServiceCosts;
