import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import { EntryCheckEntityType, EntryCheckItem } from 'graphqlTypes';
import { useUpdateEntryCheckItems } from 'modules/api/entryCheck';
import {
  filterAreas,
  filterEntity,
  filterPayloads,
} from 'modules/developerMode/helpers';
import { useNotification } from 'modules/notification';
import { StepName } from 'routes/RefurbishmentRoute/EntryCheckAuthorizationRoute/types';
import {
  DamageArea,
  DamageSubArea,
  PayloadType,
} from 'routes/RefurbishmentRoute/types';

const filterBySection = ({ items, section }: Props) => {
  switch (section) {
    case StepName.DetailsWheels:
      return [
        ...items.filter(filterEntity(EntryCheckEntityType.Vehicle)),
        ...items.filter(filterEntity(EntryCheckEntityType.Refurbishment)),
        ...items
          .filter(filterEntity(EntryCheckEntityType.Damage))
          .filter(filterPayloads([PayloadType.BrakeDamage])),
        ...filterAreas(
          [DamageArea.Tires],
          [
            DamageSubArea.BodyRight,
            DamageSubArea.FrontRight,
            DamageSubArea.BackRight,
            DamageSubArea.BodyLeft,
            DamageSubArea.FrontLeft,
            DamageSubArea.BackLeft,
          ],
          items,
        ),
      ];
    case StepName.Damages:
      return items
        .filter(filterEntity(EntryCheckEntityType.Damage))
        .filter(item => !filterPayloads([PayloadType.BrakeDamage])(item));
    case StepName.Engine:
      return items.filter(filterEntity(EntryCheckEntityType.Engine));
    case StepName.VehicleServices:
      return items.filter(filterEntity(EntryCheckEntityType.Service));
    case StepName.Summary:
    case StepName.CustomerSummary:
    default:
      return items;
  }
};

interface Props extends ButtonProps {
  items: EntryCheckItem[];
  section: StepName;
}

const ApproveAuthorizationItems = ({ items, section, ...rest }: Props) => {
  const [updateEntryCheckItems] = useUpdateEntryCheckItems();
  const { info } = useNotification();

  const filteredItems = filterBySection({ items, section });

  const handleClick = async () => {
    const getPayload = (item: EntryCheckItem) =>
      (item.values?.[0].proposedValue || item.values?.[0].originalValue)
        ?.payloadType;

    const inputs = filteredItems
      .filter(
        item =>
          item.verified === null ||
          (item.verified === false &&
            getPayload(item) === PayloadType.DamageCustomerDisplay),
      )
      .map(item => ({
        id: item.id,
        value: { verified: true },
      }));

    if (inputs.length === 0) {
      info('Nothing to approve in this section.');
      return;
    }

    try {
      await updateEntryCheckItems(inputs);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Button onClick={handleClick} variant="outline-danger" {...rest}>
      Approve All
    </Button>
  );
};

export default ApproveAuthorizationItems;
