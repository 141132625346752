import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import {
  EntryCheckItemCreateInput,
  Mutation,
  MutationCreateEntryCheckItemsArgs,
  Query,
  QueryEntryCheckPostAuthorizationArgs,
} from 'graphqlTypes';
import CREATE_ENTRY_CHECK_ITEMS_FOR_POST_AUTHORIZATION from './gql/createEntryCheckItemsForPostAuthorization';
import ENTRY_CHECK_POST_AUTHORIZATION from './gql/entryCheckPostAuthorization';

const useCreateEntryCheckItemsForPostAuthorization = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createEntryCheckItemsForPostAuthorization'>,
    MutationCreateEntryCheckItemsArgs
  >(CREATE_ENTRY_CHECK_ITEMS_FOR_POST_AUTHORIZATION);

  const createEntryCheckItem = (
    inputs: EntryCheckItemCreateInput[],
    refurbishmentId: string,
  ) =>
    mutation({
      variables: { inputs },
      update(cache, { data: updatedData }) {
        const entryCheck = cache.readQuery<
          Pick<Query, 'entryCheckPostAuthorization'>,
          QueryEntryCheckPostAuthorizationArgs
        >({
          query: ENTRY_CHECK_POST_AUTHORIZATION,
          variables: {
            refurbishmentId,
          },
        });

        if (entryCheck && updatedData) {
          cache.writeQuery<
            Pick<Query, 'entryCheckPostAuthorization'>,
            QueryEntryCheckPostAuthorizationArgs
          >({
            query: ENTRY_CHECK_POST_AUTHORIZATION,
            data: {
              entryCheckPostAuthorization: {
                ...entryCheck.entryCheckPostAuthorization!,
                items: [
                  ...entryCheck.entryCheckPostAuthorization!.items,
                  ...updatedData.createEntryCheckItemsForPostAuthorization,
                ],
              },
            },
            variables: {
              refurbishmentId,
            },
          });
        }
      },
    });

  return [
    createEntryCheckItem,
    {
      data: get('createEntryCheckItemsForPostAuthorization', data),
      ...rest,
    },
  ] as const;
};

export default useCreateEntryCheckItemsForPostAuthorization;
