import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import TIRE_MANUFACTURERS from './gql/tireManufacturers';

const useTireManufacturers = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'tireManufacturers'>, null>(
    TIRE_MANUFACTURERS,
  );

  return {
    data: get('tireManufacturers', data),
    ...rest,
  };
};

export default useTireManufacturers;
