import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import {
  Query,
  QueryActiveGroupedPartnerWorkshopConnectionsArgs,
} from 'graphqlTypes';
import ACTIVE_PARTNER_WORKSHOP_CONNECTIONS from './gql/activeGroupedPartnerWorkshopConnections';

const useActiveGroupedPartnerWorkshopConnections = (
  variables: QueryActiveGroupedPartnerWorkshopConnectionsArgs,
  options?: QueryHookOptions<
    Pick<Query, 'activeGroupedPartnerWorkshopConnections'>,
    QueryActiveGroupedPartnerWorkshopConnectionsArgs
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'activeGroupedPartnerWorkshopConnections'>,
    QueryActiveGroupedPartnerWorkshopConnectionsArgs
  >(ACTIVE_PARTNER_WORKSHOP_CONNECTIONS, {
    ...options,
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.activeGroupedPartnerWorkshopConnections,
    ...rest,
  };
};

export default useActiveGroupedPartnerWorkshopConnections;
