import gql from 'graphql-tag';

import {
  EntryCheckAuthorizationItemFragment,
  EntryCheckRefurbishmentDetailsFragment,
} from './fragments';

export default gql`
  query entryCheckAuthorization($refurbishmentId: UUID!) {
    entryCheckAuthorization(refurbishmentId: $refurbishmentId) {
      __typename
      id
      refurbishment {
        ...EntryCheckRefurbishmentDetailsFragment
      }
      state
      items {
        ...EntryCheckAuthorizationItemFragment
      }
      tags
    }
  }
  ${EntryCheckRefurbishmentDetailsFragment}
  ${EntryCheckAuthorizationItemFragment}
`;
