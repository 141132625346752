import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import { PartnerDetails } from 'graphqlTypes';
import useUpdatePartnerDetails from 'modules/api/partners/useUpdatePartner';
import { useLogger } from 'modules/logger';
import PartnerForm from '../PartnerForm';

const EditPartner = ({ item }: { item: PartnerDetails }) => {
  const { logError } = useLogger();
  const [showModal, setShowModal] = useState(false);

  const [updatePartnerDetails, { loading }] = useUpdatePartnerDetails();

  const onSubmit = async (values: PartnerDetails) => {
    try {
      await updatePartnerDetails({
        id: item.id,
        partnerInput: values,
      });
      setShowModal(false);
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShowModal(true)}
        data-qa-selector="editPartner"
      >
        {__('partners.edit')}
      </Button>
      <CustomModal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{__('partners.editTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PartnerForm
            data={item}
            loading={loading}
            onSubmit={onSubmit}
            onCancel={() => setShowModal(false)}
          />
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default EditPartner;
