import gql from 'graphql-tag';

import { PartnerDetailsFragment } from './fragments';

export default gql`
  query partnerSearch(
    $page: Int!
    $pageSize: Int!
    $filter: PartnerSearchFilterInput
    $sort: PartnerSearchSortInput
  ) {
    partnerSearch(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...PartnerDetailsFragment
      }
      pageInfo {
        page
        pageSize
        totalPageCount
        totalEntityCount
      }
    }
  }
  ${PartnerDetailsFragment}
`;
