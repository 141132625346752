import { Table } from 'react-bootstrap';

import LoadingIndicator from 'components/LoadingIndicator';
import { ProductionOverview } from 'graphqlTypes';
import ProductionItem from './ProductionItem';

type Props = {
  data?: ProductionOverview | null;
  loading: boolean;
};

const ProductionList = ({ data, loading }: Props) => {
  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  return (
    <Table
      striped
      responsive
      className="mt-3"
      data-qa-selector="production_list"
    >
      <thead>
        <tr>
          <th>{__('production.vinNumber')}</th>
          <th>{__('production.stockNumber')}</th>
          <th>{__('production.refurbishmentNumber')}</th>
          <th>{__('production.vehicle')}</th>
          <th>{__('production.retailStockCountry')}</th>
          <th>{__('production.refurbishmentType')}</th>
          <th>{__('production.refurbishmentState')}</th>
          <th>{__('production.lastStatusUpdate')}</th>
          <th>{__('production.refurbishmentLocation')}</th>
          <th>{__('production.refurbishmentPartner')}</th>
        </tr>
      </thead>
      <tbody>
        {data.items.map(item => (
          <ProductionItem key={item.refId} item={item} />
        ))}
      </tbody>
    </Table>
  );
};

export default ProductionList;
