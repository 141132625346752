import React from 'react';

import {
  BrakeTypePayload,
  EntryCheckItemValueState,
  EntryCheckItemValueUpdateEntryInput,
} from 'graphqlTypes';
import { useUpdateEntryCheckItemValues } from 'modules/api/entryCheck';
import { useBrakeTypes } from 'modules/api/wheelAttributes';
import { useLogger } from 'modules/logger';
import { SelectOption } from 'routes/ExitCheckRoute/types';
import { EntryCheckItem, PayloadType } from 'routes/RefurbishmentRoute/types';
import BrakeSelectItem from './Common/BrakeSelectItem';
import { BrakeType } from '../BrakeCheckpoints';

type Props = {
  items?: EntryCheckItem[];
  disabled: boolean;
};

const BrakeTypeSelect = ({ items, disabled }: Props) => {
  const { logError } = useLogger();
  const [updateEntryCheckItemValue] = useUpdateEntryCheckItemValues();
  const entryCheckItem = items?.find(
    item => item.payloadType === PayloadType.BrakeType,
  );
  const { data, loading } = useBrakeTypes();

  if (!entryCheckItem || !data) return null;

  const value = (entryCheckItem?.values?.[0]?.proposedValue as BrakeTypePayload)
    ?.brakeType;

  const onChange = async (newValue: string) => {
    const updateItems: EntryCheckItemValueUpdateEntryInput[] = [];

    const brakeDiskEntryCheckItem = items?.find(
      item => item.payloadType === PayloadType.BrakeDiskCondition,
    );

    if (newValue === BrakeType.DRUM) {
      // eslint-disable-next-line no-unused-expressions
      items
        ?.filter(item =>
          [
            PayloadType.IsBrakeCaliperInGoodCondition,
            PayloadType.BrakePadCondition,
            PayloadType.BrakeDiskCondition,
          ].includes(item.payloadType),
        )
        .forEach(item =>
          updateItems.push({
            id: item.values[0].id,
            value: {
              state: EntryCheckItemValueState.Confirmed,
              proposedValue: null,
            },
          }),
        );
    } else if (value === BrakeType.DRUM) {
      // eslint-disable-next-line no-unused-expressions
      items
        ?.filter(
          item =>
            item.values[0].state === EntryCheckItemValueState.Confirmed &&
            [
              PayloadType.IsBrakeCaliperInGoodCondition,
              PayloadType.BrakePadCondition,
              PayloadType.BrakeDiskCondition,
            ].includes(item.payloadType),
        )
        .forEach(item =>
          updateItems.push({
            id: item.values[0].id,
            value: {
              state: EntryCheckItemValueState.Created,
              proposedValue: null,
            },
          }),
        );
    } else if (
      brakeDiskEntryCheckItem &&
      brakeDiskEntryCheckItem.values[0].state ===
        EntryCheckItemValueState.Confirmed
    ) {
      updateItems.push({
        id: brakeDiskEntryCheckItem.values[0].id,
        value: {
          state: EntryCheckItemValueState.Created,
          proposedValue: null,
        },
      });
    }

    if (!updateItems.length) return;

    try {
      await updateEntryCheckItemValue(updateItems);
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <BrakeSelectItem
      label={__('entryCheck.brakeCheckpoints.brakeType')}
      required
      name="brakeType"
      options={data.map<SelectOption>(
        item =>
          ({
            label: item.brakeType.translation,
            value: item.brakeType.key,
          } as SelectOption),
      )}
      value={value}
      entryCheckItem={entryCheckItem}
      loading={loading}
      disabled={disabled}
      updateEntryCheckItemValueField="brakeType"
      onChange={onChange}
    />
  );
};

export default BrakeTypeSelect;
