import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

export const useSetPage = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchObject = qs.parse(search, { ignoreQueryPrefix: true });

  return {
    setPage: (page: number) => {
      const restSearchObject = { ...searchObject };
      delete restSearchObject.p;
      navigate({
        pathname,
        search: qs.stringify(
          page ? { ...searchObject, p: page + 1 } : restSearchObject,
        ),
      });
    },
  };
};
