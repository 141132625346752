import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import CustomModal from 'components/UI/CustomModal';
import { PartnerInput } from 'graphqlTypes';
import useCreatePartner from 'modules/api/partners/useCreatePartner';
import usePartnerSearch from 'modules/api/partners/usePartnerSearch';
import { useLogger } from 'modules/logger';
import PartnerForm from '../PartnerForm';
import { usePartnerSearchParams } from '../PartnerSearchTable/PartnerSeachTable';

const AddPartner = () => {
  const { logError } = useLogger();
  const [show, setShow] = useState(false);
  const [createPartner, { loading }] = useCreatePartner();

  const searchArgs = usePartnerSearchParams();

  const { refetch: refetchPartnerList } = usePartnerSearch(searchArgs);

  const handleOpen = () => setShow(true);

  const handleClose = () => setShow(false);

  const handleSubmit = async (values: PartnerInput) => {
    try {
      await createPartner(values);
      await refetchPartnerList();
    } catch (error) {
      logError({ error });
    }

    setShow(false);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleOpen}
        data-qa-selector="add_new_partner"
      >
        {__('partners.addNewPartner')}
      </Button>
      <CustomModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__('partners.addNewPartner')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PartnerForm
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={handleClose}
          />
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default AddPartner;
