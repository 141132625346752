import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationAddRefurbishmentPartnerWorkshopConnectionsArgs,
  RefurbishmentDetails,
} from 'graphqlTypes';
import ADD_REFURBISHMENT_PARTNER_WORKSHOP_CONNECTIONS from './gql/addRefurbishmentPartnerWorkshopConnections';
import { RefurbishmentDetailsPartnerWorkshopConnectionsFragment } from './gql/fragments';

const useAddRefurbishmentPartnerWorkshopConnections = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'addRefurbishmentPartnerWorkshopConnections'>,
    MutationAddRefurbishmentPartnerWorkshopConnectionsArgs
  >(ADD_REFURBISHMENT_PARTNER_WORKSHOP_CONNECTIONS);
  const { cache } = useApolloClient();

  const addConnection = (
    refurbishmentId: string,
    partnerWorkshopConnectionIds: string[],
  ) =>
    mutation({
      variables: {
        refurbishmentId,
        refurbishmentPartnerWorkshopConnectionAdditionInput: {
          partnerWorkshopConnectionIds,
        },
      },
      update: (client, result) => {
        if (!result.data?.addRefurbishmentPartnerWorkshopConnections) return;

        const refurbishment = cache.readFragment<RefurbishmentDetails>({
          id: `RefurbishmentDetails:${refurbishmentId}`,
          fragment: RefurbishmentDetailsPartnerWorkshopConnectionsFragment,
          fragmentName:
            'RefurbishmentDetailsPartnerWorkshopConnectionsFragment',
        });

        if (refurbishment) {
          const partnerWorkshopConnections = [
            ...(refurbishment.partnerWorkshopConnections || []),
            ...result.data.addRefurbishmentPartnerWorkshopConnections,
          ].sort(
            (a, b) =>
              a.mainWorkshop.name.localeCompare(
                b.mainWorkshop.name,
                __LOCALE__,
              ) ||
              a.repairWorkshop.name.localeCompare(
                b.repairWorkshop.name,
                __LOCALE__,
              ) ||
              a.repairPartner.name.localeCompare(
                b.repairPartner.name,
                __LOCALE__,
              ),
          );

          cache.writeFragment({
            id: `RefurbishmentDetails:${refurbishmentId}`,
            fragment: RefurbishmentDetailsPartnerWorkshopConnectionsFragment,
            fragmentName:
              'RefurbishmentDetailsPartnerWorkshopConnectionsFragment',
            data: {
              partnerWorkshopConnections,
              __typename: 'RefurbishmentDetails',
            },
          });
        }
      },
    });

  return [
    addConnection,
    {
      data: data?.addRefurbishmentPartnerWorkshopConnections,
      ...rest,
    },
  ] as const;
};

export default useAddRefurbishmentPartnerWorkshopConnections;
