import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import cns from 'classnames';

import CollapsableContent from 'components/UI/CollapsableContent';
import { RepairCost } from 'graphqlTypes';
import { Role, useHasRole } from 'modules/acl';
import DisplayCost from './DisplayCost';
import LaborTime from './LaborTime';

import cn from './RepairCostRow.module.scss';

type Props = {
  item: RepairCost;
  defaultCheckboxHandler: (item: RepairCost) => void;
  updateRepairCostItem: (
    updatedData: { active?: boolean; isDefault?: boolean },
    item: RepairCost,
  ) => void;
};

const RepairCostRow = ({
  item,
  updateRepairCostItem,
  defaultCheckboxHandler,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const hasRepairCostAdminRole = useHasRole(Role.REPAIR_COST_ADMIN) || false;
  const collapseId = `collapsableSection-${item.id}`;

  return (
    <tr key={item.id} data-qa-selector={`row-${item.id}`} data-qa-id={item.id}>
      <td data-qa-selector="mainWorkshop">{item.mainWorkshop.name}</td>
      <td data-qa-selector="repairPartner">{item.repairPartner.name}</td>
      <td data-qa-selector="repairWorkshop">{item.repairWorkshop.name}</td>
      <td data-qa-selector="area">{item.area.translation}</td>
      <td data-qa-selector="part">{item.part.translation}</td>
      <td data-qa-selector="repairMethod">{item.repairMethod.translation}</td>
      <td data-qa-selector="laborTotal">
        <CollapsableContent
          header={
            <DisplayCost
              costMinorUnits={item.cost.laborTotalCostMinorUnits}
              currencyCode={item.cost.currencyCode}
              qaSelector="laborTotalRO"
            />
          }
          name={`${collapseId}-labor`}
          collapsed={collapsed}
          toggleClicked={() => setCollapsed(!collapsed)}
        >
          <div data-qa-selector="laborTime">
            <LaborTime timeInHours={item.cost.laborTimeHours} />
          </div>
          <div data-qa-selector="laborRate">
            <DisplayCost
              costMinorUnits={item.cost.laborRateMinorUnits}
              currencyCode={item.cost.currencyCode}
              qaSelector="laborRateRO"
            />{' '}
            {__('costTable.rate')}
          </div>
        </CollapsableContent>
      </td>
      <td data-qa-selector="materialTotal">
        <CollapsableContent
          header={
            <DisplayCost
              costMinorUnits={item.cost.materialCostMinorUnits}
              currencyCode={item.cost.currencyCode}
              qaSelector="materialCostRO"
            />
          }
          name={`${collapseId}-material`}
          collapsed={collapsed}
          toggleClicked={() => setCollapsed(!collapsed)}
        >
          <div>
            <DisplayCost
              costMinorUnits={item.cost.sparePartCostMinorUnits}
              currencyCode={item.cost.currencyCode}
              qaSelector="sparePartCostRO"
            />{' '}
            {__('costTable.sparePart')}
          </div>
          <div>
            <DisplayCost
              costMinorUnits={item.cost.consumableCostMinorUnits}
              currencyCode={item.cost.currencyCode}
              qaSelector="consumableCostRO"
            />{' '}
            {__('costTable.consumable')}
          </div>
        </CollapsableContent>
      </td>
      <td data-qa-selector="totalPrice">
        <DisplayCost
          costMinorUnits={item.cost.totalPriceMinorUnits}
          currencyCode={item.cost.currencyCode}
          qaSelector="totalPriceRO"
        />
      </td>
      <td data-qa-selector="default">
        <Form.Check
          type="checkbox"
          id={`repairCost-default-${item.id}`}
          checked={item.isDefault}
          custom
          onChange={() => defaultCheckboxHandler(item)}
          disabled={!item.active || !hasRepairCostAdminRole}
          className={cns({
            [cn.disabledCheckbox]: !item.active,
          })}
        />
      </td>
      <td data-qa-selector="active">
        <Form.Check
          type="checkbox"
          id={`repairCost-active-${item.id}`}
          defaultChecked={item.active}
          onChange={event =>
            updateRepairCostItem({ active: event?.target.checked }, item)
          }
          disabled={item.isDefault || !hasRepairCostAdminRole}
          custom
        />
      </td>
    </tr>
  );
};

export default RepairCostRow;
