import { useEffect, useState } from 'react';

import { FeatureFlag } from 'modules/feature';
import { getJSONItem, setJSONItem } from 'modules/safetyLocalStorage';

const FLAG_STORAGE_KEY = 'featureFlags';

const useDeveloperModeFeatureFlags = () => {
  const [flagsStatus, setFlagStatus] = useState<Record<string, boolean>>(() => {
    return (
      getJSONItem(FLAG_STORAGE_KEY) ||
      Object.values(FeatureFlag).reduce<Partial<Record<FeatureFlag, boolean>>>(
        (accum, value) => {
          return {
            ...accum,
            [value]: process.env?.[value] === 'YES',
          };
        },
        {},
      )
    );
  });

  useEffect(() => {
    setJSONItem(FLAG_STORAGE_KEY, flagsStatus);
  }, [flagsStatus]);

  const switchFlag = (flag: FeatureFlag) => {
    setFlagStatus({ ...flagsStatus, [flag]: !flagsStatus[flag] });
  };

  return { flagsStatus, switchFlag };
};

export default useDeveloperModeFeatureFlags;
