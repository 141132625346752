import { Link } from 'react-router-dom';

import BackButton from 'components/UI/BackButton';

import cn from './Headbar.module.scss';

type Props = {
  url: string;
  label: string;
};

const Headbar = ({ url, label }: Props) => {
  return (
    <div className={cn.root}>
      <BackButton as={Link} to={url} qaSelector="backToRefurbishment">
        {label}
      </BackButton>
    </div>
  );
};

export default Headbar;
