import React from 'react';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash/fp';
import qs from 'qs';

import { FormFilterNames } from 'components/Filter/constants';
import { useSetPage } from 'components/TableInfoPagination';
import {
  Group,
  RefurbishmentProviderData,
  User,
  UserPartnerWorkshopConnection,
} from 'graphqlTypes';
import { useSearchUsers } from 'modules/api/users';

export type OverviewData = Omit<
  User,
  'groups' | 'refurbishmentProvidersData'
> & {
  groups: string[];
  providers: string[];
  productionLocations: string[];
};

const flatGroups = map<Group, string>(group => group.name);
const flatProviders = map<RefurbishmentProviderData, string>(
  provider => `${provider.partner.name} / ${provider.location.name}`,
);

const flatProductionLocations = map<UserPartnerWorkshopConnection, string>(
  connection => connection.mainWorkshop.name,
);

export const mapUserData = map<User, OverviewData>(user => ({
  ...user,
  groups: flatGroups(user.groups),
  providers: flatProviders(user.refurbishmentProvidersData),
  productionLocations: flatProductionLocations(
    user.userPartnerWorkshopConnections,
  ),
}));

const useGetUserOverview = () => {
  const { search } = useLocation();
  const { setPage } = useSetPage();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

  const page = Math.max(Number(searchParams?.p) - 1 || 0, 0);
  const firstName = searchParams?.[FormFilterNames.FIRST_NAME];
  const email = searchParams?.[FormFilterNames.EMAIL];

  const [searchUsers, { data, pageInfo, ...rest }] = useSearchUsers();

  React.useEffect(() => {
    searchUsers({
      page,
      pageSize: 10,
      filter: {
        ...(firstName ? { firstName } : {}),
        ...(email ? { email } : {}),
      },
    });
  }, [search]);

  return {
    data: mapUserData(data),
    pageInfo,
    filterValues: {
      ...searchParams,
      filter: {
        ...searchParams.filter,
      },
    },
    setPage,
    ...rest,
  };
};

export default useGetUserOverview;
