import gql from 'graphql-tag';

export default gql`
  mutation updateVehicle($vehicleUpdateInput: VehicleUpdateInput!) {
    updateVehicle(vehicleUpdateInput: $vehicleUpdateInput) {
      vin
      lastServiceMileage {
        distance
        unit
      }
      lastServiceOn
      technicalInspectionCertificateIssueMileage {
        distance
        unit
      }
      technicalInspectionCertificateIssueDate
      inspectionExpiryDate
    }
  }
`;
