import {
  ExitCheckState,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import { ImageFile } from 'helpers/adImageUpload/useAdImageUpload';
import { Option } from '../../types';

export enum Area {
  DAMAGE_BODY = 'damage-body',
  DAMAGE_TYRES_BRAKES = 'damage-tyresAndBrakes',
  DAMAGE_INTERIOR = 'damage-interior',
  DAMAGE_UNDERBODY = 'damage-underbody',
  DAMAGE_ENGINE = 'damage-engine',
}

export enum SubArea {
  DAMAGE_BODY_FRONT = 'damage-body-Front',
  DAMAGE_BODY_RIGHT = 'damage-body-right',
  DAMAGE_BODY_FRONT_RIGHT = 'front_right',
  DAMAGE_BODY_BACK_RIGHT = 'back_right',
  DAMAGE_BODY_FRONT_LEFT = 'front_left',
  DAMAGE_BODY_BACK_LEFT = 'back_left',
  DAMAGE_BODY_BACK = 'damage-body-back',
  DAMAGE_BODY_LEFT = 'damage-body-left',
  DAMAGE_BODY_ROOF = 'damage-body-roof',
  DAMAGE_INTERIOR_INTERIOR = 'damage-interior-interior',
  DAMAGE_UNDERBODY_UNTERBODEN = 'damage-underbody-unterboden',
  DAMAGE_INTERIOR_CONTROLS = 'damage-interior-controls',
  DAMAGE_INTERIOR_TRUNK = 'damage-interior-trunk',
  DAMAGE_MOTOR_MOTOR = 'damage-motor-motor',
}

export enum CarPosition {
  FRONT = 'front',
  RIGHT = 'right',
  BACK = 'back',
  LEFT = 'left',
  ROOF = 'roof',
  INTERIOR = 'interior',
  UNDERBODY = 'underbody',
}

export type DamageFormData = {
  budget: number;
  carPart: SelectOption;
  service: {
    name: RefurbishmentServiceName;
    type: RefurbishmentServiceType;
  };
  imageUrl: Array<ImageFile> | Array<{ url: string }>;
  comment?: string;
  damageType?: any;
};

export type ExitCheckStateOption = Option<ExitCheckState>;

export type SelectOption = {
  label: string;
  value: string;
};

export type ServiceFormValues = {
  service: SelectOption;
  budget: number;
  comment?: string | null;
};

export enum PhotoType {
  CarLeftSide = 'CAR_LEFT_SIDE',
  FrontInterior = 'FRONT_INTERIOR',
  BackInterior = 'BACK_INTERIOR',
  Trunk = 'TRUNK',
}

export enum DocumentType {
  ProofOfService = 'DOCUMENT_REFURBISHMENT_PROVIDER_TYPE_PROOF_OF_SERVICE',
}
