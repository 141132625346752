import React, { memo } from 'react';

interface Props {
  className?: string;
}

export default memo(({ className }: Props) => (
  <svg className={className} width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </svg>
));
