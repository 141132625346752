import {
  DoesRimCenterCapExistsPayload,
  EntryCheckItemValueSourceType,
  GraphQlJsonPayload,
  IsRimInGoodConditionPayload,
  IsTireDirectionCorrectPayload,
  IsTireInGoodConditionPayload,
  IsTirePressureCorrectPayload,
  Maybe,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import {
  DamageFormData,
  EntryCheckItem,
  PayloadType,
  SliceKeyDamage,
  WheelPosition,
} from 'routes/RefurbishmentRoute/types';

export type AddOptions = {
  damageGroup?: SliceKeyDamage;
  addDamage?: Partial<Pick<DamageFormData, 'carPart' | 'name' | 'typeName'>>;
  addService?: RefurbishmentServiceName;
};

export type Checkpoint<T extends boolean | string> = AddOptions & {
  id: string;
  payloadType: string;
  value: Maybe<T>;
};

type MapCheckItem = {
  id: string;
  payloadType: PayloadType;
  value: Maybe<GraphQlJsonPayload>;
};

const mapCheckItems = (items: EntryCheckItem[]) =>
  items
    .map(item => {
      const value = item.values.find(
        itemValue =>
          itemValue.sourceType === EntryCheckItemValueSourceType.EntryCheck,
      );

      if (!value?.id) return null;

      return {
        id: value.id,
        payloadType: item.payloadType,
        value: value?.proposedValue ?? null,
      };
    })
    .filter((item): item is MapCheckItem => !!item);

type RimTireMap = {
  isRimInGoodCondition?: Checkpoint<boolean>;
  doesRimCenterCapExists?: Checkpoint<boolean>;
  isTireInGoodCondition?: Checkpoint<boolean>;
  isTireDirectionCorrect?: Checkpoint<boolean>;
  isTirePressureCorrect?: Checkpoint<boolean>;
};

export const carParts = {
  rim: {
    fwr: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-attachments-rimFrontRight',
      section: 'damage-tyresAndBrakes',
      subSection: 'front_right',
    },
    bwr: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-attachments-rimRearRight',
      section: 'damage-tyresAndBrakes',
      subSection: 'back_right',
    },
    fwl: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-attachments-rimFrontLeft',
      section: 'damage-tyresAndBrakes',
      subSection: 'front_left',
    },
    bwl: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-attachments-rimRearLeft',
      section: 'damage-tyresAndBrakes',
      subSection: 'back_left',
    },
  },
  tire: {
    fwr: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-tire-tyresFrontRight',
      section: 'damage-tyresAndBrakes',
      subSection: 'front_right',
    },
    bwr: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-tire-tyresRearRight',
      section: 'damage-tyresAndBrakes',
      subSection: 'back_right',
    },
    fwl: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-tire-tyresFrontLeft',
      section: 'damage-tyresAndBrakes',
      subSection: 'front_left',
    },
    bwl: {
      name: 'damage-tyresAndBrakes-tyresAndBrakes-tire-tyresRearLeft',
      section: 'damage-tyresAndBrakes',
      subSection: 'back_left',
    },
  },
  brakeLines: {
    fwr: {
      name: 'damage-underbody-unterboden-brakePipe-brakeLinesFront',
      section: 'damage-underbody',
      subSection: 'damage-underbody-unterboden',
    },
    bwr: {
      name: 'damage-underbody-unterboden-brakePipe-BrakeLinesBack',
      section: 'damage-underbody',
      subSection: 'damage-underbody-unterboden',
    },
    fwl: {
      name: 'damage-underbody-unterboden-brakePipe-brakeLinesFront',
      section: 'damage-underbody',
      subSection: 'damage-underbody-unterboden',
    },
    bwl: {
      name: 'damage-underbody-unterboden-brakePipe-BrakeLinesBack',
      section: 'damage-underbody',
      subSection: 'damage-underbody-unterboden',
    },
  },
};

export const brakeReplaceDamageServices = {
  fwr: RefurbishmentServiceName.ReplaceFrontRightBrake,
  bwr: RefurbishmentServiceName.ReplaceRearRightBrake,
  fwl: RefurbishmentServiceName.ReplaceFrontLeftBrake,
  bwl: RefurbishmentServiceName.ReplaceRearLeftBrake,
};

export const rimTireMap = (
  items: EntryCheckItem[],
  position: WheelPosition,
) => {
  const mappedItems = mapCheckItems(items);

  return mappedItems.reduce<RimTireMap>((map, item) => {
    switch (item.payloadType) {
      case PayloadType.IsRimInGoodCondition:
        return {
          ...map,
          isRimInGoodCondition: {
            ...item,
            value: (item.value as IsRimInGoodConditionPayload)?.value ?? null,
            addDamage: {
              carPart: carParts.rim[position],
            },
          },
        };

      case PayloadType.DoesRimCenterCapExists:
        return {
          ...map,
          doesRimCenterCapExists: {
            ...item,
            value: (item.value as DoesRimCenterCapExistsPayload)?.value ?? null,
            addDamage: {
              carPart: carParts.rim[position],
            },
          },
        };

      case PayloadType.IsTireInGoodCondition:
        return {
          ...map,
          isTireInGoodCondition: {
            ...item,
            value: (item.value as IsTireInGoodConditionPayload)?.value ?? null,
            addDamage: {
              carPart: carParts.tire[position],
              typeName: 'damage-type-tire-dimensionInadmissable',
              name: {
                name: RefurbishmentServiceName.Replace,
                type: RefurbishmentServiceType.Damage,
              },
            },
          },
        };

      case PayloadType.IsTireDirectionCorrect:
        return {
          ...map,
          isTireDirectionCorrect: {
            ...item,
            value: (item.value as IsTireDirectionCorrectPayload)?.value ?? null,
            addDamage: {
              carPart: carParts.tire[position],
              typeName: 'damage-type-tire-dimensionInadmissable',
            },
          },
        };

      case PayloadType.IsTirePressureCorrect:
        return {
          ...map,
          isTirePressureCorrect: {
            ...item,
            value: (item.value as IsTirePressureCorrectPayload)?.value ?? null,
            addDamage: {
              carPart: carParts.tire[position],
            },
          },
        };

      default:
        return map;
    }
  }, {});
};
