import gql from 'graphql-tag';

import { ServiceItemPayloadFragment } from './fragments';

export default gql`
  mutation createQualityCheckServiceItem(
    $serviceItemCreateInput: ServiceItemCreateInput!
  ) {
    createQualityCheckServiceItem(
      serviceItemCreateInput: $serviceItemCreateInput
    ) {
      id
      attachedTo
      providerTotalCostsMinorUnits
      phaseName
      createdOn
      revisions {
        id
        propertyPath
        state
        createdBy {
          id
          firstName
        }
      }
      data {
        payloadType
        ...ServiceItemPayloadFragment
        ... on ServiceItemPayload {
          serviceState: state
        }
      }
    }
  }
  ${ServiceItemPayloadFragment}
`;
