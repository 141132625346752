import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import LoadingIndicator from 'components/LoadingIndicator';
import { Role } from 'graphqlTypes';
import { useAssignRoles } from 'modules/api/groups';
import { useGetRoles } from 'modules/api/roles';
import mapData from './mapData';

interface Props {
  id: string;
  roles: Role[] | null | undefined;
}

type FormData = {
  roles: string[];
};

const Roles = ({ id, roles: groupRoles }: Props) => {
  const { data: allRoles, loading, error } = useGetRoles();
  const [assignRoles] = useAssignRoles();
  const { register, getValues } = useForm<FormData>();

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <p>Error</p>;
  }

  const handleChange = () => {
    const { roles } = getValues();

    assignRoles({ groupId: id, roleIds: roles });
  };

  const roles = mapData(allRoles || [], groupRoles || []);

  return (
    <>
      <h3 className="title-section">{__('group.detail.roles')}</h3>
      <Table borderless>
        <tbody>
          {roles &&
            roles.map(role => (
              <tr key={role.id} className="border-bottom">
                <td className="w-25">
                  <Form.Check
                    {...register('roles', { onChange: handleChange })}
                    type="checkbox"
                    id={`role-${role.id}`}
                    custom
                    value={role.id}
                    className="font-weight-bold"
                    label={role.name}
                    defaultChecked={role.checked}
                  />
                </td>
                <td className="w-75">{role.description}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default Roles;
