import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import PARTNERS from './gql/partners';

const useGetAllPartners = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'partners'>, null>(PARTNERS);

  return {
    data: data?.partners?.partners,
    ...rest,
  };
};

export default useGetAllPartners;
