import { filter, find, flow, get, map } from 'lodash/fp';
import { Option } from 'types';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import SelectInput from 'components/Form/SelectInput';
import {
  RefurbishmentServiceName,
  RefurbishmentServiceType,
  ServiceData,
} from 'graphqlTypes';
import { useGetServiceTypes } from 'modules/api/services';
import { isImperfectionService } from '../../helpers/imperfections';

export type ServiceTypeSelectValue = {
  name: RefurbishmentServiceName;
  type: RefurbishmentServiceType;
};

const getServiceTypesDamage = (serviceType: RefurbishmentServiceType[]) =>
  filter<ServiceData>(service => serviceType.includes(get(['type'], service)));

const filterForbiddenServiceNames = (
  data: ServiceData[],
  isImperfectionRepairMethods: boolean,
) => {
  const forbiddenDamageServiceTypes = isImperfectionRepairMethods
    ? [RefurbishmentServiceName.NoRepairNeeded]
    : [
        RefurbishmentServiceName.NoRepairImperfection,
        RefurbishmentServiceName.NoRepairWearAndTear,
      ];

  return data.filter(item => {
    if (item.type === RefurbishmentServiceType.Damage) {
      return !forbiddenDamageServiceTypes.includes(item.name.key);
    }

    return true;
  });
};

const hideNonImperfections = (data: ServiceData[]) => {
  return data.filter(item => {
    if (item.type === RefurbishmentServiceType.Damage) {
      return isImperfectionService(item.name.key);
    }

    return true;
  });
};

const sortServices = (services: Array<Option<ServiceTypeSelectValue>>) =>
  services.sort((a, b) => a.label.localeCompare(b.label));

const reorderServiceOther = (
  services: Array<Option<ServiceTypeSelectValue>>,
) => {
  const otherService = find(
    service => service.value.name === RefurbishmentServiceName.Other,
    services,
  );

  const sortedServices = filter(
    service => service.value.name !== RefurbishmentServiceName.Other,
    services,
  );

  if (otherService) {
    sortedServices.push(otherService);
  }

  return sortedServices;
};

const mapServiceTypes = map<ServiceData, Option<ServiceTypeSelectValue>>(
  service => ({
    label: service.name.translation || service.name.key,
    value: {
      name: service.name.key,
      type: service.type,
    },
  }),
);

const removeMaintenanceService = filter<Option<ServiceTypeSelectValue>>(
  service => service.value.name !== RefurbishmentServiceName.Maintenance,
);

export const getServiceTypesOptions = ({
  serviceType,
  allServiceTypes,
  hideMaintenanceService,
  isImperfectionRepairMethods,
  hideNonImperfectionServices,
  optionMapping,
}: {
  serviceType: RefurbishmentServiceType[];
  allServiceTypes?: ServiceData[];
  hideMaintenanceService: boolean;
  isImperfectionRepairMethods: boolean;
  hideNonImperfectionServices: boolean;
  optionMapping?: (
    option: Option<ServiceTypeSelectValue>,
  ) => Option<ServiceTypeSelectValue>;
}) =>
  flow(
    getServiceTypesDamage(serviceType),
    data => filterForbiddenServiceNames(data, isImperfectionRepairMethods),
    data => (hideNonImperfectionServices ? hideNonImperfections(data) : data),
    mapServiceTypes,
    data => (optionMapping ? data.map(optionMapping) : data),
    data => (hideMaintenanceService ? removeMaintenanceService(data) : data),
    sortServices,
    reorderServiceOther,
  )(allServiceTypes);

interface Props {
  name: string;
  placeholder?: string;
  form?: string;
  control: any;
  serviceType: RefurbishmentServiceType | RefurbishmentServiceType[];
  disabled?: boolean;
  hideMaintenanceService?: boolean;
  dataQa?: string;
  dataQaOption?: string;
  useReactSelect?: boolean;
  onChange?(value: {
    name: RefurbishmentServiceName;
    type: RefurbishmentServiceType;
  }): void;
  isImperfectionRepairMethods?: boolean;
  hideNonImperfectionServices?: boolean;
  optionMapping?: (
    option: Option<ServiceTypeSelectValue>,
  ) => Option<ServiceTypeSelectValue>;
  required?: boolean;
}

const ServiceTypeSelectControl = ({
  name,
  placeholder,
  form,
  control,
  serviceType,
  disabled,
  hideMaintenanceService = false,
  required = true,
  dataQa = 'service-type-select',
  dataQaOption = 'service-type-option',
  useReactSelect,
  onChange,
  isImperfectionRepairMethods = false,
  hideNonImperfectionServices = false,
  optionMapping,
}: Props) => {
  const { data, loading } = useGetServiceTypes();
  const serviceTypeOptions = getServiceTypesOptions({
    serviceType: [serviceType].flat(),
    allServiceTypes: data,
    hideMaintenanceService,
    isImperfectionRepairMethods,
    hideNonImperfectionServices,
    optionMapping,
  });

  const rules = required
    ? {
        required: __('field.required'),
      }
    : {};
  const SelectControl = useReactSelect ? ReactSelectInput : SelectInput;

  return (
    <SelectControl
      name={name}
      placeholder={placeholder}
      form={form}
      options={serviceTypeOptions}
      isLoading={loading}
      control={control}
      disabled={disabled}
      rules={rules}
      onChange={onChange}
      dataQa={dataQa}
      dataQaOption={dataQaOption}
      {...(useReactSelect ? { isClearable: !required } : {})}
      {...(useReactSelect
        ? {
            dataQaOptionHandler: (value: {
              name: RefurbishmentServiceName;
              type: RefurbishmentServiceType;
            }) => value.name,
          }
        : {})}
    />
  );
};

export default ServiceTypeSelectControl;
