import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import {
  EntryCheckItemValue,
  EntryCheckItemValueCreateInput,
  Mutation,
  MutationCreateEntryCheckItemValuesArgs,
  Query,
  QueryEntryCheckPostAuthorizationArgs,
} from 'graphqlTypes';
import CREATE_ENTRY_CHECK_ITEM_VALUES from './gql/createEntryCheckItemValues';
import ENTRY_CHECK_POST_AUTHORIZATION from './gql/entryCheckPostAuthorization';

/**
 * @deprecated
 * Use `useCreateEntryCheckItemValues` instead
 */
const useCreateEntryCheckItemValuesForPostAuthorization = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createEntryCheckItemValues'>,
    MutationCreateEntryCheckItemValuesArgs
  >(CREATE_ENTRY_CHECK_ITEM_VALUES);

  const createEntryCheckItemValues = async (
    inputs: EntryCheckItemValueCreateInput[],
    refurbishmentId: string,
  ) => {
    return mutation({
      variables: { inputs },
      update(cache, { data: updatedData }) {
        const entryCheck = cache.readQuery<
          Pick<Query, 'entryCheckPostAuthorization'>,
          QueryEntryCheckPostAuthorizationArgs
        >({
          query: ENTRY_CHECK_POST_AUTHORIZATION,
          variables: {
            refurbishmentId,
          },
        });

        if (entryCheck && updatedData) {
          const newValues = new Map(
            updatedData.createEntryCheckItemValues.map(value => [
              value.entryCheckItemId,
              value,
            ]),
          );

          const items = entryCheck.entryCheckPostAuthorization!.items.map(
            item => {
              return {
                ...item,
                values: [
                  ...item.values,
                  ...([newValues.get(item.id)].filter(
                    Boolean,
                  ) as EntryCheckItemValue[]),
                ],
              };
            },
          );

          cache.writeQuery<
            Pick<Query, 'entryCheckPostAuthorization'>,
            QueryEntryCheckPostAuthorizationArgs
          >({
            query: ENTRY_CHECK_POST_AUTHORIZATION,
            data: {
              entryCheckPostAuthorization: {
                ...entryCheck.entryCheckPostAuthorization!,
                items,
              },
            },
            variables: {
              refurbishmentId,
            },
          });
        }
      },
    });
  };

  return [
    createEntryCheckItemValues,
    {
      data: get('createEntryCheckItemValues', data),
      ...rest,
    },
  ] as const;
};

export default useCreateEntryCheckItemValuesForPostAuthorization;
