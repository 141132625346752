export const enum FormFilterNames {
  COUNTRIES = 'cc',
  MAIN_WORKSHOPS = 'mw',
  AREA = 'da',
  DAMAGE_TYPE = 'dt',
  PART = 'dp',
  REPAIR_METHOD_LT = 'rb',
  REPAIR_METHOD_GTE = 'ra',
  ACTIVE_ONLY = 'ao',
}
