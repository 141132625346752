import { useEffect } from 'react';
import { get } from 'lodash/fp';

import { useGetCurrentUser } from 'modules/api/users';
import { useAuth } from '.';

const useAuthorizedUser = () => {
  const { info } = useAuth();
  const uuid = get('userUuid', info);
  const isAuthorized = get('isAuthorized', info);

  const [getUser, { user: userData, ...rest }] = useGetCurrentUser();

  useEffect(() => {
    if (isAuthorized) {
      getUser(uuid);
    }
  }, [isAuthorized]);

  const user = isAuthorized ? userData : undefined;

  return {
    user,
    ...rest,
  };
};

export default useAuthorizedUser;
