import { keys } from 'lodash/fp';

import createRequestOptions from './createRequestOptions';
import processRequest from './processRequest';

export default (globalOptionsParam = {}) => {
  const globalOptions = { cmap: {}, ...globalOptionsParam };
  const { cacheClient } = globalOptions;

  return async reqOptions => {
    const start = new Date();

    const options = createRequestOptions({ ...globalOptions, ...reqOptions });
    const {
      cacheable,
      cmap,
      getIdenticalRequestText,
      id,
      pb,
      provider,
    } = options;

    if (cacheable && id && cacheClient) {
      const data = await cacheClient.getAsync(id);

      if (data) {
        return {
          elapsed: new Date() - start,
          fromCache: true,
          ...JSON.parse(data),
        };
      }
    }

    if (cmap[id]) {
      cmap[id](getIdenticalRequestText(options));
    }

    cmap[id] = provider.cancel;

    if (!pb.status()) {
      pb.start();
    }

    const res = await processRequest(options);

    delete cmap[id];

    if (!keys(cmap).length) {
      pb.done();
    }

    if (res.error) {
      throw res;
    }

    if (cacheable && id && cacheClient) {
      cacheClient.setWithExpireAsync(id, JSON.stringify(res));
    }

    res.elapsed = new Date() - start;

    return res;
  };
};
