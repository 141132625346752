import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { PreparedFile } from 'components/FileDropzone';
import FormButtonModal from 'components/Form/FormButtonModal/FormButtonsModal';
import ImportCsvUpload from 'components/ImportCsvUpload';
import { ImportTask } from 'graphqlTypes';
import s3FileUpload from 'helpers/s3FileUpload';
import { usePromiseBuildRepairMethodImportUploadCredentials } from 'modules/api/costTable';
import useCreateRepairMethodImportTask from 'modules/api/costTable/useCreateRepairMethodImportTask';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { ImportRepairMethodPhase } from './ImportRepairMethodModal';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairMethodPhase>>;
  getRepairMethodImportTask: (uuid: string) => void;
  repairMethodImportTaskData?: ImportTask;
  stopPolling: () => void;
};

const ImportRepairMethodUpload = ({
  closeModal,
  setActivePhase,
  getRepairMethodImportTask,
  stopPolling,
}: Props) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);

  const { logError } = useLogger();
  const { error } = useNotification();

  const getCredentials = usePromiseBuildRepairMethodImportUploadCredentials();
  const [createRepairMethodImportTask] = useCreateRepairMethodImportTask();

  const exampleCsv =
    process.env.NODE_ENV === 'development'
      ? `/repairMethod-example.csv`
      : `/${__LOCALE__}/repairMethod-example.csv`;

  const onFiles = (e: PreparedFile[]) => {
    if (e.length > 0) {
      setCsvFile(e[0]?.file);
    } else {
      setCsvFile(null);
    }
  };

  const uploadCsv = async () => {
    const credentials = await getCredentials({
      importId: uuidv4(),
      extension: 'csv',
    });

    try {
      if (csvFile && credentials) {
        const { host, formData } = credentials;
        const uri = `${host}${formData.key}`;

        setActivePhase(ImportRepairMethodPhase.VALIDATION_LOADING);

        await s3FileUpload(csvFile, formData, host);

        const { data } = await createRepairMethodImportTask({
          name: csvFile.name,
          uri,
        });

        const id = data?.createRepairMethodImportTask.id || '';
        getRepairMethodImportTask(id);
      }
    } catch (errorData) {
      logError({ error: errorData });
      error(__('refurbishment.detail.failedUpload'));
      setActivePhase(ImportRepairMethodPhase.UPLOADING);
    }
  };

  return (
    <>
      <h3>{__('repairMethod.pleaseUploadFile')}</h3>
      <ImportCsvUpload onFiles={onFiles} />
      <Button variant="link" className="p-0 mt-3" href={exampleCsv}>
        {__('repairMethod.downloadExampleFile')}
      </Button>
      <FormButtonModal>
        <Button
          variant="secondary"
          onClick={() => {
            if (stopPolling) {
              stopPolling();
            }
            setActivePhase(ImportRepairMethodPhase.UPLOADING);
            closeModal();
          }}
          data-qa-selector="closeImportRepairMethodModal"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="ml-4"
          data-qa-selector="confirmImportRepairMethodModal"
          disabled={!csvFile}
          onClick={uploadCsv}
        >
          {__('action.confirm')}
        </Button>
      </FormButtonModal>
    </>
  );
};

export default ImportRepairMethodUpload;
