import gql from 'graphql-tag';

export const TaskVehicleFragment = gql`
  fragment TaskVehicleFragment on Vehicle {
    vin
    stockNumber
    make
    model
    subModel
  }
`;

export const TaskRefurbishmentFragment = gql`
  fragment TaskRefurbishmentFragment on TaskRefurbishmentInfo {
    refurbishmentId
    refurbishmentNumber
    refurbishmentState
    orderedOn
    currencyCode
  }
`;

export const TaskPartnerWorkshopConnectionFragment = gql`
  fragment TaskPartnerWorkshopConnectionFragment on PartnerWorkshopConnection {
    id
    mainWorkshop {
      id
      name
      branchId
    }
    repairWorkshop {
      id
      name
      branchId
    }
    repairPartner {
      id
      name
      refurbishmentPartnerId
    }
  }
`;

export const TaskServiceFragment = gql`
  fragment TaskServiceFragment on Service {
    id
    relatedServiceId
    damageId
    budgetMinorUnits
    comment
    state
    stateComment
    sourceType
    createdOn
    updatedOn
    partnerWorkshopConnectionId
    serviceData {
      name
      type
    }
  }
`;

export const TaskServiceExcludingCostFragment = gql`
  fragment TaskServiceExcludingCostFragment on ServiceExcludingCost {
    id
    relatedServiceId
    damageId
    comment
    state
    stateComment
    sourceType
    createdOn
    updatedOn
    partnerWorkshopConnectionId
    serviceData {
      name
      type
    }
  }
`;

export const TaskDamageFragment = gql`
  fragment TaskDamageFragment on Damage {
    id
    imageUrl
    photoId
    area
    subArea
    part
    type
    isSecondaryWheel
    sourceType
    state
    customerDisplay
    cancelReason
    cancelComment
    createdOn
    updatedOn
  }
`;

export const RefurbishmentTaskItemIncludingCostFragment = gql`
  fragment RefurbishmentTaskItemIncludingCostFragment on RefurbishmentTaskItemIncludingCost {
    id
    state
    itemType
    partnerWorkshopConnection {
      ...TaskPartnerWorkshopConnectionFragment
    }
    service {
      ...TaskServiceFragment
    }
    damage {
      ...TaskDamageFragment
    }
  }
  ${TaskPartnerWorkshopConnectionFragment}
  ${TaskServiceFragment}
  ${TaskDamageFragment}
`;

export const RefurbishmentTaskItemExcludingCostFragment = gql`
  fragment RefurbishmentTaskItemExcludingCostFragment on RefurbishmentTaskItemExcludingCost {
    id
    state
    itemType
    partnerWorkshopConnection {
      ...TaskPartnerWorkshopConnectionFragment
    }
    service {
      ...TaskServiceExcludingCostFragment
    }
    damage {
      ...TaskDamageFragment
    }
  }
  ${TaskPartnerWorkshopConnectionFragment}
  ${TaskServiceExcludingCostFragment}
  ${TaskDamageFragment}
`;
