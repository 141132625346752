import { ClearIndicatorProps, components } from 'react-select';

const createReactSelectClearIndicator = (selector: string) => (
  props: ClearIndicatorProps,
) => {
  return (
    <div data-qa-selector={selector}>
      <components.ClearIndicator {...props} />
    </div>
  );
};

export default createReactSelectClearIndicator;
