import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { VehicleModelVersion } from 'graphqlTypes';
import { useVehicleAllDamageTypes } from 'modules/api/costTable';
import createPlaceholder from './createPlaceholder';
import createSingleValue from './createSingleValue';

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, object>;
  qaSelector: string;
  placeholder: string;
};

const DamageTypeFilter = <T extends FieldValues>({
  name,
  control,
  qaSelector,
  placeholder,
}: Props<T>) => {
  const { data, loading } = useVehicleAllDamageTypes(VehicleModelVersion.V1_0);

  const options =
    data
      ?.map(item => ({
        label: item.type.translation || item.type.key,
        value: item.type.key,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <ReactSelectInput
      name={name}
      inputId={name}
      options={options}
      isLoading={loading}
      control={control}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
      placeholder={placeholder}
      isClearable
      components={{
        SingleValue: createSingleValue(),
        Placeholder: createPlaceholder(),
      }}
    />
  );
};

export default DamageTypeFilter;
