import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryUserArgs } from 'graphqlTypes';
import REPAIR_COST_IMPORT_TASK from './gql/getRepairCostImportTask';

const useGetRepairCostImportTask = () => {
  const [getData, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'getRepairCostImportTask'>,
    QueryUserArgs
  >(REPAIR_COST_IMPORT_TASK, {
    fetchPolicy: 'no-cache',
    pollInterval: 2000,
  });

  const getRepairCostImportTask = (uuid: string) => {
    getData({
      variables: { id: uuid },
    });
  };

  return [
    getRepairCostImportTask,
    {
      repairCostImportTaskData: get('getRepairCostImportTask', data),
      ...rest,
    },
  ] as const;
};

export default useGetRepairCostImportTask;
