import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  DamageItemPayloadV2,
  DamageItemUpdateInputV2,
  DamageState,
  Item,
  Mutation,
  MutationUpdateQualityCheckApprovalDamageItemV2Args,
} from 'graphqlTypes';
import { getRefurbishmentItemIdFromCache } from './common/getRefurbishmentItemIdFromCache';
import { ItemFragmentQCA } from './gql/fragments';
import QUALITY_CHECK_APPROVAL_ITEMS from './gql/qualityCheckApprovalItems';
import UPDATE_QUALITY_CHECK_APPROVAL_DAMAGE_ITEM_V2 from './gql/updateQualityCheckApprovalDamageItemV2';

const useUpdateQualityCheckApprovalDamageItemV2 = (reviewId?: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckApprovalDamageItemV2'>,
    MutationUpdateQualityCheckApprovalDamageItemV2Args
  >(UPDATE_QUALITY_CHECK_APPROVAL_DAMAGE_ITEM_V2);
  const { cache } = useApolloClient();

  const updateQualityCheckApprovalDamageItem = (
    itemId: string,
    damageItemUpdateInput: DamageItemUpdateInputV2,
  ) => {
    const item = cache.readFragment<Item>({
      id: `Item:${itemId}`,
      fragment: ItemFragmentQCA,
      fragmentName: 'ItemFragmentQCA',
    });

    const refurbishmentItemId = reviewId
      ? getRefurbishmentItemIdFromCache(cache, reviewId)
      : undefined;

    return mutation({
      variables: {
        itemId,
        damageItemUpdateInput,
      },
      optimisticResponse: item
        ? {
            updateQualityCheckApprovalDamageItemV2: {
              ...item,
              data: {
                ...item.data,
                damageState: damageItemUpdateInput.state,
                stateComment: damageItemUpdateInput.stateComment,
              } as DamageItemPayloadV2 & { damageState: DamageState },
            },
          }
        : undefined,
      refetchQueries: refurbishmentItemId
        ? [
            {
              query: QUALITY_CHECK_APPROVAL_ITEMS,
              variables: {
                ids: [refurbishmentItemId],
              },
            },
          ]
        : undefined,
    });
  };

  return [updateQualityCheckApprovalDamageItem, options] as const;
};

export default useUpdateQualityCheckApprovalDamageItemV2;
