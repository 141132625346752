import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, Scalars } from 'graphqlTypes';
import GROUPS_BY_ID from './gql/groupsById';

const useGetGroupsById = (id?: Scalars['ID']) => {
  const { data, ...rest } = useQuery<Pick<Query, 'groups'>>(GROUPS_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    data: get(['groups', 0], data),
    ...rest,
  };
};

export default useGetGroupsById;
