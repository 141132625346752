import React from 'react';
import cns from 'classnames';

import LoadingIndicatorIcon from './LoadingIndicatorIcon';

import cn from './styles.module.scss';

interface Props {
  className?: string;
}

const LoadingIndicator = ({ className }: Props) => (
  <div className={cns(className, cn.root)} data-qa-selector="loadingIndicator">
    <LoadingIndicatorIcon />
  </div>
);

LoadingIndicator.Icon = LoadingIndicatorIcon;

export default LoadingIndicator;
