import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationAssignRolesArgs } from 'graphqlTypes';
import ASSIGN_ROLES from './gql/assignRoles';

const useAssignRoles = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'assignRoles'>,
    MutationAssignRolesArgs
  >(ASSIGN_ROLES);

  const assignRoles = (variables: MutationAssignRolesArgs) =>
    mutation({
      variables,
    });

  return [assignRoles, options] as const;
};

export default useAssignRoles;
