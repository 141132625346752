import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Mutation, MutationUpdateUserArgs } from 'graphqlTypes';
import UPDATE_USER from './gql/updateUser';

const useUpdateUser = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateUser'>,
    MutationUpdateUserArgs
  >(UPDATE_USER);

  const updateUser = (variables: MutationUpdateUserArgs) => {
    return mutation({
      variables,
    });
  };

  return [
    updateUser,
    {
      data: get(['updateUser'], data),
      ...rest,
    },
  ] as const;
};

export default useUpdateUser;
