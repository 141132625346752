import React from 'react';
import { Button } from 'react-bootstrap';
import cns from 'classnames';

import Divider from 'components/Divider';
import BackButton from '../../../../components/UI/BackButton';

import cn from './Footbar.module.scss';

interface Props {
  isBackButtonDisabled?: boolean;
  isNextButtonDisabled: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  submitButton: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;
}

const Footbar = ({
  isBackButtonDisabled = false,
  isNextButtonDisabled,
  isFirstStep,
  isLastStep,
  children,
  submitButton,
  onBackButtonClick,
  onNextButtonClick,
  className,
}: Props) => {
  const getNextButton = () => {
    if (isLastStep) {
      return <>{submitButton}</>;
    }

    return (
      <Button
        variant="secondary"
        className="btn-wide"
        onClick={onNextButtonClick}
        disabled={isNextButtonDisabled}
        data-qa-selector="entryCheckNext"
      >
        {__('refurbishment.footbar.nextButtonText')}
      </Button>
    );
  };

  return (
    <div className={cns(className, 'px-4 white-bg', cn.root)}>
      <Divider />
      <div className="d-flex flex-row-reverse justify-content-between py-4">
        <div className="align-self-center">{getNextButton()}</div>
        <div className={cn.middleArea}>{children}</div>
        <div>
          {!isFirstStep && (
            <BackButton
              onClick={onBackButtonClick}
              disabled={isBackButtonDisabled}
              qaSelector="entryCheckBack"
            >
              {__('refurbishment.footbar.backButtonText')}
            </BackButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footbar;
