import { MutationFunctionOptions } from '@apollo/react-common';
import { useMutation } from '@apollo/react-hooks';
import { PureQueryOptions } from 'apollo-client';

import { Mutation, MutationUpdateDocumentArgs } from 'graphqlTypes';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';
import UPDATE_DOCUMENT from './gql/updateDocument';

type UpdateDocumentMutation = Pick<Mutation, 'updateDocument'>;

type UpdateDocumentArgs = MutationFunctionOptions<
  UpdateDocumentMutation,
  MutationUpdateDocumentArgs
> & { refurbishmentId: string };

const useUpdateDocument = () => {
  const [mutation, options] = useMutation(UPDATE_DOCUMENT);

  const updateDocument = ({ refurbishmentId, ...rest }: UpdateDocumentArgs) => {
    const refetchQueries: (string | PureQueryOptions)[] =
      rest.refetchQueries && !(rest.refetchQueries instanceof Function)
        ? rest.refetchQueries
        : [];

    mutation({
      ...rest,
      refetchQueries: refetchQueries.concat([
        {
          query: REFURBISHMENT_DETAILS,
          variables: {
            id: refurbishmentId,
          },
        },
      ]),
    });
  };

  return [updateDocument, options] as const;
};

export default useUpdateDocument;
