import { setupApi } from 'modules/apiRest';

setupApi({
  REQUEST_NEW_PASSWORD: (email: string) => ({
    method: 'POST',
    url: '/api/v1/refurbishment-user/password-reset',
    body: {
      email,
    },
  }),
  CREATE_NEW_PASSWORD: (hash: string, newPassword: string) => ({
    method: 'POST',
    url: `/api/v1/refurbishment-user/password-set/${hash}`,
    body: {
      newPassword,
    },
  }),
});
