import gql from 'graphql-tag';

import {
  TaskDamageFragment,
  TaskPartnerWorkshopConnectionFragment,
  TaskRefurbishmentFragment,
  TaskVehicleFragment,
} from './fragments';

// TODO: fix fragments to use RefurbishmentTaskItemExcludingCostFragment
export default gql`
  query refurbishmentTaskItemsExcludingCosts($taskId: UUID!) {
    refurbishmentTaskItemsExcludingCosts(taskId: $taskId) {
      vehicle {
        ...TaskVehicleFragment
      }
      refurbishment {
        ...TaskRefurbishmentFragment
      }
      items {
        id
        state
        itemType
        partnerWorkshopConnection {
          ...TaskPartnerWorkshopConnectionFragment
        }
        service {
          id
          relatedServiceId
          damageId
          comment
          state
          stateComment
          sourceType
          createdOn
          updatedOn
          partnerWorkshopConnectionId
          serviceData {
            name
            type
          }
        }
        damage {
          ...TaskDamageFragment
        }
      }
      currentPartnerWorkshopConnection {
        id
      }
    }
  }
  ${TaskVehicleFragment}
  ${TaskRefurbishmentFragment}
  ${TaskPartnerWorkshopConnectionFragment}
  ${TaskDamageFragment}
`;
