import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { isNumber } from 'lodash/fp';

import {
  Mutation,
  MutationDeleteQualityCheckApprovalItemArgs,
  Query,
  QueryQualityCheckApprovalArgs,
} from 'graphqlTypes';
import { updateProviderTotalCost } from './common/updateProviderTotalCost';
import DELETE_QUALITY_CHECK_APPROVAL_ITEM from './gql/deleteQualityCheckApprovalItem';
import QUALITY_CHECK_APPROVAL from './gql/qualityCheckApproval';

const useDeleteQualityCheckApprovalItem = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'deleteQualityCheckApprovalItem'>,
    MutationDeleteQualityCheckApprovalItemArgs
  >(DELETE_QUALITY_CHECK_APPROVAL_ITEM);
  const client = useApolloClient();

  const deleteQualityCheckItem = (id: string) => {
    return mutation({
      variables: {
        id,
      },
      update(cache, { data }) {
        // update costs
        const providerTotalCostMinorUnits =
          data?.deleteQualityCheckApprovalItem.providerTotalCostsMinorUnits;

        if (isNumber(providerTotalCostMinorUnits)) {
          updateProviderTotalCost(reviewId, providerTotalCostMinorUnits, cache);
        }

        // update items
        const cachedQuery = cache.readQuery<
          Pick<Query, 'qualityCheckApproval'>,
          QueryQualityCheckApprovalArgs
        >({
          query: QUALITY_CHECK_APPROVAL,
          variables: {
            id: reviewId,
          },
        });

        if (cachedQuery) {
          cache.writeQuery({
            query: QUALITY_CHECK_APPROVAL,
            data: {
              qualityCheckApproval: {
                ...cachedQuery.qualityCheckApproval,
                items: cachedQuery.qualityCheckApproval.items?.filter(
                  ({ values }) => !values?.find(value => value.id === id),
                ),
              },
            },
            variables: {
              id: reviewId,
            },
          });
        }

        // This is necesary because vehicle details change in BE after some delete operations
        client.query<
          Pick<Query, 'qualityCheckApproval'>,
          QueryQualityCheckApprovalArgs
        >({
          query: QUALITY_CHECK_APPROVAL,
          variables: { id: reviewId },
          fetchPolicy: 'network-only',
        });
      },
    });
  };

  return [deleteQualityCheckItem, options] as const;
};

export default useDeleteQualityCheckApprovalItem;
