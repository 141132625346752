import gql from 'graphql-tag';

import { RepairCostImportTaskBasicFragment } from './fragments';

export default gql`
  query searchRepairCostImportTasks($page: Int!, $pageSize: Int!) {
    searchRepairCostImportTasks(page: $page, pageSize: $pageSize) {
      items {
        ...RepairCostImportTaskBasicFragment
      }
      pageInfo {
        page
        pageSize
        totalPageCount
        totalEntityCount
      }
    }
  }

  ${RepairCostImportTaskBasicFragment}
`;
