import { RefurbishmentSparePartStatus, SparePartStatus } from 'graphqlTypes';

export const refurbishmentSparePartStatusesTranslated = {
  [RefurbishmentSparePartStatus.NotAdded]: __('spareParts.statuses.notAdded'),
  [RefurbishmentSparePartStatus.NotOrdered]: __(
    'spareParts.statuses.notOrdered',
  ),
  [RefurbishmentSparePartStatus.IncompleteOrder]: __(
    'spareParts.statuses.incompleteOrder',
  ),
  [RefurbishmentSparePartStatus.Ordered]: __('spareParts.statuses.ordered'),
  [RefurbishmentSparePartStatus.PartiallyDelivered]: __(
    'spareParts.statuses.partiallyDelivered',
  ),
  [RefurbishmentSparePartStatus.Delivered]: __('spareParts.statuses.delivered'),
  [RefurbishmentSparePartStatus.MissingSpareParts]: __(
    'spareParts.statuses.missingSpareParts',
  ),
  [RefurbishmentSparePartStatus.HandedOut]: __('spareParts.statuses.handedOut'),
};

export const sparePartStatusesTranslated = {
  [SparePartStatus.SparePartsNotOrdered]: __(
    'sparePartStatus.sparePartsNotOrdered',
  ),
  [SparePartStatus.SparePartsPartiallyOrdered]: __(
    'sparePartStatus.sparePartsPartiallyOrdered',
  ),
  [SparePartStatus.SparePartsOrdered]: __('sparePartStatus.sparePartsOrdered'),
  [SparePartStatus.SparePartsMissing]: __('sparePartStatus.sparePartsMissing'),
  [SparePartStatus.SparePartsDelivered]: __(
    'sparePartStatus.sparePartsDelivered',
  ),
  [SparePartStatus.SparePartsHandedOut]: __(
    'sparePartStatus.sparePartsHandedOut',
  ),
};
