import gql from 'graphql-tag';

import { DamageItemPayloadV2FragmentQC } from './fragments';

export default gql`
  mutation updateQualityCheckDamageItemV2(
    $itemId: UUID!
    $damageItemUpdateInput: DamageItemUpdateInputV2!
  ) {
    updateQualityCheckDamageItemV2(
      itemId: $itemId
      damageItemUpdateInput: $damageItemUpdateInput
    ) {
      id
      data {
        payloadType
        ... on DamageItemPayloadV2 {
          damageState: state
        }
        ...DamageItemPayloadV2FragmentQC
      }
    }
  }
  ${DamageItemPayloadV2FragmentQC}
`;
