import React from 'react';
import { FormControlProps } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ControllerProps, FieldValues } from 'react-hook-form';

import DecimalInput from 'components/Form/DecimalInput';

import cn from './LaborTime.module.scss';

type Props<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    timeInHours: number;
    editable: boolean;
  };

const LaborTime = <T extends FieldValues>({
  timeInHours,
  editable,
  ...rest
}: Props<T>) => {
  if (!editable) {
    return (
      <span data-qa-selector="laborTimeRO">
        {timeInHours} {__('costTable.hours')}
      </span>
    );
  }

  return (
    <div className={cn.wrapper}>
      <Form.Control
        {...rest}
        as={DecimalInput}
        data-qa-selector="laborTimeEditable"
        className={cn.input}
      />
      <div className={cn.suffix}>{__('costTable.hours')}</div>
    </div>
  );
};

export default LaborTime;
