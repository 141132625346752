import { useQuery } from '@apollo/react-hooks';

import { Query, QuerySearchRepairMethodImportTasksArgs } from 'graphqlTypes';
import QUERY from './gql/searchRepairMethodImportTasks';

const useSearchRepairMethodImportTasks = (
  variables: QuerySearchRepairMethodImportTasksArgs,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'searchRepairMethodImportTasks'>,
    QuerySearchRepairMethodImportTasksArgs
  >(QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.searchRepairMethodImportTasks,
    ...rest,
  };
};

export default useSearchRepairMethodImportTasks;
