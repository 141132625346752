import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import CustomModal from 'components/UI/CustomModal';
import Text from 'components/UI/Text';
import {
  PartnerWorkshopConnection,
  RefurbishmentState,
  RefurbishmentTask,
} from 'graphqlTypes';
import { Role, useHasRole } from 'modules/acl';
import {
  useCreateRefurbishmentPartnerTaskEta,
  useUpdateRefurbishmentStateAndTaskState,
  useUpdateRefurbishmentTaskCurrentPartnerWorkshopConnection,
} from 'modules/api/refurbishmentTasks';
import useAuthorizedUser from 'modules/auth/useAuthorizedUser';
import { useLogger } from 'modules/logger';
import { getUrl } from 'modules/routeUtils';
import TaskItemForm, { TaskItemFormData } from './TaskItemForm';

type GetActionText = {
  taskId: string;
  state: RefurbishmentState;
  hasTasksWrite: boolean;
  hasCompletedTasks: boolean;
  userHasCurrentPWC: boolean;
};

type GetActionTextReturn = {
  type: 'button' | 'label';
  text?: string;
};

const getActionText = ({
  taskId,
  state,
  hasTasksWrite,
  hasCompletedTasks,
  userHasCurrentPWC,
}: GetActionText): GetActionTextReturn | null => {
  if (state === RefurbishmentState.RefurbishmentAuthorized) {
    if (hasTasksWrite) {
      return {
        type: 'button',
        text: __('refurbishmentTaskList.actionButton.startRefurbishment'),
      };
    }
    return null;
  }

  if (state === RefurbishmentState.RefurbishmentStarted) {
    if (hasCompletedTasks || !hasTasksWrite) {
      return null;
    }
    if (userHasCurrentPWC) {
      return {
        type: 'button',
        text: __('refurbishmentTaskList.actionButton.goToTasks'),
      };
    }
    return {
      type: 'button',
      text: __('refurbishmentTaskList.actionButton.carReceived'),
    };
  }

  if (state === RefurbishmentState.RefurbishmentCompleted) {
    return {
      type: 'label',
      text: __('refurbishmentTaskList.actionLabel.noActionPossible'),
    };
  }

  // TODO log error
  throw new Error(
    `Unhandled task action case:
- Task ID: ${taskId}
- State: ${state}
- Has TASK_WRITE role: ${hasTasksWrite}
- Has completed tasks: ${hasCompletedTasks}
- User has current PWC: ${userHasCurrentPWC}`,
  );
};

type Props = {
  task: RefurbishmentTask;
  onStateChange?(): void;
};

const TaskItemAction = ({ task, onStateChange }: Props) => {
  const { logError } = useLogger();
  const navigate = useNavigate();
  const { user } = useAuthorizedUser();
  const [updateState] = useUpdateRefurbishmentStateAndTaskState();
  const [
    updateCurrentPartnerWorkshopConnection,
  ] = useUpdateRefurbishmentTaskCurrentPartnerWorkshopConnection();
  const [
    createRefurbishmentPartnerTaskEta,
  ] = useCreateRefurbishmentPartnerTaskEta();
  const hasTasksWrite = useHasRole(Role.TASK_WRITE);
  const [showModal, setShowModal] = useState(false);

  if (hasTasksWrite === undefined) return null;

  const state = task.refurbishment.refurbishmentState.key;
  const hasCompletedTasks =
    task.completedTaskItemsCount === task.totalTaskItemsCount;
  const userHasCurrentPWC =
    !!task.currentPartnerWorkshopConnection &&
    [
      ...(user?.userPartnerWorkshopConnections?.flatMap(
        pwc => pwc.partnerWorkshopConnections,
      ) || []),
    ]
      .filter((pwc): pwc is PartnerWorkshopConnection => !!pwc)
      .map(pwc => pwc.id)
      .includes(task.currentPartnerWorkshopConnection.id);

  let action;

  try {
    action = getActionText({
      taskId: task.id,
      state,
      hasTasksWrite,
      hasCompletedTasks,
      userHasCurrentPWC,
    });
  } catch (error) {
    action = null;
    logError({ error });
  }

  if (!action) return null;

  const showETA =
    state === RefurbishmentState.RefurbishmentStarted && !userHasCurrentPWC;

  const updateRefurbishmentState = async (newState: RefurbishmentState) => {
    try {
      const result = await updateState(
        task.id,
        task.refurbishment.refurbishmentId,
        newState,
      );

      if (
        onStateChange &&
        result.data?.updateRefurbishmentStateAndTaskState?.refurbishmentState
          .key === newState
      ) {
        onStateChange();
      }
    } catch (error) {
      logError({ error });
    }
  };

  const handleActionButtonClick = () => {
    if (state === RefurbishmentState.RefurbishmentAuthorized) {
      updateRefurbishmentState(RefurbishmentState.RefurbishmentStarted);
      return;
    }

    if (state === RefurbishmentState.RefurbishmentCompleted) {
      updateRefurbishmentState(RefurbishmentState.QualityCheckOrdered);
      return;
    }

    if (state === RefurbishmentState.RefurbishmentStarted) {
      if (hasCompletedTasks) {
        updateRefurbishmentState(RefurbishmentState.RefurbishmentCompleted);
        return;
      }

      if (!userHasCurrentPWC) {
        setShowModal(true);
        return;
      }

      navigate(
        getUrl('REFURBISHMENT_TASK_DETAIL', {
          id: task.id,
        }),
      );
    }
  };

  const handleModalSubmit = async (values: TaskItemFormData) => {
    if (state === RefurbishmentState.RefurbishmentAuthorized) {
      try {
        if (
          values.partnerWorkshopConnectionId !==
          task.currentPartnerWorkshopConnection?.id
        ) {
          updateCurrentPartnerWorkshopConnection(
            task.id,
            values.partnerWorkshopConnectionId,
          );
        }
        await updateRefurbishmentState(RefurbishmentState.RefurbishmentStarted);
        setShowModal(false);
      } catch (error) {
        logError({ error });
      }
      return;
    }

    if (state === RefurbishmentState.RefurbishmentStarted) {
      try {
        await updateCurrentPartnerWorkshopConnection(
          task.id,
          values.partnerWorkshopConnectionId,
        );

        await createRefurbishmentPartnerTaskEta({
          taskId: task.id,
          partnerWorkshopConnectionId: values.partnerWorkshopConnectionId,
          eta: values.eta,
        });

        if (onStateChange) onStateChange();

        setShowModal(false);
      } catch (error) {
        logError({ error });
      }
      return;
    }

    // TODO log error
    logError({
      error: new Error(
        `Unhandled case for task action submit:
  - Task ID: ${task.id}
  - State: ${state}
  - Task PWC: ${task.currentPartnerWorkshopConnection?.id}
  - Submitted PWC ${values.partnerWorkshopConnectionId}`,
      ),
    });
  };

  if (action.type === 'button') {
    return (
      <>
        <Button
          onClick={handleActionButtonClick}
          data-qa-selector="action-button"
        >
          {action.text}
        </Button>

        <CustomModal
          show={showModal}
          onHide={() => setShowModal(false)}
          qaSelector="taskItemAction-modal"
        >
          <Modal.Body>
            <TaskItemForm
              task={task}
              showETA={showETA}
              onSubmit={handleModalSubmit}
              onCancel={() => setShowModal(false)}
            />
          </Modal.Body>
        </CustomModal>
      </>
    );
  }

  if (action.type === 'label') {
    return <Text qaSelector="action-label">{action.text}</Text>;
  }

  return null;
};

export default TaskItemAction;
