import React, { useEffect } from 'react';
import { find, flatMap, flow, get, identity } from 'lodash/fp';
import { Option } from 'types';

import SelectInput from 'components/Form/SelectInput';
import { useGetDamageTypesLazy } from 'modules/api/damages';
import { CarPart } from 'routes/RefurbishmentRoute/types';
import getDamageTypesOptions from '../../helpers/getDamageTypesOptions';
import useCarParts from '../../hooks/useCarParts';

const getCarPartId = (value: any, carParts: any) => {
  if (value.id) return value.id;

  const name = typeof value === 'string' ? value : value.name;

  return flow(
    flatMap(identity),
    find((part: any) => part.value.name === name),
    get(['value', 'id']),
  )(carParts);
};

interface Props {
  name: string;
  control: any;
  selectedCarPart: Option<CarPart> | CarPart | string | undefined;
  disabled?: boolean;
}

const DamageTypeSelectControl = ({
  name,
  control,
  selectedCarPart,
  disabled,
}: Props) => {
  const [getDamageTypes, { data, loading }] = useGetDamageTypesLazy();
  const { carParts } = useCarParts();

  const damageTypesOptions = getDamageTypesOptions(data);

  useEffect(() => {
    if (selectedCarPart) {
      const id = getCarPartId(selectedCarPart, carParts);
      if (id) {
        getDamageTypes(id);
      }
    }
  }, [selectedCarPart, carParts]);

  return (
    <SelectInput
      name={name}
      options={damageTypesOptions}
      isLoading={loading}
      disabled={disabled || damageTypesOptions.length === 0}
      control={control}
      rules={{
        required: __('field.required'),
      }}
      dataQa="damage-type-select"
      dataQaOption="damage-type-option"
    />
  );
};

export default DamageTypeSelectControl;
