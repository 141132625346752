import { Component, Fragment } from 'react';
import stringReplace from 'react-string-replace';
import { isEqual, map } from 'lodash/fp';
import PropTypes from 'prop-types';

import { callIf, isPopulatedString } from '../utils/fp';
import { stringFormatter } from '../utils/stringFormatter';

export default class FormattedMessage extends Component {
  shouldComponentUpdate(nextProps) {
    const { text, values } = this.props;

    return text !== nextProps.text || !isEqual(values, nextProps.values);
  }

  render() {
    const { text, values } = this.props;

    let replacedText = stringReplace(text, /\{(\w+)\}/g, (match, i) => (
      <Fragment key={match + i}>{values[match] ?? `{${match}}`}</Fragment>
    ));

    replacedText = map(
      callIf(isPopulatedString, textFragment =>
        stringFormatter(textFragment)(values),
      ),
    )(replacedText);

    replacedText = stringReplace(replacedText, /(\*.+?\*)/g, (match, i) => (
      <strong key={match + i}>{match.slice(1, -1)}</strong>
    ));

    replacedText = stringReplace(replacedText, /(\n)/g, (match, i) => (
      <br key={match + i} />
    ));

    return <>{replacedText}</>;
  }
}

FormattedMessage.propTypes = {
  text: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};
