import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import BRAKE_PAD_CONDITIONS from './gql/brakePadConditions';

const useBrakePadConditions = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'brakePadConditions'>, null>(
    BRAKE_PAD_CONDITIONS,
  );

  return {
    data: get('brakePadConditions', data),
    ...rest,
  };
};

export default useBrakePadConditions;
