import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import GROUPS from './gql/groups';

const useGetGroups = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'groups'>>(GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: get('groups', data),
    ...rest,
  };
};

export default useGetGroups;
