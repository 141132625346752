import { useMutation } from '@apollo/react-hooks';

import DELETE_COMMENT from './gql/deleteComment';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';

const useDeleteComment = () => {
  const [mutation, options] = useMutation(DELETE_COMMENT);

  const deleteComment = async ({ refId, ...rest }) => {
    const refetchQueries = rest.refetchQueries || [];
    await mutation({
      ...rest,
      refetchQueries: refetchQueries.concat([
        {
          query: REFURBISHMENT_DETAILS,
          variables: {
            id: refId,
          },
        },
      ]),
    });
  };

  return [deleteComment, options];
};

export default useDeleteComment;
