import gql from 'graphql-tag';

export default gql`
  query refurbishmentTasks(
    $page: Int!
    $pageSize: Int!
    $filter: RefurbishmentTaskFilter
  ) {
    refurbishmentTasks(page: $page, pageSize: $pageSize, filter: $filter) {
      items {
        id
        completedTaskItemsCount
        totalTaskItemsCount
        refurbishment {
          refurbishmentId
          refurbishmentNumber
          refurbishmentState
          orderedOn
          latestReworkDate
        }
        vehicle {
          make
          model
          subModel
          vin
          stockNumber
          overviewImageFullUrl
        }
        currentPartnerWorkshopConnection {
          id
          mainWorkshop {
            name
          }
          repairWorkshop {
            name
          }
          repairPartner {
            name
          }
        }
      }
      pageInfo {
        page
        pageSize
        totalEntityCount
        totalPageCount
      }
    }
  }
`;
