import gql from 'graphql-tag';

import {
  ExitCheckCommentFragment,
  ExitCheckDocumentFragment,
  ExitCheckPhotoFragment,
} from './fragments';

export default gql`
  query exitCheck($id: UUID!) {
    exitCheck(id: $id) {
      values {
        id
        valueType
        originalValue {
          ... on MileageEcv {
            payloadType
            distance
            unit
          }
          ... on FuelLevelEcv {
            payloadType
            level
          }
          ... on WarningLightsEcv {
            payloadType
            brakeWarningLight
            batteryWarningLight
            engineOilLevel
            airbagWarningLight
            lowTirePressureWarningLight
            engineWarningLight
            brakePadWarningLight
            tractionControlSystemWarningLight
            coolantTemperatureWarningLight
            absWarningLight
          }
          ... on GpsEcv {
            payloadType
            exists
            type
            manufacturer
          }
          ... on BatteryEcv {
            age {
              week
              year
            }
            quality
            voltage
            comment
          }
          ... on RadioEcv {
            payloadType
            exists
            manufacturer
          }
        }
        proposedValue {
          ... on MileageEcv {
            payloadType
            distance
            unit
          }
          ... on FuelLevelEcv {
            payloadType
            level
          }
          ... on WarningLightsEcv {
            payloadType
            brakeWarningLight
            batteryWarningLight
            engineOilLevel
            airbagWarningLight
            lowTirePressureWarningLight
            engineWarningLight
            brakePadWarningLight
            tractionControlSystemWarningLight
            coolantTemperatureWarningLight
            absWarningLight
          }
          ... on GpsEcv {
            payloadType
            exists
            type
            manufacturer
          }
          ... on BatteryEcv {
            age {
              week
              year
            }
            quality
            voltage
            comment
          }
          ... on RadioEcv {
            payloadType
            exists
            manufacturer
          }
        }
        state
      }
      services {
        id
        serviceName
        serviceBudget
        comment
        state
      }
      serviceWork {
        exitCheckId
        serviceBudget
        comment
        serviceCompany
        serviceMileage {
          distance
          unit
        }
        serviceDate
        serviceDueMileage {
          distance
          unit
        }
        serviceDueDate
        serviceBookType
        createdOn
        updatedOn
        createdById
        updatedById
        serviceWorkItems
      }
      vehicle {
        make
        model
        subModel
        vin
        stockNumber
        licensePlate
        mileage {
          distance
          unit
        }
        fuelType
        gearType
        registration
        sourcingCountry
        lastServiceOn
        technicalInspectionCertificateIssueDate
        technicalInspectionCertificateIssueMileage {
          distance
          unit
        }
        inspectionExpiryDate
        wheelSets {
          isPrimary
          wheels {
            position
            season
            profile {
              depth
              unit
            }
            rimType
          }
        }
        electricBatteryDetails {
          isLeased
          leasingCost
          hasConditionReport
          conditionStatus
          capacity
          range
          batteryDocumentUrl
        }
      }
      damages {
        imageUrl
        partName
        area
        subArea
        serviceName
        serviceBudget
        id
        comment
        typeName
        state
      }
      imperfections {
        id
        imageUrl
        partName
        area
        typeName
        subArea
      }
      details {
        id
        adId
        state
        createdOn
        repairEta
        currencyCode
        secondaryWheelsInfo
        completedOn
        location {
          id
          name
        }
        isHomeDelivered
        deliveryType
      }
      documents {
        ...ExitCheckDocumentFragment
      }
      totalCost
      totalCostTaken
      comments {
        ...ExitCheckCommentFragment
      }
      refurbishment {
        id
        country
        vehicleInspection {
          lastInspectionDate
          lastInspectionDueDate
          lastMileage {
            distance
            unit
          }
        }
        documents {
          ...ExitCheckDocumentFragment
        }
        location {
          id
        }
        keysCount
        serviceBookType
        serviceBookPresent
        tags
      }
      photos {
        ...ExitCheckPhotoFragment
      }
      verificationQuestions {
        id
        exitCheckId
        question
        answer
        blocksExitCheckSubmit
        orderNumber
        updatedOn
        updatedById
      }
    }
  }
  ${ExitCheckDocumentFragment}
  ${ExitCheckCommentFragment}
  ${ExitCheckPhotoFragment}
`;
