import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Mutation, MutationSubmitEntryCheckArgs } from 'graphqlTypes';
import SUBMIT_ENTRY_CHECK from './gql/submitEntryCheck';

const useSubmitEntryCheck = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'submitEntryCheck'>,
    MutationSubmitEntryCheckArgs
  >(SUBMIT_ENTRY_CHECK);

  const submitEntryCheck = (id: string) => {
    return mutation({
      variables: { id },
    });
  };

  return [
    submitEntryCheck,
    {
      data: get('submitEntryCheck', data),
      ...rest,
    },
  ] as const;
};

export default useSubmitEntryCheck;
