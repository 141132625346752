import { useMutation } from '@apollo/react-hooks';

import {
  GroupUpdateInput,
  Mutation,
  MutationUpdateGroupArgs,
} from 'graphqlTypes';
import GROUPS_BY_ID from './gql/groupsById';
import UPDATE_GROUP from './gql/updateGroup';

const useUpdateGroup = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateGroup'>,
    MutationUpdateGroupArgs
  >(UPDATE_GROUP);

  const updateGroup = (id: string, input: GroupUpdateInput) =>
    mutation({
      variables: {
        id,
        input,
      },
      refetchQueries: [
        {
          query: GROUPS_BY_ID,
          variables: {
            id,
          },
        },
      ],
    });

  return [updateGroup, options] as const;
};

export default useUpdateGroup;
