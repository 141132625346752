import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Mutation,
  MutationCompleteQualityCheckApprovalArgs,
  ReviewState,
} from 'graphqlTypes';
import COMPLETE_QUALITY_CHECK_APPROVAL from './gql/completeQualityCheckApproval';

const useCompleteQualityCheckApproval = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'completeQualityCheckApproval'>,
    MutationCompleteQualityCheckApprovalArgs
  >(COMPLETE_QUALITY_CHECK_APPROVAL);

  const completeQualityCheckApproval = (phaseId: string, reviewId: string) => {
    return mutation({
      variables: { id: phaseId },
      update(cache, { data }) {
        if (data?.completeQualityCheckApproval) {
          cache.writeFragment({
            id: `Review:${reviewId}`,
            fragment: gql`
              fragment review on Review {
                state
              }
            `,
            data: {
              state: ReviewState.Completed,
              __typename: 'Review',
            },
          });
        }
      },
    });
  };

  return [completeQualityCheckApproval, options] as const;
};

export default useCompleteQualityCheckApproval;
