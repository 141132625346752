import { get } from 'lodash/fp';

import { unitsFromMajorToMinor } from 'modules/currency';
import { isCorrectCostValue } from 'routes/RefurbishmentRoute/helpers/isCorrectCostValue';
import { ServiceFormData } from './ServiceForm';
import { ServiceInput } from '../../types';

export const serviceFormToInput = (
  values: ServiceFormData,
  hasSplitCost?: boolean,
) => {
  const budgetMajorUnits = get(['budgetMajorUnits'], values);
  const consumableCostMajorUnits = get(['consumableCost'], values);
  const sparePartMajorUnits = get(['sparePartCost'], values);
  const laborTime = get(['laborTime'], values);
  const laborRateMajorUnits = get(['laborRate'], values);

  const serviceInput: ServiceInput = {
    type: get(['service', 'type'], values),
    name: get(['service', 'name'], values),
    budgetMinorUnits: unitsFromMajorToMinor(budgetMajorUnits),
    ...(hasSplitCost && {
      budgetMinorUnits: isCorrectCostValue(budgetMajorUnits)
        ? unitsFromMajorToMinor(budgetMajorUnits)
        : null,
      consumableCostMinorUnits: isCorrectCostValue(consumableCostMajorUnits)
        ? unitsFromMajorToMinor(consumableCostMajorUnits)
        : null,
      sparePartCostMinorUnits: isCorrectCostValue(sparePartMajorUnits)
        ? unitsFromMajorToMinor(sparePartMajorUnits)
        : null,
      laborTimeHours: isCorrectCostValue(laborTime) ? laborTime : null,
      laborRateMinorUnits: isCorrectCostValue(laborRateMajorUnits)
        ? unitsFromMajorToMinor(laborRateMajorUnits)
        : null,
    }),
    comment: get(['comment'], values) || null,
    partnerWorkshopConnectionId:
      get('partnerWorkshopConnectionId', values) || null,
  };
  return serviceInput;
};
