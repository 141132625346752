import React from 'react';
import { Collapse } from 'react-bootstrap';
import cns from 'classnames';

import arrowDown from 'images/arrow-down.svg';
import arrowUp from 'images/arrow-up.svg';

import cn from './CollapsableSidebarGroup.module.scss';

type Props = {
  name: string;
  qaSelector: string;
  isDisabled: boolean;
  isHighlighted: boolean;
  children: React.ReactNode;
};

const CollapsableSidebarGroup = ({
  name,
  qaSelector,
  isDisabled,
  isHighlighted,
  children,
}: Props) => {
  const collapseId = `collapse.${name}`;
  const [open, setOpen] = React.useState(!isDisabled);
  const [wasDisabled, setWasDisabled] = React.useState(isDisabled);
  if (wasDisabled && !isDisabled) {
    setWasDisabled(false);
    setOpen(true);
  }
  const toggleImg = open ? arrowUp : arrowDown;
  const toggleName = open
    ? __('entryCheck.step.hide')
    : __('entryCheck.step.show');
  const stateClassName = open ? cn.open : '';
  const disabledClassName = isDisabled ? cn.disabled : '';

  return (
    <div data-qa-selector={qaSelector}>
      <div
        className={[
          cn.toggleContainer,
          'row',
          stateClassName,
          disabledClassName,
        ].join(' ')}
      >
        <div className="col-10">
          <span
            className={cns(cn.name, {
              [cn.highlighted]: isHighlighted,
            })}
          >
            {name}
          </span>
        </div>
        {!isDisabled && (
          <div className={[cn.toggleBtnContainer, 'col-2'].join(' ')}>
            <button
              onClick={() => setOpen(!open)}
              className="btn btn-link"
              type="button"
              aria-expanded={open}
              aria-controls={collapseId}
            >
              {' '}
              <img
                src={toggleImg}
                className={cn.toggleImage}
                alt={toggleName}
              />
            </button>
          </div>
        )}
      </div>
      <div className={cns(cn.narrowName, disabledClassName)}>{name}</div>
      <Collapse in={open}>
        <div id={collapseId} className={cn.collapsibleContent}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsableSidebarGroup;
