import React from 'react';
import cns from 'classnames';

import cn from './DataItem.module.scss';

interface LabelProps {
  children: string;
  className?: string;
  qaSelector?: string;
  required?: boolean;
}

const DataItemLabel = ({
  children,
  className,
  qaSelector,
  required,
}: LabelProps) => {
  return (
    <div
      className={cns(
        'data-item-label',
        'text-capitalize',
        cn.label,
        { [cn.required]: required },
        className,
      )}
      data-qa-selector={qaSelector ? `${qaSelector}Label` : 'label'}
    >
      {children}
    </div>
  );
};

interface ContentProps {
  children: React.ReactNode;
  className?: string;
  qaSelector?: string;
}

const DataItemContent = ({ children, className, qaSelector }: ContentProps) => {
  return (
    <div
      className={cns(
        'd-flex justify-content-between data-item-content',
        cn.data,
        className,
      )}
      data-qa-selector={qaSelector ? `${qaSelector}Value` : 'value'}
    >
      {children}
    </div>
  );
};

export type DataItemProps = {
  label: string;
  required?: boolean;
  highlight?: boolean;
  strike?: boolean;
  strikeSecondValue?: boolean;
  className?: string;
  children: React.ReactNode;
  secondValue?: React.ReactNode;
  qaSelector?: string;
};

const DataItem = ({
  label,
  required,
  highlight,
  strike,
  strikeSecondValue,
  className,
  children = '-',
  secondValue,
  qaSelector,
}: DataItemProps) => {
  return (
    <div className={className} data-qa-selector={qaSelector}>
      <DataItemLabel required={required} qaSelector={qaSelector}>
        {label}
      </DataItemLabel>
      <DataItemContent
        qaSelector={qaSelector}
        className={cns({ [cn.highlight]: highlight, [cn.strike]: strike })}
      >
        {children}
      </DataItemContent>
      {!!secondValue && (
        <div
          className={cns('data-item-second-value', {
            [cn.strike]: strikeSecondValue,
          })}
          data-qa-selector={
            qaSelector ? `${qaSelector}SecondValue` : 'secondValue'
          }
        >
          {secondValue}
        </div>
      )}
    </div>
  );
};

DataItem.Label = DataItemLabel;
DataItem.Content = DataItemContent;

export default DataItem;
