import { useEffect, useRef } from 'react';

const useFixedFilterButtons = () => {
  const filterButtonContainerRef = useRef<HTMLDivElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const filterContainer = filterButtonContainerRef.current;
    const tableContainer = tableContainerRef.current;

    if (filterContainer && tableContainer) {
      const initialFilterWidth = filterContainer.offsetWidth;

      const setFilterButtonPosition = () => {
        const leftPosition =
          tableContainer.offsetWidth - initialFilterWidth - 24; // 24 = 1.5rem
        const left = leftPosition + tableContainer.scrollLeft;
        filterContainer.setAttribute('style', `left: ${left}px`);
      };

      tableContainer.addEventListener('scroll', setFilterButtonPosition);
      window.addEventListener('resize', setFilterButtonPosition);

      return () => {
        tableContainer.removeEventListener('scroll', setFilterButtonPosition);
        window.removeEventListener('resize', setFilterButtonPosition);
      };
    }
  }, [filterButtonContainerRef.current, tableContainerRef.current]);

  return [filterButtonContainerRef, tableContainerRef] as const;
};

export default useFixedFilterButtons;
