import gql from 'graphql-tag';

export default gql`
  mutation updateRefurbishmentStateAndTaskState(
    $taskId: UUID!
    $refurbishmentId: UUID!
    $refurbishmentState: RefurbishmentState!
  ) {
    updateRefurbishmentStateAndTaskState(
      taskId: $taskId
      refurbishmentId: $refurbishmentId
      refurbishmentState: $refurbishmentState
    ) {
      refurbishmentState
    }
  }
`;
