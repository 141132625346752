import { useMutation } from '@apollo/react-hooks';

import {
  DamageItemCreateInputV2,
  Mutation,
  MutationCreateQualityCheckApprovalDamageItemV2Args,
} from 'graphqlTypes';
import CREATE_QUALITY_CHECK_DAMAGE_APPROVAL_ITEM from './gql/createQualityCheckApprovalDamageItem';
import QUALITY_CHECK_APPROVAL from './gql/qualityCheckApproval';

const useCreateQualityCheckApprovalDamageItem = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'createQualityCheckApprovalDamageItemV2'>,
    MutationCreateQualityCheckApprovalDamageItemV2Args
  >(CREATE_QUALITY_CHECK_DAMAGE_APPROVAL_ITEM);

  const createQualityCheckApprovalDamageItem = (
    damageItemCreateInput: DamageItemCreateInputV2,
  ) => {
    return mutation({
      variables: {
        damageItemCreateInput,
      },
      refetchQueries: reviewId
        ? [
            {
              query: QUALITY_CHECK_APPROVAL,
              variables: {
                id: reviewId,
              },
            },
          ]
        : undefined,
    });
  };

  return [createQualityCheckApprovalDamageItem, options] as const;
};

export default useCreateQualityCheckApprovalDamageItem;
