import { Navigate } from 'react-router-dom';

import { Role, useHasRole } from 'modules/acl';
import { getUrl } from 'modules/routeUtils';

export const HomeRoute = () => {
  const userHasTaskReadRol = useHasRole(Role.TASK_READ);
  const userHasReadRol = useHasRole(Role.READ);

  if (userHasReadRol === undefined || userHasTaskReadRol === undefined) {
    return null;
  }

  if (!userHasReadRol && userHasTaskReadRol) {
    return <Navigate to={getUrl('REFURBISHMENT_TASK')} />;
  }

  return <Navigate to={getUrl('REFURBISHMENT')} />;
};
