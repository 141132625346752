import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryUserSearchArgs } from 'graphqlTypes';
import USER_SEARCH from './gql/userSearch';

const useSearchUsers = () => {
  const [getData, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'userSearch'>,
    QueryUserSearchArgs
  >(USER_SEARCH, {
    fetchPolicy: 'cache-and-network',
  });

  const searchUsers = (variables: QueryUserSearchArgs) => {
    getData({
      variables,
    });
  };

  return [
    searchUsers,
    {
      data: get(['userSearch', 'items'], data),
      pageInfo: get(['userSearch', 'pageInfo'], data),
      ...rest,
    },
  ] as const;
};

export default useSearchUsers;
