import { Query, QueryRepairCostSearchArgs } from 'graphqlTypes';
import REPAIR_COST_SEARCH from './gql/repairCostSearch';
import usePromiseQuery from '../helpers/usePromiseQuery';

const useRepairCostSearchPromise = () => {
  const query = usePromiseQuery<
    Pick<Query, 'repairCostSearch'>,
    QueryRepairCostSearchArgs
  >(REPAIR_COST_SEARCH, { fetchPolicy: 'no-cache' });

  const getRepairCost = async (variables: QueryRepairCostSearchArgs) => {
    const { data } = await query({
      ...variables,
    });

    return data?.repairCostSearch;
  };

  return getRepairCost;
};

export default useRepairCostSearchPromise;
