import {
  BeautyShotTranslation,
  CurrencyCode,
  Document,
  Maybe,
  RefurbishmentDetails,
  ReviewState,
  Scalars,
  StringTranslation,
  WheelSetDeleteReasonTranslation,
  WheelSetSwitchVerificationStatus,
} from 'graphqlTypes';
import {
  Budgets,
  DamageItem,
  DetailedBudget,
  PartnerWorkshopConnectionRefurbishment,
  VehicleDetails,
  WheelItem,
} from 'routes/RefurbishmentRoute/types';
import DamageQualityCheck from './DamageQualityCheck/DamageQualityCheck';
import ServiceQualityCheck from './ServiceQualityCheck';
import ServiceWorkQualityCheck from './ServiceWorkQualityCheck';
import { PartnersList } from '../helpers/mapData/getDetailedBudgets';

export interface RefurbishmentSummary {
  refurbishmentId: string;
  itemId: string;
  refId: string;
  adId: string;
  currencyCode: CurrencyCode;
  state: string;
  vin: string;
  stockNumber: string;
  model: string;
  lastStateUpdatedOn: string;
  hasSecondaryWheels: boolean;
  wheelSets: {
    switched: boolean;
    switchedAction?: WheelSetSwitchVerificationStatus;
    deleted: boolean;
    deletedReason: WheelSetDeleteReasonTranslation;
  };
  beautyShot: BeautyShotTranslation;
  picturesReceivedOn: string;
  refurbishmentEta: string;
  serviceBookType?: Maybe<StringTranslation>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
}

export interface QualityCheckMap {
  id: string;
  state: ReviewState;
  phaseId: string;
  reviewId: string;
  refurbishmentSummary: RefurbishmentSummary;
  vehicleDetails: VehicleDetails;
  wheels: Array<WheelItem<DamageQualityCheck>>;
  documents: Array<Document>;
  /**
   * @deprecated
   * Consider refactoring your code to use `damagesMapped` instead of `damages`
   */
  damages: Array<DamageItem>; // TODO: remove
  damagesMapped: Array<DamageQualityCheck>;
  services: Array<ServiceQualityCheck>;
  servicesWork: Array<ServiceWorkQualityCheck>;
  budgets: Budgets;
  detailedBudget: DetailedBudget;
  disabled: boolean;
  hasReworkItems: boolean;
  refurbishment: RefurbishmentDetails;
  hasQualityCheckAuthorization: boolean;
  hasServiceBook: boolean;
  hasImperfectionPhotos: boolean;
  hasImprovedTireDataAccuracy: boolean;
  partnerWorkshopConnectionRefurbishments?: PartnerWorkshopConnectionRefurbishment[];
  imperfections: DamageQualityCheck[];
  readyForImperfectionPhotosOn?: Maybe<Scalars['LocalDate']>;
  hasWriteRole: boolean;
  hasServiceBookType: boolean;
  hasSplitCost?: boolean;
  partnersList: PartnersList[];
  isImperfectionRepairMethods: boolean;
}

export enum StepName {
  VehicleWheels = 'VEHICLE_WHEELS',
  RefurbishmentDefaults = 'REFURBISHMENT_DEFAULTS',
  ServiceBook = 'SERVICE_BOOK',
  Damages = 'DAMAGES',
  Imperfections = 'IMPERFECTIONS',
  VehicleServices = 'VEHICLE_SERVICES',
  RefurbishmentOverview = 'REFURBISHMENT_OVERVIEW',
  Summary = 'SUMMARY',
  VehicleInspection = 'VEHICLE_INSPECTION',
  ImperfectionShotsFront = 'IMPERFECTION_SHOTS_FRONT',
  ImperfectionShotsRight = 'IMPERFECTION_SHOTS_RIGHT',
  ImperfectionShotsBack = 'IMPERFECTION_SHOTS_BACK',
  ImperfectionShotsLeft = 'IMPERFECTION_SHOTS_LEFT',
  ImperfectionShotsRoof = 'IMPERFECTION_SHOTS_ROOF',
  ImperfectionShotsInterior = 'IMPERFECTION_SHOTS_INTERIOR',
  ImperfectionShotsWheels = 'IMPERFECTION_SHOTS_WHEELS',
  ImperfectionShotsUnderbody = 'IMPERFECTION_SHOTS_UNDERBODY',
}
