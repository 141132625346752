import { useEffect } from 'react';
import { castArray, find, flatten, flow, get, includes, map } from 'lodash/fp';

import { useGetCurrentUser } from 'modules/api/users';
import { useAuth } from 'modules/auth';
import { Role } from '../role';

const useHasRole = (role?: Role | Role[]): boolean | undefined => {
  const { info } = useAuth();
  const [getUser, { user, loading }] = useGetCurrentUser();

  useEffect(() => {
    if (info.isAuthorized) {
      getUser(info.userUuid);
    }
  }, [info.userUuid]);

  if (!user || loading) return undefined;

  return flow(
    get(['groups']),
    map('roles'),
    flatten,
    map('name'),
    find(roleName => flow(castArray, includes(roleName))(role)),
    Boolean,
  )(user);
};

export default useHasRole;
