import { Route, Routes } from 'react-router-dom';

import { Role, RoleAccess } from 'modules/acl';
import DetailRoute from './DetailRoute';
import DuplicateRoute from './DuplicateRoute';
import NewRoute from './NewRoute';
import OverviewRoute from './OverviewRoute';

const UserRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RoleAccess roles={Role.USERS_ADMIN}>
            <OverviewRoute />
          </RoleAccess>
        }
      />
      <Route
        path="new"
        element={
          <RoleAccess roles={Role.USERS_ADMIN}>
            <NewRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/duplicate"
        element={
          <RoleAccess roles={Role.USERS_ADMIN}>
            <DuplicateRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id"
        element={
          <RoleAccess roles={Role.USERS_ADMIN}>
            <DetailRoute />
          </RoleAccess>
        }
      />
    </Routes>
  );
};

export default UserRoute;
