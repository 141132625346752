import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from 'modules/api/partnerWorkshop/gql/fragments';
import {
  DamageItemPayloadV2FragmentQCA,
  QualityCheckApprovalVehicleItemPayloadFragment,
  RefurbishmentItemPayloadFragment,
  ServiceItemPayloadFragment,
} from './fragments';
import {
  DocumentFragment,
  PartnerWorkshopConnectionRefurbishmentResponseFragment,
} from '../../refurbishments/gql/fragments';

const QualityCheckApprovalLocationFragment = gql`
  fragment QualityCheckApprovalLocationFragment on Location {
    id
  }
`;

const QualityCheckApprovalRefurbishmentDetailsFragment = gql`
  fragment QualityCheckApprovalRefurbishmentDetailsFragment on RefurbishmentDetails {
    id
    adId
    refId
    state
    currencyCode
    country
    lastStateUpdatedOn
    providerEstimatedCompleteDate
    beautyShot
    picturesReceivedOn
    vehicle {
      stockNumber
      vin
      make
      model
      subModel
      fuelType
      lastServiceMileage {
        distance
        unit
      }
      lastServiceOn
      technicalInspectionCertificateIssueDate
      technicalInspectionCertificateIssueMileage {
        distance
        unit
      }
      gearType
      registration
      registrationUrl
      sourcingCountry
      mileage {
        distance
        unit
      }
    }
    documents {
      ...DocumentFragment
    }
    location {
      ...QualityCheckApprovalLocationFragment
    }
    currentPartnerWorkshopConnection {
      ...PartnerWorkshopConnectionFragment
    }
    partnerWorkshopConnections {
      ...PartnerWorkshopConnectionRefurbishmentResponseFragment
    }
    tags
  }
  ${DocumentFragment}
  ${QualityCheckApprovalLocationFragment}
  ${PartnerWorkshopConnectionFragment}
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
`;

export default gql`
  query qualityCheckApproval($id: UUID!) {
    qualityCheckApproval(id: $id) {
      id
      state
      refurbishment {
        ...QualityCheckApprovalRefurbishmentDetailsFragment
      }
      phases {
        id
        name
        state
      }
      tags
      items {
        itemId
        itemType
        values {
          id
          phaseName
          data {
            payloadType
            ... on ServiceItemPayload {
              serviceState: state
            }
            ... on DamageItemPayloadV2 {
              damageState: state
            }
            ...QualityCheckApprovalVehicleItemPayloadFragment
            ...ServiceItemPayloadFragment
            ...DamageItemPayloadV2FragmentQCA
            ...RefurbishmentItemPayloadFragment
          }
          revisions {
            id
            propertyPath
            state
          }
          createdOn
        }
      }
    }
  }
  ${QualityCheckApprovalRefurbishmentDetailsFragment}
  ${QualityCheckApprovalVehicleItemPayloadFragment}
  ${ServiceItemPayloadFragment}
  ${DamageItemPayloadV2FragmentQCA}
  ${RefurbishmentItemPayloadFragment}
`;
