import { get } from 'lodash/fp';

import {
  Query,
  QueryDownloadRepairCostImportTaskOriginalFileArgs,
  Scalars,
} from 'graphqlTypes';
import QUERY from './gql/downloadRepairCostImportTaskOriginalFile';
import usePromiseQuery from '../helpers/usePromiseQuery';

const usePromiseDownloadRepairCostImportTaskOriginalFile = () => {
  const query = usePromiseQuery<
    Query['downloadRepairCostImportTaskOriginalFile'],
    QueryDownloadRepairCostImportTaskOriginalFileArgs
  >(QUERY, {
    fetchPolicy: 'network-only',
    context: { disableBatch: true },
  });

  const getData = async (id: Scalars['UUID']) => {
    const { data } = await query({
      id,
    });

    return get(['downloadRepairCostImportTaskOriginalFile'], data);
  };

  return getData;
};

export default usePromiseDownloadRepairCostImportTaskOriginalFile;
