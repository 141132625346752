export const enum FormFilterNames {
  STATE = 's',
  REFURBISHMENT_TYPE = 'rt',
  SPARE_PART_STATUS = 'sps',
  REFURBISHMENT_NUMBER = 'r',
  STOCK_NUMBER = 'n',
  VIN_NUMBER = 'v',
  REFURBISHMENT_STOCK_VIN = 'rsv',
  REFURBISHMENT_STOCK = 'rs',
  VIN_STOCK = 'vs',
  PLATE_NUMBER = 'lp',
  REWORK_ONLY = 'rw',
  FIRST_NAME = 'fn',
  EMAIL = 'e',
  BRANCH = 'b',
  SORT_DATE = 'lastTransitionDate',
}
