import gql from 'graphql-tag';

export default gql`
  mutation createServiceExitCheck(
    $exitCheckId: UUID!
    $serviceData: ExitCheckServiceCreateInput!
  ) {
    createExitCheckService(
      exitCheckId: $exitCheckId
      serviceData: $serviceData
    ) {
      id
    }
  }
`;
