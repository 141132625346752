import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { get } from 'lodash/fp';

import {
  EntryCheckItemUpdateEntryInput,
  Mutation,
  MutationUpdateEntryCheckItemsArgs,
} from 'graphqlTypes';
import UPDATE_ENTRY_CHECK_ITEMS from './gql/updateEntryCheckItems';

export type UpdateEntryCheckItemsPromise = Promise<
  ExecutionResult<Pick<Mutation, 'updateEntryCheckItems'>>
>;

const useUpdateEntryCheckItems = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateEntryCheckItems'>,
    MutationUpdateEntryCheckItemsArgs
  >(UPDATE_ENTRY_CHECK_ITEMS);

  const updateEntryCheckItems = (inputs: EntryCheckItemUpdateEntryInput[]) =>
    mutation({ variables: { inputs } });

  return [
    updateEntryCheckItems,
    {
      data: get('updateEntryCheckItems', data),
      ...rest,
    },
  ] as const;
};

export default useUpdateEntryCheckItems;
