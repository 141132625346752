import React from 'react';

import { FeatureFlag, LocationFlag } from './flags';
import useFeature from './useFeature';

type FeatureProps = {
  feature: FeatureFlag;
  location?: LocationFlag;
  locationId?: Number;
  children: React.ReactNode;
};

const Feature = ({ feature, location, locationId, children }: FeatureProps) => {
  const hasFeature = useFeature({ feature, location, locationId });
  if (!hasFeature) return null;
  return <>{children}</>;
};

export default Feature;
