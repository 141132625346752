import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Divider from 'components/Divider';
import PageSidebarContainer from 'components/Layout/PageSidebarContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  RefurbishmentState,
  RefurbishmentTaskItemExcludingCostsResponse,
  RefurbishmentTaskItemIncludingCostsResponse,
} from 'graphqlTypes';
import { Role, useHasRole } from 'modules/acl';
import useAuthorizedUser from 'modules/auth/useAuthorizedUser';
import { ErrorType, useHandleError } from 'modules/errorHandler';
import { getUrl } from 'modules/routeUtils';
import Footbar from 'routes/RefurbishmentRoute/components/Footbar';
import Headbar from 'routes/RefurbishmentRoute/components/Headbar';
import Sidebar from 'routes/RefurbishmentRoute/components/Sidebar';
import VehicleHeader from 'routes/RefurbishmentRoute/components/VehicleHeader';
import useStep from 'routes/RefurbishmentRoute/hooks/useStep';
import { useRefurbishmentTaskItems } from './hooks/useRefurbishmentTaskItems';
import { mapData } from './mapData';
import { steps } from './steps';
import { Step } from './types';

type TaskItemContentProps = {
  data:
    | RefurbishmentTaskItemIncludingCostsResponse
    | RefurbishmentTaskItemExcludingCostsResponse;
};

const TaskItemContent = ({ data }: TaskItemContentProps) => {
  const [isLastItemSubmited, setIsLastItemSubmitted] = useState(false);

  const hasTaskWriteRole = useHasRole(Role.TASK_WRITE);
  const { user } = useAuthorizedUser();

  const { progress } = mapData(data);

  const {
    StepComponent,
    currentStep,
    currentStepName,
    currentStepItems,
    isFirstStep,
    isLastStep,
    goToCustomStep,
    goToNextStep,
    goToPrevStep,
  } = useStep<Step>({
    progress,
    stepComponents: steps,
  });

  const userHasCurrentPWC =
    data.currentPartnerWorkshopConnection &&
    user?.userPartnerWorkshopConnections?.some(pwcs =>
      pwcs.partnerWorkshopConnections?.some(
        pwc => pwc.id === data.currentPartnerWorkshopConnection?.id,
      ),
    );

  const disabled =
    isLastItemSubmited ||
    !hasTaskWriteRole ||
    data.refurbishment.refurbishmentState.key !==
      RefurbishmentState.RefurbishmentStarted ||
    !userHasCurrentPWC;

  return (
    <PageSidebarContainer
      headbar={
        <Headbar
          url={getUrl('REFURBISHMENT_TASK')}
          label={__('refurbishment.headbar.backToList')}
        />
      }
      sidebar={
        <Sidebar<Step>
          items={progress}
          title={__('refurbishment.task.item.title')}
          selectedItemIndex={currentStep}
          onStepClick={({ index }) => goToCustomStep(index)}
        />
      }
      footbar={
        <Footbar
          isNextButtonDisabled={isLastStep}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          onBackButtonClick={goToPrevStep}
          onNextButtonClick={goToNextStep}
          submitButton={<div />}
        />
      }
    >
      <VehicleHeader
        refId={data.refurbishment.refurbishmentNumber}
        state={data.refurbishment.refurbishmentState}
        vin={data.vehicle.vin}
        stockNumber={data.vehicle.stockNumber}
        make={data.vehicle.make}
        model={data.vehicle.model}
        subModel={data.vehicle.subModel}
        lastStateUpdatedOn={data.refurbishment.orderedOn}
      />
      <Divider />
      <StepComponent
        section={currentStepName}
        items={currentStepItems}
        currencyCode={data.refurbishment.currencyCode}
        disabled={disabled}
        onLastItemSubmitted={() => setIsLastItemSubmitted(true)}
      />
    </PageSidebarContainer>
  );
};

export const TaskItemRoute = () => {
  const setError = useHandleError();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useRefurbishmentTaskItems(id);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    setError(ErrorType.UnexpectedError);
    return null;
  }

  if (!data) {
    return null;
  }

  return <TaskItemContent data={data} />;
};
