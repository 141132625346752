import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ErrorPage } from 'components/ErrorPage';
import LoadingIndicator from 'components/LoadingIndicator';
import { Role, RoleAccess } from 'modules/acl';
import { useAuth } from 'modules/auth';
import { ErrorHandlerProvider, ErrorType } from 'modules/errorHandler';
import { getUrl } from 'modules/routeUtils';
import ExitCheckRoute from './ExitCheckRoute/ExitCheckRoute';
import CostTableRoute from './CostTableRoute';
import CreatePasswordRoute from './CreatePasswordRoute';
import DisabledUserRoute from './DisabledUserRoute';
import GroupRoute from './GroupRoute';
import HomeRoute from './HomeRoute';
import LoginRoute from './LoginRoute';
import LogoutRoute from './LogoutRoute';
import MonitoringDashboardRoute from './MonitoringDashboardRoute';
import PartnerRoute from './PartnerRoute';
import PartnerWorkshopConnectionsRoute from './PartnerWorkshopConnectionsRoute';
import ProductionRoute from './ProductionRoute';
import RefurbishmentRoute from './RefurbishmentRoute';
import RefurbishmentTaskRoute from './RefurbishmentTaskRoute';
import RepairCostRoute from './RepairCostRoute';
import RepairMethodRoute from './RepairMethodRoute';
import ResetPasswordRoute from './ResetPasswordRoute';
import UserRoute from './UserRoute';

type PrivateProps = {
  children: React.ReactNode;
};

const Private = ({ children }: PrivateProps) => {
  const { info } = useAuth();
  const location = useLocation();

  return info.isAuthorized ? (
    <>{children}</>
  ) : (
    <Navigate to={getUrl('LOGIN')} state={{ from: location }} />
  );
};

const RootRoute = () => {
  return (
    <ErrorHandlerProvider
      renderErrorComponent={errorType => <ErrorPage type={errorType} />}
    >
      <Suspense fallback={<LoadingIndicator />}>
        <Routes>
          <Route
            path="/"
            element={
              <Private>
                <HomeRoute />
              </Private>
            }
          />

          <Route
            path="refurbishment/*"
            element={
              <Private>
                <RefurbishmentRoute />
              </Private>
            }
          />

          <Route
            path="exit-check/*"
            element={
              <Private>
                <ExitCheckRoute />
              </Private>
            }
          />
          <Route
            path="refurbishment-task/*"
            element={
              <Private>
                <RefurbishmentTaskRoute />
              </Private>
            }
          />
          <Route
            path="user/*"
            element={
              <Private>
                <UserRoute />
              </Private>
            }
          />
          <Route
            path="partner-workshop-connections"
            element={
              <Private>
                <RoleAccess roles={Role.PARTNER_LOCATION_CONNECTIONS_READ}>
                  <PartnerWorkshopConnectionsRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route
            path="group/*"
            element={
              <Private>
                <GroupRoute />
              </Private>
            }
          />
          <Route
            path="logout"
            element={
              <Private>
                <LogoutRoute />
              </Private>
            }
          />
          <Route path="login" element={<LoginRoute />} />
          <Route path="reset-password" element={<ResetPasswordRoute />} />
          <Route
            path="create-password/:hash"
            element={<CreatePasswordRoute />}
          />
          <Route
            path="access-denied"
            element={<ErrorPage type={ErrorType.AccessDenied} />}
          />
          <Route path="disabled-user" element={<DisabledUserRoute />} />
          <Route
            path="cost-table"
            element={
              <Private>
                <RoleAccess roles={Role.COST_TABLE_READ}>
                  <CostTableRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route
            path="repair-method"
            element={
              <Private>
                <RoleAccess roles={Role.REPAIR_METHOD_READ}>
                  <RepairMethodRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route
            path="repair-cost"
            element={
              <Private>
                <RoleAccess roles={Role.REPAIR_COST_READ}>
                  <RepairCostRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route
            path="production"
            element={
              <Private>
                <RoleAccess roles={Role.PRODUCTION_READ}>
                  <ProductionRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route
            path="partners"
            element={
              <Private>
                <RoleAccess roles={Role.PARTNER_READ}>
                  <PartnerRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route
            path="monitoring"
            element={
              <Private>
                <RoleAccess roles={Role.INFORM_MONITORING}>
                  <MonitoringDashboardRoute />
                </RoleAccess>
              </Private>
            }
          />
          <Route path="*" element={<ErrorPage type={ErrorType.NotFound} />} />
        </Routes>
      </Suspense>
    </ErrorHandlerProvider>
  );
};

export default RootRoute;
