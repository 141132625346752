import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isEmpty, sortBy } from 'lodash/fp';

import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import { Group } from 'graphqlTypes';
import { useGetGroups } from 'modules/api/groups';
import { ErrorType, useHandleError } from 'modules/errorHandler';
import { getUrl } from 'modules/routeUtils';

const renderTable = (data: Group[] | undefined) => {
  if (isEmpty(data)) return null;

  const groups = sortBy<Group>('name')(data);

  return (
    <>
      <PageHeader
        title={__('group.overview.title')}
        actions={
          <Button as={Link} to={getUrl('GROUP_NEW')} variant="primary">
            {__('group.overview.newGroup')}
          </Button>
        }
      />
      <Table>
        <thead>
          <tr>
            <th>{__('group.overview.name')}</th>
            <th>{__('group.overview.description')}</th>
          </tr>
        </thead>
        <tbody>
          {groups.map(({ id, name, description }: Group) => (
            <tr key={id}>
              <td>
                <Link to={getUrl('GROUP_DETAIL', { id })}>{name}</Link>
              </td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const OverviewRoute = () => {
  const { data, loading, error } = useGetGroups();
  const setError = useHandleError();

  if (error) {
    setError(ErrorType.UnexpectedError);
    return null;
  }

  return (
    <PageContainer>
      {loading && <LoadingIndicator />}
      {!loading && renderTable(data)}
    </PageContainer>
  );
};

export default OverviewRoute;
