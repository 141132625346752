import { NavDropdown } from 'react-bootstrap';

import { User } from 'graphqlTypes';
import { ALL_MAIN_WORKSHOPS, MainWorkshop } from 'helpers/currentMainWorkshop';
import { useGetCurrentMainWorkshop } from 'helpers/currentMainWorkshop/useGetCurrentMainWorkshop';
import { useSetCurrentMainWorkshop } from 'helpers/currentMainWorkshop/useSetCurrentMainWorkshop';
import useAuthorizedUser from 'modules/auth/useAuthorizedUser';

import cn from './MainWorkshopSelector.module.scss';

const getMainWorkshopListFromPWC = (user: User): MainWorkshop[] =>
  user.userPartnerWorkshopConnections
    ?.filter(({ mainWorkshop }) => mainWorkshop.branchId)
    .map(({ mainWorkshop }) => ({
      name: mainWorkshop.name,
      branchId: Number(mainWorkshop.branchId),
    }))
    .sort((p1, p2) => p1.name.localeCompare(p2.name, __LOCALE__)) || [];

const MainWorkshopSelector = () => {
  const { user } = useAuthorizedUser();
  const { currentMainWorkshop } = useGetCurrentMainWorkshop();
  const setCurrentMainWorkshop = useSetCurrentMainWorkshop();

  if (!user) return <span>&nbsp;</span>;

  const mainWorkshops = getMainWorkshopListFromPWC(user);

  const hasMultipleMainWorkshops = mainWorkshops.length > 1;

  const defaultMainWorkshop =
    mainWorkshops.length === 1
      ? mainWorkshops[0].name
      : __('refurbishment.mainWorkshopSelector.all');

  const currentMainWorkshopName =
    currentMainWorkshop && currentMainWorkshop.name
      ? currentMainWorkshop.name
      : defaultMainWorkshop;

  return (
    <div className="d-flex">
      <span data-qa-selector="headerRefurbishmentMainWorkshop">
        {__('refurbishment.mainWorkshopSelect')}:{' '}
      </span>
      {hasMultipleMainWorkshops ? (
        <NavDropdown
          title={currentMainWorkshopName}
          id="main-workshop-dropdown"
          alignRight
          data-qa-selector="main_workshop_name"
          className={cn.dropdown}
        >
          <>
            {mainWorkshops.map(mainWorkshop => (
              <NavDropdown.Item
                key={mainWorkshop.name}
                data-qa-selector="main_workshop_item"
                onSelect={() => setCurrentMainWorkshop(mainWorkshop)}
              >
                {mainWorkshop.name}
              </NavDropdown.Item>
            ))}
            <NavDropdown.Item
              onSelect={() => setCurrentMainWorkshop(ALL_MAIN_WORKSHOPS)}
              data-qa-selector="main_workshop_item"
            >
              {__('refurbishment.mainWorkshopSelect.all')}
            </NavDropdown.Item>
          </>
        </NavDropdown>
      ) : (
        <span className="ml-2">{currentMainWorkshopName}</span>
      )}
    </div>
  );
};

export default MainWorkshopSelector;
