import cns from 'classnames';

import Text from 'components/UI/Text';
import { ImportTaskStatistics, Maybe } from 'graphqlTypes';

import cn from './ImportRepairMethodStatistics.module.scss';

type Props = {
  statistics?: Maybe<ImportTaskStatistics>;
};

const ImportRepairMethodStatistics = ({ statistics }: Props) => {
  if (!statistics) {
    return null;
  }

  return (
    <div className={cn.statistics}>
      {!!statistics?.created && (
        <Text
          color="success"
          size="m"
          data-qa-selector="importRepairMethodStatisticsItem"
        >{`${statistics.created} ${
          statistics.created === 1
            ? __('repairMethod.newMethod')
            : __('repairMethod.newMethods')
        }`}</Text>
      )}
      {!!statistics?.updated && (
        <Text
          size="m"
          className={cns(cn.statisticsItem, cn.updated)}
          data-qa-selector="importRepairMethodStatisticsItem"
        >{`${statistics.updated} ${
          statistics.updated === 1
            ? __('repairMethod.replacedMethod')
            : __('repairMethod.replacedMethods')
        }`}</Text>
      )}
      {!!statistics?.duplicated && (
        <Text
          size="m"
          className={cns(cn.statisticsItem, cn.duplicated)}
          data-qa-selector="importRepairMethodStatisticsItem"
        >{`${statistics.duplicated} ${
          statistics.duplicated === 1
            ? __('repairMethod.duplicatedMethod')
            : __('repairMethod.duplicatedMethods')
        }`}</Text>
      )}
      {!!statistics?.failed && (
        <Text
          size="m"
          className={cns(cn.statisticsItem, cn.failed)}
          data-qa-selector="importRepairMethodStatisticsItem"
        >{`${statistics.failed} ${
          statistics.failed === 1
            ? __('repairMethod.failedMethod')
            : __('repairMethod.failedMethods')
        }`}</Text>
      )}
    </div>
  );
};

export default ImportRepairMethodStatistics;
