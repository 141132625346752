import gql from 'graphql-tag';

import { EntryCheckAuthorizationItemFragment } from './fragments';

export default gql`
  mutation createEntryCheckItemsForPostAuthorization(
    $inputs: [EntryCheckItemCreateInput!]!
  ) {
    createEntryCheckItemsForPostAuthorization(inputs: $inputs) {
      ...EntryCheckAuthorizationItemFragment
    }
  }
  ${EntryCheckAuthorizationItemFragment}
`;
