import gql from 'graphql-tag';

import { VehicleServiceCostFragment } from './fragments';

export default gql`
  query vehicleServiceCostSearch(
    $page: Int!
    $pageSize: Int!
    $filter: VehicleServiceCostFilter
    $sort: VehicleServiceCostSortInput
  ) {
    vehicleServiceCostSearch(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...VehicleServiceCostFragment
      }
      pageInfo {
        page
        pageSize
        totalPageCount
        totalEntityCount
      }
    }
  }
  ${VehicleServiceCostFragment}
`;
