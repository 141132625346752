import { identity, noop } from 'lodash/fp';
import qs from 'qs';

import filterEmptyParams from './filterEmptyParams';

const defaultOptions = {
  method: 'GET',
  timeout: 90000,
  formatUrl: ({ url }) => url,
  createProviderOptions: identity,
  headers: { Accept: 'application/json' },
  createId: ({ method, url, body }) =>
    `${method}:${url}:${body ? JSON.stringify(body) : 'none'}`,
  pb: { trickle: noop, done: noop, status: noop, start: noop },
  paramsSerializer: params => qs.stringify(filterEmptyParams(params)),
  getIdenticalRequestText: ({ url, id }) =>
    `Identical request. URL: "${url}" ID: "${id}"`,
};

export default optionsParam => {
  const options = {
    ...defaultOptions,
    headers: { ...defaultOptions.headers, ...optionsParam.headers },
    ...optionsParam,
  };

  const { formatUrl, createId, createProviderOptions } = options;

  options.path = options.url;
  options.url = formatUrl(options);
  options.id = createId(options);
  options.provider = createProviderOptions(options);

  return options;
};
