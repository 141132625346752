import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import {
  UserCreateInputV2,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import { useActiveGroupedPartnerWorkshopConnections } from 'modules/api/partnerWorkshop';
import { useCreateUserV2 } from 'modules/api/users';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { getUrl } from 'modules/routeUtils';
import Groups from '../components/Groups';
import Info from '../components/Info';
import ProductionLocations from '../components/ProductionLocations';
import { formToUserCreateInfo } from '../mapData';
import { UserInfoForm } from '../types';
import { getUserPartnerWorkshopConnections } from '../utils';

const NewRoute = () => {
  const navigate = useNavigate();
  const {
    data: groupedPartnerWorkshopConnections,
  } = useActiveGroupedPartnerWorkshopConnections({
    page: 0,
    pageSize: 999999,
  });
  const [createUserV2] = useCreateUserV2();
  const [partnerWorkshopConnections, setPartnerWorkshopConnections] = useState<
    UserPartnerWorkshopConnectionInput
  >({
    partnerWorkshopConnectionIds: [],
    userMainWorkshopIds: [],
  });
  const [groups, setGroups] = useState<string[]>([]);
  const { error } = useNotification();
  const { logError } = useLogger();

  const userPartnerWorkshopConnections = useMemo(
    () =>
      getUserPartnerWorkshopConnections(
        groupedPartnerWorkshopConnections,
        partnerWorkshopConnections,
      ),
    [groupedPartnerWorkshopConnections, partnerWorkshopConnections],
  );

  const onPartnerWorkshopConnectionChange = async (
    value: UserPartnerWorkshopConnectionInput,
  ) => {
    setPartnerWorkshopConnections(value);
  };

  const handleSave = async (info: UserInfoForm) => {
    const userCreateInput: UserCreateInputV2 = {
      ...formToUserCreateInfo(info),
      groups,
      userPartnerWorkshopConnections: partnerWorkshopConnections,
    };

    if (!groups.length) {
      error(__('user.new.missingGroups'));
      return;
    }

    try {
      const { data } = await createUserV2(userCreateInput);
      if (data) {
        navigate(getUrl('USER_DETAIL', { id: data?.createUserV2.id }));
      }
    } catch (e) {
      logError({ error: e });
    }
  };

  return (
    <PageContainer>
      <PageHeader title={__('user.detail.newUser')} />
      <Info submitButton={() => <span />} onSubmit={handleSave} />

      <div className="my-4">
        <ProductionLocations
          partnerWorkshopConnections={userPartnerWorkshopConnections}
          onChange={onPartnerWorkshopConnectionChange}
        />
      </div>

      <Groups onChange={setGroups} />

      <div className="d-flex justify-content-end">
        <Button
          variant="primary"
          type="submit"
          data-qa-selector="save"
          form="user-form"
        >
          {__('action.save')}
        </Button>
      </div>
    </PageContainer>
  );
};

export default NewRoute;
