import gql from 'graphql-tag';

import { EntryCheckItemFragment } from './fragments';

export default gql`
  mutation createEntryCheckItems($inputs: [EntryCheckItemCreateInput!]!) {
    createEntryCheckItems(inputs: $inputs) {
      ...EntryCheckItemFragment
    }
  }
  ${EntryCheckItemFragment}
`;
