import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import SortableTableHeader from 'components/SortableTableHeader';
import {
  RepairCost,
  RepairCostFilter,
  RepairCostSortInput,
  SortDirection,
} from 'graphqlTypes';
import { useRepairCostSearch } from 'modules/api/costTable';
import useUpdateRepairCost from 'modules/api/costTable/useUpdateRepairCost';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import RepairCostDefaultCostConfirmationModal from './RepairCostIsDefaultConfirmationModal';
import RepairCostPagination from './RepairCostPagination';
import RepairCostRow from './RepairCostRow';
import { FormFilterNames } from '../../FormFilterNames';

import cn from './RepairCostTable.module.scss';

const RepairCostTable = () => {
  const { logError } = useLogger();
  const { error } = useNotification();
  const [updateRepairCost] = useUpdateRepairCost();
  const { search } = useLocation();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [currentItem, setCurrentItem] = useState<null | RepairCost>(null);

  const searchParams = new URLSearchParams(search);
  const page = Math.max(Number(searchParams.get('p')) - 1 || 0, 0);
  const mainWorkshop = searchParams.get(FormFilterNames.MAIN_WORKSHOP) || '';
  const repairWorkshop =
    searchParams.get(FormFilterNames.REPAIR_WORKSHOP) || '';
  const repairPartner = searchParams.get(FormFilterNames.REPAIR_PARTNER) || '';
  const area = searchParams.get(FormFilterNames.AREA) || '';
  const part = searchParams.get(FormFilterNames.PART) || '';
  const repairMethod = searchParams.get(FormFilterNames.REPAIR_METHOD) || '';
  const sortField = searchParams.get('sortField') || '';
  const sortDir = searchParams.get('sortDir') || '';
  const active = searchParams.get(FormFilterNames.ACTIVE_ONLY) || '';

  const sort: RepairCostSortInput = {
    createdOn: SortDirection.Desc,
  };
  if (sortField === 'mainWorkshop') {
    sort.mainWorkshop =
      sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
  } else if (sortField === 'repairWorkshop') {
    sort.repairWorkshop =
      sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
  } else if (sortField === 'repairPartner') {
    sort.repairPartner =
      sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
  }

  const filter: RepairCostFilter = {};
  if (mainWorkshop) {
    filter.mainWorkshopId = parseInt(mainWorkshop, 10);
  }
  if (repairWorkshop) {
    filter.repairWorkshopId = parseInt(repairWorkshop, 10);
  }
  if (repairPartner) {
    filter.repairPartnerId = parseInt(repairPartner, 10);
  }
  if (area) {
    filter.area = area;
  }
  if (part) {
    filter.part = part;
  }
  if (repairMethod) {
    filter.repairMethod = repairMethod;
  }
  if (active) {
    filter.active = true;
  }

  const searchArgs = {
    page,
    pageSize: 50,
    sort,
    filter,
  };

  const closeModal = () => setIsModalVisible(false);

  const { data, loading } = useRepairCostSearch(searchArgs);

  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  const { items } = data;

  const updateRepairCostItem = async (
    updatedData: {
      active?: boolean;
      isDefault?: boolean;
    },
    item: RepairCost,
  ) => {
    const updateRepairCostInput = {
      id: item.id,
      cost: {
        laborRateMinorUnits: item.cost.laborRateMinorUnits,
        laborTimeHours: item.cost.laborTimeHours,
        consumableCostMinorUnits: item.cost.consumableCostMinorUnits,
        sparePartCostMinorUnits: item.cost.sparePartCostMinorUnits,
        currencyCode: item.cost.currencyCode,
      },
      isDefault: item.isDefault,
      active: item.active,
      ...updatedData,
    };

    try {
      const updatedRepairCostData = await updateRepairCost(
        updateRepairCostInput,
      );
      // If default checkbox has been checked, check if the previous default cost item from the group is on the current list, if so uncheck it's default checkbox
      if (updatedData.isDefault) {
        const updatedItem = updatedRepairCostData.data?.updateRepairCost;

        if (updatedItem) {
          const previousDefaultItem = data?.items.find(
            costItem =>
              costItem.id !== updatedItem.id &&
              costItem.mainWorkshop.id === updatedItem.mainWorkshop.id &&
              costItem.area.key === updatedItem.area.key &&
              costItem.part.key === updatedItem.part.key &&
              costItem.repairMethod.key === updatedItem.repairMethod.key,
          );

          if (previousDefaultItem) {
            const previousDefaultItemUpdateRepairCostInput = {
              id: previousDefaultItem.id,
              cost: {
                laborRateMinorUnits:
                  previousDefaultItem.cost.laborRateMinorUnits,
                laborTimeHours: previousDefaultItem.cost.laborTimeHours,
                consumableCostMinorUnits:
                  previousDefaultItem.cost.consumableCostMinorUnits,
                sparePartCostMinorUnits:
                  previousDefaultItem.cost.sparePartCostMinorUnits,
                currencyCode: previousDefaultItem.cost.currencyCode,
              },
              active: previousDefaultItem.active,
              isDefault: false,
            };
            await updateRepairCost(previousDefaultItemUpdateRepairCostInput);
          }
        }
      }

      closeModal();
    } catch (errorData) {
      logError({ error: errorData });
      error(__('repairCost.updateDataFailed'));
    }
  };

  const defaultCheckboxHandler = (item: RepairCost) => {
    setIsModalVisible(true);
    setCurrentItem(item);
  };

  return (
    <>
      <RepairCostDefaultCostConfirmationModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        currentItem={currentItem}
        updateRepairCostItem={updateRepairCostItem}
      />
      <Table striped responsive>
        <thead>
          <tr>
            <th className={cn.mainWorkshop}>
              <SortableTableHeader
                header={__('repairCost.mainWorkshop')}
                sortField="mainWorkshop"
              />
            </th>
            <th className={cn.repairPartner}>
              <SortableTableHeader
                header={__('repairCost.repairPartner')}
                sortField="repairPartner"
              />
            </th>
            <th className={cn.repairWorkshop}>
              <SortableTableHeader
                header={__('repairCost.repairWorkshop')}
                sortField="repairWorkshop"
              />
            </th>
            <th className={cn.area}>{__('repairCost.area')}</th>
            <th className={cn.part}>{__('repairCost.part')}</th>
            <th className={cn.repairMethod}>{__('repairCost.repairMethod')}</th>
            <th className={cn.laborTotal}>{__('repairCost.labourTotal')}</th>
            <th className={cn.materialTotal}>
              {__('repairCost.materialTotal')}
            </th>
            <th className={cn.totalPrice}>{__('repairCost.totalPrice')}</th>
            <th className={cn.default}>{__('repairCost.default')}</th>
            <th className={cn.active}>{__('repairCost.active')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            return (
              <RepairCostRow
                key={item.id}
                item={item}
                updateRepairCostItem={updateRepairCostItem}
                defaultCheckboxHandler={defaultCheckboxHandler}
              />
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <RepairCostPagination totalPageCount={data.pageInfo.totalPageCount} />
      </div>
    </>
  );
};

export default RepairCostTable;
