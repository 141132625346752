import Divider from 'components/Divider';
import {
  CurrencyCode,
  RefurbishmentTaskItemIncludingCost,
  RefurbishmentTaskItemType,
} from 'graphqlTypes';
import StepContainer from 'routes/RefurbishmentRoute/components/StepContainer';
import StepHeader from 'routes/RefurbishmentRoute/components/StepHeader';
import { PartnerGroup } from './PartnerGroup';
import { ServiceWork } from './ServiceWork';
import TaskListServiceWorkChecker from './TaskListServiceWorkChecker';
import { WorkshopGroup } from './WorkshopGroup';

type Props = {
  section: string;
  items: RefurbishmentTaskItemIncludingCost[];
  currencyCode: CurrencyCode;
  disabled: boolean;
  onLastItemSubmitted: () => void;
};

type ServiceWorkItem = RefurbishmentTaskItemIncludingCost & {
  subServiceItems: RefurbishmentTaskItemIncludingCost[];
};

export const TaskItemListServiceWork = ({
  section,
  items,
  currencyCode,
  disabled,
  onLastItemSubmitted,
}: Props) => {
  const serviceWorkItems = items
    .filter(
      item =>
        item.itemType === RefurbishmentTaskItemType.ServiceBookMainService,
    )
    .map(serviceWorkItem => {
      return {
        ...serviceWorkItem,
        subServiceItems: items.filter(
          item =>
            item.itemType === RefurbishmentTaskItemType.ServiceBookSubService &&
            item.service?.relatedServiceId === serviceWorkItem.service?.id,
        ),
      };
    });

  const itemsGroupedByPartner = serviceWorkItems.reduce(
    (acc: Record<string, ServiceWorkItem[]>, cur) => {
      acc[cur.partnerWorkshopConnection.repairPartner.id] = [
        ...(acc[cur.partnerWorkshopConnection.repairPartner.id] || []),
        cur,
      ];
      return acc;
    },
    {},
  );

  const partnerEntries = Object.entries(itemsGroupedByPartner);

  return (
    <StepContainer>
      <StepHeader
        header={section}
        subHeader={
          items.length > 0
            ? __('refurbishment.task.item.confirmTasks')
            : __('refurbishment.task.item.noTasksAvailable')
        }
      />
      {partnerEntries.map(([partnerId, partnerItems]) => {
        const itemsGroupedByWorkshop = partnerItems.reduce(
          (acc: Record<string, ServiceWorkItem[]>, cur) => {
            acc[cur.partnerWorkshopConnection.repairWorkshop.id] = [
              ...(acc[cur.partnerWorkshopConnection.repairWorkshop.id] || []),
              cur,
            ];
            return acc;
          },
          {},
        );

        const workshopEntries = Object.entries(itemsGroupedByWorkshop);

        return (
          <div key={partnerId}>
            <Divider className="my-3" />
            <PartnerGroup
              partner={partnerItems[0].partnerWorkshopConnection.repairPartner}
              count={partnerItems.length}
            >
              {workshopEntries.map(([workshopId, workshopItems]) => (
                <WorkshopGroup
                  workshop={
                    workshopItems[0].partnerWorkshopConnection.repairWorkshop
                  }
                  key={workshopId}
                >
                  {workshopItems.map(item => (
                    <div className="mb-4" key={item.id}>
                      <ServiceWork
                        comment={item.service?.comment}
                        costMinorUnits={item.service?.budgetMinorUnits}
                        subServices={item.subServiceItems}
                        currencyCode={currencyCode}
                        disabled={disabled}
                        onLastItemSubmitted={onLastItemSubmitted}
                      >
                        <TaskListServiceWorkChecker item={item} />
                      </ServiceWork>
                    </div>
                  ))}
                </WorkshopGroup>
              ))}
            </PartnerGroup>
          </div>
        );
      })}
    </StepContainer>
  );
};
