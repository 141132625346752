import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import { QueryActiveGroupedPartnerWorkshopConnectionsArgs } from 'graphqlTypes';
import {
  useCreatePartnerWorkshopConnections,
  useRemovePartnerWorkshopConnections,
} from 'modules/api/partnerWorkshop';
import { useLogger } from 'modules/logger';
import { PartnerWorkshopItem } from 'routes/PartnerWorkshopConnectionsRoute/types';
import LocationPartnerForm from '../PartnerWorkshopForm';
import { PartnerWorkshopFormData } from '../PartnerWorkshopForm/PartnerWorkshopForm';

type Props = {
  item: PartnerWorkshopItem;
  partnerWorkshopConnectionArgs: QueryActiveGroupedPartnerWorkshopConnectionsArgs;
};

const EditPartnerWorkshop = ({
  item,
  partnerWorkshopConnectionArgs,
}: Props) => {
  const { logError } = useLogger();
  const [showModal, setShowModal] = useState(false);
  const [
    createConnection,
    { loading: loadingCreate },
  ] = useCreatePartnerWorkshopConnections();
  const [
    removeConnection,
    { loading: loadingRemove },
  ] = useRemovePartnerWorkshopConnections();

  const formData: PartnerWorkshopFormData = {
    mainWorkshop: {
      branchId: item.mainWorkshop.branchId,
      name: item.mainWorkshop.name,
    },
    repairWorkshop: {
      branchId: item.repairWorkshop.branchId,
      name: item.repairWorkshop.name,
    },
    repairPartners: item.repairPartners.map(partner => ({
      refurbishmentPartnerId: partner.refurbishmentPartnerId,
      name: partner.name,
    })),
  };

  const onSubmit = async (values: PartnerWorkshopFormData) => {
    if (
      values.mainWorkshop.branchId !== formData.mainWorkshop.branchId ||
      values.repairWorkshop.branchId !== formData.repairWorkshop.branchId
    ) {
      try {
        await removeConnection(
          item.repairPartners.map(partner => partner.idConnection),
          partnerWorkshopConnectionArgs,
        );
        await createConnection(values, partnerWorkshopConnectionArgs);
        setShowModal(false);
      } catch (error) {
        logError({ error });
      }
      return;
    }

    try {
      const newPartners = values.repairPartners.filter(
        partner =>
          !item.repairPartners.find(
            currentPartner =>
              currentPartner.name === partner.name &&
              currentPartner.refurbishmentPartnerId ===
                partner.refurbishmentPartnerId,
          ),
      );
      if (newPartners.length) {
        await createConnection(
          { ...values, repairPartners: newPartners },
          partnerWorkshopConnectionArgs,
        );
      }

      const removedPartnerIds = item.repairPartners
        .filter(
          currentPartner =>
            !values.repairPartners.find(
              partner =>
                currentPartner.refurbishmentPartnerId ===
                partner.refurbishmentPartnerId,
            ),
        )
        .map(partner => partner.idConnection);
      if (removedPartnerIds.length) {
        await removeConnection(
          removedPartnerIds,
          partnerWorkshopConnectionArgs,
        );
      }

      setShowModal(false);
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShowModal(true)}
        data-qa-selector="editConnections"
      >
        {__('action.edit')}
      </Button>
      <CustomModal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{__('workshopPartnerConnection.editTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LocationPartnerForm
            data={formData}
            loading={loadingCreate || loadingRemove}
            onSubmit={onSubmit}
            onCancel={() => setShowModal(false)}
          />
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default EditPartnerWorkshop;
