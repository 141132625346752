import { Control } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { statusOptions } from './options';
import { FormType } from './types';

type Props = {
  control: Control<FormType, any>;
  name: keyof FormType;
  form: string;
};

const StatusOptions = ({ control, name, form }: Props) => {
  return (
    <ReactSelectInput
      control={control}
      name={name}
      form={form}
      options={statusOptions}
      styles={{
        menu: base => ({ ...base, width: 'max-content', minWidth: '100%' }),
      }}
      dataQa="status"
      dataQaOption="statusOption"
    />
  );
};

export default StatusOptions;
