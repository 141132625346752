import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { ReactSelectInputProps } from 'components/Form/ReactSelectInput/ReactSelectInput';
import { refurbishmentSparePartStatusesTranslated } from 'constants/sparePartsStatuses';
import createOptions from 'helpers/createOptions';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const SparePartStatusOptions = createOptions(
  refurbishmentSparePartStatusesTranslated,
);

type Props = Omit<
  ReactSelectInputProps,
  'name' | 'dataQa' | 'dataQaOption' | 'dataQaMultiValueContainer' | 'options'
> & {
  hideSelectedOptionsInput?: boolean;
  className?: string;
};

const SparePartStatusSelect = ({
  className,
  hideSelectedOptionsInput,
  ...rest
}: Props) => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'SparePartStatusSelect' must be used inside a 'Filter' component.",
    );
  }
  const { control, watch } = formMethods;

  const isOptionSelected = !!watch(FormFilterNames.SPARE_PART_STATUS)?.length;

  return (
    <div
      className={cns(cn.col, cn.input, className)}
      data-qa-selector="spare_part_statuses"
    >
      <ReactSelectInput
        {...rest}
        name={FormFilterNames.SPARE_PART_STATUS}
        control={control}
        options={SparePartStatusOptions}
        placeholder={__('refurbishment.task.list.sparePartStatus')}
        isMulti
        dataQa="filter_spare_part_status"
        dataQaOption="filter_spare_part_status_option"
        dataQaMultiValueContainer="filter_spare_part_status_option_selected"
        components={
          isOptionSelected && hideSelectedOptionsInput
            ? {
                ValueContainer: () => (
                  <div className={cn.selectPlaceholder}>
                    {__('refurbishment.task.list.sparePartStatusSelected')}
                  </div>
                ),
              }
            : {}
        }
        styles={
          hideSelectedOptionsInput
            ? {
                multiValue: (styles: any) => ({
                  ...styles,
                  display: 'none',
                }),
              }
            : {}
        }
      />
    </div>
  );
};

export default SparePartStatusSelect;
