import { map } from 'lodash/fp';

import { CompositeType, UploadImagesInfo } from 'graphqlTypes';
import { usePromiseBuildAdImageUploadCredentials } from 'modules/api/refurbishments';
import { useLogger } from 'modules/logger';
import { createUploadData, uploadFiles } from './useAdImageUpload';

export type ImageFile = {
  file: File;
  url: string;
  uploadId: string;
  extension: string;
  objectUrl: string;
};

type Data = string[] | undefined | Array<ImageFile>;

interface UseAdImageUpload {
  adId: string;
  compositeType: CompositeType;
}

export default ({ adId, compositeType }: UseAdImageUpload) => {
  const { logError } = useLogger();
  const getCredentials = usePromiseBuildAdImageUploadCredentials();

  const uploadFile = async (file: File): Promise<Data> => {
    const uploadData = createUploadData({
      adId,
      files: [file],
      compositeType,
    });
    const uploadImagesInfos: UploadImagesInfo[] = map(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      ({ adId, uploadId, type, extension }) => ({
        adId,
        uploadId,
        type,
        extension,
      }),
      uploadData,
    );
    try {
      const awsImageUploadCredentials = await getCredentials({
        uploadImagesInfos,
      });
      if (awsImageUploadCredentials) {
        const setData = await uploadFiles({
          uploadData,
          awsImageUploadCredentials,
          compositeType,
        });
        return setData;
      }
    } catch (error) {
      logError({ error });
      window.alert('Image upload error'); // eslint-disable-line no-alert
    }
  };

  return {
    uploadFile,
  };
};
