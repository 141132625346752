import gql from 'graphql-tag';

export default gql`
  mutation createRepairCostImportTask(
    $createImportTaskInput: CreateImportTaskInput!
  ) {
    createRepairCostImportTask(createImportTaskInput: $createImportTaskInput) {
      id
      name
      uri
      type
      state
      createdBy {
        id
      }
      createdOn
    }
  }
`;
