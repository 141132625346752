import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FieldError from 'components/Form/FieldError';
import FormButtonsModal from 'components/Form/FormButtonModal';
import LocationSelectControl from 'components/LocationSelectControl';
import { PartnerWorkshopConnectionCreateInput } from 'graphqlTypes';
import PartnerSelectControl from '../PartnerSelectControl';

export type PartnerWorkshopFormData = PartnerWorkshopConnectionCreateInput;

type Props = {
  data?: PartnerWorkshopFormData;
  loading: boolean;
  onSubmit(values: PartnerWorkshopFormData): Promise<any>;
  onCancel?(): void;
};

const PartnerWorkshopForm = ({ data, loading, onSubmit, onCancel }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<PartnerWorkshopFormData>({
    defaultValues: data,
  });

  const onFormSubmit = (values: PartnerWorkshopFormData) => {
    onSubmit(values);
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} data-qa-selector="form">
      <Form.Group controlId="mainWorkshop">
        <Form.Label>{__('partnerWorkshopForm.mainWorkshop')}</Form.Label>
        <LocationSelectControl
          control={control}
          name="mainWorkshop"
          qaSelector="mainWorkshop"
        />
        <FieldError name="mainWorkshop" errors={errors} />
      </Form.Group>
      <Form.Group controlId="repairWorkshop">
        <Form.Label>{__('partnerWorkshopForm.repairWorkshop')}</Form.Label>
        <LocationSelectControl
          control={control}
          name="repairWorkshop"
          qaSelector="repairWorkshop"
        />
        <FieldError name="repairWorkshop" errors={errors} />
      </Form.Group>
      <Form.Group
        controlId="repairPartners"
        data-qa-selector="partner-container"
      >
        <Form.Label>{__('partnerWorkshopForm.repairPartners')}</Form.Label>
        <PartnerSelectControl
          control={control}
          name="repairPartners"
          qaSelector="repairPartners"
        />
        <FieldError name="repairPartners" errors={errors} />
      </Form.Group>
      <FormButtonsModal>
        <Button
          variant="link"
          disabled={loading}
          onClick={onCancel}
          data-qa-selector="form-cancel"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="secondary"
          type="submit"
          disabled={loading || isSubmitting}
          className="btn-wide ml-4"
          data-qa-selector="form-submit"
        >
          {__('action.confirm')}
        </Button>
      </FormButtonsModal>
    </Form>
  );
};

export default PartnerWorkshopForm;
