import { Button } from 'react-bootstrap';

import useRetryInformMessages from 'modules/api/informMessage/useRetryInformMessages';
import { useLogger } from 'modules/logger';
import { MessageSelected } from 'routes/MonitoringDashboardRoute/hooks/useSelectMessages';
import { InformMessageStatus } from 'routes/MonitoringDashboardRoute/types';

type Props = {
  messages?: MessageSelected[];
  onRetry(): void;
};

const RetryMessage = ({ messages, onRetry }: Props) => {
  const [retryInformMessages, { loading }] = useRetryInformMessages();
  const { logError } = useLogger();
  const ids = messages
    ?.filter(
      ({ selected, status }) =>
        selected && status === InformMessageStatus.VPC_PROCESSING_ERROR,
    )
    .map(({ id }) => id);

  const handleOnClick = async () => {
    if (!ids?.length) return;

    try {
      await retryInformMessages({ ids });
      onRetry();
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <Button
      variant="link"
      onClick={handleOnClick}
      disabled={loading}
      className="pb-0"
      data-qa-selector="retry-message-button"
    >
      {loading ? __('loading') : __('monitoring.retry')}
    </Button>
  );
};

export default RetryMessage;
