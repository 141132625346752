import { castArray, get } from 'lodash/fp';

type Path = string | number | Array<string | number | symbol>;

const makePath = (finalKey: string, path: Path) => {
  const pathArray = castArray(path);
  return [...pathArray, finalKey].filter(Boolean) as Array<
    string | number | symbol
  >;
};

export const getKey = get('key');

export const getTranslation = get('translation');

export const getKeyPath = (path: Path) => {
  const pathArray = makePath('key', path);
  return get(pathArray);
};
export const getTranslationPath = (path: Path) => {
  const pathArray = makePath('translation', path);
  return get(pathArray);
};
