import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const EmailInput = () => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error("'EmailInput' must be used inside a 'Filter' component.");
  }
  const { register } = formMethods;

  return (
    <div className={cns(cn.col, cn.input)}>
      <Form.Control
        {...register(FormFilterNames.EMAIL)}
        placeholder={__('user.overview.email')}
        data-qa-selector="email_filter"
      />
    </div>
  );
};

export default EmailInput;
