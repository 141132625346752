import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import {
  EntryCheckItemValueSourceType,
  RefurbishmentDetails,
} from 'graphqlTypes';
import { useLogger } from 'modules/logger';
import DamageForm from './DamageForm';
import { damageFormToInput } from './mapData';
import useCreateDamage from '../../hooks/useCreateDamage';
import { DamageFormData, SliceKeyDamage, WheelPosition } from '../../types';

interface Props {
  id: string;
  refurbishment: RefurbishmentDetails;
  hasPA3?: boolean;
  name?: string;
  damageGroup?: SliceKeyDamage | WheelPosition;
  sourceType: EntryCheckItemValueSourceType;
  isSecondaryWheel: boolean | null;
  show: boolean;
  setShow: Function;
  damage?: Partial<DamageFormData>;
  displaySection?: SliceKeyDamage;
  budgetRequired?: boolean;
  isImperfectionRepairMethods: boolean;
  validateSubmit?(data: DamageFormData): string | null;
  hideCost?: boolean;
  hasSplitCost?: boolean;
  hasSparePartsManagement: boolean;
}

const AddDamageModal = ({
  id,
  refurbishment,
  hasPA3,
  name,
  damageGroup,
  sourceType,
  isSecondaryWheel,
  show,
  setShow,
  damage,
  displaySection,
  validateSubmit,
  isImperfectionRepairMethods,
  budgetRequired = false,
  hideCost,
  hasSplitCost,
  hasSparePartsManagement,
}: Props) => {
  const { logError } = useLogger();
  const [createDamage] = useCreateDamage({
    entryCheckId: id,
    refurbishmentId: refurbishment.id,
    sourceType,
    hasSparePartsManagement,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFormSubmit = async (values: DamageFormData) => {
    if (validateSubmit) {
      const msg = validateSubmit(values);
      setErrorMessage(msg);
      if (msg) {
        return;
      }
    }
    try {
      const submitValues = {
        ...values,
        ...(isSecondaryWheel !== undefined && { isSecondaryWheel }),
        ...(displaySection !== undefined && { displaySection }),
      };

      const damageInput = damageFormToInput(submitValues, hasSplitCost);
      await createDamage(damageInput);
    } catch (error) {
      logError({ error });
    }
    setShow(false);
  };

  return (
    <CustomModal size="ml" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {__('entryCheck.addNewDamage')}
          {name ? ` - ${name}` : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DamageForm
          refurbishment={refurbishment}
          hasPA3={hasPA3}
          damageGroup={damageGroup}
          isNew
          onSubmit={handleFormSubmit}
          onCancel={() => setShow(false)}
          disabled={!show}
          damage={damage}
          budgetRequired={budgetRequired}
          errorMessage={errorMessage}
          isImperfectionRepairMethods={isImperfectionRepairMethods}
          hideCost={hideCost}
          hasSplitCost={hasSplitCost}
        />
      </Modal.Body>
    </CustomModal>
  );
};

export default AddDamageModal;
