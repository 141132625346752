import { Role } from 'graphqlTypes';

interface FormRole extends Role {
  checked?: boolean;
}

export default (allRoles: Role[], groupRoles: Role[]): FormRole[] => {
  return allRoles.map(role => {
    if (groupRoles.find(item => item.id === role.id)) {
      return {
        ...role,
        checked: true,
      };
    }
    return role;
  });
};
