import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateVehicleServiceCostArgs,
  VehicleServiceCostUpdateInput,
} from 'graphqlTypes';
import UPDATE_VEHICLE_SERVICE_COST from './gql/updateVehicleServiceCost';

const useUpdateVehicleServiceCost = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateVehicleServiceCost'>,
    MutationUpdateVehicleServiceCostArgs
  >(UPDATE_VEHICLE_SERVICE_COST);

  const updateVehicleServiceCost = (
    id: string,
    vehicleServiceCostUpdateInput: VehicleServiceCostUpdateInput,
  ) => mutation({ variables: { id, vehicleServiceCostUpdateInput } });

  return [
    updateVehicleServiceCost,
    {
      data: data?.updateVehicleServiceCost,
      ...rest,
    },
  ] as const;
};

export default useUpdateVehicleServiceCost;
