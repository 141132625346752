import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { PreparedFile } from 'components/FileDropzone';
import FormButtonModal from 'components/Form/FormButtonModal/FormButtonsModal';
import ImportCsvUpload from 'components/ImportCsvUpload';
import { ImportTask } from 'graphqlTypes';
import s3FileUpload from 'helpers/s3FileUpload';
import { usePromiseBuildRepairCostImportUploadCredentials } from 'modules/api/costTable';
import useCreateRepairCostImportTask from 'modules/api/costTable/useCreateRepairCostImportTask';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { ImportRepairCostPhase } from './ImportRepairCostModal';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairCostPhase>>;
  getRepairCostImportTask: (uuid: string) => void;
  repairCostImportTaskData?: ImportTask;
  stopPolling: () => void;
};

const ImportRepairCostUpload = ({
  closeModal,
  setActivePhase,
  getRepairCostImportTask,
  stopPolling,
}: Props) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);

  const { logError } = useLogger();
  const { error } = useNotification();

  const getCredentials = usePromiseBuildRepairCostImportUploadCredentials();
  const [createRepairCostImportTask] = useCreateRepairCostImportTask();

  const exampleCsv =
    process.env.NODE_ENV === 'development'
      ? `/repairCost-example.csv`
      : `/${__LOCALE__}/repairCost-example.csv`;

  const onFiles = (e: PreparedFile[]) => {
    if (e.length > 0) {
      setCsvFile(e[0]?.file);
    } else {
      setCsvFile(null);
    }
  };

  const uploadCsv = async () => {
    const credentials = await getCredentials({
      importId: uuidv4(),
      extension: 'csv',
    });

    try {
      if (csvFile && credentials) {
        const { host, formData } = credentials;
        const uri = `${host}${formData.key}`;

        setActivePhase(ImportRepairCostPhase.VALIDATION_LOADING);

        await s3FileUpload(csvFile, formData, host);

        const { data } = await createRepairCostImportTask({
          name: csvFile.name,
          uri,
        });

        const id = data?.createRepairCostImportTask.id || '';
        getRepairCostImportTask(id);
      }
    } catch (errorData) {
      logError({ error: errorData });
      error(__('refurbishment.detail.failedUpload'));
      setActivePhase(ImportRepairCostPhase.UPLOADING);
    }
  };

  return (
    <>
      <h3>{__('repairCost.pleaseUploadFile')}</h3>
      <ImportCsvUpload onFiles={onFiles} />
      <Button variant="link" className="p-0 mt-3" href={exampleCsv}>
        {__('repairCost.downloadExampleFile')}
      </Button>
      <FormButtonModal>
        <Button
          variant="secondary"
          onClick={() => {
            if (stopPolling) {
              stopPolling();
            }
            setActivePhase(ImportRepairCostPhase.UPLOADING);
            closeModal();
          }}
          data-qa-selector="closeImportRepairCostModal"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="ml-4"
          data-qa-selector="confirmImportRepairCostModal"
          disabled={!csvFile}
          onClick={uploadCsv}
        >
          {__('action.confirm')}
        </Button>
      </FormButtonModal>
    </>
  );
};

export default ImportRepairCostUpload;
