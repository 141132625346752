import React from 'react';
import cns from 'classnames';

import LoadingIndicator from 'components/LoadingIndicator';

import cn from './IconButton.module.scss';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  icon: React.FunctionComponent;
  loading?: boolean;
  selected?: boolean;
  readOnly?: boolean;
};

const IconButton = ({
  icon,
  loading,
  className,
  selected,
  readOnly,
  ...rest
}: Props) => {
  const { disabled } = rest;
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={cns(className, readOnly ? cn.readOnlyButton : cn.button, {
        [cn.selected]: selected,
        [cn.disabled]: disabled || readOnly,
      })}
      data-selected={selected}
      {...rest}
      disabled={disabled || readOnly}
    >
      {loading ? (
        <LoadingIndicator className="d-inline" />
      ) : (
        React.createElement(icon)
      )}
    </button>
  );
};

export default IconButton;
