import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationCreatePartnerWorkshopConnectionsArgs,
  PartnerWorkshopConnectionCreateInput,
  QueryActiveGroupedPartnerWorkshopConnectionsArgs,
} from 'graphqlTypes';
import ACTIVE_PARTNER_WORKSHOP_CONNECTIONS from './gql/activeGroupedPartnerWorkshopConnections';
import CREATE_PARTNER_WORKSHOP_CONNECTIONS from './gql/createPartnerWorkshopConnections';

const useCreatePartnerWorkshopConnections = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createPartnerWorkshopConnections'>,
    MutationCreatePartnerWorkshopConnectionsArgs
  >(CREATE_PARTNER_WORKSHOP_CONNECTIONS);

  const createPartnerWorkshopConnections = (
    input: PartnerWorkshopConnectionCreateInput,
    partnerWorkshopConnectionArgs: QueryActiveGroupedPartnerWorkshopConnectionsArgs,
  ) =>
    mutation({
      variables: { input },
      refetchQueries: [
        {
          query: ACTIVE_PARTNER_WORKSHOP_CONNECTIONS,
          variables: partnerWorkshopConnectionArgs,
        },
      ],
    });

  return [
    createPartnerWorkshopConnections,
    {
      data: data?.createPartnerWorkshopConnections,
      ...rest,
    },
  ] as const;
};

export default useCreatePartnerWorkshopConnections;
