import React from 'react';
import { Button } from 'react-bootstrap';
import { noop } from 'lodash/fp';

import LeftArrow from './LeftArrow';

import cn from './BackButton.module.scss';

type Props = {
  as?: React.ElementType;
  to?: string;
  onClick?: () => void;
  children: string | React.ReactNode;
  disabled?: boolean;
  className?: string;
  qaSelector?: string;
};

const BackButton = ({
  as,
  to,
  onClick = noop,
  children,
  disabled,
  className,
  qaSelector,
}: Props) => {
  return (
    <Button
      as={as}
      onClick={onClick}
      to={to}
      variant="link"
      disabled={disabled}
      className={className}
      data-qa-selector={qaSelector}
    >
      <LeftArrow className={cn.arrow} />
      <span className="pl-2">{children}</span>
    </Button>
  );
};

export default BackButton;
