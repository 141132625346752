import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationCancelRepairCostImportArgs } from 'graphqlTypes';
import CANCEL_REPAIR_COST_IMPORT from './gql/cancelRepairCostImport';

const useCancelRepairCostImport = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'cancelRepairCostImport'>,
    MutationCancelRepairCostImportArgs
  >(CANCEL_REPAIR_COST_IMPORT);

  const cancelRepairCostImport = (id: string) =>
    mutation({ variables: { id } });

  return [
    cancelRepairCostImport,
    {
      data: data?.cancelRepairCostImport,
      ...rest,
    },
  ] as const;
};

export default useCancelRepairCostImport;
