import { components, LoadingIndicatorProps } from 'react-select';

const createLoadingIndicator = (selector: string) => (
  props: LoadingIndicatorProps<any>,
) => {
  return (
    <div data-qa-selector={selector}>
      <components.LoadingIndicator {...props} />
    </div>
  );
};

export default createLoadingIndicator;
