import gql from 'graphql-tag';

import { RefurbishmentItemPayloadFragment } from './fragments';

export default gql`
  mutation updateQualityCheckRefurbishmentItem(
    $itemId: UUID!
    $refurbishmentItemUpdateInput: RefurbishmentItemUpdateInput!
  ) {
    updateQualityCheckRefurbishmentItem(
      itemId: $itemId
      refurbishmentItemUpdateInput: $refurbishmentItemUpdateInput
    ) {
      id
      data {
        ...RefurbishmentItemPayloadFragment
      }
    }
  }
  ${RefurbishmentItemPayloadFragment}
`;
