import { User } from 'graphqlTypes';

export type MainWorkshop = {
  name: string;
  branchId: number;
};

export const ALL_MAIN_WORKSHOPS: MainWorkshop = {
  name: __('refurbishment.mainWorkshopSelector.all'),
  branchId: 0,
};

const getMainWorkshopIdsFromUserPWC = (user: User): number[] =>
  user.userPartnerWorkshopConnections?.map(pwc => pwc.mainWorkshop.branchId) ||
  [];

export const getMainWorkshop = (
  user?: User,
  currentWorkshop?: MainWorkshop,
) => {
  if (currentWorkshop?.branchId) {
    return {
      mainWorkshopIds: [currentWorkshop.branchId],
    };
  }
  if (user) {
    return {
      mainWorkshopIds: getMainWorkshopIdsFromUserPWC(user),
    };
  }
  return {
    mainWorkshopIds: [],
  };
};
