import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import FieldError from 'components/Form/FieldError';
import PageContainer from 'components/Layout/PageContainer/PageContainer';
import { sendReq } from 'modules/apiRest';
import { useLogger } from 'modules/logger';
import { getUrl } from 'modules/routeUtils';

type FormData = {
  newPassword: string;
  confirmPassword: string;
};

const CreatePasswordRoute = () => {
  const { logError } = useLogger();
  const { hash } = useParams<{ hash: string }>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const navigate = useNavigate();

  const onSubmit = async ({ newPassword }: FormData) => {
    try {
      await sendReq('CREATE_NEW_PASSWORD', hash, newPassword);
      window.alert(__('password.created.message'));
      navigate(getUrl('LOGIN'));
    } catch (error) {
      logError({ error });
      // eslint-disable-next-line no-alert
      window.alert(__('password.created.failedMessage'));
    }
  };

  return (
    <PageContainer>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formPassword">
              <Form.Label>{__('login.password')}</Form.Label>
              <Form.Control type="password" {...register('newPassword')} />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword">
              <Form.Label>{__('user.confirmPassword')}</Form.Label>
              <Form.Control
                type="password"
                {...register('confirmPassword', {
                  validate: value =>
                    value === watch('newPassword') ||
                    __('field.passwordNotEqual'),
                })}
              />
              <FieldError name="confirmPassword" errors={errors} />
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                {__('action.save')}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default CreatePasswordRoute;
