import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';

import PageContainer from 'components/Layout/PageContainer';
import LoadingIndicator from 'components/LoadingIndicator';
import { initialState } from 'constants/appstate';
import { useAuth } from 'modules/auth';

const Logout = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    signOut(() => {
      client.clearStore().then(() => client.writeData({ data: initialState }));
      navigate('/', { replace: true });
    });
  }, []);

  return (
    <PageContainer>
      <div className="text-center">
        <LoadingIndicator />
      </div>
    </PageContainer>
  );
};

export default Logout;
