import { useCallback } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MainWorkshopSelector from 'components/MainWorkshopSelector';
import { FilterType } from 'helpers/listFilterPersistor/types';
import { useGetFilterPersistor } from 'helpers/listFilterPersistor/useGetFilterPersistor';
import { ACL, Role } from 'modules/acl';
import { useAuth } from 'modules/auth';
import { DeveloperModeToggle } from 'modules/developerMode';
import { getUrl } from 'modules/routeUtils';
import LanguageSelector from './LanguageSelector';
import logo from './logo.svg';

import cn from './Header.module.scss';

const Header = () => {
  const { info } = useAuth();
  const getFilters = useGetFilterPersistor();

  const calculateAppendedUrl = useCallback(
    (url: string, filtersFor: FilterType) => {
      const filters = getFilters(filtersFor);

      return filters ? `${url}/${filters}` : url;
    },
    [getFilters],
  );

  return (
    <>
      <div className={cn.headerTop}>
        <Container className="d-flex flex-row-reverse justify-content-between py-1">
          {info.isAuthorized ? (
            <>
              <MainWorkshopSelector />
              {process.env.REACT_APP_ENABLE_DEVELOPER_MODE && (
                <DeveloperModeToggle />
              )}
            </>
          ) : (
            <div>&nbsp;</div>
          )}
        </Container>
      </div>
      <Navbar className={cn.header} expand="lg">
        <Container>
          <Navbar.Brand
            as={Link}
            to={getUrl('REFURBISHMENT')}
            data-qa-selector="home_link"
          >
            <img src={logo} alt={__('header.appName')} className={cn.logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-between">
            <Nav>
              {info.isAuthorized ? (
                <>
                  <ACL roles={Role.READ}>
                    <Nav.Link
                      as={Link}
                      to={calculateAppendedUrl(
                        getUrl('REFURBISHMENT'),
                        FilterType.REFURBISHMENT,
                      )}
                      data-qa-selector="refurbishment_link"
                    >
                      {__('header.refurbishment')}
                    </Nav.Link>
                  </ACL>
                  <ACL roles={[Role.TASK_READ]}>
                    <Nav.Link
                      as={Link}
                      to={calculateAppendedUrl(
                        getUrl('REFURBISHMENT_TASK'),
                        FilterType.REFURBISHMENT,
                      )}
                      data-qa-selector="refurbishment-task-list-link"
                    >
                      {__('header.task')}
                    </Nav.Link>
                  </ACL>
                  <ACL
                    roles={[Role.EXIT_CHECK_VIEW, Role.EXIT_CHECK_READ_ONLY]}
                  >
                    <Nav.Link
                      as={Link}
                      to={calculateAppendedUrl(
                        getUrl('EXIT_CHECK_LIST'),
                        FilterType.EXITCHECK,
                      )}
                      data-qa-selector="exit-checks-list-link"
                    >
                      {__('header.exitCheck')}
                    </Nav.Link>
                  </ACL>
                  <ACL roles={Role.PRODUCTION_READ}>
                    <Nav.Link
                      as={Link}
                      to={getUrl('PRODUCTION')}
                      data-qa-selector="production-link"
                    >
                      {__('header.production')}
                    </Nav.Link>
                  </ACL>
                  <ACL roles={Role.INFORM_MONITORING}>
                    <Nav.Link
                      as={Link}
                      to={getUrl('MONITORING')}
                      data-qa-selector="monitoring-dashboard"
                    >
                      {__('header.monitoring')}
                    </Nav.Link>
                  </ACL>
                  <ACL
                    roles={[
                      Role.PARTNER_LOCATION_CONNECTIONS_READ,
                      Role.USERS_ADMIN,
                      Role.GROUPS_ADMIN,
                      Role.COST_TABLE_READ,
                      Role.REPAIR_METHOD_READ,
                      Role.REPAIR_COST_READ,
                      Role.PARTNER_READ,
                    ]}
                  >
                    <NavDropdown
                      title={__('header.security')}
                      id="security-dropdown"
                      data-qa-selector="refurbishment_security_dropdown"
                    >
                      <ACL roles={Role.PARTNER_LOCATION_CONNECTIONS_READ}>
                        <NavDropdown.Item
                          as={Link}
                          to={getUrl('PARTNER_WORKSHOP_CONNECTIONS')}
                          data-qa-selector="refurbishment_security_partner_workshop_connections"
                        >
                          {__('header.partnerWorkshopConnections')}
                        </NavDropdown.Item>
                      </ACL>
                      <ACL roles={Role.USERS_ADMIN}>
                        <NavDropdown.Item
                          as={Link}
                          to={getUrl('USER')}
                          data-qa-selector="refurbishment_security_users"
                        >
                          {__('header.user')}
                        </NavDropdown.Item>
                      </ACL>
                      <ACL roles={Role.GROUPS_ADMIN}>
                        <NavDropdown.Item
                          as={Link}
                          to={getUrl('GROUP')}
                          data-qa-selector="refurbishment_security_groups"
                        >
                          {__('header.group')}
                        </NavDropdown.Item>
                      </ACL>
                      <ACL roles={Role.COST_TABLE_READ}>
                        <NavDropdown.Item
                          as={Link}
                          to={`${getUrl(
                            'COST_TABLE',
                          )}?sortField=mainWorkshop&sortDir=ASC`}
                          data-qa-selector="refurbishment_security_cost_table"
                        >
                          {__('header.costTable')}
                        </NavDropdown.Item>
                      </ACL>
                      <ACL roles={Role.REPAIR_METHOD_READ}>
                        <NavDropdown.Item
                          as={Link}
                          to={`${getUrl(
                            'REPAIR_METHOD',
                          )}?sortField=mainWorkshop&sortDir=ASC`}
                          data-qa-selector="refurbishment_security_repair_method"
                        >
                          {__('header.repairMethods')}
                        </NavDropdown.Item>
                      </ACL>
                      <ACL roles={Role.REPAIR_COST_READ}>
                        <NavDropdown.Item
                          as={Link}
                          to={`${getUrl(
                            'REPAIR_COST',
                          )}?sortField=mainWorkshop&sortDir=ASC`}
                          data-qa-selector="refurbishment_security_repair_cost"
                        >
                          {__('header.repairCosts')}
                        </NavDropdown.Item>
                      </ACL>
                      <ACL roles={Role.PARTNER_READ}>
                        <NavDropdown.Item
                          as={Link}
                          to={`${getUrl(
                            'PARTNERS',
                          )}?sortField=partner&sortDir=ASC`}
                          data-qa-selector="refurbishment_security_repair_cost"
                        >
                          {__('header.partners')}
                        </NavDropdown.Item>
                      </ACL>
                    </NavDropdown>
                  </ACL>
                </>
              ) : null}
            </Nav>
            <Nav>
              {info.isAuthorized ? (
                <Nav.Link
                  as={Link}
                  to={getUrl('LOGOUT')}
                  data-qa-selector="logout_link"
                >
                  {__('header.logout')}
                </Nav.Link>
              ) : (
                <Nav.Link
                  as={Link}
                  to={getUrl('LOGIN')}
                  data-qa-selector="login_link"
                >
                  {__('header.login')}
                </Nav.Link>
              )}
              <LanguageSelector />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
