import gql from 'graphql-tag';

export default gql`
  query repairCostByDamage($repairCostByDamageInput: RepairCostByDamageInput!) {
    repairCostByDamage(repairCostByDamageInput: $repairCostByDamageInput) {
      repairCosts {
        totalCosts {
          amount
          currency
        }
        materialCosts {
          amount
          currency
        }
        splitMaterialCosts {
          materialCost {
            amount
          }
          consumableCost {
            amount
          }
          sparePartCost {
            amount
          }
        }
        labour {
          rate {
            amount
          }
          timeInHours
        }
      }
    }
  }
`;
