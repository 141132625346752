import gql from 'graphql-tag';

export const RefurbishmentItemPayloadFragment = gql`
  fragment RefurbishmentItemPayloadFragment on RefurbishmentItemPayload {
    providerTotalCostMinorUnits
    wheelSetsSwitched
    wheelSetDeleted
    wheelSetDeletedReason
    serviceBookType
    serviceBookPresent
  }
`;

export const QualityCheckApprovalVehicleItemPayloadFragment = gql`
  fragment QualityCheckApprovalVehicleItemPayloadFragment on VehicleItemPayload {
    mileage {
      distance
      unit
    }
    inspectionMileage {
      distance
      unit
    }
    maintenanceMileage {
      distance
      unit
    }
    serviceCompany
    serviceBookType
    serviceDueDate
    serviceDueMileage {
      distance
      unit
    }
    isServiceRequired
    inspectionDate
    inspectionDueDate
    maintenanceDate
    wheelSets {
      isPrimary
      wheels {
        position
        season
        seasonLabel
        brakeDamageId
        brakeCondition
        profile {
          depth
          unit
        }
        rimType
        rimSize {
          value
          unit
        }
        wheelSize {
          value
          height
          unit
        }
        loadIndex
        speedIndex
        manufacturer
        dot
        imageUrl
      }
    }
  }
`;

export const ServiceItemPayloadFragment = gql`
  fragment ServiceItemPayloadFragment on ServiceItemPayload {
    serviceName
    serviceType
    budgetMinorUnits
    serviceComment
    # state
    stateComment
    relatedServiceId
    partnerWorkshopConnectionId
    laborRateMinorUnits
    laborTimeHours
    consumableCostMinorUnits
    sparePartCostMinorUnits
  }
`;

export const DamageServiceFragment = gql`
  fragment DamageServiceFragment on DamageService {
    serviceName
    serviceType
    budgetMinorUnits
    sparePartCostMinorUnits
    consumableCostMinorUnits
    laborRateMinorUnits
    laborTimeHours
    serviceComment
    state
    stateComment
    partnerWorkshopConnectionId
  }
`;

export const DamageItemPayloadV2FragmentQCA = gql`
  fragment DamageItemPayloadV2FragmentQCA on DamageItemPayloadV2 {
    area
    subArea
    partName
    typeName
    isSecondaryWheel
    photoId
    imageUrl
    customerDisplay
    # state
    stateComment
    currentService {
      ...DamageServiceFragment
    }
    newService {
      ...DamageServiceFragment
    }
  }
  ${DamageServiceFragment}
`;

export const ItemFragmentQCA = gql`
  fragment ItemFragmentQCA on Item {
    id
    phaseName
    data {
      payloadType
      ... on ServiceItemPayload {
        serviceState: state
      }
      ... on DamageItemPayloadV2 {
        damageState: state
      }
      ...QualityCheckApprovalVehicleItemPayloadFragment
      ...ServiceItemPayloadFragment
      ...DamageItemPayloadV2FragmentQCA
      ...RefurbishmentItemPayloadFragment
    }
    revisions {
      id
      propertyPath
      state
    }
    createdOn
  }
  ${QualityCheckApprovalVehicleItemPayloadFragment}
  ${ServiceItemPayloadFragment}
  ${DamageItemPayloadV2FragmentQCA}
  ${RefurbishmentItemPayloadFragment}
`;
