import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

import CollapsableContent from 'components/UI/CollapsableContent';
import {
  RefurbishmentServiceType,
  ServiceData,
  VehicleServiceCost,
  VehicleServiceCostUpdateInput,
} from 'graphqlTypes';
import { useUpdateVehicleServiceCost } from 'modules/api/costTable';
import { useGetServiceTypes } from 'modules/api/services';
import { unitsFromMajorToMinor, unitsFromMinorToMajor } from 'modules/currency';
import { formatString } from 'modules/i18n';
import {
  MAX_CONSUMABLE_COST,
  MAX_LABOR_TIME_IN_HOURS,
  MAX_SPARE_PART_COST,
} from './constants';
import CostTableCost from './CostTableCost';
import InlineFieldError from './InlineFieldError';
import LaborRate from './LaborRate';
import LaborTime from './LaborTime';
import MaterialCost from './MaterialCost';
import { CostTableRowMode, RowFormData } from './types';
import {
  createLaborTotalPriceMinorUnits,
  createTotalMaterialCostMinorUnits,
  createTotalPriceMinorUnits,
} from './utils';

import cn from './CostTableRow.module.scss';

type Props = {
  item: VehicleServiceCost;
  allServiceTypes: ServiceData[];
  editable: boolean;
  mode: CostTableRowMode;
  onEditClick: (id: string) => void;
  onCancelClick: () => void;
  onRowSaved: () => void;
};

const CostTableRow = ({
  item,
  allServiceTypes,
  editable,
  mode,
  onEditClick,
  onCancelClick,
  onRowSaved,
}: Props) => {
  const {
    data: serviceTypesData,
    loading: serviceTypesLoading,
  } = useGetServiceTypes();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<RowFormData>({
    defaultValues: {
      consumableCost: unitsFromMinorToMajor(
        item.serviceCost.consumableCostMinorUnits,
      ),
      sparePartCost: unitsFromMinorToMajor(
        item.serviceCost.sparePartCostMinorUnits,
      ),
      laborTime: item.serviceCost.laborTimeHours,
      laborRate: unitsFromMinorToMajor(item.serviceCost.laborRateMinorUnits),
      active: item.active,
      totalPriceMinorUnits: item.serviceCost.totalPriceMinorUnits,
      isDefault: item.isDefault,
      isAutoCreate: !!item.isAutoCreate,
    },
  });

  const [collapsed, setCollapsed] = useState(true);

  const [updateVehicleServiceCost] = useUpdateVehicleServiceCost();

  const consumableCost = watch('consumableCost');
  const sparePartCost = watch('sparePartCost');
  const laborTime = watch('laborTime');
  const laborRate = watch('laborRate');

  const totalMaterialCost = createTotalMaterialCostMinorUnits(
    Number(consumableCost),
    Number(sparePartCost),
  );
  const laborTotalPriceMinorUnits = createLaborTotalPriceMinorUnits(
    Number(laborTime),
    Number(laborRate),
  );
  const totalPriceMinorUnits = createTotalPriceMinorUnits(
    Number(consumableCost),
    Number(sparePartCost),
    Number(laborTime),
    Number(laborRate),
  );

  const itemServiceType = allServiceTypes.find(
    sd => sd.name.key === item.vehicleServiceName.key,
  )?.type;
  const isServiceWork =
    itemServiceType === RefurbishmentServiceType.ServiceBookSubService;

  const disableAutoCreate =
    serviceTypesLoading ||
    !serviceTypesData ||
    !!serviceTypesData.find(
      service =>
        service.name.key === item.vehicleServiceName.key &&
        service.type === RefurbishmentServiceType.VehicleServiceCost,
    );

  const onSubmit = (formData: RowFormData) => {
    const input: VehicleServiceCostUpdateInput = {
      serviceCost: {
        laborRateMinorUnits: unitsFromMajorToMinor(formData.laborRate),
        laborTimeHours: formData.laborTime,
        consumableCostMinorUnits: unitsFromMajorToMinor(
          formData.consumableCost,
        ),
        sparePartCostMinorUnits: unitsFromMajorToMinor(formData.sparePartCost),
        currencyCode: item.serviceCost.currencyCode,
      },
      active: formData.active,
      isDefault: formData.isDefault,
      isAutoCreate: formData.isAutoCreate,
    };
    updateVehicleServiceCost(item.id, input).then(() => onRowSaved());
  };

  const isEditOrNewMode =
    mode === CostTableRowMode.NEW || mode === CostTableRowMode.EDIT;

  const form = `costTable-${item.id}`;

  return (
    <tr
      className={mode === CostTableRowMode.EDIT ? cn.selectedRow : ''}
      key={item.id}
      data-qa-selector={`row-${item.id}`}
      data-qa-id={item.id}
    >
      <td data-qa-selector="mainWorkshop">{item.mainWorkshop.name}</td>
      <td data-qa-selector="partner">{item.refurbishmentPartner.name}</td>
      <td data-qa-selector="repairPartner">{item.location.name}</td>
      <td data-qa-selector="serviceType">
        {isServiceWork
          ? `${__('costTable.swPrefix')} ${item.vehicleServiceName.translation}`
          : item.vehicleServiceName.translation}
      </td>
      <td data-qa-selector="laborTotal">
        {isEditOrNewMode && (
          <CollapsableContent
            name={`laborTotal-${item.id}`}
            collapsed={false}
            header={
              <CostTableCost
                costMinorUnits={laborTotalPriceMinorUnits}
                currencyCode={item.serviceCost.currencyCode}
                qaSelector="laborTotalRO"
              />
            }
          >
            <div data-qa-selector="laborTime">
              <LaborTime
                form={form}
                control={control}
                name="laborTime"
                timeInHours={item.serviceCost.laborTimeHours}
                editable={isEditOrNewMode}
                rules={{
                  min: { value: 0, message: __('field.positive') },
                  max: {
                    value: MAX_LABOR_TIME_IN_HOURS,
                    message: formatString(__('field.lowerOrEqualTo'), {
                      value: `${MAX_LABOR_TIME_IN_HOURS} ${__(
                        'costTable.hours',
                      )}`,
                    }),
                  },
                }}
              />
              <InlineFieldError name="laborTime" errors={errors} />
            </div>
            <div data-qa-selector="laborRate" className="mt-2">
              <LaborRate
                form={form}
                control={control}
                name="laborRate"
                currencyCode={item.serviceCost.currencyCode}
                laborRateMinorUnits={item.serviceCost.laborRateMinorUnits}
                editable={isEditOrNewMode}
                rules={{
                  min: { value: 0, message: __('field.positive') },
                }}
                data-qa-selector="laborRateEditable"
              />
              <InlineFieldError name="laborRate" errors={errors} />
            </div>
          </CollapsableContent>
        )}
        {!isEditOrNewMode && (
          <CollapsableContent
            name={`total-${item.id}`}
            header={
              <CostTableCost
                costMinorUnits={laborTotalPriceMinorUnits}
                currencyCode={item.serviceCost.currencyCode}
                qaSelector="laborTotalRO"
              />
            }
            collapsed={collapsed}
            toggleClicked={() => setCollapsed(!collapsed)}
          >
            <div data-qa-selector="laborTime">
              <LaborTime
                form={form}
                control={control}
                name="laborTime"
                timeInHours={item.serviceCost.laborTimeHours}
                editable={false}
              />
            </div>
            <div data-qa-selector="laborRate">
              <LaborRate
                form={form}
                control={control}
                name="laborRate"
                currencyCode={item.serviceCost.currencyCode}
                laborRateMinorUnits={item.serviceCost.laborRateMinorUnits}
                editable={false}
                data-qa-selector="laborRateNonEditable"
              />
              <InlineFieldError name="laborRate" errors={errors} />
            </div>
          </CollapsableContent>
        )}
      </td>
      <td data-qa-selector="materialTotal">
        {isEditOrNewMode && (
          <CollapsableContent
            name={`materialTotal-${item.id}`}
            collapsed={false}
            header={
              <CostTableCost
                costMinorUnits={totalMaterialCost}
                currencyCode={item.serviceCost.currencyCode}
                qaSelector="materialTotalRO"
              />
            }
          >
            <div data-qa-selector="sparePartCost">
              <MaterialCost
                form={form}
                name="sparePartCost"
                currencyCode={item.serviceCost.currencyCode}
                control={control}
                rules={{
                  min: { value: 0, message: __('field.positive') },
                  max: {
                    value: MAX_SPARE_PART_COST,
                    message: formatString(__('field.lowerOrEqualTo'), {
                      value: `${MAX_SPARE_PART_COST}`,
                    }),
                  },
                }}
                suffix={__('costTable.sparePart')}
                data-qa-selector="sparePartCostEditable"
              />
              <InlineFieldError name="sparePartCost" errors={errors} />
            </div>
            <div data-qa-selector="consumableCost" className="mt-2">
              <MaterialCost
                form={form}
                name="consumableCost"
                currencyCode={item.serviceCost.currencyCode}
                control={control}
                rules={{
                  min: { value: 0, message: __('field.positive') },
                  max: {
                    value: MAX_CONSUMABLE_COST,
                    message: formatString(__('field.lowerOrEqualTo'), {
                      value: `${MAX_CONSUMABLE_COST}`,
                    }),
                  },
                }}
                suffix={__('costTable.consumable')}
                data-qa-selector="consumableCostEditable"
              />
              <InlineFieldError name="consumableCost" errors={errors} />
            </div>
          </CollapsableContent>
        )}

        {!isEditOrNewMode && (
          <CollapsableContent
            name={`total-${item.id}`}
            header={
              <CostTableCost
                costMinorUnits={totalMaterialCost}
                currencyCode={item.serviceCost.currencyCode}
                qaSelector="materialTotalRO"
              />
            }
            collapsed={collapsed}
            toggleClicked={() => setCollapsed(!collapsed)}
          >
            <div data-qa-selector="sparePartCost">
              <CostTableCost
                costMinorUnits={item.serviceCost.sparePartCostMinorUnits}
                currencyCode={item.serviceCost.currencyCode}
                qaSelector="sparePartCostMinorUnitsRO"
              />{' '}
              {__('costTable.sparePart')}
            </div>
            <div data-qa-selector="consumableCost">
              <CostTableCost
                costMinorUnits={item.serviceCost.consumableCostMinorUnits}
                currencyCode={item.serviceCost.currencyCode}
                qaSelector="consumableCostMinorUnitsRO"
              />{' '}
              {__('costTable.consumable')}
            </div>
          </CollapsableContent>
        )}
      </td>
      <td data-qa-selector="totalPrice">
        <CostTableCost
          costMinorUnits={totalPriceMinorUnits}
          currencyCode={item.serviceCost.currencyCode}
          qaSelector="totalPriceRO"
        />
      </td>
      <td data-qa-selector="isAutoCreate">
        <Form.Check
          type="checkbox"
          id={`costTable-isAutoCreate-${item.id}`}
          disabled={
            mode === CostTableRowMode.DISPLAY ||
            mode === CostTableRowMode.DISABLED ||
            disableAutoCreate
          }
          {...register('isAutoCreate')}
          custom
          className="font-weight-bold"
        />
      </td>
      <td data-qa-selector="isDefault">
        <Form.Check
          type="checkbox"
          id={`costTable-isDefault-${item.id}`}
          disabled={
            mode === CostTableRowMode.DISPLAY ||
            mode === CostTableRowMode.DISABLED
          }
          {...register('isDefault')}
          custom
          className="font-weight-bold"
        />
      </td>
      <td data-qa-selector="active">
        <Form.Check
          type="checkbox"
          id={`costTable-active-${item.id}`}
          disabled={
            mode === CostTableRowMode.DISPLAY ||
            mode === CostTableRowMode.DISABLED
          }
          {...register('active')}
          custom
          className="font-weight-bold"
        />
      </td>
      <td data-qa-selector="actions">
        <form onSubmit={handleSubmit(onSubmit)} id={form}>
          {mode === CostTableRowMode.DISPLAY && editable && (
            <EditIcon
              color="primary"
              onClick={() => onEditClick(item.id)}
              data-qa-selector="editInDisplayMode"
              className={cn.icon}
            />
          )}
          {mode === CostTableRowMode.DISABLED && (
            <EditIcon
              color="disabled"
              data-qa-selector="editInDisabledMode"
              className={cn.icon}
            />
          )}
          {mode === CostTableRowMode.EDIT && (
            <>
              <ClearIcon
                color="primary"
                onClick={() => {
                  reset();
                  onCancelClick();
                }}
                data-qa-selector="cancelInEditMode"
                className={cn.icon}
              />
              <button
                type="submit"
                data-qa-selector="acceptInEditMode"
                className={cn.button}
              >
                <DoneIcon color="primary" />
              </button>
            </>
          )}
        </form>
      </td>
    </tr>
  );
};

export default CostTableRow;
