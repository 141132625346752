import { useLazyQuery } from '@apollo/react-hooks';

import {
  AwsImageUploadCredentialsInput,
  Query,
  QueryBuildAdImageUploadCredentialsArgs,
} from 'graphqlTypes';
import BUILD_AD_IMAGE_UPLOAD_CREDENTIALS from './gql/buildAdImageUploadCredentials';

export default () => {
  const [query, result] = useLazyQuery<
    Query['buildAdImageUploadCredentials'],
    QueryBuildAdImageUploadCredentialsArgs
  >(BUILD_AD_IMAGE_UPLOAD_CREDENTIALS);

  const getData = ({ uploadImagesInfos }: AwsImageUploadCredentialsInput) => {
    query({
      variables: {
        awsImageUploadCredentialsInput: {
          uploadImagesInfos,
        },
      },
    });
  };

  return { getData, result };
};
