import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import SortableTableHeader from 'components/SortableTableHeader';
import {
  RepairMethodFilter,
  RepairMethodSortInput,
  SortDirection,
} from 'graphqlTypes';
import { useRepairMethodSearch } from 'modules/api/costTable';
import RepairMethodPagination from './RepairMethodPagination';
import RepairMethodRow from './RepairMethodRow';
import { FormFilterNames } from '../../FormFilterNames';

import cn from './RepairMethodTable.module.scss';

const RepairMethodTable = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const page = Math.max(Number(searchParams.get('p')) - 1 || 0, 0);
  const countryCodes = searchParams.get(FormFilterNames.COUNTRIES) || '';
  const mainWorkshopIds =
    searchParams.get(FormFilterNames.MAIN_WORKSHOPS) || '';
  const area = searchParams.get(FormFilterNames.AREA) || '';
  const damageType = searchParams.get(FormFilterNames.DAMAGE_TYPE) || '';
  const part = searchParams.get(FormFilterNames.PART) || '';
  const repairMethodBelowThreshold =
    searchParams.get(FormFilterNames.REPAIR_METHOD_LT) || '';
  const repairMethodEqualOrAboveThreshold =
    searchParams.get(FormFilterNames.REPAIR_METHOD_GTE) || '';
  const sortField = searchParams.get('sortField') || '';
  const sortDir = searchParams.get('sortDir') || '';
  const active = searchParams.get(FormFilterNames.ACTIVE_ONLY) || '';

  const filter: RepairMethodFilter = {};
  if (countryCodes) {
    filter.countryCodes = countryCodes.split(',');
  }
  if (mainWorkshopIds) {
    filter.mainWorkshopIds = mainWorkshopIds
      .split(',')
      .map(s => parseInt(s, 10));
  }
  if (area) {
    filter.area = area;
  }
  if (damageType) {
    filter.damageType = damageType;
  }
  if (part) {
    filter.part = part;
  }
  if (repairMethodBelowThreshold) {
    filter.repairMethodBelowThreshold = repairMethodBelowThreshold;
  }
  if (repairMethodEqualOrAboveThreshold) {
    filter.repairMethodEqualOrAboveThreshold = repairMethodEqualOrAboveThreshold;
  }
  if (active) {
    filter.active = true;
  }

  const sort: RepairMethodSortInput = {
    createdOn: SortDirection.Desc,
  };
  if (sortField === 'area') {
    sort.area = sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
  } else if (sortField === 'damageType') {
    sort.damageType =
      sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
  } else if (sortField === 'part') {
    sort.part = sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;
  }

  const searchArgs = {
    page,
    pageSize: 50,
    sort,
    filter,
  };
  const { data, loading } = useRepairMethodSearch(searchArgs);

  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  const { items } = data;

  return (
    <>
      <Table striped responsive>
        <thead>
          <tr>
            <th className={cn.mainWorkshop}>
              <SortableTableHeader
                header={__('repairMethod.mainWorkshop')}
                sortField="mainWorkshop"
              />
            </th>
            <th className={cn.area}>
              <SortableTableHeader
                header={__('repairMethod.area')}
                sortField="area"
              />
            </th>
            <th className={cn.damageType}>
              <SortableTableHeader
                header={__('repairMethod.damageType')}
                sortField="damageType"
              />
            </th>
            <th className={cn.part}>
              <SortableTableHeader
                header={__('repairMethod.part')}
                sortField="part"
              />
            </th>
            <th className={cn.attribute1Name}>
              {__('repairMethod.attribute1Name')}
            </th>
            <th className={cn.attribute1Value}>
              {__('repairMethod.attribute1Value')}
            </th>
            <th className={cn.attribute2Name}>
              {__('repairMethod.attribute2Name')}
            </th>
            <th className={cn.attribute2Value}>
              {__('repairMethod.attribute2Value')}
            </th>
            <th className={cn.quantityThreshold}>
              {__('repairMethod.quantityThreshold')}
            </th>
            <th className={cn.repairMethodBelowThreshold}>
              {__('repairMethod.repairMethodBelowThreshold')}
            </th>
            <th className={cn.repairMethodEqualOrAboveThreshold}>
              {__('repairMethod.repairMethodEqualOrAboveThreshold')}
            </th>
            <th className={cn.active}>{__('repairMethod.active')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            return <RepairMethodRow key={item.id} item={item} />;
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <RepairMethodPagination totalPageCount={data.pageInfo.totalPageCount} />
      </div>
    </>
  );
};

export default RepairMethodTable;
