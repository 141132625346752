import React from 'react';

import {
  useBrakeDiskConditions,
  useBrakeTypes,
} from 'modules/api/wheelAttributes';
import { SelectOption } from 'routes/ExitCheckRoute/types';
import {
  AddOptions,
  brakeReplaceDamageServices,
} from 'routes/RefurbishmentRoute/components/WheelCheckpoints/mapData';
import { BrakeCheckpointsSelectGraphQlJsonPayload } from 'routes/RefurbishmentRoute/helpers/mapData/getCheckpoints';
import {
  EntryCheckItem,
  PayloadType,
  WheelPosition,
} from 'routes/RefurbishmentRoute/types';
import BrakeSelectItem from './Common/BrakeSelectItem';
import { BrakeType } from '../BrakeCheckpoints';

const BRAKE_DISK_LESS_25_PERCENT =
  'disk_greater_than_or_equal_0_and_less_than_25_percent';
const BRAKE_DISK_25_PERCENT = 'disk_25_percent';
const BRAKE_GOOD_CONDITION = 'ceramic_in_good_condition';

type Props = {
  items?: EntryCheckItem[];
  disabled: boolean;
  brakeType: string;
  position: WheelPosition;
  setAddOption(otpion: AddOptions): void;
};

const BrakeDiskInGoodCondition = ({
  items,
  disabled,
  brakeType,
  position,
  setAddOption,
}: Props) => {
  const { data: brakeTypes } = useBrakeTypes();
  const brakeTypeId = brakeTypes?.find(item => item.brakeType.key === brakeType)
    ?.id;
  const { data, loading } = useBrakeDiskConditions(brakeTypeId);

  const entryCheckItem = items?.find(
    item => item.payloadType === PayloadType.BrakeDiskCondition,
  );

  if (!entryCheckItem || !data) return null;

  const value = (entryCheckItem?.values?.[0]
    ?.proposedValue as BrakeCheckpointsSelectGraphQlJsonPayload)
    ?.brakeCondition;

  return (
    <BrakeSelectItem
      label={__('entryCheck.brakeCheckpoints.brakeDiskCondition')}
      required
      name="brakeDisk"
      options={data.map<SelectOption>(
        item =>
          ({
            label: item.recordKey.translation,
            value: item.recordKey.key,
          } as SelectOption),
      )}
      value={value}
      entryCheckItem={entryCheckItem}
      loading={loading}
      disabled={disabled}
      updateEntryCheckItemValueField="condition"
      onChange={selectedValue => {
        if (
          selectedValue === BRAKE_DISK_LESS_25_PERCENT ||
          selectedValue === BRAKE_DISK_25_PERCENT ||
          (brakeType === BrakeType.CERAMIC &&
            selectedValue !== BRAKE_GOOD_CONDITION)
        ) {
          setAddOption({
            addService: brakeReplaceDamageServices[position],
          });
        }
      }}
    />
  );
};

export default BrakeDiskInGoodCondition;
