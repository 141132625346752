import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import {
  REFURBISHMENT_NUMBER_LENGTH,
  SHORT_VIN_NUMBER_LENGTH,
  STOCK_NUMBER_LENGTH,
  VIN_NUMBER_LENGTH,
} from 'constants/refurbishment';
import Button from './Button';
import { FormFilterNames } from './constants';
import EmailInput from './EmailInput';
import LastTransitionDateSortSelect from './LastTransitionDateSortSelect';
import LocationSelect from './LocationSelect';
import NameInput from './NameInput';
import RefStockInput from './RefStockInput';
import RefurbishmentTypeBadges from './RefurbishmentTypeBadges';
import RefurbishmentTypeSelect from './RefurbishmentTypeSelect';
import RefVinStockInput from './RefVinStockInput';
import RefVinStockPlateInput from './RefVinStockPlateInput';
import ReworkOnlyCheck from './ReworkOnlyCheck';
import SparePartStatusBadges from './SparePartStatusBadges';
import SparePartStatusSelect from './SparePartStatusSelect';
import StateBadges from './StateBadges';
import StateSelect from './StateSelect';
import VinStockInput from './VinStockInput';
import VinStockPlateInput from './VinStockPlateInput';

type FormType = Record<FormFilterNames, any>;

type Props = {
  children: React.ReactNode;
  defaultFormValues?: Partial<Record<FormFilterNames, any>>;
  className?: string;
  onChange?: (values: Record<string, string | string[]>) => void;
};

const searchParamsToFormValues = (
  searchParams: Record<string, any>,
  defaultFormValues: Partial<Record<FormFilterNames, any>>,
): FormType => {
  const refurbishmentStates = searchParams?.[FormFilterNames.STATE];
  const refurbishmentTypes = searchParams?.[FormFilterNames.REFURBISHMENT_TYPE];
  const sparePartStatuses = searchParams?.[FormFilterNames.SPARE_PART_STATUS];
  const refurbishmentStockVin =
    searchParams?.[FormFilterNames.REFURBISHMENT_STOCK_VIN];
  const refurbishmentStock =
    searchParams?.[FormFilterNames.REFURBISHMENT_STOCK];
  const vinStock = searchParams?.[FormFilterNames.VIN_STOCK];
  const plateNumber = searchParams?.[FormFilterNames.PLATE_NUMBER];
  const reworkOnly = searchParams?.[FormFilterNames.REWORK_ONLY];
  const fName = searchParams?.[FormFilterNames.FIRST_NAME];
  const email = searchParams?.[FormFilterNames.EMAIL];
  const branch = searchParams?.[FormFilterNames.BRANCH];
  const lastTransitionDate = searchParams?.[FormFilterNames.SORT_DATE];

  return {
    [FormFilterNames.STATE]: refurbishmentStates || null,
    [FormFilterNames.REFURBISHMENT_TYPE]: refurbishmentTypes || null,
    [FormFilterNames.SPARE_PART_STATUS]: sparePartStatuses || null,
    [FormFilterNames.REFURBISHMENT_STOCK_VIN]: refurbishmentStockVin || null,
    [FormFilterNames.REFURBISHMENT_STOCK]: refurbishmentStock || null,
    [FormFilterNames.VIN_STOCK]: vinStock || null,
    [FormFilterNames.PLATE_NUMBER]: plateNumber || null,
    [FormFilterNames.REWORK_ONLY]: reworkOnly || null,
    [FormFilterNames.FIRST_NAME]: fName || null,
    [FormFilterNames.EMAIL]: email || null,
    [FormFilterNames.BRANCH]: branch || null,
    [FormFilterNames.SORT_DATE]:
      lastTransitionDate ||
      defaultFormValues[FormFilterNames.SORT_DATE] ||
      null,
    [FormFilterNames.REFURBISHMENT_NUMBER]: null,
    [FormFilterNames.STOCK_NUMBER]: null,
    [FormFilterNames.VIN_NUMBER]: null,
  };
};

const searchValuesToCleanValues = (values: Record<string, any>) => {
  const refStockVin =
    values[FormFilterNames.REFURBISHMENT_STOCK_VIN] ||
    values[FormFilterNames.REFURBISHMENT_STOCK];
  const refurbishmentNumberValue =
    refStockVin?.length === REFURBISHMENT_NUMBER_LENGTH ? refStockVin : '';
  const stockNumberValue =
    refStockVin?.length === STOCK_NUMBER_LENGTH ? refStockVin : '';
  const vinValue =
    refStockVin?.length === VIN_NUMBER_LENGTH ||
    refStockVin?.length === SHORT_VIN_NUMBER_LENGTH
      ? refStockVin
      : '';
  const reworkOnlyValue =
    typeof values[FormFilterNames.REWORK_ONLY] === 'string'
      ? values[FormFilterNames.REWORK_ONLY] === 'true'
      : !!values[FormFilterNames.REWORK_ONLY];

  const fullValues = {
    ...values,
    [FormFilterNames.REWORK_ONLY]: reworkOnlyValue,
    [FormFilterNames.REFURBISHMENT_NUMBER]: refurbishmentNumberValue,
    [FormFilterNames.STOCK_NUMBER]: stockNumberValue,
    [FormFilterNames.VIN_NUMBER]: vinValue,
  };

  const cleanValues = Object.fromEntries(
    Object.entries(fullValues)
      .filter(([, value]) => (Array.isArray(value) ? value.length : !!value))
      .filter(
        ([name]) =>
          name !== FormFilterNames.REFURBISHMENT_STOCK_VIN &&
          name !== FormFilterNames.REFURBISHMENT_STOCK,
      ),
  );

  return cleanValues;
};

const Filter = ({
  children,
  defaultFormValues = {},
  className,
  onChange,
}: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

  const formMethods = useForm<FormType>({
    defaultValues: async () =>
      searchParamsToFormValues(searchParams, defaultFormValues),
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (!Object.keys(searchParams).length) {
      reset({
        [FormFilterNames.STATE]: null,
        [FormFilterNames.REFURBISHMENT_TYPE]: null,
        [FormFilterNames.REFURBISHMENT_NUMBER]: null,
        [FormFilterNames.STOCK_NUMBER]: null,
        [FormFilterNames.VIN_NUMBER]: null,
        [FormFilterNames.REFURBISHMENT_STOCK_VIN]: null,
        [FormFilterNames.REFURBISHMENT_STOCK]: null,
        [FormFilterNames.VIN_STOCK]: null,
        [FormFilterNames.PLATE_NUMBER]: null,
        [FormFilterNames.REWORK_ONLY]: null,
        [FormFilterNames.FIRST_NAME]: null,
        [FormFilterNames.EMAIL]: null,
        [FormFilterNames.BRANCH]: null,
      });
      if (onChange) onChange({});
    } else {
      reset(searchParamsToFormValues(searchParams, defaultFormValues));
      if (onChange) onChange(searchValuesToCleanValues(searchParams));
    }
  }, [search]);

  useEffect(() => {
    if (onChange) onChange(searchValuesToCleanValues(searchParams));
  }, []);

  const onSubmit = (values: FormType) => {
    const cleanValues = searchValuesToCleanValues(values);

    const qsValues = Object.fromEntries(
      Object.entries(values).filter(([, value]) =>
        Array.isArray(value) ? value.length : !!value,
      ),
    );

    if (onChange) onChange(cleanValues);
    navigate({
      pathname,
      search: qs.stringify(qsValues),
    });
  };

  return (
    <FormProvider {...(formMethods as any)}>
      <Form onSubmit={handleSubmit(onSubmit)} className={className}>
        {children}
      </Form>
    </FormProvider>
  );
};

Filter.Button = Button;
Filter.StateSelect = StateSelect;
Filter.RefurbishmentTypeSelect = RefurbishmentTypeSelect;
Filter.SparePartStatusSelect = SparePartStatusSelect;
Filter.LastTransitionDateSortSelect = LastTransitionDateSortSelect;
Filter.RefVinStockPlateInput = RefVinStockPlateInput;
Filter.VinStockPlateInput = VinStockPlateInput;
Filter.StateBadges = StateBadges;
Filter.RefurbishmentTypeBadges = RefurbishmentTypeBadges;
Filter.SparePartStatusBadges = SparePartStatusBadges;
Filter.RefVinStockInput = RefVinStockInput;
Filter.RefStockInput = RefStockInput;
Filter.ReworkOnlyCheck = ReworkOnlyCheck;
Filter.VinStockInput = VinStockInput;
Filter.EmailInput = EmailInput;
Filter.NameInput = NameInput;
Filter.LocationSelect = LocationSelect;

export default Filter;
