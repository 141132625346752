import { useContext } from 'react';

import useAuthorizedUser from 'modules/auth/useAuthorizedUser';
import DeveloperModeContext from 'modules/developerMode/context';
import { FeatureFlag, LocationFlag } from './flags';

type UseFeatureProps = {
  feature: FeatureFlag;
  location?: LocationFlag;
  locationId?: Number;
};

const useGetUserLocations = () => {
  const { user } = useAuthorizedUser();

  return user?.refurbishmentProvidersData?.map(
    provider => provider.location.id,
  );
};

const useFeature = ({ feature, location, locationId }: UseFeatureProps) => {
  const userLocations = useGetUserLocations();
  const { developerMode, flagsStatus } = useContext(DeveloperModeContext);

  const isFeatureEnabled = developerMode
    ? flagsStatus[feature]
    : process.env?.[feature] === 'YES';

  if (locationId && !location)
    throw new Error(`'location' is required if 'locationId' is used`);

  if (location === undefined) return isFeatureEnabled;

  if (!userLocations) return undefined;

  if (process.env?.[location] === 'ALL') return isFeatureEnabled;

  const isBranchEnabled = locationId
    ? process.env?.[location]
        ?.split(',')
        ?.some(branch => locationId === Number(branch)) || false
    : process.env?.[location]
        ?.split(',')
        ?.some(branch => userLocations.includes(Number(branch))) || false;

  return isFeatureEnabled && isBranchEnabled;
};

export default useFeature;
