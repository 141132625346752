import React from 'react';
import { components, PlaceholderProps } from 'react-select';
import SearchIcon from '@mui/icons-material/Search';

import cn from './Filter.module.scss';

const createPlaceholder = () => ({ children, ...rest }: PlaceholderProps) => {
  return (
    <components.Placeholder {...rest}>
      <SearchIcon className={cn.searchIcon} />
      {children}
    </components.Placeholder>
  );
};

export default createPlaceholder;
