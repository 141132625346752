import gql from 'graphql-tag';

import { VehicleServiceCostFragment } from './fragments';

export default gql`
  mutation updateVehicleServiceCost(
    $id: UUID!
    $vehicleServiceCostUpdateInput: VehicleServiceCostUpdateInput!
  ) {
    updateVehicleServiceCost(
      id: $id
      vehicleServiceCostUpdateInput: $vehicleServiceCostUpdateInput
    ) {
      ...VehicleServiceCostFragment
    }
  }
  ${VehicleServiceCostFragment}
`;
