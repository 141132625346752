import gql from 'graphql-tag';

export default gql`
  mutation createRefurbishmentPartnerTaskEta(
    $refurbishmentPartnerTaskEtaCreateInput: RefurbishmentPartnerTaskEtaCreateInput!
  ) {
    createRefurbishmentPartnerTaskEta(
      refurbishmentPartnerTaskEtaCreateInput: $refurbishmentPartnerTaskEtaCreateInput
    ) {
      taskId
      partnerWorkshopConnectionId
      eta
    }
  }
`;
