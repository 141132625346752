import gql from 'graphql-tag';

export default gql`
  query user($id: UUID!) {
    user(id: $id) {
      id
      email
      refurbishmentProvidersData {
        location {
          id
          name
        }
        partner {
          id
          name
        }
      }
      userPartnerWorkshopConnections {
        mainWorkshop {
          id
          name
          branchId
        }
        isAllLocations
        partnerWorkshopConnections {
          id
          repairWorkshop {
            id
            name
            branchId
          }
          repairPartner {
            id
            name
            refurbishmentPartnerId
          }
        }
      }
      groups {
        roles {
          name
        }
      }
    }
  }
`;
