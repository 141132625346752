import CommentEditInline from 'components/CommentEditInline';
import { InformMessage } from 'graphqlTypes';
import { useUpdateInformMessage } from 'modules/api/informMessage';

import cn from './MonitoringRow.module.scss';

type Props = {
  message: InformMessage;
};

const Comment = ({ message }: Props) => {
  const [updateInformMessage, { loading }] = useUpdateInformMessage();

  const { id, comment } = message;

  const handleChange = (value: string) => {
    updateInformMessage({
      id,
      input: {
        comment: value,
      },
    });
  };

  return (
    <CommentEditInline
      comment={comment || ''}
      loading={loading}
      onChange={handleChange}
      qaSelector="monitoring"
      className={cn.comment}
    />
  );
};

export default Comment;
