import {
  RefurbishmentTaskItemExcludingCost,
  RefurbishmentTaskItemExcludingCostsResponse,
  RefurbishmentTaskItemIncludingCost,
  RefurbishmentTaskItemIncludingCostsResponse,
  RefurbishmentTaskItemState,
  RefurbishmentTaskItemType,
} from 'graphqlTypes';
import {
  DamageArea,
  DamageSubArea,
  ProgressItem,
} from 'routes/RefurbishmentRoute/types';
import { Step } from './types';

type RefurbishmentTaskItem =
  | RefurbishmentTaskItemIncludingCost
  | RefurbishmentTaskItemExcludingCost;

const mapStep = (item: RefurbishmentTaskItem) => {
  if (item.itemType === RefurbishmentTaskItemType.Service)
    return Step.VehicleServices;

  if (
    [
      RefurbishmentTaskItemType.ServiceBookMainService,
      RefurbishmentTaskItemType.ServiceBookSubService,
    ].includes(item.itemType)
  ) {
    return Step.Services;
  }

  switch (item.damage?.area.key) {
    case DamageArea.Body: {
      switch (item.damage?.subArea?.key) {
        case DamageSubArea.BodyFront:
          return Step.Front;
        case DamageSubArea.BodyBack:
          return Step.Back;
        case DamageSubArea.BodyLeft:
        case DamageSubArea.FrontLeft:
        case DamageSubArea.BackLeft:
          return Step.Left;
        case DamageSubArea.BodyRight:
        case DamageSubArea.FrontRight:
        case DamageSubArea.BackRight:
          return Step.Right;
        case DamageSubArea.BodyRoof:
          return Step.Roof;
        default:
          return null;
      }
    }
    case DamageArea.Interior:
      return Step.Interior;
    case DamageArea.Underbody:
    case DamageArea.Engine:
      return Step.Underbody;
    case DamageArea.Tires:
      return Step.Wheels;
    default:
      return null;
  }
};

const isNotServiceBookMainService = (
  refurbishmentTaskItems: Array<RefurbishmentTaskItem>,
) =>
  refurbishmentTaskItems.filter(
    ({ itemType }) =>
      itemType !== RefurbishmentTaskItemType.ServiceBookMainService,
  );

const getSectionProgress = (
  section: Step,
  data: Array<RefurbishmentTaskItem>,
) => {
  const items = data.filter(
    (item: RefurbishmentTaskItem) => mapStep(item) === section,
  );

  const completed = isNotServiceBookMainService(items).filter(
    ({ state }: RefurbishmentTaskItem) =>
      state !== RefurbishmentTaskItemState.Open,
  ).length;

  const total = isNotServiceBookMainService(items).length;
  return { items, total, completed };
};

const getProgress = (items: Array<RefurbishmentTaskItem>) => {
  const progress = new Map<Step, ProgressItem>();

  progress.set(Step.Services, {
    name: __('entryCheck.progress.serviceBook'),
    ...getSectionProgress(Step.Services, items),
  });

  progress.set(Step.Front, {
    name: __('entryCheck.progress.front'),
    ...getSectionProgress(Step.Front, items),
  });

  progress.set(Step.Right, {
    name: __('entryCheck.progress.right'),
    ...getSectionProgress(Step.Right, items),
  });

  progress.set(Step.Back, {
    name: __('entryCheck.progress.back'),
    ...getSectionProgress(Step.Back, items),
  });

  progress.set(Step.Left, {
    name: __('entryCheck.progress.left'),
    ...getSectionProgress(Step.Left, items),
  });

  progress.set(Step.Roof, {
    name: __('entryCheck.progress.roof'),
    ...getSectionProgress(Step.Roof, items),
  });

  progress.set(Step.Interior, {
    name: __('entryCheck.progress.interior'),
    ...getSectionProgress(Step.Interior, items),
  });

  progress.set(Step.Underbody, {
    name: __('entryCheck.progress.underbody'),
    ...getSectionProgress(Step.Underbody, items),
  });

  progress.set(Step.Engine, {
    name: __('entryCheck.progress.engine'),
    ...getSectionProgress(Step.Engine, items),
  });

  progress.set(Step.Wheels, {
    name: __('entryCheck.progress.wheels'),
    ...getSectionProgress(Step.Wheels, items),
  });

  progress.set(Step.VehicleServices, {
    name: __('entryCheck.progress.services'),
    ...getSectionProgress(Step.VehicleServices, items),
  });

  return progress;
};

export const mapData = (
  refurbishmentTaskItemIncludingCosts:
    | RefurbishmentTaskItemIncludingCostsResponse
    | RefurbishmentTaskItemExcludingCostsResponse,
) => {
  const { items } = refurbishmentTaskItemIncludingCosts;
  const progress = getProgress(items);

  return { progress };
};
