import gql from 'graphql-tag';

import { DamageItemPayloadV2FragmentQCA } from './fragments';

export default gql`
  mutation createQualityCheckApprovalDamageItemV2(
    $damageItemCreateInput: DamageItemCreateInputV2!
  ) {
    createQualityCheckApprovalDamageItemV2(
      damageItemCreateInput: $damageItemCreateInput
    ) {
      id
      providerTotalCostsMinorUnits
      phaseName
      createdOn
      revisions {
        id
        propertyPath
        state
      }
      data {
        payloadType
        ...DamageItemPayloadV2FragmentQCA
        ... on DamageItemPayloadV2 {
          damageState: state
        }
      }
    }
  }
  ${DamageItemPayloadV2FragmentQCA}
`;
