/**  This function restores date from local timezone to its original value (after using new Date() constructor,
 * conversion to user local time happens under the hood) by reducing the date to UTC and adds GMT time to it.
 *
 * In order to extract GMT value, the date argument must be a proper date string.
 */
export const getOriginalDate = (date: string): Date => {
  const gmt = date?.match(/\+\d{2}/);
  const gmtHours = (gmt && gmt[0]?.slice(1)) || 0;
  const gmtMinutes = Number(gmtHours) * 60;

  const dateObject = new Date(date);
  dateObject.setMinutes(
    dateObject.getTimezoneOffset() + dateObject.getMinutes() + gmtMinutes,
  );

  return dateObject;
};
