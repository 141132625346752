import { Control, FieldValues, Path } from 'react-hook-form';
import { uniqBy } from 'lodash';

import SelectInput from 'components/Form/SelectInput';
import { useActiveGroupedPartnerWorkshopConnections } from 'modules/api/partnerWorkshop';

type Props<T extends FieldValues> = {
  name: Path<T>;
  form: string;
  control: Control<T, object>;
  qaSelector: string;
  mainWorkshopName: string;
};

const PartnerSelectControl = <T extends FieldValues>({
  name,
  form,
  control,
  qaSelector,
  mainWorkshopName,
}: Props<T>) => {
  const { data, loading } = useActiveGroupedPartnerWorkshopConnections({
    page: 0,
    pageSize: 999999,
    filter: {
      mainWorkshopName,
    },
  });

  const optionsWithDuplicates =
    data?.items?.[0].groupedPartnerWorkshopConnections
      .flat()
      ?.map(item => ({
        label: item.repairPartner.name,
        value: {
          name: item.repairPartner.name,
          refurbishmentPartnerId: item.repairPartner.refurbishmentPartnerId,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  const options = uniqBy(optionsWithDuplicates, 'value.refurbishmentPartnerId');

  return (
    <SelectInput
      name={name}
      form={form}
      control={control}
      options={options}
      isLoading={loading}
      rules={{
        required: __('field.required'),
      }}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
    />
  );
};

export default PartnerSelectControl;
