import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import ROLES from './gql/roles';

const useGetRoles = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'roles'>>(ROLES, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: get('roles', data),
    ...rest,
  };
};

export default useGetRoles;
