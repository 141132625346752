import gql from 'graphql-tag';

export default gql`
  mutation updateTaskItemState(
    $taskItemId: UUID!
    $refurbishmentTaskItemState: RefurbishmentTaskItemState!
  ) {
    updateTaskItemState(
      taskItemId: $taskItemId
      refurbishmentTaskItemState: $refurbishmentTaskItemState
    ) {
      status
      error {
        errorType
      }
    }
  }
`;
