import { Option } from 'types';

import cn from './BadgeItem.module.scss';

type Props<T> = {
  option: Option<T>;
  qaSelector: string;
  onRemove: (value: T) => void;
};

const BadgeItem = <T extends {}>({
  option,
  qaSelector,
  onRemove,
}: Props<T>) => {
  return (
    <div
      className={cn.activeFilter}
      data-qa-selector={qaSelector}
      data-qa-value={option.value}
      onClick={() => onRemove(option.value)}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onRemove(option.value);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className={cn.label} data-qa-selector="label">
        {option.label}
      </div>
      <div className={cn.remove} data-qa-selector="removeButton">
        ×
      </div>
    </div>
  );
};

export default BadgeItem;
