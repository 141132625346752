import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

import { isValidNumber } from 'helpers/regexps';
import { getDecimalSeparator } from 'modules/i18n';

type DecimalProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange'
> & {
  value?: number | string;
  digits?: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const formatNumber = (value: string, digits: number) => {
  const decimalSeparator = getDecimalSeparator();
  const splitedValue = value.split(/[.,]/);
  if (splitedValue.length >= 2) {
    return `${splitedValue[0]}${decimalSeparator}${splitedValue[1].substring(
      0,
      digits,
    )}`;
  }
  return value;
};

const numberPattern = /^[\d]*[.,]?[\d]*$/;

const Decimal = ({
  value,
  digits = 2,
  onChange,
  onBlur,
  ...rest
}: DecimalProps) => {
  const currentValue = value?.toString() || '';
  const formattedValue = numberPattern.test(currentValue)
    ? formatNumber(currentValue || '', digits)
    : '';

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();
    if (numberPattern.test(inputValue)) {
      onChange({
        ...event,
        target: {
          ...event.target,
          value: inputValue,
        },
      });
    }
  };

  return (
    <input
      {...rest}
      type="text"
      inputMode="decimal"
      value={formattedValue}
      onChange={handleOnChange}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
    />
  );
};

type Props<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render' | 'control'
> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'value'> & {
    value?: number | string;
    digits?: number;
    control?: any;
  };

const DecimalInput = <T extends FieldValues>({
  name,
  onBlur,
  digits = 2,
  control,
  rules,
  ...rest
}: Props<T>) => {
  const handleOnChange = (onChange: any) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const formattedValue = formatNumber(event.target.value, digits);
    if (onChange) onChange(formattedValue.replace(',', '.'));
    return formattedValue;
  };

  const validationRules = {
    pattern: {
      value: isValidNumber,
      message: __('forms.invalidValue'),
    },
    ...rules,
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      render={({ field: { value, onChange } }) => (
        <Decimal
          digits={digits}
          value={value}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange(onChange)}
          {...rest}
        />
      )}
    />
  );
};
export default DecimalInput;
