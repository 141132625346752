import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaUserSlash } from 'react-icons/fa';

import { User } from 'graphqlTypes';
import { useAssignUsers } from 'modules/api/groups';
import { FormattedMessage } from 'modules/i18n';

interface Props {
  className: string;
  id: string;
  member: User;
  members: User[];
}

const RemoveMember = ({ id, member, members, className }: Props) => {
  const [show, setShow] = useState(false);
  const [assignUsers] = useAssignUsers();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    const membersIds = members
      .filter(user => user.id !== member.id)
      .map(item => item.id);

    assignUsers({ groupId: id, userIds: membersIds });
    handleClose();
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        className={`d-flex align-items-center ${className}`}
      >
        <FaUserSlash />{' '}
        <strong className="pl-2">
          {__('group.detail.removeMember.button')}
        </strong>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__('group.detail.removeMember.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            text={__('group.detail.removeMember.message')}
            values={{ name: `${member.firstName} ${member.lastName}` }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {__('action.cancel')}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {__('action.remove')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveMember;
