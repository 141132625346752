import { flow, map, toPairs } from 'lodash/fp';

type CreateOptions = (
  args: Record<string, string>,
) => Array<{ label: string; value: string }>;

const createOptions: CreateOptions = flow(
  toPairs,
  map(([value, label]) => ({
    label,
    value,
  })),
);

export default createOptions;
