import gql from 'graphql-tag';

import { TransitionFragment } from './fragments';

export default gql`
  mutation updateRefurbishmentState($input: RefurbishmentStateChangeInput!) {
    updateRefurbishmentState(input: $input) {
      refurbishmentDetails {
        id
        state
        transitionCurrentSet {
          ...TransitionFragment
        }
        lastStateUpdatedOn
      }
    }
  }
  ${TransitionFragment}
`;
