import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import LoadingIndicator from 'components/LoadingIndicator';
import CustomModal from 'components/UI/CustomModal';
import { ImportTaskState } from 'graphqlTypes';
import useGetRepairMethodImportTask from 'modules/api/costTable/useGetRepairMethodImportTask';
import ImportRepairMethodImportResult from './ImportRepairMethodImportResult';
import ImportRepairMethodProceed from './ImportRepairMethodProceed';
import ImportRepairMethodReUpload from './ImportRepairMethodReUpload';
import ImportRepairMethodUpload from './ImportRepairMethodUpload';

export const enum ImportRepairMethodPhase {
  'UPLOADING' = 'uploading',
  'VALIDATION_LOADING' = 'validationLoading',
  'RE_UPLOADING' = 'reUploading',
  'PROCEEDING_WITH_IMPORT' = 'proceedingWithImport',
  'IMPORT_LOADING' = 'importLoading',
  'DISPLAYING_IMPORT_RESULT' = 'displayingImportResult',
}

type Props = {
  isModalVisible: boolean;
  closeModal: () => void;
};

const ImportRepairMethodModal = ({ isModalVisible, closeModal }: Props) => {
  const [activePhase, setActivePhase] = useState<ImportRepairMethodPhase>(
    ImportRepairMethodPhase.UPLOADING,
  );

  const [
    getRepairMethodImportTask,
    { repairMethodImportTaskData, stopPolling },
  ] = useGetRepairMethodImportTask();

  useEffect(() => {
    const currentImportState = repairMethodImportTaskData?.state;

    if (activePhase === ImportRepairMethodPhase.VALIDATION_LOADING) {
      if (currentImportState === ImportTaskState.ValidationFinished) {
        stopPolling();
        setActivePhase(ImportRepairMethodPhase.PROCEEDING_WITH_IMPORT);
      } else if (currentImportState === ImportTaskState.ValidationFailed) {
        stopPolling();
        setActivePhase(ImportRepairMethodPhase.RE_UPLOADING);
      }
    }

    if (activePhase === ImportRepairMethodPhase.IMPORT_LOADING) {
      if (
        currentImportState === ImportTaskState.ImportFinished ||
        currentImportState === ImportTaskState.ImportFailed
      ) {
        stopPolling();
        setActivePhase(ImportRepairMethodPhase.DISPLAYING_IMPORT_RESULT);
      }
    }
  }, [repairMethodImportTaskData]);

  const renderContent = () => {
    switch (activePhase) {
      case ImportRepairMethodPhase.VALIDATION_LOADING:
      case ImportRepairMethodPhase.IMPORT_LOADING:
        return (
          <>
            <h3>{__('repairMethod.importInProgress')}</h3>
            <LoadingIndicator />
          </>
        );
      case ImportRepairMethodPhase.RE_UPLOADING:
        return (
          <ImportRepairMethodReUpload
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            repairMethodImportTaskData={repairMethodImportTaskData}
          />
        );
      case ImportRepairMethodPhase.PROCEEDING_WITH_IMPORT:
        return (
          <ImportRepairMethodProceed
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            repairMethodImportTaskData={repairMethodImportTaskData}
            getRepairMethodImportTask={getRepairMethodImportTask}
          />
        );
      case ImportRepairMethodPhase.DISPLAYING_IMPORT_RESULT:
        return (
          <ImportRepairMethodImportResult
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            currentImportState={repairMethodImportTaskData?.state}
          />
        );
      default:
        return (
          <ImportRepairMethodUpload
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            getRepairMethodImportTask={getRepairMethodImportTask}
            repairMethodImportTaskData={repairMethodImportTaskData}
            stopPolling={stopPolling}
          />
        );
    }
  };

  return (
    <CustomModal
      show={isModalVisible}
      onHide={() => {
        if (stopPolling) {
          stopPolling();
        }
        setActivePhase(ImportRepairMethodPhase.UPLOADING);
        closeModal();
      }}
      qaSelector="repairMethodImportRepairMethodModal"
    >
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          {__('repairMethod.importRepairMethodModalTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
    </CustomModal>
  );
};

export default ImportRepairMethodModal;
