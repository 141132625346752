import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import cns from 'classnames';

import {
  ActiveOnlyFilter,
  CountryFilter,
  DamageAreaFilter,
  DamagePartFilter,
  DamageTypeFilter,
  RepairMethodFilter,
} from 'components/CostTableFilter';
import { Location, Workshop } from 'graphqlTypes';
import MainWorkshopFilter from './MainWorkshopFilter';
import { FormFilterNames } from '../../FormFilterNames';

import cn from './RepairMethodSearch.module.scss';

type FormData = {
  countries: Array<string>;
  mainWorkshops: Array<Workshop> | null;
  damageArea: string;
  damageType: string;
  damagePart: string;
  repairMethodBelowThreshold: string;
  repairMethodEqualOrAboveThreshold: string;
  activeOnly: boolean;
};

type Props = {
  allLocations: Array<Location>;
};

const RepairMethodSearch = ({ allLocations }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const countryCodes = searchParams.get(FormFilterNames.COUNTRIES) || '';
  const mainWorkshopIds = (
    searchParams.get(FormFilterNames.MAIN_WORKSHOPS) || ''
  )
    .split(',')
    .map(s => parseInt(s, 10));
  const damageArea = searchParams.get(FormFilterNames.AREA) || '';
  const damageType = searchParams.get(FormFilterNames.DAMAGE_TYPE) || '';
  const damagePart = searchParams.get(FormFilterNames.PART) || '';
  const repairMethodBelowThreshold =
    searchParams.get(FormFilterNames.REPAIR_METHOD_LT) || '';
  const repairMethodEqualOrAboveThreshold =
    searchParams.get(FormFilterNames.REPAIR_METHOD_GTE) || '';
  const countries = countryCodes.split(',');
  const mainWorkshops =
    allLocations
      .filter(location => mainWorkshopIds.indexOf(location.id) >= 0)
      .map(location => {
        return {
          branchId: location.id,
          name: location.name,
        };
      }) || null;
  const activeOnly = searchParams.get(FormFilterNames.ACTIVE_ONLY) === 'true';

  const { handleSubmit, control, watch, register } = useForm<FormData>({
    defaultValues: {
      countries,
      mainWorkshops,
      damageArea,
      damageType,
      damagePart,
      repairMethodBelowThreshold,
      repairMethodEqualOrAboveThreshold,
      activeOnly,
    },
  });

  const selectedCountries = watch('countries');

  const handleOnSubmit = (values: FormData) => {
    const currentSearchParams = searchParams.toString();

    if (values.countries && values.countries.length) {
      searchParams.set(FormFilterNames.COUNTRIES, values.countries.toString());
    } else {
      searchParams.delete(FormFilterNames.COUNTRIES);
    }

    if (values.mainWorkshops && values.mainWorkshops.length) {
      searchParams.set(
        FormFilterNames.MAIN_WORKSHOPS,
        values.mainWorkshops.map(workshop => workshop.branchId).toString(),
      );
    } else {
      searchParams.delete(FormFilterNames.MAIN_WORKSHOPS);
    }

    if (values.damageArea) {
      searchParams.set(FormFilterNames.AREA, values.damageArea);
    } else {
      searchParams.delete(FormFilterNames.AREA);
    }

    if (values.damageType) {
      searchParams.set(FormFilterNames.DAMAGE_TYPE, values.damageType);
    } else {
      searchParams.delete(FormFilterNames.DAMAGE_TYPE);
    }

    if (values.damagePart) {
      searchParams.set(FormFilterNames.PART, values.damagePart);
    } else {
      searchParams.delete(FormFilterNames.PART);
    }

    if (values.repairMethodBelowThreshold) {
      searchParams.set(
        FormFilterNames.REPAIR_METHOD_LT,
        values.repairMethodBelowThreshold,
      );
    } else {
      searchParams.delete(FormFilterNames.REPAIR_METHOD_LT);
    }

    if (values.repairMethodEqualOrAboveThreshold) {
      searchParams.set(
        FormFilterNames.REPAIR_METHOD_GTE,
        values.repairMethodEqualOrAboveThreshold,
      );
    } else {
      searchParams.delete(FormFilterNames.REPAIR_METHOD_GTE);
    }
    if (values.activeOnly) {
      searchParams.set(FormFilterNames.ACTIVE_ONLY, String(values.activeOnly));
    } else {
      searchParams.delete(FormFilterNames.ACTIVE_ONLY);
    }

    if (currentSearchParams !== searchParams.toString()) {
      searchParams.delete('p');
    }

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={cn.searchInputContainer}
    >
      <div className={cn.row}>
        <Col sm={11} className={cns('d-flex', 'flex-wrap', cn.filtersWrapper)}>
          <div className={cn.countryFilter}>
            <CountryFilter
              name="countries"
              qaSelector="countryFilter"
              placeholder={__('repairMethod.country')}
              control={control}
            />
          </div>
          <div className={cn.workshopFilter}>
            <MainWorkshopFilter
              name="mainWorkshops"
              qaSelector="mainWorkshopFilter"
              control={control}
              selectedCountries={selectedCountries}
            />
          </div>
          <div className={cn.searchField}>
            <DamageAreaFilter
              name="damageArea"
              qaSelector="damageAreaFilter"
              placeholder={__('repairMethod.area')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <DamageTypeFilter
              name="damageType"
              qaSelector="damageTypeFilter"
              placeholder={__('repairMethod.damageType')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <DamagePartFilter
              name="damagePart"
              qaSelector="damagePartFilter"
              placeholder={__('repairMethod.part')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <RepairMethodFilter
              name="repairMethodBelowThreshold"
              qaSelector="repairMethodBelowThresholdFilter"
              placeholder={__('repairMethod.repairMethodBelowThreshold')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <RepairMethodFilter
              name="repairMethodEqualOrAboveThreshold"
              qaSelector="repairMethodEqualOrAboveThresholdFilter"
              placeholder={__('repairMethod.repairMethodEqualOrAboveThreshold')}
              control={control}
            />
          </div>
          <div className={`${cn.searchField} d-flex align-items-center`}>
            <ActiveOnlyFilter register={register} />
          </div>
        </Col>

        <Col sm={1}>
          <Button
            variant="primary"
            type="submit"
            data-qa-selector="searchButton"
          >
            {__('action.search')}
          </Button>
        </Col>
      </div>
    </form>
  );
};

export default RepairMethodSearch;
