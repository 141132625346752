import React from 'react';

import { formatPrice } from 'modules/currency';

type Props = {
  costMinorUnits: number;
  currencyCode: string;
  qaSelector: string;
};

const CostTableCost = ({ costMinorUnits, currencyCode, qaSelector }: Props) => {
  return (
    <span data-qa-selector={qaSelector}>
      {formatPrice(costMinorUnits, currencyCode)}
    </span>
  );
};

export default CostTableCost;
