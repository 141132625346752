import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from './fragments';

export default gql`
  query activeGroupedPartnerWorkshopConnections(
    $page: Int!
    $pageSize: Int!
    $filter: PartnerWorkshopConnectionFilter
  ) {
    activeGroupedPartnerWorkshopConnections(
      page: $page
      pageSize: $pageSize
      filter: $filter
    ) {
      items {
        groupedPartnerWorkshopConnections {
          ...PartnerWorkshopConnectionFragment
        }
      }
      pageInfo {
        page
        pageSize
        totalEntityCount
        totalPageCount
      }
    }
  }
  ${PartnerWorkshopConnectionFragment}
`;
