import { useQuery } from '@apollo/react-hooks';

import { Query, QueryProviderTotalCostsAuthorizationArgs } from 'graphqlTypes';
import PROVIDER_TOTAL_COSTS_AUTHORIZATION from './gql/providerTotalCostsAuthorization';

const useGetProviderTotalCostsForAuthorization = (entryCheckId: string) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'providerTotalCostsAuthorization'>,
    QueryProviderTotalCostsAuthorizationArgs
  >(PROVIDER_TOTAL_COSTS_AUTHORIZATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      entryCheckId,
    },
  });

  return {
    data: data?.providerTotalCostsAuthorization,
    ...rest,
  };
};

export default useGetProviderTotalCostsForAuthorization;
