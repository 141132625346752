import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import { ACL, Role } from 'modules/acl';
import { useActiveGroupedPartnerWorkshopConnections } from 'modules/api/partnerWorkshop';
import DeletePartnerWorkshop from './DeletePartnerWorkshop';
import EditPartnerWorkshop from './EditPartnerWorkshop';
import mapData from './mapData';
import PartnerWorkshopTablePagination from './PartnerWorkshopTablePagination';
import { PAGE_SIZE } from '../../constants';
import AddConnection from '../AddConnection';

const PartnerWorkshopTable = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const page = Math.max(Number(searchParams.get('p')) - 1 || 0, 0);
  const workshopOrPartnerName = searchParams.get('f') || '';

  const partnerWorkshopConnectionArgs = {
    page,
    pageSize: PAGE_SIZE,
    ...(workshopOrPartnerName
      ? {
          filter: {
            workshopOrPartnerName,
          },
        }
      : {}),
  };
  const { data, loading } = useActiveGroupedPartnerWorkshopConnections(
    partnerWorkshopConnectionArgs,
  );

  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  const items = mapData(data.items);

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>{__('partnerWorkshopConnections.mainWorkshop')}</th>
            <th>{__('partnerWorkshopConnections.repairWorkshop')}</th>
            <th>{__('partnerWorkshopConnections.partners')}</th>
            <th>{__('partnerWorkshopConnections.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            const { mainWorkshop, repairWorkshop, repairPartners } = item;
            const partners = repairPartners
              .map(partner => partner.name)
              .join(', ');
            const key = `${mainWorkshop.name}-${repairWorkshop.name}-${partners}`;

            return (
              <tr key={key} data-qa-selector={`row-${key}`}>
                <td data-qa-selector="mainWorkshop">{mainWorkshop.name}</td>
                <td data-qa-selector="repairWorkshop">{repairWorkshop.name}</td>
                <td data-qa-selector="repairPartners">{partners}</td>
                <td data-qa-selector="actions">
                  <ACL roles={Role.PARTNER_LOCATION_CONNECTIONS_WRITE}>
                    <div className="d-flex">
                      <EditPartnerWorkshop
                        item={item}
                        partnerWorkshopConnectionArgs={
                          partnerWorkshopConnectionArgs
                        }
                      />
                      <DeletePartnerWorkshop
                        item={item}
                        partnerWorkshopConnectionArgs={
                          partnerWorkshopConnectionArgs
                        }
                      />
                    </div>
                  </ACL>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <PartnerWorkshopTablePagination
          totalPageCount={data.pageInfo.totalPageCount}
        />
      </div>
      <ACL roles={Role.PARTNER_LOCATION_CONNECTIONS_WRITE}>
        <AddConnection
          partnerWorkshopConnectionArgs={partnerWorkshopConnectionArgs}
        />
      </ACL>
    </>
  );
};

export default PartnerWorkshopTable;
