import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

import Select, { SelectProps } from '../Select';

export type SelectInputProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render' | 'onChange'
> &
  Omit<SelectProps, 'value' | 'onChange'> & {
    onChange?: (value: any) => void;
  };

const SelectInput = <T extends FieldValues>({
  name,
  control,
  rules,
  options,
  onChange,
  dataQa,
  dataQaOption,
  isLoading = false,
  ...rest
}: SelectInputProps<T>) => {
  const isValueObject = !!options.find(
    ({ value }) => value !== null && typeof value === 'object',
  );

  const handleOnChange = (onChangeField: (...event: any[]) => void) => (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = isValueObject
      ? options[Number(event.target.value)].value
      : event.target.value;

    if (onChange) onChange(value);
    onChangeField(value);

    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange: onChangeField, ...field } }) => (
        <Select
          options={options}
          isValueObject={isValueObject}
          dataQa={dataQa}
          dataQaOption={dataQaOption}
          isLoading={isLoading}
          {...rest}
          {...field}
          onChange={handleOnChange(onChangeField)}
        />
      )}
    />
  );
};

export default SelectInput;
