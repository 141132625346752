import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { get } from 'lodash/fp';

import {
  EntryCheckItemValueUpdateEntryInput,
  Mutation,
  MutationUpdateEntryCheckItemValuesArgs,
} from 'graphqlTypes';
import UPDATE_ENTRY_CHECK_ITEM_VALUES from './gql/updateEntryCheckItemValues';

export type UpdateEntryCheckItemValuesPromise = Promise<
  ExecutionResult<Pick<Mutation, 'updateEntryCheckItemValues'>>
>;

const useUpdateEntryCheckItemValues = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateEntryCheckItemValues'>,
    MutationUpdateEntryCheckItemValuesArgs
  >(UPDATE_ENTRY_CHECK_ITEM_VALUES);

  const updateEntryCheckItemValue = (
    inputs: EntryCheckItemValueUpdateEntryInput[],
  ) =>
    mutation({
      variables: { inputs },
    });

  return [
    updateEntryCheckItemValue,
    {
      data: get('updateEntryCheckItemValue', data),
      ...rest,
    },
  ] as const;
};

export default useUpdateEntryCheckItemValues;
