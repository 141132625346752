import React from 'react';
import cns from 'classnames';

import cn from './Badge.module.scss';

export type BadgeColor =
  | 'success'
  | 'danger'
  | 'warning'
  | 'neutral'
  | 'info'
  | 'highlight'
  | 'dark';

export type BadgeProps = {
  children: React.ReactNode;
  color?: BadgeColor;
  size?: 's' | 'm';
  weight?: 'light' | 'normal' | 'bold';
  className?: string;
  qaSelector?: string | null;
  qaSelectorState?: string | null;
};

const Badge = ({
  children,
  color = 'neutral',
  size = 's',
  weight = 'bold',
  className,
  qaSelector,
  qaSelectorState,
}: BadgeProps) => {
  return (
    <div
      className={cns(cn.badge, cn[color], cn[size], cn[weight], className)}
      data-qa-selector={qaSelector}
      data-qa-selector-state={qaSelectorState}
    >
      {children}
    </div>
  );
};

export default Badge;
