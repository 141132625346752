import { Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import cns from 'classnames';

import { ActiveOnlyFilter, CountryFilter } from 'components/CostTableFilter';
import PartnerFilter from 'components/CostTableFilter/PartnerFilter';
import { Partner } from 'graphqlTypes';
import { useGetAllPartners } from 'modules/api/partners';
import { FormFilterNames } from '../../FormFilterNames';

import cn from './PartnerSearch.module.scss';

type FormData = {
  partner: Partner | null;
  country: string;
  activeOnly: boolean;
};

const PartnerSearch = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const repairPartnerId = parseInt(
    searchParams.get(FormFilterNames.PARTNER_NAME) || '0',
    10,
  );

  const { data: allPartners } = useGetAllPartners();

  const partner = allPartners?.find(
    partnerObj => partnerObj.id === repairPartnerId,
  );

  const country = searchParams.get(FormFilterNames.COUNTRY) || '';

  const activeOnly = searchParams.get(FormFilterNames.ACTIVE_ONLY) === 'true';

  const { handleSubmit, control, register } = useForm<FormData>({
    defaultValues: {
      partner: partner || null,
      country,
      activeOnly,
    },
  });

  const handleOnSubmit = (values: FormData) => {
    if (values.country && values.country.length) {
      searchParams.set(FormFilterNames.COUNTRY, values.country.toString());
    } else {
      searchParams.delete(FormFilterNames.COUNTRY);

      if (values.partner) {
        searchParams.set(FormFilterNames.PARTNER_NAME, values.partner.name);
      } else {
        searchParams.delete(FormFilterNames.PARTNER_NAME);
      }
    }

    if (values.activeOnly) {
      searchParams.set(FormFilterNames.ACTIVE_ONLY, String(values.activeOnly));
    } else {
      searchParams.delete(FormFilterNames.ACTIVE_ONLY);
    }

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={cn.searchInputContainer}
    >
      <div className={cn.row}>
        <Col sm={11} className={cns('d-flex', 'flex-wrap', cn.filtersWrapper)}>
          <div className={cn.workshopFilter}>
            <PartnerFilter
              name="partner"
              qaSelector="partnerFilter"
              placeholder={__('partners.partnerName')}
              control={control}
            />
          </div>
          <div className={cn.countryFilter}>
            <CountryFilter
              name="country"
              qaSelector="countryFilter"
              placeholder={__('partners.country')}
              isMulti={false}
              control={control}
            />
          </div>

          <div className={`${cn.searchField} d-flex align-items-center`}>
            <ActiveOnlyFilter register={register} />
          </div>
        </Col>

        <Col sm={1}>
          <Button
            variant="primary"
            type="submit"
            data-qa-selector="searchButton"
          >
            {__('action.search')}
          </Button>
        </Col>
      </div>
    </form>
  );
};

export default PartnerSearch;
