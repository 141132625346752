import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import PartnerWorkshopSearch from './components/PartnerWorkshopSearch';
import PartnerWorkshopTable from './components/PartnerWorkshopTable';

const PartnerWorkshopConnectionsRoute = () => {
  return (
    <PageContainer>
      <PageHeader
        title={__('partnerWorkshopConnections.title')}
        subTitle={__('partnerWorkshopConnections.subTitle')}
      />
      <PartnerWorkshopSearch />
      <PartnerWorkshopTable />
    </PageContainer>
  );
};

export default PartnerWorkshopConnectionsRoute;
