import gql from 'graphql-tag';

export default gql`
  query tireWidths {
    tireWidths {
      width
      unit
    }
  }
`;
