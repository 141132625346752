import gql from 'graphql-tag';

export default gql`
  query refurbishmentPartnerWorkshopConnections($refurbishmentId: UUID!) {
    refurbishmentPartnerWorkshopConnections(refurbishmentId: $refurbishmentId) {
      id
      repairWorkshop {
        id
        name
      }
      repairPartner {
        id
        name
      }
    }
  }
`;
