import React from 'react';
import { components, OptionProps } from 'react-select';

export type CreateReactSelectOptionParams = {
  dataQaOption: string;
  dataQaOptionHandler?: (value: any) => string;
  renderOption?: (
    children: React.ReactNode,
    optionData: {
      value: any;
    },
  ) => React.ReactNode;
};

const createReactSelectOption = ({
  dataQaOption,
  dataQaOptionHandler,
  renderOption,
}: CreateReactSelectOptionParams) => (props: OptionProps<any>) => {
  const {
    data: { value },
    children,
  } = props;

  let v = value;

  if (dataQaOptionHandler) {
    v = dataQaOptionHandler(value);
  } else if (v instanceof Object && v.name) {
    v = v.name;
  }

  return (
    <div data-qa-selector={`${dataQaOption}_${v}`}>
      <components.Option {...props}>
        {renderOption ? renderOption(children, { value }) : children}
      </components.Option>
    </div>
  );
};

export default createReactSelectOption;
