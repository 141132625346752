import { useMutation } from '@apollo/react-hooks';
import { isNumber } from 'lodash/fp';

import {
  ItemType,
  Mutation,
  MutationCreateQualityCheckApprovalServiceItemArgs,
  Query,
  QueryQualityCheckApprovalArgs,
  ServiceItemCreateInput,
} from 'graphqlTypes';
import { updateProviderTotalCost } from './common/updateProviderTotalCost';
import CREATE_QUALITY_CHECK_APPROVAL_SERVICE_ITEM from './gql/createQualityCheckApprovalServiceItem';
import QUALITY_CHECK_APPROVAL from './gql/qualityCheckApproval';

const useCreateQualityCheckApprovalServiceItem = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'createQualityCheckApprovalServiceItem'>,
    MutationCreateQualityCheckApprovalServiceItemArgs
  >(CREATE_QUALITY_CHECK_APPROVAL_SERVICE_ITEM);

  const createQualityCheckApprovalServiceItem = (
    serviceItemCreateInput: ServiceItemCreateInput,
  ) => {
    return mutation({
      variables: {
        serviceItemCreateInput,
      },
      update(cache, { data }) {
        // update cost
        const providerTotalCostMinorUnits =
          data?.createQualityCheckApprovalServiceItem
            .providerTotalCostsMinorUnits;

        if (isNumber(providerTotalCostMinorUnits)) {
          updateProviderTotalCost(reviewId, providerTotalCostMinorUnits, cache);
        }

        // update items
        const newItem = data?.createQualityCheckApprovalServiceItem;
        if (newItem) {
          const cachedQuery = cache.readQuery<
            Pick<Query, 'qualityCheckApproval'>,
            QueryQualityCheckApprovalArgs
          >({
            query: QUALITY_CHECK_APPROVAL,
            variables: {
              id: reviewId,
            },
          });

          if (cachedQuery) {
            cache.writeQuery({
              query: QUALITY_CHECK_APPROVAL,
              data: {
                qualityCheckApproval: {
                  ...cachedQuery.qualityCheckApproval,
                  items: [
                    ...(cachedQuery.qualityCheckApproval.items || []),
                    {
                      __typename: 'ItemGroup',
                      itemId: newItem.attachedTo,
                      itemType: ItemType.Service,
                      values: [{ ...newItem }],
                    },
                  ],
                },
              },
              variables: {
                id: reviewId,
              },
            });
          }
        }
      },
    });
  };

  return [createQualityCheckApprovalServiceItem, options] as const;
};

export default useCreateQualityCheckApprovalServiceItem;
