import gql from 'graphql-tag';

export default gql`
  query refurbishmentDetails($id: UUID!) {
    refurbishmentDetails(id: $id) {
      id
      transitionCurrentSet {
        id
        stateTo
        transitionDate
      }
    }
  }
`;
