import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationUpdateRefurbishmentArgs } from 'graphqlTypes';
import UPDATE_REFURBISHMENT_DEFAULT_CONNECTION from './gql/updateRefurbishmentDefaultConnection';

const useUpdateRefurbishmentDefaultConnection = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateRefurbishment'>,
    MutationUpdateRefurbishmentArgs
  >(UPDATE_REFURBISHMENT_DEFAULT_CONNECTION);

  const updateRefurbishment = (refurbishmentId: string, connectionId: string) =>
    mutation({
      variables: {
        id: refurbishmentId,
        input: {
          defaultPartnerWorkshopConnectionId: connectionId,
        },
      },
    });

  return [
    updateRefurbishment,
    {
      data: data?.updateRefurbishment,
      ...rest,
    },
  ] as const;
};

export default useUpdateRefurbishmentDefaultConnection;
