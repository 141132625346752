import { MutationFunctionOptions } from '@apollo/react-common';
import { useMutation } from '@apollo/react-hooks';
import { PureQueryOptions } from 'apollo-client';

import { Mutation, MutationUpdateCommentArgs } from 'graphqlTypes';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';
import UPDATE_COMMENT from './gql/updateComment';

type UpdateCommentMutation = Pick<Mutation, 'updateComment'>;

type UpdateCommentArgs = MutationFunctionOptions<
  UpdateCommentMutation,
  MutationUpdateCommentArgs
> & { refId: string };

const useUpdateComment = () => {
  const [mutation, options] = useMutation(UPDATE_COMMENT);

  const updateComment = async ({ refId, ...rest }: UpdateCommentArgs) => {
    const refetchQueries: (string | PureQueryOptions)[] =
      rest.refetchQueries && !(rest.refetchQueries instanceof Function)
        ? rest.refetchQueries
        : [];

    await mutation({
      ...rest,
      refetchQueries: refetchQueries.concat([
        {
          query: REFURBISHMENT_DETAILS,
          variables: {
            id: refId,
          },
        },
      ]),
    });
  };

  return [updateComment, options] as const;
};

export default useUpdateComment;
