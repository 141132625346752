import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import IconClear from '@mui/icons-material/Clear';
import IconDone from '@mui/icons-material/Done';

import CustomModal from 'components/UI/CustomModal';
import IconButton from 'components/UI/IconButton';
import Text from 'components/UI/Text';
import { RefurbishmentTaskItemState } from 'graphqlTypes';
import {
  useUpdateTaskItemState,
  useUpdateTaskItemStateAndRefurbishmentState,
} from 'modules/api/refurbishmentTasks';
import { useLogger } from 'modules/logger';

interface Props {
  taskItemId: string;
  state: RefurbishmentTaskItemState | null;
  disabled: boolean;
  onLastItemSubmitted: () => void;
}
const TaskItemChecker = ({
  onLastItemSubmitted,
  disabled,
  state,
  taskItemId,
}: Props) => {
  const { logError } = useLogger();
  const [updateTaskItemState, { loading }] = useUpdateTaskItemState();
  const [
    updateTaskItemStateAndRefurbishmentState,
  ] = useUpdateTaskItemStateAndRefurbishmentState();
  const loadingButton = useRef<RefurbishmentTaskItemState | null>();
  const [showModal, setShowModal] = useState(false);
  const [nextState, setNextState] = useState<RefurbishmentTaskItemState | null>(
    null,
  );

  const isDone = state === RefurbishmentTaskItemState.Done;
  const isDecline = state === RefurbishmentTaskItemState.Declined;

  const onClick = async (newState: RefurbishmentTaskItemState) => {
    loadingButton.current = newState;
    try {
      const { data } = await updateTaskItemState(taskItemId, newState);

      if (
        data?.updateTaskItemState?.status === 'FAILED' &&
        data.updateTaskItemState.error?.errorType === 'LAST_TASK_ITEM'
      ) {
        setNextState(newState);
        setShowModal(true);
      }
    } catch (error) {
      logError({ error });
    }
  };

  const onApprove = async () => {
    if (!nextState) return;
    try {
      await updateTaskItemStateAndRefurbishmentState(taskItemId, nextState);
      setShowModal(false);
      onLastItemSubmitted();
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <div>
      <IconButton
        icon={IconClear}
        selected={isDecline}
        loading={
          loading &&
          loadingButton.current === RefurbishmentTaskItemState.Declined
        }
        disabled={disabled || loading}
        className="ml-4"
        onClick={() => onClick(RefurbishmentTaskItemState.Declined)}
        data-qa-selector="buttonDecline"
        readOnly={disabled}
      />

      <IconButton
        icon={IconDone}
        selected={isDone}
        loading={
          loading && loadingButton.current === RefurbishmentTaskItemState.Done
        }
        disabled={disabled || loading}
        onClick={() => onClick(RefurbishmentTaskItemState.Done)}
        data-qa-selector="buttonApprove"
        readOnly={disabled}
      />

      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        qaSelector="taskItemChecker-modal"
      >
        <Modal.Body>
          <Text tag="p">
            {__('refurbishmentTask.modal.completeWholeRefurbishment')}
          </Text>
          <Text tag="p">
            {__('refurbishmentTask.modal.completeWholeRefurbishmentWarning')}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => setShowModal(false)}
            data-qa-selector="taskItemCheckerModalNo"
          >
            {__('no')}
          </Button>
          <Button
            variant="secondary"
            onClick={onApprove}
            data-qa-selector="taskItemCheckerModalYes"
          >
            {__('yes')}
          </Button>
        </Modal.Footer>
      </CustomModal>
    </div>
  );
};

export default TaskItemChecker;
