import React from 'react';
import { Container } from 'react-bootstrap';

import cn from './PageContainer.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  fluid?: boolean | 'sm' | 'md' | 'lg' | 'xl';
}

const PageContainer = ({ children, className, fluid }: Props) => {
  return (
    <Container
      fluid={fluid}
      className={[cn.pageContainer, cn.content, className].join(' ')}
    >
      {children}
    </Container>
  );
};

export default PageContainer;
