import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import cns from 'classnames';

import arrowDown from 'images/arrow-down.svg';
import arrowUp from 'images/arrow-up.svg';

import cn from './CollapsableText.module.scss';

type Props = {
  children: string | React.ReactElement;
  lines?: number;
  qaSelector?: string;
  className?: string;
  classNameArrow?: string;
};

const CollapsableText = ({
  children,
  lines = 4,
  qaSelector,
  className,
  classNameArrow,
}: Props) => {
  const textRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const toggleImg = open ? arrowDown : arrowUp;

  useEffect(() => {
    if (!textRef.current) return;

    const initialHeight = textRef.current.clientHeight;

    const checkTextOverflow = () => {
      if (!textRef.current || !buttonRef.current) return;
      if (
        textRef.current.clientHeight === textRef.current.scrollHeight &&
        textRef.current.scrollHeight <= initialHeight
      ) {
        buttonRef.current.style.visibility = 'hidden';
      } else {
        buttonRef.current.style.visibility = 'visible';
      }
    };

    const resizeObserver = new ResizeObserver(() => checkTextOverflow());
    resizeObserver.observe(textRef.current);

    checkTextOverflow();

    return () => {
      resizeObserver.disconnect();
    };
  }, [textRef, buttonRef]);

  return (
    <div className={cns(cn.container, className)}>
      <div
        className={cns({ [cn.textCollapsed]: !open })}
        style={{ WebkitLineClamp: lines }}
        ref={textRef}
      >
        {children}
      </div>
      <Button
        variant="link"
        className={classNameArrow}
        onClick={() => setOpen(!open)}
        data-qa-selector={qaSelector}
        ref={buttonRef}
      >
        <img src={toggleImg} alt="" />
      </Button>
    </div>
  );
};

export default CollapsableText;
