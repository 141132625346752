import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationCreateUserV2Args,
  UserCreateInputV2,
} from 'graphqlTypes';
import CREATE_USER_V2 from './gql/createUserV2';

const useCreateUserV2 = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createUserV2'>,
    MutationCreateUserV2Args
  >(CREATE_USER_V2);

  const createUserV2 = (userCreateInput: UserCreateInputV2) =>
    mutation({ variables: { userCreateInput } });

  return [
    createUserV2,
    {
      data: data?.createUserV2,
      ...rest,
    },
  ] as const;
};

export default useCreateUserV2;
