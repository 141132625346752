import gql from 'graphql-tag';

import { DamageItemPayloadV2FragmentQCA } from './fragments';

export default gql`
  mutation updateQualityCheckApprovalDamageItemV2(
    $itemId: UUID!
    $damageItemUpdateInput: DamageItemUpdateInputV2!
  ) {
    updateQualityCheckApprovalDamageItemV2(
      itemId: $itemId
      damageItemUpdateInput: $damageItemUpdateInput
    ) {
      id
      data {
        payloadType
        ... on DamageItemPayloadV2 {
          damageState: state
        }
        ...DamageItemPayloadV2FragmentQCA
      }
      revisions {
        id
        propertyPath
        state
      }
    }
  }
  ${DamageItemPayloadV2FragmentQCA}
`;
