import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import cn from './PartnerWorkshopSearch.module.scss';

type FormData = {
  partnerWorkshop: string;
};

const PartnerWorkshopSearch = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const partnerWorkshop = searchParams.get('f') || '';
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      partnerWorkshop,
    },
  });

  const handleOnSubmit = (values: FormData) => {
    if (values.partnerWorkshop) {
      searchParams.set('f', values.partnerWorkshop);
      searchParams.delete('p');
    } else {
      searchParams.delete('f');
    }

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={cn.searchInputContainer}
    >
      <SearchIcon className={cn.searchIcon} />
      <div className={cn.row}>
        <Form.Control
          {...register('partnerWorkshop')}
          placeholder={__('partnerWorkshopConnections.searchPlaceHolder')}
          className={cn.searchInput}
          data-qa-selector="partnerWorkshopFilter"
        />
        <Button variant="primary" type="submit" data-qa-selector="filterButton">
          {__('action.filter')}
        </Button>
      </div>
    </form>
  );
};

export default PartnerWorkshopSearch;
