import cns from 'classnames';

import Text from 'components/UI/Text';
import { ImportTaskStatistics, Maybe } from 'graphqlTypes';

import cn from './ImportRepairCostStatistics.module.scss';

type Props = {
  statistics?: Maybe<ImportTaskStatistics>;
};

const ImportRepairCostStatistics = ({ statistics }: Props) => {
  if (!statistics) {
    return null;
  }

  return (
    <div className={cn.statistics}>
      {!!statistics?.created && (
        <Text
          color="success"
          size="m"
          data-qa-selector="importRepairCostStatisticsItem"
        >{`${statistics.created} ${
          statistics.created === 1
            ? __('repairCost.newCost')
            : __('repairCost.newCosts')
        }`}</Text>
      )}
      {!!statistics?.updated && (
        <Text
          size="m"
          className={cns(cn.statisticsItem, cn.updated)}
          data-qa-selector="importRepairCostStatisticsItem"
        >{`${statistics.updated} ${
          statistics.updated === 1
            ? __('repairCost.replacedCost')
            : __('repairCost.replacedCosts')
        }`}</Text>
      )}
      {!!statistics?.duplicated && (
        <Text
          size="m"
          className={cns(cn.statisticsItem, cn.duplicated)}
          data-qa-selector="importRepairCostStatisticsItem"
        >{`${statistics.duplicated} ${
          statistics.duplicated === 1
            ? __('repairCost.duplicatedCost')
            : __('repairCost.duplicatedCosts')
        }`}</Text>
      )}
      {!!statistics?.failed && (
        <Text
          size="m"
          className={cns(cn.statisticsItem, cn.failed)}
          data-qa-selector="importRepairCostStatisticsItem"
        >{`${statistics.failed} ${
          statistics.failed === 1
            ? __('repairCost.failedCost')
            : __('repairCost.failedCosts')
        }`}</Text>
      )}
    </div>
  );
};

export default ImportRepairCostStatistics;
