import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  DamageItemPayload,
  DamageItemUpdateInput,
  DamageState,
  Item,
  Mutation,
  MutationUpdateQualityCheckDamageItemArgs,
} from 'graphqlTypes';
import { ItemFragmentQC } from './gql/fragments';
import UPDATE_QUALITY_CHECK_DAMAGE_ITEM from './gql/updateQualityCheckDamageItem';

const useUpdateQualityCheckDamageItem = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckDamageItem'>,
    MutationUpdateQualityCheckDamageItemArgs
  >(UPDATE_QUALITY_CHECK_DAMAGE_ITEM);
  const { cache } = useApolloClient();

  const updateQualityCheckDamageItem = (
    itemId: string,
    damageItemUpdateInput: DamageItemUpdateInput,
  ) => {
    const item = cache.readFragment<Item>({
      id: `Item:${itemId}`,
      fragment: ItemFragmentQC,
      fragmentName: 'ItemFragmentQC',
    });

    return mutation({
      variables: {
        itemId,
        damageItemUpdateInput,
      },
      optimisticResponse: item
        ? {
            updateQualityCheckDamageItem: {
              ...item,
              data: {
                ...item.data,
                damageState: damageItemUpdateInput.state,
                stateComment: damageItemUpdateInput.stateComment,
              } as DamageItemPayload & { damageState: DamageState },
            },
          }
        : undefined,
    });
  };

  return [updateQualityCheckDamageItem, options] as const;
};

export default useUpdateQualityCheckDamageItem;
