import gql from 'graphql-tag';

export const informMessageFragment = gql`
  fragment informMessageFragment on InformMessage {
    id
    vin
    stockNumber
    refurbishmentId
    refId
    serviceName
    carPart
    serviceCode
    serviceId
    messageType
    createdAt
    status
    tenant {
      id
      name
    }
    errorCode
    errorText
    direction
    reviewed
    reviewedBy {
      id
      firstName
      lastName
    }
    comment
    content
  }
`;

export default gql`
  query searchInformMessages(
    $page: Int!
    $pageSize: Int!
    $filter: InformMessageFilterInput!
    $sort: InformMessageSortInput
  ) {
    searchInformMessages(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...informMessageFragment
      }
      pageInfo {
        page
        pageSize
        totalEntityCount
        totalPageCount
      }
    }
  }
  ${informMessageFragment}
`;
