import { differenceInMilliseconds } from 'date-fns';

const TIME_SPAN_MILLISECONDS = 5000;

export const enum LogType {
  Click = 'click',
  GraphQL = 'graphql',
}

type LogEvent = {
  type: LogType;
  time: Date;
  description: string;
};

class State {
  private logs: LogEvent[] = [];

  push(logEvent: Omit<LogEvent, 'time'>) {
    const now = new Date();
    this.logs = this.logs.filter(
      ({ time }) =>
        differenceInMilliseconds(now, time) < TIME_SPAN_MILLISECONDS,
    );
    this.logs.push({ ...logEvent, time: now });
  }

  get() {
    return this.logs;
  }
}

let state: State;

const createState = () => new State();

export const getState = () => {
  state = state || createState();
  return state;
};
