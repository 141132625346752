import { RefurbishmentServiceName } from 'graphqlTypes';

const isImperfectionService = (
  serviceName: RefurbishmentServiceName | undefined | string,
): boolean => {
  if (!serviceName) {
    return false;
  }
  return (
    serviceName === RefurbishmentServiceName.NoRepairNeeded ||
    serviceName === RefurbishmentServiceName.NoRepairImperfection || // introduced in PA3
    serviceName === RefurbishmentServiceName.NoRepairWearAndTear // introduced in PA3
  );
};

const getEffectiveCustomerDisplay = (
  serviceName: RefurbishmentServiceName | undefined | string,
  customerDisplay: boolean,
): boolean => {
  if (serviceName === RefurbishmentServiceName.NoRepairImperfection) {
    return true;
  }
  if (serviceName === RefurbishmentServiceName.NoRepairWearAndTear) {
    return false;
  }

  return customerDisplay;
};

export { isImperfectionService, getEffectiveCustomerDisplay };
