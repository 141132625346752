import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import {
  GroupCreateInput,
  Mutation,
  MutationCreateGroupArgs,
} from 'graphqlTypes';
import CREATE_GROUP from './gql/createGroup';

const useCreateGroup = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createGroup'>,
    MutationCreateGroupArgs
  >(CREATE_GROUP);

  const createGroup = (input: GroupCreateInput) =>
    mutation({ variables: { input } });

  return [
    createGroup,
    {
      data: get(['createGroup'], data),
      ...rest,
    },
  ] as const;
};

export default useCreateGroup;
