import gql from 'graphql-tag';

import { RepairMethodFragment } from './fragments';

export default gql`
  mutation updateRepairMethod(
    $id: UUID!
    $repairMethodUpdateInput: RepairMethodUpdateInput!
  ) {
    updateRepairMethod(
      id: $id
      repairMethodUpdateInput: $repairMethodUpdateInput
    ) {
      ...RepairMethodFragment
    }
  }
  ${RepairMethodFragment}
`;
