import React from 'react';
import cns from 'classnames';

import cn from './RequiredFieldIndicator.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const RequiredFieldIndicator = ({ children, className }: Props) => (
  <div
    className={cns(className, cn.root)}
    data-qa-selector="requiredFieldIndicator"
  >
    {children}
    <span className={cns(cn.indicator)}>*</span>
  </div>
);

export default RequiredFieldIndicator;
