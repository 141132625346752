import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import TableInfoPagination from 'components/TableInfoPagination';
import { ACL, Role } from 'modules/acl';
import { ErrorType, useHandleError } from 'modules/errorHandler';
import { getUrl } from 'modules/routeUtils';
import OverviewFilter from './components/OverviewFilter';
import OverviewTable from './components/OverviewTable';
import useGetUserOverview from './hooks/useGetUserOverview';

const OverviewRoute = () => {
  const { data, pageInfo, loading, error } = useGetUserOverview();
  const setError = useHandleError();

  const renderResult = () => {
    if (error) {
      setError(ErrorType.UnexpectedError);
      return null;
    }

    if (loading) return <LoadingIndicator />;

    if (!data || !pageInfo) return null;

    return (
      <>
        <TableInfoPagination
          totalEntityCount={pageInfo.totalEntityCount}
          totalPageCount={pageInfo.totalPageCount}
          page={pageInfo.page}
        />
        <OverviewTable data={data} />
        <TableInfoPagination
          totalPageCount={pageInfo.totalPageCount}
          page={pageInfo.page}
        />
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeader
        title={__('user.overview.title')}
        actions={
          <ACL roles={Role.USERS_ADMIN}>
            <Button
              as={Link}
              to={getUrl('USER_NEW')}
              variant="primary"
              data-qa-selector="new_user"
            >
              {__('user.overview.newUser')}
            </Button>
          </ACL>
        }
      />
      <OverviewFilter />
      {renderResult()}
    </PageContainer>
  );
};

export default OverviewRoute;
