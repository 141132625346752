import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateQualityCheckApprovalVehicleItemArgs,
  VehicleItemUpdateInput,
} from 'graphqlTypes';
import UPDATE_QUALITY_CHECK_APPROVAL_VEHICLE_ITEM from './gql/updateQualityCheckApprovalVehicleItem';

const useUpdateQualityCheckApprovalVehicleItem = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckApprovalVehicleItem'>,
    MutationUpdateQualityCheckApprovalVehicleItemArgs
  >(UPDATE_QUALITY_CHECK_APPROVAL_VEHICLE_ITEM);

  const updateQualityCheckApprovalVehicleItem = (
    itemId: string,
    vehicleItemUpdateInput: VehicleItemUpdateInput,
  ) => {
    return mutation({
      variables: {
        itemId,
        vehicleItemUpdateInput,
      },
    });
  };

  return [updateQualityCheckApprovalVehicleItem, options] as const;
};

export default useUpdateQualityCheckApprovalVehicleItem;
