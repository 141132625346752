import { useLazyQuery } from '@apollo/react-hooks';

import { Query, QueryRefurbishmentTasksArgs } from 'graphqlTypes';
import REFURBISHMENT_TASKS from './gql/refurbishmentTasks';

const useRefurbishmentTasks = () => {
  const [query, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'refurbishmentTasks'>,
    QueryRefurbishmentTasksArgs
  >(REFURBISHMENT_TASKS, {
    fetchPolicy: 'no-cache',
  });

  const getRefurbishmentTasks = (variables: QueryRefurbishmentTasksArgs) => {
    query({ variables });
  };

  return [
    getRefurbishmentTasks,
    {
      data: data?.refurbishmentTasks,
      ...rest,
    },
  ] as const;
};

export default useRefurbishmentTasks;
