import gql from 'graphql-tag';

export default gql`
  query groups($id: UUID!) {
    groups(id: $id) {
      id
      name
      description
      roles {
        id
        name
        description
      }
      users {
        id
        email
        firstName
        lastName
        active
      }
    }
  }
`;
