import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Mutation, MutationCompleteEntryCheckArgs } from 'graphqlTypes';
import ENTRY_CHECK from './gql/entryCheck';
import IMPORT_CAT_DAMAGES_TO_ENTRY_CHECK from './gql/importCatDamagesToEntryCheck';

const useImportCatDamagesToEntryCheck = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'importCatDamagesToEntryCheck'>,
    MutationCompleteEntryCheckArgs
  >(IMPORT_CAT_DAMAGES_TO_ENTRY_CHECK);

  const importCatDamagesToEntryCheck = (
    id: string,
    refurbishmentId: string,
  ) => {
    return mutation({
      variables: { id },
      refetchQueries: [
        {
          query: ENTRY_CHECK,
          variables: { refurbishmentId },
        },
      ],
    });
  };

  return [
    importCatDamagesToEntryCheck,
    {
      data: get('useImportCatDamagesToEntryCheck', data),
      ...rest,
    },
  ] as const;
};

export default useImportCatDamagesToEntryCheck;
