import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import ImportHistoryModal from './ImportHistoryModal';
import ImportRepairCostModal from './ImportRepairCostModal';

export const enum RepairCostImportModalType {
  'IMPORT_HISTORY' = 'importHistory',
  'IMPORT_REPAIR_COST' = 'importRepairCost',
}

const RepairCostImport = () => {
  const [
    activeModal,
    setActiveModal,
  ] = useState<null | RepairCostImportModalType>(null);

  const showModal = (modalName: RepairCostImportModalType) =>
    setActiveModal(modalName);

  const closeModal = () => setActiveModal(null);

  return (
    <>
      <ImportHistoryModal
        isModalVisible={
          activeModal === RepairCostImportModalType.IMPORT_HISTORY
        }
        closeModal={closeModal}
      />

      <ImportRepairCostModal
        isModalVisible={
          activeModal === RepairCostImportModalType.IMPORT_REPAIR_COST
        }
        closeModal={closeModal}
      />

      <Button
        variant="outline-primary"
        onClick={() => showModal(RepairCostImportModalType.IMPORT_HISTORY)}
        data-qa-selector="importHistoryButton"
        className="ml-4"
      >
        {__('repairCost.openImportHistory')}
      </Button>

      <Button
        variant="outline-primary"
        onClick={() => showModal(RepairCostImportModalType.IMPORT_REPAIR_COST)}
        className="ml-4"
        data-qa-selector="importRepairCostsButton"
      >
        {__('repairCost.importRepairCosts')}
      </Button>
    </>
  );
};

export default RepairCostImport;
