import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';
import ReactSelect from 'react-select';
import { debounce } from 'lodash';
import { map } from 'lodash/fp';

import {
  createMultiValueContainer,
  createOption,
} from 'components/Form/ReactSelectInput';
import { User } from 'graphqlTypes';
import { useAssignUsers } from 'modules/api/groups';
import { useSearchUsers } from 'modules/api/users';

interface Props {
  id: string;
  members: User[];
}

const AddMember = ({ id, members }: Props) => {
  const [show, setShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [assignUsers] = useAssignUsers();
  const [searchUsers, { data, loading }] = useSearchUsers();

  const debounceSearchUsers = useCallback(debounce(searchUsers, 500), [
    searchUsers,
  ]);

  const options = map(
    user => ({
      label: `${user.firstName} ${user.lastName} (${user.email})`,
      value: user.id,
    }),
    data,
  );

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleAutocomplete = (value: string) => {
    if (value) {
      debounceSearchUsers({
        page: 0,
        pageSize: 20,
        filter: {
          firstName: value,
          active: true,
        },
      });
    }
  };

  const handleChange = (value: any) => {
    setSelectedOptions(value);
  };

  const handleSubmit = () => {
    const currentMembersIds = members.map(member => member.id);
    const newMembersIds = selectedOptions.map(user => user.value);

    assignUsers({
      groupId: id,
      userIds: currentMembersIds.concat(newMembersIds),
    });
    handleClose();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="d-flex align-items-center"
        data-qa-selector="add_new_user"
      >
        <FaUserPlus />{' '}
        <strong className="pl-2">{__('group.detail.addMember.button')}</strong>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__('group.detail.addMember.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div data-qa-selector="add_group_member_select">
            <ReactSelect
              name="users"
              options={options}
              placeholder={__('refurbishment.overview.select')}
              isClearable
              isMulti
              isLoading={loading}
              onInputChange={handleAutocomplete}
              onChange={handleChange}
              components={{
                Option: createOption({ dataQaOption: 'member_option' }),
                MultiValueContainer: createMultiValueContainer('added_member'),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {__('action.cancel')}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {__('action.add')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMember;
