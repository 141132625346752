import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import TableInfoPagination from 'components/TableInfoPagination';
import TableInfo from 'components/TableInfoPagination/TableInfo';
import MonitoringFilter from './components/MonitoringFilter';
import MonitoringRow from './components/MonitoringRow/MonitoringRow';
import MonitoringTable from './components/MonitoringTable';
import RetryMessage from './components/RetryMessage';
import useDefaultSorting from './hooks/useDefaultSorting';
import useFilterInformMessages from './hooks/useFilterInformMessages';
import useFixedFilterButtons from './hooks/useFixedFilterButtons';
import useSelectMessages from './hooks/useSelectMessages';
import { InformMessageStatus } from './types';

import cn from './MonitoringDashboard.module.scss';

const MonitoringDashboardRoute = () => {
  useDefaultSorting();
  const { data, setFilterValues, loading } = useFilterInformMessages();
  const {
    messages,
    hasSelectedMessages,
    setSelectedMessage,
    setAllSelectedMessages,
  } = useSelectMessages(data?.items);

  const pageInfo = data?.pageInfo;

  const [filterButtonContainerRef, tableContainerRef] = useFixedFilterButtons();

  return (
    <PageContainer fluid className={cn.pageContainer}>
      <Helmet>
        <title>{__('monitoring.title')}</title>
      </Helmet>
      <PageHeader
        title={__('monitoring.title')}
        className={cn.pageHeader}
        actions={
          <div className={cn.actionsArea}>
            <TableInfo
              results={data?.pageInfo.totalEntityCount || ''}
              className="ml-3 text-body"
            />
            <TableInfoPagination
              totalPageCount={pageInfo?.totalPageCount}
              page={pageInfo?.page}
              className={cn.paginationTop}
            />
          </div>
        }
      />

      <MonitoringTable tableContainerRef={tableContainerRef}>
        <MonitoringFilter
          onChange={setFilterValues}
          loading={loading}
          filterButtonContainerRef={filterButtonContainerRef}
          checkBoxArea={
            <Form.Check
              type="checkbox"
              id="selectAll"
              custom
              data-qa-selector="select-all"
              checked={hasSelectedMessages}
              onChange={e => setAllSelectedMessages(e.target.checked)}
              className="mt-2"
            />
          }
          retryArea={
            hasSelectedMessages && (
              <RetryMessage
                messages={messages}
                onRetry={() => setAllSelectedMessages(false)}
              />
            )
          }
        />
        {messages?.map(message => (
          <MonitoringRow
            message={message}
            key={message.id}
            checkboxArea={
              message.status === InformMessageStatus.VPC_PROCESSING_ERROR && (
                <Form.Check
                  type="checkbox"
                  id={message.id}
                  checked={message.selected}
                  custom
                  onChange={() =>
                    setSelectedMessage(message.id, !message.selected)
                  }
                  data-qa-selector={`select-${message.id}`}
                />
              )
            }
          />
        ))}
      </MonitoringTable>
    </PageContainer>
  );
};

export default MonitoringDashboardRoute;
