import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationRetryInformMessagesArgs } from 'graphqlTypes';
import { InformMessageStatus } from 'routes/MonitoringDashboardRoute/types';
import RETRY_INFORM_MESSAGES from './gql/retryInformMessages';
import { informMessageFragment } from './gql/searchInformMessages';

const useRetryInformMessages = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'retryInformMessages'>,
    MutationRetryInformMessagesArgs
  >(RETRY_INFORM_MESSAGES);

  const retryInformMessages = (variables: MutationRetryInformMessagesArgs) =>
    mutation({
      variables,
      update: cache => {
        variables.ids.forEach(id => {
          cache.writeFragment({
            id: `InformMessage:${id}`,
            fragment: informMessageFragment,
            data: {
              status: InformMessageStatus.RETRIED,
              __typename: 'InformMessage',
            },
          });
        });
      },
    });

  return [
    retryInformMessages,
    {
      data: data?.retryInformMessages,
      ...rest,
    },
  ] as const;
};

export default useRetryInformMessages;
