import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateRefurbishmentTaskCurrentPartnerWorkshopConnectionArgs,
} from 'graphqlTypes';
import UPDATE_REFURBISHMENT_TASK_PWC from './gql/updateRefurbishmentTaskCurrentPartnerWorkshopConnection';

const useUpdateRefurbishmentTaskCurrentPartnerWorkshopConnection = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateRefurbishmentTaskCurrentPartnerWorkshopConnection'>,
    MutationUpdateRefurbishmentTaskCurrentPartnerWorkshopConnectionArgs
  >(UPDATE_REFURBISHMENT_TASK_PWC);

  const updateRefurbishmentTaskCurrentPartnerWorkshopConnection = (
    taskId: string,
    currentPartnerWorkshopConnectionId: string,
  ) =>
    mutation({
      variables: {
        taskId,
        currentPartnerWorkshopConnectionId,
      },
    });

  return [
    updateRefurbishmentTaskCurrentPartnerWorkshopConnection,
    {
      data: data?.updateRefurbishmentTaskCurrentPartnerWorkshopConnection,
      ...rest,
    },
  ] as const;
};

export default useUpdateRefurbishmentTaskCurrentPartnerWorkshopConnection;
