import { format, isValid } from 'date-fns';
import { de, enGB, es, fr, it, nl, pl, sv } from 'date-fns/locale';

import { getOriginalDate } from './utils/getOriginalDate';
import { LOCALE } from './config';

// List of locales: https://github.com/date-fns/date-fns/tree/master/src/locale
const locales = {
  'en-GB': enGB,
  'de-DE': de,
  'es-ES': es,
  'fr-BE': fr,
  'FR-FR': fr,
  'it-IT': it,
  'nl-BE': nl,
  'pl-PL': pl,
  'sv-SE': sv,
};

type Locale = keyof typeof locales;

export const dateLocale = locales[LOCALE as Locale];

const getFormattedDate = (dateFormat: string) => (
  date?: string | Date | null,
) => {
  if (!date) return;

  const dateObject = date instanceof Date ? date : getOriginalDate(date);

  if (isValid(dateObject)) {
    return format(dateObject, dateFormat, {
      locale: locales[LOCALE as Locale] || enGB,
    });
  }

  return date as string;
};

export const formatDate = getFormattedDate('PP');
export const formatDateTime = getFormattedDate('PPp');
export const formatDateYYYYMMDD = getFormattedDate('yyyy-MM-dd');
export const formatDateDDMMYYYY = getFormattedDate('dd/MM/yyyy');
