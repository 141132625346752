import {
  Query,
  QueryRepairCostByDamageArgs,
  RepairCostByDamageInput,
} from 'graphqlTypes';
import REPAIR_COST_BY_DAMAGE from './gql/repairCostByDamage';
import usePromiseQuery from '../helpers/usePromiseQuery';

const useGetRepairCostByDamage = () => {
  const query = usePromiseQuery<
    Pick<Query, 'repairCostByDamage'>,
    QueryRepairCostByDamageArgs
  >(REPAIR_COST_BY_DAMAGE, {
    fetchPolicy: 'no-cache',
  });

  const getRepairCostByDamage = async (
    repairCostByDamageInput: RepairCostByDamageInput,
  ) => {
    const { data } = await query({
      repairCostByDamageInput,
    });
    return data.repairCostByDamage.repairCosts;
  };

  return getRepairCostByDamage;
};

export default useGetRepairCostByDamage;
