import axios from 'axios';

export const CANCELED_CODE = 'CANCELED';

export default (res, options) => {
  const config = {
    id: options.id,
    method: options.method,
    url: options.url,
    timeout: options.timeout,
    headers: options.headers,
  };

  let error = null;

  if (res.stack) {
    error = { message: res.message, stack: res.stack };
  }

  const response = res.response || res;

  const result = response.data || {};
  const headers = response.headers || {};

  let statusCode = response.status || response.code;

  if (axios.isCancel(response)) {
    statusCode = CANCELED_CODE;
    error = { message: response.message };
  }

  return { config, statusCode, result, headers, error };
};
