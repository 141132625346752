import React from 'react';
import { Helmet } from 'react-helmet';

import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import { ACL, Role } from 'modules/acl';
import { useGetAllLocations } from 'modules/api/locations';
import RepairMethodImport from './components/RepairMethodImport';
import RepairMethodSearch from './components/RepairMethodSearch';
import RepairMethodTable from './components/RepairMethodTable';

const RepairCostRoute = () => {
  const {
    data: allLocations,
    loading: allLocationsLoading,
  } = useGetAllLocations();

  if (allLocationsLoading || !allLocations) {
    return <LoadingIndicator />;
  }

  return (
    <PageContainer fluid>
      <Helmet>
        <title>{__('repairMethod.title')}</title>
      </Helmet>
      <PageHeader
        title={__('repairMethod.title')}
        subTitle={__('repairMethod.subTitle')}
        actions={
          <ACL roles={Role.REPAIR_METHOD_ADMIN}>
            <RepairMethodImport />
          </ACL>
        }
      />
      <RepairMethodSearch allLocations={allLocations} />
      <RepairMethodTable />
    </PageContainer>
  );
};

export default RepairCostRoute;
