import gql from 'graphql-tag';

import { QualityCheckVehicleItemPayloadFragment } from './fragments';

export default gql`
  mutation updateQualityCheckVehicleItem(
    $itemId: UUID!
    $vehicleItemUpdateInput: VehicleItemUpdateInput!
  ) {
    updateQualityCheckVehicleItem(
      itemId: $itemId
      vehicleItemUpdateInput: $vehicleItemUpdateInput
    ) {
      id
      data {
        ...QualityCheckVehicleItemPayloadFragment
      }
    }
  }
  ${QualityCheckVehicleItemPayloadFragment}
`;
