import React from 'react';
import cns from 'classnames';

import cn from './Divider.module.scss';

interface Props {
  expand?: boolean;
  className?: string;
}
const Divider = ({ expand = true, className }: Props) => (
  <div className={cns(cn.root, { [cn.expand]: expand }, className)} />
);

export default Divider;
