import React, { useState } from 'react';

import ImageStorageContext from '../context';

type Props = {
  children: React.ReactNode;
};

const ImageStorageProvider = ({ children }: Props) => {
  const [urlToObjectUrl, setUrlToObjectUrl] = useState<Map<string, string>>(
    new Map<string, string>(),
  );

  const bucketName = process.env.REACT_APP_IMAGES_S3_BUCKET || '';
  const cdn = process.env.REACT_APP_IMAGES_CDN || '';

  const storeUploadedImage = (url: string, objectUrl: string): void => {
    urlToObjectUrl.set(url, objectUrl);
    setUrlToObjectUrl(urlToObjectUrl);
  };

  return (
    <ImageStorageContext.Provider
      value={{ urlToObjectUrl, storeUploadedImage, bucketName, cdn }}
    >
      {children}
    </ImageStorageContext.Provider>
  );
};

export default ImageStorageProvider;
