import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  name: string;
  errors: object;
}

const InlineFieldError = ({ name, errors }: Props) => {
  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => (
        <OverlayTrigger
          overlay={<Tooltip id={`tooltip-${name}`}>{message}</Tooltip>}
        >
          <ErrorIcon color="error" />
        </OverlayTrigger>
      )}
    />
  );
};

export default InlineFieldError;
