import { Button, Form } from 'react-bootstrap';
import ReactDatePicker, {
  CalendarContainer,
  ReactDatePickerProps,
} from 'react-datepicker';
import { ControllerProps, FieldValues, useController } from 'react-hook-form';
import {
  endOfDay,
  isSameDay,
  isToday,
  isYesterday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  subDays,
} from 'date-fns';

import { dateLocale } from 'modules/i18n';

import cn from './DateRangePickerInput.module.scss';

export type DatePickerInputProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render' | 'onChange' | 'valueName' | 'customInput'
> &
  Omit<ReactDatePickerProps, 'name' | 'onChange' | 'locale'> & {
    disabled?: Boolean;
    dataQaSelector?: string;
    onSelect?: Function;
    errors?: any;
    form?: string;
  };

type ContainerProps = {
  className?: string;
  children?: React.ReactNode;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (value: [Date | null, Date | null]) => void;
};

const Container = ({
  className,
  children,
  startDate,
  endDate,
  onChange,
}: ContainerProps) => {
  const today = startOfToday();

  const isTodaySelected =
    startDate && endDate && isToday(startDate) && isToday(endDate);
  const isYesterdaySelected =
    startDate && endDate && isYesterday(startDate) && isYesterday(endDate);
  const isThisWeekSelected =
    startDate &&
    endDate &&
    isSameDay(
      startOfWeek(today, {
        locale: dateLocale,
      }),
      startDate,
    ) &&
    isToday(endDate);
  const isThisMonthSelected =
    startDate &&
    endDate &&
    isSameDay(startOfMonth(today), startDate) &&
    isToday(endDate);
  const isLast7DaysSelected =
    startDate &&
    endDate &&
    isSameDay(subDays(today, 7), startDate) &&
    isToday(endDate);

  const handleToday = () => {
    onChange([today, today]);
  };

  const handleYesterday = () => {
    const date = subDays(today, 1);
    onChange([date, date]);
  };

  const handleThisWeek = () => {
    const date = startOfWeek(today, {
      locale: dateLocale,
    });
    onChange([date, today]);
  };

  const handleThisMonth = () => {
    const date = startOfMonth(today);
    onChange([date, today]);
  };

  const handleLast7Days = () => {
    const date = subDays(today, 7);
    onChange([date, today]);
  };

  return (
    <CalendarContainer className={className}>
      <div className={cn.buttons}>
        <Button
          size="sm"
          variant={isTodaySelected ? 'primary' : 'outline-primary'}
          onClick={handleToday}
        >
          {__('dateRangePicker.today')}
        </Button>
        <Button
          size="sm"
          variant={isYesterdaySelected ? 'primary' : 'outline-primary'}
          onClick={handleYesterday}
        >
          {__('dateRangePicker.yesterday')}
        </Button>
        <Button
          size="sm"
          variant={isThisWeekSelected ? 'primary' : 'outline-primary'}
          onClick={handleThisWeek}
        >
          {__('dateRangePicker.thisWeek')}
        </Button>
        <Button
          size="sm"
          variant={isThisMonthSelected ? 'primary' : 'outline-primary'}
          onClick={handleThisMonth}
        >
          {__('dateRangePicker.thisMonth')}
        </Button>
        <Button
          size="sm"
          variant={isLast7DaysSelected ? 'primary' : 'outline-primary'}
          onClick={handleLast7Days}
        >
          {__('dateRangePicker.last7days')}
        </Button>
      </div>
      <div style={{ position: 'relative' }}>{children}</div>
    </CalendarContainer>
  );
};

const formatDateValue = (value: any) => {
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string') {
    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
  }
  return null;
};

const DateRangePickerInput = <T extends FieldValues>({
  name,
  control,
  errors,
  disabled,
  dataQaSelector,
  rules,
  form,
  ...rest
}: DatePickerInputProps<T>) => {
  const { field } = useController({ name, control, rules });
  const { onChange, value, ...fieldRest } = field;

  const dateValue = [formatDateValue(value?.[0]), formatDateValue(value?.[1])];

  const handleOnChange = ([startDay, endDay]: [Date | null, Date | null]) => {
    const fixedValue = [startDay, endDay ? endOfDay(endDay) : null];

    onChange(fixedValue);
  };

  return (
    <>
      <ReactDatePicker
        {...rest}
        {...fieldRest}
        selected={dateValue[0]}
        onChange={handleOnChange}
        startDate={dateValue[0]}
        endDate={dateValue[1]}
        selectsRange
        showPreviousMonths
        monthsShown={2}
        autoComplete="off"
        popperClassName={cn.datePickerPopper}
        dateFormat="dd/MM/yyyy"
        locale={dateLocale}
        disabled={disabled}
        calendarContainer={containerProps => (
          <Container
            {...containerProps}
            startDate={dateValue[0]}
            endDate={dateValue[1]}
            onChange={handleOnChange}
          />
        )}
        customInput={
          <Form.Control
            data-qa-selector={dataQaSelector}
            isInvalid={errors && !!errors[name]}
            disabled={disabled}
            form={form}
          />
        }
      />
      {!!errors && !!errors[name] && errors[name].message && (
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {errors[name].message}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default DateRangePickerInput;
