import { Control, FieldValues, Path } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { useGetAllPartners } from 'modules/api/partners';
import createPlaceholder from './createPlaceholder';
import createSingleValue from './createSingleValue';

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, object>;
  qaSelector: string;
  placeholder: string;
};

type Value = {
  name: string;
  id: number;
};

const PartnerFilter = <T extends FieldValues>({
  name,
  control,
  qaSelector,
  placeholder,
}: Props<T>) => {
  const { data, loading } = useGetAllPartners();

  const options =
    data
      ?.map(item => ({
        label: item.name,
        value: {
          name: item.name,
          id: item.id,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <ReactSelectInput
      name={name}
      control={control}
      options={options}
      isLoading={loading}
      closeMenuOnSelect={false}
      inputId={name}
      equalFn={(value1: Value, value2: Value) => value1?.id === value2?.id}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
      placeholder={placeholder}
      isClearable
      components={{
        SingleValue: createSingleValue(),
        Placeholder: createPlaceholder(),
      }}
    />
  );
};

export default PartnerFilter;
