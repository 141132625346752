import React from 'react';

type Props = {
  timeInHours: number;
};

const LaborTime = ({ timeInHours }: Props) => {
  return (
    <span data-qa-selector="laborTimeRO">
      {timeInHours} {__('costTable.hours')}
    </span>
  );
};

export default LaborTime;
