import gql from 'graphql-tag';

export default gql`
  query buildImageUploadCredentials(
    $awsUploadCredentialsInput: AwsUploadCredentialsInput!
  ) {
    buildImageUploadCredentials(
      awsUploadCredentialsInput: $awsUploadCredentialsInput
    ) {
      awsUploadCredentials {
        host
        cdn
        fileName
        formData
      }
    }
  }
`;
