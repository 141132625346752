import { useState } from 'react';
import { Button } from 'react-bootstrap';

import LoadingIndicator from 'components/LoadingIndicator';
import CollapsableText from 'components/UI/CollapsableText';
import CommentEditInlineForm from './CommentEditInlineForm';

type Props = {
  comment: string;
  qaSelector: string;
  lines?: number;
  className?: string;
  loading?: boolean;
  onChange: (comment: string) => void;
};

const CommentEditInline = ({
  comment,
  qaSelector,
  lines = 3,
  className,
  loading,
  onChange,
}: Props) => {
  const [editing, setEditing] = useState(false);

  const handleOnChange = (value: string) => {
    onChange(value);
    setEditing(false);
  };

  return (
    <div className={className}>
      {editing ? (
        <CommentEditInlineForm
          comment={comment}
          qaSelector={qaSelector}
          onChange={handleOnChange}
          onCancel={() => setEditing(false)}
        />
      ) : (
        <>
          <CollapsableText lines={lines} qaSelector={`${qaSelector}-text`}>
            {loading ? <LoadingIndicator /> : comment || '-'}
          </CollapsableText>
          <Button
            variant="link"
            className="px-0 edit-button"
            data-qa-selector={`${qaSelector}-edit`}
            onClick={() => setEditing(value => !value)}
          >
            {__('action.editComment')}
          </Button>
        </>
      )}
    </div>
  );
};

export default CommentEditInline;
