import React, { Ref } from 'react';
import cns from 'classnames';
import { findIndex, flow, get, isEqual, toString } from 'lodash/fp';
import { Option } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';

export type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  options: Option<any>[];
  isValueObject?: boolean;
  dataQa: string;
  dataQaOption: string;
  isLoading?: boolean;
};

const Select = React.forwardRef(
  (
    {
      value,
      options,
      isValueObject = false,
      className,
      dataQa,
      dataQaOption,
      isLoading,
      ...rest
    }: SelectProps,
    ref?: Ref<HTMLSelectElement>,
  ) => {
    if (isLoading) return <LoadingIndicator />;

    const selectOptions = isValueObject
      ? options.map<Option<number>>(({ label }, index) => ({
          label,
          value: index,
        }))
      : options;

    let currentValue = isValueObject
      ? findIndex(flow(get('value'), isEqual(value)), options)
      : value;

    if (
      !selectOptions
        .map(option => toString(option.value))
        .includes(toString(currentValue))
    ) {
      currentValue = -1;
    }

    const selectValue =
      currentValue === -1 ||
      currentValue === undefined ||
      currentValue === null ? (
        <option value={-1} disabled>
          {__('action.select')}
        </option>
      ) : null;

    return (
      <select
        {...rest}
        ref={ref}
        value={currentValue ?? -1}
        className={cns(className, 'form-control')}
        data-qa-selector={dataQa}
      >
        {selectValue}
        {selectOptions.map(({ label, value: optionValue }, index) => (
          <option
            key={index}
            value={isValueObject ? index : optionValue}
            data-qa-selector={`${dataQaOption}_${
              isValueObject
                ? Object.values(options[index].value).join('_')
                : optionValue
            }`}
          >
            {label}
          </option>
        ))}
      </select>
    );
  },
);

export default Select;
