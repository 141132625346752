import { RefurbishmentState } from 'graphqlTypes';

export type AvailableRefurbishmentState = Exclude<
  RefurbishmentState,
  RefurbishmentState.Open
>;

export enum RefurbishmentStateExtra {
  ReworkAuthorization = 'REWORK_AUTHORIZATION',
}

export const states: Record<RefurbishmentState, RefurbishmentState> = {
  OPEN: RefurbishmentState.Open,
  REFURBISHMENT_ORDERED: RefurbishmentState.RefurbishmentOrdered,
  PREPARATION_STARTED: RefurbishmentState.PreparationStarted,
  REFURBISHMENT_FEEDBACK_RECEIVED:
    RefurbishmentState.RefurbishmentFeedbackReceived,
  REFURBISHMENT_AUTHORIZED: RefurbishmentState.RefurbishmentAuthorized,
  REFURBISHMENT_STARTED: RefurbishmentState.RefurbishmentStarted,
  REFURBISHMENT_COMPLETED: RefurbishmentState.RefurbishmentCompleted,
  QUALITY_CHECK_ORDERED: RefurbishmentState.QualityCheckOrdered,
  QUALITY_CHECK_COMPLETED: RefurbishmentState.QualityCheckCompleted,
  COMPLETED: RefurbishmentState.Completed,
  CANCELLED: RefurbishmentState.Cancelled,
  CAR_ARRIVED_IN_WORKSHOP: RefurbishmentState.CarArrivedInWorkshop,
  CAR_IN_BUFFER: RefurbishmentState.CarInBuffer,
  PREPARED_FOR_ENTRY_CHECK: RefurbishmentState.PreparedForEntryCheck,
  READY_FOR_COST_CALCULATION: RefurbishmentState.ReadyForCostCalculation,
  RETURN_TO_AUTO1_CANDIDATE: RefurbishmentState.ReturnToAuto1Candidate,
  FLAG_FOR_RETURN_TO_AUTO1: RefurbishmentState.FlagForReturnToAuto1,
};

export const statesTranslated = {
  [states.OPEN]: __('state.refurbishment_open'),
  [states.REFURBISHMENT_ORDERED]: __('state.refurbishment_ordered'),
  [states.CAR_ARRIVED_IN_WORKSHOP]: __('state.car_arrived_in_workshop'),
  [states.CAR_IN_BUFFER]: __('state.car_in_buffer'),
  [states.PREPARED_FOR_ENTRY_CHECK]: __('state.prepared_for_entry_check'),
  [states.PREPARATION_STARTED]: __('state.preparation_started'),
  [states.READY_FOR_COST_CALCULATION]: __('state.ready_for_cost_calculation'),
  [states.REFURBISHMENT_FEEDBACK_RECEIVED]: __(
    'state.refurbishment_feedback_received',
  ),
  [states.REFURBISHMENT_AUTHORIZED]: __('state.refurbishment_authorized'),
  [states.REFURBISHMENT_STARTED]: __('state.refurbishment_started'),
  [states.REFURBISHMENT_COMPLETED]: __('state.refurbishment_completed'),
  [states.QUALITY_CHECK_ORDERED]: __('state.quality_check_ordered'),
  [states.QUALITY_CHECK_COMPLETED]: __('state.quality_check_completed'),
  [states.COMPLETED]: __('state.completed'),
  [states.CANCELLED]: __('state.cancelled'),
  [states.RETURN_TO_AUTO1_CANDIDATE]: __('state.return_to_auto1_candidate'),
  [states.FLAG_FOR_RETURN_TO_AUTO1]: __('state.flag_for_return_to_auto1'),
};

export const ALL_STATES = 'ALL_STATES';
