import { FeatureFlag, useFeature } from 'modules/feature';
import { getLoggerConfig } from './loggerInit';
import { getState } from './state';

type LogRecord = {
  error: unknown;
};

const isDevelop = process.env.NODE_ENV === 'development';

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  if (typeof error === 'string') return error;
  return 'Unknown error';
};

export const useLogger = () => {
  const isClientSideLogsEnabled = useFeature({
    feature: FeatureFlag.CLIENT_SIDE_LOGS,
  });
  const { apiOrigin, useGetUser } = getLoggerConfig();
  const { user } = useGetUser();

  const api = (body: object) => {
    fetch(`${apiOrigin}/api/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      // eslint-disable-next-line no-console
    }).catch(console.log);
  };

  const logError = ({ error }: LogRecord) => {
    if (isDevelop || !isClientSideLogsEnabled) {
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }

    const breadcrumbs = getState()
      .get()
      .map(
        ({ type, time, description }) =>
          `  ${type},${description},${time.toISOString()}`,
      )
      .join('\n');

    api({
      error: {
        message: getErrorMessage(error),
        stack: error instanceof Error ? error.stack : undefined,
      },
      auth: user?.email,
      request: window.location.href,
      service: { version: process.env.REACT_APP_INFO_VERSION || 'unknown' },
      breadcrumbs,
    });
  };

  return { logError };
};
