import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import REFURBISHMENT_TASK_ITEMS_EXCLUDING_COSTS from './gql/refurbishmentTaskItemsExcludingCosts';

const useRefurbishmentTaskItemsExcludingCosts = (
  taskId: string = '',
  userCanReadCosts: boolean | undefined,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'refurbishmentTaskItemsExcludingCosts'>
  >(REFURBISHMENT_TASK_ITEMS_EXCLUDING_COSTS, {
    variables: { taskId },
    skip: !taskId || userCanReadCosts !== false,
    fetchPolicy: 'cache-and-network',
  });

  return [
    {
      data: data?.refurbishmentTaskItemsExcludingCosts,
      ...rest,
    },
  ] as const;
};

export default useRefurbishmentTaskItemsExcludingCosts;
