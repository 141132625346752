import { getState, LogType } from './state';

const { fetch: originalFetch } = window;

export const initGraphQLTracker = () => {
  window.fetch = async (...args) => {
    const [resource, options] = args;
    const response = await originalFetch(resource, options);

    if (options && options.body && typeof options.body === 'string') {
      const { body } = options;
      const parsedBody = JSON.parse(body);

      if (Array.isArray(parsedBody)) {
        const shortBody = parsedBody
          .filter(
            item =>
              'operationName' in item && 'variables' in item && 'query' in item,
          )
          .map(item => ({
            operationName: item.operationName,
            variables: item.variables,
            query: `${item.query?.slice(0, 300)}${
              item.query?.length > 300 ? '...' : ''
            }`,
          }));

        const traceId = response.headers.get('x-b3-traceid');
        getState().push({
          type: LogType.GraphQL,
          description: JSON.stringify({
            queries: shortBody,
            'x-b3-traceid': traceId,
          }),
        });
      }
    }

    return response;
  };
};
