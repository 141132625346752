import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from 'modules/api/partnerWorkshop/gql/fragments';
import {
  DamageItemPayloadFragment,
  DamageItemPayloadV2FragmentQC,
  QualityCheckVehicleItemPayloadFragment,
  RefurbishmentItemPayloadFragment,
  ServiceItemPayloadFragment,
} from './fragments';
import { RefurbishmentEtaFragment } from '../../entryCheck/gql/fragments';
import {
  DocumentFragment,
  PartnerWorkshopConnectionRefurbishmentResponseFragment,
} from '../../refurbishments/gql/fragments';

const QualityCheckReviewV2LocationFragment = gql`
  fragment QualityCheckReviewV2LocationFragment on Location {
    id
  }
`;

const QualityCheckReviewFragment = gql`
  fragment QualityCheckReviewFragment on RefurbishmentDetails {
    id
    adId
    refId
    state
    currencyCode
    country
    lastStateUpdatedOn
    providerEstimatedCompleteDate
    beautyShot
    beautyShotsTakenOn
    picturesReceivedOn
    refurbishmentType
    refurbishmentEtas {
      ...RefurbishmentEtaFragment
    }
    transitionCurrentSet {
      stateTo
      transitionDate
    }
    documents {
      ...DocumentFragment
    }
    location {
      ...QualityCheckReviewV2LocationFragment
    }
    vehicle {
      stockNumber
      vin
      make
      model
      subModel
      fuelType
      lastServiceMileage {
        distance
        unit
      }
      lastServiceOn
      technicalInspectionCertificateIssueDate
      technicalInspectionCertificateIssueMileage {
        distance
        unit
      }
      gearType
      registration
      registrationUrl
      serviceBookImageUrls
      sourcingCountry
      mileage {
        distance
        unit
      }
      electricBatteryDetails {
        isLeased
        leasingCost
        hasConditionReport
        conditionStatus
        capacity
        range
        batteryDocumentUrl
      }
    }
    currentPartnerWorkshopConnection {
      ...PartnerWorkshopConnectionFragment
    }
    partnerWorkshopConnections {
      ...PartnerWorkshopConnectionRefurbishmentResponseFragment
    }
    tags
  }
  ${RefurbishmentEtaFragment}
  ${DocumentFragment}
  ${QualityCheckReviewV2LocationFragment}
  ${PartnerWorkshopConnectionFragment}
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
`;

export default gql`
  query qualityCheckReview($id: UUID!) {
    qualityCheckReview(id: $id) {
      id
      state
      refurbishment {
        ...QualityCheckReviewFragment
      }
      phases {
        id
        name
        state
      }
      tags
      items {
        itemId
        itemType
        values {
          id
          phaseName
          data {
            payloadType
            ... on ServiceItemPayload {
              serviceState: state
            }
            ... on DamageItemPayload {
              damageState: state
            }
            ... on DamageItemPayloadV2 {
              damageState: state
            }
            ...QualityCheckVehicleItemPayloadFragment
            ...ServiceItemPayloadFragment
            ...DamageItemPayloadFragment
            ...DamageItemPayloadV2FragmentQC
            ...RefurbishmentItemPayloadFragment
          }
          revisions {
            id
            propertyPath
            state
            createdBy {
              id
              firstName
            }
          }
          createdOn
        }
      }
      readyForImperfectionPhotosOn
    }
  }
  ${QualityCheckReviewFragment}
  ${QualityCheckVehicleItemPayloadFragment}
  ${ServiceItemPayloadFragment}
  ${DamageItemPayloadFragment}
  ${DamageItemPayloadV2FragmentQC}
  ${RefurbishmentItemPayloadFragment}
`;
