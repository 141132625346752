import gql from 'graphql-tag';

export const PartnerDetailsFragment = gql`
  fragment PartnerDetailsFragment on PartnerDetails {
    id
    name
    contactName
    phone
    partnerEmail
    email1
    email2
    address
    postcode
    city
    country
    active
    partnerType
  }
`;
