import { get } from 'lodash/fp';
import { compile as compilePath } from 'path-to-regexp';

let urls = null;

export const createRoute = (path, defaultParams = {}) => {
  const toPath = compilePath(path);

  const fn = ({ anchor, ...urlParams } = {}) => {
    const compiledPath = toPath({ ...defaultParams, ...urlParams });

    return anchor
      ? `${compiledPath}#${encodeURIComponent(anchor)}`
      : compiledPath;
  };

  fn.path = path;

  return fn;
};

export const setupUrls = _urls => {
  urls = _urls;
};

const getInternal = name => {
  const value = get(name, urls);

  if (value === undefined) {
    throw new Error(`The url value '${name}' is not configured!`);
  }

  return value;
};

export const getUrl = (name, ...args) => {
  const value = getInternal(name);

  return value(...args);
};
