import { BatteryQuality, ExitCheckState } from 'graphqlTypes';

export const statesTranslated: Record<ExitCheckState, string> = {
  [ExitCheckState.Ordered]: __('exitCheck.list.state.ordered'),
  [ExitCheckState.Prepared]: __('exitCheck.list.state.prepared'),
  [ExitCheckState.RepairsNeeded]: __('exitCheck.list.state.repairs_needed'),
  [ExitCheckState.RepairsInProgress]: __(
    'exitCheck.list.state.repairs_in_progress',
  ),
  [ExitCheckState.RepairsCompleted]: __(
    'exitCheck.list.state.repairs_completed',
  ),
  [ExitCheckState.Completed]: __('exitCheck.list.state.completed'),
  [ExitCheckState.Cancelled]: __('exitCheck.list.state.cancelled'),
};

export const batteryQualityTranslated: Record<BatteryQuality, string> = {
  [BatteryQuality.Good]: __('GOOD'),
  [BatteryQuality.GoodRecharge]: __('GOOD_RECHARGE'),
  [BatteryQuality.Replace]: __('REPLACE'),
};
