import { Form } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';

interface Props {
  name: string;
  errors: object;
  className?: string;
}

const FieldError = ({ name, errors, className }: Props) => {
  return (
    <ErrorMessage
      name={name}
      errors={errors}
      as={
        <Form.Control.Feedback
          type="invalid"
          data-qa-selector={`${name}-error`}
          style={{ display: 'block' }}
          className={className}
        />
      }
    />
  );
};

export default FieldError;
