import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { Query, QueryEntryCheckArgs } from 'graphqlTypes';
import ENTRY_CHECK_STATE from './gql/entryCheckState';

const useGetEntryCheck = (
  refurbishmentId: string = '',
  options?: Omit<
    QueryHookOptions<Pick<Query, 'entryCheck'>, QueryEntryCheckArgs>,
    'variables'
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'entryCheck'>,
    QueryEntryCheckArgs
  >(ENTRY_CHECK_STATE, {
    fetchPolicy: 'cache-and-network',
    ...options,
    variables: { refurbishmentId },
    skip: options?.skip || !refurbishmentId,
  });

  return {
    state: data?.entryCheck?.state,
    tags: data?.entryCheck?.tags,
    ...rest,
  };
};

export default useGetEntryCheck;
