import { Query, QueryVehicleServiceCostSearchArgs } from 'graphqlTypes';
import VEHICLE_SERVICE_COST_SEARCH from './gql/vehicleServiceCostSearch';
import usePromiseQuery from '../helpers/usePromiseQuery';

const useVehicleServiceCostSearchPromise = () => {
  const query = usePromiseQuery<
    Pick<Query, 'vehicleServiceCostSearch'>,
    QueryVehicleServiceCostSearchArgs
  >(VEHICLE_SERVICE_COST_SEARCH, {
    fetchPolicy: 'no-cache',
  });

  const getVehicleServiceCost = async (
    variables: QueryVehicleServiceCostSearchArgs,
  ) => {
    const { data } = await query({
      ...variables,
    });

    return data?.vehicleServiceCostSearch;
  };

  return getVehicleServiceCost;
};

export default useVehicleServiceCostSearchPromise;
