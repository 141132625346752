import gql from 'graphql-tag';

import { VehicleServiceCostFragment } from './fragments';

export default gql`
  mutation createVehicleServiceCost(
    $vehicleServiceCostInput: VehicleServiceCostInput!
  ) {
    createVehicleServiceCost(
      vehicleServiceCostInput: $vehicleServiceCostInput
    ) {
      ...VehicleServiceCostFragment
    }
  }
  ${VehicleServiceCostFragment}
`;
