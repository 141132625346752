import { useApolloClient } from '@apollo/react-hooks';

import { MainWorkshop } from './mainWorkshop';

export const useSetCurrentMainWorkshop = () => {
  const client = useApolloClient();

  const setCurrentMainWorkshop = (mainWorkshop?: MainWorkshop) => {
    const currentMainWorkshop = mainWorkshop
      ? {
          ...mainWorkshop,
          __typename: 'CurrentMainWorkshop',
        }
      : null;

    client.writeData({
      data: {
        currentMainWorkshop,
      },
    });
  };

  return setCurrentMainWorkshop;
};
