import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateTaskItemStateArgs,
  RefurbishmentTaskItemExcludingCost,
  RefurbishmentTaskItemIncludingCost,
  RefurbishmentTaskItemState,
} from 'graphqlTypes';
import {
  RefurbishmentTaskItemExcludingCostFragment,
  RefurbishmentTaskItemIncludingCostFragment,
} from './gql/fragments';
import UPDATE_TASK_ITEMS_STATE from './gql/updateTaskItemState';

const useUpdateTaskItemState = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateTaskItemState'>,
    MutationUpdateTaskItemStateArgs
  >(UPDATE_TASK_ITEMS_STATE);

  const updateTaskItemState = (
    taskItemId: string,
    refurbishmentTaskItemState: RefurbishmentTaskItemState,
  ) => {
    return mutation({
      variables: { taskItemId, refurbishmentTaskItemState },
      update: (proxy, mutationResult) => {
        const status = mutationResult.data?.updateTaskItemState?.status;
        if (status === 'FAILED') return;

        const itemIncludingCost = proxy.readFragment<
          RefurbishmentTaskItemIncludingCost
        >({
          fragment: RefurbishmentTaskItemIncludingCostFragment,
          fragmentName: 'RefurbishmentTaskItemIncludingCostFragment',
          id: `RefurbishmentTaskItemIncludingCost:${taskItemId}`,
        });
        const itemExcludingCost = proxy.readFragment<
          RefurbishmentTaskItemExcludingCost
        >({
          fragment: RefurbishmentTaskItemExcludingCostFragment,
          fragmentName: 'RefurbishmentTaskItemExcludingCostFragment',
          id: `RefurbishmentTaskItemExcludingCost:${taskItemId}`,
        });

        if (itemIncludingCost) {
          proxy.writeFragment<RefurbishmentTaskItemIncludingCost>({
            fragment: RefurbishmentTaskItemIncludingCostFragment,
            fragmentName: 'RefurbishmentTaskItemIncludingCostFragment',
            id: `RefurbishmentTaskItemIncludingCost:${taskItemId}`,
            data: {
              ...itemIncludingCost,
              state: refurbishmentTaskItemState,
            },
          });
        } else if (itemExcludingCost) {
          proxy.writeFragment<RefurbishmentTaskItemExcludingCost>({
            fragment: RefurbishmentTaskItemExcludingCostFragment,
            fragmentName: 'RefurbishmentTaskItemExcludingCostFragment',
            id: `RefurbishmentTaskItemExcludingCost:${taskItemId}`,
            data: {
              ...itemExcludingCost,
              state: refurbishmentTaskItemState,
            },
          });
        }
      },
    });
  };

  return [
    updateTaskItemState,
    {
      data: data?.updateTaskItemState,
      ...rest,
    },
  ] as const;
};

export default useUpdateTaskItemState;
