import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateQualityCheckApprovalRefurbishmentItemArgs,
  RefurbishmentItemUpdateInput,
} from 'graphqlTypes';
import QUALITY_CHECK_APPROVAL from './gql/qualityCheckApproval';
import UPDATE_QUALITY_CHECK_APPROVAL_VEHICLE_ITEM from './gql/updateQualityCheckApprovalRefurbishmentItem';

const useUpdateQualityCheckApprovalRefurbishmentItem = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckApprovalRefurbishmentItem'>,
    MutationUpdateQualityCheckApprovalRefurbishmentItemArgs
  >(UPDATE_QUALITY_CHECK_APPROVAL_VEHICLE_ITEM);

  const updateQualityCheckApprovalRefurbishmentItem = (
    itemId: string,
    refurbishmentItemUpdateInput: RefurbishmentItemUpdateInput,
  ) => {
    const {
      wheelSetsSwitched,
      wheelSetDeleted,
      wheelSetDeletedReason,
      serviceBookType,
      serviceBookPresent,
    } = refurbishmentItemUpdateInput;

    return mutation({
      variables: {
        itemId,
        refurbishmentItemUpdateInput: {
          wheelSetsSwitched:
            wheelSetsSwitched !== null ? wheelSetsSwitched : undefined,
          wheelSetDeleted:
            wheelSetDeleted !== null ? wheelSetDeleted : undefined,
          wheelSetDeletedReason:
            wheelSetDeleted !== null ? wheelSetDeletedReason : undefined,
          serviceBookType:
            serviceBookType !== null ? serviceBookType : undefined,
          serviceBookPresent:
            serviceBookType !== null ? serviceBookPresent : undefined,
        },
      },
      refetchQueries: [
        {
          query: QUALITY_CHECK_APPROVAL,
          variables: {
            id: reviewId,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return [updateQualityCheckApprovalRefurbishmentItem, options] as const;
};

export default useUpdateQualityCheckApprovalRefurbishmentItem;
