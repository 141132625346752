import gql from 'graphql-tag';

export default gql`
  query damageTypes($carPartId: Int) {
    damageTypes(carPartId: $carPartId) {
      id
      name
    }
  }
`;
