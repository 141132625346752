import { useEffect, useRef } from 'react';

const useResizeContainer = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const setSizeContainer = () => {
      const container = containerRef.current;
      if (container) {
        const height = window.innerHeight - container.offsetTop;
        container.setAttribute('style', `height: ${height}px`);
      }
    };
    setSizeContainer();

    window.addEventListener('resize', setSizeContainer);

    return () => {
      window.removeEventListener('resize', setSizeContainer);
    };
  }, [containerRef.current]);

  return containerRef;
};

export default useResizeContainer;
