import gql from 'graphql-tag';

export default gql`
  query buildRepairMethodImportUploadCredentials(
    $awsImportUploadCredentialsInput: AwsImportUploadCredentialsInput!
  ) {
    buildRepairMethodImportUploadCredentials(
      awsImportUploadCredentialsInput: $awsImportUploadCredentialsInput
    ) {
      host
      cdn
      fileName
      formData
    }
  }
`;
