import { useQuery } from '@apollo/react-hooks';

import { Query, QueryRepairCostSearchArgs } from 'graphqlTypes';
import REPAIR_COST_SEARCH from './gql/repairCostSearch';

const useRepairCostSearch = (variables: QueryRepairCostSearchArgs) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'repairCostSearch'>,
    QueryRepairCostSearchArgs
  >(REPAIR_COST_SEARCH, {
    variables,
  });

  return {
    data: data?.repairCostSearch,
    ...rest,
  };
};

export default useRepairCostSearch;
