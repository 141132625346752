import { useMutation } from '@apollo/react-hooks';

import CREATE_DOCUMENT from './gql/createDocument';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';

const useCreateDocument = () => {
  const [mutation, result] = useMutation(CREATE_DOCUMENT);

  const createDocument = ({ id, ...rest }: any) =>
    mutation({
      ...rest,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REFURBISHMENT_DETAILS,
          variables: {
            id,
          },
        },
      ],
    });

  return [createDocument, result] as const;
};

export default useCreateDocument;
