import gql from 'graphql-tag';

export default gql`
  query entryCheck($refurbishmentId: UUID!) {
    entryCheck(refurbishmentId: $refurbishmentId) {
      __typename
      id
      state
      tags
    }
  }
`;
