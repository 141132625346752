import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryRefurbishmentDetailsArgs } from 'graphqlTypes';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetailsTransitionSet';

const useGetRefurbishmentDetailsTransitionSet = (
  id: string = '',
  options?: Omit<
    QueryHookOptions<
      Pick<Query, 'refurbishmentDetails'>,
      QueryRefurbishmentDetailsArgs
    >,
    'variables'
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'refurbishmentDetails'>,
    QueryRefurbishmentDetailsArgs
  >(REFURBISHMENT_DETAILS, {
    fetchPolicy: 'cache-and-network',
    ...options,
    variables: { id },
    skip: options?.skip || !id,
  });

  return {
    data: get('refurbishmentDetails', data),
    ...rest,
  };
};

export default useGetRefurbishmentDetailsTransitionSet;
