import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { ReactSelectInputProps } from 'components/Form/ReactSelectInput/ReactSelectInput';
import { useGetAllLocations } from 'modules/api/locations';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

type Props = Omit<
  ReactSelectInputProps,
  'name' | 'dataQa' | 'dataQaOption' | 'dataQaMultiValueContainer' | 'options'
> & {
  className?: string;
};

const LocationSelect = ({ className, ...rest }: Props) => {
  const { data, loading } = useGetAllLocations();
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'LocationSelect' must be used inside a 'Filter' component.",
    );
  }
  const { control } = formMethods;

  const options =
    data
      ?.map(item => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <div
      className={cns(cn.col, cn.input, className)}
      data-qa-selector="locations"
    >
      <ReactSelectInput
        {...rest}
        name={FormFilterNames.BRANCH}
        control={control}
        options={options}
        placeholder={__('filter.refurbishmentLocation')}
        isMulti
        isLoading={loading}
        equalFn={(value1, value2) => value1?.toString() === value2?.toString()}
        dataQa="filter_location"
        dataQaOption="filter_location_option"
        dataQaMultiValueContainer="filter_location_option_selected"
      />
    </div>
  );
};

export default LocationSelect;
