import { useMutation } from '@apollo/react-hooks';
import { DataProxy } from 'apollo-cache';

import {
  Mutation,
  MutationUpdateRefurbishmentArgs,
  Query,
  QueryQualityCheckReviewArgs,
  QueryRefurbishmentDetailsArgs,
} from 'graphqlTypes';
import GET_REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';
import UPDATE_REFURBISHMENT from './gql/updateRefurbishmentBeautyShotsTakenOn';
import QUALITY_REVIEW_CHECKS from '../qualityCheck/gql/qualityCheckReviewV2';

const useUpdateRefurbishmentBeautyShotsTakenOn = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateRefurbishment'>,
    MutationUpdateRefurbishmentArgs
  >(UPDATE_REFURBISHMENT);

  const updateRefurbishmentDetailsCache = (
    cache: DataProxy,
    refurbishmentId: string,
    updatedBeautyShotsTakenOn: Date | null | undefined,
  ) => {
    const refurbishment = cache.readQuery<
      Pick<Query, 'refurbishmentDetails'>,
      QueryRefurbishmentDetailsArgs
    >({
      query: GET_REFURBISHMENT_DETAILS,
      variables: {
        id: refurbishmentId,
      },
    });

    if (refurbishment) {
      cache.writeQuery<
        Pick<Query, 'refurbishmentDetails'>,
        QueryRefurbishmentDetailsArgs
      >({
        query: GET_REFURBISHMENT_DETAILS,
        data: {
          refurbishmentDetails: {
            ...refurbishment.refurbishmentDetails,
            beautyShotsTakenOn: updatedBeautyShotsTakenOn,
          },
        },
        variables: {
          id: refurbishmentId,
        },
      });
    }
  };

  const updateQualityCheckReviewCache = (
    cache: DataProxy,
    qualityCheckReviewId: string,
    updatedBeautyShotsTakenOn: Date | null | undefined,
  ) => {
    const qualityCheckReview = cache.readQuery<
      Pick<Query, 'qualityCheckReview'>,
      QueryQualityCheckReviewArgs
    >({
      query: QUALITY_REVIEW_CHECKS,
      variables: {
        id: qualityCheckReviewId,
      },
    });

    if (qualityCheckReview) {
      cache.writeQuery<
        Pick<Query, 'qualityCheckReview'>,
        QueryQualityCheckReviewArgs
      >({
        query: QUALITY_REVIEW_CHECKS,
        data: {
          qualityCheckReview: {
            ...qualityCheckReview.qualityCheckReview,
            refurbishment: {
              ...qualityCheckReview.qualityCheckReview.refurbishment,
              beautyShotsTakenOn: updatedBeautyShotsTakenOn,
            },
          },
        },
        variables: {
          id: qualityCheckReviewId,
        },
      });
    }
  };

  const updateRefurbishment = async (
    refurbishmentId: string,
    beautyShotsTakenOn: Date | null,
    qualityCheckReviewId?: string,
  ) =>
    mutation({
      variables: {
        id: refurbishmentId,
        input: {
          beautyShotsTakenOn,
        },
      },
      update(cache, { data: updatedData }) {
        if (updatedData) {
          const updatedBeautyShotsTakenOn =
            updatedData.updateRefurbishment.refurbishmentDetails
              .beautyShotsTakenOn;
          if (qualityCheckReviewId) {
            updateQualityCheckReviewCache(
              cache,
              qualityCheckReviewId,
              updatedBeautyShotsTakenOn,
            );
          } else {
            updateRefurbishmentDetailsCache(
              cache,
              refurbishmentId,
              updatedBeautyShotsTakenOn,
            );
          }
        }
      },
    });

  return [
    updateRefurbishment,
    {
      data: data?.updateRefurbishment,
      ...rest,
    },
  ] as const;
};

export default useUpdateRefurbishmentBeautyShotsTakenOn;
