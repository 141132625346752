import { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import cns from 'classnames';

import CostDetails from 'components/CostDetails';
import DataItem from 'components/UI/DataItem';
import { formatCurrency } from 'modules/i18n/currency';
import { calculateCostDetailsMajorUnits } from 'routes/RefurbishmentRoute/helpers/calculateCostDetails';
import DecimalInput from '../DecimalInput';
import FieldError from '../FieldError';
import PriceInputCurrency from '../PriceInputCurrency';

import cn from './CostDetailsInput.module.scss';

type Props = {
  laborTimeValidation?(value: string): string | boolean;
  laborRateMajorUnits: number;
  control: Control<any, object>;
  setValue: UseFormSetValue<Record<string, any>>;
  currencyCode: string;
  isExternal: boolean;
  errors: object;
  prefix?: string;
  onBlurHandler?(): void;
  suffix?: string;
  laborTimeSuffix?: string;
  disabled?: boolean;
  className?: string;
};

const CostDetailsInput = ({
  laborTimeValidation,
  laborRateMajorUnits,
  control,
  setValue,
  currencyCode,
  isExternal = false,
  errors,
  prefix = '',
  onBlurHandler,
  suffix = '',
  laborTimeSuffix = '',
  disabled,
  className,
}: Props) => {
  const consumableCost =
    useWatch({ name: `${prefix}consumableCost${suffix}`, control }) || 0;
  const sparePartCost =
    useWatch({ name: `${prefix}sparePartCost${suffix}`, control }) || 0;
  const laborTime =
    useWatch({ name: `${prefix}laborTime${laborTimeSuffix}`, control }) || 0;

  const { materialCost, laborCost, totalCost } = calculateCostDetailsMajorUnits(
    consumableCost,
    sparePartCost,
    laborTime,
    laborRateMajorUnits,
  );
  useEffect(() => {
    setValue(`${prefix}budgetMajorUnits`, totalCost);
  }, [totalCost]);

  useEffect(() => {
    setValue(`${prefix}laborRate${suffix}`, laborRateMajorUnits);
  }, [laborRateMajorUnits]);

  const hideLaborRate = isExternal && laborRateMajorUnits === 1;

  const laborCostLabel = isExternal
    ? __('refurbishment.externalLaborCosts')
    : __('refurbishment.laborCost');

  const laborRateLabel = isExternal
    ? __('refurbishment.externalLaborRate')
    : __('refurbishment.internalLaborRate');

  let laborTimeCostLabel;
  if (isExternal) {
    if (laborRateMajorUnits === 1) {
      laborTimeCostLabel = __('refurbishment.externalCost');
    } else {
      laborTimeCostLabel = __('refurbishment.externalLaborTime');
    }
  } else {
    laborTimeCostLabel = __('refurbishment.internalLaborTime');
  }

  return (
    <CostDetails
      splitCost={{
        materialCost: formatCurrency(materialCost, currencyCode),
        totalCost: formatCurrency(totalCost, currencyCode),
      }}
      className={cns(className, cn.costDetailsInput)}
    >
      <div
        className={cns(
          cn.material,
          {
            [cn.external]: isExternal,
            [cn.internal]: !isExternal,
          },
          'division division-material',
        )}
      >
        <div className={cn.label}>{__('refurbishment.costSplit.material')}</div>
        <div className={cn.line} />
      </div>

      <Form.Group controlId={`${prefix}consumableCost${suffix}`}>
        <Form.Label>{__('refurbishment.consumableCost')}</Form.Label>
        <PriceInputCurrency
          control={control}
          name={`${prefix}consumableCost${suffix}`}
          currencyCode={currencyCode}
          rules={{
            required: __('field.required'),
          }}
          onBlur={onBlurHandler}
          disabled={disabled}
          data-qa-selector={`${prefix}consumableCostEdit`}
        />
        <FieldError
          name={`${prefix}consumableCost${suffix}`}
          errors={errors}
          className={cn.error}
        />
      </Form.Group>

      <Form.Group controlId={`${prefix}sparePartCost${suffix}`}>
        <Form.Label>{__('refurbishment.sparePartCost')}</Form.Label>
        <PriceInputCurrency
          control={control}
          name={`${prefix}sparePartCost${suffix}`}
          currencyCode={currencyCode}
          rules={{
            required: __('field.required'),
          }}
          onBlur={onBlurHandler}
          disabled={disabled}
          data-qa-selector={`${prefix}sparePartCostEdit`}
        />
        <FieldError
          name={`${prefix}sparePartCost${suffix}`}
          errors={errors}
          className={cn.error}
        />
      </Form.Group>

      <div
        className={cns(
          cn.labor,
          {
            [cn.external]: isExternal,
            [cn.internal]: !isExternal,
          },
          'division division-labor',
        )}
      >
        <div className={cn.label}>
          {isExternal
            ? __('refurbishment.costSplit.external')
            : __('refurbishment.costSplit.internal')}
        </div>
        <div className={cn.line} />
      </div>

      <CostDetails.LaborCost label={laborCostLabel}>
        {formatCurrency(laborCost, currencyCode)}
      </CostDetails.LaborCost>

      <div>
        {!hideLaborRate && (
          <>
            <DataItem
              label={laborRateLabel}
              className={cns(cn.laborRate, {
                'text-danger': !laborRateMajorUnits,
              })}
              qaSelector="laborRate"
            >
              {formatCurrency(laborRateMajorUnits || 0, currencyCode)}
            </DataItem>

            {!laborRateMajorUnits && (
              <Form.Control.Feedback type="invalid" className={cn.error}>
                {__('refurbishment.laborRateMissing')}
              </Form.Control.Feedback>
            )}
          </>
        )}
      </div>

      <Form.Group controlId={`${prefix}laborTime${laborTimeSuffix}`}>
        <Form.Label>{laborTimeCostLabel}</Form.Label>
        {hideLaborRate ? (
          <PriceInputCurrency
            control={control}
            name={`${prefix}laborTime${laborTimeSuffix}`}
            currencyCode={currencyCode}
            rules={{
              required: __('field.required'),
              validate: laborTimeValidation,
            }}
            onBlur={onBlurHandler}
            disabled={disabled}
            data-qa-selector={`${prefix}externalCostEdit`}
          />
        ) : (
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{__('refurbishment.hours')}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              control={control}
              name={`${prefix}laborTime${laborTimeSuffix}`}
              rules={{
                required: __('field.required'),
                validate: laborTimeValidation,
              }}
              as={DecimalInput}
              onBlur={onBlurHandler}
              disabled={disabled}
              data-qa-selector={`${prefix}laborTimeEdit`}
            />
          </InputGroup>
        )}
        <FieldError
          name={`${prefix}laborTime${laborTimeSuffix}`}
          errors={errors}
          className={cn.error}
        />
      </Form.Group>

      <div
        className={cns(
          cn.total,
          {
            [cn.external]: isExternal,
            [cn.internal]: !isExternal,
          },
          'division division-total',
        )}
      >
        <div className={cn.label}>{__('refurbishment.costSplit.total')}</div>
        <div className={cn.line} />
      </div>
    </CostDetails>
  );
};

export default CostDetailsInput;
