import { useMutation } from '@apollo/react-hooks';

import {
  EntryCheckItemValueSourceType,
  Mutation,
  MutationDeleteEntryCheckItemValuesArgs,
  Query,
  QueryEntryCheckAuthorizationArgs,
  QueryEntryCheckPostAuthorizationArgs,
} from 'graphqlTypes';
import DELETE_ENTRY_CHECK_ITEM_VALUES from './gql/deleteEntryCheckItemValues';
import ENTRY_CHECK_AUTHORIZATION from './gql/entryCheckAuthorization';
import ENTRY_CHECK_POST_AUTHORIZATION from './gql/entryCheckPostAuthorization';

export default () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'deleteEntryCheckItemValues'>,
    MutationDeleteEntryCheckItemValuesArgs
  >(DELETE_ENTRY_CHECK_ITEM_VALUES);

  const deleteEntryCheckItemValues = (
    variables: MutationDeleteEntryCheckItemValuesArgs & {
      refurbishmentId: string;
      sourceType: EntryCheckItemValueSourceType;
    },
  ) =>
    mutation({
      variables,
      update(cache, { data }) {
        if (data && data.deleteEntryCheckItemValues) {
          const { refurbishmentId, ids, sourceType } = variables;

          if (sourceType === EntryCheckItemValueSourceType.Authorization) {
            const entryCheckAuth = cache.readQuery<
              Pick<Query, 'entryCheckAuthorization'>,
              QueryEntryCheckAuthorizationArgs
            >({
              query: ENTRY_CHECK_AUTHORIZATION,
              variables: { refurbishmentId },
            });

            if (entryCheckAuth) {
              const items = entryCheckAuth.entryCheckAuthorization!.items.map(
                item => ({
                  ...item,
                  values: item.values.filter(value => !ids.includes(value.id)),
                }),
              );

              cache.writeQuery<
                Pick<Query, 'entryCheckAuthorization'>,
                QueryEntryCheckAuthorizationArgs
              >({
                query: ENTRY_CHECK_AUTHORIZATION,
                data: {
                  entryCheckAuthorization: {
                    ...entryCheckAuth.entryCheckAuthorization!,
                    items,
                  },
                },
                variables: {
                  refurbishmentId,
                },
              });
            }
          } else if (
            sourceType === EntryCheckItemValueSourceType.PostAuthorization
          ) {
            const entryCheckPostAuth = cache.readQuery<
              Pick<Query, 'entryCheckPostAuthorization'>,
              QueryEntryCheckPostAuthorizationArgs
            >({
              query: ENTRY_CHECK_POST_AUTHORIZATION,
              variables: { refurbishmentId },
            });

            if (entryCheckPostAuth) {
              const items = entryCheckPostAuth.entryCheckPostAuthorization!.items.map(
                item => ({
                  ...item,
                  values: item.values.filter(value => !ids.includes(value.id)),
                }),
              );

              cache.writeQuery<
                Pick<Query, 'entryCheckPostAuthorization'>,
                QueryEntryCheckPostAuthorizationArgs
              >({
                query: ENTRY_CHECK_POST_AUTHORIZATION,
                data: {
                  entryCheckPostAuthorization: {
                    ...entryCheckPostAuth.entryCheckPostAuthorization!,
                    items,
                  },
                },
                variables: {
                  refurbishmentId,
                },
              });
            }
          }
        }
      },
    });

  return [deleteEntryCheckItemValues, options] as const;
};
