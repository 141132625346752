import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import {
  Mutation,
  MutationUpdateVehicleArgs,
  VehicleUpdateInput,
} from 'graphqlTypes';
import UPDATE_VEHICLE from './gql/updateVehicle';

const useUpdateVehicle = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateVehicle'>,
    MutationUpdateVehicleArgs
  >(UPDATE_VEHICLE);

  const updateVehicle = (vehicleUpdateInput: VehicleUpdateInput) =>
    mutation({ variables: { vehicleUpdateInput } });

  return [
    updateVehicle,
    {
      data: get('updateVehicle', data),
      ...rest,
    },
  ] as const;
};

export default useUpdateVehicle;
