import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import CustomModal from 'components/UI/CustomModal';
import { QueryActiveGroupedPartnerWorkshopConnectionsArgs } from 'graphqlTypes';
import { useCreatePartnerWorkshopConnections } from 'modules/api/partnerWorkshop';
import { useLogger } from 'modules/logger';
import PartnerWorkshopForm, {
  PartnerWorkshopFormData,
} from '../PartnerWorkshopForm';

type Props = {
  partnerWorkshopConnectionArgs: QueryActiveGroupedPartnerWorkshopConnectionsArgs;
};

const AddConnection = ({ partnerWorkshopConnectionArgs }: Props) => {
  const { logError } = useLogger();
  const [show, setShow] = useState(false);
  const [
    createConnections,
    { loading },
  ] = useCreatePartnerWorkshopConnections();

  const handleOpen = () => setShow(true);

  const handleClose = () => setShow(false);

  const handleSubmit = async (values: PartnerWorkshopFormData) => {
    try {
      await createConnections(values, partnerWorkshopConnectionArgs);
    } catch (error) {
      logError({ error });
    }

    setShow(false);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleOpen}
        data-qa-selector="add_new_connections"
      >
        {__('partnerWorkshopConnections.addNewConnections')}
      </Button>
      <CustomModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {__('partnerWorkshopConnections.addNewConnections')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PartnerWorkshopForm
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={handleClose}
          />
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default AddConnection;
