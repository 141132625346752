import { useEffect } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import { Role } from 'graphqlTypes';
import { useCreateGroup } from 'modules/api/groups';
import { useGetRoles } from 'modules/api/roles';
import { useLogger } from 'modules/logger';
import { getUrl } from 'modules/routeUtils';

type FormData = {
  name: string;
  description: string;
  roles: string[];
};

const NewRoute = () => {
  const { logError } = useLogger();
  const { data: allRoles, loading, error: errorRoles } = useGetRoles();
  const { register, handleSubmit } = useForm<FormData>();
  const [createGroup, { data, error }] = useCreateGroup();
  const navigate = useNavigate();

  const onSubmit = (formData: FormData) => {
    const { name, description, roles } = formData;

    try {
      createGroup({ name, roles, description });
    } catch (e) {
      logError({ error });
    }
  };

  useEffect(() => {
    if (error) {
      logError({ error });
    }

    if (data) {
      const { id } = data;

      navigate(getUrl('GROUP_DETAIL', { id }));
    }
  }, [error, data]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (errorRoles) {
    return <p>Error</p>;
  }

  return (
    <PageContainer>
      <PageHeader title={__('group.new.title')} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="name">
          <Form.Label>{__('group.new.name')}:</Form.Label>
          <Form.Control type="text" {...register('name')} />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>{__('group.new.description')}:</Form.Label>
          <Form.Control type="text" {...register('description')} />
        </Form.Group>
        <h3 className="title-section">{__('group.detail.roles')}</h3>
        <Table borderless>
          <tbody>
            {allRoles &&
              allRoles.map((role: Role) => (
                <tr key={role.id} className="border-bottom">
                  <td className="w-25">
                    <Form.Check
                      type="checkbox"
                      id={`role-${role.id}`}
                      value={role.id}
                      {...register('roles')}
                      custom
                      className="font-weight-bold"
                      label={role.name}
                    />
                  </td>
                  <td className="w-75">{role.description}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="text-right">
          <Button variant="primary" type="submit">
            {__('action.create')}
          </Button>
        </div>
      </Form>
    </PageContainer>
  );
};

export default NewRoute;
