import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { get } from 'lodash/fp';

import {
  EntryCheckItemCreateInput,
  Mutation,
  MutationCreateEntryCheckItemsArgs,
  Query,
  QueryEntryCheckAuthorizationArgs,
} from 'graphqlTypes';
import CREATE_ENTRY_CHECK_ITEMS_FOR_AUTHORIZATION from './gql/createEntryCheckItemsForAuthorization';
import ENTRY_CHECK_AUTHORIZATION from './gql/entryCheckAuthorization';

export type CreateEntryCheckItemsForAuthorizationPromise = Promise<
  ExecutionResult<Pick<Mutation, 'createEntryCheckItemsForAuthorization'>>
>;

const useCreateEntryCheckItemsForAuthorization = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createEntryCheckItemsForAuthorization'>,
    MutationCreateEntryCheckItemsArgs
  >(CREATE_ENTRY_CHECK_ITEMS_FOR_AUTHORIZATION);

  const createEntryCheckItem = (
    inputs: EntryCheckItemCreateInput[],
    refurbishmentId: string,
  ) =>
    mutation({
      variables: { inputs },
      update(cache, { data: updatedData }) {
        const entryCheck = cache.readQuery<
          Pick<Query, 'entryCheckAuthorization'>,
          QueryEntryCheckAuthorizationArgs
        >({
          query: ENTRY_CHECK_AUTHORIZATION,
          variables: {
            refurbishmentId,
          },
        });

        if (entryCheck && updatedData) {
          cache.writeQuery<
            Pick<Query, 'entryCheckAuthorization'>,
            QueryEntryCheckAuthorizationArgs
          >({
            query: ENTRY_CHECK_AUTHORIZATION,
            data: {
              entryCheckAuthorization: {
                ...entryCheck.entryCheckAuthorization!,
                items: [
                  ...entryCheck.entryCheckAuthorization!.items,
                  ...updatedData.createEntryCheckItemsForAuthorization,
                ],
              },
            },
            variables: {
              refurbishmentId,
            },
          });
        }
      },
    });

  return [
    createEntryCheckItem,
    {
      data: get('createEntryCheckItemsForAuthorization', data),
      ...rest,
    },
  ] as const;
};

export default useCreateEntryCheckItemsForAuthorization;
