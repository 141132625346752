import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationCreateCommentArgs } from 'graphqlTypes';
import CREATE_COMMENT from './gql/createComment';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';

const useCreateComment = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'createComment'>,
    MutationCreateCommentArgs
  >(CREATE_COMMENT);

  const createComment = ({ refId, ...rest }: any) => {
    const refetchQueries = rest.refetchQueries || [];
    return mutation({
      ...rest,
      refetchQueries: refetchQueries.concat([
        {
          query: REFURBISHMENT_DETAILS,
          variables: {
            id: refId,
          },
        },
      ]),
    });
  };

  return [createComment, options] as const;
};

export default useCreateComment;
