import { useMutation } from '@apollo/react-hooks';

import {
  DamageItemCreateInputV2,
  Mutation,
  MutationCreateQualityCheckDamageItemV2Args,
} from 'graphqlTypes';
import CREATE_QUALITY_CHECK_DAMAGE_ITEM_V2 from './gql/createQualityCheckDamageItemV2';
import QUALITY_CHECK_REVIEW from './gql/qualityCheckReviewV2';

const useCreateQualityCheckDamageItemV2 = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'createQualityCheckDamageItemV2'>,
    MutationCreateQualityCheckDamageItemV2Args
  >(CREATE_QUALITY_CHECK_DAMAGE_ITEM_V2);

  const createQualityCheckDamageItem = (
    damageItemCreateInput: DamageItemCreateInputV2,
  ) => {
    return mutation({
      variables: {
        damageItemCreateInput,
      },
      refetchQueries: reviewId
        ? [
            {
              query: QUALITY_CHECK_REVIEW,
              variables: {
                id: reviewId,
              },
            },
          ]
        : undefined,
    });
  };

  return [createQualityCheckDamageItem, options] as const;
};

export default useCreateQualityCheckDamageItemV2;
