import React from 'react';
import { Helmet } from 'react-helmet';

import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import { ACL, Role } from 'modules/acl';
import { useGetAllLocations } from 'modules/api/locations';
import { useGetAllPartners } from 'modules/api/partners';
import RepairCostImport from './components/RepairCostImport';
import RepairCostSearch from './components/RepairCostSearch';
import RepairCostTable from './components/RepairCostTable';

const RepairCostRoute = () => {
  const {
    data: allLocations,
    loading: allLocationsLoading,
  } = useGetAllLocations();
  const {
    data: allPartners,
    loading: allPartnersLoading,
  } = useGetAllPartners();

  if (
    allLocationsLoading ||
    allPartnersLoading ||
    !allLocations ||
    !allPartners
  ) {
    return <LoadingIndicator />;
  }

  return (
    <PageContainer fluid>
      <Helmet>
        <title>{__('repairCost.title')}</title>
      </Helmet>
      <PageHeader
        title={__('repairCost.title')}
        subTitle={__('repairCost.subTitle')}
        actions={
          <ACL roles={Role.REPAIR_COST_ADMIN}>
            <RepairCostImport />
          </ACL>
        }
      />
      <RepairCostSearch allLocations={allLocations} allPartners={allPartners} />
      <RepairCostTable />
    </PageContainer>
  );
};

export default RepairCostRoute;
