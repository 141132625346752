import { sortBy } from 'lodash/fp';

import { Group, User } from 'graphqlTypes';

export default (data: Group) => {
  const sortedUsers = sortBy<User>('firstName')(data.users);

  return {
    ...data,
    users: sortedUsers,
  };
};
