import React, { useEffect, useState } from 'react';
import IconClear from '@mui/icons-material/Clear';
import IconDone from '@mui/icons-material/Done';

import IconButton from 'components/UI/IconButton';
import NotApplicableIcon from 'components/UI/NotApplicableIcon';
import { OptionalBooleanType } from 'graphqlTypes';

type Props = {
  value: OptionalBooleanType | null;
  editDisabled: boolean;
  displayNotApplicable: boolean;
  notApplicableDisabled?: boolean;
  onAcceptSelect?: () => void;
  onDeclineSelect?: () => void;
  onNotApplicableSelect?: () => void;
  loading?: boolean;
};

const CheckButton = ({
  value,
  editDisabled,
  displayNotApplicable,
  notApplicableDisabled = false,
  onAcceptSelect,
  onDeclineSelect,
  onNotApplicableSelect,
  loading = false,
}: Props) => {
  const [state, setState] = useState<OptionalBooleanType | null>();
  const isYesSelected = value === OptionalBooleanType.Yes;
  const isNoSelected = value === OptionalBooleanType.No;
  const isNoAcceptableSelected = value === OptionalBooleanType.NoAcceptable;

  useEffect(() => {
    if (!loading) {
      setState(null);
    }
  }, [loading]);

  return (
    <>
      {displayNotApplicable ? (
        <IconButton
          icon={NotApplicableIcon}
          className="ml-4"
          selected={isNoAcceptableSelected}
          disabled={
            editDisabled || isNoAcceptableSelected || notApplicableDisabled
          }
          readOnly={editDisabled || notApplicableDisabled}
          loading={loading && state === OptionalBooleanType.NoAcceptable}
          data-qa-selector="notApplicable"
          onClick={() => {
            if (onNotApplicableSelect && !notApplicableDisabled) {
              setState(OptionalBooleanType.NoAcceptable);
              onNotApplicableSelect();
            }
          }}
        />
      ) : null}
      <IconButton
        icon={IconClear}
        selected={isNoSelected}
        disabled={editDisabled || isNoSelected}
        readOnly={editDisabled}
        loading={loading && state === OptionalBooleanType.No}
        data-qa-selector="decline"
        onClick={() => {
          if (onDeclineSelect) {
            setState(OptionalBooleanType.No);
            onDeclineSelect();
          }
        }}
      />
      <IconButton
        icon={IconDone}
        selected={isYesSelected}
        disabled={editDisabled || isYesSelected}
        readOnly={editDisabled}
        loading={loading && state === OptionalBooleanType.Yes}
        data-qa-selector="accept"
        onClick={() => {
          if (onAcceptSelect) {
            setState(OptionalBooleanType.Yes);
            onAcceptSelect();
          }
        }}
      />
    </>
  );
};

export default CheckButton;
