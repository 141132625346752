import { useMutation } from '@apollo/react-hooks';

import {
  InformMessage,
  Mutation,
  MutationUpdateInformMessageArgs,
} from 'graphqlTypes';
import { informMessageFragment } from './gql/searchInformMessages';
import UPDATE_INFORM_MESSAGE from './gql/updateInformMessage';

const useUpdateInformMessage = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateInformMessage'>,
    MutationUpdateInformMessageArgs
  >(UPDATE_INFORM_MESSAGE);

  const updateInformMessage = (variables: MutationUpdateInformMessageArgs) => {
    return mutation({
      variables,
      update: (cache, { data: updatedData }) => {
        if (updatedData) {
          const updatedInformMessage = updatedData.updateInformMessage;

          cache.writeFragment<InformMessage>({
            id: `InformMessage:${variables.id}`,
            fragment: informMessageFragment,
            data: updatedInformMessage,
          });
        }
      },
    });
  };

  return [
    updateInformMessage,
    {
      data: data?.updateInformMessage,
      ...rest,
    },
  ] as const;
};

export default useUpdateInformMessage;
