import { Button } from 'react-bootstrap';

import FormButtonModal from 'components/Form/FormButtonModal/FormButtonsModal';
import { ImportTask } from 'graphqlTypes';
import useCancelRepairMethodImport from 'modules/api/costTable/useCancelRepairMethodImport';
import useStartRepairMethodImport from 'modules/api/costTable/useStartRepairMethodImport';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { ImportRepairMethodPhase } from './ImportRepairMethodModal';
import ImportRepairMethodStatistics from './ImportRepairMethodStatistics';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairMethodPhase>>;
  getRepairMethodImportTask: (uuid: string) => void;
  repairMethodImportTaskData?: ImportTask;
};

const ImportRepairMethodProceed = ({
  closeModal,
  setActivePhase,
  getRepairMethodImportTask,
  repairMethodImportTaskData,
}: Props) => {
  const { logError } = useLogger();
  const { error } = useNotification();

  const [
    startRepairMethodImport,
    // { startRepairMethodImportData }, TODO
  ] = useStartRepairMethodImport();
  const [cancelRepairMethodImport] = useCancelRepairMethodImport();

  const proceedWithImport = async () => {
    try {
      setActivePhase(ImportRepairMethodPhase.IMPORT_LOADING);
      const id = repairMethodImportTaskData?.id || '';

      await startRepairMethodImport(id);
      getRepairMethodImportTask(id);
    } catch (errorData) {
      logError({ error: errorData });
      error(__('refurbishment.detail.failedImport'));
    }
  };

  const cancelImport = async () => {
    const id = repairMethodImportTaskData?.id || '';

    try {
      await cancelRepairMethodImport(id);
      setActivePhase(ImportRepairMethodPhase.UPLOADING);
      closeModal();
    } catch (errorData) {
      logError({ error: errorData });
      error(__('refurbishment.detail.cancelImportFailed'));
      setActivePhase(ImportRepairMethodPhase.PROCEEDING_WITH_IMPORT);
    }
  };

  return (
    <>
      <h3>{__('repairMethod.proceedWithImport')}</h3>
      <ImportRepairMethodStatistics
        statistics={repairMethodImportTaskData?.statistics}
      />
      <FormButtonModal>
        <Button
          variant="secondary"
          onClick={cancelImport}
          data-qa-selector="closeImportRepairMethodModal"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="ml-4"
          data-qa-selector="proceedActionRepairMethodModal"
          onClick={proceedWithImport}
        >
          {__('repairMethod.proceedAction')}
        </Button>
      </FormButtonModal>
    </>
  );
};

export default ImportRepairMethodProceed;
