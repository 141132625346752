import {
  BrakeCheckpointsGraphQlJsonPayload,
  BrakeCheckpointsPayloadTypes,
} from 'routes/RefurbishmentRoute/helpers/mapData/getCheckpoints';
import { EntryCheckItem, WheelPosition } from 'routes/RefurbishmentRoute/types';

export const getBrakeCheckpoints = (
  position: WheelPosition,
  items?: EntryCheckItem[],
) =>
  items
    ?.filter(item => BrakeCheckpointsPayloadTypes.includes(item.payloadType))
    .filter(item => {
      const value =
        item.valuesExtended[0]?.proposedValue ||
        item.valuesExtended[0]?.originalValue;
      return (
        (value as BrakeCheckpointsGraphQlJsonPayload)?.position === position
      );
    });
