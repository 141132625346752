import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationAssignUserPartnerWorkshopConnectionPermissionsArgs,
} from 'graphqlTypes';
import ASSIGN_USER_PARTNER_WORKSHOP_CONNECTION_PERMISSIONS from './gql/assignUserPartnerWorkshopConnectionPermissions';
import USER from './gql/user';

const useAssignUserPartnerWorkshopConnection = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'assignUserPartnerWorkshopConnectionPermissions'>,
    MutationAssignUserPartnerWorkshopConnectionPermissionsArgs
  >(ASSIGN_USER_PARTNER_WORKSHOP_CONNECTION_PERMISSIONS);

  const assignUserPartnerWorkshopConnectionPermissions = (
    variables: MutationAssignUserPartnerWorkshopConnectionPermissionsArgs,
  ) =>
    mutation({
      variables,
      refetchQueries: [
        {
          query: USER,
          variables: {
            id: variables.userId,
          },
        },
      ],
    });

  return [
    assignUserPartnerWorkshopConnectionPermissions,
    {
      data: data?.assignUserPartnerWorkshopConnectionPermissions,
      ...rest,
    },
  ] as const;
};

export default useAssignUserPartnerWorkshopConnection;
