import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryQualityChecksArgs } from 'graphqlTypes';
import QUALITY_CHECKS from './gql/qualityChecks';

const useQualityChecks = (
  refurbishmentId: string = '',
  options?: QueryHookOptions<
    Pick<Query, 'qualityChecks'>,
    QueryQualityChecksArgs
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'qualityChecks'>,
    QueryQualityChecksArgs
  >(QUALITY_CHECKS, {
    variables: { refurbishmentId },
    ...options,
    skip: options?.skip || !refurbishmentId,
  });

  return {
    data: get('qualityChecks', data),
    ...rest,
  };
};

export default useQualityChecks;
