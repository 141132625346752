import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from 'modules/api/partnerWorkshop/gql/fragments';
import { PartnerWorkshopConnectionRefurbishmentResponseFragment } from 'modules/api/refurbishments/gql/fragments';
import { CommentFragment } from '../../common/gql/fragments';

export const DocumentFragment = gql`
  fragment DocumentFragment on Document {
    id
    attachedTo
    createdOn
    state
    sourceType
    url
    fileName
    description
    type
    createdBy {
      id
      firstName
      lastName
      refurbishmentProvidersData {
        location {
          id
          name
        }
        partner {
          id
          name
        }
      }
    }
  }
`;

export const WheelSetFragmentEntryCheck = gql`
  fragment WheelSetFragmentEntryCheck on WheelSet {
    isPrimary
    wheels {
      position
      season
      profile {
        depth
        unit
      }
      rimType
    }
  }
`;

export const VehicleFragmentEntryCheck = gql`
  fragment VehicleFragmentEntryCheck on Vehicle {
    vin
    stockNumber
    make
    model
    subModel
    registration
    mileage {
      distance
      unit
    }
    fuelType
    gearType
    lastServiceOn
    lastServiceMileage {
      distance
      unit
    }
    inspectionExpiryDate
    sourcingCountry
    wheelSets {
      ...WheelSetFragmentEntryCheck
    }
    registrationUrl
    serviceBookImageUrls
    technicalInspectionCertificateUrls
    technicalInspectionCertificateIssueDate
    technicalInspectionCertificateIssueMileage {
      distance
      unit
    }
    color {
      outside
      metallic
    }
    builtYear
    modelCode
    licensePlate
    manufacturerCode
    electricBatteryDetails {
      isLeased
      leasingCost
      hasConditionReport
      conditionStatus
      capacity
      range
      batteryDocumentUrl
    }
    source {
      value
      name
      evaluationType {
        value
        name
      }
    }
    bodyType
    doorCount
  }
  ${WheelSetFragmentEntryCheck}
`;

export const TransitionECFragment = gql`
  fragment TransitionECFragment on Transition {
    id
    stateTo
    transitionDate
    sourceType
  }
`;

export const EntryCheckLocationFragment = gql`
  fragment EntryCheckLocationFragment on Location {
    id
    name
  }
`;

export const RefurbishmentEtaFragment = gql`
  fragment RefurbishmentEtaFragment on RefurbishmentEta {
    id
    refurbishmentId
    comment
    eta
    createdOn
    createdBy {
      id
    }
  }
`;

export const VehicleInspectionFragment = gql`
  fragment VehicleInspectionFragment on VehicleInspection {
    inspectionNeeded
    doneBeforeEntryCheck
    carPassInspection
    lastInspectionDate
    lastMileage {
      distance
      unit
    }
    lastInspectionDueDate
  }
`;

export const KeysCountPayloadFragment = gql`
  fragment KeysCountPayloadFragment on KeysCountPayload {
    keysCount
  }
`;

export const ServiceBookPayloadFragment = gql`
  fragment ServiceBookPayloadFragment on ServiceBookPayload {
    serviceBookType
    serviceBookPresent
  }
`;

export const EntryCheckRefurbishmentDetailsFragment = gql`
  fragment EntryCheckRefurbishmentDetailsFragment on RefurbishmentDetails {
    id
    refId
    adId
    retailReady
    state
    country
    currencyCode
    cancelReason
    cancelDescription
    completedReason
    picturesReceivedOn
    picturesUploadedOn
    lastStateUpdatedOn
    daysInCurrentState
    daysSincePreparationStarted
    estimatedCompleteDate
    estimatedTotalCostMinorUnits
    providerEstimatedCompleteDate
    providerTotalCostMinorUnits
    pricingBudgetCostMinorUnits
    minimalGrossProfitMinorUnits
    targetGrossProfitMinorUnits
    transitionCurrentSet {
      ...TransitionECFragment
    }
    comments {
      ...CommentFragment
    }
    vehicle {
      ...VehicleFragmentEntryCheck
    }
    documents {
      ...DocumentFragment
    }
    location {
      ...EntryCheckLocationFragment
    }
    flowVersion
    beautyShot
    picturesReceivedOn
    refurbishmentEtas {
      ...RefurbishmentEtaFragment
    }
    vehicleInspection {
      ...VehicleInspectionFragment
    }
    currentPartnerWorkshopConnection {
      ...PartnerWorkshopConnectionFragment
    }
    partnerWorkshopConnections {
      ...PartnerWorkshopConnectionRefurbishmentResponseFragment
    }
    keysCount
    serviceBookType
    serviceBookPresent
    tags
  }
  ${CommentFragment}
  ${VehicleFragmentEntryCheck}
  ${TransitionECFragment}
  ${DocumentFragment}
  ${EntryCheckLocationFragment}
  ${RefurbishmentEtaFragment}
  ${VehicleInspectionFragment}
  ${PartnerWorkshopConnectionFragment}
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
`;

export const FuelTypePayloadFragment = gql`
  fragment FuelTypePayloadFragment on FuelTypePayload {
    fuelType
  }
`;

export const MileagePayloadFragment = gql`
  fragment MileagePayloadFragment on MileagePayload {
    payloadType
    distance
    mileageUnit: unit
  }
`;

export const WheelSeasonPayloadFragment = gql`
  fragment WheelSeasonPayloadFragment on WheelSeasonPayload {
    season
    isSecondaryWheel
    wheelSeasonPosition: position
  }
`;

export const WheelProfilePayloadFragment = gql`
  fragment WheelProfilePayloadFragment on WheelProfilePayload {
    depth
    wheelProfileUnit: unit
    isSecondaryWheel
    wheelProfilePosition: position
  }
`;

export const WheelRimTypePayloadFragment = gql`
  fragment WheelRimTypePayloadFragment on WheelRimTypePayload {
    rimType
    isSecondaryWheel
    wheelRimTypePosition: position
  }
`;

export const WheelSizePayloadFragment = gql`
  fragment WheelSizePayloadFragment on WheelSizePayload {
    wheelSizeValue: value
    wheelSizeUnit: unit
    height
    isSecondaryWheel
    wheelSizePosition: position
  }
`;

export const WheelRimSizePayloadFragment = gql`
  fragment WheelRimSizePayloadFragment on RimSizePayload {
    wheelRimSizeValue: value
    wheelRimSizeUnit: unit
    isSecondaryWheel
    wheelRimSizePosition: position
  }
`;

export const WheelSpeedIndexPayloadFragment = gql`
  fragment WheelSpeedIndexPayloadFragment on WheelSpeedIndexPayload {
    speedIndex
    isSecondaryWheel
    wheelSpeedIndexPosition: position
  }
`;

export const WheelLoadIndexPayloadFragment = gql`
  fragment WheelLoadIndexPayloadFragment on WheelLoadIndexPayload {
    loadIndex
    isSecondaryWheel
    wheelLoadIndexPosition: position
  }
`;

export const WheelManufacturerPayloadFragment = gql`
  fragment WheelManufacturerPayloadFragment on WheelManufacturerPayload {
    manufacturer
    isSecondaryWheel
    wheelManufacturerPosition: position
  }
`;

export const WheelDotPayloadFragment = gql`
  fragment WheelDotPayloadFragment on WheelDotPayload {
    dot
    isSecondaryWheel
    wheelDotPosition: position
  }
`;

export const TirePressurePayloadFragment = gql`
  fragment TirePressurePayloadFragment on TirePressurePayload {
    valueMinorUnits
    unit
    isSecondaryWheel
    position
  }
`;

export const WheelImagePayloadFragment = gql`
  fragment WheelImagePayloadFragment on WheelImagePayload {
    wheelImageUrl: imageUrl
    isSecondaryWheel
    wheelImagePosition: position
  }
`;

const WheelSetsSwitchPayloadFragment = gql`
  fragment WheelSetsSwitchPayloadFragment on WheelSetsSwitchPayload {
    switched
  }
`;

const WheelSetDeletePayloadFragment = gql`
  fragment WheelSetDeletePayloadFragment on WheelSetDeletePayload {
    deleted
    reason
  }
`;

export const BrakeDamagePayloadFragment = gql`
  fragment BrakeDamagePayloadFragment on BrakeDamagePayload {
    area
    subArea
    typeName
  }
`;

export const DamageTypePayloadFragment = gql`
  fragment DamageTypePayloadFragment on DamageTypePayload {
    area
    subArea
    partName
    typeName
    isSecondaryWheel
    displaySection
    isPA3Damage
    attribute1Name
    attribute1Value
    attribute2Name
    attribute2Value
    quantity
  }
`;

export const DamageImagePayloadFragment = gql`
  fragment DamageImagePayloadFragment on DamageImagePayload {
    photoId
    imageUrl
  }
`;

export const DamageServiceNamePayloadFragment = gql`
  fragment DamageServiceNamePayloadFragment on DamageServiceNamePayload {
    name
    type
  }
`;

export const VehicleServiceNamePayloadFragment = gql`
  fragment VehicleServiceNamePayloadFragment on VehicleServiceNamePayload {
    name
    type
    isAutoCreate
  }
`;

export const ServiceBudgetPayloadFragment = gql`
  fragment ServiceBudgetPayloadFragment on ServiceBudgetPayload {
    budgetMinorUnits
    consumableCostMinorUnits
    sparePartCostMinorUnits
    laborRateMinorUnits
    laborTimeHours
  }
`;

export const ServiceCommentPayloadFragment = gql`
  fragment ServiceCommentPayloadFragment on ServiceCommentPayload {
    comment
  }
`;
export const DamageCustomerDisplayPayloadFragment = gql`
  fragment DamageCustomerDisplayPayloadFragment on DamageCustomerDisplayPayload {
    customerDisplay
  }
`;
export const ServiceCustomerDisplayPayloadFragment = gql`
  fragment ServiceCustomerDisplayPayloadFragment on ServiceCustomerDisplayPayload {
    customerDisplay
  }
`;

export const DamageCancellationPayloadFragment = gql`
  fragment DamageCancellationPayloadFragment on DamageCancellationPayload {
    cancelReason
    cancelComment
  }
`;

export const BeautyShotPayloadFragment = gql`
  fragment BeautyShotPayloadFragment on BeautyShotPayload {
    beautyShot
  }
`;

export const SmokerPayloadFragment = gql`
  fragment SmokerPayloadFragment on SmokerPayload {
    isSmoker
  }
`;

export const AccidentCarPayloadFragment = gql`
  fragment AccidentCarPayloadFragment on AccidentCarPayload {
    isAccidentCar
  }
`;

export const OriginalEnginePayloadFragment = gql`
  fragment OriginalEnginePayloadFragment on OriginalEnginePayload {
    hasOriginalEngine
  }
`;

export const OriginalUpholsteryPayloadFragment = gql`
  fragment OriginalUpholsteryPayloadFragment on OriginalUpholsteryPayload {
    hasOriginalUpholstery
  }
`;

export const WasInCommercialUsePayloadFragment = gql`
  fragment WasInCommercialUsePayloadFragment on WasInCommercialUsePayload {
    wasInCommercialUse
  }
`;

export const HoodReleasePayloadFragment = gql`
  fragment HoodReleasePayloadFragment on HoodReleasePayload {
    doesHoodRelease
  }
`;

export const BlanketInsulatorPayloadFragment = gql`
  fragment BlanketInsulatorPayloadFragment on BlanketInsulatorPayload {
    hasBlanketInsulator
  }
`;

export const OilCoolerPayloadFragment = gql`
  fragment OilCoolerPayloadFragment on OilCoolerPayload {
    isOilCoolerConditionOk
  }
`;

export const AlternatorPayloadFragment = gql`
  fragment AlternatorPayloadFragment on AlternatorPayload {
    isAlternatorConditionOk
  }
`;

export const WindshieldFluidLevelPayloadFragment = gql`
  fragment WindshieldFluidLevelPayloadFragment on WindshieldFluidLevelPayload {
    isWindshieldFluidLevelOk
  }
`;

export const PowerSteeringPayloadFragment = gql`
  fragment PowerSteeringPayloadFragment on PowerSteeringPayload {
    isPowerSteeringConditionOk
  }
`;

export const IgnitionSystemOperationPayloadFragment = gql`
  fragment IgnitionSystemOperationPayloadFragment on IgnitionSystemOperationPayload {
    isIgnitionSystemOperationConditionOk
  }
`;

export const AACCondenserPayloadFragment = gql`
  fragment AACCondenserPayloadFragment on AACCondenserPayload {
    isCondenserConditionOk
  }
`;

export const ACSystemLeaksPayloadFragment = gql`
  fragment ACSystemLeaksPayloadFragment on ACSystemLeaksPayload {
    hasACSystemLeaks
  }
`;

export const ABSOpticalConditionPayloadFragment = gql`
  fragment ABSOpticalConditionPayloadFragment on ABSOpticalConditionPayload {
    isABSOpticalOk
  }
`;

export const BrakeMasterConditionPayloadFragment = gql`
  fragment BrakeMasterConditionPayloadFragment on BrakeMasterConditionPayload {
    isBrakeMasterOk
  }
`;

export const BrakeFluidPayloadFragment = gql`
  fragment BrakeFluidPayloadFragment on BrakeFluidPayload {
    brakeFluidLevel
    condition
  }
`;

export const CoolantRecoveryConditionFragment = gql`
  fragment CoolantRecoveryConditionFragment on CoolantRecoveryConditionPayload {
    isCoolantRecoveryOk
  }
`;

export const CoolantHosesConditionFragment = gql`
  fragment CoolantHosesConditionFragment on CoolantHosesConditionPayload {
    isCoolantHosesOk
  }
`;

export const RadiatorConditionFragment = gql`
  fragment RadiatorConditionFragment on RadiatorConditionPayload {
    isRadiatorOk
  }
`;

export const RadiatorFanConditionFragment = gql`
  fragment RadiatorFanConditionFragment on RadiatorFanConditionPayload {
    isRadiatorFanOk
  }
`;

export const WaterLeaksConditionFragment = gql`
  fragment WaterLeaksConditionFragment on WaterLeaksConditionPayload {
    hasWaterLeaks
  }
`;

export const WaterPumpConditionFragment = gql`
  fragment WaterPumpConditionFragment on WaterPumpConditionPayload {
    isWaterPumpOk
  }
`;

export const CoolantFragment = gql`
  fragment CoolantFragment on CoolantPayload {
    coolantLevel
    temperature
  }
`;

export const EngineRunningPayloadFragment = gql`
  fragment EngineRunningPayloadFragment on EngineRunningPayload {
    doesEngineRunning
  }
`;

export const EngineSoundPayloadFragment = gql`
  fragment EngineSoundPayloadFragment on EngineSoundPayload {
    doesEngineSoundNormalNoise
    engineSoundType
    comment
  }
`;

export const FuelSystemPayloadFragment = gql`
  fragment FuelSystemPayloadFragment on FuelSystemPayload {
    isFuelSystemConditionOk
  }
`;

export const IntercoolerPayloadFragment = gql`
  fragment IntercoolerPayloadFragment on IntercoolerPayload {
    isIntercoolerConditionOk
  }
`;

export const VBeltPayloadFragment = gql`
  fragment VBeltPayloadFragment on VBeltPayload {
    isVBeltConditionOk
  }
`;

export const CambeltPayloadFragment = gql`
  fragment CambeltPayloadFragment on CambeltPayload {
    isCambeltConditionOk
  }
`;

export const TimingChainPayloadFragment = gql`
  fragment TimingChainPayloadFragment on TimingChainPayload {
    isTimingChainConditionOk
  }
`;

export const OilLevelPayloadFragment = gql`
  fragment OilLevelPayloadFragment on OilLevelPayload {
    oilLevelType
  }
`;

export const BatteryPayloadFragment = gql`
  fragment BatteryPayloadFragment on BatteryPayload {
    age {
      week
      year
    }
    qualityType
    voltage
    chargeLoad
  }
`;

export const BatteryTesterPayloadFragment = gql`
  fragment BatteryTesterPayloadFragment on BatteryTesterPayload {
    imageUrl
  }
`;

export const AxleJointFLPayloadFragment = gql`
  fragment AxleJointFLPayloadFragment on AxleJointFLPayload {
    checkValue
  }
`;

export const AxleJointFRPayloadFragment = gql`
  fragment AxleJointFRPayloadFragment on AxleJointFRPayload {
    checkValue
  }
`;

export const AxleJointRLPayloadFragment = gql`
  fragment AxleJointRLPayloadFragment on AxleJointRLPayload {
    checkValue
  }
`;

export const AxleJointRRPayloadFragment = gql`
  fragment AxleJointRRPayloadFragment on AxleJointRRPayload {
    checkValue
  }
`;

export const BackExhaustPayloadFragment = gql`
  fragment BackExhaustPayloadFragment on BackExhaustPayload {
    checkValue
  }
`;

export const BrakeLineFLPayloadFragment = gql`
  fragment BrakeLineFLPayloadFragment on BrakeLineFLPayload {
    checkValue
  }
`;

export const BrakeLineFRPayloadFragment = gql`
  fragment BrakeLineFRPayloadFragment on BrakeLineFRPayload {
    checkValue
  }
`;

export const BrakeLineRLPayloadFragment = gql`
  fragment BrakeLineRLPayloadFragment on BrakeLineRLPayload {
    checkValue
  }
`;

export const BrakeLineRRPayloadFragment = gql`
  fragment BrakeLineRRPayloadFragment on BrakeLineRRPayload {
    checkValue
  }
`;

export const CatalyticConverterPayloadFragment = gql`
  fragment CatalyticConverterPayloadFragment on CatalyticConverterPayload {
    checkValue
  }
`;

export const CenterExhaustPayloadFragment = gql`
  fragment CenterExhaustPayloadFragment on CenterExhaustPayload {
    checkValue
  }
`;

export const DifferentialPayloadFragment = gql`
  fragment DifferentialPayloadFragment on DifferentialPayload {
    checkValue
  }
`;

export const DriveshaftFLPayloadFragment = gql`
  fragment DriveshaftFLPayloadFragment on DriveshaftFLPayload {
    checkValue
  }
`;

export const DriveshaftFRPayloadFragment = gql`
  fragment DriveshaftFRPayloadFragment on DriveshaftFRPayload {
    checkValue
  }
`;

export const DriveshaftRLPayloadFragment = gql`
  fragment DriveshaftRLPayloadFragment on DriveshaftRLPayload {
    checkValue
  }
`;

export const DriveshaftRRPayloadFragment = gql`
  fragment DriveshaftRRPayloadFragment on DriveshaftRRPayload {
    checkValue
  }
`;

export const EngineMountPayloadFragment = gql`
  fragment EngineMountPayloadFragment on EngineMountPayload {
    checkValue
  }
`;

export const ExhaustMountPayloadFragment = gql`
  fragment ExhaustMountPayloadFragment on ExhaustMountPayload {
    checkValue
  }
`;

export const FuelTankPayloadFragment = gql`
  fragment FuelTankPayloadFragment on FuelTankPayload {
    checkValue
  }
`;

export const HandbrakeCableLeftPayloadFragment = gql`
  fragment HandbrakeCableLeftPayloadFragment on HandbrakeCableLeftPayload {
    checkValue
  }
`;

export const HandbrakeCableRightPayloadFragment = gql`
  fragment HandbrakeCableRightPayloadFragment on HandbrakeCableRightPayload {
    checkValue
  }
`;

export const JackPointFrontLeftPayloadFragment = gql`
  fragment JackPointFrontLeftPayloadFragment on JackPointFrontLeftPayload {
    checkValue
  }
`;

export const JackPointFrontRightPayloadFragment = gql`
  fragment JackPointFrontRightPayloadFragment on JackPointFrontRightPayload {
    checkValue
  }
`;

export const JackPointRearLeftPayloadFragment = gql`
  fragment JackPointRearLeftPayloadFragment on JackPointRearLeftPayload {
    checkValue
  }
`;

export const JackPointRearRightPayloadFragment = gql`
  fragment JackPointRearRightPayloadFragment on JackPointRearRightPayload {
    checkValue
  }
`;

export const ManifoldPayloadFragment = gql`
  fragment ManifoldPayloadFragment on ManifoldPayload {
    checkValue
  }
`;

export const ManualTransmissionCablesPayloadFragment = gql`
  fragment ManualTransmissionCablesPayloadFragment on ManualTransmissionCablesPayload {
    checkValue
  }
`;

export const ParticleFilterPayloadFragment = gql`
  fragment ParticleFilterPayloadFragment on ParticleFilterPayload {
    checkValue
  }
`;

export const PropellerShaftPayloadFragment = gql`
  fragment PropellerShaftPayloadFragment on PropellerShaftPayload {
    checkValue
  }
`;

export const SillLeftPayloadFragment = gql`
  fragment SillLeftPayloadFragment on SillLeftPayload {
    checkValue
  }
`;

export const SillRightPayloadFragment = gql`
  fragment SillRightPayloadFragment on SillRightPayload {
    checkValue
  }
`;

export const SteeringCircuitPayloadFragment = gql`
  fragment SteeringCircuitPayloadFragment on SteeringCircuitPayload {
    checkValue
  }
`;

export const SteeringDustCapsPayloadFragment = gql`
  fragment SteeringDustCapsPayloadFragment on SteeringDustCapsPayload {
    checkValue
  }
`;

export const SteeringPumpPayloadFragment = gql`
  fragment SteeringPumpPayloadFragment on SteeringPumpPayload {
    checkValue
  }
`;

export const SteeringRackPayloadFragment = gql`
  fragment SteeringRackPayloadFragment on SteeringRackPayload {
    checkValue
  }
`;

export const SteeringReservoirPayloadFragment = gql`
  fragment SteeringReservoirPayloadFragment on SteeringReservoirPayload {
    checkValue
  }
`;

export const TorqueRodsFrontPayloadFragment = gql`
  fragment TorqueRodsFrontPayloadFragment on TorqueRodsFrontPayload {
    checkValue
  }
`;

export const TorqueRodsRearPayloadFragment = gql`
  fragment TorqueRodsRearPayloadFragment on TorqueRodsRearPayload {
    checkValue
  }
`;

export const TorqueSupportPayloadFragment = gql`
  fragment TorqueSupportPayloadFragment on TorqueSupportPayload {
    checkValue
  }
`;

export const TrackRodEndLeftPayloadFragment = gql`
  fragment TrackRodEndLeftPayloadFragment on TrackRodEndLeftPayload {
    checkValue
  }
`;

export const TrackRodEndRightPayloadFragment = gql`
  fragment TrackRodEndRightPayloadFragment on TrackRodEndRightPayload {
    checkValue
  }
`;

export const TransmissionLeaksPayloadFragment = gql`
  fragment TransmissionLeaksPayloadFragment on TransmissionLeaksPayload {
    checkValue
  }
`;

export const TransmissionMountPayloadFragment = gql`
  fragment TransmissionMountPayloadFragment on TransmissionMountPayload {
    checkValue
  }
`;

export const TurbochargerPayloadFragment = gql`
  fragment TurbochargerPayloadFragment on TurbochargerPayload {
    checkValue
  }
`;

export const UnderbodyFrontPayloadFragment = gql`
  fragment UnderbodyFrontPayloadFragment on UnderbodyFrontPayload {
    checkValue
  }
`;

export const UnderbodyLeftPayloadFragment = gql`
  fragment UnderbodyLeftPayloadFragment on UnderbodyLeftPayload {
    checkValue
  }
`;

export const UnderbodyRearPayloadFragment = gql`
  fragment UnderbodyRearPayloadFragment on UnderbodyRearPayload {
    checkValue
  }
`;

export const UnderbodyRightPayloadFragment = gql`
  fragment UnderbodyRightPayloadFragment on UnderbodyRightPayload {
    checkValue
  }
`;

export const UnderrideGuardEnginePayloadFragment = gql`
  fragment UnderrideGuardEnginePayloadFragment on UnderrideGuardEnginePayload {
    checkValue
  }
`;

export const UnderrideGuardLeftPayloadFragment = gql`
  fragment UnderrideGuardLeftPayloadFragment on UnderrideGuardLeftPayload {
    checkValue
  }
`;

export const UnderrideGuardRearPayloadFragment = gql`
  fragment UnderrideGuardRearPayloadFragment on UnderrideGuardRearPayload {
    checkValue
  }
`;

export const UnderrideGuardRightPayloadFragment = gql`
  fragment UnderrideGuardRightPayloadFragment on UnderrideGuardRightPayload {
    checkValue
  }
`;

export const UniversalJointsFLPayloadFragment = gql`
  fragment UniversalJointsFLPayloadFragment on UniversalJointsFLPayload {
    checkValue
  }
`;

export const UniversalJointsFRPayloadFragment = gql`
  fragment UniversalJointsFRPayloadFragment on UniversalJointsFRPayload {
    checkValue
  }
`;

export const UniversalJointsRLPayloadFragment = gql`
  fragment UniversalJointsRLPayloadFragment on UniversalJointsRLPayload {
    checkValue
  }
`;

export const UniversalJointsRRPayloadFragment = gql`
  fragment UniversalJointsRRPayloadFragment on UniversalJointsRRPayload {
    checkValue
  }
`;

export const IsWheelBearingOKPayloadFragment = gql`
  fragment IsWheelBearingOKPayloadFragment on IsWheelBearingOKPayload {
    position
    value
  }
`;

export const AreSpringsOKPayloadFragment = gql`
  fragment AreSpringsOKPayloadFragment on AreSpringsOKPayload {
    position
    value
  }
`;

export const IsShockAbsorberOKPayloadFragment = gql`
  fragment IsShockAbsorberOKPayloadFragment on IsShockAbsorberOKPayload {
    position
    value
  }
`;

export const DoesBallJointWorkPayloadFragment = gql`
  fragment DoesBallJointWorkPayloadFragment on DoesBallJointWorkPayload {
    position
    value
  }
`;

export const DoesDustCoverExistsPayloadFragment = gql`
  fragment DoesDustCoverExistsPayloadFragment on DoesDustCoverExistsPayload {
    position
    value
  }
`;

export const IsBumpStopOKPayloadFragment = gql`
  fragment IsBumpStopOKPayloadFragment on IsBumpStopOKPayload {
    position
    value
  }
`;

export const IsStrutBearingOKPayloadFragment = gql`
  fragment IsStrutBearingOKPayloadFragment on IsStrutBearingOKPayload {
    position
    value
  }
`;

export const AreSupportArmsOKPayloadFragment = gql`
  fragment AreSupportArmsOKPayloadFragment on AreSupportArmsOKPayload {
    position
    value
  }
`;

export const IsControlArmOKPayloadFragment = gql`
  fragment IsControlArmOKPayloadFragment on IsControlArmOKPayload {
    position
    value
  }
`;

export const IsStabilizerBearingOKPayloadFragment = gql`
  fragment IsStabilizerBearingOKPayloadFragment on IsStabilizerBearingOKPayload {
    position
    value
  }
`;

export const IsRimInGoodConditionPayloadFragment = gql`
  fragment IsRimInGoodConditionPayloadFragment on IsRimInGoodConditionPayload {
    isSecondaryWheel
    position
    value
  }
`;

export const DoesRimCenterCapExistsPayloadFragment = gql`
  fragment DoesRimCenterCapExistsPayloadFragment on DoesRimCenterCapExistsPayload {
    isSecondaryWheel
    position
    value
  }
`;

const DoesTheCarRequireAServicePayloadFragment = gql`
  fragment DoesTheCarRequireAServicePayloadFragment on DoesTheCarRequireAServicePayload {
    checkValue
  }
`;

export const IsTireInGoodConditionPayloadFragment = gql`
  fragment IsTireInGoodConditionPayloadFragment on IsTireInGoodConditionPayload {
    isSecondaryWheel
    position
    value
  }
`;
export const IsTireDirectionCorrectPayloadFragment = gql`
  fragment IsTireDirectionCorrectPayloadFragment on IsTireDirectionCorrectPayload {
    isSecondaryWheel
    position
    value
  }
`;
export const IsTirePressureCorrectPayloadFragment = gql`
  fragment IsTirePressureCorrectPayloadFragment on IsTirePressureCorrectPayload {
    isSecondaryWheel
    position
    value
  }
`;

export const AreRimsAndTiresLicensedForThisCarPayloadFragment = gql`
  fragment AreRimsAndTiresLicensedForThisCarPayloadFragment on AreRimsAndTiresLicensedForThisCarPayload {
    isSecondaryWheelSet
    value
  }
`;

export const BrakeTypePayloadFragment = gql`
  fragment BrakeTypePayloadFragment on BrakeTypePayload {
    position
    brakeType
  }
`;

export const DoesBrakeFluidCapExistsPayloadFragment = gql`
  fragment DoesBrakeFluidCapExistsPayloadFragment on DoesBrakeFluidCapExistsPayload {
    position
    value
  }
`;

export const AreBrakeHosesInGoodConditionPayloadFragment = gql`
  fragment AreBrakeHosesInGoodConditionPayloadFragment on AreBrakeHosesInGoodConditionPayload {
    position
    value
  }
`;

export const BrakePadConditionPayloadFragment = gql`
  fragment BrakePadConditionPayloadFragment on BrakePadConditionPayload {
    position
    brakeCondition: condition
  }
`;

export const BrakeDiskConditionPayloadFragment = gql`
  fragment BrakeDiskConditionPayloadFragment on BrakeDiskConditionPayload {
    position
    brakeCondition: condition
  }
`;

export const IsBrakeCaliperInGoodConditionPayloadFragment = gql`
  fragment IsBrakeCaliperInGoodConditionPayloadFragment on IsBrakeCaliperInGoodConditionPayload {
    position
    value
  }
`;

export const ChargingCablePayloadFragment = gql`
  fragment ChargingCablePayloadFragment on ChargingCablePayload {
    hasChargingCable
  }
`;

export const InspectionNeededPayloadFragment = gql`
  fragment InspectionNeededPayloadFragment on InspectionNeededPayload {
    inspectionNeeded
  }
`;
export const DoneBeforeEntryCheckPayloadFragment = gql`
  fragment DoneBeforeEntryCheckPayloadFragment on DoneBeforeEntryCheckPayload {
    doneBeforeEntryCheck
  }
`;
export const DidCarPassInspectionPayloadFragment = gql`
  fragment DidCarPassInspectionPayloadFragment on DidCarPassInspectionPayload {
    didCarPassInspection
  }
`;
export const LastDatePayloadFragment = gql`
  fragment LastDatePayloadFragment on LastDatePayload {
    lastDate
  }
`;
export const LastMileagePayloadFragment = gql`
  fragment LastMileagePayloadFragment on LastMileagePayload {
    lastDistance: distance
    lastUnit: unit
  }
`;
export const LastDueDatePayloadFragment = gql`
  fragment LastDueDatePayloadFragment on LastDueDatePayload {
    lastDueDate
  }
`;

export const ServiceStatePayloadFragment = gql`
  fragment ServiceStatePayloadFragment on ServiceStatePayload {
    serviceState
  }
`;

export const PartnerWorkshopConnectionPayloadFragment = gql`
  fragment PartnerWorkshopConnectionPayloadFragment on PartnerWorkshopConnectionPayload {
    id
  }
`;

export const EcCommonPayloadFragment = gql`
  fragment EcCommonPayloadFragment on EcCommonPayload {
    sparePart {
      status
      comment
      lastStatusUpdatedOn
    }
  }
`;

export const GraphQLJsonPayloadFragment = gql`
  fragment GraphQLJsonPayloadFragment on GraphQLJsonPayload {
    payloadType
    ...FuelTypePayloadFragment
    ...MileagePayloadFragment
    ...WheelSeasonPayloadFragment
    ...WheelProfilePayloadFragment
    ...WheelRimTypePayloadFragment
    ...WheelSizePayloadFragment
    ...WheelRimSizePayloadFragment
    ...WheelSpeedIndexPayloadFragment
    ...WheelLoadIndexPayloadFragment
    ...WheelManufacturerPayloadFragment
    ...WheelDotPayloadFragment
    ...TirePressurePayloadFragment
    ...WheelImagePayloadFragment
    ...WheelSetsSwitchPayloadFragment
    ...WheelSetDeletePayloadFragment
    ...BrakeDamagePayloadFragment
    ...DamageTypePayloadFragment
    ...DamageImagePayloadFragment
    ...DamageServiceNamePayloadFragment
    ...VehicleServiceNamePayloadFragment
    ...ServiceBudgetPayloadFragment
    ...ServiceCommentPayloadFragment
    ...DamageCustomerDisplayPayloadFragment
    ...ServiceCustomerDisplayPayloadFragment
    ...DamageCancellationPayloadFragment
    ...BeautyShotPayloadFragment
    ...SmokerPayloadFragment
    ...AccidentCarPayloadFragment
    ...OriginalEnginePayloadFragment
    ...OriginalUpholsteryPayloadFragment
    ...WasInCommercialUsePayloadFragment
    ...HoodReleasePayloadFragment
    ...BlanketInsulatorPayloadFragment
    ...OilCoolerPayloadFragment
    ...AlternatorPayloadFragment
    ...WindshieldFluidLevelPayloadFragment
    ...PowerSteeringPayloadFragment
    ...IgnitionSystemOperationPayloadFragment
    ...AACCondenserPayloadFragment
    ...ACSystemLeaksPayloadFragment
    ...ABSOpticalConditionPayloadFragment
    ...BrakeMasterConditionPayloadFragment
    ...BrakeFluidPayloadFragment
    ...CoolantRecoveryConditionFragment
    ...CoolantHosesConditionFragment
    ...RadiatorConditionFragment
    ...RadiatorFanConditionFragment
    ...WaterLeaksConditionFragment
    ...WaterPumpConditionFragment
    ...CoolantFragment
    ...EngineRunningPayloadFragment
    ...EngineSoundPayloadFragment
    ...FuelSystemPayloadFragment
    ...IntercoolerPayloadFragment
    ...VBeltPayloadFragment
    ...CambeltPayloadFragment
    ...TimingChainPayloadFragment
    ...OilLevelPayloadFragment
    ...BatteryPayloadFragment
    ...BatteryTesterPayloadFragment
    ...AxleJointFLPayloadFragment
    ...AxleJointFRPayloadFragment
    ...AxleJointRLPayloadFragment
    ...AxleJointRRPayloadFragment
    ...BackExhaustPayloadFragment
    ...BrakeLineFLPayloadFragment
    ...BrakeLineFRPayloadFragment
    ...BrakeLineRLPayloadFragment
    ...BrakeLineRRPayloadFragment
    ...CatalyticConverterPayloadFragment
    ...CenterExhaustPayloadFragment
    ...DifferentialPayloadFragment
    ...DriveshaftFLPayloadFragment
    ...DriveshaftFRPayloadFragment
    ...DriveshaftRLPayloadFragment
    ...DriveshaftRRPayloadFragment
    ...EngineMountPayloadFragment
    ...ExhaustMountPayloadFragment
    ...FuelTankPayloadFragment
    ...HandbrakeCableLeftPayloadFragment
    ...HandbrakeCableRightPayloadFragment
    ...JackPointFrontLeftPayloadFragment
    ...JackPointFrontRightPayloadFragment
    ...JackPointRearLeftPayloadFragment
    ...JackPointRearRightPayloadFragment
    ...ManifoldPayloadFragment
    ...ManualTransmissionCablesPayloadFragment
    ...ParticleFilterPayloadFragment
    ...PropellerShaftPayloadFragment
    ...SillLeftPayloadFragment
    ...SillRightPayloadFragment
    ...SteeringCircuitPayloadFragment
    ...SteeringDustCapsPayloadFragment
    ...SteeringPumpPayloadFragment
    ...SteeringRackPayloadFragment
    ...SteeringReservoirPayloadFragment
    ...TorqueRodsFrontPayloadFragment
    ...TorqueRodsRearPayloadFragment
    ...TorqueSupportPayloadFragment
    ...TrackRodEndLeftPayloadFragment
    ...TrackRodEndRightPayloadFragment
    ...TransmissionLeaksPayloadFragment
    ...TransmissionMountPayloadFragment
    ...TurbochargerPayloadFragment
    ...UnderbodyFrontPayloadFragment
    ...UnderbodyLeftPayloadFragment
    ...UnderbodyRearPayloadFragment
    ...UnderbodyRightPayloadFragment
    ...UnderrideGuardEnginePayloadFragment
    ...UnderrideGuardLeftPayloadFragment
    ...UnderrideGuardRearPayloadFragment
    ...UnderrideGuardRightPayloadFragment
    ...UniversalJointsFLPayloadFragment
    ...UniversalJointsFRPayloadFragment
    ...UniversalJointsRLPayloadFragment
    ...UniversalJointsRRPayloadFragment
    ...IsWheelBearingOKPayloadFragment
    ...AreSpringsOKPayloadFragment
    ...IsShockAbsorberOKPayloadFragment
    ...DoesBallJointWorkPayloadFragment
    ...DoesDustCoverExistsPayloadFragment
    ...IsBumpStopOKPayloadFragment
    ...IsStrutBearingOKPayloadFragment
    ...AreSupportArmsOKPayloadFragment
    ...IsControlArmOKPayloadFragment
    ...IsStabilizerBearingOKPayloadFragment
    ...IsRimInGoodConditionPayloadFragment
    ...DoesRimCenterCapExistsPayloadFragment
    ...DoesTheCarRequireAServicePayloadFragment
    ...IsTireInGoodConditionPayloadFragment
    ...IsTireDirectionCorrectPayloadFragment
    ...IsTirePressureCorrectPayloadFragment
    ...AreRimsAndTiresLicensedForThisCarPayloadFragment
    ...BrakeTypePayloadFragment
    ...DoesBrakeFluidCapExistsPayloadFragment
    ...AreBrakeHosesInGoodConditionPayloadFragment
    ...BrakePadConditionPayloadFragment
    ...BrakeDiskConditionPayloadFragment
    ...IsBrakeCaliperInGoodConditionPayloadFragment
    ...ChargingCablePayloadFragment
    ...InspectionNeededPayloadFragment
    ...DoneBeforeEntryCheckPayloadFragment
    ...DidCarPassInspectionPayloadFragment
    ...LastDatePayloadFragment
    ...LastMileagePayloadFragment
    ...LastDueDatePayloadFragment
    ...ServiceStatePayloadFragment
    ...PartnerWorkshopConnectionPayloadFragment
    ...KeysCountPayloadFragment
    ...ServiceBookPayloadFragment
    ...EcCommonPayloadFragment
  }
  ${FuelTypePayloadFragment}
  ${MileagePayloadFragment}
  ${WheelSeasonPayloadFragment}
  ${WheelProfilePayloadFragment}
  ${WheelRimTypePayloadFragment}
  ${WheelSizePayloadFragment}
  ${WheelRimSizePayloadFragment}
  ${WheelSpeedIndexPayloadFragment}
  ${WheelLoadIndexPayloadFragment}
  ${WheelManufacturerPayloadFragment}
  ${WheelImagePayloadFragment}
  ${WheelSetsSwitchPayloadFragment}
  ${WheelSetDeletePayloadFragment}
  ${WheelDotPayloadFragment}
  ${TirePressurePayloadFragment}
  ${BrakeDamagePayloadFragment}
  ${DamageTypePayloadFragment}
  ${DamageImagePayloadFragment}
  ${DamageServiceNamePayloadFragment}
  ${VehicleServiceNamePayloadFragment}
  ${ServiceBudgetPayloadFragment}
  ${ServiceCommentPayloadFragment}
  ${DamageCustomerDisplayPayloadFragment}
  ${ServiceCustomerDisplayPayloadFragment}
  ${DamageCancellationPayloadFragment}
  ${BeautyShotPayloadFragment}
  ${SmokerPayloadFragment}
  ${AccidentCarPayloadFragment}
  ${OriginalEnginePayloadFragment}
  ${OriginalUpholsteryPayloadFragment}
  ${WasInCommercialUsePayloadFragment}
  ${HoodReleasePayloadFragment}
  ${BlanketInsulatorPayloadFragment}
  ${OilCoolerPayloadFragment}
  ${AlternatorPayloadFragment}
  ${WindshieldFluidLevelPayloadFragment}
  ${PowerSteeringPayloadFragment}
  ${IgnitionSystemOperationPayloadFragment}
  ${AACCondenserPayloadFragment}
  ${ACSystemLeaksPayloadFragment}
  ${ABSOpticalConditionPayloadFragment}
  ${BrakeMasterConditionPayloadFragment}
  ${BrakeFluidPayloadFragment}
  ${CoolantRecoveryConditionFragment}
  ${CoolantHosesConditionFragment}
  ${RadiatorConditionFragment}
  ${RadiatorFanConditionFragment}
  ${WaterLeaksConditionFragment}
  ${WaterPumpConditionFragment}
  ${CoolantFragment}
  ${EngineRunningPayloadFragment}
  ${EngineSoundPayloadFragment}
  ${FuelSystemPayloadFragment}
  ${IntercoolerPayloadFragment}
  ${VBeltPayloadFragment}
  ${CambeltPayloadFragment}
  ${TimingChainPayloadFragment}
  ${OilLevelPayloadFragment}
  ${BatteryPayloadFragment}
  ${BatteryTesterPayloadFragment}
  ${AxleJointFLPayloadFragment}
  ${AxleJointFRPayloadFragment}
  ${AxleJointRLPayloadFragment}
  ${AxleJointRRPayloadFragment}
  ${BackExhaustPayloadFragment}
  ${BrakeLineFLPayloadFragment}
  ${BrakeLineFRPayloadFragment}
  ${BrakeLineRLPayloadFragment}
  ${BrakeLineRRPayloadFragment}
  ${CatalyticConverterPayloadFragment}
  ${CenterExhaustPayloadFragment}
  ${DifferentialPayloadFragment}
  ${DriveshaftFLPayloadFragment}
  ${DriveshaftFRPayloadFragment}
  ${DriveshaftRLPayloadFragment}
  ${DriveshaftRRPayloadFragment}
  ${EngineMountPayloadFragment}
  ${ExhaustMountPayloadFragment}
  ${FuelTankPayloadFragment}
  ${HandbrakeCableLeftPayloadFragment}
  ${HandbrakeCableRightPayloadFragment}
  ${JackPointFrontLeftPayloadFragment}
  ${JackPointFrontRightPayloadFragment}
  ${JackPointRearLeftPayloadFragment}
  ${JackPointRearRightPayloadFragment}
  ${ManifoldPayloadFragment}
  ${ManualTransmissionCablesPayloadFragment}
  ${ParticleFilterPayloadFragment}
  ${PropellerShaftPayloadFragment}
  ${SillLeftPayloadFragment}
  ${SillRightPayloadFragment}
  ${SteeringCircuitPayloadFragment}
  ${SteeringDustCapsPayloadFragment}
  ${SteeringPumpPayloadFragment}
  ${SteeringRackPayloadFragment}
  ${SteeringReservoirPayloadFragment}
  ${TorqueRodsFrontPayloadFragment}
  ${TorqueRodsRearPayloadFragment}
  ${TorqueSupportPayloadFragment}
  ${TrackRodEndLeftPayloadFragment}
  ${TrackRodEndRightPayloadFragment}
  ${TransmissionLeaksPayloadFragment}
  ${TransmissionMountPayloadFragment}
  ${TurbochargerPayloadFragment}
  ${UnderbodyFrontPayloadFragment}
  ${UnderbodyLeftPayloadFragment}
  ${UnderbodyRearPayloadFragment}
  ${UnderbodyRightPayloadFragment}
  ${UnderrideGuardEnginePayloadFragment}
  ${UnderrideGuardLeftPayloadFragment}
  ${UnderrideGuardRearPayloadFragment}
  ${UnderrideGuardRightPayloadFragment}
  ${UniversalJointsFLPayloadFragment}
  ${UniversalJointsFRPayloadFragment}
  ${UniversalJointsRLPayloadFragment}
  ${UniversalJointsRRPayloadFragment}
  ${IsWheelBearingOKPayloadFragment}
  ${AreSpringsOKPayloadFragment}
  ${IsShockAbsorberOKPayloadFragment}
  ${DoesBallJointWorkPayloadFragment}
  ${DoesDustCoverExistsPayloadFragment}
  ${IsBumpStopOKPayloadFragment}
  ${IsStrutBearingOKPayloadFragment}
  ${AreSupportArmsOKPayloadFragment}
  ${IsControlArmOKPayloadFragment}
  ${IsStabilizerBearingOKPayloadFragment}
  ${IsRimInGoodConditionPayloadFragment}
  ${DoesRimCenterCapExistsPayloadFragment}
  ${DoesTheCarRequireAServicePayloadFragment}
  ${IsTireInGoodConditionPayloadFragment}
  ${IsTireDirectionCorrectPayloadFragment}
  ${IsTirePressureCorrectPayloadFragment}
  ${AreRimsAndTiresLicensedForThisCarPayloadFragment}
  ${BrakeTypePayloadFragment}
  ${DoesBrakeFluidCapExistsPayloadFragment}
  ${AreBrakeHosesInGoodConditionPayloadFragment}
  ${BrakePadConditionPayloadFragment}
  ${BrakeDiskConditionPayloadFragment}
  ${IsBrakeCaliperInGoodConditionPayloadFragment}
  ${ChargingCablePayloadFragment}
  ${InspectionNeededPayloadFragment}
  ${DoneBeforeEntryCheckPayloadFragment}
  ${DidCarPassInspectionPayloadFragment}
  ${LastDatePayloadFragment}
  ${LastMileagePayloadFragment}
  ${LastDueDatePayloadFragment}
  ${ServiceStatePayloadFragment}
  ${PartnerWorkshopConnectionPayloadFragment}
  ${KeysCountPayloadFragment}
  ${ServiceBookPayloadFragment}
  ${EcCommonPayloadFragment}
`;

export const EntryCheckItemValueFragment = gql`
  fragment EntryCheckItemValueFragment on EntryCheckItemValue {
    __typename
    id
    entryCheckItemId
    sourceType
    state
    updatedBy {
      firstName
      lastName
    }
    updatedOn
    createdOn
    originalValue {
      ...GraphQLJsonPayloadFragment
    }
    proposedValue {
      ...GraphQLJsonPayloadFragment
    }
  }
  ${GraphQLJsonPayloadFragment}
`;

export const EntryCheckItemFragment = gql`
  fragment EntryCheckItemFragment on EntryCheckItem {
    __typename
    id
    entryCheckId
    entityId
    entityType
    verified
    values {
      ...EntryCheckItemValueFragment
    }
  }
  ${EntryCheckItemValueFragment}
`;

export const EntryCheckAuthorizationItemValueFragment = gql`
  fragment EntryCheckAuthorizationItemValueFragment on EntryCheckItemValue {
    __typename
    id
    entryCheckItemId
    sourceType
    state
    updatedBy {
      firstName
      lastName
    }
    updatedOn
    createdOn
    originalValue {
      ...GraphQLJsonPayloadFragment
    }
    proposedValue {
      ...GraphQLJsonPayloadFragment
    }
  }
  ${GraphQLJsonPayloadFragment}
`;

export const EntryCheckAuthorizationItemFragment = gql`
  fragment EntryCheckAuthorizationItemFragment on EntryCheckItem {
    __typename
    id
    entryCheckId
    entityId
    entityType
    verified
    values {
      ...EntryCheckAuthorizationItemValueFragment
    }
  }
  ${EntryCheckAuthorizationItemValueFragment}
`;
