import { get } from 'lodash/fp';

import { Query, QueryUserSearchArgs } from 'graphqlTypes';
import SEARCH_USERS from './gql/userSearch';
import usePromiseQuery from '../helpers/usePromiseQuery';

const useSearchUsers = () => {
  const query = usePromiseQuery<Pick<Query, 'userSearch'>, QueryUserSearchArgs>(
    SEARCH_USERS,
  );

  const searchUsers = async (variables: QueryUserSearchArgs) => {
    const { data } = await query(variables);
    return {
      data: get(['userSearch', 'items'], data),
    };
  };

  return searchUsers;
};

export default useSearchUsers;
