import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PersonIcon from '@mui/icons-material/Person';

import LoadingIndicator from 'components/LoadingIndicator';
import { InformMessage } from 'graphqlTypes';
import { useUpdateInformMessage } from 'modules/api/informMessage';
import { useLogger } from 'modules/logger';
import { InformMessageStatus } from 'routes/MonitoringDashboardRoute/types';

import cn from './MonitoringRow.module.scss';

type Props = {
  message: InformMessage;
};

const Review = ({ message }: Props) => {
  const [updateInformMessage, { loading }] = useUpdateInformMessage();
  const { logError } = useLogger();

  if (message.status !== InformMessageStatus.VPC_PROCESSING_ERROR) return null;

  const { id, reviewed, reviewedBy } = message;

  const handleCheck = async (value: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await updateInformMessage({
        id,
        input: {
          reviewed: !!value.target.checked,
        },
      });
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <div className={cn.review}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Form.Check
          id={`reviewed-${id}`}
          checked={!!reviewed}
          custom
          type="checkbox"
          onChange={handleCheck}
          data-qa-selector={`reviewed-${id}`}
        />
      )}
      {(reviewedBy?.firstName || reviewedBy?.lastName) && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip
              id={`tooltip-${id}`}
            >{`${reviewedBy?.firstName} ${reviewedBy?.lastName}`}</Tooltip>
          }
          data-qa-selector="reviewedBy"
        >
          <PersonIcon className={cn.personIcon} />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default Review;
