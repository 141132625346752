import React from 'react';

interface TableInfoProps {
  results: number | string | null;
  className: string;
}

const TableInfo = ({ results, className }: TableInfoProps) => {
  return (
    <div className={className} data-qa-selector="table-info-total-results">
      {results || 0} {__('refurbishment.table.totalResults')}
    </div>
  );
};

TableInfo.defaultProps = {
  className: '',
};

export default TableInfo;
