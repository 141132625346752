import { useMemo } from 'react';
import Table from 'react-bootstrap/Table';

import Badge from 'components/Badge/Badge';
import {
  PartnerWorkshopConnection,
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import DeleteProductionLocation from './DeleteProductionLocation';
import EditProductionLocation from './EditProductionLocation';

import cn from './ProductionLocationsTable.module.scss';

type Props = {
  partnerWorkshopConnections: UserPartnerWorkshopConnection[];
  loading: boolean;
  onChange(values: UserPartnerWorkshopConnectionInput): Promise<any>;
};

const ProductionLocationsTable = ({
  partnerWorkshopConnections,
  loading,
  onChange,
}: Props) => {
  const sortedConnections = useMemo(
    () =>
      [...partnerWorkshopConnections]
        .map(connection => {
          connection.partnerWorkshopConnections?.sort((a, b) =>
            `${a.repairWorkshop.name}${a.repairPartner.name}`.localeCompare(
              `${b.repairWorkshop.name}${b.repairPartner.name}`,
              __LOCALE__,
            ),
          );
          return connection;
        })
        .sort((a, b) =>
          a.mainWorkshop.name.localeCompare(b.mainWorkshop.name, __LOCALE__),
        ),
    [partnerWorkshopConnections],
  );

  return (
    <div data-qa-selector="userProductionLocationTable">
      <Table striped className="invert-stripped">
        <thead>
          <tr>
            <th className={cn.mainWorkshopTitle}>{__('user.main.workshop')}</th>
            <th>{__('user.repairWorkshop.partnerLocations')}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {sortedConnections.map(group => {
            return (
              <tr
                key={group.mainWorkshop.id}
                data-qa-selector={`row-${group.mainWorkshop.id}`}
              >
                <td className="align-baseline" data-qa-selector="mainWorkshop">
                  {group.mainWorkshop.name}
                </td>
                <td className="align-baseline" data-qa-selector="repairPartner">
                  <div className={cn.badges}>
                    {group.isAllLocations && (
                      <Badge
                        color="neutral"
                        size="m"
                        className="mr-2 mb-2"
                        qaSelector="allRepairPartner"
                      >
                        {__('user.productionLocation.allPartners')}
                      </Badge>
                    )}

                    {group.partnerWorkshopConnections
                      ?.filter(
                        (connection): connection is PartnerWorkshopConnection =>
                          !!connection,
                      )
                      .map(connection => {
                        const name = `${connection.repairWorkshop.name} (${connection.repairPartner.name})`;
                        return (
                          <Badge
                            color="info"
                            size="m"
                            className="mr-2 mb-2"
                            key={name}
                            qaSelector={`repairPartner-${name}`}
                          >
                            {name}
                          </Badge>
                        );
                      })}
                  </div>
                </td>
                <td className="d-flex justify-content-end align-baseline">
                  <EditProductionLocation
                    userPartnerWorkshopConnectionItem={group}
                    userPartnerWorkshopConnections={partnerWorkshopConnections}
                    loading={loading}
                    onChange={onChange}
                  />
                  <DeleteProductionLocation
                    userPartnerWorkshopConnectionItem={group}
                    userPartnerWorkshopConnections={partnerWorkshopConnections}
                    onChange={onChange}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ProductionLocationsTable;
