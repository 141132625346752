import { useMutation } from '@apollo/react-hooks';
import { isNumber } from 'lodash/fp';

import {
  ItemType,
  Mutation,
  MutationCreateQualityCheckServiceItemArgs,
  Query,
  QueryQualityCheckReviewArgs,
  ServiceItemCreateInput,
} from 'graphqlTypes';
import { updateProviderTotalCost } from './common/updateProviderTotalCost';
import CREATE_QUALITY_CHECK_SERVICE_ITEM from './gql/createQualityCheckServiceItem';
import QUALITY_CHECK_REVIEW from './gql/qualityCheckReview';

const useCreateQualityCheckServiceItem = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'createQualityCheckServiceItem'>,
    MutationCreateQualityCheckServiceItemArgs
  >(CREATE_QUALITY_CHECK_SERVICE_ITEM);

  const createQualityCheckServiceItem = (
    serviceItemCreateInput: ServiceItemCreateInput,
  ) => {
    return mutation({
      variables: {
        serviceItemCreateInput,
      },
      update(cache, { data }) {
        // update cost
        const providerTotalCostMinorUnits =
          data?.createQualityCheckServiceItem.providerTotalCostsMinorUnits;

        if (isNumber(providerTotalCostMinorUnits)) {
          updateProviderTotalCost(reviewId, providerTotalCostMinorUnits, cache);
        }

        // update items
        const newItem = data?.createQualityCheckServiceItem;
        if (newItem) {
          const cachedQuery = cache.readQuery<
            Pick<Query, 'qualityCheckReview'>,
            QueryQualityCheckReviewArgs
          >({
            query: QUALITY_CHECK_REVIEW,
            variables: {
              id: reviewId,
            },
          });

          if (cachedQuery) {
            cache.writeQuery({
              query: QUALITY_CHECK_REVIEW,
              data: {
                qualityCheckReview: {
                  ...cachedQuery.qualityCheckReview,
                  items: [
                    ...(cachedQuery.qualityCheckReview.items || []),
                    {
                      __typename: 'ItemGroup',
                      itemId: newItem.attachedTo,
                      itemType: ItemType.Service,
                      values: [{ ...newItem }],
                    },
                  ],
                },
              },
              variables: {
                id: reviewId,
              },
            });
          }
        }
      },
    });
  };

  return [createQualityCheckServiceItem, options] as const;
};

export default useCreateQualityCheckServiceItem;
