import { useFormContext } from 'react-hook-form';
import { Option } from 'types';

import BadgeItem from 'components/Filter/BadgeItem';
import { refurbishmentSparePartStatusesTranslated } from 'constants/sparePartsStatuses';
import createOptions from 'helpers/createOptions';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const sparePartStatusOptions = createOptions(
  refurbishmentSparePartStatusesTranslated,
).reduce<Record<string, Option>>(
  (acc, item) => ({ ...acc, [item.value]: item }),
  {},
);

const SparePartStatusBadges = () => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'SparePartStatusBadges' must be used inside a 'Filter' component.",
    );
  }
  const { watch, getValues, setValue } = formMethods;

  const sparePartStatusValues = watch(FormFilterNames.SPARE_PART_STATUS) as
    | string[]
    | undefined;

  if (!sparePartStatusValues || !sparePartStatusValues?.length) return null;

  const options = sparePartStatusValues.map(
    option => sparePartStatusOptions[option],
  );

  return (
    <div
      className={cn.badges}
      data-qa-selector="activeBadgeSparePartStatusFilters"
    >
      {options.map(option => (
        <BadgeItem
          key={option.value}
          option={option}
          qaSelector="SparePartStatusFilter"
          onRemove={sparePartStatus => {
            const values = getValues();
            setValue(
              FormFilterNames.SPARE_PART_STATUS,
              values[FormFilterNames.SPARE_PART_STATUS]?.filter(
                (item: string) => item !== sparePartStatus,
              ),
            );
          }}
        />
      ))}
    </div>
  );
};

export default SparePartStatusBadges;
