import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationRemovePartnerWorkshopConnectionsArgs,
  QueryActiveGroupedPartnerWorkshopConnectionsArgs,
} from 'graphqlTypes';
import ACTIVE_PARTNER_WORKSHOP_CONNECTIONS from './gql/activeGroupedPartnerWorkshopConnections';
import REMOVE_PARTNER_WORKSHOP_CONNECTIONS from './gql/removePartnerWorkshopConnections';

const useRemovePartnerWorkshopConnections = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'removePartnerWorkshopConnections'>,
    MutationRemovePartnerWorkshopConnectionsArgs
  >(REMOVE_PARTNER_WORKSHOP_CONNECTIONS);

  const removePartnerWorkshopConnections = (
    ids: string[],
    partnerWorkshopConnectionArgs: QueryActiveGroupedPartnerWorkshopConnectionsArgs,
  ) =>
    mutation({
      variables: { ids },
      refetchQueries: [
        {
          query: ACTIVE_PARTNER_WORKSHOP_CONNECTIONS,
          variables: partnerWorkshopConnectionArgs,
        },
      ],
    });

  return [
    removePartnerWorkshopConnections,
    {
      data: data?.removePartnerWorkshopConnections,
      ...rest,
    },
  ] as const;
};

export default useRemovePartnerWorkshopConnections;
