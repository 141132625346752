type CalculatedCostDetailsType = {
  materialCost: number;
  laborCost: number;
  totalCost: number;
};

export const calculateCostDetailsMinorUnits = (
  consumableCostMinorUnits: number | string,
  sparePartCostMinorUnits: number | string,
  laborTime: number | string,
  laborRateMinorUnits: number | string,
): CalculatedCostDetailsType => {
  const materialCost =
    Number(consumableCostMinorUnits) + Number(sparePartCostMinorUnits);
  const laborCost = Math.round(
    (Number(laborTime) * 100 * Number(laborRateMinorUnits)) / 100,
  );

  const totalCost = materialCost + laborCost;
  return {
    materialCost,
    laborCost,
    totalCost,
  };
};

export const calculateCostDetailsMajorUnits = (
  consumableCostMajorUnits: number | string,
  sparePartCostMajorUnits: number | string,
  laborTime: number | string,
  laborRateMajorUnits: number | string,
): CalculatedCostDetailsType => {
  const materialCost =
    Number(consumableCostMajorUnits) + Number(sparePartCostMajorUnits);
  const laborCost =
    Math.round(
      (Number(laborTime) * 100 * (Number(laborRateMajorUnits) * 100)) / 100,
    ) / 100;

  const totalCost = materialCost + laborCost;
  return {
    materialCost,
    laborCost,
    totalCost,
  };
};
