import React, { useState } from 'react';
import { noop } from 'lodash/fp';

import FileDropzone, { PreparedFile } from 'components/FileDropzone';
import ClipSvg from './img/ClipSvg';
import CloseSvg from './img/CloseSvg';
import CloudUploadSvg from './img/CloudUploadSvg';

import cn from './ImportCsvUpload.module.scss';

interface Props {
  onFiles?: (e: PreparedFile[]) => void;
}

const ImportCsvUpload = ({ onFiles = noop }: Props) => {
  const [files, setFiles] = useState<PreparedFile[]>([]);

  const handleFilesChange = (e: PreparedFile[]) => {
    setFiles(e);
    onFiles(e);
  };

  return (
    <>
      <FileDropzone
        onFiles={handleFilesChange}
        className={cn.root}
        accept="text/csv"
      >
        <div className={cn.body}>
          <CloudUploadSvg className={cn.icon} />
          <span className={cn.text}>
            {__('repairCostAndMethod.importCsvUpload.dropFileHere')}
          </span>
          <span className={cn.browse}>
            {__('repairCostAndMethod.importCsvUpload.browse')}
          </span>
        </div>
      </FileDropzone>
      {files.map(({ file }) => (
        <div key={`${file.name}${file.lastModified}`} className={cn.filesList}>
          <div>
            <ClipSvg />
          </div>
          <div className={cn.fileName}>{file.name}</div>
          <div>
            <button
              type="button"
              className={cn.crossButton}
              onClick={() => {
                handleFilesChange(files.filter(item => item.file !== file));
              }}
            >
              <CloseSvg />
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default ImportCsvUpload;
