import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Mutation, MutationCompleteEntryCheckArgs } from 'graphqlTypes';
import COMPLETE_ENTRY_CHECK from './gql/completeEntryCheck';

const useCompleteEntryCheck = (
  options?: MutationHookOptions<
    Pick<Mutation, 'completeEntryCheck'>,
    MutationCompleteEntryCheckArgs
  >,
) => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'completeEntryCheck'>,
    MutationCompleteEntryCheckArgs
  >(COMPLETE_ENTRY_CHECK, options);

  const completeEntryCheck = (id: string) => {
    return mutation({
      variables: { id },
    });
  };

  return [
    completeEntryCheck,
    {
      data: get('completeEntryCheck', data),
      ...rest,
    },
  ] as const;
};

export default useCompleteEntryCheck;
