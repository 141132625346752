export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  Long: number;
  UUID: string;
  Map: any;
  JSON: object;
  Object: any;
  LocalDate: string;
  LocalDateTime: any;
  /** An RFC-3339 compliant date time scalar that accepts string values like 1996-12-19T16:39:57-08:00 and produces java.time.OffsetDateTime objects at runtime */
  DateTime: any;
};

export type CatDamageAttribute = {
  __typename?: 'CatDamageAttribute';
  depth?: Maybe<StringTranslation>;
  diameter?: Maybe<StringTranslation>;
  incomplete?: Maybe<StringTranslation>;
  legalStandard?: Maybe<StringTranslation>;
  length?: Maybe<StringTranslation>;
  location?: Maybe<StringTranslation>;
  mechanicalFailure?: Maybe<StringTranslation>;
  oilLoss?: Maybe<StringTranslation>;
  paintQuality?: Maybe<StringTranslation>;
  thickness?: Maybe<StringTranslation>;
  width?: Maybe<StringTranslation>;
};

export type CatDamage = {
  __typename?: 'CatDamage';
  uuid: Scalars['UUID'];
  area: StringTranslation;
  subArea: StringTranslation;
  attributes: CatDamageAttribute;
  part: StringTranslation;
  type: StringTranslation;
  image: Scalars['String'];
  quantity: StringTranslation;
  matchingScore?: Maybe<DamageMatchingScore>;
  repairCost?: Maybe<CatDamageRepairCost>;
};

export type CatScanScore = {
  __typename?: 'CatScanScore';
  carScore: Scalars['Float'];
  /** might be 0 if no scratches */
  scratchesScore: Scalars['Float'];
  /** might have some default value if no scratches */
  scratchesMostDamagedSide: Scalars['String'];
  /** might be 0 if no dents */
  dentsScore: Scalars['Float'];
  /** might have some default value if no dents */
  dentsMostDamagedSide: Scalars['String'];
};

export type CatDamageRepairCost = {
  __typename?: 'CatDamageRepairCost';
  repairMethod: StringTranslation;
  totalCosts: RepairCostMoney;
  materialCosts: RepairCostMoney;
  splitMaterialCosts: MaterialCost;
  labour: Labour;
};

export type CatScan = {
  __typename?: 'CatScan';
  createdOn: Scalars['LocalDateTime'];
  damages: Array<CatDamage>;
  scoring?: Maybe<CatScanScore>;
};

export type DamageMatchingScore = {
  __typename?: 'DamageMatchingScore';
  score: Scalars['Int'];
  refurbishmentDamageId?: Maybe<Scalars['UUID']>;
};

export enum DamageSourceType {
  Import = 'IMPORT',
  EntryCheckSubmit = 'ENTRY_CHECK_SUBMIT',
  EntryCheckAuthorize = 'ENTRY_CHECK_AUTHORIZE',
  EntryCheckPostAuthorize = 'ENTRY_CHECK_POST_AUTHORIZE',
  QualityCheckSubmit = 'QUALITY_CHECK_SUBMIT',
  QualityCheckAuthorize = 'QUALITY_CHECK_AUTHORIZE',
}

export enum DamageState {
  Open = 'OPEN',
  Inactive = 'INACTIVE',
  Fixed = 'FIXED',
  NotFixed = 'NOT_FIXED',
}

export enum DamageCancellationReason {
  NotFound = 'NOT_FOUND',
  IncorrectVehiclePart = 'INCORRECT_VEHICLE_PART',
}

export type Damage = {
  __typename?: 'Damage';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  attribute1Name?: Maybe<StringTranslation>;
  attribute1Value?: Maybe<StringTranslation>;
  attribute2Name?: Maybe<StringTranslation>;
  attribute2Value?: Maybe<StringTranslation>;
  quantity?: Maybe<StringTranslation>;
  area: StringTranslation;
  subArea?: Maybe<StringTranslation>;
  part: StringTranslation;
  type: StringTranslation;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  sourceType: DamageSourceType;
  state: DamageState;
  customerDisplay: Scalars['Boolean'];
  cancelReason?: Maybe<DamageCancellationReason>;
  cancelComment?: Maybe<Scalars['String']>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
};

export type DamageCancellationReasonResponse = {
  __typename?: 'DamageCancellationReasonResponse';
  reason?: Maybe<DamageCancellationReasonTranslation>;
};

export enum DocumentSourceType {
  BackOffice = 'BACK_OFFICE',
  RefurbishmentApplication = 'REFURBISHMENT_APPLICATION',
}

export enum DocumentState {
  Uploaded = 'UPLOADED',
  Deleted = 'DELETED',
  HardDeleted = 'HARD_DELETED',
}

export enum DocumentUploadSourceType {
  AutoHero = 'AUTO_HERO',
  BackOffice = 'BACK_OFFICE',
  Ftp = 'FTP',
  RefurbishmentApplication = 'REFURBISHMENT_APPLICATION',
}

export type AwsUploadCredentialsInput = {
  uploadDocumentInfos: Array<UploadDocumentInfo>;
};

export type UploadDocumentInfo = {
  id: Scalars['UUID'];
  documentTypeId: Scalars['UUID'];
  fileExt: Scalars['String'];
};

export type CreateDocumentInput = {
  attachedTo: Scalars['UUID'];
  fullUrl: Scalars['String'];
  documentTypeId: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  documentUploadSourceType?: Maybe<DocumentUploadSourceType>;
};

export type DocumentUpdateInput = {
  state: DocumentState;
};

export type DocumentFilter = {
  documentStates: Array<DocumentState>;
  documentSourceTypes: Array<DocumentSourceType>;
  attachedTo: Scalars['ID'];
};

export type Documents = {
  __typename?: 'Documents';
  items: Array<Document>;
  pageInfo: PageInfo;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  attachedTo: Scalars['ID'];
  createdOn: Scalars['Date'];
  state: DocumentState;
  sourceType: DocumentSourceType;
  url: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type: StringTranslation;
  createdBy: User;
};

export type DocumentTopCategoryResponse = {
  __typename?: 'DocumentTopCategoryResponse';
  topCategory: Scalars['String'];
  categories: Array<DocumentCategory>;
};

export type DocumentCategory = {
  __typename?: 'DocumentCategory';
  name: Scalars['String'];
  documentTypes: Array<DocumentType>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  key?: Maybe<StringTranslation>;
};

export type AwsUploadCredentialsResponse = {
  __typename?: 'AwsUploadCredentialsResponse';
  awsUploadCredentials: Array<AwsUploadCredential>;
};

export type AwsUploadCredential = {
  __typename?: 'AwsUploadCredential';
  host?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map']>;
};

export enum EntryCheckState {
  Open = 'OPEN',
  Submitted = 'SUBMITTED',
  Completed = 'COMPLETED',
  PostAuthorized = 'POST_AUTHORIZED',
  Cancelled = 'CANCELLED',
}

export enum EntryCheckEntityType {
  Vehicle = 'VEHICLE',
  Damage = 'DAMAGE',
  Service = 'SERVICE',
  Refurbishment = 'REFURBISHMENT',
  Engine = 'ENGINE',
  Underbody = 'UNDERBODY',
  Interior = 'INTERIOR',
  Inspection = 'INSPECTION',
}

export enum EntryCheckItemValueSourceType {
  EntryCheck = 'ENTRY_CHECK',
  Authorization = 'AUTHORIZATION',
  PostAuthorization = 'POST_AUTHORIZATION',
}

export enum EntryCheckItemValueState {
  Created = 'CREATED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Skipped = 'SKIPPED',
}

export enum Unit {
  Mm = 'MM',
  In = 'IN',
  Cm = 'CM',
}

export enum BrakeFluidLevel {
  AboveMax = 'ABOVE_MAX',
  Max = 'MAX',
  Middle = 'MIDDLE',
  Min = 'MIN',
  BelowMin = 'BELOW_MIN',
}

export enum OilLevelType {
  AboveMax = 'ABOVE_MAX',
  Max = 'MAX',
  Middle = 'MIDDLE',
  Min = 'MIN',
  BelowMin = 'BELOW_MIN',
}

export enum EngineSoundType {
  Roar = 'ROAR',
  Clatter = 'CLATTER',
  Whistle = 'WHISTLE',
  Grumble = 'GRUMBLE',
  Sizzle = 'SIZZLE',
  Rattle = 'RATTLE',
  Squeak = 'SQUEAK',
  Knocking = 'KNOCKING',
}

export enum CoolantLevel {
  AboveMax = 'ABOVE_MAX',
  Max = 'MAX',
  Middle = 'MIDDLE',
  Min = 'MIN',
  BelowMin = 'BELOW_MIN',
}

export enum OptionalBooleanType {
  Yes = 'YES',
  No = 'NO',
  NoAcceptable = 'NO_ACCEPTABLE',
}

export enum BatteryQualityType {
  Good = 'GOOD',
  GoodRecharge = 'GOOD_RECHARGE',
  Replace = 'REPLACE',
}

export enum TirePressureUnit {
  Pascal = 'PASCAL',
  Atmosphere = 'ATMOSPHERE',
  Bar = 'BAR',
}

export type EntryCheckUpdateInput = {
  state: EntryCheckState;
};

export type EntryCheckItemCreateInput = {
  entryCheckId: Scalars['ID'];
  entityType: EntryCheckEntityType;
  proposedValue: Scalars['JSON'];
  comment?: Maybe<Scalars['String']>;
};

export type EntryCheckItemUpdateEntryInput = {
  id: Scalars['UUID'];
  value: EntryCheckItemUpdateInput;
};

export type EntryCheckItemUpdateInput = {
  verified: Scalars['Boolean'];
};

export type EntryCheckItemValueCreateInput = {
  entryCheckItemId: Scalars['UUID'];
  sourceType: EntryCheckItemValueSourceType;
  state?: Maybe<EntryCheckItemValueState>;
  originalValue?: Maybe<Scalars['JSON']>;
  proposedValue?: Maybe<Scalars['JSON']>;
  comment?: Maybe<Scalars['String']>;
};

export type EntryCheckItemValueUpdateInput = {
  state: EntryCheckItemValueState;
  originalValue?: Maybe<Scalars['JSON']>;
  proposedValue?: Maybe<Scalars['JSON']>;
  comment?: Maybe<Scalars['String']>;
};

export type EntryCheckItemValueUpdateEntryInput = {
  id: Scalars['UUID'];
  value: EntryCheckItemValueUpdateInput;
};

export type GraphQlJsonPayload = {
  payloadType: Scalars['String'];
};

export type EntryCheck = {
  __typename?: 'EntryCheck';
  id: Scalars['ID'];
  refurbishment: RefurbishmentDetails;
  state: EntryCheckState;
  items: Array<EntryCheckItem>;
  createdBy?: Maybe<User>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type EntryCheckItem = {
  __typename?: 'EntryCheckItem';
  id: Scalars['ID'];
  entryCheckId: Scalars['ID'];
  entityId?: Maybe<Scalars['ID']>;
  entityType: EntryCheckEntityType;
  values: Array<EntryCheckItemValue>;
  verified?: Maybe<Scalars['Boolean']>;
  verifiedBy?: Maybe<User>;
  verifiedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdOn: Scalars['Date'];
  updatedBy?: Maybe<User>;
  updatedOn?: Maybe<Scalars['Date']>;
};

export type EntryCheckItemValue = {
  __typename?: 'EntryCheckItemValue';
  id: Scalars['ID'];
  entryCheckItemId: Scalars['ID'];
  sourceType: EntryCheckItemValueSourceType;
  state: EntryCheckItemValueState;
  originalValue?: Maybe<GraphQlJsonPayload>;
  proposedValue?: Maybe<GraphQlJsonPayload>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdOn: Scalars['Date'];
  updatedBy?: Maybe<User>;
  updatedOn?: Maybe<Scalars['Date']>;
};

export type CarPart = {
  __typename?: 'CarPart';
  id: Scalars['Int'];
  name: StringTranslation;
  section: StringTranslation;
  subSection: StringTranslation;
};

export type DamageType = {
  __typename?: 'DamageType';
  id: Scalars['Int'];
  name: StringTranslation;
  section: StringTranslation;
  subSection: StringTranslation;
  carPartId: Scalars['Int'];
  carPartName: StringTranslation;
};

export type BrakeType = {
  __typename?: 'BrakeType';
  id: Scalars['UUID'];
  brakeType: StringTranslation;
};

export type BrakeDiskCondition = {
  __typename?: 'BrakeDiskCondition';
  id: Scalars['UUID'];
  brakeTypeId: Scalars['UUID'];
  recordKey: StringTranslation;
  rangeStartInPercentInclusive?: Maybe<Scalars['Int']>;
  rangeEndInPercentExclusive?: Maybe<Scalars['Int']>;
};

export type BrakePadCondition = {
  __typename?: 'BrakePadCondition';
  id: Scalars['UUID'];
  recordKey: StringTranslation;
  rangeStartInPercentInclusive: Scalars['Int'];
  rangeEndInPercentExclusive: Scalars['Int'];
};

export type EntryCheckPageResponse = {
  __typename?: 'EntryCheckPageResponse';
  items: Array<EntryCheck>;
  pageInfo: PageInfo;
};

export type EntryCheckItemPageResponse = {
  __typename?: 'EntryCheckItemPageResponse';
  items: Array<EntryCheckItem>;
  pageInfo: PageInfo;
};

export type BatteryAge = {
  __typename?: 'BatteryAge';
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ServiceBookType = {
  __typename?: 'ServiceBookType';
  id: Scalars['UUID'];
  serviceBookType: StringTranslation;
};

export type ServiceCompany = {
  __typename?: 'ServiceCompany';
  id: Scalars['UUID'];
  serviceCompany: StringTranslation;
};

export type FuelTypePayload = GraphQlJsonPayload & {
  __typename?: 'FuelTypePayload';
  payloadType: Scalars['String'];
  fuelType?: Maybe<IntTranslation>;
};

export type MileagePayload = GraphQlJsonPayload & {
  __typename?: 'MileagePayload';
  payloadType: Scalars['String'];
  distance: Scalars['Int'];
  unit: MileageUnitTranslation;
};

export type WheelSeasonPayload = GraphQlJsonPayload & {
  __typename?: 'WheelSeasonPayload';
  payloadType: Scalars['String'];
  season?: Maybe<IntTranslation>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelProfilePayload = GraphQlJsonPayload & {
  __typename?: 'WheelProfilePayload';
  payloadType: Scalars['String'];
  depth?: Maybe<Scalars['Int']>;
  unit?: Maybe<WheelProfileUnitTranslation>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelRimTypePayload = GraphQlJsonPayload & {
  __typename?: 'WheelRimTypePayload';
  payloadType: Scalars['String'];
  rimType?: Maybe<IntTranslation>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
};

export type BrakeDamagePayload = GraphQlJsonPayload & {
  __typename?: 'BrakeDamagePayload';
  payloadType: Scalars['String'];
  area: StringTranslation;
  subArea: StringTranslation;
  typeName: StringTranslation;
};

export type DamageTypePayload = GraphQlJsonPayload & {
  __typename?: 'DamageTypePayload';
  payloadType: Scalars['String'];
  area: StringTranslation;
  subArea: StringTranslation;
  partName: StringTranslation;
  typeName: StringTranslation;
  displaySection?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  isPA3Damage?: Maybe<Scalars['Boolean']>;
  attribute1Name?: Maybe<StringTranslation>;
  attribute1Value?: Maybe<StringTranslation>;
  attribute2Name?: Maybe<StringTranslation>;
  attribute2Value?: Maybe<StringTranslation>;
  quantity?: Maybe<StringTranslation>;
};

export type DamageImagePayload = GraphQlJsonPayload & {
  __typename?: 'DamageImagePayload';
  payloadType: Scalars['String'];
  photoId?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type DamageServiceNamePayload = GraphQlJsonPayload & {
  __typename?: 'DamageServiceNamePayload';
  payloadType: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: RefurbishmentServiceNameTranslation;
  type: RefurbishmentServiceType;
};

export type DamageCustomerDisplayPayload = GraphQlJsonPayload & {
  __typename?: 'DamageCustomerDisplayPayload';
  payloadType: Scalars['String'];
  customerDisplay?: Maybe<Scalars['Boolean']>;
};

export type VehicleServiceNamePayload = GraphQlJsonPayload & {
  __typename?: 'VehicleServiceNamePayload';
  payloadType: Scalars['String'];
  name: RefurbishmentServiceNameTranslation;
  type: RefurbishmentServiceType;
  isAutoCreate?: Maybe<Scalars['Boolean']>;
};

export type ServiceStatePayload = GraphQlJsonPayload & {
  __typename?: 'ServiceStatePayload';
  payloadType: Scalars['String'];
  serviceState?: Maybe<RefurbishmentServiceState>;
};

export type ServiceBudgetPayload = GraphQlJsonPayload & {
  __typename?: 'ServiceBudgetPayload';
  payloadType: Scalars['String'];
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
};

export type ServiceCommentPayload = GraphQlJsonPayload & {
  __typename?: 'ServiceCommentPayload';
  payloadType: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type ServiceCustomerDisplayPayload = GraphQlJsonPayload & {
  __typename?: 'ServiceCustomerDisplayPayload';
  payloadType: Scalars['String'];
  customerDisplay?: Maybe<Scalars['Boolean']>;
};

export type ProviderTotalCostsPayload = GraphQlJsonPayload & {
  __typename?: 'ProviderTotalCostsPayload';
  payloadType: Scalars['String'];
  providerTotalCostsMinorUnits: Scalars['Long'];
  totalCostBudgetIncludingInternalLaborMinorUnits?: Maybe<Scalars['Long']>;
};

export type DamageCancellationPayload = GraphQlJsonPayload & {
  __typename?: 'DamageCancellationPayload';
  payloadType: Scalars['String'];
  cancelReason?: Maybe<DamageCancellationReasonTranslation>;
  cancelComment?: Maybe<Scalars['String']>;
};

export type BeautyShotPayload = GraphQlJsonPayload & {
  __typename?: 'BeautyShotPayload';
  payloadType: Scalars['String'];
  beautyShot: BeautyShotTranslation;
};

export type RimSizePayload = GraphQlJsonPayload & {
  __typename?: 'RimSizePayload';
  payloadType: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelDotPayload = GraphQlJsonPayload & {
  __typename?: 'WheelDotPayload';
  payloadType: Scalars['String'];
  dot?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelImagePayload = GraphQlJsonPayload & {
  __typename?: 'WheelImagePayload';
  payloadType: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelLoadIndexPayload = GraphQlJsonPayload & {
  __typename?: 'WheelLoadIndexPayload';
  payloadType: Scalars['String'];
  loadIndex?: Maybe<Scalars['Int']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelManufacturerPayload = GraphQlJsonPayload & {
  __typename?: 'WheelManufacturerPayload';
  payloadType: Scalars['String'];
  manufacturer?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelSizePayload = GraphQlJsonPayload & {
  __typename?: 'WheelSizePayload';
  payloadType: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  height?: Maybe<Scalars['Int']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelSpeedIndexPayload = GraphQlJsonPayload & {
  __typename?: 'WheelSpeedIndexPayload';
  payloadType: Scalars['String'];
  speedIndex?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
};

export type WheelSetsSwitchPayload = GraphQlJsonPayload & {
  __typename?: 'WheelSetsSwitchPayload';
  payloadType: Scalars['String'];
  switched?: Maybe<Scalars['Boolean']>;
};

export type WheelSetDeletePayload = GraphQlJsonPayload & {
  __typename?: 'WheelSetDeletePayload';
  payloadType: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<WheelSetDeleteReasonTranslation>;
};

export type SmokerPayload = GraphQlJsonPayload & {
  __typename?: 'SmokerPayload';
  payloadType: Scalars['String'];
  isSmoker?: Maybe<Scalars['Boolean']>;
};

export type AccidentCarPayload = GraphQlJsonPayload & {
  __typename?: 'AccidentCarPayload';
  payloadType: Scalars['String'];
  isAccidentCar?: Maybe<Scalars['Boolean']>;
};

export type OriginalEnginePayload = GraphQlJsonPayload & {
  __typename?: 'OriginalEnginePayload';
  payloadType: Scalars['String'];
  hasOriginalEngine?: Maybe<Scalars['Boolean']>;
};

export type OriginalUpholsteryPayload = GraphQlJsonPayload & {
  __typename?: 'OriginalUpholsteryPayload';
  payloadType: Scalars['String'];
  hasOriginalUpholstery?: Maybe<Scalars['Boolean']>;
};

export type WasInCommercialUsePayload = GraphQlJsonPayload & {
  __typename?: 'WasInCommercialUsePayload';
  payloadType: Scalars['String'];
  wasInCommercialUse?: Maybe<Scalars['Boolean']>;
};

export type AacCondenserPayload = GraphQlJsonPayload & {
  __typename?: 'AACCondenserPayload';
  payloadType: Scalars['String'];
  isCondenserConditionOk?: Maybe<Scalars['Boolean']>;
};

export type AbsOpticalConditionPayload = GraphQlJsonPayload & {
  __typename?: 'ABSOpticalConditionPayload';
  payloadType: Scalars['String'];
  isABSOpticalOk?: Maybe<Scalars['Boolean']>;
};

export type AcSystemLeaksPayload = GraphQlJsonPayload & {
  __typename?: 'ACSystemLeaksPayload';
  payloadType: Scalars['String'];
  hasACSystemLeaks?: Maybe<Scalars['Boolean']>;
};

export type AlternatorPayload = GraphQlJsonPayload & {
  __typename?: 'AlternatorPayload';
  payloadType: Scalars['String'];
  isAlternatorConditionOk?: Maybe<Scalars['Boolean']>;
};

export type BatteryPayload = GraphQlJsonPayload & {
  __typename?: 'BatteryPayload';
  payloadType: Scalars['String'];
  qualityType?: Maybe<BatteryQualityTypeTranslation>;
  age?: Maybe<BatteryAge>;
  voltage?: Maybe<Scalars['String']>;
  chargeLoad?: Maybe<Scalars['Int']>;
};

export type BatteryTesterPayload = GraphQlJsonPayload & {
  __typename?: 'BatteryTesterPayload';
  payloadType: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type BlanketInsulatorPayload = GraphQlJsonPayload & {
  __typename?: 'BlanketInsulatorPayload';
  payloadType: Scalars['String'];
  hasBlanketInsulator?: Maybe<Scalars['Boolean']>;
};

export type BrakeFluidPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeFluidPayload';
  payloadType: Scalars['String'];
  brakeFluidLevel?: Maybe<BrakeFluidLevelTranslation>;
  condition?: Maybe<Scalars['Int']>;
};

export type BrakeMasterConditionPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeMasterConditionPayload';
  payloadType: Scalars['String'];
  isBrakeMasterOk?: Maybe<Scalars['Boolean']>;
};

export type CambeltPayload = GraphQlJsonPayload & {
  __typename?: 'CambeltPayload';
  payloadType: Scalars['String'];
  isCambeltConditionOk?: Maybe<OptionalBooleanType>;
};

export type CoolantHosesConditionPayload = GraphQlJsonPayload & {
  __typename?: 'CoolantHosesConditionPayload';
  payloadType: Scalars['String'];
  isCoolantHosesOk?: Maybe<Scalars['Boolean']>;
};

export type CoolantPayload = GraphQlJsonPayload & {
  __typename?: 'CoolantPayload';
  payloadType: Scalars['String'];
  coolantLevel?: Maybe<CoolantLevelTranslation>;
  temperature?: Maybe<Scalars['Float']>;
};

export type CoolantRecoveryConditionPayload = GraphQlJsonPayload & {
  __typename?: 'CoolantRecoveryConditionPayload';
  payloadType: Scalars['String'];
  isCoolantRecoveryOk?: Maybe<Scalars['Boolean']>;
};

export type HoodReleasePayload = GraphQlJsonPayload & {
  __typename?: 'HoodReleasePayload';
  payloadType: Scalars['String'];
  doesHoodRelease?: Maybe<Scalars['Boolean']>;
};

export type EngineRunningPayload = GraphQlJsonPayload & {
  __typename?: 'EngineRunningPayload';
  payloadType: Scalars['String'];
  doesEngineRunning?: Maybe<Scalars['Boolean']>;
};

export type EngineSoundPayload = GraphQlJsonPayload & {
  __typename?: 'EngineSoundPayload';
  payloadType: Scalars['String'];
  doesEngineSoundNormalNoise?: Maybe<Scalars['Boolean']>;
  engineSoundType?: Maybe<EngineSoundTypeTranslation>;
  comment?: Maybe<Scalars['String']>;
};

export type FuelSystemPayload = GraphQlJsonPayload & {
  __typename?: 'FuelSystemPayload';
  payloadType: Scalars['String'];
  isFuelSystemConditionOk?: Maybe<Scalars['Boolean']>;
};

export type IgnitionSystemOperationPayload = GraphQlJsonPayload & {
  __typename?: 'IgnitionSystemOperationPayload';
  payloadType: Scalars['String'];
  isIgnitionSystemOperationConditionOk?: Maybe<OptionalBooleanType>;
};

export type IntercoolerPayload = GraphQlJsonPayload & {
  __typename?: 'IntercoolerPayload';
  payloadType: Scalars['String'];
  isIntercoolerConditionOk?: Maybe<Scalars['Boolean']>;
};

export type OilCoolerPayload = GraphQlJsonPayload & {
  __typename?: 'OilCoolerPayload';
  payloadType: Scalars['String'];
  isOilCoolerConditionOk?: Maybe<Scalars['Boolean']>;
};

export type OilLevelPayload = GraphQlJsonPayload & {
  __typename?: 'OilLevelPayload';
  payloadType: Scalars['String'];
  oilLevelType?: Maybe<OilLevelTypeTranslation>;
};

export type PowerSteeringPayload = GraphQlJsonPayload & {
  __typename?: 'PowerSteeringPayload';
  payloadType: Scalars['String'];
  isPowerSteeringConditionOk?: Maybe<Scalars['Boolean']>;
};

export type RadiatorConditionPayload = GraphQlJsonPayload & {
  __typename?: 'RadiatorConditionPayload';
  payloadType: Scalars['String'];
  isRadiatorOk?: Maybe<Scalars['Boolean']>;
};

export type RadiatorFanConditionPayload = GraphQlJsonPayload & {
  __typename?: 'RadiatorFanConditionPayload';
  payloadType: Scalars['String'];
  isRadiatorFanOk?: Maybe<Scalars['Boolean']>;
};

export type TimingChainPayload = GraphQlJsonPayload & {
  __typename?: 'TimingChainPayload';
  payloadType: Scalars['String'];
  isTimingChainConditionOk?: Maybe<OptionalBooleanType>;
};

export type VBeltPayload = GraphQlJsonPayload & {
  __typename?: 'VBeltPayload';
  payloadType: Scalars['String'];
  isVBeltConditionOk?: Maybe<Scalars['Boolean']>;
};

export type WaterLeaksConditionPayload = GraphQlJsonPayload & {
  __typename?: 'WaterLeaksConditionPayload';
  payloadType: Scalars['String'];
  hasWaterLeaks?: Maybe<Scalars['Boolean']>;
};

export type WaterPumpConditionPayload = GraphQlJsonPayload & {
  __typename?: 'WaterPumpConditionPayload';
  payloadType: Scalars['String'];
  isWaterPumpOk?: Maybe<Scalars['Boolean']>;
};

export type WindshieldFluidLevelPayload = GraphQlJsonPayload & {
  __typename?: 'WindshieldFluidLevelPayload';
  payloadType: Scalars['String'];
  isWindshieldFluidLevelOk?: Maybe<Scalars['Boolean']>;
};

export type AxleJointFlPayload = GraphQlJsonPayload & {
  __typename?: 'AxleJointFLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type AxleJointFrPayload = GraphQlJsonPayload & {
  __typename?: 'AxleJointFRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type AxleJointRlPayload = GraphQlJsonPayload & {
  __typename?: 'AxleJointRLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type AxleJointRrPayload = GraphQlJsonPayload & {
  __typename?: 'AxleJointRRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type BackExhaustPayload = GraphQlJsonPayload & {
  __typename?: 'BackExhaustPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type BrakeLineFlPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeLineFLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type BrakeLineFrPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeLineFRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type BrakeLineRlPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeLineRLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type BrakeLineRrPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeLineRRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type CatalyticConverterPayload = GraphQlJsonPayload & {
  __typename?: 'CatalyticConverterPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type CenterExhaustPayload = GraphQlJsonPayload & {
  __typename?: 'CenterExhaustPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type DifferentialPayload = GraphQlJsonPayload & {
  __typename?: 'DifferentialPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type DriveshaftFlPayload = GraphQlJsonPayload & {
  __typename?: 'DriveshaftFLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type DriveshaftFrPayload = GraphQlJsonPayload & {
  __typename?: 'DriveshaftFRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type DriveshaftRlPayload = GraphQlJsonPayload & {
  __typename?: 'DriveshaftRLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type DriveshaftRrPayload = GraphQlJsonPayload & {
  __typename?: 'DriveshaftRRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type EngineMountPayload = GraphQlJsonPayload & {
  __typename?: 'EngineMountPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type ExhaustMountPayload = GraphQlJsonPayload & {
  __typename?: 'ExhaustMountPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type FuelTankPayload = GraphQlJsonPayload & {
  __typename?: 'FuelTankPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type HandbrakeCableLeftPayload = GraphQlJsonPayload & {
  __typename?: 'HandbrakeCableLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type HandbrakeCableRightPayload = GraphQlJsonPayload & {
  __typename?: 'HandbrakeCableRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type JackPointFrontLeftPayload = GraphQlJsonPayload & {
  __typename?: 'JackPointFrontLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type JackPointFrontRightPayload = GraphQlJsonPayload & {
  __typename?: 'JackPointFrontRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type JackPointRearLeftPayload = GraphQlJsonPayload & {
  __typename?: 'JackPointRearLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type JackPointRearRightPayload = GraphQlJsonPayload & {
  __typename?: 'JackPointRearRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type ManifoldPayload = GraphQlJsonPayload & {
  __typename?: 'ManifoldPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type ManualTransmissionCablesPayload = GraphQlJsonPayload & {
  __typename?: 'ManualTransmissionCablesPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type ParticleFilterPayload = GraphQlJsonPayload & {
  __typename?: 'ParticleFilterPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type PropellerShaftPayload = GraphQlJsonPayload & {
  __typename?: 'PropellerShaftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SillLeftPayload = GraphQlJsonPayload & {
  __typename?: 'SillLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SillRightPayload = GraphQlJsonPayload & {
  __typename?: 'SillRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SteeringCircuitPayload = GraphQlJsonPayload & {
  __typename?: 'SteeringCircuitPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SteeringDustCapsPayload = GraphQlJsonPayload & {
  __typename?: 'SteeringDustCapsPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SteeringPumpPayload = GraphQlJsonPayload & {
  __typename?: 'SteeringPumpPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SteeringRackPayload = GraphQlJsonPayload & {
  __typename?: 'SteeringRackPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type SteeringReservoirPayload = GraphQlJsonPayload & {
  __typename?: 'SteeringReservoirPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TorqueRodsFrontPayload = GraphQlJsonPayload & {
  __typename?: 'TorqueRodsFrontPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TorqueRodsRearPayload = GraphQlJsonPayload & {
  __typename?: 'TorqueRodsRearPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TorqueSupportPayload = GraphQlJsonPayload & {
  __typename?: 'TorqueSupportPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TrackRodEndLeftPayload = GraphQlJsonPayload & {
  __typename?: 'TrackRodEndLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TrackRodEndRightPayload = GraphQlJsonPayload & {
  __typename?: 'TrackRodEndRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TransmissionLeaksPayload = GraphQlJsonPayload & {
  __typename?: 'TransmissionLeaksPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TransmissionMountPayload = GraphQlJsonPayload & {
  __typename?: 'TransmissionMountPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type TurbochargerPayload = GraphQlJsonPayload & {
  __typename?: 'TurbochargerPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderbodyFrontPayload = GraphQlJsonPayload & {
  __typename?: 'UnderbodyFrontPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderbodyLeftPayload = GraphQlJsonPayload & {
  __typename?: 'UnderbodyLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderbodyRearPayload = GraphQlJsonPayload & {
  __typename?: 'UnderbodyRearPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderbodyRightPayload = GraphQlJsonPayload & {
  __typename?: 'UnderbodyRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderrideGuardEnginePayload = GraphQlJsonPayload & {
  __typename?: 'UnderrideGuardEnginePayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderrideGuardLeftPayload = GraphQlJsonPayload & {
  __typename?: 'UnderrideGuardLeftPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderrideGuardRearPayload = GraphQlJsonPayload & {
  __typename?: 'UnderrideGuardRearPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UnderrideGuardRightPayload = GraphQlJsonPayload & {
  __typename?: 'UnderrideGuardRightPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UniversalJointsFlPayload = GraphQlJsonPayload & {
  __typename?: 'UniversalJointsFLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UniversalJointsFrPayload = GraphQlJsonPayload & {
  __typename?: 'UniversalJointsFRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UniversalJointsRlPayload = GraphQlJsonPayload & {
  __typename?: 'UniversalJointsRLPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type UniversalJointsRrPayload = GraphQlJsonPayload & {
  __typename?: 'UniversalJointsRRPayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type DoesRimCenterCapExistsPayload = GraphQlJsonPayload & {
  __typename?: 'DoesRimCenterCapExistsPayload';
  payloadType: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsRimInGoodConditionPayload = GraphQlJsonPayload & {
  __typename?: 'IsRimInGoodConditionPayload';
  payloadType: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsTireDirectionCorrectPayload = GraphQlJsonPayload & {
  __typename?: 'IsTireDirectionCorrectPayload';
  payloadType: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsTireInGoodConditionPayload = GraphQlJsonPayload & {
  __typename?: 'IsTireInGoodConditionPayload';
  payloadType: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsTirePressureCorrectPayload = GraphQlJsonPayload & {
  __typename?: 'IsTirePressureCorrectPayload';
  payloadType: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type TirePressurePayload = GraphQlJsonPayload & {
  __typename?: 'TirePressurePayload';
  payloadType: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  position: Scalars['String'];
  valueMinorUnits?: Maybe<Scalars['Int']>;
  unit?: Maybe<TirePressureUnit>;
};

export type AreBrakeHosesInGoodConditionPayload = GraphQlJsonPayload & {
  __typename?: 'AreBrakeHosesInGoodConditionPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type BrakeTypePayload = GraphQlJsonPayload & {
  __typename?: 'BrakeTypePayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  brakeType?: Maybe<Scalars['String']>;
};

export type DoesBrakeFluidCapExistsPayload = GraphQlJsonPayload & {
  __typename?: 'DoesBrakeFluidCapExistsPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsBrakeCaliperInGoodConditionPayload = GraphQlJsonPayload & {
  __typename?: 'IsBrakeCaliperInGoodConditionPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type BrakeDiskConditionPayload = GraphQlJsonPayload & {
  __typename?: 'BrakeDiskConditionPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
};

export type BrakePadConditionPayload = GraphQlJsonPayload & {
  __typename?: 'BrakePadConditionPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
};

export type AreSpringsOkPayload = GraphQlJsonPayload & {
  __typename?: 'AreSpringsOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type AreSupportArmsOkPayload = GraphQlJsonPayload & {
  __typename?: 'AreSupportArmsOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DoesBallJointWorkPayload = GraphQlJsonPayload & {
  __typename?: 'DoesBallJointWorkPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type DoesDustCoverExistsPayload = GraphQlJsonPayload & {
  __typename?: 'DoesDustCoverExistsPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsBumpStopOkPayload = GraphQlJsonPayload & {
  __typename?: 'IsBumpStopOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsControlArmOkPayload = GraphQlJsonPayload & {
  __typename?: 'IsControlArmOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsShockAbsorberOkPayload = GraphQlJsonPayload & {
  __typename?: 'IsShockAbsorberOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsStabilizerBearingOkPayload = GraphQlJsonPayload & {
  __typename?: 'IsStabilizerBearingOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsStrutBearingOkPayload = GraphQlJsonPayload & {
  __typename?: 'IsStrutBearingOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type IsWheelBearingOkPayload = GraphQlJsonPayload & {
  __typename?: 'IsWheelBearingOKPayload';
  payloadType: Scalars['String'];
  position: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};

export type AreRimsAndTiresLicensedForThisCarPayload = GraphQlJsonPayload & {
  __typename?: 'AreRimsAndTiresLicensedForThisCarPayload';
  payloadType: Scalars['String'];
  isSecondaryWheelSet: Scalars['Boolean'];
  value?: Maybe<Scalars['Boolean']>;
};

export type ChargingCablePayload = GraphQlJsonPayload & {
  __typename?: 'ChargingCablePayload';
  payloadType: Scalars['String'];
  hasChargingCable?: Maybe<OptionalBooleanType>;
};

export type DoesTheCarRequireAServicePayload = GraphQlJsonPayload & {
  __typename?: 'DoesTheCarRequireAServicePayload';
  payloadType: Scalars['String'];
  checkValue?: Maybe<Scalars['Boolean']>;
};

export type InspectionNeededPayload = GraphQlJsonPayload & {
  __typename?: 'InspectionNeededPayload';
  payloadType: Scalars['String'];
  inspectionNeeded?: Maybe<Scalars['Boolean']>;
};

export type DoneBeforeEntryCheckPayload = GraphQlJsonPayload & {
  __typename?: 'DoneBeforeEntryCheckPayload';
  payloadType: Scalars['String'];
  doneBeforeEntryCheck?: Maybe<Scalars['Boolean']>;
};

export type DidCarPassInspectionPayload = GraphQlJsonPayload & {
  __typename?: 'DidCarPassInspectionPayload';
  payloadType: Scalars['String'];
  didCarPassInspection?: Maybe<Scalars['Boolean']>;
};

export type LastDatePayload = GraphQlJsonPayload & {
  __typename?: 'LastDatePayload';
  payloadType: Scalars['String'];
  lastDate?: Maybe<Scalars['LocalDate']>;
};

export type LastMileagePayload = GraphQlJsonPayload & {
  __typename?: 'LastMileagePayload';
  payloadType: Scalars['String'];
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<MileageUnitTranslation>;
};

export type LastDueDatePayload = GraphQlJsonPayload & {
  __typename?: 'LastDueDatePayload';
  payloadType: Scalars['String'];
  lastDueDate?: Maybe<Scalars['LocalDate']>;
};

export type PricingBudgetCosts = {
  __typename?: 'PricingBudgetCosts';
  technicalInspection?: Maybe<Scalars['Int']>;
  opticalRepair?: Maybe<Scalars['Int']>;
  mechanicalRepair?: Maybe<Scalars['Int']>;
  maintenance?: Maybe<Scalars['Int']>;
  interiorCleaning?: Maybe<Scalars['Int']>;
  exteriorCleaning?: Maybe<Scalars['Int']>;
  photos?: Maybe<Scalars['Int']>;
  workshopHandling?: Maybe<Scalars['Int']>;
  exitCheckCleaning?: Maybe<Scalars['Int']>;
  exitCheckPolishing?: Maybe<Scalars['Int']>;
  refueling?: Maybe<Scalars['Int']>;
  extraCleaningWork?: Maybe<Scalars['Int']>;
};

export type PartnerWorkshopConnectionPayload = GraphQlJsonPayload & {
  __typename?: 'PartnerWorkshopConnectionPayload';
  payloadType: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};

export type KeysCountPayload = GraphQlJsonPayload & {
  __typename?: 'KeysCountPayload';
  payloadType: Scalars['String'];
  keysCount?: Maybe<Scalars['Int']>;
};

export type ServiceBookPayload = GraphQlJsonPayload & {
  __typename?: 'ServiceBookPayload';
  payloadType: Scalars['String'];
  serviceBookType?: Maybe<StringTranslation>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
};

export type EcCommonPayload = GraphQlJsonPayload & {
  __typename?: 'EcCommonPayload';
  payloadType: Scalars['String'];
  sparePart?: Maybe<SparePart>;
};

export type SparePart = {
  __typename?: 'SparePart';
  status?: Maybe<SparePartStatusTranslation>;
  comment?: Maybe<Scalars['String']>;
  lastStatusUpdatedOn?: Maybe<Scalars['LocalDateTime']>;
};

export enum TranslationType {
  NormalKey = 'NORMAL_KEY',
  Attribute = 'ATTRIBUTE',
}

export enum TranslationProject {
  RefurbishmentPortal = 'REFURBISHMENT_PORTAL',
  DamagesAndEquipment = 'DAMAGES_AND_EQUIPMENT',
  CarConditionsEvaluation = 'CAR_CONDITIONS_EVALUATION',
  None = 'NONE',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum UpdateOperationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export enum UpdateOperationErrorType {
  LastTaskItem = 'LAST_TASK_ITEM',
}

export enum ExitCheckState {
  Ordered = 'ORDERED',
  Prepared = 'PREPARED',
  RepairsNeeded = 'REPAIRS_NEEDED',
  RepairsInProgress = 'REPAIRS_IN_PROGRESS',
  RepairsCompleted = 'REPAIRS_COMPLETED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum ExitCheckDamageState {
  RepairNeeded = 'REPAIR_NEEDED',
  Repaired = 'REPAIRED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
}

export enum ExitCheckValueState {
  Created = 'CREATED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
}

export enum ExitCheckServiceState {
  ServiceNeeded = 'SERVICE_NEEDED',
  Done = 'DONE',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
}

export enum ExitCheckGpsType {
  Mobile = 'MOBILE',
  BuiltIn = 'BUILT_IN',
}

export enum ExitCheckValueType {
  Mileage = 'MILEAGE',
  FrontLicensePlate = 'FRONT_LICENSE_PLATE',
  BackLicensePlate = 'BACK_LICENSE_PLATE',
  ReadyExterior = 'READY_EXTERIOR',
  AntennaExists = 'ANTENNA_EXISTS',
  CarStarts = 'CAR_STARTS',
  WarningLights = 'WARNING_LIGHTS',
  FuelLevel = 'FUEL_LEVEL',
  Gps = 'GPS',
  Radio = 'RADIO',
  FirstAidKit = 'FIRST_AID_KIT',
  WarningVest = 'WARNING_VEST',
  SecurityTriangle = 'SECURITY_TRIANGLE',
  TireFit = 'TIRE_FIT',
  TrunkCover = 'TRUNK_COVER',
  FloorMats = 'FLOOR_MATS',
  SpareTire = 'SPARE_TIRE',
  ReadyInterior = 'READY_INTERIOR',
  PrimaryFrontRightProfile = 'PRIMARY_FRONT_RIGHT_PROFILE',
  PrimaryBackRightProfile = 'PRIMARY_BACK_RIGHT_PROFILE',
  PrimaryFrontLeftProfile = 'PRIMARY_FRONT_LEFT_PROFILE',
  PrimaryBackLeftProfile = 'PRIMARY_BACK_LEFT_PROFILE',
  PrimaryFrontRightRimType = 'PRIMARY_FRONT_RIGHT_RIM_TYPE',
  PrimaryBackRightRimType = 'PRIMARY_BACK_RIGHT_RIM_TYPE',
  PrimaryFrontLeftRimType = 'PRIMARY_FRONT_LEFT_RIM_TYPE',
  PrimaryBackLeftRimType = 'PRIMARY_BACK_LEFT_RIM_TYPE',
  PrimaryFrontRightSeason = 'PRIMARY_FRONT_RIGHT_SEASON',
  PrimaryBackRightSeason = 'PRIMARY_BACK_RIGHT_SEASON',
  PrimaryFrontLeftSeason = 'PRIMARY_FRONT_LEFT_SEASON',
  PrimaryBackLeftSeason = 'PRIMARY_BACK_LEFT_SEASON',
  SecondaryWheels = 'SECONDARY_WHEELS',
  PrimaryWheelsSeasonMatch = 'PRIMARY_WHEELS_SEASON_MATCH',
  Battery = 'BATTERY',
  ServiceWorkRequired = 'SERVICE_WORK_REQUIRED',
}

export enum ExitCheckValuePayloadType {
  Boolean = 'BOOLEAN',
  FuelLevel = 'FUEL_LEVEL',
  Gps = 'GPS',
  Mileage = 'MILEAGE',
  Radio = 'RADIO',
  WarningLights = 'WARNING_LIGHTS',
  WheelProfile = 'WHEEL_PROFILE',
  WheelRimType = 'WHEEL_RIM_TYPE',
  WheelSeason = 'WHEEL_SEASON',
  Battery = 'BATTERY',
}

export enum ExitCheckSecondaryWheelsInfo {
  Ordered = 'ORDERED',
  NotOrdered = 'NOT_ORDERED',
  NotApplicable = 'NOT_APPLICABLE',
}

export enum BatteryQuality {
  Good = 'GOOD',
  GoodRecharge = 'GOOD_RECHARGE',
  Replace = 'REPLACE',
}

export enum ExitCheckDeliveryType {
  Standard = 'STANDARD',
  Express = 'EXPRESS',
}

export type ExitCheckSearchFilterInput = {
  vinOrStockNumber?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  states?: Maybe<Array<ExitCheckState>>;
  locationIds?: Maybe<Array<Scalars['Int']>>;
  partnerIds?: Maybe<Array<Scalars['Int']>>;
  mainWorkshopIds?: Maybe<Array<Scalars['Int']>>;
};

export type ExitCheckDamageCreateInput = {
  area: Scalars['String'];
  subArea: Scalars['String'];
  partName: Scalars['String'];
  typeName: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  serviceBudget?: Maybe<Scalars['Int']>;
  imageUrl: Scalars['String'];
};

export type ExitCheckDamageUpdateInput = {
  partName: Scalars['String'];
  typeName: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  serviceBudget?: Maybe<Scalars['Int']>;
  imageUrl: Scalars['String'];
};

export type ExitCheckServiceCreateInput = {
  serviceName: Scalars['String'];
  serviceBudget: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type ExitCheckServiceUpdateInput = {
  serviceName: Scalars['String'];
  serviceBudget: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type ExitCheckServiceWorkCreateInput = {
  serviceBudget: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  serviceCompany?: Maybe<Scalars['String']>;
  serviceMileage?: Maybe<ServiceMileageInput>;
  serviceDate?: Maybe<Scalars['LocalDate']>;
  serviceDueDate?: Maybe<Scalars['LocalDate']>;
  serviceDueMileage?: Maybe<ServiceMileageInput>;
  serviceBookType?: Maybe<Scalars['String']>;
  serviceWorkItems: Array<Scalars['String']>;
};

export type ExitCheckServiceWorkUpdateInput = {
  serviceBudget: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  serviceCompany?: Maybe<Scalars['String']>;
  serviceMileage?: Maybe<ServiceMileageInput>;
  serviceDate?: Maybe<Scalars['LocalDate']>;
  serviceDueDate?: Maybe<Scalars['LocalDate']>;
  serviceDueMileage?: Maybe<ServiceMileageInput>;
  serviceBookType?: Maybe<Scalars['String']>;
  serviceWorkItems: Array<Scalars['String']>;
};

export type ExitCheckValueUpdateInput = {
  state: ExitCheckValueState;
  proposedValue?: Maybe<Scalars['Object']>;
};

export type ExitCheckPhotoCreateInput = {
  type: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ExitCheckPhotoUpdateInput = {
  imageUrl: Scalars['String'];
};

export type ServiceMileageInput = {
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<MileageUnit>;
};

export type VerificationQuestionUpdateInput = {
  answer?: Maybe<Scalars['Boolean']>;
};

export type ExitCheckSearchItem = {
  __typename?: 'ExitCheckSearchItem';
  details: ExitCheckDetails;
  vehicle?: Maybe<Vehicle>;
  carPosition?: Maybe<Scalars['String']>;
};

export type ExitCheckDetails = {
  __typename?: 'ExitCheckDetails';
  id: Scalars['ID'];
  adId: Scalars['ID'];
  state: ExitCheckStateTranslation;
  createdOn: Scalars['Date'];
  repairEta?: Maybe<Scalars['Date']>;
  currencyCode: CurrencyCode;
  secondaryWheelsInfo?: Maybe<ExitCheckSecondaryWheelsInfo>;
  lastCompletedExitCheckId?: Maybe<Scalars['UUID']>;
  lastExitCheckCompletedOn?: Maybe<Scalars['Date']>;
  isOrderCancelled: Scalars['Boolean'];
  completedOn?: Maybe<Scalars['Date']>;
  location?: Maybe<Location>;
  stockNumber?: Maybe<Scalars['String']>;
  isHomeDelivered: Scalars['Boolean'];
  deliveryType?: Maybe<ExitCheckDeliveryType>;
};

export type ExitCheckPageResponse = {
  __typename?: 'ExitCheckPageResponse';
  items: Array<ExitCheckSearchItem>;
  pageInfo: PageInfo;
};

export type ExitCheckDamage = {
  __typename?: 'ExitCheckDamage';
  id: Scalars['UUID'];
  area: StringTranslation;
  subArea: StringTranslation;
  partName: StringTranslation;
  typeName: StringTranslation;
  comment?: Maybe<Scalars['String']>;
  state: ExitCheckDamageState;
  serviceName: RefurbishmentServiceNameTranslation;
  serviceBudget?: Maybe<Scalars['Int']>;
  imageUrl: Scalars['String'];
};

export type ExitCheckImperfection = {
  __typename?: 'ExitCheckImperfection';
  id: Scalars['UUID'];
  area: StringTranslation;
  subArea: StringTranslation;
  partName: StringTranslation;
  typeName: StringTranslation;
  serviceName: RefurbishmentServiceNameTranslation;
  serviceBudget?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type ExitCheckValue = {
  __typename?: 'ExitCheckValue';
  id: Scalars['UUID'];
  valueType: ExitCheckValueType;
  originalValue?: Maybe<ExitCheckJsonValuePayload>;
  proposedValue?: Maybe<ExitCheckJsonValuePayload>;
  state: ExitCheckValueState;
};

export type ExitCheckService = {
  __typename?: 'ExitCheckService';
  id: Scalars['UUID'];
  serviceName: RefurbishmentServiceNameTranslation;
  serviceBudget: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  state: ExitCheckServiceState;
};

export type ExitCheckServiceWork = {
  __typename?: 'ExitCheckServiceWork';
  exitCheckId: Scalars['UUID'];
  serviceBudget: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  serviceCompany?: Maybe<Scalars['String']>;
  serviceMileage?: Maybe<ServiceMileage>;
  serviceDate?: Maybe<Scalars['LocalDate']>;
  serviceDueMileage?: Maybe<ServiceMileage>;
  serviceDueDate?: Maybe<Scalars['LocalDate']>;
  serviceBookType?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
  createdById?: Maybe<Scalars['UUID']>;
  updatedById?: Maybe<Scalars['UUID']>;
  serviceWorkItems: Array<RefurbishmentServiceNameTranslation>;
};

export type ExitCheckData = {
  __typename?: 'ExitCheckData';
  details: ExitCheckDetails;
  damages?: Maybe<Array<ExitCheckDamage>>;
  imperfections?: Maybe<Array<ExitCheckImperfection>>;
  documents?: Maybe<Array<Document>>;
  vehicle: Vehicle;
  values?: Maybe<Array<ExitCheckValue>>;
  services?: Maybe<Array<ExitCheckService>>;
  serviceWork?: Maybe<ExitCheckServiceWork>;
  totalCost?: Maybe<Scalars['Int']>;
  totalCostTaken?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<ExitCheckComment>>;
  refurbishment?: Maybe<RefurbishmentDetails>;
  photos: Array<ExitCheckPhoto>;
  verificationQuestions?: Maybe<Array<VerificationQuestionAggregate>>;
};

export type ExitCheckComment = {
  __typename?: 'ExitCheckComment';
  id: Scalars['UUID'];
  exitCheckId: Scalars['ID'];
  comment: Scalars['String'];
  createdBy: User;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
};

export type ExitCheckPhoto = {
  __typename?: 'ExitCheckPhoto';
  id: Scalars['UUID'];
  exitCheckId: Scalars['ID'];
  type: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ServiceMileage = {
  __typename?: 'ServiceMileage';
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<MileageUnit>;
};

export type VerificationQuestionAggregate = {
  __typename?: 'VerificationQuestionAggregate';
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  question: StringTranslation;
  answer?: Maybe<Scalars['Boolean']>;
  blocksExitCheckSubmit: Scalars['Boolean'];
  orderNumber: Scalars['Int'];
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
  updatedById?: Maybe<Scalars['UUID']>;
};

export type ExitCheckValuePayload = {
  payloadType: ExitCheckValuePayloadType;
};

export type MileageEcv = ExitCheckValuePayload & {
  __typename?: 'MileageEcv';
  payloadType: ExitCheckValuePayloadType;
  distance: Scalars['Int'];
  unit: MileageUnitTranslation;
};

export type WarningLightsEcv = ExitCheckValuePayload & {
  __typename?: 'WarningLightsEcv';
  payloadType: ExitCheckValuePayloadType;
  brakeWarningLight: Scalars['Boolean'];
  batteryWarningLight: Scalars['Boolean'];
  engineOilLevel: Scalars['Boolean'];
  airbagWarningLight: Scalars['Boolean'];
  lowTirePressureWarningLight: Scalars['Boolean'];
  engineWarningLight: Scalars['Boolean'];
  brakePadWarningLight: Scalars['Boolean'];
  tractionControlSystemWarningLight: Scalars['Boolean'];
  coolantTemperatureWarningLight: Scalars['Boolean'];
  absWarningLight: Scalars['Boolean'];
};

export type FuelLevelEcv = ExitCheckValuePayload & {
  __typename?: 'FuelLevelEcv';
  payloadType: ExitCheckValuePayloadType;
  level: Scalars['Int'];
};

export type GpsEcv = ExitCheckValuePayload & {
  __typename?: 'GpsEcv';
  payloadType: ExitCheckValuePayloadType;
  exists: Scalars['Boolean'];
  type?: Maybe<ExitCheckGpsTypeTranslation>;
  manufacturer?: Maybe<Scalars['String']>;
};

export type RadioEcv = ExitCheckValuePayload & {
  __typename?: 'RadioEcv';
  payloadType: ExitCheckValuePayloadType;
  exists: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
};

export type BatteryEcv = ExitCheckValuePayload & {
  __typename?: 'BatteryEcv';
  payloadType: ExitCheckValuePayloadType;
  age?: Maybe<BatteryAge>;
  voltage?: Maybe<Scalars['String']>;
  quality?: Maybe<BatteryQualityTranslation>;
  comment?: Maybe<Scalars['String']>;
};

export type BooleanEcv = ExitCheckValuePayload & {
  __typename?: 'BooleanEcv';
  payloadType: ExitCheckValuePayloadType;
  value: Scalars['Boolean'];
};

export type ExitCheckJsonValuePayload =
  | MileageEcv
  | WarningLightsEcv
  | FuelLevelEcv
  | GpsEcv
  | RadioEcv
  | BooleanEcv
  | BatteryEcv;

export enum CompositeType {
  Damages = 'DAMAGES',
  Wheel = 'WHEEL',
  Others = 'OTHERS',
}

export type AwsImageUploadCredentialsInput = {
  uploadImagesInfos: Array<UploadImagesInfo>;
};

export type UploadImagesInfo = {
  adId: Scalars['UUID'];
  uploadId: Scalars['UUID'];
  type: CompositeType;
  extension: Scalars['String'];
};

export type AwsImageUploadCredentialsResponse = {
  __typename?: 'AwsImageUploadCredentialsResponse';
  awsImageUploadCredentials: Array<AwsImageUploadCredential>;
};

export type AwsImageUploadCredential = {
  __typename?: 'AwsImageUploadCredential';
  bucketName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map']>;
};

export type InformMessageFilterInput = {
  vinOrStockNumber?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  errorText?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  reviewed?: Maybe<Scalars['Boolean']>;
};

export type InformMessageSortInput = {
  vin?: Maybe<SortDirection>;
  refId?: Maybe<SortDirection>;
  messageType?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  tenant?: Maybe<SortDirection>;
  errorCode?: Maybe<SortDirection>;
  errorText?: Maybe<SortDirection>;
  direction?: Maybe<SortDirection>;
  reviewed?: Maybe<SortDirection>;
};

export type InformMessageUpdateInput = {
  comment?: Maybe<Scalars['String']>;
  reviewed?: Maybe<Scalars['Boolean']>;
};

export type InformMessagePageResponse = {
  __typename?: 'InformMessagePageResponse';
  items: Array<InformMessage>;
  pageInfo: PageInfo;
};

export type InformMessage = {
  __typename?: 'InformMessage';
  id: Scalars['ID'];
  vin: Scalars['String'];
  stockNumber?: Maybe<Scalars['String']>;
  refurbishmentId?: Maybe<Scalars['ID']>;
  refId?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  carPart?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['ID']>;
  messageType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  status: Scalars['String'];
  tenant: InformTenant;
  errorCode?: Maybe<Scalars['String']>;
  errorText?: Maybe<Scalars['String']>;
  direction: Scalars['String'];
  reviewed?: Maybe<Scalars['Boolean']>;
  reviewedBy?: Maybe<User>;
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type InformTenant = {
  __typename?: 'InformTenant';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateRefurbishmentState?: Maybe<UpdateRefurbishmentStateResponse>;
  updateRefurbishment: RefurbishmentUpdateResponse;
  updateVehicle: Vehicle;
  createRefurbishmentEta: RefurbishmentEta;
  updateQualityCheckVehicleInspectionItem: Scalars['Boolean'];
  cancelReturnToAuto1: Scalars['Boolean'];
  returnToAuto1: Scalars['Boolean'];
  createPartnerWorkshopConnections: PartnerWorkshopConnectionMutationResponse;
  removePartnerWorkshopConnections: Scalars['Boolean'];
  addRefurbishmentPartnerWorkshopConnections: Array<
    PartnerWorkshopConnectionRefurbishmentResponse
  >;
  removeRefurbishmentPartnerWorkshopConnection: Scalars['Boolean'];
  updateCurrentPartnerWorkshopConnection: PartnerWorkshopConnection;
  createComment: Comment;
  updateComment: CommentUpdateResponse;
  deleteComment: Scalars['Boolean'];
  createDocument: Document;
  updateDocument: Document;
  createUserV2: User;
  updateUser: User;
  assignGroups: Scalars['Boolean'];
  assignUserPartnerWorkshopConnectionPermissions: Scalars['Boolean'];
  createGroup: Group;
  updateGroup: Group;
  assignRoles: Scalars['Boolean'];
  assignUsers: Scalars['Boolean'];
  postAuthorizeEntryCheck: EntryCheck;
  completeEntryCheck: EntryCheck;
  submitEntryCheck: EntryCheck;
  createEntryCheckItems: Array<EntryCheckItem>;
  createEntryCheckItemsForAuthorization: Array<EntryCheckItem>;
  createEntryCheckItemsForPostAuthorization: Array<EntryCheckItem>;
  createEntryCheckItemsForPostAuthorizationByEntityId: Array<EntryCheckItem>;
  updateEntryCheckItems: Array<EntryCheckItem>;
  deleteEntryCheckItems: Scalars['Boolean'];
  createEntryCheckItemValues: Array<EntryCheckItemValue>;
  updateEntryCheckItemValues: Array<EntryCheckItemValue>;
  updateEntryCheckItemValuesForCostCalculation: Array<EntryCheckItemValue>;
  deleteEntryCheckItemValues: Scalars['Boolean'];
  importCatDamagesToEntryCheck: Scalars['Boolean'];
  createExitCheck: ExitCheckDetails;
  prepareExitCheck: ExitCheckDetails;
  repairsNeededExitCheck: ExitCheckDetails;
  startRepairsForExitCheck: ExitCheckDetails;
  completeRepairsForExitCheck: ExitCheckDetails;
  completeExitCheck: ExitCheckDetails;
  cancelExitCheck: ExitCheckDetails;
  createExitCheckDamage: ExitCheckDamage;
  deleteExitCheckDamage: Scalars['Boolean'];
  updateExitCheckDamage: ExitCheckDamage;
  changeExitCheckDamageState: ExitCheckDamage;
  changeExitCheckServiceState: ExitCheckService;
  deleteExitCheckService: Scalars['Boolean'];
  createExitCheckService: ExitCheckService;
  updateExitCheckService: ExitCheckService;
  createExitCheckServiceWork: ExitCheckServiceWork;
  updateExitCheckServiceWork: ExitCheckServiceWork;
  deleteExitCheckServiceWork: Scalars['Boolean'];
  updateExitCheckValue: ExitCheckValue;
  createExitCheckComment: ExitCheckComment;
  updateExitCheckComment: ExitCheckComment;
  deleteExitCheckComment: Scalars['Boolean'];
  createExitCheckPhoto: ExitCheckPhoto;
  updateExitCheckPhoto: ExitCheckPhoto;
  updateVerificationQuestionAnswer: VerificationQuestionAggregate;
  updateReview: ReviewInfo;
  deleteQualityCheckItem: DeleteItemResponse;
  createQualityCheckServiceItem: ItemExtended;
  createQualityCheckDamageItem: ItemExtended;
  updateQualityCheckServiceItem: Item;
  updateQualityCheckDamageItem: Item;
  updateQualityCheckVehicleItem: Item;
  updateQualityCheckRefurbishmentItem: Item;
  createQualityCheckDamageItemV2: ItemExtended;
  updateQualityCheckDamageItemV2: Item;
  completeQualityCheckReview: Scalars['Boolean'];
  deleteQualityCheckApprovalItem: DeleteItemResponse;
  createQualityCheckApprovalServiceItem: ItemExtended;
  createQualityCheckApprovalDamageItem: ItemExtended;
  updateQualityCheckApprovalServiceItem: Item;
  updateQualityCheckApprovalDamageItem: Item;
  updateQualityCheckApprovalVehicleItem: Item;
  updateQualityCheckApprovalRefurbishmentItem: Item;
  createQualityCheckApprovalDamageItemV2: ItemExtended;
  updateQualityCheckApprovalDamageItemV2: Item;
  completeQualityCheckApproval: Scalars['Boolean'];
  updateRefurbishmentTaskCurrentPartnerWorkshopConnection: RefurbishmentTaskPartnerWorkshopConnectionUpdateResponse;
  updateRefurbishmentStateAndTaskState?: Maybe<
    RefurbishmentTaskStateUpdateResponse
  >;
  createRefurbishmentPartnerTaskEta?: Maybe<
    RefurbishmentPartnerTaskEtaResponse
  >;
  updateTaskItemState: UpdateOperationResponse;
  updateTaskItemStateAndRefurbishmentState: RefurbishmentTaskItemStateUpdateResponse;
  createVehicleServiceCost: VehicleServiceCost;
  createRepairCost: RepairCost;
  updateRepairCost: RepairCost;
  deleteRepairCost: Scalars['Boolean'];
  createRepairMethod: RepairMethod;
  updateRepairMethod: RepairMethod;
  deleteRepairMethod: Scalars['Boolean'];
  updateVehicleServiceCost: VehicleServiceCost;
  createRepairCostImportTask: ImportTaskCreated;
  startRepairCostImport: ImportTask;
  cancelRepairCostImport: ImportTask;
  createRepairMethodImportTask: ImportTaskCreated;
  startRepairMethodImport: ImportTask;
  cancelRepairMethodImport: ImportTask;
  createPartner: PartnerDetails;
  updatePartner: PartnerDetails;
  updateInformMessage: InformMessage;
  retryInformMessages: Array<Scalars['UUID']>;
};

export type MutationUpdateRefurbishmentStateArgs = {
  input: RefurbishmentStateChangeInput;
};

export type MutationUpdateRefurbishmentArgs = {
  id: Scalars['UUID'];
  input: RefurbishmentUpdateInput;
};

export type MutationUpdateVehicleArgs = {
  vehicleUpdateInput: VehicleUpdateInput;
};

export type MutationCreateRefurbishmentEtaArgs = {
  input: RefurbishmentEtaInput;
};

export type MutationUpdateQualityCheckVehicleInspectionItemArgs = {
  id: Scalars['UUID'];
  input: VehicleInspectionUpdateInput;
};

export type MutationCancelReturnToAuto1Args = {
  refurbishmentId: Scalars['UUID'];
};

export type MutationReturnToAuto1Args = {
  refurbishmentId: Scalars['UUID'];
  input: ReturnToAuto1Input;
};

export type MutationCreatePartnerWorkshopConnectionsArgs = {
  input: PartnerWorkshopConnectionCreateInput;
};

export type MutationRemovePartnerWorkshopConnectionsArgs = {
  ids: Array<Scalars['UUID']>;
};

export type MutationAddRefurbishmentPartnerWorkshopConnectionsArgs = {
  refurbishmentId: Scalars['UUID'];
  refurbishmentPartnerWorkshopConnectionAdditionInput: RefurbishmentPartnerWorkshopConnectionAdditionInput;
};

export type MutationRemoveRefurbishmentPartnerWorkshopConnectionArgs = {
  refurbishmentId: Scalars['UUID'];
  partnerWorkshopConnectionId: Scalars['UUID'];
};

export type MutationUpdateCurrentPartnerWorkshopConnectionArgs = {
  refurbishmentId: Scalars['UUID'];
  currentPartnerWorkshopConnectionUpdateInput: CurrentPartnerWorkshopConnectionUpdateInput;
};

export type MutationCreateCommentArgs = {
  refurbishmentId: Scalars['UUID'];
  comment: Scalars['String'];
};

export type MutationUpdateCommentArgs = {
  id: Scalars['UUID'];
  comment: Scalars['String'];
};

export type MutationDeleteCommentArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateDocumentArgs = {
  createDocumentInput: CreateDocumentInput;
};

export type MutationUpdateDocumentArgs = {
  id: Scalars['UUID'];
  input: DocumentUpdateInput;
};

export type MutationCreateUserV2Args = {
  userCreateInput: UserCreateInputV2;
};

export type MutationUpdateUserArgs = {
  id: Scalars['UUID'];
  input: UserUpdateInput;
};

export type MutationAssignGroupsArgs = {
  userId: Scalars['UUID'];
  groupIds: Array<Scalars['UUID']>;
};

export type MutationAssignUserPartnerWorkshopConnectionPermissionsArgs = {
  userId?: Maybe<Scalars['UUID']>;
  input: UserPartnerWorkshopConnectionInput;
};

export type MutationCreateGroupArgs = {
  input: GroupCreateInput;
};

export type MutationUpdateGroupArgs = {
  id: Scalars['UUID'];
  input: GroupUpdateInput;
};

export type MutationAssignRolesArgs = {
  groupId: Scalars['UUID'];
  roleIds: Array<Scalars['UUID']>;
};

export type MutationAssignUsersArgs = {
  groupId: Scalars['UUID'];
  userIds: Array<Scalars['UUID']>;
};

export type MutationPostAuthorizeEntryCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationCompleteEntryCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationSubmitEntryCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateEntryCheckItemsArgs = {
  inputs: Array<EntryCheckItemCreateInput>;
};

export type MutationCreateEntryCheckItemsForAuthorizationArgs = {
  inputs: Array<EntryCheckItemCreateInput>;
};

export type MutationCreateEntryCheckItemsForPostAuthorizationArgs = {
  inputs: Array<EntryCheckItemCreateInput>;
};

export type MutationCreateEntryCheckItemsForPostAuthorizationByEntityIdArgs = {
  inputs: Array<EntryCheckItemCreateInput>;
  entityId: Scalars['UUID'];
};

export type MutationUpdateEntryCheckItemsArgs = {
  inputs: Array<EntryCheckItemUpdateEntryInput>;
};

export type MutationDeleteEntryCheckItemsArgs = {
  ids: Array<Scalars['UUID']>;
};

export type MutationCreateEntryCheckItemValuesArgs = {
  inputs: Array<EntryCheckItemValueCreateInput>;
};

export type MutationUpdateEntryCheckItemValuesArgs = {
  inputs: Array<EntryCheckItemValueUpdateEntryInput>;
};

export type MutationUpdateEntryCheckItemValuesForCostCalculationArgs = {
  inputs: Array<EntryCheckItemValueUpdateEntryInput>;
};

export type MutationDeleteEntryCheckItemValuesArgs = {
  ids: Array<Scalars['UUID']>;
};

export type MutationImportCatDamagesToEntryCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateExitCheckArgs = {
  adId: Scalars['UUID'];
};

export type MutationPrepareExitCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationRepairsNeededExitCheckArgs = {
  id: Scalars['UUID'];
  repairEta?: Maybe<Scalars['Date']>;
};

export type MutationStartRepairsForExitCheckArgs = {
  id: Scalars['UUID'];
  repairEta?: Maybe<Scalars['Date']>;
};

export type MutationCompleteRepairsForExitCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationCompleteExitCheckArgs = {
  id: Scalars['UUID'];
  repairEta?: Maybe<Scalars['Date']>;
};

export type MutationCancelExitCheckArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateExitCheckDamageArgs = {
  exitCheckId: Scalars['UUID'];
  damageInput: ExitCheckDamageCreateInput;
};

export type MutationDeleteExitCheckDamageArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
};

export type MutationUpdateExitCheckDamageArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  damageInput: ExitCheckDamageUpdateInput;
};

export type MutationChangeExitCheckDamageStateArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  state: ExitCheckDamageState;
};

export type MutationChangeExitCheckServiceStateArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  state: ExitCheckServiceState;
};

export type MutationDeleteExitCheckServiceArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
};

export type MutationCreateExitCheckServiceArgs = {
  exitCheckId: Scalars['UUID'];
  serviceData: ExitCheckServiceCreateInput;
};

export type MutationUpdateExitCheckServiceArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  serviceData: ExitCheckServiceUpdateInput;
};

export type MutationCreateExitCheckServiceWorkArgs = {
  exitCheckId: Scalars['UUID'];
  serviceWorkData: ExitCheckServiceWorkCreateInput;
};

export type MutationUpdateExitCheckServiceWorkArgs = {
  exitCheckId: Scalars['UUID'];
  serviceWorkData: ExitCheckServiceWorkUpdateInput;
};

export type MutationDeleteExitCheckServiceWorkArgs = {
  exitCheckId: Scalars['UUID'];
};

export type MutationUpdateExitCheckValueArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  valueData: ExitCheckValueUpdateInput;
};

export type MutationCreateExitCheckCommentArgs = {
  exitCheckId: Scalars['UUID'];
  comment: Scalars['String'];
};

export type MutationUpdateExitCheckCommentArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  comment: Scalars['String'];
};

export type MutationDeleteExitCheckCommentArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
};

export type MutationCreateExitCheckPhotoArgs = {
  exitCheckId: Scalars['UUID'];
  photoInput: ExitCheckPhotoCreateInput;
};

export type MutationUpdateExitCheckPhotoArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  photoInput: ExitCheckPhotoUpdateInput;
};

export type MutationUpdateVerificationQuestionAnswerArgs = {
  id: Scalars['UUID'];
  exitCheckId: Scalars['UUID'];
  verificationQuestionUpdateInput: VerificationQuestionUpdateInput;
};

export type MutationUpdateReviewArgs = {
  id: Scalars['UUID'];
  updateReviewInput: UpdateReviewInput;
};

export type MutationDeleteQualityCheckItemArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateQualityCheckServiceItemArgs = {
  serviceItemCreateInput: ServiceItemCreateInput;
};

export type MutationCreateQualityCheckDamageItemArgs = {
  damageItemCreateInput: DamageItemCreateInput;
};

export type MutationUpdateQualityCheckServiceItemArgs = {
  itemId: Scalars['UUID'];
  serviceItemUpdateInput: ServiceItemUpdateInput;
};

export type MutationUpdateQualityCheckDamageItemArgs = {
  itemId: Scalars['UUID'];
  damageItemUpdateInput: DamageItemUpdateInput;
};

export type MutationUpdateQualityCheckVehicleItemArgs = {
  itemId: Scalars['UUID'];
  vehicleItemUpdateInput: VehicleItemUpdateInput;
};

export type MutationUpdateQualityCheckRefurbishmentItemArgs = {
  itemId: Scalars['UUID'];
  refurbishmentItemUpdateInput: RefurbishmentItemUpdateInput;
};

export type MutationCreateQualityCheckDamageItemV2Args = {
  damageItemCreateInput: DamageItemCreateInputV2;
};

export type MutationUpdateQualityCheckDamageItemV2Args = {
  itemId: Scalars['UUID'];
  damageItemUpdateInput: DamageItemUpdateInputV2;
};

export type MutationCompleteQualityCheckReviewArgs = {
  id: Scalars['UUID'];
};

export type MutationDeleteQualityCheckApprovalItemArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateQualityCheckApprovalServiceItemArgs = {
  serviceItemCreateInput: ServiceItemCreateInput;
};

export type MutationCreateQualityCheckApprovalDamageItemArgs = {
  damageItemCreateInput: DamageItemCreateInput;
};

export type MutationUpdateQualityCheckApprovalServiceItemArgs = {
  itemId: Scalars['UUID'];
  serviceItemUpdateInput: ServiceItemUpdateInput;
};

export type MutationUpdateQualityCheckApprovalDamageItemArgs = {
  itemId: Scalars['UUID'];
  damageItemUpdateInput: DamageItemUpdateInput;
};

export type MutationUpdateQualityCheckApprovalVehicleItemArgs = {
  itemId: Scalars['UUID'];
  vehicleItemUpdateInput: VehicleItemUpdateInput;
};

export type MutationUpdateQualityCheckApprovalRefurbishmentItemArgs = {
  itemId: Scalars['UUID'];
  refurbishmentItemUpdateInput: RefurbishmentItemUpdateInput;
};

export type MutationCreateQualityCheckApprovalDamageItemV2Args = {
  damageItemCreateInput: DamageItemCreateInputV2;
};

export type MutationUpdateQualityCheckApprovalDamageItemV2Args = {
  itemId: Scalars['UUID'];
  damageItemUpdateInput: DamageItemUpdateInputV2;
};

export type MutationCompleteQualityCheckApprovalArgs = {
  id: Scalars['UUID'];
};

export type MutationUpdateRefurbishmentTaskCurrentPartnerWorkshopConnectionArgs = {
  taskId: Scalars['UUID'];
  currentPartnerWorkshopConnectionId: Scalars['UUID'];
};

export type MutationUpdateRefurbishmentStateAndTaskStateArgs = {
  taskId: Scalars['UUID'];
  refurbishmentId: Scalars['UUID'];
  refurbishmentState: RefurbishmentState;
};

export type MutationCreateRefurbishmentPartnerTaskEtaArgs = {
  refurbishmentPartnerTaskEtaCreateInput: RefurbishmentPartnerTaskEtaCreateInput;
};

export type MutationUpdateTaskItemStateArgs = {
  taskItemId: Scalars['UUID'];
  refurbishmentTaskItemState: RefurbishmentTaskItemState;
};

export type MutationUpdateTaskItemStateAndRefurbishmentStateArgs = {
  taskItemId: Scalars['UUID'];
  refurbishmentTaskItemState: RefurbishmentTaskItemState;
};

export type MutationCreateVehicleServiceCostArgs = {
  vehicleServiceCostInput: VehicleServiceCostInput;
};

export type MutationCreateRepairCostArgs = {
  repairCostCreateInput: RepairCostCreateInput;
};

export type MutationUpdateRepairCostArgs = {
  id: Scalars['UUID'];
  cost: ServiceCostInput;
  isDefault?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteRepairCostArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateRepairMethodArgs = {
  repairMethodCreateInput: RepairMethodCreateInput;
};

export type MutationUpdateRepairMethodArgs = {
  id: Scalars['UUID'];
  repairMethodUpdateInput: RepairMethodUpdateInput;
};

export type MutationDeleteRepairMethodArgs = {
  id: Scalars['UUID'];
};

export type MutationUpdateVehicleServiceCostArgs = {
  id: Scalars['UUID'];
  vehicleServiceCostUpdateInput: VehicleServiceCostUpdateInput;
};

export type MutationCreateRepairCostImportTaskArgs = {
  createImportTaskInput: CreateImportTaskInput;
};

export type MutationStartRepairCostImportArgs = {
  id: Scalars['UUID'];
};

export type MutationCancelRepairCostImportArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateRepairMethodImportTaskArgs = {
  createImportTaskInput: CreateImportTaskInput;
};

export type MutationStartRepairMethodImportArgs = {
  id: Scalars['UUID'];
};

export type MutationCancelRepairMethodImportArgs = {
  id: Scalars['UUID'];
};

export type MutationCreatePartnerArgs = {
  partnerInput: PartnerInput;
};

export type MutationUpdatePartnerArgs = {
  id: Scalars['Int'];
  partnerInput: PartnerInput;
};

export type MutationUpdateInformMessageArgs = {
  id: Scalars['UUID'];
  input: InformMessageUpdateInput;
};

export type MutationRetryInformMessagesArgs = {
  ids: Array<Scalars['UUID']>;
};

export type PartnerWorkshopConnectionFilter = {
  workshopOrPartnerName?: Maybe<Scalars['String']>;
  mainWorkshopName?: Maybe<Scalars['String']>;
};

export type PartnerWorkshopConnectionCreateInput = {
  mainWorkshop: WorkshopCreateInput;
  repairWorkshop: WorkshopCreateInput;
  repairPartners: Array<PartnerCreateInput>;
};

export type WorkshopCreateInput = {
  name: Scalars['String'];
  branchId: Scalars['Int'];
};

export type PartnerCreateInput = {
  name: Scalars['String'];
  refurbishmentPartnerId: Scalars['Int'];
};

export type PartnerWorkshopConnections = {
  __typename?: 'PartnerWorkshopConnections';
  items: Array<PartnerWorkshopConnection>;
  pageInfo: PageInfo;
};

export type GroupedPartnerWorkshopConnections = {
  __typename?: 'GroupedPartnerWorkshopConnections';
  items: Array<GroupedPartnerWorkshopConnection>;
  pageInfo: PageInfo;
};

export type GroupedPartnerWorkshopConnection = {
  __typename?: 'GroupedPartnerWorkshopConnection';
  groupedPartnerWorkshopConnections: Array<Array<PartnerWorkshopConnection>>;
};

export type PartnerWorkshopConnection = {
  __typename?: 'PartnerWorkshopConnection';
  id: Scalars['UUID'];
  mainWorkshop: Workshop;
  repairWorkshop: Workshop;
  repairPartner: RepairPartner;
};

export type PartnerWorkshopConnectionRefurbishmentResponse = {
  __typename?: 'PartnerWorkshopConnectionRefurbishmentResponse';
  id: Scalars['UUID'];
  isDefault: Scalars['Boolean'];
  mainWorkshop: Workshop;
  repairWorkshop: Workshop;
  repairPartner: RepairPartner;
};

export type Workshop = {
  __typename?: 'Workshop';
  id: Scalars['UUID'];
  name: Scalars['String'];
  branchId: Scalars['Int'];
};

export type RepairPartner = {
  __typename?: 'RepairPartner';
  id: Scalars['UUID'];
  name: Scalars['String'];
  refurbishmentPartnerId: Scalars['Int'];
  type?: Maybe<PartnerType>;
};

export type PartnerWorkshopConnectionMutationResponse = {
  __typename?: 'PartnerWorkshopConnectionMutationResponse';
  partnerWorkshopConnections: Array<PartnerWorkshopConnection>;
};

export type PartnerSearchFilterInput = {
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type PartnerSearchSortInput = {
  name?: Maybe<SortDirection>;
};

export type PartnerInput = {
  name: Scalars['String'];
  contactName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  partnerEmail: Scalars['String'];
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  active: Scalars['Boolean'];
  partnerType?: Maybe<PartnerType>;
};

export type PartnerPageResponse = {
  __typename?: 'PartnerPageResponse';
  items: Array<PartnerDetails>;
  pageInfo: PageInfo;
};

export type PartnerDetails = {
  __typename?: 'PartnerDetails';
  id: Scalars['Int'];
  name: Scalars['String'];
  contactName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  partnerEmail: Scalars['String'];
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  active: Scalars['Boolean'];
  partnerType?: Maybe<PartnerType>;
};

export type ProductionOverviewFilter = {
  refurbishmentTypes?: Maybe<Array<RefurbishmentType>>;
  refurbishmentStates?: Maybe<Array<RefurbishmentState>>;
  branchIds?: Maybe<Array<Scalars['Int']>>;
  stockNumber?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
};

export type ProductionOverview = {
  __typename?: 'ProductionOverview';
  items: Array<ProductionOverviewItem>;
  pageInfo: PageInfo;
};

export type ProductionOverviewItem = {
  __typename?: 'ProductionOverviewItem';
  /** Refurbishment id */
  id: Scalars['ID'];
  /** Classifieds Ad id */
  adId: Scalars['ID'];
  stockNumber?: Maybe<Scalars['String']>;
  refId: Scalars['String'];
  retailStockCountry?: Maybe<Scalars['String']>;
  modelInfo?: Maybe<ModelInfo>;
  refurbishmentType: RefurbishmentType;
  state?: Maybe<RefurbishmentState>;
  lastStatusUpdatedOn?: Maybe<Scalars['LocalDateTime']>;
  branchName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
};

export type ModelInfo = {
  __typename?: 'ModelInfo';
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  subModel?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  refurbishmentOverview?: Maybe<RefurbishmentOverview>;
  refurbishmentDetails: RefurbishmentDetails;
  refurbishmentDetailsByRefId: RefurbishmentDetails;
  productionOverview?: Maybe<ProductionOverview>;
  serviceTypes: Array<ServiceData>;
  user: User;
  userSearch: UserPageResponse;
  documentCategories: DocumentTopCategoryResponse;
  buildImageUploadCredentials: AwsUploadCredentialsResponse;
  partners: PartnerResponse;
  allLocations: LocationResponse;
  activeGroupedPartnerWorkshopConnections: GroupedPartnerWorkshopConnections;
  refurbishmentActiveGroupedPartnerWorkshopConnections: GroupedPartnerWorkshopConnections;
  refurbishmentPartnerWorkshopConnections: Array<
    PartnerWorkshopConnectionRefurbishmentResponse
  >;
  groups: Array<Group>;
  roles: Array<Role>;
  entryCheck?: Maybe<EntryCheck>;
  entryCheckAuthorization?: Maybe<EntryCheck>;
  entryCheckPostAuthorization?: Maybe<EntryCheck>;
  carParts?: Maybe<Array<CarPart>>;
  damageTypes: Array<DamageType>;
  brakeTypes: Array<BrakeType>;
  brakeDiskConditions: Array<BrakeDiskCondition>;
  brakePadConditions: Array<BrakePadCondition>;
  buildAdImageUploadCredentials?: Maybe<AwsImageUploadCredentialsResponse>;
  carAttributes?: Maybe<CarAttributeTypesResponse>;
  providerTotalCosts: Scalars['Int'];
  providerTotalCostsAuthorization: Scalars['Int'];
  providerTotalCostsPostAuthorization: Scalars['Int'];
  pricingBudgetCosts?: Maybe<PricingBudgetCosts>;
  damageCancelReasons: Array<DamageCancellationReasonResponse>;
  exitCheck: ExitCheckData;
  exitCheckSearch: ExitCheckPageResponse;
  qualityChecks?: Maybe<Array<ReviewInfo>>;
  qualityCheckReview: Review;
  qualityCheckReviewItems: Array<Item>;
  qualityCheckApproval: Review;
  qualityCheckApprovalItems: Array<Item>;
  isCarReadyForExitCheck: CarReadyForExitCheckResponse;
  refurbishmentTasks: RefurbishmentTaskAggregate;
  refurbishmentTaskItemsIncludingCosts: RefurbishmentTaskItemIncludingCostsResponse;
  refurbishmentTaskItemsExcludingCosts: RefurbishmentTaskItemExcludingCostsResponse;
  rimSizes: Array<RimSizeDto>;
  loadIndexes: Array<LoadIndexDto>;
  tireWidths: Array<TireWidthDto>;
  tireHeights: Array<TireHeightDto>;
  speedIndexes: Array<SpeedIndexDto>;
  tireManufacturers: Array<TireManufacturerDto>;
  wheelSetDeletedReasons: Array<WheelSetDeletedReasonsResponse>;
  serviceBookTypes: Array<ServiceBookType>;
  serviceCompanies: Array<ServiceCompany>;
  returnToAuto1MainReasons: Array<ReturnToAuto1MainReason>;
  returnToAuto1Categories: Array<ReturnToAuto1Category>;
  vehicleServiceCostSearch: VehicleServiceCostPageResponse;
  vehicleServiceCostSearchV2: VehicleServiceCostPageResponse;
  repairCostSearch: RepairCostPageResponse;
  repairMethodSearch: RepairMethodPageResponse;
  vehicleDamageAreas: Array<VehicleDamageArea>;
  vehicleDamageParts: Array<VehicleDamagePart>;
  vehicleDamageTypes: Array<VehicleDamageType>;
  vehicleDamageAttributes: VehicleAttributesQuantities;
  vehicleRepairMethods: Array<VehicleRepairMethod>;
  vehicleAllDamageParts: Array<VehicleDamagePart>;
  vehicleAllDamageTypes: Array<VehicleDamageType>;
  repairMethodByDamage: RepairMethodItemsResponse;
  repairCostByDamage: RepairCostItemsResponse;
  getRepairCostImportTask: ImportTask;
  searchRepairCostImportTasks: ImportTaskPageResponse;
  buildRepairCostImportUploadCredentials: AwsImportUploadCredentialsResponse;
  getRepairMethodImportTask: ImportTask;
  searchRepairMethodImportTasks: ImportTaskPageResponse;
  buildRepairMethodImportUploadCredentials: AwsImportUploadCredentialsResponse;
  downloadRepairCostImportTaskOriginalFile?: Maybe<Scalars['String']>;
  downloadRepairCostImportTaskValidationResult?: Maybe<Scalars['String']>;
  downloadRepairMethodImportTaskOriginalFile?: Maybe<Scalars['String']>;
  downloadRepairMethodImportTaskValidationResult?: Maybe<Scalars['String']>;
  partnerSearch: PartnerPageResponse;
  providerTotalSplitCosts: ProviderTotalSplitCostResponse;
  providerTotalSplitCostsAuthorization: ProviderTotalSplitCostResponse;
  providerTotalSplitCostsPostAuthorization: ProviderTotalSplitCostResponse;
  getLatestCatScan?: Maybe<CatScan>;
  searchInformMessages: InformMessagePageResponse;
  getInformTenants: Array<InformTenant>;
  getInformMessageTypes: Array<Scalars['String']>;
};

export type QueryRefurbishmentOverviewArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter: RefurbishmentOverviewFilter;
  sort?: Maybe<RefurbishmentOverviewSortInput>;
};

export type QueryRefurbishmentDetailsArgs = {
  id: Scalars['UUID'];
};

export type QueryRefurbishmentDetailsByRefIdArgs = {
  refId: Scalars['String'];
};

export type QueryProductionOverviewArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter: ProductionOverviewFilter;
};

export type QueryUserArgs = {
  id: Scalars['UUID'];
};

export type QueryUserSearchArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<UserSearchInput>;
  sort?: Maybe<UserSortInput>;
};

export type QueryDocumentCategoriesArgs = {
  country: Scalars['String'];
  topCategory: Scalars['String'];
};

export type QueryBuildImageUploadCredentialsArgs = {
  awsUploadCredentialsInput: AwsUploadCredentialsInput;
};

export type QueryActiveGroupedPartnerWorkshopConnectionsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<PartnerWorkshopConnectionFilter>;
};

export type QueryRefurbishmentActiveGroupedPartnerWorkshopConnectionsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  refurbishmentId: Scalars['UUID'];
};

export type QueryRefurbishmentPartnerWorkshopConnectionsArgs = {
  refurbishmentId: Scalars['UUID'];
};

export type QueryGroupsArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type QueryRolesArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type QueryEntryCheckArgs = {
  refurbishmentId: Scalars['UUID'];
};

export type QueryEntryCheckAuthorizationArgs = {
  refurbishmentId: Scalars['UUID'];
};

export type QueryEntryCheckPostAuthorizationArgs = {
  refurbishmentId: Scalars['UUID'];
};

export type QueryDamageTypesArgs = {
  carPartId?: Maybe<Scalars['Int']>;
};

export type QueryBrakeDiskConditionsArgs = {
  brakeTypeId: Scalars['UUID'];
};

export type QueryBuildAdImageUploadCredentialsArgs = {
  awsImageUploadCredentialsInput: AwsImageUploadCredentialsInput;
};

export type QueryCarAttributesArgs = {
  carAttributeInput: CarAttributeInput;
};

export type QueryProviderTotalCostsArgs = {
  entryCheckId: Scalars['UUID'];
};

export type QueryProviderTotalCostsAuthorizationArgs = {
  entryCheckId: Scalars['UUID'];
};

export type QueryProviderTotalCostsPostAuthorizationArgs = {
  entryCheckId: Scalars['UUID'];
};

export type QueryPricingBudgetCostsArgs = {
  stockNumber: Scalars['String'];
};

export type QueryExitCheckArgs = {
  id: Scalars['UUID'];
};

export type QueryExitCheckSearchArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<ExitCheckSearchFilterInput>;
};

export type QueryQualityChecksArgs = {
  refurbishmentId: Scalars['UUID'];
};

export type QueryQualityCheckReviewArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type QueryQualityCheckReviewItemsArgs = {
  ids: Array<Scalars['UUID']>;
};

export type QueryQualityCheckApprovalArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type QueryQualityCheckApprovalItemsArgs = {
  ids: Array<Scalars['UUID']>;
};

export type QueryIsCarReadyForExitCheckArgs = {
  adId?: Maybe<Scalars['UUID']>;
};

export type QueryRefurbishmentTasksArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<RefurbishmentTaskFilter>;
};

export type QueryRefurbishmentTaskItemsIncludingCostsArgs = {
  taskId: Scalars['UUID'];
};

export type QueryRefurbishmentTaskItemsExcludingCostsArgs = {
  taskId: Scalars['UUID'];
};

export type QueryVehicleServiceCostSearchArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<VehicleServiceCostFilter>;
  sort?: Maybe<VehicleServiceCostSortInput>;
};

export type QueryVehicleServiceCostSearchV2Args = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<VehicleServiceCostFilterV2>;
  sort?: Maybe<VehicleServiceCostSortInput>;
};

export type QueryRepairCostSearchArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<RepairCostFilter>;
  sort?: Maybe<RepairCostSortInput>;
};

export type QueryRepairMethodSearchArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<RepairMethodFilter>;
  sort?: Maybe<RepairMethodSortInput>;
};

export type QueryVehicleDamageAreasArgs = {
  vehicleModelVersion: VehicleModelVersion;
};

export type QueryVehicleDamagePartsArgs = {
  vehicleModelVersion: VehicleModelVersion;
  damageArea: DamageArea;
};

export type QueryVehicleDamageTypesArgs = {
  vehicleModelVersion: VehicleModelVersion;
  damagePart: Scalars['String'];
};

export type QueryVehicleDamageAttributesArgs = {
  vehicleModelVersion: VehicleModelVersion;
  damagePart: Scalars['String'];
  damageType: Scalars['String'];
};

export type QueryVehicleRepairMethodsArgs = {
  vehicleModelVersion: VehicleModelVersion;
};

export type QueryVehicleAllDamagePartsArgs = {
  vehicleModelVersion: VehicleModelVersion;
};

export type QueryVehicleAllDamageTypesArgs = {
  vehicleModelVersion: VehicleModelVersion;
};

export type QueryRepairMethodByDamageArgs = {
  repairMethodByDamageInput?: Maybe<RepairMethodByDamageInput>;
};

export type QueryRepairCostByDamageArgs = {
  repairCostByDamageInput?: Maybe<RepairCostByDamageInput>;
};

export type QueryGetRepairCostImportTaskArgs = {
  id: Scalars['UUID'];
};

export type QuerySearchRepairCostImportTasksArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryBuildRepairCostImportUploadCredentialsArgs = {
  awsImportUploadCredentialsInput: AwsImportUploadCredentialsInput;
};

export type QueryGetRepairMethodImportTaskArgs = {
  id: Scalars['UUID'];
};

export type QuerySearchRepairMethodImportTasksArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryBuildRepairMethodImportUploadCredentialsArgs = {
  awsImportUploadCredentialsInput: AwsImportUploadCredentialsInput;
};

export type QueryDownloadRepairCostImportTaskOriginalFileArgs = {
  id: Scalars['UUID'];
};

export type QueryDownloadRepairCostImportTaskValidationResultArgs = {
  id: Scalars['UUID'];
};

export type QueryDownloadRepairMethodImportTaskOriginalFileArgs = {
  id: Scalars['UUID'];
};

export type QueryDownloadRepairMethodImportTaskValidationResultArgs = {
  id: Scalars['UUID'];
};

export type QueryPartnerSearchArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<PartnerSearchFilterInput>;
  sort?: Maybe<PartnerSearchSortInput>;
};

export type QueryProviderTotalSplitCostsArgs = {
  entryCheckId: Scalars['UUID'];
};

export type QueryProviderTotalSplitCostsAuthorizationArgs = {
  entryCheckId: Scalars['UUID'];
};

export type QueryProviderTotalSplitCostsPostAuthorizationArgs = {
  entryCheckId: Scalars['UUID'];
};

export type QueryGetLatestCatScanArgs = {
  vin: Scalars['String'];
};

export type QuerySearchInformMessagesArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter?: Maybe<InformMessageFilterInput>;
  sort?: Maybe<InformMessageSortInput>;
};

export enum CurrencyCode {
  Eur = 'EUR',
  Sek = 'SEK',
  Pln = 'PLN',
}

export enum RefurbishmentCancelReason {
  SoldWithoutRefurbishment = 'SOLD_WITHOUT_REFURBISHMENT',
  UndisclosedAccident = 'UNDISCLOSED_ACCIDENT',
  CostEstimateTooHigh = 'COST_ESTIMATE_TOO_HIGH',
  CostEstimateCouldNotBeFulfilled = 'COST_ESTIMATE_COULD_NOT_BE_FULFILLED',
  TooLongRefurbishmentTime = 'TOO_LONG_REFURBISHMENT_TIME',
  IncorrectRefurbishmentPartnerLocation = 'INCORRECT_REFURBISHMENT_PARTNER_LOCATION',
  Other = 'OTHER',
  ReturnedToAuto1 = 'RETURNED_TO_AUTO1',
}

export enum RefurbishmentCompletedReason {
  RetailReady = 'RETAIL_READY',
  QualityCheckFailed = 'QUALITY_CHECK_FAILED',
  PartialRefurbishment = 'PARTIAL_REFURBISHMENT',
}

export enum RefurbishmentServiceName {
  BrakeConditionProblem = 'BRAKE_CONDITION_PROBLEM',
  CarConditionReport = 'CAR_CONDITION_REPORT',
  CarInspection = 'CAR_INSPECTION',
  PostVehicleInspection = 'POST_VEHICLE_INSPECTION',
  CostEstimate = 'COST_ESTIMATE',
  ExteriorCleaning = 'EXTERIOR_CLEANING',
  FlatRate = 'FLAT_RATE',
  InteriorCleaning = 'INTERIOR_CLEANING',
  Maintenance = 'MAINTENANCE',
  NoRepairNeeded = 'NO_REPAIR_NEEDED',
  Other = 'OTHER',
  Photos = 'PHOTOS',
  Polish = 'POLISH',
  RefurbishmentFeedback = 'REFURBISHMENT_FEEDBACK',
  Repaint = 'REPAINT',
  Repair = 'REPAIR',
  Replace = 'REPLACE',
  SpotRepair = 'SPOT_REPAIR',
  TireReplacement = 'TIRE_REPLACEMENT',
  UsedCarCertificate = 'USED_CAR_CERTIFICATE',
  ReplaceFrontLeftTire = 'REPLACE_FRONT_LEFT_TIRE',
  ReplaceFrontRightTire = 'REPLACE_FRONT_RIGHT_TIRE',
  ReplaceRearLeftTire = 'REPLACE_REAR_LEFT_TIRE',
  ReplaceRearRightTire = 'REPLACE_REAR_RIGHT_TIRE',
  PartOfOtherService = 'PART_OF_OTHER_SERVICE',
  ReplaceFrontLeftBrake = 'REPLACE_FRONT_LEFT_BRAKE',
  ReplaceFrontRightBrake = 'REPLACE_FRONT_RIGHT_BRAKE',
  ReplaceRearLeftBrake = 'REPLACE_REAR_LEFT_BRAKE',
  ReplaceRearRightBrake = 'REPLACE_REAR_RIGHT_BRAKE',
  WheelsSwitchInPreparation = 'WHEELS_SWITCH_IN_PREPARATION',
  WheelsSwitch = 'WHEELS_SWITCH',
  OzoneCleaning = 'OZONE_CLEANING',
  Refueling = 'REFUELING',
  WheelSetReplacement = 'WHEEL_SET_REPLACEMENT',
  RimReplacement = 'RIM_REPLACEMENT',
  InteriorCleaningPhoto = 'INTERIOR_CLEANING_PHOTO',
  ExteriorCleaningPhoto = 'EXTERIOR_CLEANING_PHOTO',
  YardMovement = 'YARD_MOVEMENT',
  FullCarHailDamageRepair = 'FULL_CAR_HAIL_DAMAGE_REPAIR',
  Diagnosis = 'DIAGNOSIS',
  ReplaceFrontBrakeAxleDisksAndPads = 'REPLACE_FRONT_BRAKE_AXLE_DISKS_AND_PADS',
  ReplaceRearBrakeAxleDisksAndPads = 'REPLACE_REAR_BRAKE_AXLE_DISKS_AND_PADS',
  ReplaceFrontBrakeAxlePads = 'REPLACE_FRONT_BRAKE_AXLE_PADS',
  ReplaceRearBrakeAxlePads = 'REPLACE_REAR_BRAKE_AXLE_PADS',
  AxleOrWheelAlignment = 'AXLE_OR_WHEEL_ALIGNMENT',
  /** Service book main service */
  ServiceWork = 'SERVICE_WORK',
  EngineAirFilterChange = 'ENGINE_AIR_FILTER_CHANGE',
  InteriorFilterChange = 'INTERIOR_FILTER_CHANGE',
  SparkPlugChange = 'SPARK_PLUG_CHANGE',
  FuelFilterChange = 'FUEL_FILTER_CHANGE',
  BrakeFluidChange = 'BRAKE_FLUID_CHANGE',
  GearboxOilChange = 'GEARBOX_OIL_CHANGE',
  DifferentialOilChange = 'DIFFERENTIAL_OIL_CHANGE',
  EngineOilIncludingFilterChange = 'ENGINE_OIL_INCLUDING_FILTER_CHANGE',
  VBeltChange = 'V_BELT_CHANGE',
  CamBeltChange = 'CAM_BELT_CHANGE',
  GasFilledShockAbsorberChange = 'GAS_FILLED_SHOCK_ABSORBER_CHANGE',
  CoolingFluidChange = 'COOLING_FLUID_CHANGE',
  BatteryCheckNeeded = 'BATTERY_CHECK_NEEDED',
  DryerPatrons = 'DRYER_PATRONS',
  IonExchangerChange = 'ION_EXCHANGER_CHANGE',
  HydrogenSensorCheck = 'HYDROGEN_SENSOR_CHECK',
  VentilationCheck = 'VENTILATION_CHECK',
  BlowOffStop = 'BLOW_OFF_STOP',
  InspectionWithoutOilChange = 'INSPECTION_WITHOUT_OIL_CHANGE',
  GeneralCheck = 'GENERAL_CHECK',
  HaldexOilChange = 'HALDEX_OIL_CHANGE',
  ReplaceKeyBattery = 'REPLACE_KEY_BATTERY',
  SunroofMaintenance = 'SUNROOF_MAINTENANCE',
  SwOther = 'SW_OTHER',
  /** Vehicle model repair methods */
  FullPanelRepaint = 'FULL_PANEL_REPAINT',
  NoRepairImperfection = 'NO_REPAIR_IMPERFECTION',
  NoRepairWearAndTear = 'NO_REPAIR_WEAR_AND_TEAR',
  PaintlessDentRepair = 'PAINTLESS_DENT_REPAIR',
  SmartRepairWindscreen = 'SMART_REPAIR_WINDSCREEN',
  SpotRepairDabbing = 'SPOT_REPAIR_DABBING',
  SpotRepairPartialRepaint = 'SPOT_REPAIR_PARTIAL_REPAINT',
  SpotRepairRimTreatment = 'SPOT_REPAIR_RIM_TREATMENT',
  /** Production costs */
  ExitCheck = 'EXIT_CHECK',
  BranchEntryCheck = 'BRANCH_ENTRY_CHECK',
  LaborRate = 'LABOR_RATE',
  /** Overhead services */
  QualityCheck = 'QUALITY_CHECK',
  SpareParts = 'SPARE_PARTS',
  CostCalculation = 'COST_CALCULATION',
  Authorisation = 'AUTHORISATION',
}

export enum RefurbishmentServiceType {
  Vehicle = 'VEHICLE',
  Damage = 'DAMAGE',
  ServiceBookMainService = 'SERVICE_BOOK_MAIN_SERVICE',
  ServiceBookSubService = 'SERVICE_BOOK_SUB_SERVICE',
  DamagePa3 = 'DAMAGE_PA3',
  VehicleServiceCost = 'VEHICLE_SERVICE_COST',
  Overhead = 'OVERHEAD',
}

export enum RefurbishmentState {
  Open = 'OPEN',
  RefurbishmentOrdered = 'REFURBISHMENT_ORDERED',
  PreparationStarted = 'PREPARATION_STARTED',
  RefurbishmentFeedbackReceived = 'REFURBISHMENT_FEEDBACK_RECEIVED',
  ReadyForCostCalculation = 'READY_FOR_COST_CALCULATION',
  RefurbishmentAuthorized = 'REFURBISHMENT_AUTHORIZED',
  RefurbishmentStarted = 'REFURBISHMENT_STARTED',
  RefurbishmentCompleted = 'REFURBISHMENT_COMPLETED',
  QualityCheckOrdered = 'QUALITY_CHECK_ORDERED',
  QualityCheckCompleted = 'QUALITY_CHECK_COMPLETED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  CarArrivedInWorkshop = 'CAR_ARRIVED_IN_WORKSHOP',
  CarInBuffer = 'CAR_IN_BUFFER',
  PreparedForEntryCheck = 'PREPARED_FOR_ENTRY_CHECK',
  ReturnToAuto1Candidate = 'RETURN_TO_AUTO1_CANDIDATE',
  FlagForReturnToAuto1 = 'FLAG_FOR_RETURN_TO_AUTO1',
}

export enum RefurbishmentServiceSourceType {
  RefurbishmentOrder = 'REFURBISHMENT_ORDER',
  EntryCheckSubmit = 'ENTRY_CHECK_SUBMIT',
  EntryCheckAuthorize = 'ENTRY_CHECK_AUTHORIZE',
  EntryCheckPostAuthorize = 'ENTRY_CHECK_POST_AUTHORIZE',
  QualityCheckSubmit = 'QUALITY_CHECK_SUBMIT',
  QualityCheckAuthorize = 'QUALITY_CHECK_AUTHORIZE',
}

export enum RefurbishmentTransitionSourceType {
  Manual = 'MANUAL',
  Batch = 'BATCH',
  System = 'SYSTEM',
}

export enum RefurbishmentFlowVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum RefurbishmentServiceState {
  Open = 'OPEN',
  Ordered = 'ORDERED',
  Cancelled = 'CANCELLED',
  CancelledByAuthorization = 'CANCELLED_BY_AUTHORIZATION',
  Performed = 'PERFORMED',
  NotPerformed = 'NOT_PERFORMED',
}

export enum RouteStatus {
  Ongoing = 'ONGOING',
  Finished = 'FINISHED',
}

export enum BeautyShot {
  NotProvided = 'NOT_PROVIDED',
  BeforeRefurbishment = 'BEFORE_REFURBISHMENT',
  AfterRefurbishment = 'AFTER_REFURBISHMENT',
}

export enum WheelSetDeleteReason {
  WheelsDoNotExist = 'WHEELS_DO_NOT_EXIST',
  WheelsCannotBeSold = 'WHEELS_CANNOT_BE_SOLD',
  NotLicensedForTheCar = 'NOT_LICENSED_FOR_THE_CAR',
}

export enum RefurbishmentType {
  Refurbishment = 'REFURBISHMENT',
  ClaimRepair = 'CLAIM_REPAIR',
  ReEntryCheck = 'RE_ENTRY_CHECK',
  RefurbishmentAtHandoverLocation = 'REFURBISHMENT_AT_HANDOVER_LOCATION',
}

export enum PartnerType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export enum RefurbishmentSparePartStatus {
  NotAdded = 'NOT_ADDED',
  NotOrdered = 'NOT_ORDERED',
  IncompleteOrder = 'INCOMPLETE_ORDER',
  Ordered = 'ORDERED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Delivered = 'DELIVERED',
  MissingSpareParts = 'MISSING_SPARE_PARTS',
  HandedOut = 'HANDED_OUT',
}

export enum SparePartStatus {
  SparePartsNotOrdered = 'SPARE_PARTS_NOT_ORDERED',
  SparePartsPartiallyOrdered = 'SPARE_PARTS_PARTIALLY_ORDERED',
  SparePartsOrdered = 'SPARE_PARTS_ORDERED',
  SparePartsMissing = 'SPARE_PARTS_MISSING',
  SparePartsDelivered = 'SPARE_PARTS_DELIVERED',
  SparePartsHandedOut = 'SPARE_PARTS_HANDED_OUT',
}

export type RefurbishmentOverviewFilter = {
  refurbishmentStates?: Maybe<Array<RefurbishmentState>>;
  locationIds?: Maybe<Array<Scalars['Int']>>;
  partnerIds?: Maybe<Array<Scalars['Int']>>;
  vinNumber?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  hasRework?: Maybe<Scalars['Boolean']>;
  refurbishmentTypes?: Maybe<Array<RefurbishmentType>>;
  refurbishmentSparePartsStatuses?: Maybe<Array<RefurbishmentSparePartStatus>>;
  mainWorkshopIds?: Maybe<Array<Scalars['Int']>>;
};

export type RefurbishmentOverviewSortInput = {
  field?: Maybe<Scalars['String']>;
  direction?: Maybe<SortDirection>;
};

export type RefurbishmentStateChangeInput = {
  refurbishmentId: Scalars['ID'];
  adId: Scalars['ID'];
  stateTo: RefurbishmentState;
  transitionDate?: Maybe<Scalars['Date']>;
  cancelReason?: Maybe<RefurbishmentCancelReason>;
  cancelDescription?: Maybe<Scalars['String']>;
  completedReason?: Maybe<RefurbishmentCompletedReason>;
  sourceType?: Maybe<RefurbishmentTransitionSourceType>;
  lastTransitionId?: Maybe<Scalars['UUID']>;
};

export type RefurbishmentUpdateInput = {
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['Date']>;
  defaultPartnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  beautyShot?: Maybe<BeautyShot>;
  beautyShotsTakenOn?: Maybe<Scalars['Date']>;
};

export type RefurbishmentEtaInput = {
  refurbishmentId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  eta: Scalars['LocalDate'];
};

export type VehicleInspectionUpdateInput = {
  lastInspectionDate?: Maybe<Scalars['LocalDate']>;
  lastMileage?: Maybe<MileageUpdateInput>;
  lastInspectionDueDate?: Maybe<Scalars['LocalDate']>;
};

export type RefurbishmentPartnerWorkshopConnectionAdditionInput = {
  partnerWorkshopConnectionIds: Array<Scalars['UUID']>;
};

export type CurrentPartnerWorkshopConnectionUpdateInput = {
  currentPartnerWorkshopConnectionId: Scalars['UUID'];
};

export type ReturnToAuto1ReasonInput = {
  id?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type ReturnToAuto1Input = {
  id?: Maybe<Scalars['ID']>;
  mainReason?: Maybe<Scalars['String']>;
  detailedReason: Scalars['String'];
  currentWheelSet: Scalars['Int'];
  hasSecondWheelSet: Scalars['Boolean'];
  roadworthy: Scalars['Boolean'];
  budgetMinorUnits?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  candidateForm: Scalars['Boolean'];
  reasons?: Maybe<Array<ReturnToAuto1ReasonInput>>;
};

export type RefurbishmentOverview = {
  __typename?: 'RefurbishmentOverview';
  items: Array<RefurbishmentAggregate>;
  pageInfo: PageInfo;
};

export type RefurbishmentAggregate = {
  __typename?: 'RefurbishmentAggregate';
  id: Scalars['ID'];
  adId: Scalars['ID'];
  refId: Scalars['String'];
  vehicle: Vehicle;
  carPosition?: Maybe<Scalars['String']>;
  state: RefurbishmentStateTranslation;
  orderedOn?: Maybe<Scalars['Date']>;
  preparationStartedOn?: Maybe<Scalars['Date']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  hasUploadedDocuments?: Maybe<Scalars['Boolean']>;
  picturesReceivedOn?: Maybe<Scalars['Date']>;
  beautyShotsTakenOn?: Maybe<Scalars['Date']>;
  carSoldOn?: Maybe<Scalars['Date']>;
  latestReworkDate?: Maybe<Scalars['Date']>;
  branchId?: Maybe<Scalars['Int']>;
  partnerId?: Maybe<Scalars['Int']>;
  refurbishmentType: RefurbishmentTypeTranslation;
  currentRefurbishmentEta?: Maybe<RefurbishmentEta>;
  lastTransitionId: Scalars['UUID'];
  readyForImperfectionPhotosOn?: Maybe<Scalars['Date']>;
  daysInCurrentState?: Maybe<Scalars['Int']>;
  refurbishmentSparePartStatus?: Maybe<RefurbishmentSparePartStatusTranslation>;
  isAutoOrdered?: Maybe<Scalars['Boolean']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  refurbishmentId: Scalars['ID'];
  comment: Scalars['String'];
  createdBy: User;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
};

export type RefurbishmentDetails = {
  __typename?: 'RefurbishmentDetails';
  id: Scalars['ID'];
  adId: Scalars['ID'];
  refId: Scalars['String'];
  retailReady: Scalars['Boolean'];
  state: RefurbishmentStateTranslation;
  country?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  carPosition?: Maybe<Scalars['String']>;
  partner?: Maybe<Partner>;
  cancelReason?: Maybe<RefurbishmentCancelReasonTranslation>;
  cancelDescription?: Maybe<Scalars['String']>;
  completedReason?: Maybe<RefurbishmentCompletedReasonTranslation>;
  picturesReceivedOn?: Maybe<Scalars['Date']>;
  beautyShotsTakenOn?: Maybe<Scalars['Date']>;
  picturesUploadedOn?: Maybe<Scalars['Date']>;
  lastStateUpdatedOn?: Maybe<Scalars['Date']>;
  daysInCurrentState?: Maybe<Scalars['Int']>;
  daysSincePreparationStarted: Scalars['String'];
  estimatedCompleteDate?: Maybe<Scalars['Date']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['Date']>;
  estimatedTotalCostMinorUnits?: Maybe<Scalars['Long']>;
  providerTotalCostMinorUnits?: Maybe<Scalars['Long']>;
  totalCostBudgetIncludingInternalLaborMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode: CurrencyCode;
  beautyShot: BeautyShotTranslation;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  refurbishmentType: RefurbishmentTypeTranslation;
  keysCount?: Maybe<Scalars['Int']>;
  serviceBookType?: Maybe<StringTranslation>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
  pricingBudgetCostMinorUnits?: Maybe<Scalars['Long']>;
  minimalGrossProfitMinorUnits?: Maybe<Scalars['Long']>;
  targetGrossProfitMinorUnits?: Maybe<Scalars['Long']>;
  transitionCurrentSet?: Maybe<Array<Transition>>;
  comments?: Maybe<Array<Comment>>;
  vehicle: Vehicle;
  damages?: Maybe<Array<Damage>>;
  services?: Maybe<Array<Service>>;
  documents?: Maybe<Array<Document>>;
  refurbishmentEtas?: Maybe<Array<RefurbishmentEta>>;
  vehicleLocation?: Maybe<Location>;
  route?: Maybe<Route>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReasonTranslation>;
  vehicleInspection?: Maybe<VehicleInspection>;
  partnerWorkshopConnections?: Maybe<
    Array<PartnerWorkshopConnectionRefurbishmentResponse>
  >;
  currentPartnerWorkshopConnection?: Maybe<PartnerWorkshopConnection>;
  returnToAuto1?: Maybe<ReturnToAuto1>;
  spareParts?: Maybe<SpareParts>;
  refurbishmentSparePartStatus?: Maybe<RefurbishmentSparePartStatusTranslation>;
  /** feature flags that are active for given refurbishment, they are populated when refurbishment changes state from OPEN to REFURBISHMENT_ORDERED */
  tags?: Maybe<Array<Scalars['String']>>;
  isAutoOrdered?: Maybe<Scalars['Boolean']>;
};

export type VehicleInspection = {
  __typename?: 'VehicleInspection';
  inspectionNeeded?: Maybe<Scalars['Boolean']>;
  doneBeforeEntryCheck?: Maybe<Scalars['Boolean']>;
  carPassInspection?: Maybe<Scalars['Boolean']>;
  lastInspectionDate?: Maybe<Scalars['LocalDate']>;
  lastMileage?: Maybe<Mileage>;
  lastInspectionDueDate?: Maybe<Scalars['LocalDate']>;
};

export type RefurbishmentProviderData = {
  __typename?: 'RefurbishmentProviderData';
  location: Location;
  partner: Partner;
};

export type BaseRefurbishmentServiceResponse = {
  id: Scalars['ID'];
  relatedServiceId?: Maybe<Scalars['UUID']>;
  damageId?: Maybe<Scalars['ID']>;
  serviceData: ServiceData;
  comment?: Maybe<Scalars['String']>;
  state: RefurbishmentServiceState;
  stateComment?: Maybe<Scalars['String']>;
  sourceType?: Maybe<RefurbishmentServiceSourceType>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
};

export type Service = BaseRefurbishmentServiceResponse & {
  __typename?: 'Service';
  id: Scalars['ID'];
  relatedServiceId?: Maybe<Scalars['UUID']>;
  damageId?: Maybe<Scalars['ID']>;
  serviceData: ServiceData;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
  comment?: Maybe<Scalars['String']>;
  state: RefurbishmentServiceState;
  stateComment?: Maybe<Scalars['String']>;
  sourceType?: Maybe<RefurbishmentServiceSourceType>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  isAutoCreate?: Maybe<Scalars['Boolean']>;
  isDoneBeforeEntryCheck?: Maybe<Scalars['Boolean']>;
};

export type ServiceExcludingCost = BaseRefurbishmentServiceResponse & {
  __typename?: 'ServiceExcludingCost';
  id: Scalars['ID'];
  relatedServiceId?: Maybe<Scalars['UUID']>;
  damageId?: Maybe<Scalars['ID']>;
  serviceData: ServiceData;
  comment?: Maybe<Scalars['String']>;
  state: RefurbishmentServiceState;
  stateComment?: Maybe<Scalars['String']>;
  sourceType?: Maybe<RefurbishmentServiceSourceType>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
};

export type ServiceData = {
  __typename?: 'ServiceData';
  name: RefurbishmentServiceNameTranslation;
  type: RefurbishmentServiceType;
};

export type Transition = {
  __typename?: 'Transition';
  id: Scalars['ID'];
  stateTo: RefurbishmentStateTranslation;
  transitionDate: Scalars['Date'];
  sourceType?: Maybe<RefurbishmentTransitionSourceType>;
  createdOn: Scalars['Date'];
  createdBy?: Maybe<User>;
};

export type UpdateRefurbishmentStateResponse = {
  __typename?: 'UpdateRefurbishmentStateResponse';
  refurbishmentDetails: RefurbishmentDetails;
};

export type RefurbishmentUpdateResponse = {
  __typename?: 'RefurbishmentUpdateResponse';
  refurbishmentDetails: RefurbishmentDetails;
};

export type CommentUpdateResponse = {
  __typename?: 'CommentUpdateResponse';
  comment: Comment;
};

export type PartnerResponse = {
  __typename?: 'PartnerResponse';
  partners: Array<Partner>;
};

export type LocationResponse = {
  __typename?: 'LocationResponse';
  locations: Array<Location>;
};

export type AllLocationsResponse = {
  __typename?: 'AllLocationsResponse';
  locations: Array<Location>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['Int'];
  name: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['Int'];
  name: Scalars['String'];
  type?: Maybe<PartnerType>;
};

export type Route = {
  __typename?: 'Route';
  status?: Maybe<RouteStatusTranslation>;
  estimatedArrivalDate?: Maybe<Scalars['Date']>;
};

export type RefurbishmentEta = {
  __typename?: 'RefurbishmentEta';
  id: Scalars['ID'];
  refurbishmentId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  eta: Scalars['LocalDate'];
  createdOn: Scalars['Date'];
  createdBy: User;
};

export type ReturnToAuto1Category = {
  __typename?: 'ReturnToAuto1Category';
  name: StringTranslation;
  mainReasons?: Maybe<Array<ReturnToAuto1MainReason>>;
};

export type ReturnToAuto1MainReason = {
  __typename?: 'ReturnToAuto1MainReason';
  name: StringTranslation;
};

export type ReturnToAuto1Reason = {
  __typename?: 'ReturnToAuto1Reason';
  id: Scalars['ID'];
  category: StringTranslation;
  reason?: Maybe<StringTranslation>;
};

export type ReturnToAuto1 = {
  __typename?: 'ReturnToAuto1';
  id: Scalars['ID'];
  mainReason?: Maybe<StringTranslation>;
  detailedReason: Scalars['String'];
  currentWheelSet: IntTranslation;
  hasSecondWheelSet: Scalars['Boolean'];
  roadworthy: Scalars['Boolean'];
  budgetMinorUnits?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  previousState: RefurbishmentStateTranslation;
  candidateForm: Scalars['Boolean'];
  canceled: Scalars['Boolean'];
  reasons?: Maybe<Array<ReturnToAuto1Reason>>;
  createdBy: User;
  createdOn: Scalars['Date'];
};

export type SpareParts = {
  __typename?: 'SpareParts';
  damageSpareParts?: Maybe<Array<DamageSparePart>>;
  vehicleServiceSpareParts?: Maybe<Array<ServiceSparePart>>;
  serviceWorkSpareParts?: Maybe<Array<ServiceSparePart>>;
};

export type DamageSparePart = {
  __typename?: 'DamageSparePart';
  id: Scalars['ID'];
  refurbishmentServiceId: Scalars['ID'];
  status?: Maybe<SparePartStatusTranslation>;
  sparePartCostMinorUnits?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  lastStatusUpdatedOn?: Maybe<Scalars['LocalDateTime']>;
  part: StringTranslation;
  type: StringTranslation;
  serviceName: RefurbishmentServiceNameTranslation;
};

export type ServiceSparePart = {
  __typename?: 'ServiceSparePart';
  id: Scalars['ID'];
  refurbishmentServiceId: Scalars['ID'];
  status?: Maybe<SparePartStatusTranslation>;
  sparePartCostMinorUnits?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  lastStatusUpdatedOn?: Maybe<Scalars['LocalDateTime']>;
  serviceName: RefurbishmentServiceNameTranslation;
};

export enum RepairCostCurrency {
  Chf = 'CHF',
  Dkk = 'DKK',
  Eur = 'EUR',
  Sek = 'SEK',
  Pln = 'PLN',
}

export enum ImportTaskType {
  RepairCost = 'REPAIR_COST',
  RepairMethod = 'REPAIR_METHOD',
}

export enum ImportTaskState {
  Created = 'CREATED',
  ValidationInProgress = 'VALIDATION_IN_PROGRESS',
  ValidationFinished = 'VALIDATION_FINISHED',
  ValidationFailed = 'VALIDATION_FAILED',
  ImportInProgress = 'IMPORT_IN_PROGRESS',
  ImportFinished = 'IMPORT_FINISHED',
  ImportFailed = 'IMPORT_FAILED',
  ImportCancelled = 'IMPORT_CANCELLED',
}

export type RepairCostFilter = {
  mainWorkshop?: Maybe<Scalars['String']>;
  mainWorkshopId?: Maybe<Scalars['Long']>;
  repairWorkshopId?: Maybe<Scalars['Long']>;
  repairPartnerId?: Maybe<Scalars['Long']>;
  area?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  repairMethod?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type RepairCostSortInput = {
  mainWorkshop?: Maybe<SortDirection>;
  repairWorkshop?: Maybe<SortDirection>;
  repairPartner?: Maybe<SortDirection>;
  createdOn?: Maybe<SortDirection>;
};

export type RepairCostCreateInput = {
  area: Scalars['String'];
  part: Scalars['String'];
  repairMethod: Scalars['String'];
  mainWorkshopBranchId: Scalars['Int'];
  repairWorkshopBranchId: Scalars['Int'];
  refurbishmentPartnerId: Scalars['Int'];
  cost: ServiceCostInput;
  isDefault: Scalars['Boolean'];
};

export type RepairMethodFilter = {
  countryCodes?: Maybe<Array<Scalars['String']>>;
  mainWorkshopIds?: Maybe<Array<Scalars['Long']>>;
  area?: Maybe<Scalars['String']>;
  damageType?: Maybe<Scalars['String']>;
  part?: Maybe<Scalars['String']>;
  repairMethodBelowThreshold?: Maybe<Scalars['String']>;
  repairMethodEqualOrAboveThreshold?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type RepairMethodSortInput = {
  mainWorkshop?: Maybe<SortDirection>;
  area?: Maybe<SortDirection>;
  damageType?: Maybe<SortDirection>;
  part?: Maybe<SortDirection>;
  createdOn?: Maybe<SortDirection>;
};

export type RepairMethodCreateInput = {
  mainWorkshopBranchId: Scalars['Int'];
  area: Scalars['String'];
  damageType: Scalars['String'];
  part: Scalars['String'];
  attribute1Name?: Maybe<Scalars['String']>;
  attribute1Value?: Maybe<Scalars['String']>;
  attribute2Name?: Maybe<Scalars['String']>;
  attribute2Value?: Maybe<Scalars['String']>;
  quantityThreshold: Scalars['String'];
  repairMethodBelowThreshold: Scalars['String'];
  repairMethodEqualOrAboveThreshold: Scalars['String'];
};

export type RepairMethodUpdateInput = {
  repairMethodBelowThreshold?: Maybe<Scalars['String']>;
  repairMethodEqualOrAboveThreshold?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type VehicleServiceCostInput = {
  refurbishmentPartnerId: Scalars['Long'];
  mainWorkshopBranchId: Scalars['Long'];
  locationBranchId: Scalars['Long'];
  vehicleServiceName: Scalars['String'];
  serviceCost: ServiceCostInput;
  active: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  isAutoCreate?: Maybe<Scalars['Boolean']>;
};

export type VehicleServiceCostUpdateInput = {
  serviceCost: ServiceCostInput;
  active: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  isAutoCreate?: Maybe<Scalars['Boolean']>;
};

export type ServiceCostInput = {
  laborRateMinorUnits: Scalars['Long'];
  laborTimeHours: Scalars['Float'];
  materialCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode: Scalars['String'];
};

export type VehicleServiceCostFilter = {
  mainWorkshop?: Maybe<Scalars['Long']>;
  locationId?: Maybe<Scalars['Long']>;
  partnerId?: Maybe<Scalars['Long']>;
  vehicleServiceName?: Maybe<Scalars['String']>;
};

export type VehicleServiceCostFilterV2 = {
  mainWorkshop?: Maybe<Scalars['Long']>;
  locationId?: Maybe<Scalars['Long']>;
  partnerId?: Maybe<Scalars['Long']>;
  vehicleServiceNames?: Maybe<Array<Scalars['String']>>;
};

export type VehicleServiceCostSortInput = {
  mainWorkshop?: Maybe<SortDirection>;
  refurbishmentPartnerName?: Maybe<SortDirection>;
  serviceName?: Maybe<SortDirection>;
};

export type RepairMethodByDamageInput = {
  mainWorkshopId: Scalars['Int'];
  vehicle: VehicleInput;
};

export type RepairCostByDamageInput = {
  mainWorkshopId: Scalars['Int'];
  repairWorkshopId?: Maybe<Scalars['Int']>;
  partnerId?: Maybe<Scalars['Int']>;
  vehicle: VehicleInput;
};

export type VehicleInput = {
  damages: Array<DamageInput>;
  stockNumber?: Maybe<Scalars['String']>;
};

export type DamageInput = {
  id: Scalars['String'];
  damageType: Scalars['String'];
  part: Scalars['String'];
  attributes: DamageAttributesInput;
  quantity: Scalars['String'];
};

export type DamageAttributesInput = {
  depth?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  incomplete?: Maybe<Scalars['String']>;
  legalStandard?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mechanicalFailure?: Maybe<Scalars['String']>;
  oilLoss?: Maybe<Scalars['String']>;
  paintQuality?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type CreateImportTaskInput = {
  name?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type AwsImportUploadCredentialsInput = {
  importId: Scalars['UUID'];
  extension: Scalars['String'];
};

export type RepairCostPageResponse = {
  __typename?: 'RepairCostPageResponse';
  items: Array<RepairCost>;
  pageInfo: PageInfo;
};

export type RepairCost = {
  __typename?: 'RepairCost';
  id: Scalars['ID'];
  area: StringTranslation;
  part: StringTranslation;
  repairMethod: StringTranslation;
  mainWorkshop: RepairCostWorkshop;
  repairWorkshop: RepairCostWorkshop;
  repairPartner: RepairCostPartner;
  cost: ServiceCost;
  isDefault: Scalars['Boolean'];
  active: Scalars['Boolean'];
};

export type RepairCostWorkshop = {
  __typename?: 'RepairCostWorkshop';
  id: Scalars['Int'];
  name: Scalars['String'];
  countryCode: Scalars['String'];
};

export type RepairCostPartner = {
  __typename?: 'RepairCostPartner';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type RepairMethodPageResponse = {
  __typename?: 'RepairMethodPageResponse';
  items: Array<RepairMethod>;
  pageInfo: PageInfo;
};

export type RepairMethod = {
  __typename?: 'RepairMethod';
  id: Scalars['ID'];
  mainWorkshop: RepairCostWorkshop;
  area: StringTranslation;
  damageType: StringTranslation;
  part: StringTranslation;
  repairMethodBelowThreshold: StringTranslation;
  repairMethodEqualOrAboveThreshold: StringTranslation;
  attribute1Name?: Maybe<Scalars['String']>;
  attribute1Value?: Maybe<Scalars['String']>;
  attribute2Name?: Maybe<Scalars['String']>;
  attribute2Value?: Maybe<Scalars['String']>;
  quantityThreshold: Scalars['String'];
  active: Scalars['Boolean'];
};

export type VehicleServiceCost = {
  __typename?: 'VehicleServiceCost';
  id: Scalars['UUID'];
  refurbishmentPartner: RepairCostPartner;
  mainWorkshop: RepairCostWorkshop;
  location: RepairCostWorkshop;
  vehicleServiceName: StringTranslation;
  serviceCost: ServiceCost;
  active: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  isAutoCreate?: Maybe<Scalars['Boolean']>;
};

export type ServiceCost = {
  __typename?: 'ServiceCost';
  totalPriceMinorUnits: Scalars['Long'];
  laborRateMinorUnits: Scalars['Long'];
  laborTimeHours: Scalars['Float'];
  materialCostMinorUnits: Scalars['Long'];
  sparePartCostMinorUnits: Scalars['Long'];
  consumableCostMinorUnits: Scalars['Long'];
  laborTotalCostMinorUnits: Scalars['Long'];
  currencyCode: Scalars['String'];
};

export type VehicleServiceCostPageResponse = {
  __typename?: 'VehicleServiceCostPageResponse';
  items: Array<VehicleServiceCost>;
  pageInfo: PageInfo;
};

export type RepairMethodItemsResponse = {
  __typename?: 'RepairMethodItemsResponse';
  repairMethodItems: Array<RepairMethodItemResponse>;
};

export type RepairMethodItemResponse = {
  __typename?: 'RepairMethodItemResponse';
  damageId: Scalars['String'];
  repairMethod: Scalars['String'];
};

export type RepairCostItemsResponse = {
  __typename?: 'RepairCostItemsResponse';
  repairCosts: Array<RepairCostItemResponse>;
};

export type RepairCostItemResponse = {
  __typename?: 'RepairCostItemResponse';
  damageId: Scalars['String'];
  repairMethod: Scalars['String'];
  totalCosts: RepairCostMoney;
  materialCosts: RepairCostMoney;
  splitMaterialCosts: MaterialCost;
  labour?: Maybe<Labour>;
};

export type RepairCostMoney = {
  __typename?: 'RepairCostMoney';
  currency: RepairCostCurrency;
  decimalAmount: Scalars['Float'];
  formattedAmount: Scalars['String'];
  amount: Scalars['Int'];
  minors: Scalars['Int'];
};

export type Labour = {
  __typename?: 'Labour';
  rate: RepairCostMoney;
  timeInHours: Scalars['Float'];
  laborCost: RepairCostMoney;
};

export type ImportTaskStatistics = {
  __typename?: 'ImportTaskStatistics';
  total?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
  duplicated?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
};

export type ImportTask = {
  __typename?: 'ImportTask';
  id: Scalars['ID'];
  name: Scalars['String'];
  uri: Scalars['String'];
  type: ImportTaskType;
  state: ImportTaskState;
  validatedOn?: Maybe<Scalars['Date']>;
  validationDebugLog?: Maybe<Scalars['String']>;
  importedOn?: Maybe<Scalars['Date']>;
  importDebugLog?: Maybe<Scalars['String']>;
  statistics?: Maybe<ImportTaskStatistics>;
  createdBy: User;
  createdOn: Scalars['Date'];
  updatedBy?: Maybe<User>;
  updatedOn?: Maybe<Scalars['Date']>;
};

export type ImportTaskPageResponse = {
  __typename?: 'ImportTaskPageResponse';
  items: Array<ImportTask>;
  pageInfo: PageInfo;
};

export type ImportTaskCreated = {
  __typename?: 'ImportTaskCreated';
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  uri: Scalars['String'];
  type: ImportTaskType;
  state: ImportTaskState;
  createdBy: User;
  createdOn: Scalars['Date'];
};

export type AwsImportUploadCredentialsResponse = {
  __typename?: 'AwsImportUploadCredentialsResponse';
  host?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map']>;
};

export type MaterialCost = {
  __typename?: 'MaterialCost';
  materialCost: RepairCostMoney;
  consumableCost: RepairCostMoney;
  sparePartCost: RepairCostMoney;
};

export type ProviderTotalSplitCostResponse = {
  __typename?: 'ProviderTotalSplitCostResponse';
  costs: Array<PartnerWorkshopSplitCosts>;
  totalBudget: Scalars['Int'];
  totalBudgetIncludingInternalLabor?: Maybe<Scalars['Int']>;
  actualGrossProfit?: Maybe<Scalars['Int']>;
};

export type PartnerWorkshopSplitCosts = {
  __typename?: 'PartnerWorkshopSplitCosts';
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  costs: Costs;
};

export type Costs = {
  __typename?: 'Costs';
  materialCostMinorUnits: Scalars['Int'];
  laborCostMinorUnits: Scalars['Int'];
};

export type RimSizeDto = {
  __typename?: 'RimSizeDTO';
  id: Scalars['UUID'];
  size: Scalars['Int'];
  unit: Scalars['String'];
};

export type LoadIndexDto = {
  __typename?: 'LoadIndexDTO';
  id: Scalars['UUID'];
  index: Scalars['Int'];
};

export type TireWidthDto = {
  __typename?: 'TireWidthDTO';
  id: Scalars['UUID'];
  width: Scalars['Int'];
  unit: Scalars['String'];
};

export type TireHeightDto = {
  __typename?: 'TireHeightDTO';
  id: Scalars['UUID'];
  heightInPercent: Scalars['Int'];
};

export type SpeedIndexDto = {
  __typename?: 'SpeedIndexDTO';
  id: Scalars['UUID'];
  index: Scalars['String'];
};

export type TireManufacturerDto = {
  __typename?: 'TireManufacturerDTO';
  id: Scalars['UUID'];
  manufacturer: Scalars['String'];
};

export enum ReviewState {
  Open = 'OPEN',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum ReviewType {
  QualityCheck = 'QUALITY_CHECK',
}

export enum PhaseName {
  Import = 'IMPORT',
  QualityCheckReview = 'QUALITY_CHECK_REVIEW',
  QualityCheckApproval = 'QUALITY_CHECK_APPROVAL',
}

export enum PhaseState {
  Open = 'OPEN',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum ItemType {
  Vehicle = 'VEHICLE',
  Damage = 'DAMAGE',
  Service = 'SERVICE',
  Refurbishment = 'REFURBISHMENT',
}

export enum RevisionState {
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Modified = 'MODIFIED',
  Deleted = 'DELETED',
}

export enum WheelSetSwitchVerificationStatus {
  PerformedBeforeQc = 'PERFORMED_BEFORE_QC',
  ToBePerformed = 'TO_BE_PERFORMED',
  NotNeeded = 'NOT_NEEDED',
}

export type ServiceItemCreateInput = {
  phaseId: Scalars['UUID'];
  name: RefurbishmentServiceName;
  type: RefurbishmentServiceType;
  relatedServiceId?: Maybe<Scalars['UUID']>;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  comment?: Maybe<Scalars['String']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
};

export type DamageItemCreateInput = {
  phaseId: Scalars['UUID'];
  area: Scalars['String'];
  subArea: Scalars['String'];
  partName: Scalars['String'];
  typeName: Scalars['String'];
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  imageUrl: Scalars['String'];
  customerDisplay: Scalars['Boolean'];
  name: RefurbishmentServiceName;
  type: RefurbishmentServiceType;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  comment?: Maybe<Scalars['String']>;
};

export type DamageItemCreateInputV2 = {
  phaseId: Scalars['UUID'];
  area: Scalars['String'];
  subArea: Scalars['String'];
  partName: Scalars['String'];
  typeName: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  attribute1Name?: Maybe<Scalars['String']>;
  attribute1Value?: Maybe<Scalars['String']>;
  attribute2Name?: Maybe<Scalars['String']>;
  attribute2Value?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  imageUrl: Scalars['String'];
  customerDisplay: Scalars['Boolean'];
  state: DamageState;
  stateComment?: Maybe<Scalars['String']>;
  currentService: DamageServiceCreateInput;
  photoUpdatedOn?: Maybe<Scalars['LocalDate']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
};

export type ServiceItemUpdateInput = {
  serviceName?: Maybe<RefurbishmentServiceName>;
  serviceType?: Maybe<RefurbishmentServiceType>;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  serviceComment?: Maybe<Scalars['String']>;
  stateComment?: Maybe<Scalars['String']>;
  state?: Maybe<RefurbishmentServiceState>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
};

export type DamageServiceCreateInput = {
  name: RefurbishmentServiceName;
  type: RefurbishmentServiceType;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  comment?: Maybe<Scalars['String']>;
  state: RefurbishmentServiceState;
  stateComment?: Maybe<Scalars['String']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
};

export type DamageItemUpdateInput = {
  area?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  partName?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  serviceName?: Maybe<RefurbishmentServiceName>;
  serviceType?: Maybe<RefurbishmentServiceType>;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  serviceComment?: Maybe<Scalars['String']>;
  state?: Maybe<DamageState>;
  stateComment?: Maybe<Scalars['String']>;
};

export type DamageItemUpdateInputV2 = {
  area?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  partName?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  attribute1Name?: Maybe<Scalars['String']>;
  attribute1Value?: Maybe<Scalars['String']>;
  attribute2Name?: Maybe<Scalars['String']>;
  attribute2Value?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  state?: Maybe<DamageState>;
  stateComment?: Maybe<Scalars['String']>;
  currentService?: Maybe<ServiceItemUpdateInput>;
  newService?: Maybe<ServiceItemUpdateInput>;
  photoUpdatedOn?: Maybe<Scalars['LocalDate']>;
};

export type MileageUpdateInput = {
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<MileageUnit>;
};

export type WheelProfileUpdateInput = {
  depth?: Maybe<Scalars['Int']>;
  unit?: Maybe<WheelProfileUnit>;
};

export type WheelUpdateInput = {
  position: Scalars['String'];
  season?: Maybe<Scalars['Int']>;
  profile?: Maybe<WheelProfileUpdateInput>;
  rimType?: Maybe<Scalars['Int']>;
  brakeCondition?: Maybe<Scalars['String']>;
  wheelSize?: Maybe<WheelSizeUpdateInput>;
  rimSize?: Maybe<RimSizeUpdateInput>;
  manufacturer?: Maybe<Scalars['String']>;
  loadIndex?: Maybe<Scalars['Int']>;
  speedIndex?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  dot?: Maybe<Scalars['String']>;
  tireDataUpToDate?: Maybe<Scalars['Boolean']>;
};

export type WheelSetUpdateInput = {
  isPrimary: Scalars['Boolean'];
  wheels: Array<WheelUpdateInput>;
};

export type VehicleItemUpdateInput = {
  mileage?: Maybe<MileageUpdateInput>;
  inspectionMileage?: Maybe<MileageUpdateInput>;
  maintenanceMileage?: Maybe<MileageUpdateInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionDueDate?: Maybe<Scalars['Date']>;
  maintenanceDate?: Maybe<Scalars['Date']>;
  wheelSets?: Maybe<Array<WheelSetUpdateInput>>;
  isServiceRequired?: Maybe<Scalars['Boolean']>;
  serviceCompany?: Maybe<Scalars['String']>;
  serviceBookType?: Maybe<Scalars['String']>;
  serviceDueDate?: Maybe<Scalars['LocalDate']>;
  serviceDueMileage?: Maybe<MileageUpdateInput>;
  carPassInspection?: Maybe<Scalars['Boolean']>;
  lastInspectionDate?: Maybe<Scalars['LocalDate']>;
  lastInspectionMileage?: Maybe<MileageUpdateInput>;
  lastInspectionDueDate?: Maybe<Scalars['LocalDate']>;
};

export type WheelSizeUpdateInput = {
  value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  height?: Maybe<Scalars['Int']>;
};

export type RimSizeUpdateInput = {
  value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
};

export type RefurbishmentItemUpdateInput = {
  wheelSetsSwitched?: Maybe<Scalars['Boolean']>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReason>;
  wheelSetSwitchVerificationStatus?: Maybe<WheelSetSwitchVerificationStatus>;
  serviceBookType?: Maybe<Scalars['String']>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
};

export type UpdateReviewInput = {
  readyForImperfectionPhotosOn?: Maybe<Scalars['LocalDate']>;
  imperfectionPhotosSkippedOn?: Maybe<Scalars['LocalDate']>;
};

export type ItemGraphQlPayload = {
  payloadType: Scalars['String'];
};

export type ReviewInfo = {
  __typename?: 'ReviewInfo';
  id: Scalars['ID'];
  refurbishmentId: Scalars['ID'];
  type: ReviewType;
  state: ReviewState;
  phases: Array<Phase>;
  tags?: Maybe<Array<Scalars['String']>>;
  readyForImperfectionPhotosOn?: Maybe<Scalars['LocalDate']>;
  imperfectionPhotosSkippedOn?: Maybe<Scalars['LocalDate']>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  refurbishment: RefurbishmentDetails;
  type: ReviewType;
  state: ReviewState;
  phases?: Maybe<Array<Phase>>;
  items?: Maybe<Array<ItemGroup>>;
  tags?: Maybe<Array<Scalars['String']>>;
  readyForImperfectionPhotosOn?: Maybe<Scalars['LocalDate']>;
  imperfectionPhotosSkippedOn?: Maybe<Scalars['LocalDate']>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type Phase = {
  __typename?: 'Phase';
  id: Scalars['ID'];
  name: PhaseName;
  state: PhaseState;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type ItemGroup = {
  __typename?: 'ItemGroup';
  itemId: Scalars['ID'];
  itemType?: Maybe<ItemType>;
  values?: Maybe<Array<Item>>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  phaseName: PhaseName;
  data: ItemGraphQlPayload;
  revisions?: Maybe<Array<Revision>>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type ItemExtended = {
  __typename?: 'ItemExtended';
  id: Scalars['ID'];
  attachedTo: Scalars['UUID'];
  phaseName: PhaseName;
  data: ItemGraphQlPayload;
  providerTotalCostsMinorUnits: Scalars['Long'];
  revisions?: Maybe<Array<Revision>>;
  createdOn: Scalars['Date'];
  updatedOn?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
};

export type DeleteItemResponse = {
  __typename?: 'DeleteItemResponse';
  providerTotalCostsMinorUnits: Scalars['Long'];
};

export type Revision = {
  __typename?: 'Revision';
  id: Scalars['ID'];
  propertyPath: Scalars['String'];
  state: RevisionState;
  createdOn: Scalars['Date'];
  createdBy?: Maybe<User>;
};

export type VehicleItemPayload = ItemGraphQlPayload & {
  __typename?: 'VehicleItemPayload';
  payloadType: Scalars['String'];
  mileage?: Maybe<Mileage>;
  inspectionMileage?: Maybe<Mileage>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionDueDate?: Maybe<Scalars['Date']>;
  maintenanceDate?: Maybe<Scalars['Date']>;
  maintenanceMileage?: Maybe<Mileage>;
  wheelSets: Array<Maybe<WheelSet>>;
  /** Constains the answer of the question "Does this car require a service?" */
  isServiceRequired?: Maybe<Scalars['Boolean']>;
  serviceCompany?: Maybe<StringTranslation>;
  serviceBookType?: Maybe<StringTranslation>;
  serviceDueDate?: Maybe<Scalars['LocalDate']>;
  serviceDueMileage?: Maybe<Mileage>;
  inspectionNeeded?: Maybe<Scalars['Boolean']>;
  doneBeforeEntryCheck?: Maybe<Scalars['Boolean']>;
  carPassInspection?: Maybe<Scalars['Boolean']>;
  lastInspectionDate?: Maybe<Scalars['LocalDate']>;
  lastInspectionMileage?: Maybe<Mileage>;
  lastInspectionDueDate?: Maybe<Scalars['LocalDate']>;
};

export type DamageItemPayload = ItemGraphQlPayload & {
  __typename?: 'DamageItemPayload';
  payloadType: Scalars['String'];
  area: StringTranslation;
  subArea: StringTranslation;
  partName: StringTranslation;
  typeName: StringTranslation;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  photoId?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  state: DamageState;
  serviceId?: Maybe<Scalars['UUID']>;
  stateComment?: Maybe<Scalars['String']>;
  serviceName: RefurbishmentServiceNameTranslation;
  serviceType: RefurbishmentServiceType;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  serviceComment?: Maybe<Scalars['String']>;
};

export type DamageItemPayloadV2 = ItemGraphQlPayload & {
  __typename?: 'DamageItemPayloadV2';
  payloadType: Scalars['String'];
  area: StringTranslation;
  subArea: StringTranslation;
  partName: StringTranslation;
  typeName: StringTranslation;
  version?: Maybe<Scalars['String']>;
  attribute1Name?: Maybe<StringTranslation>;
  attribute1Value?: Maybe<StringTranslation>;
  attribute2Name?: Maybe<StringTranslation>;
  attribute2Value?: Maybe<StringTranslation>;
  quantity?: Maybe<StringTranslation>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  photoId?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  state: DamageState;
  stateComment?: Maybe<Scalars['String']>;
  currentService: DamageService;
  newService?: Maybe<DamageService>;
  photoUpdatedOn?: Maybe<Scalars['LocalDate']>;
};

export type DamageService = {
  __typename?: 'DamageService';
  id?: Maybe<Scalars['UUID']>;
  serviceName: RefurbishmentServiceNameTranslation;
  serviceType: RefurbishmentServiceType;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
  serviceComment?: Maybe<Scalars['String']>;
  state: RefurbishmentServiceState;
  stateComment?: Maybe<Scalars['String']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
};

export type ServiceItemPayload = ItemGraphQlPayload & {
  __typename?: 'ServiceItemPayload';
  payloadType: Scalars['String'];
  serviceName: RefurbishmentServiceNameTranslation;
  serviceType: RefurbishmentServiceType;
  relatedServiceId?: Maybe<Scalars['UUID']>;
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  serviceComment?: Maybe<Scalars['String']>;
  state: RefurbishmentServiceState;
  stateComment?: Maybe<Scalars['String']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  isAutoCreate?: Maybe<Scalars['Boolean']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
};

export type RefurbishmentItemPayload = ItemGraphQlPayload & {
  __typename?: 'RefurbishmentItemPayload';
  payloadType: Scalars['String'];
  wheelSetsSwitched?: Maybe<Scalars['Boolean']>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReasonTranslation>;
  wheelSetSwitchVerificationStatus?: Maybe<
    WheelSetSwitchVerificationStatusTranslation
  >;
  providerTotalCostMinorUnits: Scalars['Long'];
  totalCostBudgetIncludingInternalLaborMinorUnits?: Maybe<Scalars['Long']>;
  serviceBookType?: Maybe<StringTranslation>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
};

export type CarReadyForExitCheckResponse = {
  __typename?: 'CarReadyForExitCheckResponse';
  readyForExitCheck: Scalars['Boolean'];
};

export enum RefurbishmentTaskItemState {
  Open = 'OPEN',
  Done = 'DONE',
  Declined = 'DECLINED',
}

export enum RefurbishmentTaskItemType {
  Damage = 'DAMAGE',
  Service = 'SERVICE',
  ServiceBookMainService = 'SERVICE_BOOK_MAIN_SERVICE',
  ServiceBookSubService = 'SERVICE_BOOK_SUB_SERVICE',
  DamagePa3 = 'DAMAGE_PA3',
}

export enum RefurbishmentTaskSourceType {
  EntryCheckAuthorization = 'ENTRY_CHECK_AUTHORIZATION',
  ReworkAuthorization = 'REWORK_AUTHORIZATION',
  PostAuthorization = 'POST_AUTHORIZATION',
}

export type RefurbishmentTaskFilter = {
  vinNumber?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  refurbishmentNumber?: Maybe<Scalars['String']>;
  refurbishmentStates?: Maybe<Array<RefurbishmentState>>;
};

export type RefurbishmentPartnerTaskEtaCreateInput = {
  taskId: Scalars['UUID'];
  partnerWorkshopConnectionId: Scalars['UUID'];
  eta: Scalars['LocalDate'];
};

export type RefurbishmentTaskAggregate = {
  __typename?: 'RefurbishmentTaskAggregate';
  items: Array<RefurbishmentTask>;
  pageInfo: PageInfo;
};

export type TaskRefurbishmentInfo = {
  __typename?: 'TaskRefurbishmentInfo';
  refurbishmentId: Scalars['UUID'];
  refurbishmentNumber: Scalars['String'];
  refurbishmentState: RefurbishmentStateTranslation;
  orderedOn?: Maybe<Scalars['Date']>;
  latestReworkDate?: Maybe<Scalars['Date']>;
  completedOn?: Maybe<Scalars['Date']>;
  currencyCode: CurrencyCode;
};

export type RefurbishmentTask = {
  __typename?: 'RefurbishmentTask';
  id: Scalars['ID'];
  vehicle: Vehicle;
  refurbishment: TaskRefurbishmentInfo;
  totalTaskItemsCount: Scalars['Int'];
  completedTaskItemsCount: Scalars['Int'];
  currentPartnerWorkshopConnection?: Maybe<PartnerWorkshopConnection>;
};

export type RefurbishmentTaskPartnerWorkshopConnectionUpdateResponse = {
  __typename?: 'RefurbishmentTaskPartnerWorkshopConnectionUpdateResponse';
  taskId: Scalars['UUID'];
  currentPartnerWorkshopConnection: PartnerWorkshopConnection;
};

export type RefurbishmentTaskStateUpdateResponse = {
  __typename?: 'RefurbishmentTaskStateUpdateResponse';
  taskId: Scalars['UUID'];
  refurbishmentId: Scalars['UUID'];
  refurbishmentState: RefurbishmentStateTranslation;
};

export type RefurbishmentTaskItemStateUpdateResponse = {
  __typename?: 'RefurbishmentTaskItemStateUpdateResponse';
  taskItemId: Scalars['UUID'];
  refurbishmentTaskItemState: RefurbishmentTaskItemStateTranslation;
};

export type BaseTaskItemsAggregateResponse = {
  vehicle: Vehicle;
  refurbishment: TaskRefurbishmentInfo;
  currentPartnerWorkshopConnection?: Maybe<PartnerWorkshopConnection>;
};

export type RefurbishmentTaskItemIncludingCostsResponse = BaseTaskItemsAggregateResponse & {
  __typename?: 'RefurbishmentTaskItemIncludingCostsResponse';
  vehicle: Vehicle;
  refurbishment: TaskRefurbishmentInfo;
  currentPartnerWorkshopConnection?: Maybe<PartnerWorkshopConnection>;
  items: Array<RefurbishmentTaskItemIncludingCost>;
};

export type RefurbishmentTaskItemExcludingCostsResponse = BaseTaskItemsAggregateResponse & {
  __typename?: 'RefurbishmentTaskItemExcludingCostsResponse';
  vehicle: Vehicle;
  refurbishment: TaskRefurbishmentInfo;
  currentPartnerWorkshopConnection?: Maybe<PartnerWorkshopConnection>;
  items: Array<RefurbishmentTaskItemExcludingCost>;
};

export type RefurbishmentTaskItem = {
  id: Scalars['ID'];
  partnerWorkshopConnection: PartnerWorkshopConnection;
  state: RefurbishmentTaskItemState;
  itemType: RefurbishmentTaskItemType;
  damage?: Maybe<Damage>;
};

export type RefurbishmentTaskItemIncludingCost = RefurbishmentTaskItem & {
  __typename?: 'RefurbishmentTaskItemIncludingCost';
  id: Scalars['ID'];
  partnerWorkshopConnection: PartnerWorkshopConnection;
  state: RefurbishmentTaskItemState;
  itemType: RefurbishmentTaskItemType;
  service?: Maybe<Service>;
  damage?: Maybe<Damage>;
};

export type RefurbishmentTaskItemExcludingCost = {
  __typename?: 'RefurbishmentTaskItemExcludingCost';
  id: Scalars['ID'];
  partnerWorkshopConnection: PartnerWorkshopConnection;
  state: RefurbishmentTaskItemState;
  itemType: RefurbishmentTaskItemType;
  service?: Maybe<ServiceExcludingCost>;
  damage?: Maybe<Damage>;
};

export type RefurbishmentPartnerTaskEtaResponse = {
  __typename?: 'RefurbishmentPartnerTaskEtaResponse';
  taskId: Scalars['UUID'];
  partnerWorkshopConnectionId: Scalars['UUID'];
  eta: Scalars['LocalDate'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount: Scalars['Int'];
};

export type UpdateOperationResponse = {
  __typename?: 'UpdateOperationResponse';
  status: UpdateOperationStatus;
  error?: Maybe<UpdateOperationError>;
};

export type UpdateOperationError = {
  __typename?: 'UpdateOperationError';
  errorType?: Maybe<UpdateOperationErrorType>;
};

export enum UserType {
  BackOffice = 'BACK_OFFICE',
  RefurbishmentProvider = 'REFURBISHMENT_PROVIDER',
}

export type UserCreateInputV2 = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Scalars['String'];
  locale: Scalars['String'];
  active: Scalars['Boolean'];
  groups: Array<Scalars['UUID']>;
  userPartnerWorkshopConnections: UserPartnerWorkshopConnectionInput;
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UserSearchInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UserSortInput = {
  email?: Maybe<SortDirection>;
  firstName?: Maybe<SortDirection>;
};

export type UserPartnerWorkshopConnectionInput = {
  partnerWorkshopConnectionIds?: Maybe<Array<Scalars['UUID']>>;
  userMainWorkshopIds?: Maybe<Array<Scalars['UUID']>>;
};

export type GroupCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  roles: Array<Scalars['UUID']>;
  users?: Maybe<Array<Scalars['UUID']>>;
};

export type GroupUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type GroupSearchInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type GroupSortInput = {
  name?: Maybe<SortDirection>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  userType: UserType;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  createdOn: Scalars['Date'];
  createdBy?: Maybe<User>;
  groups?: Maybe<Array<Group>>;
  effectivePermissions?: Maybe<Array<Scalars['String']>>;
  refurbishmentProvidersData?: Maybe<Array<RefurbishmentProviderData>>;
  userPartnerWorkshopConnections?: Maybe<Array<UserPartnerWorkshopConnection>>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdBy: User;
  createdOn?: Maybe<Scalars['Date']>;
  updatedOn?: Maybe<Scalars['Date']>;
  roles?: Maybe<Array<Role>>;
  users?: Maybe<Array<User>>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdOn?: Maybe<Scalars['Date']>;
  updatedOn?: Maybe<Scalars['Date']>;
  groups?: Maybe<Array<Group>>;
  permissions?: Maybe<Array<Permission>>;
};

export type Permission = {
  __typename?: 'Permission';
  id?: Maybe<Scalars['ID']>;
  action: Scalars['String'];
  createdOn: Scalars['Date'];
};

export type GroupPageResponse = {
  __typename?: 'GroupPageResponse';
  items: Array<Group>;
  pageInfo: PageInfo;
};

export type UserPageResponse = {
  __typename?: 'UserPageResponse';
  items: Array<User>;
  pageInfo: PageInfo;
};

export type UserPartnerWorkshopConnection = {
  __typename?: 'UserPartnerWorkshopConnection';
  mainWorkshop: Workshop;
  isAllLocations: Scalars['Boolean'];
  partnerWorkshopConnections?: Maybe<Array<PartnerWorkshopConnection>>;
};

export enum VehicleModelVersion {
  V1_0 = 'V1_0',
}

export enum DamageArea {
  Brakes = 'BRAKES',
  Controls = 'CONTROLS',
  Engine = 'ENGINE',
  Front = 'FRONT',
  General = 'GENERAL',
  Interior = 'INTERIOR',
  Left = 'LEFT',
  Rear = 'REAR',
  Right = 'RIGHT',
  Roof = 'ROOF',
  Trunk = 'TRUNK',
  Underbody = 'UNDERBODY',
  Wheels = 'WHEELS',
}

export type VehicleDamageAttribute = {
  __typename?: 'VehicleDamageAttribute';
  value: StringTranslation;
};

export type VehicleDamageAttributes = {
  __typename?: 'VehicleDamageAttributes';
  attributeName: StringTranslation;
  attributeValues: Array<VehicleDamageAttribute>;
};

export type VehicleDamageQuantity = {
  __typename?: 'VehicleDamageQuantity';
  quantity: StringTranslation;
};

export type VehicleAttributesQuantities = {
  __typename?: 'VehicleAttributesQuantities';
  attributes: Array<VehicleDamageAttributes>;
  quantities: Array<VehicleDamageQuantity>;
};

export type VehicleDamageArea = {
  __typename?: 'VehicleDamageArea';
  area: DamageAreaTranslation;
};

export type VehicleDamagePart = {
  __typename?: 'VehicleDamagePart';
  part: StringTranslation;
  area: DamageAreaTranslation;
};

export type VehicleDamageType = {
  __typename?: 'VehicleDamageType';
  type: StringTranslation;
};

export type VehicleRepairMethod = {
  __typename?: 'VehicleRepairMethod';
  repairMethod: StringTranslation;
};

export enum MileageUnit {
  Km = 'KM',
  Miles = 'MILES',
}

export enum WheelProfileUnit {
  Mm = 'MM',
}

export enum CarAttributeType {
  FuelType = 'FUEL_TYPE',
  TireType = 'TIRE_TYPE',
  RimType = 'RIM_TYPE',
}

export type CarAttributeInput = {
  attributes: Array<CarAttributeType>;
};

export type VehicleUpdateInput = {
  adId: Scalars['ID'];
  maintenanceDate?: Maybe<Scalars['Date']>;
  maintenanceMileage?: Maybe<MileageUpdateInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionDueDate?: Maybe<Scalars['Date']>;
  inspectionMileage?: Maybe<MileageUpdateInput>;
};

export type Mileage = {
  __typename?: 'Mileage';
  distance: Scalars['Int'];
  unit: MileageUnitTranslation;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  vin: Scalars['String'];
  stockNumber: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  subModel: Scalars['String'];
  registration?: Maybe<Scalars['Date']>;
  color?: Maybe<Color>;
  builtYear?: Maybe<Scalars['Int']>;
  modelCode?: Maybe<Scalars['String']>;
  bodyType?: Maybe<Scalars['String']>;
  doorCount?: Maybe<Scalars['Int']>;
  licensePlate?: Maybe<Scalars['String']>;
  manufacturerCode?: Maybe<Scalars['String']>;
  lastServiceMileage?: Maybe<Mileage>;
  mileage?: Maybe<Mileage>;
  gearType?: Maybe<IntTranslation>;
  fuelType?: Maybe<IntTranslation>;
  lastServiceOn?: Maybe<Scalars['Date']>;
  inspectionExpiryDate?: Maybe<Scalars['Date']>;
  sourcingCountry?: Maybe<Scalars['String']>;
  wheelSets: Array<Maybe<WheelSet>>;
  registrationUrl?: Maybe<Scalars['String']>;
  serviceBookImageUrls?: Maybe<Array<Scalars['String']>>;
  technicalInspectionCertificateIssueDate?: Maybe<Scalars['Date']>;
  technicalInspectionCertificateIssueMileage?: Maybe<Mileage>;
  technicalInspectionCertificateUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  overviewImageFullUrl?: Maybe<Scalars['String']>;
  electricBatteryDetails?: Maybe<ElectricBatteryDetails>;
  source?: Maybe<VehicleSource>;
  serviceBookType?: Maybe<Scalars['Int']>;
};

export type VehicleSource = {
  __typename?: 'VehicleSource';
  /** contains enum like value REMARKETING or C2B */
  value: Scalars['String'];
  /** contains translated name like "Refurbishment" */
  name: Scalars['String'];
  evaluationType?: Maybe<EvaluationType>;
};

export type EvaluationType = {
  __typename?: 'EvaluationType';
  /** contains enum like value "EVA_PDF" */
  value: Scalars['String'];
  /** contains translated name like "Incomplete Evaluation (PDF Inspection Report)" */
  name: Scalars['String'];
};

export type Color = {
  __typename?: 'Color';
  outside: IntTranslation;
  metallic: Scalars['Boolean'];
};

export type Wheel = {
  __typename?: 'Wheel';
  position: Scalars['String'];
  season?: Maybe<IntTranslation>;
  seasonLabel?: Maybe<Scalars['String']>;
  dot?: Maybe<Scalars['String']>;
  profile?: Maybe<WheelProfile>;
  rimType?: Maybe<IntTranslation>;
  brakeDamageId?: Maybe<Scalars['ID']>;
  brakeCondition?: Maybe<StringTranslation>;
  wheelSize?: Maybe<WheelSize>;
  rimSize?: Maybe<RimSize>;
  manufacturer?: Maybe<Scalars['String']>;
  loadIndex?: Maybe<Scalars['Int']>;
  speedIndex?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  tireDataUpToDate?: Maybe<Scalars['Boolean']>;
};

export type WheelSize = {
  __typename?: 'WheelSize';
  value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  height?: Maybe<Scalars['Int']>;
};

export type RimSize = {
  __typename?: 'RimSize';
  value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
};

export type WheelProfile = {
  __typename?: 'WheelProfile';
  depth: Scalars['Int'];
  unit: WheelProfileUnitTranslation;
};

export type WheelSet = {
  __typename?: 'WheelSet';
  isPrimary: Scalars['Boolean'];
  wheels: Array<Wheel>;
};

export type CarAttributeTypesResponse = {
  __typename?: 'CarAttributeTypesResponse';
  attributeTypeValues: Array<CarAttributeTypeValues>;
};

export type CarAttributeTypeValues = {
  __typename?: 'CarAttributeTypeValues';
  attribute?: Maybe<CarAttributeType>;
  values: Scalars['Map'];
};

export type WheelSetDeletedReasonsResponse = {
  __typename?: 'WheelSetDeletedReasonsResponse';
  wheelSetDeletedReason: StringTranslation;
};

export type ElectricBatteryDetails = {
  __typename?: 'ElectricBatteryDetails';
  isLeased?: Maybe<Scalars['Boolean']>;
  leasingCost?: Maybe<Scalars['Int']>;
  hasConditionReport?: Maybe<Scalars['Boolean']>;
  conditionStatus?: Maybe<IntTranslation>;
  capacity?: Maybe<Scalars['Int']>;
  range?: Maybe<Scalars['Int']>;
  batteryDocumentUrl?: Maybe<Scalars['String']>;
};

export type StringTranslation = {
  __typename?: 'StringTranslation';
  key: Scalars['String'];
  translation?: Maybe<Scalars['String']>;
};

export type DamageCancellationReasonTranslation = {
  __typename?: 'DamageCancellationReasonTranslation';
  key: DamageCancellationReason;
  translation?: Maybe<Scalars['String']>;
};

export type IntTranslation = {
  __typename?: 'IntTranslation';
  key: Scalars['Int'];
  translation?: Maybe<Scalars['String']>;
};

export type MileageUnitTranslation = {
  __typename?: 'MileageUnitTranslation';
  key: MileageUnit;
  translation?: Maybe<Scalars['String']>;
};

export type WheelProfileUnitTranslation = {
  __typename?: 'WheelProfileUnitTranslation';
  key: WheelProfileUnit;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentServiceNameTranslation = {
  __typename?: 'RefurbishmentServiceNameTranslation';
  key: RefurbishmentServiceName;
  translation?: Maybe<Scalars['String']>;
};

export type BeautyShotTranslation = {
  __typename?: 'BeautyShotTranslation';
  key: BeautyShot;
  translation?: Maybe<Scalars['String']>;
};

export type WheelSetDeleteReasonTranslation = {
  __typename?: 'WheelSetDeleteReasonTranslation';
  key: WheelSetDeleteReason;
  translation?: Maybe<Scalars['String']>;
};

export type BatteryQualityTypeTranslation = {
  __typename?: 'BatteryQualityTypeTranslation';
  key: BatteryQualityType;
  translation?: Maybe<Scalars['String']>;
};

export type BrakeFluidLevelTranslation = {
  __typename?: 'BrakeFluidLevelTranslation';
  key: BrakeFluidLevel;
  translation?: Maybe<Scalars['String']>;
};

export type CoolantLevelTranslation = {
  __typename?: 'CoolantLevelTranslation';
  key: CoolantLevel;
  translation?: Maybe<Scalars['String']>;
};

export type EngineSoundTypeTranslation = {
  __typename?: 'EngineSoundTypeTranslation';
  key: EngineSoundType;
  translation?: Maybe<Scalars['String']>;
};

export type OilLevelTypeTranslation = {
  __typename?: 'OilLevelTypeTranslation';
  key: OilLevelType;
  translation?: Maybe<Scalars['String']>;
};

export type SparePartStatusTranslation = {
  __typename?: 'SparePartStatusTranslation';
  key: SparePartStatus;
  translation?: Maybe<Scalars['String']>;
};

export type ExitCheckStateTranslation = {
  __typename?: 'ExitCheckStateTranslation';
  key: ExitCheckState;
  translation?: Maybe<Scalars['String']>;
};

export type ExitCheckGpsTypeTranslation = {
  __typename?: 'ExitCheckGpsTypeTranslation';
  key: ExitCheckGpsType;
  translation?: Maybe<Scalars['String']>;
};

export type BatteryQualityTranslation = {
  __typename?: 'BatteryQualityTranslation';
  key: BatteryQuality;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentStateTranslation = {
  __typename?: 'RefurbishmentStateTranslation';
  key: RefurbishmentState;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentTypeTranslation = {
  __typename?: 'RefurbishmentTypeTranslation';
  key: RefurbishmentType;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentSparePartStatusTranslation = {
  __typename?: 'RefurbishmentSparePartStatusTranslation';
  key: RefurbishmentSparePartStatus;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentCancelReasonTranslation = {
  __typename?: 'RefurbishmentCancelReasonTranslation';
  key: RefurbishmentCancelReason;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentCompletedReasonTranslation = {
  __typename?: 'RefurbishmentCompletedReasonTranslation';
  key: RefurbishmentCompletedReason;
  translation?: Maybe<Scalars['String']>;
};

export type RouteStatusTranslation = {
  __typename?: 'RouteStatusTranslation';
  key: RouteStatus;
  translation?: Maybe<Scalars['String']>;
};

export type WheelSetSwitchVerificationStatusTranslation = {
  __typename?: 'WheelSetSwitchVerificationStatusTranslation';
  key: WheelSetSwitchVerificationStatus;
  translation?: Maybe<Scalars['String']>;
};

export type RefurbishmentTaskItemStateTranslation = {
  __typename?: 'RefurbishmentTaskItemStateTranslation';
  key: RefurbishmentTaskItemState;
  translation?: Maybe<Scalars['String']>;
};

export type DamageAreaTranslation = {
  __typename?: 'DamageAreaTranslation';
  key: DamageArea;
  translation?: Maybe<Scalars['String']>;
};
