import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from 'modules/api/partnerWorkshop/gql/fragments';
import {
  DamageFragment,
  DocumentFragment,
  PartnerWorkshopConnectionRefurbishmentResponseFragment,
  ServiceFragment,
  SparePartsFragment,
  TransitionFragment,
  VehicleFragment,
} from './fragments';
import { CommentFragment } from '../../common/gql/fragments';
import { RefurbishmentEtaFragment } from '../../entryCheck/gql/fragments';

export default gql`
  query refurbishmentDetails($id: UUID!) {
    refurbishmentDetails(id: $id) {
      id
      refId
      adId
      retailReady
      state
      country
      currencyCode
      location {
        id
        name
      }
      partner {
        id
        name
      }
      cancelReason
      cancelDescription
      completedReason
      picturesReceivedOn
      picturesUploadedOn
      lastStateUpdatedOn
      daysInCurrentState
      daysSincePreparationStarted
      estimatedCompleteDate
      providerEstimatedCompleteDate
      estimatedTotalCostMinorUnits
      providerTotalCostMinorUnits
      totalCostBudgetIncludingInternalLaborMinorUnits
      partnerWorkshopConnections {
        ...PartnerWorkshopConnectionRefurbishmentResponseFragment
      }
      vehicleLocation {
        name
      }
      route {
        status
        estimatedArrivalDate
      }
      transitionCurrentSet {
        ...TransitionFragment
      }
      comments {
        ...CommentFragment
      }
      vehicle {
        ...VehicleFragment
      }
      damages {
        ...DamageFragment
      }
      services {
        ...ServiceFragment
      }
      documents {
        ...DocumentFragment
      }
      refurbishmentEtas {
        ...RefurbishmentEtaFragment
      }
      beautyShot
      beautyShotsTakenOn
      flowVersion
      refurbishmentType
      wheelSetDeleted
      wheelSetDeletedReason
      refurbishmentEtas {
        id
        refurbishmentId
        comment
        eta
        createdOn
        createdBy {
          firstName
          lastName
        }
      }
      vehicleInspection {
        inspectionNeeded
        doneBeforeEntryCheck
        carPassInspection
        lastInspectionDate
        lastMileage {
          distance
          unit
        }
        lastInspectionDueDate
      }
      currentPartnerWorkshopConnection {
        ...PartnerWorkshopConnectionFragment
      }
      partnerWorkshopConnections {
        ...PartnerWorkshopConnectionRefurbishmentResponseFragment
      }
      keysCount
      serviceBookType
      serviceBookPresent
      returnToAuto1 {
        id
        mainReason
        detailedReason
        currentWheelSet
        hasSecondWheelSet
        roadworthy
        budgetMinorUnits
        currency
        previousState
        candidateForm
        canceled
        reasons {
          id
          category
          reason
        }
        createdBy {
          id
          firstName
          lastName
          email
        }
        createdOn
      }
      tags
      spareParts {
        ...SparePartsFragment
      }
      refurbishmentSparePartStatus
      isAutoOrdered
    }
  }
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
  ${TransitionFragment}
  ${CommentFragment}
  ${VehicleFragment}
  ${DamageFragment}
  ${ServiceFragment}
  ${DocumentFragment}
  ${RefurbishmentEtaFragment}
  ${PartnerWorkshopConnectionFragment}
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
  ${SparePartsFragment}
`;
