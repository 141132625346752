import {
  GroupedPartnerWorkshopConnections,
  PartnerWorkshopConnection,
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
  Workshop,
} from 'graphqlTypes';

export const getUserPartnerWorkshopConnections = (
  groupedPartnerWorkshopConnections:
    | GroupedPartnerWorkshopConnections
    | undefined,
  partnerWorkshopConnections: UserPartnerWorkshopConnectionInput,
): UserPartnerWorkshopConnection[] => {
  if (!groupedPartnerWorkshopConnections) return [];

  const workshopsById: Record<string, Workshop> = {};
  const connectionsById: Record<string, PartnerWorkshopConnection> = {};
  const connectionsByMainWorkshopId: Record<
    string,
    PartnerWorkshopConnection[]
  > = {};

  groupedPartnerWorkshopConnections?.items[0].groupedPartnerWorkshopConnections.forEach(
    groupedConnections => {
      groupedConnections.forEach(connection => {
        workshopsById[connection.mainWorkshop.id] = connection.mainWorkshop;
        workshopsById[connection.repairWorkshop.id] = connection.repairWorkshop;
        connectionsById[connection.id] = connection;
        if (!connectionsByMainWorkshopId[connection.mainWorkshop.id])
          connectionsByMainWorkshopId[connection.mainWorkshop.id] = [];
        connectionsByMainWorkshopId[connection.mainWorkshop.id].push(
          connection,
        );
      });
    },
  );

  const userPWC =
    partnerWorkshopConnections.partnerWorkshopConnectionIds?.reduce(
      (acc: Record<string, UserPartnerWorkshopConnection>, cur) => {
        const connection = connectionsById[cur];
        const mainWorkshopId = connection.mainWorkshop.id;

        if (!acc[mainWorkshopId]) {
          acc[mainWorkshopId] = {
            mainWorkshop: connection.mainWorkshop,
            isAllLocations:
              partnerWorkshopConnections.userMainWorkshopIds?.includes(
                mainWorkshopId,
              ) || false,
            partnerWorkshopConnections: [],
          };
        }

        if (acc[mainWorkshopId].isAllLocations) {
          acc[mainWorkshopId].partnerWorkshopConnections = [];
        } else if (
          !acc[mainWorkshopId].partnerWorkshopConnections?.find(
            con => con.id === connection.id,
          )
        ) {
          acc[mainWorkshopId].partnerWorkshopConnections!.push(connection);
        }

        return acc;
      },
      {},
    ) || {};

  partnerWorkshopConnections.userMainWorkshopIds?.forEach(workshopId => {
    userPWC[workshopId] = {
      mainWorkshop: workshopsById[workshopId],
      isAllLocations: true,
      partnerWorkshopConnections: connectionsByMainWorkshopId[workshopId] || [],
    };
  });

  return Object.values(userPWC);
};
