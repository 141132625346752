import gql from 'graphql-tag';

export default gql`
  query rimSizes {
    rimSizes {
      size
      unit
    }
  }
`;
