import gql from 'graphql-tag';

import { DamageItemPayloadFragment } from './fragments';

export default gql`
  mutation createQualityCheckDamageItem(
    $damageItemCreateInput: DamageItemCreateInput!
  ) {
    createQualityCheckDamageItem(
      damageItemCreateInput: $damageItemCreateInput
    ) {
      id
      providerTotalCostsMinorUnits
      phaseName
      createdOn
      revisions {
        id
        propertyPath
        state
        createdBy {
          id
          firstName
        }
      }
      data {
        payloadType
        ...DamageItemPayloadFragment
        ... on DamageItemPayload {
          damageState: state
        }
      }
    }
  }
  ${DamageItemPayloadFragment}
`;
