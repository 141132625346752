import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import FieldError from 'components/Form/FieldError';
import { FormFilterNames } from './constants';
import { vinStockRefRegExp } from './helpers';

import cn from './Filter.module.scss';

const dropDownOptions = [
  {
    key: FormFilterNames.VIN_STOCK,
    translation: __('refurbishment.overview.vinStockNumber'),
  },
  {
    key: FormFilterNames.PLATE_NUMBER,
    translation: __('entryCheck.vehicleDetails.licensePlate'),
  },
];

type Props = {
  className?: string;
};

const VinStockPlateInput = ({ className }: Props) => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'VinStockPlateInput' must be used inside a 'Filter' component.",
    );
  }
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const isPlateNumber = getValues && !!getValues(FormFilterNames.PLATE_NUMBER);
  const [currentOption, setCurrentOption] = React.useState(
    dropDownOptions[isPlateNumber ? 1 : 0],
  );

  useEffect(() => {
    setCurrentOption(dropDownOptions[isPlateNumber ? 1 : 0]);
  }, [isPlateNumber]);

  const handleSelect = (key: any) => {
    if (setValue) {
      dropDownOptions
        .filter(field => key !== field.key)
        .forEach(field => setValue(field.key, null));
    }

    setCurrentOption(dropDownOptions.filter(field => key === field.key)[0]);
  };

  return (
    <div className={cns(cn.col, cn.input, cn.refVinStockPlate, className)}>
      <InputGroup>
        <DropdownButton
          as={InputGroup.Prepend}
          variant="gray-outlined"
          title={currentOption.translation}
          onSelect={handleSelect}
          data-qa-selector="nuber_plate_selector"
        >
          {dropDownOptions.map(({ key, translation }) => (
            <Dropdown.Item
              data-qa-selector={`number_plate_selector-${key}`}
              eventKey={key}
              key={key}
              className={cn.dropdownHover}
            >
              {translation}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        {currentOption.key === FormFilterNames.VIN_STOCK ? (
          <Form.Control
            {...register(FormFilterNames.VIN_STOCK, {
              pattern: {
                value: vinStockRefRegExp,
                message: __('refurbishment.overview.wrongVinStockNumber'),
              },
            })}
            key="stock_number_filter"
            data-qa-selector="stock_number_filter"
          />
        ) : (
          <Form.Control
            {...register(FormFilterNames.PLATE_NUMBER)}
            key="plate_filter"
            data-qa-selector="plate_filter"
          />
        )}
      </InputGroup>
      {!!errors && (
        <FieldError name={FormFilterNames.VIN_STOCK} errors={errors} />
      )}
    </div>
  );
};

export default VinStockPlateInput;
