import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { LANGUAGE_FLAGS, LANGUAGES, LOCALE, LOCALES } from 'modules/i18n';

import cn from './Header.module.scss';

const LanguageSelector = () => {
  const location = useLocation();

  const handleChangeLanguage = (lang: string) => {
    const { pathname, search } = location;
    const url = pathname.replace(LOCALE, lang);

    window.location.assign(`${url}${search}`);
  };

  return (
    <NavDropdown
      title={
        <span>
          <img
            className={cn.flagIcon}
            src={LANGUAGE_FLAGS[LOCALE]}
            alt={LOCALE}
          />
        </span>
      }
      className={cn.languageSelector}
      alignRight
      id="language-nav-dropdown"
      data-qa-selector="language_nav_dropdown"
    >
      {LOCALES.map(locale => (
        <NavDropdown.Item
          className={cn.flagItem}
          onClick={() => handleChangeLanguage(locale)}
          key={locale}
        >
          <img
            className={cn.flagIcon}
            src={LANGUAGE_FLAGS[locale]}
            alt={locale}
          />
          <span>{LANGUAGES[locale]}</span>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default LanguageSelector;
