import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { get } from 'lodash/fp';

import {
  EntryCheckItemValue,
  EntryCheckItemValueCreateInput,
  Mutation,
  MutationCreateEntryCheckItemValuesArgs,
  Query,
  QueryEntryCheckAuthorizationArgs,
} from 'graphqlTypes';
import CREATE_ENTRY_CHECK_ITEM_VALUES from './gql/createEntryCheckItemValues';
import ENTRY_CHECK from './gql/entryCheck';
import ENTRY_CHECK_AUTHORIZATION from './gql/entryCheckAuthorization';
import ENTRY_CHECK_POST_AUTHORIZATION from './gql/entryCheckPostAuthorization';

export type CreateEntryCheckItemValuesPromise = Promise<
  ExecutionResult<Pick<Mutation, 'createEntryCheckItemValues'>>
>;

type EntryCheckQuery = keyof Pick<
  Query,
  'entryCheck' | 'entryCheckAuthorization' | 'entryCheckPostAuthorization'
>;

const getQuery = (entryCheckQueryName: EntryCheckQuery) => {
  if (entryCheckQueryName === 'entryCheck') return ENTRY_CHECK;
  if (entryCheckQueryName === 'entryCheckAuthorization')
    return ENTRY_CHECK_AUTHORIZATION;
  if (entryCheckQueryName === 'entryCheckPostAuthorization')
    return ENTRY_CHECK_POST_AUTHORIZATION;
  throw new Error(
    'Invalid `entryCheckQueryType` in `useCreateEntryCheckItemValues`',
  );
};

const useCreateEntryCheckItemValues = <T extends EntryCheckQuery>(
  entryCheckQueryName: T,
) => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createEntryCheckItemValues'>,
    MutationCreateEntryCheckItemValuesArgs
  >(CREATE_ENTRY_CHECK_ITEM_VALUES);

  const createEntryCheckItemValues = async (
    inputs: EntryCheckItemValueCreateInput[],
    refurbishmentId: string,
  ) => {
    const query = getQuery(entryCheckQueryName);

    return mutation({
      variables: { inputs },
      update(cache, { data: updatedData }) {
        const entryCheck = cache.readQuery<
          Pick<Query, typeof entryCheckQueryName>,
          QueryEntryCheckAuthorizationArgs
        >({
          query,
          variables: {
            refurbishmentId,
          },
        });

        if (entryCheck && updatedData) {
          const newValues = new Map(
            updatedData.createEntryCheckItemValues.map(value => [
              value.entryCheckItemId,
              value,
            ]),
          );

          const items = entryCheck[entryCheckQueryName]!.items.map(item => {
            return {
              ...item,
              values: [
                ...item.values,
                ...([newValues.get(item.id)].filter(
                  Boolean,
                ) as EntryCheckItemValue[]),
              ],
            };
          });

          cache.writeQuery<
            Pick<Query, typeof entryCheckQueryName>,
            QueryEntryCheckAuthorizationArgs
          >({
            query,
            data: {
              [entryCheckQueryName]: {
                ...entryCheck[entryCheckQueryName],
                items,
              },
            } as Pick<Query, typeof entryCheckQueryName>,
            variables: {
              refurbishmentId,
            },
          });
        }
      },
    });
  };

  return [
    createEntryCheckItemValues,
    {
      data: get('createEntryCheckItemValues', data),
      ...rest,
    },
  ] as const;
};

export default useCreateEntryCheckItemValues;
