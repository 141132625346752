import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import {
  Query,
  QueryRefurbishmentActiveGroupedPartnerWorkshopConnectionsArgs,
} from 'graphqlTypes';
import REFURBISHMENT_ACTIVE_GROUPED_PARTNER_WORKSHOP_CONNECTIONS from './gql/refurbishmentActiveGroupedPartnerWorkshopConnections';

const useRefurbishmentActiveGroupedPartnerWorkshopConnections = (
  variables: QueryRefurbishmentActiveGroupedPartnerWorkshopConnectionsArgs,
  options?: QueryHookOptions<
    Pick<Query, 'refurbishmentActiveGroupedPartnerWorkshopConnections'>,
    QueryRefurbishmentActiveGroupedPartnerWorkshopConnectionsArgs
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'refurbishmentActiveGroupedPartnerWorkshopConnections'>,
    QueryRefurbishmentActiveGroupedPartnerWorkshopConnectionsArgs
  >(REFURBISHMENT_ACTIVE_GROUPED_PARTNER_WORKSHOP_CONNECTIONS, {
    ...options,
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.refurbishmentActiveGroupedPartnerWorkshopConnections,
    ...rest,
  };
};

export default useRefurbishmentActiveGroupedPartnerWorkshopConnections;
