import gql from 'graphql-tag';

import { ServiceItemPayloadFragment } from './fragments';

export default gql`
  mutation updateQualityCheckApprovalServiceItem(
    $itemId: UUID!
    $serviceItemUpdateInput: ServiceItemUpdateInput!
  ) {
    updateQualityCheckApprovalServiceItem(
      itemId: $itemId
      serviceItemUpdateInput: $serviceItemUpdateInput
    ) {
      id
      data {
        payloadType
        ... on ServiceItemPayload {
          serviceState: state
        }
        ...ServiceItemPayloadFragment
      }
      revisions {
        id
        propertyPath
        state
      }
    }
  }
  ${ServiceItemPayloadFragment}
`;
