import { get } from 'lodash/fp';

import {
  Query,
  QueryDownloadRepairMethodImportTaskValidationResultArgs,
  Scalars,
} from 'graphqlTypes';
import QUERY from './gql/downloadRepairMethodImportTaskValidationResult';
import usePromiseQuery from '../helpers/usePromiseQuery';

const usePromiseDownloadRepairMethodImportTaskValidationResult = () => {
  const query = usePromiseQuery<
    Query['downloadRepairMethodImportTaskValidationResult'],
    QueryDownloadRepairMethodImportTaskValidationResultArgs
  >(QUERY, {
    fetchPolicy: 'network-only',
    context: { disableBatch: true },
  });

  const getData = async (id: Scalars['UUID']) => {
    const { data } = await query({
      id,
    });

    return get(['downloadRepairMethodImportTaskValidationResult'], data);
  };

  return getData;
};

export default usePromiseDownloadRepairMethodImportTaskValidationResult;
