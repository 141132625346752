import { useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  EntryCheckItemValueSourceType,
  RefurbishmentDetails,
} from 'graphqlTypes';
import { AddDamageModal } from './index';
import { DamageFormData, SliceKeyDamage, WheelPosition } from '../../types';

interface Props {
  id: string;
  refurbishment: RefurbishmentDetails;
  hasPA3?: boolean;
  name?: string;
  damageGroup?: SliceKeyDamage | WheelPosition;
  sourceType: EntryCheckItemValueSourceType;
  isSecondaryWheel: boolean | null;
  buttonLabel?: string;
  budgetRequired: boolean;
  isImperfectionRepairMethods: boolean;
  validateSubmit?(data: DamageFormData): string | null;
  hideCost?: boolean;
  hasSplitCost?: boolean;
  hasSparePartsManagement: boolean;
}

const AddNewDamage = ({
  id,
  refurbishment,
  hasPA3,
  name,
  damageGroup,
  sourceType,
  isSecondaryWheel,
  buttonLabel,
  budgetRequired,
  isImperfectionRepairMethods,
  validateSubmit,
  hideCost,
  hasSplitCost,
  hasSparePartsManagement,
}: Props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => setShow(true)}
        data-qa-selector="addDamage"
      >
        {buttonLabel || __('entryCheck.addNewDamage')}
      </Button>
      <AddDamageModal
        id={id}
        refurbishment={refurbishment}
        hasPA3={hasPA3}
        name={name}
        sourceType={sourceType}
        isSecondaryWheel={isSecondaryWheel}
        show={show}
        setShow={setShow}
        damageGroup={damageGroup}
        budgetRequired={budgetRequired}
        validateSubmit={validateSubmit}
        hideCost={hideCost}
        hasSplitCost={hasSplitCost}
        isImperfectionRepairMethods={isImperfectionRepairMethods}
        hasSparePartsManagement={hasSparePartsManagement}
      />
    </>
  );
};

export default AddNewDamage;
