import { Link } from 'react-router-dom';

import { refurbishmentTypesTranslated } from 'constants/refurbishmentType';
import { statesTranslated } from 'constants/state';
import {
  ModelInfo,
  ProductionOverviewItem,
  RefurbishmentState,
  RefurbishmentType,
} from 'graphqlTypes';
import { formatDateTime } from 'modules/i18n';
import { getUrl } from 'modules/routeUtils';

const vehicleName = (modelInfo?: ModelInfo | null) =>
  modelInfo
    ? `${modelInfo.make} ${modelInfo.model} ${modelInfo.subModel}`
    : '-';

type Props = {
  item: ProductionOverviewItem;
};

const translateRefurbishmentType = (
  refurbishmentType?: RefurbishmentType | null,
) => {
  if (refurbishmentType) {
    return refurbishmentTypesTranslated[refurbishmentType] || refurbishmentType;
  }

  return '';
};

const translateState = (state?: RefurbishmentState | null) => {
  if (state) {
    return statesTranslated[state] || state;
  }

  return '';
};

const ProductionItem = ({ item }: Props) => {
  const {
    id,
    adId,
    refId,
    stockNumber,
    retailStockCountry,
    modelInfo,
    refurbishmentType,
    state,
    lastStatusUpdatedOn,
    branchName,
    partnerName,
    vinNumber,
  } = item;

  return (
    <tr data-qa-selector={`productionRow_${refId}`}>
      <td data-qa-selector="productionCell_vinNumber">{vinNumber}</td>
      <td data-qa-selector="productionCell_stockNumber">
        <a
          href={`${process.env.REACT_APP_BO_URL}retail-ad/${adId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {stockNumber}
        </a>
      </td>
      <td data-qa-selector={`productionCell_refId_${id}`}>
        <Link
          to={getUrl('REFURBISHMENT_DETAIL', {
            id,
          })}
        >
          {refId}
        </Link>
      </td>
      <td data-qa-selector="productionCell_vehicleName">
        <a
          href={`${process.env.REACT_APP_ADMIN_CAR_URL}car/view/${stockNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {vehicleName(modelInfo)}
        </a>
      </td>
      <td data-qa-selector="productionCell_retailStockCountry">
        {retailStockCountry}
      </td>
      <td data-qa-selector="productionCell_refurbishmentType">
        {translateRefurbishmentType(refurbishmentType)}
      </td>
      <td data-qa-selector="productionCell_state">{translateState(state)}</td>
      <td data-qa-selector="productionCell_lastStatusUpdatedOn">
        {formatDateTime(lastStatusUpdatedOn)}
      </td>
      <td data-qa-selector="productionCell_branchName">{branchName}</td>
      <td data-qa-selector="productionCell_partnerName">{partnerName}</td>
    </tr>
  );
};

export default ProductionItem;
