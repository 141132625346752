import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import PageContainer from 'components/Layout/PageContainer';
import { useLogger } from 'modules/logger';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate();
  const { logError } = useLogger();

  useEffect(() => logError({ error }), [error]);

  const onRedirect = () => {
    resetErrorBoundary();
    navigate('/');
  };

  return (
    <PageContainer>
      <h1>{__('error.somethingWentWrong')}</h1>
      <div className="mb-4">{__('error.resolveText')}</div>
      <div className="mb-4">
        <Button onClick={resetErrorBoundary}>{__('error.tryAgain')}</Button>{' '}
        <Button onClick={onRedirect}>{__('error.goMainPage')}</Button>
      </div>
      <pre>{error.message}</pre>
    </PageContainer>
  );
};

export default ErrorFallback;
