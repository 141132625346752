import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import CAR_PARTS from './gql/carParts';

const useGetCarParts = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'carParts'>, null>(CAR_PARTS, {
    fetchPolicy: 'cache-first',
  });

  return {
    data: get('carParts', data),
    ...rest,
  };
};

export default useGetCarParts;
