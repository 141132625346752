import { getState, LogType } from './state';

type Path = {
  nodeName: string;
  data: string;
};

const visitParent = (element: Node, path: Path[] = []): Path[] => {
  const nodeName = element.nodeName.toLowerCase();
  if (nodeName === 'body' || !element.parentNode) return path;

  let data = '';
  if (element instanceof HTMLElement) {
    data = Object.entries(element.dataset)
      .map(([key, value]) => `${key}="${value}"`)
      .join('.');
    if (data) {
      data = `[${data}]`;
    }
  }

  path.push({
    nodeName,
    data,
  });

  return visitParent(element.parentNode, path);
};

const clickHanlder = (event: MouseEvent) => {
  if (event.target instanceof Element) {
    const path = visitParent(event.target);
    const log = path
      .map(({ nodeName, data }) => `${nodeName}${data}`)
      .join(' > ');

    getState().push({
      type: LogType.Click,
      description: log,
    });
  }
};

export const initUITracker = () => {
  document.addEventListener('click', clickHanlder);
};
