import gql from 'graphql-tag';

import { DamageItemPayloadV2FragmentQC } from './fragments';

export default gql`
  mutation createQualityCheckDamageItemV2(
    $damageItemCreateInput: DamageItemCreateInputV2!
  ) {
    createQualityCheckDamageItemV2(
      damageItemCreateInput: $damageItemCreateInput
    ) {
      id
      providerTotalCostsMinorUnits
      phaseName
      createdOn
      revisions {
        id
        propertyPath
        state
        createdBy {
          id
          firstName
        }
      }
      data {
        payloadType
        ... on DamageItemPayloadV2 {
          damageState: state
        }
        ...DamageItemPayloadV2FragmentQC
      }
    }
  }
  ${DamageItemPayloadV2FragmentQC}
`;
