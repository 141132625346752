export const statusOptions = [
  { label: __('monitoring.filter.allOption'), value: '' },
  { label: 'TO_SEND', value: 'TO_SEND' },
  { label: 'SENT', value: 'SENT' },
  { label: 'SENDING_ERROR', value: 'SENDING_ERROR' },
  {
    label: 'MAX_DELIVERY_ATTEMPTS_REACHED',
    value: 'MAX_DELIVERY_ATTEMPTS_REACHED',
  },
  { label: 'VPC_PROCESSING_ERROR', value: 'VPC_PROCESSING_ERROR' },
  { label: 'RETRIED', value: 'RETRIED' },
  { label: 'SUCCESS', value: 'SUCCESS' },
  { label: 'DRY_RUN', value: 'DRY_RUN' },
  { label: 'CANCELED', value: 'CANCELED' },
];

export const directionOptions = [
  { label: __('monitoring.filter.allOption'), value: '' },
  { label: 'INCOMING', value: 'INCOMING' },
  { label: 'OUTGOING', value: 'OUTGOING' },
];

export const reviewedOptions = [
  { label: __('monitoring.filter.allOption'), value: '' },
  { label: __('monitoring.filter.yesOption'), value: 'true' },
  { label: __('monitoring.filter.noOption'), value: 'false' },
];
