import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationCreatePartnerArgs,
  PartnerInput,
} from 'graphqlTypes';
import CREATE_PARTNER from './gql/createPartner';

const useCreatePartner = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createPartner'>,
    MutationCreatePartnerArgs
  >(CREATE_PARTNER);

  const createPartner = (partnerInput: PartnerInput) =>
    mutation({
      variables: { partnerInput },
    });

  return [
    createPartner,
    {
      data,
      ...rest,
    },
  ] as const;
};

export default useCreatePartner;
