import { useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  EntryCheckItemValueSourceType,
  RefurbishmentDetails,
} from 'graphqlTypes';
import { ServiceFormData, ServiceModal } from './index';

interface Props {
  id: string;
  refurbishment: RefurbishmentDetails;
  sourceType: EntryCheckItemValueSourceType;
  className?: string;
  hideMaintenanceService?: boolean;
  hideCost?: boolean;
  validateSubmit?(data: ServiceFormData): string | null;
  hasSplitCost?: boolean;
  hasSparePartsManagement: boolean;
}

const AddNewService = ({
  id,
  refurbishment,
  sourceType,
  className,
  hideMaintenanceService = false,
  hideCost,
  validateSubmit,
  hasSplitCost,
  hasSparePartsManagement,
}: Props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => setShow(true)}
        className={className}
        data-qa-selector="addService"
      >
        {__('entryCheck.addNewService')}
      </Button>
      <ServiceModal
        id={id}
        refurbishment={refurbishment}
        sourceType={sourceType}
        setShow={setShow}
        show={show}
        hideMaintenanceService={hideMaintenanceService}
        validateSubmit={validateSubmit}
        hideCost={hideCost}
        hasSplitCost={hasSplitCost}
        hasSparePartsManagement={hasSparePartsManagement}
      />
    </>
  );
};

export default AddNewService;
