import gql from 'graphql-tag';

import { PartnerWorkshopConnectionRefurbishmentResponseFragment } from 'modules/api/refurbishments/gql/fragments';

export const PartnerWorkshopConnectionFragment = gql`
  fragment PartnerWorkshopConnectionFragment on PartnerWorkshopConnection {
    id
    mainWorkshop {
      id
      name
      branchId
    }
    repairWorkshop {
      id
      name
      branchId
    }
    repairPartner {
      id
      name
      refurbishmentPartnerId
      type
    }
  }
`;

export const RefurbishmentDetailsPartnerWorkshopConnectionsFragment = gql`
  fragment RefurbishmentDetailsPartnerWorkshopConnectionsFragment on RefurbishmentDetails {
    partnerWorkshopConnections {
      ...PartnerWorkshopConnectionRefurbishmentResponseFragment
    }
  }
  ${PartnerWorkshopConnectionRefurbishmentResponseFragment}
`;
