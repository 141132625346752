import gql from 'graphql-tag';

export default gql`
  query brakeTypes {
    brakeTypes {
      id
      brakeType
    }
  }
`;
