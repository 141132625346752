import gql from 'graphql-tag';

export default gql`
  query roles {
    roles {
      id
      name
      description
    }
  }
`;
