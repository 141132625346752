import gql from 'graphql-tag';

export const RefurbishmentItemPayloadFragment = gql`
  fragment RefurbishmentItemPayloadFragment on RefurbishmentItemPayload {
    providerTotalCostMinorUnits
    totalCostBudgetIncludingInternalLaborMinorUnits
    wheelSetsSwitched
    wheelSetSwitchVerificationStatus
    wheelSetDeleted
    wheelSetDeletedReason
    serviceBookType
    serviceBookPresent
  }
`;

export const DocumentFragment = gql`
  fragment DocumentFragment on Document {
    id
    attachedTo
    createdOn
    state
    sourceType
    url
    fileName
    description
    type
    createdBy {
      id
      firstName
      lastName
      refurbishmentProvidersData {
        location {
          id
          name
        }
        partner {
          id
          name
        }
      }
    }
  }
`;

export const QualityCheckVehicleItemPayloadFragment = gql`
  fragment QualityCheckVehicleItemPayloadFragment on VehicleItemPayload {
    mileage {
      distance
      unit
    }
    inspectionMileage {
      distance
      unit
    }
    maintenanceMileage {
      distance
      unit
    }
    serviceCompany
    serviceBookType
    serviceDueDate
    serviceDueMileage {
      distance
      unit
    }
    isServiceRequired
    inspectionDate
    inspectionDueDate
    maintenanceDate
    wheelSets {
      isPrimary
      wheels {
        position
        season
        seasonLabel
        brakeDamageId
        brakeCondition
        profile {
          depth
          unit
        }
        rimType
        rimSize {
          value
          unit
        }
        wheelSize {
          value
          height
          unit
        }
        loadIndex
        speedIndex
        manufacturer
        dot
        imageUrl
        tireDataUpToDate
      }
    }
    inspectionNeeded
    doneBeforeEntryCheck
    carPassInspection
    lastInspectionDate
    lastInspectionMileage {
      distance
      unit
    }
    lastInspectionDueDate
  }
`;

export const ServiceItemPayloadFragment = gql`
  fragment ServiceItemPayloadFragment on ServiceItemPayload {
    serviceName
    serviceType
    budgetMinorUnits
    sparePartCostMinorUnits
    consumableCostMinorUnits
    laborRateMinorUnits
    laborTimeHours
    serviceComment
    # state
    stateComment
    relatedServiceId
    partnerWorkshopConnectionId
    isAutoCreate
    laborRateMinorUnits
    laborTimeHours
    consumableCostMinorUnits
    sparePartCostMinorUnits
  }
`;

export const DamageItemPayloadFragment = gql`
  fragment DamageItemPayloadFragment on DamageItemPayload {
    area
    subArea
    partName
    typeName
    isSecondaryWheel
    photoId
    imageUrl
    customerDisplay
    # state
    stateComment
    serviceName
    serviceType
    budgetMinorUnits
    serviceComment
  }
`;

export const DamageServiceFragment = gql`
  fragment DamageServiceFragment on DamageService {
    serviceName
    serviceType
    budgetMinorUnits
    sparePartCostMinorUnits
    consumableCostMinorUnits
    laborRateMinorUnits
    laborTimeHours
    serviceComment
    state
    stateComment
    partnerWorkshopConnectionId
  }
`;

export const DamageItemPayloadV2FragmentQC = gql`
  fragment DamageItemPayloadV2FragmentQC on DamageItemPayloadV2 {
    area
    subArea
    partName
    typeName
    isSecondaryWheel
    photoId
    imageUrl
    customerDisplay
    # state
    stateComment
    currentService {
      ...DamageServiceFragment
    }
    newService {
      ...DamageServiceFragment
    }
    photoUpdatedOn
  }
  ${DamageServiceFragment}
`;

export const ItemFragmentQC = gql`
  fragment ItemFragmentQC on Item {
    id
    phaseName
    data {
      payloadType
      ... on ServiceItemPayload {
        serviceState: state
      }
      ... on DamageItemPayload {
        damageState: state
      }
      ... on DamageItemPayloadV2 {
        damageState: state
      }
      ...QualityCheckVehicleItemPayloadFragment
      ...ServiceItemPayloadFragment
      ...DamageItemPayloadFragment
      ...DamageItemPayloadV2FragmentQC
      ...RefurbishmentItemPayloadFragment
    }
    revisions {
      id
      propertyPath
      state
      createdBy {
        id
        firstName
      }
    }
    createdOn
  }
  ${QualityCheckVehicleItemPayloadFragment}
  ${ServiceItemPayloadFragment}
  ${DamageItemPayloadFragment}
  ${DamageItemPayloadV2FragmentQC}
  ${RefurbishmentItemPayloadFragment}
`;

export const ItemExtendedFragmentQC = gql`
  fragment ItemExtendedFragmentQC on ItemExtended {
    id
    attachedTo
    providerTotalCostsMinorUnits
    phaseName
    createdOn
    revisions {
      id
      propertyPath
      state
      createdBy {
        id
        firstName
      }
    }
    data {
      payloadType
      ...ServiceItemPayloadFragment
      ... on ServiceItemPayload {
        serviceState: state
      }
    }
  }
  ${ServiceItemPayloadFragment}
`;
