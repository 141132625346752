import { Button } from 'react-bootstrap';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

import FormButtonModal from 'components/Form/FormButtonModal';
import { ImportTaskState } from 'graphqlTypes';
import { ImportRepairCostPhase } from './ImportRepairCostModal';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairCostPhase>>;
  currentImportState?: ImportTaskState;
};

const ImportRepairCostImportResult = ({
  currentImportState,
  closeModal,
  setActivePhase,
}: Props) => (
  <>
    <h3 className="text-center">
      {currentImportState === ImportTaskState.ImportFinished
        ? __('repairCost.importSuccessful')
        : __('repairCost.importFailed')}
    </h3>
    <div className="d-flex justify-content-center mt-4">
      {currentImportState === ImportTaskState.ImportFinished ? (
        <DoneIcon
          color="success"
          fontSize="large"
          data-qa-selector="DoneIcon"
        />
      ) : (
        <ErrorIcon
          color="error"
          fontSize="large"
          data-qa-selector="ErrorIcon"
        />
      )}
    </div>

    <FormButtonModal>
      <Button
        variant="secondary"
        onClick={() => {
          setActivePhase(ImportRepairCostPhase.UPLOADING);
          closeModal();
        }}
        data-qa-selector="closeImportRepairCostModal"
      >
        {__('action.close')}
      </Button>
    </FormButtonModal>
  </>
);

export default ImportRepairCostImportResult;
