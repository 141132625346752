import { EntryCheckItem, RefurbishmentDetails } from 'graphqlTypes';
import DamageEntryCheckAuth from './DamageEntryCheckAuth';
import ServiceEntryCheckAuth from './ServiceEntryCheckAuth';
import ServiceWorkEntryCheckAuth from './ServiceWorkEntryCheckAuth';
import {
  PartnerWorkshopConnectionRefurbishment,
  SliceResult,
  WheelSetsAuth,
} from '../types';

export enum StepName {
  ServiceBook = 'SERVICE_BOOK',
  DetailsWheels = 'DETAILS_WHEELS',
  // RefurbishmentDefaults = 'REFURBISHMENT_DEFAULTS',
  Damages = 'DAMAGES',
  VehicleServices = 'VEHICLE_SERVICES',
  CustomerSummary = 'CUSTOMER_SUMMARY',
  Summary = 'SUMMARY',
  Engine = 'ENGINE',
  Underbody = 'UNDERBODY',
}

export type EntryCheckAuthMap = {
  id: string;
  refurbishment: RefurbishmentDetails;
  items: EntryCheckItem[];
  itemsSliced: SliceResult;
  wheels: WheelSetsAuth<DamageEntryCheckAuth>;
  services: ServiceEntryCheckAuth[];
  damages: DamageEntryCheckAuth[];
  serviceWork: ServiceWorkEntryCheckAuth | null;
  partnerWorkshopConnectionRefurbishments?: PartnerWorkshopConnectionRefurbishment[];
  disabled: boolean;
  hasWriteRole: boolean;
  providerTotalCost: number;
  hasPhotosService: boolean;
  pricingBudgetCosts: PricingBudgetInput[] | null;
  hasPA3?: boolean;
  hasSplitCost?: boolean;
  isImperfectionRepairMethods: boolean;
  hasSparePartsManagement: boolean;
  pricingBudgetCostMinorUnits: number;
  targetGrossProfitMinorUnits: number;
  minimalGrossProfitMinorUnits: number;
  actualGrossProfitMinorUnits: number;
};

export type PricingBudgetInput = {
  label: string;
  cost: number | null | undefined;
};
