import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from './fragments';

export default gql`
  mutation createPartnerWorkshopConnections(
    $input: PartnerWorkshopConnectionCreateInput!
  ) {
    createPartnerWorkshopConnections(input: $input) {
      partnerWorkshopConnections {
        ...PartnerWorkshopConnectionFragment
      }
    }
  }
  ${PartnerWorkshopConnectionFragment}
`;
