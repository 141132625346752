import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';

import {
  ItemType,
  PhaseName,
  Query,
  QueryQualityCheckReviewArgs,
} from 'graphqlTypes';
import QUALITY_CHECK_REVIEW from '../gql/qualityCheckReview';

export const updateProviderTotalCost = (
  qualityCheckId: string,
  providerTotalCostMinorUnits: number,
  cache: DataProxy,
) => {
  const qualityReview = cache.readQuery<
    Pick<Query, 'qualityCheckReview'>,
    QueryQualityCheckReviewArgs
  >({ query: QUALITY_CHECK_REVIEW, variables: { id: qualityCheckId } });

  const itemId = qualityReview?.qualityCheckReview.items
    ?.find(itemGroup => itemGroup.itemType === ItemType.Refurbishment)
    ?.values?.find(item => item.phaseName === PhaseName.QualityCheckReview)?.id;

  if (!itemId) return;

  cache.writeFragment({
    id: `Item:${itemId}`,
    fragment: gql`
      fragment item on Item {
        data {
          providerTotalCostMinorUnits
          __typename
        }
        __typename
      }
    `,
    data: {
      data: {
        providerTotalCostMinorUnits,
        __typename: 'RefurbishmentItemPayload',
      },
      __typename: 'Item',
    },
  });
};
