import { InformMessageFilterInput } from 'graphqlTypes';
import { FilterNames, FormType } from './types';

const formatDate = (date?: Date | string | null) => {
  if (date instanceof Date) return date.toISOString();
  if (typeof date === 'string' && !isNaN(new Date(date).getTime())) return date;
  return null;
};

export const formValuesToCleanValues = (
  values: FormType,
): Partial<FormType> => {
  const cleanValues = Object.fromEntries(
    Object.entries(values).filter(([, value]) =>
      Array.isArray(value) ? value.length : !!value,
    ),
  );

  return cleanValues;
};

export const cleanValuesToQueryStringValues = (
  cleanValues: Partial<FormType>,
) => {
  const createdDate = [
    formatDate(cleanValues.createdDate?.[0]),
    formatDate(cleanValues.createdDate?.[1]),
  ];

  return {
    ...(cleanValues.vinOrStockNumber
      ? {
          [FilterNames.VIN_OR_STOCK_NUMBER]: cleanValues.vinOrStockNumber,
        }
      : {}),
    ...(cleanValues.refId
      ? {
          [FilterNames.REF_ID]: cleanValues.refId,
        }
      : {}),
    ...(cleanValues.serviceCode
      ? { [FilterNames.SERVICE_CODE]: cleanValues.serviceCode }
      : {}),
    ...(cleanValues.messageType
      ? { [FilterNames.MESSAGE_TYPE]: cleanValues.messageType }
      : {}),
    ...(cleanValues.createdDate
      ? { [FilterNames.CREATED_DATE]: createdDate }
      : {}),
    ...(cleanValues.status ? { [FilterNames.STATUS]: cleanValues.status } : {}),
    ...(cleanValues.tenant ? { [FilterNames.TENANT]: cleanValues.tenant } : {}),
    ...(cleanValues.errorCode
      ? { [FilterNames.ERROR_CODE]: cleanValues.errorCode }
      : {}),
    ...(cleanValues.errorText
      ? { [FilterNames.ERROR_TEXT]: cleanValues.errorText }
      : {}),
    ...(cleanValues.direction
      ? { [FilterNames.DIRECTION]: cleanValues.direction }
      : {}),
    ...(cleanValues.reviewed
      ? { [FilterNames.REVIEWED]: cleanValues.reviewed }
      : {}),
  };
};

export const searchParamsToFormValues = (
  searchParams: Record<FilterNames, any>,
): FormType => {
  return {
    vinOrStockNumber: searchParams[FilterNames.VIN_OR_STOCK_NUMBER] || null,
    refId: searchParams[FilterNames.REF_ID] || null,
    serviceCode: searchParams[FilterNames.SERVICE_CODE] || null,
    messageType: searchParams[FilterNames.MESSAGE_TYPE] || null,
    createdDate: [
      searchParams[FilterNames.CREATED_DATE]?.[0] || null,
      searchParams[FilterNames.CREATED_DATE]?.[1] || null,
    ],
    status: searchParams[FilterNames.STATUS] || '',
    tenant: searchParams[FilterNames.TENANT] || null,
    errorCode: searchParams[FilterNames.ERROR_CODE] || null,
    errorText: searchParams[FilterNames.ERROR_TEXT] || null,
    direction: searchParams[FilterNames.DIRECTION] || '',
    reviewed: searchParams[FilterNames.REVIEWED] || '',
  };
};

export const cleanValuesToInformMessageFilterInput = (
  cleanValues: Partial<FormType>,
): InformMessageFilterInput => {
  const { createdDate, ...partialValues } = cleanValues;

  const createdAtFrom = formatDate(createdDate?.[0]);
  const createdAtTo = formatDate(createdDate?.[1]);

  const informMessageFilterInput = {
    ...partialValues,
    ...(createdAtFrom ? { createdAtFrom } : {}),
    ...(createdAtTo ? { createdAtTo } : {}),
  };

  return informMessageFilterInput;
};
