import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationAssignUsersArgs } from 'graphqlTypes';
import ASSIGN_USERS from './gql/assignUsers';
import GROUPS_BY_ID from './gql/groupsById';

const useAssignUsers = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'assignUsers'>,
    MutationAssignUsersArgs
  >(ASSIGN_USERS);

  const assignUsers = (variables: MutationAssignUsersArgs) =>
    mutation({
      variables,
      refetchQueries: [
        {
          query: GROUPS_BY_ID,
          variables: {
            id: variables.groupId,
          },
        },
      ],
    });

  return [assignUsers, options] as const;
};

export default useAssignUsers;
