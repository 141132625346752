import { get } from 'lodash/fp';

import {
  Query,
  QueryDownloadRepairCostImportTaskValidationResultArgs,
  Scalars,
} from 'graphqlTypes';
import QUERY from './gql/downloadRepairCostImportTaskValidationResult';
import usePromiseQuery from '../helpers/usePromiseQuery';

const usePromiseDownloadRepairCostImportTaskValidationResult = () => {
  const query = usePromiseQuery<
    Query['downloadRepairCostImportTaskValidationResult'],
    QueryDownloadRepairCostImportTaskValidationResultArgs
  >(QUERY, {
    fetchPolicy: 'network-only',
    context: { disableBatch: true },
  });

  const getData = async (id: Scalars['UUID']) => {
    const { data } = await query({
      id,
    });

    return get(['downloadRepairCostImportTaskValidationResult'], data);
  };

  return getData;
};

export default usePromiseDownloadRepairCostImportTaskValidationResult;
