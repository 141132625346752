import React from 'react';

import DataItem from 'components/UI/DataItem';
import { CurrencyCode } from 'graphqlTypes';
import { formatPrice } from 'modules/currency';

type Props = {
  id?: string | null;
  service?: string | null;
  costMinorUnits?: number | null;
  comment?: string | null;
  currencyCode: CurrencyCode;
  children: React.ReactNode;
};

export const Service = ({
  id,
  service,
  costMinorUnits,
  comment,
  currencyCode,
  children,
}: Props) => {
  const hasCost = Number.isFinite(costMinorUnits);

  return (
    <div
      className="border rounded py-3 px-4"
      data-qa-selector={`service-container${id ? `-${id}` : ''}`}
    >
      <div className="d-flex justify-content-between">
        <DataItem
          label={__('refurbishment.serviceItem.service')}
          qaSelector="service-name"
        >
          {service}
        </DataItem>
        <DataItem
          label={__('refurbishment.serviceItem.cost')}
          qaSelector="service-cost"
        >
          {hasCost ? formatPrice(costMinorUnits, currencyCode) : '-'}
        </DataItem>
        <div>{children}</div>
      </div>
      <div className="mt-4">
        <DataItem
          label={__('refurbishment.serviceItem.comment')}
          qaSelector="service-comment"
        >
          {comment ? <span className="fs-80">{comment}</span> : '-'}
        </DataItem>
      </div>
    </div>
  );
};
