import gql from 'graphql-tag';

export default gql`
  fragment userFields on User {
    id
    email
    firstName
    lastName
    country
    locale
    active
    groups {
      id
    }
    refurbishmentProvidersData {
      location {
        id
        name
      }
      partner {
        id
        name
      }
    }
    userPartnerWorkshopConnections {
      mainWorkshop {
        id
        name
        branchId
      }
      isAllLocations
      partnerWorkshopConnections {
        id
        repairWorkshop {
          id
          name
          branchId
        }
        repairPartner {
          id
          name
          refurbishmentPartnerId
        }
      }
    }
  }
`;
