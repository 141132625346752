import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import {
  EntryCheckItemValueUpdateEntryInput,
  Mutation,
  MutationUpdateEntryCheckItemValuesArgs,
} from 'graphqlTypes';
import PROVIDER_TOTAL_COSTS from './gql/providerTotalCosts';
import PROVIDER_TOTAL_SPLIT_COSTS from './gql/providerTotalSplitCosts';
import UPDATE_ENTRY_CHECK_ITEM_VALUES from './gql/updateEntryCheckItemValuesForCostCalculation';

const useUpdateEntryCheckItemValuesForCostCalculation = (
  entryCheckId?: string,
  hasSplitCost?: boolean,
) => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateEntryCheckItemValuesForCostCalculation'>,
    MutationUpdateEntryCheckItemValuesArgs
  >(UPDATE_ENTRY_CHECK_ITEM_VALUES);

  const updateEntryCheckItemValue = (
    inputs: EntryCheckItemValueUpdateEntryInput[],
  ) =>
    mutation({
      variables: { inputs },
      refetchQueries: entryCheckId
        ? [
            {
              query: hasSplitCost
                ? PROVIDER_TOTAL_SPLIT_COSTS
                : PROVIDER_TOTAL_COSTS,
              variables: {
                entryCheckId,
              },
            },
          ]
        : undefined,
    });

  return [
    updateEntryCheckItemValue,
    {
      data: get('updateEntryCheckItemValue', data),
      ...rest,
    },
  ] as const;
};

export default useUpdateEntryCheckItemValuesForCostCalculation;
