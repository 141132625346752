import { get } from 'lodash/fp';

import {
  AwsImageUploadCredentialsInput,
  Query,
  QueryBuildAdImageUploadCredentialsArgs,
} from 'graphqlTypes';
import BUILD_AD_IMAGE_UPLOAD_CREDENTIALS from './gql/buildAdImageUploadCredentials';
import usePromiseQuery from '../helpers/usePromiseQuery';

const usePromiseBuildAdImageUploadCredentials = () => {
  const query = usePromiseQuery<
    Query['buildAdImageUploadCredentials'],
    QueryBuildAdImageUploadCredentialsArgs
  >(BUILD_AD_IMAGE_UPLOAD_CREDENTIALS, {
    fetchPolicy: 'network-only',
    context: { disableBatch: true },
  });

  const getData = async ({
    uploadImagesInfos,
  }: AwsImageUploadCredentialsInput) => {
    const { data } = await query({
      awsImageUploadCredentialsInput: {
        uploadImagesInfos,
      },
    });

    return get(
      ['buildAdImageUploadCredentials', 'awsImageUploadCredentials'],
      data,
    );
  };

  return getData;
};

export default usePromiseBuildAdImageUploadCredentials;
