import React from 'react';

import YesNoChecker from 'components/ServiceWork/YesNoChecker';
import { EntryCheckItemValueState } from 'graphqlTypes';
import { useUpdateEntryCheckItemValues } from 'modules/api/entryCheck';
import { useLogger } from 'modules/logger';
import { BrakeCheckpointsYesNoGraphQlJsonPayload } from 'routes/RefurbishmentRoute/helpers/mapData/getCheckpoints';
import { EntryCheckItem } from 'routes/RefurbishmentRoute/types';

type Props = {
  entryCheckItem?: EntryCheckItem;
  label: string;
  required?: boolean;
  disabled: boolean;
  qaSelector: string;
  onDecline?: Function;
};

const BrakeYesNoItem = ({
  entryCheckItem,
  label,
  required,
  disabled,
  qaSelector,
  onDecline,
}: Props) => {
  const { logError } = useLogger();
  if (!entryCheckItem) return null;

  const [
    updateEntryCheckItemValue,
    { loading },
  ] = useUpdateEntryCheckItemValues();

  const handleUpdate = async (value: boolean) => {
    const { id } = entryCheckItem.values[0];

    try {
      await updateEntryCheckItemValue([
        {
          id,
          value: {
            state: EntryCheckItemValueState.Confirmed,
            proposedValue: {
              ...entryCheckItem.values[0].originalValue,
              value,
            },
          },
        },
      ]);

      if (value === false && onDecline) onDecline();
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <YesNoChecker
      label={label}
      required={required}
      value={
        (entryCheckItem.values[0]
          .proposedValue as BrakeCheckpointsYesNoGraphQlJsonPayload)?.value ??
        null
      }
      editDisabled={disabled}
      qaSelector={qaSelector}
      onAcceptSelect={() => handleUpdate(true)}
      onDeclineSelect={() => handleUpdate(false)}
      loading={loading}
    />
  );
};

export default BrakeYesNoItem;
