import { useQuery } from '@apollo/react-hooks';

import { Query, QueryVehicleServiceCostSearchV2Args } from 'graphqlTypes';
import VEHICLE_SERVICE_COST_SEARCH from './gql/vehicleServiceCostSearchV2';

const useVehicleServiceCostSearch = (
  variables: QueryVehicleServiceCostSearchV2Args,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'vehicleServiceCostSearchV2'>,
    QueryVehicleServiceCostSearchV2Args
  >(VEHICLE_SERVICE_COST_SEARCH, {
    variables,
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.vehicleServiceCostSearchV2,
    ...rest,
  };
};

export default useVehicleServiceCostSearch;
