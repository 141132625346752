import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationUpdateRefurbishmentStateArgs } from 'graphqlTypes';
import UPDATE_REFURBISHMENT_STATE from './gql/updateRefurbishmentState';

const useUpdateRefurbishmentState = () => {
  return useMutation<
    Pick<Mutation, 'updateRefurbishmentState'>,
    MutationUpdateRefurbishmentStateArgs
  >(UPDATE_REFURBISHMENT_STATE);
};

export default useUpdateRefurbishmentState;
