import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Mutation, MutationCreateExitCheckServiceArgs } from 'graphqlTypes';
import CREATE_EXIT_CHECK_SERVICE from 'modules/api/exitCheck/gql/createServiceExitCheck';
import EXIT_CHECK_DETAILS from 'modules/api/exitCheck/gql/exitCheck';

const useCreateExitCheckService = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createExitCheckService'>,
    MutationCreateExitCheckServiceArgs
  >(CREATE_EXIT_CHECK_SERVICE);

  const createExitCheckService = (input: MutationCreateExitCheckServiceArgs) =>
    mutation({
      variables: input,
      refetchQueries: [
        { query: EXIT_CHECK_DETAILS, variables: { id: input.exitCheckId } },
      ],
    });

  return [
    createExitCheckService,
    {
      data: get('createExitCheckService', data),
      ...rest,
    },
  ] as const;
};

export default useCreateExitCheckService;
