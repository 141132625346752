import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { ReactSelectInputProps } from 'components/Form/ReactSelectInput/ReactSelectInput';
import { statesTranslated } from 'constants/state';
import createOptions from 'helpers/createOptions';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const stateOptions = createOptions(statesTranslated);

type Props = Omit<
  ReactSelectInputProps,
  'name' | 'dataQa' | 'dataQaOption' | 'dataQaMultiValueContainer' | 'options'
> & {
  options?: typeof stateOptions;
  placeholder?: string;
  transformOptions?(options: typeof stateOptions): typeof stateOptions;
  transformSelectedOptions?(
    newValue: string[],
    previousValue: string[],
  ): string[] | undefined;
  hideSelectedOptionsInput?: boolean;
  className?: string;
};

const StateSelect = ({
  options,
  placeholder = __('refurbishment.task.list.status'),
  transformOptions,
  transformSelectedOptions = (o: string[]) => o,
  hideSelectedOptionsInput,
  className,
  ...rest
}: Props) => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error("'StateSelect' must be used inside a 'Filter' component.");
  }
  const { control, watch } = formMethods;

  const isOptionSelected = !!watch(FormFilterNames.STATE)?.length;

  if (options && transformOptions) {
    throw new Error(
      "If 'options' prop is set, 'transformOptions' prop will be ignored.",
    );
  }

  const filteredOptions = transformOptions
    ? transformOptions(stateOptions)
    : stateOptions;

  return (
    <div
      className={cns(cn.col, cn.input, className)}
      data-qa-selector="refurbishment_states"
    >
      <ReactSelectInput
        {...rest}
        name={FormFilterNames.STATE}
        control={control}
        options={options || filteredOptions}
        transformSelectedOptions={transformSelectedOptions}
        placeholder={placeholder}
        isMulti
        dataQa="filter_state"
        dataQaOption="filter_state_option"
        dataQaMultiValueContainer="filter_state_option_selected"
        components={
          isOptionSelected && hideSelectedOptionsInput
            ? {
                ValueContainer: () => (
                  <div className={cn.selectPlaceholder}>
                    {__('refurbishment.task.list.statusSelected')}
                  </div>
                ),
              }
            : {}
        }
        styles={
          hideSelectedOptionsInput
            ? {
                multiValue: (styles: any) => ({
                  ...styles,
                  display: 'none',
                }),
              }
            : {}
        }
      />
    </div>
  );
};

export default StateSelect;
