import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import cns from 'classnames';

import cn from './PageSidebarContainer.module.scss';

interface Props {
  headbar: React.ReactNode;
  sidebar: React.ReactNode;
  children: React.ReactNode;
  footbar: React.ReactNode;
}

const PageSidebarContainer = ({
  headbar,
  sidebar,
  footbar,
  children,
}: Props) => {
  return (
    <Container className={cn.pageSidebarContainer}>
      <div className={cn.header}>{headbar || null}</div>
      <Row>
        <Col lg={3} className={cns(cn.sticky, cn.sidebarContainer)}>
          <div className={cns(cn.sidebar, 'white-bg')}>{sidebar}</div>
        </Col>
        <Col lg={9} className={cn.contentContainer}>
          <div className="px-4 pb-3 pt-3 d-flex-1 white-bg flex-grow-1">
            {children}
          </div>
          {footbar || null}
        </Col>
      </Row>
    </Container>
  );
};

export default PageSidebarContainer;
