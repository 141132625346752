import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import PageContainer from 'components/Layout/PageContainer/PageContainer';
import Title from 'components/UI/Title';
import { sendReq } from 'modules/apiRest';
import { getUrl } from 'modules/routeUtils';

interface FormData {
  email: string;
}

const ResetPasswordRoute = () => {
  const { register, handleSubmit } = useForm<FormData>();
  const navigate = useNavigate();

  const onSubmit = async ({ email }: FormData) => {
    try {
      await sendReq('REQUEST_NEW_PASSWORD', email);
      window.alert(__('password.reset.message'));
      navigate(getUrl('LOGIN'));
    } catch (error) {
      let message = __('password.reset.failedMessage');
      if (error.statusCode === 409 && error.result) {
        if (
          error.result.globalErrors &&
          error.result.globalErrors.filter((globalError: string) =>
            globalError.endsWith(
              'password reset attempts can be performed per day.',
            ),
          ).length > 0
        ) {
          message = __('password.reset.tooManyAttempts');
        } else if (error.result.fieldErrors) {
          const emailErrors = error.result.fieldErrors
            .filter(
              (fieldError: { field: string }) => fieldError.field === 'email',
            )
            .map((fieldError: { message: string }) => fieldError.message);
          if (emailErrors) {
            message = emailErrors.join(', ');
          }
        }
      }
      // eslint-disable-next-line no-alert
      window.alert(message);
    }
  };

  return (
    <PageContainer>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div className="text-center mt-3">
            <Title level="h1">{__('login.forgotPassword')}</Title>
            <div className="mt-4">{__('login.forgotPasswordDescription')}</div>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <Form.Group controlId="formNewPassword">
              <Form.Label>{__('login.emailAddress')}</Form.Label>
              <Form.Control type="email" {...register('email')} />
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                {__('action.send')}
              </Button>
            </div>
          </Form>

          <div className="text-center mt-5">
            <Link to="login" data-qa-selector="backToLogin">
              {__('action.backToLogin')}
            </Link>
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default ResetPasswordRoute;
