import React, { useContext } from 'react';

import DeveloperModeContext from 'modules/developerMode/context';

type Props = {
  children: React.ReactNode;
};

const DeveloperModeWrapper = ({ children }: Props) => {
  const { developerMode } = useContext(DeveloperModeContext);

  return <>{developerMode ? children : null}</>;
};

export default DeveloperModeWrapper;
