import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationUpdateRepairCostArgs } from 'graphqlTypes';
import UPDATE_REPAIR_COST from './gql/updateRepairCost';

const useUpdateRepairCost = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateRepairCost'>,
    MutationUpdateRepairCostArgs
  >(UPDATE_REPAIR_COST);

  const updateRepairCost = (variables: MutationUpdateRepairCostArgs) =>
    mutation({ variables });

  return [
    updateRepairCost,
    {
      data: data?.updateRepairCost,
      ...rest,
    },
  ] as const;
};

export default useUpdateRepairCost;
