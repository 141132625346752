import React from 'react';
import { Collapse } from 'react-bootstrap';

import arrowDown from 'images/arrow-down.svg';
import arrowUp from 'images/arrow-up.svg';
import { SectionProgress } from 'routes/RefurbishmentRoute/types';

import cn from './CollapsableCheckSection.module.css';

type Props = {
  name: string;
  progress?: SectionProgress;
  children: React.ReactNode;
};

const CollapsableCheckSection = ({ name, progress, children }: Props) => {
  const collapseId = `collapse.${name}`;
  const [open, setOpen] = React.useState(true);
  const toggleImg = open ? arrowUp : arrowDown;
  const toggleName = open
    ? __('entryCheck.step.hide')
    : __('entryCheck.step.show');

  const headerText = () => {
    if (progress?.total) {
      return `${name} (${progress.completed}/${progress.total})`;
    }

    return name;
  };

  return (
    <div>
      <div className="row">
        <div className="col-9">
          <h4 data-qa-selector="collapsableCheckSection.header.text">
            {headerText()}
          </h4>
        </div>
        <div className={[cn.toggleBtnContainer, 'col-3'].join(' ')}>
          <button
            onClick={() => setOpen(!open)}
            className="btn btn-link"
            type="button"
            aria-expanded={open}
            aria-controls={collapseId}
            data-qa-selector="collapsableCheckSection.header.button"
          >
            {toggleName}{' '}
            <img src={toggleImg} className={cn.toggleImage} alt={toggleName} />
          </button>
        </div>
      </div>
      <Collapse in={open}>
        <div id={collapseId}>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsableCheckSection;
