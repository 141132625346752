import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { ReactSelectInputProps } from 'components/Form/ReactSelectInput/ReactSelectInput';
import { refurbishmentTypesTranslated } from 'constants/refurbishmentType';
import createOptions from 'helpers/createOptions';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const refurbishmentTypeOptions = createOptions(refurbishmentTypesTranslated);

type Props = Omit<
  ReactSelectInputProps,
  'name' | 'dataQa' | 'dataQaOption' | 'dataQaMultiValueContainer' | 'options'
> & {
  hideSelectedOptionsInput?: boolean;
  className?: string;
};

const RefurbishmentTypeSelect = ({
  className,
  hideSelectedOptionsInput,
  ...rest
}: Props) => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'RefurbishmentTypeSelect' must be used inside a 'Filter' component.",
    );
  }
  const { control, watch } = formMethods;

  const isOptionSelected = !!watch(FormFilterNames.REFURBISHMENT_TYPE)?.length;

  return (
    <div
      className={cns(cn.col, cn.input, className)}
      data-qa-selector="refurbishment_types"
    >
      <ReactSelectInput
        {...rest}
        name={FormFilterNames.REFURBISHMENT_TYPE}
        control={control}
        options={refurbishmentTypeOptions}
        placeholder={__('refurbishment.task.list.refurbishmentType')}
        isMulti
        dataQa="filter_refurbishment_type"
        dataQaOption="filter_refurbishment_type_option"
        dataQaMultiValueContainer="filter_refurbishment_type_option_selected"
        components={
          isOptionSelected && hideSelectedOptionsInput
            ? {
                ValueContainer: () => (
                  <div className={cn.selectPlaceholder}>
                    {__('refurbishment.task.list.refurbishmentTypeSelected')}
                  </div>
                ),
              }
            : {}
        }
        styles={
          hideSelectedOptionsInput
            ? {
                multiValue: (styles: any) => ({
                  ...styles,
                  display: 'none',
                }),
              }
            : {}
        }
      />
    </div>
  );
};

export default RefurbishmentTypeSelect;
