import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationCreateVehicleServiceCostArgs,
  VehicleServiceCostInput,
} from 'graphqlTypes';
import CREATE_VEHICLE_SERVICE_COST from './gql/createVehicleServiceCost';

const useCreateVehicleServiceCost = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createVehicleServiceCost'>,
    MutationCreateVehicleServiceCostArgs
  >(CREATE_VEHICLE_SERVICE_COST);

  const createVehicleServiceCost = (
    vehicleServiceCostInput: VehicleServiceCostInput,
  ) => mutation({ variables: { vehicleServiceCostInput } });

  return [
    createVehicleServiceCost,
    {
      data: data?.createVehicleServiceCost,
      ...rest,
    },
  ] as const;
};

export default useCreateVehicleServiceCost;
