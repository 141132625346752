import { Button as BootstrapButton } from 'react-bootstrap';

type Props = {
  className?: string;
};

const Button = ({ className }: Props) => {
  return (
    <BootstrapButton
      className={className}
      variant="primary"
      type="submit"
      data-qa-selector="filter_button"
    >
      {__('action.filter')}
    </BootstrapButton>
  );
};

export default Button;
