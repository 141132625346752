import { flow, isFinite, isUndefined, toNumber } from 'lodash/fp';

import { formatCurrency } from 'modules/i18n';

const isCalculable = flow(toNumber, isFinite);

const DEFAULT_CONVERSION_MAJOR = 100;

type UnitConversionOptions = {
  convertNullToNaN: boolean;
};

const defaultUnitConversionOptions: UnitConversionOptions = {
  convertNullToNaN: false, // By default, null is converted to 0
};

export const unitsFromMinorToMajor = (
  value?: number | null,
  options: UnitConversionOptions = defaultUnitConversionOptions,
) => {
  if (options.convertNullToNaN && value === null) return NaN;

  return !isUndefined(value) && isCalculable(value)
    ? toNumber(value) / DEFAULT_CONVERSION_MAJOR
    : NaN;
};

export const unitsFromMajorToMinor = (
  value?: number | null,
  options: UnitConversionOptions = defaultUnitConversionOptions,
) => {
  if (options.convertNullToNaN && value === null) return NaN;

  return !isUndefined(value) && isCalculable(value)
    ? Math.round(toNumber(value) * DEFAULT_CONVERSION_MAJOR)
    : NaN;
};

export const unitsFromMinorToMajorOrNull = (value?: number | null) => {
  const result = unitsFromMinorToMajor(value, { convertNullToNaN: true });
  if (Number.isNaN(result)) return null;
  return result;
};

export const unitsFromMajorToMinorOrNull = (value?: number | null) => {
  const result = unitsFromMajorToMinor(value, { convertNullToNaN: true });
  if (Number.isNaN(result)) return null;
  return result;
};

export const formatPriceCurrency = (currencyCode: string) => (
  price?: number | null,
) => {
  if (price !== null && isCalculable(price)) {
    return formatCurrency(unitsFromMinorToMajor(price), currencyCode);
  }

  return formatCurrency(0, currencyCode);
};

export const formatPrice = (price: number | null = 0, currencyCode: string) => {
  if (price !== null && isCalculable(price)) {
    return formatCurrency(unitsFromMinorToMajor(price), currencyCode);
  }
  return formatCurrency(0, currencyCode);
};
