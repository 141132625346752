import { useQuery } from '@apollo/react-hooks';

import {
  Query,
  QueryVehicleRepairMethodsArgs,
  VehicleModelVersion,
} from 'graphqlTypes';
import VEHICLE_REPAIR_METHODS from './gql/vehicleRepairMethods';

const useVehicleRepairMethods = (vehicleModelVersion: VehicleModelVersion) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'vehicleRepairMethods'>,
    QueryVehicleRepairMethodsArgs
  >(VEHICLE_REPAIR_METHODS, {
    variables: {
      vehicleModelVersion,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    data: data?.vehicleRepairMethods,
    ...rest,
  };
};

export default useVehicleRepairMethods;
