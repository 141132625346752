import { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { noop } from 'lodash/fp';

import { dateLocale } from 'modules/i18n';
import CalendarSvg from './img/CalendarSvg';

import cn from './DatePickerInput.module.scss';

export type DatePickerInputProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render' | 'onChange' | 'valueName' | 'customInput'
> &
  Omit<ReactDatePickerProps, 'name' | 'onChange' | 'locale'> & {
    disabled?: Boolean;
    dataQaSelector?: string;
    onSelect?: Function;
    errors: any;
  };

const DatePickerInput = <T extends FieldValues>({
  name,
  control,
  errors,
  disabled,
  dataQaSelector,
  onSelect = noop,
  rules,
  ...rest
}: DatePickerInputProps<T>) => {
  const calendar = useRef<any>();

  return (
    <>
      <div className={cn.datePicker}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { value, onChange, ...field } }) => (
            <ReactDatePicker
              {...field}
              {...rest}
              onChange={onChange}
              selected={value}
              onSelect={onSelect}
              ref={calendar}
              autoComplete="off"
              popperClassName={cn.datePickerPopper}
              dateFormat="dd/MM/yyyy"
              locale={dateLocale}
              disabled={disabled}
              customInput={
                <Form.Control
                  data-qa-selector={dataQaSelector}
                  isInvalid={errors && !!errors[name]}
                  disabled={disabled}
                />
              }
            />
          )}
        />
        <Button
          className={cn.button}
          disabled={disabled}
          onClick={() => calendar.current.setOpen(true)}
        >
          <CalendarSvg />
        </Button>
      </div>
      {!!errors && !!errors[name] && errors[name].message && (
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {errors[name].message}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default DatePickerInput;
