import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import CustomModal from 'components/UI/CustomModal';
import Text from 'components/UI/Text';
import {
  PartnerWorkshopConnection,
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import { useLogger } from 'modules/logger';

type Props = {
  userPartnerWorkshopConnections: UserPartnerWorkshopConnection[];
  userPartnerWorkshopConnectionItem: UserPartnerWorkshopConnection;
  onChange(values: UserPartnerWorkshopConnectionInput): Promise<any>;
};

const DeleteProductionLocation = ({
  userPartnerWorkshopConnections,
  userPartnerWorkshopConnectionItem,
  onChange,
}: Props) => {
  const { logError } = useLogger();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isAllPartners = userPartnerWorkshopConnectionItem.isAllLocations;

  const handleDelete = async () => {
    const userPartnerWorkshopConnectionIds = userPartnerWorkshopConnectionItem.partnerWorkshopConnections?.map(
      connection => connection?.id,
    );

    const partnerWorkshopConnectionIds = userPartnerWorkshopConnections
      .filter(group => !group.isAllLocations)
      .flatMap(group =>
        group.partnerWorkshopConnections?.map(connection => connection?.id),
      )
      .filter((id): id is string => !!id)
      .filter(
        id => isAllPartners || !userPartnerWorkshopConnectionIds?.includes(id),
      );

    const userMainWorkshopIds = userPartnerWorkshopConnections
      .filter(group => group.isAllLocations)
      .map(group => group.mainWorkshop.id)
      .filter(
        id =>
          !isAllPartners ||
          userPartnerWorkshopConnectionItem.mainWorkshop.id !== id,
      );

    try {
      await onChange({
        partnerWorkshopConnectionIds,
        userMainWorkshopIds,
      });
      setShow(false);
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <>
      <Button variant="link" data-qa-selector="delete" onClick={handleShow}>
        {__('action.delete')}
      </Button>

      <CustomModal
        size="lg"
        show={show}
        onHide={handleClose}
        qaSelector="deleteProductionLocationModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{__('user.action.delete.connections')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userPartnerWorkshopConnections.length === 1 ? (
            <div>{__('user.productionLocation.AtLeastOne')}</div>
          ) : (
            <>
              <Text className="mb-4" size="l" weight="bold" tag="p">
                {__('user.delete.connections')}
              </Text>

              <Table size="sm">
                <thead>
                  <tr>
                    <th> {__('user.main.workshop')}</th>
                    <th>{__('user.repairWorkshop.partnerLocations')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {userPartnerWorkshopConnectionItem.mainWorkshop.name}
                    </td>
                    <td>
                      <ul className="pl-3">
                        {isAllPartners ? (
                          <li key="allPartners">
                            {__('user.productionLocation.allPartners')}
                          </li>
                        ) : (
                          userPartnerWorkshopConnectionItem.partnerWorkshopConnections
                            ?.filter(
                              (
                                connection,
                              ): connection is PartnerWorkshopConnection =>
                                !!connection,
                            )
                            .map(connection => {
                              const name = `${connection.repairWorkshop.name} (${connection.repairPartner.name})`;
                              return <li key={name}>{name}</li>;
                            })
                        )}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>
            {__('action.cancel')}
          </Button>
          {userPartnerWorkshopConnections.length > 1 && (
            <Button
              variant="secondary"
              onClick={handleDelete}
              className="btn-wide ml-4"
              data-qa-selector="deleteConfirm"
            >
              {__('action.confirm')}
            </Button>
          )}
        </Modal.Footer>
      </CustomModal>
    </>
  );
};

export default DeleteProductionLocation;
