import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryDamageTypesArgs } from 'graphqlTypes';
import DAMAGE_TYPES from './gql/damageTypes';

const useGetDamageTypesLazy = (carPartId?: number) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'damageTypes'>,
    QueryDamageTypesArgs
  >(DAMAGE_TYPES, {
    variables: {
      carPartId,
    },
    skip: !carPartId,
    fetchPolicy: 'cache-first',
  });

  return {
    data: get('damageTypes', data),
    ...rest,
  };
};

export default useGetDamageTypesLazy;
