import React from 'react';
import { Helmet } from 'react-helmet';
import cns from 'classnames';

import Filter from 'components/Filter';
import cn from 'components/Filter/Filter.module.scss';
import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import TableInfoPagination from 'components/TableInfoPagination';
import TableInfo from 'components/TableInfoPagination/TableInfo';
import ProductionList from './components/ProductionList';
import useGetProductionOverview from './hooks/useGetProductionOverview';

const ProductionRoute = () => {
  const { data, loading, setFilterValues } = useGetProductionOverview();

  const pageInfo = data?.pageInfo;

  return (
    <PageContainer fluid>
      <Helmet>
        <title>{__('production.title')}</title>
      </Helmet>
      <PageHeader
        title={__('production.title')}
        className="justify-content-start align-items-baseline text-primary"
        actions={
          <TableInfo
            results={pageInfo?.totalEntityCount || ''}
            className="ml-3 text-body"
          />
        }
      />

      <Filter onChange={setFilterValues} className={cn.row}>
        <Filter.RefurbishmentTypeSelect />
        <Filter.StateSelect />
        <Filter.LocationSelect />
        <Filter.RefVinStockInput />
        <Filter.Button className={cns(cn.col, cn.button)} />
      </Filter>

      {!!pageInfo && (
        <TableInfoPagination
          totalPageCount={pageInfo.totalPageCount}
          page={pageInfo.page}
        />
      )}

      <ProductionList data={data} loading={loading} />

      {!!pageInfo && (
        <TableInfoPagination
          totalPageCount={pageInfo.totalPageCount}
          page={pageInfo.page}
        />
      )}
    </PageContainer>
  );
};

export default ProductionRoute;
