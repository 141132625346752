import gql from 'graphql-tag';

import {
  RefurbishmentTaskItemIncludingCostFragment,
  TaskRefurbishmentFragment,
  TaskVehicleFragment,
} from './fragments';

export default gql`
  query refurbishmentTaskItemsIncludingCosts($taskId: UUID!) {
    refurbishmentTaskItemsIncludingCosts(taskId: $taskId) {
      vehicle {
        ...TaskVehicleFragment
      }
      refurbishment {
        ...TaskRefurbishmentFragment
      }
      items {
        ...RefurbishmentTaskItemIncludingCostFragment
      }
      currentPartnerWorkshopConnection {
        id
      }
    }
  }
  ${TaskVehicleFragment}
  ${TaskRefurbishmentFragment}
  ${RefurbishmentTaskItemIncludingCostFragment}
`;
