import gql from 'graphql-tag';

import { EntryCheckItemValueFragment } from './fragments';

export default gql`
  mutation createEntryCheckItemValues(
    $inputs: [EntryCheckItemValueCreateInput!]!
  ) {
    createEntryCheckItemValues(inputs: $inputs) {
      ...EntryCheckItemValueFragment
    }
  }
  ${EntryCheckItemValueFragment}
`;
