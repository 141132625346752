import React from 'react';

import ImageViewer from 'components/ImageViewer';
import DataItem from 'components/UI/DataItem';
import { CurrencyCode } from 'graphqlTypes';
import { ImageSize } from 'helpers/images';
import useAdImage from 'modules/adImage/useAdImage';
import { formatPrice } from 'modules/currency';

import cn from './Damage.module.scss';

type Props = {
  id?: string | null;
  imageUrl?: string | null;
  carPart?: string | null;
  damage?: string | null;
  service?: string | null;
  costMinorUnits?: number | null;
  comment?: string | null;
  currencyCode: CurrencyCode;
  children: React.ReactNode;
};

export const Damage = ({
  id,
  imageUrl,
  carPart,
  damage,
  service,
  costMinorUnits,
  comment,
  currencyCode,
  children,
}: Props) => {
  const { getImageUrl } = useAdImage();

  const hasCost = Number.isFinite(costMinorUnits);

  return (
    <div
      className={cn.grid}
      data-qa-selector={`damage-container${id ? `-${id}` : ''}`}
    >
      <div className={cn.image}>
        <ImageViewer url={getImageUrl(imageUrl, ImageSize.ORIGINAL)}>
          <img
            src={getImageUrl(imageUrl, ImageSize.SMALL)}
            alt={__('refurbishment.detail.damage')}
            data-qa-selector="damage-image"
            className="w-100 pr-3"
          />
        </ImageViewer>
      </div>
      <div className={cn.carPart}>
        <DataItem
          label={__('refurbishment.damageItem.carPart')}
          qaSelector="damage-car-part"
        >
          {carPart}
        </DataItem>
      </div>
      <div className={cn.damage}>
        <DataItem
          label={__('refurbishment.damageItem.damageType')}
          qaSelector="damage-type"
        >
          {damage}
        </DataItem>
      </div>
      <div className={cn.service}>
        <DataItem
          label={__('refurbishment.damageItem.service')}
          qaSelector="damage-service-name"
        >
          {service}
        </DataItem>
      </div>
      <div className={cn.cost}>
        <DataItem
          label={__('refurbishment.damageItem.cost')}
          qaSelector="damage-cost"
        >
          {hasCost ? formatPrice(costMinorUnits, currencyCode) : '-'}
        </DataItem>
      </div>
      <div className={cn.comment}>
        <DataItem
          label={__('refurbishment.damageItem.comment')}
          qaSelector="damage-comment"
        >
          {comment ? <span className="fs-80">{comment}</span> : '-'}
        </DataItem>
      </div>
      <div className={cn.actions}>
        <div>{children}</div>
      </div>
    </div>
  );
};
