import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import ExitCheckRedirectRoute from './ExitCheckDetailsRoute/ExitCheckRedirectRoute';
import { Role, RoleAccess } from '../../modules/acl';

const ExitCheckListRoute = lazy(() => import('./ExitCheckListRoute'));
const ExitCheckDetailsRoute = lazy(() => import('./ExitCheckDetailsRoute'));
const ExitCheckRepairsSummaryRoute = lazy(() =>
  import('./ExitCheckDetailsRoute/ExitCheckRepairsSummaryRoute'),
);
const ExitCheckOverviewRoute = lazy(() =>
  import('./ExitCheckOverviewRoute/ExitCheckOverviewRoute'),
);

const ExitCheckRoute = () => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Routes>
        <Route
          path="entries" // done
          element={
            <RoleAccess roles={Role.EXIT_CHECK_VIEW}>
              <ExitCheckListRoute />
            </RoleAccess>
          }
        />
        <Route
          path="details/:id/*"
          element={
            <RoleAccess roles={Role.EXIT_CHECK_VIEW}>
              <ExitCheckRedirectRoute>
                <ExitCheckDetailsRoute />
              </ExitCheckRedirectRoute>
            </RoleAccess>
          }
        />
        <Route
          path="repairs-summary/:id" // done
          element={
            <RoleAccess roles={Role.EXIT_CHECK_VIEW}>
              <ExitCheckRedirectRoute>
                <ExitCheckRepairsSummaryRoute />
              </ExitCheckRedirectRoute>
            </RoleAccess>
          }
        />
        <Route
          path="overview/:id" // done
          element={
            <RoleAccess roles={Role.EXIT_CHECK_READ_ONLY}>
              <ExitCheckOverviewRoute />
            </RoleAccess>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default ExitCheckRoute;
