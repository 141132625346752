import { LOCALE } from 'modules/i18n';
import { createRoute, setupUrls } from 'modules/routeUtils';
import { CarPosition } from 'routes/ExitCheckRoute/types';

const createRouteWithLanguage = (path: string) =>
  createRoute(`/:lang${path}`, { lang: LOCALE });

setupUrls({
  HOME: createRouteWithLanguage('/'),
  LOGIN: createRouteWithLanguage('/login'),
  LOGOUT: createRouteWithLanguage('/logout'),
  REFURBISHMENT: createRouteWithLanguage('/refurbishment'),
  REFURBISHMENT_DETAIL: createRouteWithLanguage('/refurbishment/:id'),
  REFURBISHMENT_ENTRY_CHECK: createRouteWithLanguage(
    '/refurbishment/:id/entry-check',
  ),
  REFURBISHMENT_TASK: createRouteWithLanguage('/refurbishment-task'),
  REFURBISHMENT_TASK_DETAIL: createRouteWithLanguage('/refurbishment-task/:id'),
  EXIT_CHECK_ROOT: createRouteWithLanguage('/exit-check'),
  EXIT_CHECK_LIST: createRouteWithLanguage('/exit-check/entries'),
  EXIT_CHECK_SELECTED: createRouteWithLanguage(
    '/exit-check/entries?v=:stockNumber',
  ),
  EXIT_CHECK_DETAILS: createRouteWithLanguage(
    '/exit-check/details/:id/:section',
  ),
  EXIT_CHECK_OVERVIEW: createRouteWithLanguage('/exit-check/overview/:id'),
  EXIT_CHECK_VEHICLE: createRouteWithLanguage(
    '/exit-check/details/:id/vehicle',
  ),
  EXIT_CHECK_VEHICLE_INSPECTION: createRouteWithLanguage(
    '/exit-check/details/:id/vehicle-inspection',
  ),
  EXIT_CHECK_FRONT: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.FRONT}`,
  ),
  EXIT_CHECK_RIGHT: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.RIGHT}`,
  ),
  EXIT_CHECK_BACK: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.BACK}`,
  ),
  EXIT_CHECK_LEFT: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.LEFT}`,
  ),
  EXIT_CHECK_ROOF: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.ROOF}`,
  ),
  EXIT_CHECK_INTERIOR: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.INTERIOR}`,
  ),
  EXIT_CHECK_UNDERBODY: createRouteWithLanguage(
    `/exit-check/details/:id/${CarPosition.UNDERBODY}`,
  ),
  EXIT_CHECK_WHEELS: createRouteWithLanguage('/exit-check/details/:id/wheels'),
  EXIT_CHECK_SERVICES: createRouteWithLanguage(
    '/exit-check/details/:id/vehicle-services',
  ),
  EXIT_CHECK_SERVICEBOOK: createRouteWithLanguage(
    '/exit-check/details/:id/service-work',
  ),
  EXIT_CHECK_TOTAL_COST: createRouteWithLanguage(
    '/exit-check/details/:id/total-cost',
  ),
  EXIT_CHECK_ADD_DAMAGE: createRouteWithLanguage(
    '/exit-check/details/:id/damage/add/:carPartFilter/:section',
  ),
  EXIT_CHECK_EDIT_DAMAGE: createRouteWithLanguage(
    '/exit-check/details/:id/damage/edit/:damageId/:carPartFilter/:section',
  ),
  EXIT_CHECK_ADD_SERVICE: createRouteWithLanguage(
    '/exit-check/details/:id/service/add',
  ),
  EXIT_CHECK_EDIT_SERVICE: createRouteWithLanguage(
    '/exit-check/details/:id/service/edit/:serviceId',
  ),
  EXIT_CHECK_REPAIRS_SUMMARY: createRouteWithLanguage(
    '/exit-check/repairs-summary/:id',
  ),
  REFURBISHMENT_ENTRY_CHECK_AUTHORIZATION: createRouteWithLanguage(
    '/refurbishment/:id/entry-check-authorization',
  ),
  REFURBISHMENT_ENTRY_CHECK_POST_AUTHORIZATION: createRouteWithLanguage(
    '/refurbishment/:id/entry-check-post-authorization',
  ),
  QUALITY_CHECK: createRouteWithLanguage(
    '/refurbishment/:id/quality-check/:reviewId',
  ),
  QUALITY_CHECK_OPEN: createRouteWithLanguage(
    '/refurbishment/:id/quality-check',
  ),
  REWORK_AUTHORIZATION: createRouteWithLanguage(
    '/refurbishment/:id/rework-authorization/:reviewId',
  ),
  USER: createRouteWithLanguage('/user'),
  USER_NEW: createRouteWithLanguage('/user/new'),
  USER_DETAIL: createRouteWithLanguage('/user/:id'),
  USER_DUPLICATE: createRouteWithLanguage('/user/:id/duplicate'),
  RESET_PASSWORD: createRouteWithLanguage('/reset-password'),
  CREATE_PASSWORD: createRouteWithLanguage('/create-password/:hash'),
  GROUP: createRouteWithLanguage('/group'),
  GROUP_NEW: createRouteWithLanguage('/group/new'),
  GROUP_DETAIL: createRouteWithLanguage('/group/:id'),
  ACCESS_DENIED: createRouteWithLanguage('/access-denied'),
  PARTNER_WORKSHOP_CONNECTIONS: createRouteWithLanguage(
    '/partner-workshop-connections',
  ),
  DISABLED_USER: createRouteWithLanguage('/disabled-user'),
  COST_TABLE: createRouteWithLanguage('/cost-table'),
  REPAIR_METHOD: createRouteWithLanguage('/repair-method'),
  REPAIR_COST: createRouteWithLanguage('/repair-cost'),
  PRODUCTION: createRouteWithLanguage('/production'),
  PARTNERS: createRouteWithLanguage('/partners'),
  MONITORING: createRouteWithLanguage('/monitoring'),
});
