import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import GET_INFORM_MESSAGE_TYPES from './gql/getInformMessageTypes';

const useGetInformMessageTypes = () => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'getInformMessageTypes'>,
    null
  >(GET_INFORM_MESSAGE_TYPES);

  return {
    data: data?.getInformMessageTypes,
    ...rest,
  };
};

export default useGetInformMessageTypes;
