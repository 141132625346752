import React from 'react';
import { Navigate } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import { ExitCheckState } from 'graphqlTypes';
import { useExitCheck } from 'modules/api/exitCheck/useExitCheck';
import { getUrl } from 'modules/routeUtils';

type Props = {
  children: React.ReactNode;
};

const ExitCheckRedirectRoute = ({ children }: Props) => {
  const { data, loading } = useExitCheck();
  const state = data?.exitCheck.details.state.key;

  if (loading) return <LoadingIndicator />;

  return state &&
    [ExitCheckState.Completed, ExitCheckState.Cancelled].includes(state) ? (
    <Navigate
      to={getUrl('EXIT_CHECK_OVERVIEW', {
        id: data?.exitCheck.details.id,
      })}
    />
  ) : (
    <>{children}</>
  );
};

export default ExitCheckRedirectRoute;
