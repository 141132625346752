import React from 'react';

import DataItem from 'components/UI/DataItem';
import { CurrencyCode, RefurbishmentTaskItemIncludingCost } from 'graphqlTypes';
import { formatPrice } from 'modules/currency';
import { ServiceWorkSubService } from './ServiceWorkSubService';
import TaskItemChecker from './TaskItemChecker';

type Props = {
  costMinorUnits?: number | null;
  comment?: string | null;
  subServices?: RefurbishmentTaskItemIncludingCost[];
  currencyCode: CurrencyCode;
  disabled: boolean;
  children: React.ReactNode;
  onLastItemSubmitted: () => void;
};

export const ServiceWork = ({
  costMinorUnits,
  subServices,
  comment,
  currencyCode,
  disabled,
  children,
  onLastItemSubmitted,
}: Props) => {
  const hasCost = Number.isFinite(costMinorUnits);

  return (
    <div className="border rounded" data-qa-selector="service-work-container">
      <div className="d-flex justify-content-between border-bottom py-3 px-4">
        <DataItem
          label={__('refurbishment.serviceItem.service')}
          qaSelector="service-work-name"
        >
          {__('refurbishment.serviceWork.title')}
        </DataItem>
        <DataItem
          label={__('refurbishment.serviceItem.cost')}
          qaSelector="service-work-cost"
        >
          {hasCost ? formatPrice(costMinorUnits, currencyCode) : '-'}
        </DataItem>
        <div>{children}</div>
      </div>
      <div className="border-bottom py-3 px-4">
        <DataItem
          label={__('refurbishment.serviceWork.subServicesLabel')}
          qaSelector="service-work-subservices"
        >
          <div className="w-100">
            {subServices?.map(subService => (
              <div key={subService.id} className="mb-2">
                <ServiceWorkSubService subService={subService}>
                  <TaskItemChecker
                    taskItemId={subService.id}
                    state={subService.state}
                    disabled={disabled}
                    onLastItemSubmitted={onLastItemSubmitted}
                  />
                </ServiceWorkSubService>
              </div>
            ))}
          </div>
        </DataItem>
      </div>
      <div className="py-3 px-4">
        <DataItem
          label={__('refurbishment.serviceItem.comment')}
          qaSelector="service-work-comment"
        >
          {comment ? <span className="fs-80">{comment}</span> : '-'}
        </DataItem>
      </div>
    </div>
  );
};
