import { Control, FieldValues, Path } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { useGetAllLocations } from 'modules/api/locations';

type Props<T extends FieldValues> = {
  name: Path<T>;
  form: string;
  control: Control<T, object>;
  qaSelector: string;
};

const MainWorkshopSelectControl = <T extends FieldValues>({
  name,
  form,
  control,
  qaSelector,
}: Props<T>) => {
  const { data, loading } = useGetAllLocations();

  const options =
    data
      ?.map(item => ({
        label: item.name,
        value: {
          id: item.id,
          name: item.name,
          branchId: item.id,
          countryCode: item.country,
        },
      }))
      .filter(item => {
        return item.value.countryCode;
      })
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <ReactSelectInput
      name={name}
      form={form}
      options={options}
      isLoading={loading}
      control={control}
      rules={{
        required: __('field.required'),
      }}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
    />
  );
};

export default MainWorkshopSelectControl;
