import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryDamageTypesArgs } from 'graphqlTypes';
import DAMAGE_TYPES from './gql/damageTypes';

const useGetDamageTypesLazy = () => {
  const [query, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'damageTypes'>,
    QueryDamageTypesArgs
  >(DAMAGE_TYPES, {
    fetchPolicy: 'cache-first',
  });

  const getDamageTypes = (carPartId: number) => {
    query({
      variables: {
        carPartId,
      },
    });
  };

  return [
    getDamageTypes,
    {
      data: get('damageTypes', data),
      ...rest,
    },
  ] as const;
};

export default useGetDamageTypesLazy;
