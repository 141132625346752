import gql from 'graphql-tag';

import { RepairCostImportTaskBasicFragment } from './fragments';

export default gql`
  query searchRepairMethodImportTasks($page: Int!, $pageSize: Int!) {
    searchRepairMethodImportTasks(page: $page, pageSize: $pageSize) {
      items {
        ...RepairCostImportTaskBasicFragment
      }
      pageInfo {
        page
        pageSize
        totalPageCount
        totalEntityCount
      }
    }
  }

  ${RepairCostImportTaskBasicFragment}
`;
