import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import ALL_LOCATIONS from './gql/allLocations';

const useGetAllLocations = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'allLocations'>, null>(
    ALL_LOCATIONS,
  );

  return {
    data: data?.allLocations?.locations,
    ...rest,
  };
};

export default useGetAllLocations;
