import { pick } from 'lodash/fp';

import {
  EntryCheckEntityType,
  EntryCheckItemCreateInput,
  EntryCheckItemValueSourceType,
} from 'graphqlTypes';
import { getEffectiveCustomerDisplay } from 'helpers/imperfections';
import useCreateEntryCheckItems from 'modules/api/entryCheck/useCreateEntryCheckItems';
import useCreateEntryCheckItemsForAuthorization from 'modules/api/entryCheck/useCreateEntryCheckItemsForAuthorization';
import useCreateEntryCheckItemsForPostAuthorization from 'modules/api/entryCheck/useCreateEntryCheckItemsForPostAuthorization';
import calculateSparePart from '../helpers/Damage/calculateSparePart';
import {
  DamageInput,
  DamageInputPA3,
  PayloadType,
  PayloadTypeDamage,
} from '../types';

type Params = {
  entryCheckId: string;
  refurbishmentId: string;
  sourceType: EntryCheckItemValueSourceType;
  isPA3?: boolean;
  hasSparePartsManagement: boolean;
};

const useCreateDamage = ({
  entryCheckId,
  refurbishmentId,
  sourceType,
  isPA3,
  hasSparePartsManagement,
}: Params) => {
  const [createEntryCheckItem] = useCreateEntryCheckItems();
  const [
    createEntryCheckItemForAuthorization,
  ] = useCreateEntryCheckItemsForAuthorization();
  const [
    createEntryCheckItemForPostAuthorization,
  ] = useCreateEntryCheckItemsForPostAuthorization();

  const createDamage = (damage: DamageInput | DamageInputPA3) => {
    const makeEntryCheckItem = (
      payloadType: PayloadTypeDamage,
      values: any,
    ) => ({
      entryCheckId,
      entityType: EntryCheckEntityType.Damage,
      proposedValue: {
        payloadType,
        ...values,
      },
    });
    const items: EntryCheckItemCreateInput[] = [
      isPA3
        ? makeEntryCheckItem(
            PayloadType.DamageType,
            pick(
              [
                'area',
                'subArea',
                'typeName',
                'partName',
                'attribute1Name',
                'attribute1Value',
                'attribute2Name',
                'attribute2Value',
                'quantity',
                'isSecondaryWheel',
                'displaySection',
                'isPA3Damage',
              ],
              damage,
            ),
          )
        : makeEntryCheckItem(
            PayloadType.DamageType,

            pick(
              [
                'area',
                'subArea',
                'typeName',
                'partName',
                'isSecondaryWheel',
                'displaySection',
              ],
              damage,
            ),
          ),
      makeEntryCheckItem(PayloadType.DamageImage, pick('imageUrl', damage)),
      makeEntryCheckItem(
        PayloadType.DamageServiceName,
        pick(['name', 'type'], damage),
      ),
      makeEntryCheckItem(
        PayloadType.ServiceBudget,
        pick(
          [
            'budgetMinorUnits',
            'sparePartCostMinorUnits',
            'consumableCostMinorUnits',
            'laborTimeHours',
            'laborRateMinorUnits',
          ],
          damage,
        ),
      ),
      makeEntryCheckItem(PayloadType.ServiceComment, pick('comment', damage)),
      makeEntryCheckItem(PayloadType.PartnerWorkshopConnection, {
        id: damage.partnerWorkshopConnectionId,
      }),
      ...(hasSparePartsManagement
        ? [
            makeEntryCheckItem(PayloadType.EcCommon, {
              sparePart: calculateSparePart({
                newSparePartCost: damage.sparePartCostMinorUnits,
                serviceComment: damage.comment,
              }),
            }),
          ]
        : []),
    ];

    if (sourceType === EntryCheckItemValueSourceType.Authorization) {
      const itemsAuthorization: EntryCheckItemCreateInput[] = [
        ...items,
        makeEntryCheckItem(PayloadType.DamageCustomerDisplay, {
          customerDisplay: getEffectiveCustomerDisplay(damage.name, false),
        }),
      ];
      return createEntryCheckItemForAuthorization(
        itemsAuthorization,
        refurbishmentId,
      );
    }

    if (sourceType === EntryCheckItemValueSourceType.PostAuthorization) {
      const itemsAuthorization: EntryCheckItemCreateInput[] = [
        ...items,
        makeEntryCheckItem(PayloadType.DamageCustomerDisplay, {
          customerDisplay: getEffectiveCustomerDisplay(damage.name, false),
        }),
      ];
      return createEntryCheckItemForPostAuthorization(
        itemsAuthorization,
        refurbishmentId,
      );
    }

    return createEntryCheckItem(items, refurbishmentId);
  };

  return [createDamage] as const;
};

export default useCreateDamage;
