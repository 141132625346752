import Divider from 'components/Divider';
import {
  CurrencyCode,
  RefurbishmentTaskItemIncludingCost,
  RefurbishmentTaskItemType,
} from 'graphqlTypes';
import StepContainer from 'routes/RefurbishmentRoute/components/StepContainer';
import StepHeader from 'routes/RefurbishmentRoute/components/StepHeader';
import { Damage } from './Damage';
import { PartnerGroup } from './PartnerGroup';
import { Service } from './Service';
import TaskItemChecker from './TaskItemChecker';
import { WorkshopGroup } from './WorkshopGroup';

type Props = {
  section: string;
  items: RefurbishmentTaskItemIncludingCost[];
  currencyCode: CurrencyCode;
  disabled: boolean;
  onLastItemSubmitted: () => void;
};

export const TaskItemList = ({
  section,
  items,
  currencyCode,
  disabled,
  onLastItemSubmitted,
}: Props) => {
  const itemsGroupedByPartner = items.reduce(
    (acc: Record<string, RefurbishmentTaskItemIncludingCost[]>, cur) => {
      acc[cur.partnerWorkshopConnection.repairPartner.id] = [
        ...(acc[cur.partnerWorkshopConnection.repairPartner.id] || []),
        cur,
      ];
      return acc;
    },
    {},
  );

  const partnerEntries = Object.entries(itemsGroupedByPartner);

  return (
    <StepContainer>
      <StepHeader
        header={section}
        subHeader={
          items.length > 0
            ? __('refurbishment.task.item.confirmTasks')
            : __('refurbishment.task.item.noTasksAvailable')
        }
      />
      {partnerEntries.map(([partnerId, partnerItems]) => {
        const itemsGroupedByWorkshop = partnerItems.reduce(
          (acc: Record<string, RefurbishmentTaskItemIncludingCost[]>, cur) => {
            acc[cur.partnerWorkshopConnection.repairWorkshop.id] = [
              ...(acc[cur.partnerWorkshopConnection.repairWorkshop.id] || []),
              cur,
            ];
            return acc;
          },
          {},
        );

        const workshopEntries = Object.entries(itemsGroupedByWorkshop);

        return (
          <div key={partnerId}>
            <Divider className="my-3" />
            <PartnerGroup
              partner={partnerItems[0].partnerWorkshopConnection.repairPartner}
              count={partnerItems.length}
            >
              {workshopEntries.map(([workshopId, workshopItems]) => (
                <WorkshopGroup
                  workshop={
                    workshopItems[0].partnerWorkshopConnection.repairWorkshop
                  }
                  key={workshopId}
                >
                  {workshopItems.map(
                    (item: RefurbishmentTaskItemIncludingCost) => (
                      <div className="mb-4" key={item.id}>
                        {(item.itemType === RefurbishmentTaskItemType.Damage ||
                          item.itemType ===
                            RefurbishmentTaskItemType.DamagePa3) && (
                          <Damage
                            carPart={item.damage?.part.translation}
                            comment={item.service?.comment}
                            costMinorUnits={item.service?.budgetMinorUnits}
                            damage={item.damage?.type.translation}
                            imageUrl={item.damage?.imageUrl}
                            service={item.service?.serviceData.name.translation}
                            currencyCode={currencyCode}
                          >
                            <TaskItemChecker
                              taskItemId={item.id}
                              state={item.state}
                              disabled={disabled}
                              onLastItemSubmitted={onLastItemSubmitted}
                            />
                          </Damage>
                        )}
                        {item.itemType ===
                          RefurbishmentTaskItemType.Service && (
                          <Service
                            id={item.id}
                            comment={item.service?.comment}
                            costMinorUnits={item.service?.budgetMinorUnits}
                            service={item.service?.serviceData.name.translation}
                            currencyCode={currencyCode}
                          >
                            <TaskItemChecker
                              taskItemId={item.id}
                              state={item.state}
                              disabled={disabled}
                              onLastItemSubmitted={onLastItemSubmitted}
                            />
                          </Service>
                        )}
                      </div>
                    ),
                  )}
                </WorkshopGroup>
              ))}
            </PartnerGroup>
          </div>
        );
      })}
    </StepContainer>
  );
};
