import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FieldError from 'components/Form/FieldError';
import FormButtonsModal from 'components/Form/FormButtonModal';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import LocationSelectControl from 'components/LocationSelectControl';
import {
  PartnerWorkshopConnection,
  PartnerWorkshopConnectionRefurbishmentResponse,
  UserPartnerWorkshopConnection,
  WorkshopCreateInput,
} from 'graphqlTypes';
import { useActiveGroupedPartnerWorkshopConnections } from 'modules/api/partnerWorkshop';

export const ALL_LOCATIONS_VALUE = 'allLocations';

export type ProductionLocationFormData = {
  mainWorkshop?: WorkshopCreateInput & { id: string };
  partnerLocations: string[];
};

type InnerProductionLocationFormData = {
  mainWorkshop: WorkshopCreateInput;
  partnerLocations: string[];
};

type Props = {
  defaultValues?: UserPartnerWorkshopConnection;
  currentConnections?: PartnerWorkshopConnectionRefurbishmentResponse[] | null;
  loading: boolean;
  onSubmit(data: ProductionLocationFormData): void;
  onCancel(): void;
};

const ProductionLocationForm = ({
  defaultValues,
  currentConnections = [],
  loading,
  onSubmit,
  onCancel,
}: Props) => {
  const mainWorkshop = defaultValues?.mainWorkshop && {
    name: defaultValues.mainWorkshop.name,
    branchId: defaultValues.mainWorkshop.branchId,
  };
  const partnerLocations = defaultValues?.isAllLocations
    ? [ALL_LOCATIONS_VALUE]
    : (defaultValues?.partnerWorkshopConnections &&
        defaultValues.partnerWorkshopConnections
          .filter(
            (connection): connection is PartnerWorkshopConnection =>
              !!connection,
          )
          .map(connection => connection.id)) ||
      undefined;
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<InnerProductionLocationFormData>({
    defaultValues: {
      mainWorkshop,
      partnerLocations,
    },
  });

  const selectedWorkshop = watch().mainWorkshop?.name;
  const currentConnectionIds = currentConnections?.map(item => item.id) || [];

  const {
    data,
    loading: loadingConnections,
  } = useActiveGroupedPartnerWorkshopConnections(
    {
      page: 0,
      pageSize: 999999,
      ...(selectedWorkshop
        ? {
            filter: {
              mainWorkshopName: selectedWorkshop,
            },
          }
        : null),
    },
    { skip: !selectedWorkshop },
  );

  const selectedPartners = watch().partnerLocations;
  const allPartnersSelected = !!selectedPartners?.includes(ALL_LOCATIONS_VALUE);

  const workshopPartners =
    data?.items?.[0].groupedPartnerWorkshopConnections
      .flat()
      .filter(item => !currentConnectionIds.includes(item.id))
      .map(item => ({
        label: `${item.repairWorkshop.name} - ${item.repairPartner.name}`,
        value: item.id,
      })) || [];

  const workshopPartnerOptions = [];

  if (workshopPartners.length > 0) {
    workshopPartnerOptions.push({
      label: __('user.productionLocation.allPartners'),
      value: ALL_LOCATIONS_VALUE,
    });
  }

  if (!allPartnersSelected) {
    workshopPartners.forEach(option => workshopPartnerOptions.push(option));
  }

  const handleOnSubmit = (values: InnerProductionLocationFormData) => {
    const mainWorkshopId =
      data?.items?.[0].groupedPartnerWorkshopConnections?.[0]?.[0].mainWorkshop
        .id;

    if (!mainWorkshopId) {
      throw new Error("Main workshop id doesn't exist");
    }

    onSubmit({
      partnerLocations: allPartnersSelected
        ? [ALL_LOCATIONS_VALUE]
        : values.partnerLocations,
      mainWorkshop: allPartnersSelected
        ? { ...values.mainWorkshop, id: mainWorkshopId }
        : undefined,
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(handleOnSubmit)}
      data-qa-selector="productionLocationForm"
    >
      <Form.Group className="mb-3" controlId="mainWorkshop">
        <Form.Label>{__('user.main.workshop')}</Form.Label>
        <LocationSelectControl
          name="mainWorkshop"
          control={control}
          qaSelector="mainWorkshop"
        />
        <FieldError name="mainWorkshop" errors={errors} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="partnerLocations">
        <Form.Label>{__('user.repairWorkshop.partnerLocations')}</Form.Label>
        <ReactSelectInput
          name="partnerLocations"
          options={workshopPartnerOptions}
          isMulti
          control={control}
          rules={{
            required: __('field.required'),
          }}
          isLoading={loadingConnections}
          dataQa="partnerLocationSelect"
          dataQaOption="partnerLocationOption"
          dataQaMultiValueContainer="partnerLocationValue"
        />
        <FieldError name="partnerLocations" errors={errors} />
      </Form.Group>
      <FormButtonsModal>
        <Button
          variant="link"
          disabled={loading}
          onClick={onCancel}
          data-qa-selector="form-cancel"
        >
          {__('action.close')}
        </Button>
        <Button
          variant="secondary"
          disabled={loading}
          type="submit"
          className="btn-wide ml-4"
          data-qa-selector="form-submit"
        >
          {__('action.save')}
        </Button>
      </FormButtonsModal>
    </Form>
  );
};

export default ProductionLocationForm;
