import { useFormContext } from 'react-hook-form';
import { Option } from 'types';

import BadgeItem from 'components/Filter/BadgeItem';
import { refurbishmentTypesTranslated } from 'constants/refurbishmentType';
import createOptions from 'helpers/createOptions';
import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

const refurbishmentTypeOptions = createOptions(
  refurbishmentTypesTranslated,
).reduce<Record<string, Option>>(
  (acc, item) => ({ ...acc, [item.value]: item }),
  {},
);

const RefurbishmentTypeBadges = () => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'RefurbishmentTypeBadges' must be used inside a 'Filter' component.",
    );
  }
  const { watch, getValues, setValue } = formMethods;

  const refurbishmentTypeValues = watch(FormFilterNames.REFURBISHMENT_TYPE) as
    | string[]
    | undefined;

  if (!refurbishmentTypeValues || !refurbishmentTypeValues?.length) return null;

  const options = refurbishmentTypeValues.map(
    option => refurbishmentTypeOptions[option],
  );

  return (
    <div
      className={cn.badges}
      data-qa-selector="activeBadgeRefurbishmentTypeFilters"
    >
      {options.map(option => (
        <BadgeItem
          key={option.value}
          option={option}
          qaSelector="refurbishmentTypeFilter"
          onRemove={refurbishmentType => {
            const values = getValues();
            setValue(
              FormFilterNames.REFURBISHMENT_TYPE,
              values[FormFilterNames.REFURBISHMENT_TYPE]?.filter(
                (item: string) => item !== refurbishmentType,
              ),
            );
          }}
        />
      ))}
    </div>
  );
};

export default RefurbishmentTypeBadges;
