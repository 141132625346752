import gql from 'graphql-tag';

export default gql`
  mutation($input: RefurbishmentEtaInput!) {
    createRefurbishmentEta(input: $input) {
      refurbishmentId
      comment
      eta
    }
  }
`;
