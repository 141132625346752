import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import SERVICE_TYPES from './gql/serviceTypes';

const useGetServiceTypes = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'serviceTypes'>, null>(
    SERVICE_TYPES,
    {
      fetchPolicy: 'cache-first',
    },
  );

  return {
    data: data?.serviceTypes,
    ...rest,
  };
};

export default useGetServiceTypes;
