import gql from 'graphql-tag';

import { PartnerDetailsFragment } from './fragments';

export default gql`
  mutation updatePartner($id: Int!, $partnerInput: PartnerInput!) {
    updatePartner(id: $id, partnerInput: $partnerInput) {
      ...PartnerDetailsFragment
    }
  }
  ${PartnerDetailsFragment}
`;
