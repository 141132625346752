import { isFinite } from 'lodash';

import { LOCALE } from './config';

const DEFAULT_CURRENCY_CODE = 'EUR';

const CURRENCY_SYMBOLS: Record<string, string> = {
  EUR: '€',
  PLN: 'zł',
  SEK: 'kr',
};

export const formatCurrency = (
  value: number,
  currencyCode?: string,
  noValue = '-',
) => {
  const number = Intl.NumberFormat(LOCALE, {
    style: 'currency',
    currency: currencyCode || DEFAULT_CURRENCY_CODE,
  }).format(value);
  return isFinite(value) ? number : noValue;
};

export const formatCurrencyFP = (currencyCode: string, noValue = '-') => (
  value: number,
) => formatCurrency(value, currencyCode, noValue);

export const formatSymbol = (currencyCode: string) =>
  CURRENCY_SYMBOLS[currencyCode] || currencyCode;
