import { replace } from 'lodash/fp';

import imagePlaceholder from 'images/placeholder.svg';

export enum ImageSize {
  ORIGINAL = '',
  SMALL = 's-',
}

export const getImageUrlWithoutPlaceholders = (
  imageUrl?: string | null,
  size: ImageSize = ImageSize.ORIGINAL,
) => replace('{size}', size)(imageUrl || imagePlaceholder);
