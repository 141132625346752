import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationCreateRefurbishmentEtaArgs,
  RefurbishmentEtaInput,
} from 'graphqlTypes';
import ENTRY_CHECK from 'modules/api/entryCheck/gql/entryCheck';
import QUALITY_REVIEW_CHECKS from 'modules/api/qualityCheck/gql/qualityCheckReviewV2';
import CREATE_REFURBISHMENT_ETA from './gql/createRefurbishmentEta';
import REFURBISHMENT_DETAILS from './gql/refurbishmentDetails';

const useCreateRefurbishmentEta = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'createRefurbishmentEta'>,
    MutationCreateRefurbishmentEtaArgs
  >(CREATE_REFURBISHMENT_ETA);

  const createRefurbishmentEta = (
    input: RefurbishmentEtaInput,
    qualityCheckId?: string,
    refetchEntryCheck?: boolean,
  ) => {
    const refetchQueries = [];

    refetchQueries.push({
      query: REFURBISHMENT_DETAILS,
      variables: {
        id: input.refurbishmentId,
      },
    });

    if (refetchEntryCheck) {
      refetchQueries.push({
        query: ENTRY_CHECK,
        variables: {
          refurbishmentId: input.refurbishmentId,
        },
      });
    }

    if (qualityCheckId) {
      refetchQueries.push({
        query: QUALITY_REVIEW_CHECKS,
        variables: {
          id: qualityCheckId,
        },
      });
    }

    return mutation({
      awaitRefetchQueries: true,
      variables: {
        input,
      },
      refetchQueries,
    });
  };

  return [createRefurbishmentEta, options] as const;
};

export default useCreateRefurbishmentEta;
