import React, { createContext, useReducer } from 'react';

import { notificationReducer } from './reducer';

interface ContextData {
  dispatch: React.Dispatch<any>;
}

interface Props {
  children: React.ReactNode;
  renderNotifications(
    notifications: Array<any>,
    actions: {
      expire(id: number): void;
      remove(id: number): void;
    },
  ): React.ReactNode;
}

export const NotificationContext = createContext<ContextData | null>(null);

export const NotificationProvider = ({
  children,
  renderNotifications,
}: Props) => {
  const [notifications, dispatch] = useReducer(notificationReducer, []);

  const expire = (id: number) => dispatch({ type: 'EXPIRE', payload: { id } });
  const remove = (id: number) => dispatch({ type: 'REMOVE', payload: { id } });

  return (
    <NotificationContext.Provider value={{ dispatch }}>
      {renderNotifications(notifications, { expire, remove })}
      {children}
    </NotificationContext.Provider>
  );
};
