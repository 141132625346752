import React from 'react';

import useHasRole from '../hooks/useHasRole';
import { Role } from '../role';

interface Props {
  children: React.ReactNode;
  roles: Role | Role[];
}

const ACL = ({ children, roles }: Props) => {
  const hasRoles = useHasRole(roles);

  if (hasRoles) {
    return <>{children}</>;
  }

  return null;
};

export default ACL;
