import { useQuery } from '@apollo/react-hooks';

import {
  Query,
  QueryVehicleDamageAreasArgs,
  VehicleModelVersion,
} from 'graphqlTypes';
import VEHICLE_DAMAGE_AREAS from './gql/vehicleDamageAreas';

const useVehicleDamageAreas = (vehicleModelVersion: VehicleModelVersion) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'vehicleDamageAreas'>,
    QueryVehicleDamageAreasArgs
  >(VEHICLE_DAMAGE_AREAS, {
    variables: {
      vehicleModelVersion,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    data: data?.vehicleDamageAreas,
    ...rest,
  };
};

export default useVehicleDamageAreas;
