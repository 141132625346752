import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import DatePickerInput from 'components/Form/DatePickerInput';
import FieldError from 'components/Form/FieldError';
import FormButtonModal from 'components/Form/FormButtonModal';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import { PartnerWorkshopConnection, RefurbishmentTask } from 'graphqlTypes';
import { useRefurbishmentPartnerWorkshopConnections } from 'modules/api/refurbishmentTasks';
import useAuthorizedUser from 'modules/auth/useAuthorizedUser';
import { formatDateYYYYMMDD } from 'modules/i18n';

export type TaskItemFormData = {
  partnerWorkshopConnectionId: string;
  eta: string;
};

type Props = {
  task: RefurbishmentTask;
  showETA: boolean;
  onSubmit(values: TaskItemFormData): void;
  onCancel(): void;
};

const TaskItemForm = ({ task, showETA, onSubmit, onCancel }: Props) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
  } = useForm<TaskItemFormData>({
    defaultValues: {
      partnerWorkshopConnectionId: task.currentPartnerWorkshopConnection?.id,
    },
  });
  const { user, loading: userLoading } = useAuthorizedUser();
  const {
    data: taskPartnerWorkshopConnections,
    loading: pwcLoading,
  } = useRefurbishmentPartnerWorkshopConnections(
    task.refurbishment.refurbishmentId,
  );

  const taskPWC = [...(taskPartnerWorkshopConnections || [])];
  const userPWC = [
    ...(user?.userPartnerWorkshopConnections?.flatMap(
      pwc => pwc.partnerWorkshopConnections,
    ) || []),
  ].filter((pwc): pwc is PartnerWorkshopConnection => !!pwc);

  const intersectedPWC = taskPWC.filter(
    pwc => userPWC.findIndex(pwc2 => pwc2.id === pwc.id) !== -1,
  );

  const pwcOptions = intersectedPWC.map(pwc => ({
    label: `${pwc.repairWorkshop.name} - ${pwc.repairPartner.name}`,
    value: pwc.id,
  }));

  useEffect(() => {
    if (pwcOptions && pwcOptions.length === 1) {
      setValue('partnerWorkshopConnectionId', pwcOptions[0].value);
    }
  }, [pwcOptions]);

  const onFormSubmit = (values: TaskItemFormData) => {
    const eta = formatDateYYYYMMDD(values.eta) || '';
    if (showETA && !eta) {
      setError('eta', { type: 'custom', message: __('field.invalidDate') });
      return;
    }

    onSubmit({ ...values, eta });
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Form.Group controlId="partnerWorkshopConnectionId">
        <Form.Label>
          {__('refurbishmentTaskList.taskActionForm.workshopPartner')}
        </Form.Label>
        <ReactSelectInput
          name="partnerWorkshopConnectionId"
          control={control}
          rules={{ required: __('field.required') }}
          options={pwcOptions}
          isLoading={userLoading || pwcLoading}
          dataQa="partnerLocation-select"
          dataQaOption="partnerLocation-option"
        />
        <FieldError name="partnerWorkshopConnectionId" errors={errors} />
      </Form.Group>
      {showETA && (
        <Form.Group controlId="eta">
          <Form.Label>
            {__('refurbishmentTaskList.taskActionForm.date')}
          </Form.Label>
          <DatePickerInput
            name="eta"
            id="eta"
            control={control}
            rules={{ required: __('field.required') }}
            errors={errors}
            minDate={new Date()}
            dataQaSelector="eta"
          />
          <FieldError name="eta" errors={errors} />
        </Form.Group>
      )}
      <FormButtonModal>
        <Button
          variant="link"
          onClick={onCancel}
          data-qa-selector="item-action-modal-cancel"
        >
          {__('action.close')}
        </Button>
        <Button
          variant="secondary"
          type="submit"
          data-qa-selector="item-action-modal-submit"
        >
          {__('action.submit')}
        </Button>
      </FormButtonModal>
    </Form>
  );
};

export default TaskItemForm;
