import { ExecutionResult } from 'graphql';
import { pick } from 'lodash/fp';

import {
  EntryCheckEntityType,
  EntryCheckItemCreateInput,
  EntryCheckItemValueSourceType,
  Mutation,
  RefurbishmentServiceState,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import useCreateEntryCheckItems from 'modules/api/entryCheck/useCreateEntryCheckItems';
import useCreateEntryCheckItemsForAuthorization from 'modules/api/entryCheck/useCreateEntryCheckItemsForAuthorization';
import useCreateEntryCheckItemsForPostAuthorization from 'modules/api/entryCheck/useCreateEntryCheckItemsForPostAuthorization';
import calculateSparePart from '../helpers/Damage/calculateSparePart';
import { PayloadType, ServiceInput } from '../types';

export type CreateEntryCheckServicePromise = Promise<
  ExecutionResult<Pick<Mutation, 'createEntryCheckItems'>>
>;

export type CreateAuthorizationServicePromise = Promise<
  ExecutionResult<Pick<Mutation, 'createEntryCheckItemsForAuthorization'>>
>;

export type CreatePostAuthorizationServicePromise = Promise<
  ExecutionResult<Pick<Mutation, 'createEntryCheckItemsForPostAuthorization'>>
>;

type Params = {
  entryCheckId: string;
  refurbishmentId: string;
  sourceType: EntryCheckItemValueSourceType;
  hasSparePartsManagement: boolean;
};

const useCreateService = ({
  entryCheckId,
  refurbishmentId,
  sourceType,
  hasSparePartsManagement,
}: Params) => {
  const [
    createEntryCheckItem,
    entryCheckMutationData,
  ] = useCreateEntryCheckItems();
  const [
    createEntryCheckItemForAuthorization,
    authorizationMutationData,
  ] = useCreateEntryCheckItemsForAuthorization();

  const [
    createEntryCheckItemForPostAuthorization,
  ] = useCreateEntryCheckItemsForPostAuthorization();

  const createService = (item: ServiceInput) => {
    const entryCheckItem = {
      entryCheckId,
      entityType: EntryCheckEntityType.Service,
    };

    const items: EntryCheckItemCreateInput[] = [
      {
        ...entryCheckItem,
        proposedValue: {
          payloadType: PayloadType.VehicleServiceName,
          ...pick(['type', 'name', 'isAutoCreate'], item),
        },
      },
      {
        ...entryCheckItem,
        proposedValue: {
          payloadType: PayloadType.ServiceBudget,
          ...pick(
            [
              'budgetMinorUnits',
              'consumableCostMinorUnits',
              'sparePartCostMinorUnits',
              'laborTimeHours',
              'laborRateMinorUnits',
            ],
            item,
          ),
        },
      },
      {
        ...entryCheckItem,
        proposedValue: {
          payloadType: PayloadType.ServiceComment,
          ...pick(['comment'], item),
        },
      },
      ...(item.type === RefurbishmentServiceType.Damage ||
      item.type === RefurbishmentServiceType.Vehicle ||
      item.type === RefurbishmentServiceType.ServiceBookMainService
        ? [
            {
              ...entryCheckItem,
              proposedValue: {
                payloadType: PayloadType.PartnerWorkshopConnection,
                id: item.partnerWorkshopConnectionId,
              },
            },
          ]
        : []),
      {
        ...entryCheckItem,
        proposedValue: {
          payloadType: PayloadType.ServiceState,
          serviceState: RefurbishmentServiceState.Ordered,
        },
      },
      ...(hasSparePartsManagement
        ? [
            {
              ...entryCheckItem,
              proposedValue: {
                payloadType: PayloadType.EcCommon,
                sparePart: calculateSparePart({
                  newSparePartCost: item.sparePartCostMinorUnits,
                  serviceComment: item.comment,
                }),
              },
            },
          ]
        : []),
    ];

    if (sourceType === EntryCheckItemValueSourceType.Authorization) {
      return createEntryCheckItemForAuthorization(items, refurbishmentId);
    }

    if (sourceType === EntryCheckItemValueSourceType.PostAuthorization) {
      return createEntryCheckItemForPostAuthorization(items, refurbishmentId);
    }

    return createEntryCheckItem(items, refurbishmentId);
  };

  return [
    createService,
    {
      loading:
        entryCheckMutationData.loading || authorizationMutationData.loading,
    },
  ] as const;
};

export default useCreateService;
