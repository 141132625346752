import gql from 'graphql-tag';

export default gql`
  mutation createRepairMethodImportTask(
    $createImportTaskInput: CreateImportTaskInput!
  ) {
    createRepairMethodImportTask(
      createImportTaskInput: $createImportTaskInput
    ) {
      id
      name
      uri
      type
      state
      createdBy {
        id
      }
      createdOn
    }
  }
`;
