import gql from 'graphql-tag';

import {
  DamageItemPayloadFragment,
  DocumentFragment,
  QualityCheckVehicleItemPayloadFragment,
  RefurbishmentItemPayloadFragment,
  ServiceItemPayloadFragment,
} from './fragments';

const QualityCheckLocationFragment = gql`
  fragment QualityCheckLocationFragment on Location {
    id
  }
`;

const QualityCheckRefurbishmentDetailsFragment = gql`
  fragment QualityCheckRefurbishmentDetailsFragment on RefurbishmentDetails {
    id
    adId
    refId
    state
    currencyCode
    country
    lastStateUpdatedOn
    providerEstimatedCompleteDate
    beautyShot
    beautyShotsTakenOn
    picturesReceivedOn
    transitionCurrentSet {
      stateTo
      transitionDate
    }
    documents {
      ...DocumentFragment
    }
    location {
      ...QualityCheckLocationFragment
    }
    vehicle {
      stockNumber
      vin
      make
      model
      subModel
      fuelType
      lastServiceMileage {
        distance
        unit
      }
      lastServiceOn
      technicalInspectionCertificateIssueDate
      technicalInspectionCertificateIssueMileage {
        distance
        unit
      }
      gearType
      registration
      registrationUrl
      sourcingCountry
      mileage {
        distance
        unit
      }
    }
    tags
  }
  ${DocumentFragment}
  ${QualityCheckLocationFragment}
`;

export default gql`
  query qualityCheckReview($id: UUID!) {
    qualityCheckReview(id: $id) {
      id
      state
      refurbishment {
        ...QualityCheckRefurbishmentDetailsFragment
      }
      phases {
        id
        name
        state
      }
      tags
      items {
        itemId
        itemType
        values {
          id
          phaseName
          data {
            payloadType
            ... on ServiceItemPayload {
              serviceState: state
            }
            ... on DamageItemPayload {
              damageState: state
            }
            ...QualityCheckVehicleItemPayloadFragment
            ...ServiceItemPayloadFragment
            ...DamageItemPayloadFragment
            ...RefurbishmentItemPayloadFragment
          }
          revisions {
            id
            propertyPath
            state
            createdBy {
              id
              firstName
            }
          }
          createdOn
        }
      }
    }
  }
  ${QualityCheckRefurbishmentDetailsFragment}
  ${QualityCheckVehicleItemPayloadFragment}
  ${ServiceItemPayloadFragment}
  ${DamageItemPayloadFragment}
  ${RefurbishmentItemPayloadFragment}
`;
