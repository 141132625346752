import { Role, useHasRole } from 'modules/acl';

const useDeveloperModeEnabled = () => {
  const hasDevModeRole = useHasRole(Role.DEVELOPER);

  const isFeatureEnabled =
    process.env.REACT_APP_ENABLE_DEVELOPER_MODE === 'YES';

  const isDevPreprodEnv = ['local', 'qa', 'preprod'].includes(
    String(process.env.ENV),
  );

  return isFeatureEnabled && isDevPreprodEnv && hasDevModeRole;
};

export default useDeveloperModeEnabled;
