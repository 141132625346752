import React, { useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { get } from 'lodash/fp';

import Text from 'components/UI/Text';
import { User } from 'graphqlTypes';
import { getUrl } from 'modules/routeUtils';
import AddMember from './AddMember';
import RemoveMember from './RemoveMember';

interface Props {
  id: string;
  members: User[];
}

const Members = ({ id, members }: Props) => {
  const [filteredMembers, setFilteredMembers] = useState(members);

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = get(['target', 'value'], event);

    if (value) {
      setFilteredMembers(
        members.filter(member => {
          const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
          const email = member.email.toLowerCase();
          const search = value.toLowerCase();

          return fullName.includes(search) || email.includes(search);
        }),
      );
    } else {
      setFilteredMembers(members);
    }
  };

  useEffect(() => {
    setFilteredMembers(members);
  }, [members]);

  return (
    <>
      <h3 className="title-section">{__('group.detail.members')}</h3>
      <div className="mb-3 d-flex justify-content-between">
        <Text size="xl" qaSelector="results_count">
          {members.length} {__('group.detail.membersCount')}
        </Text>{' '}
        <AddMember id={id} members={members} />
      </div>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          name="users"
          placeholder={__('group.detail.searchMembers')}
          onChange={onFilterChange}
        />
      </InputGroup>
      <Row
        className="mt-2"
        style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }}
      >
        {filteredMembers.map((member: User) => (
          <Col
            md={4}
            style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
            key={member.id}
          >
            <Card className="my-2" data-qa-selector={`user_card_${member.id}`}>
              <Card.Body>
                <Card.Title className="text-truncate mb-1">
                  <Link to={getUrl('USER_DETAIL', { id: member.id })}>
                    {member.firstName} {member.lastName}
                  </Link>
                </Card.Title>
                <Card.Text className="text-truncate">{member.email}</Card.Text>
                <RemoveMember
                  className="float-right"
                  id={id}
                  member={member}
                  members={members}
                />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Members;
