import gql from 'graphql-tag';

export default gql`
  query buildRepairCostImportUploadCredentials(
    $awsImportUploadCredentialsInput: AwsImportUploadCredentialsInput!
  ) {
    buildRepairCostImportUploadCredentials(
      awsImportUploadCredentialsInput: $awsImportUploadCredentialsInput
    ) {
      host
      cdn
      fileName
      formData
    }
  }
`;
