import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { countryOptions } from 'modules/i18n';
import createPlaceholder from './createPlaceholder';
import createSingleValue from './createSingleValue';

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, object>;
  qaSelector: string;
  placeholder: string;
  isMulti?: boolean;
};

const CountryFilter = <T extends FieldValues>({
  name,
  control,
  qaSelector,
  placeholder,
  isMulti = true,
}: Props<T>) => {
  return (
    <ReactSelectInput
      name={name}
      inputId={name}
      options={countryOptions}
      control={control}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
      dataQaMultiValueContainer={`${qaSelector}-multiValue`}
      placeholder={placeholder}
      isClearable
      isMulti={isMulti}
      components={{
        SingleValue: createSingleValue(),
        Placeholder: createPlaceholder(),
      }}
    />
  );
};

export default CountryFilter;
