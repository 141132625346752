import { useMutation } from '@apollo/react-hooks';

import {
  CreateImportTaskInput,
  Mutation,
  MutationCreateRepairMethodImportTaskArgs,
} from 'graphqlTypes';
import CREATE_REPAIR_METHOD_IMPORT_TASK from './gql/createRepairMethodImportTask';

const useCreateRepairMethodImportTask = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createRepairMethodImportTask'>,
    MutationCreateRepairMethodImportTaskArgs
  >(CREATE_REPAIR_METHOD_IMPORT_TASK);

  const createRepairMethodImportTask = (
    createImportTaskInput: CreateImportTaskInput,
  ) => mutation({ variables: { createImportTaskInput } });

  return [
    createRepairMethodImportTask,
    {
      data: data?.createRepairMethodImportTask,
      ...rest,
    },
  ] as const;
};

export default useCreateRepairMethodImportTask;
