import gql from 'graphql-tag';

export default gql`
  mutation removeRefurbishmentPartnerWorkshopConnection(
    $refurbishmentId: UUID!
    $partnerWorkshopConnectionId: UUID!
  ) {
    removeRefurbishmentPartnerWorkshopConnection(
      refurbishmentId: $refurbishmentId
      partnerWorkshopConnectionId: $partnerWorkshopConnectionId
    )
  }
`;
