import gql from 'graphql-tag';

export default gql`
  query allLocations {
    allLocations {
      locations {
        id
        name
        country
      }
    }
  }
`;
