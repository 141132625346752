import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import cns from 'classnames';

import {
  ActiveOnlyFilter,
  DamageAreaFilter,
  DamagePartFilter,
  PartnerFilter,
  RepairMethodFilter,
  WorkshopFilter,
} from 'components/CostTableFilter';
import { Location, Partner, Workshop } from 'graphqlTypes';
import { FormFilterNames } from '../../FormFilterNames';

import cn from './RepairCostSearch.module.scss';

type Props = {
  allLocations: Array<Location>;
  allPartners: Array<Partner>;
};

type FormData = {
  mainWorkshop: Workshop | null;
  repairWorkshop: Workshop | null;
  repairPartner: Partner | null;
  damageArea: string;
  damagePart: string;
  repairMethod: string;
  activeOnly: boolean;
};

const RepairCostSearch = ({ allLocations, allPartners }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const mainWorkshopBranchId = parseInt(
    searchParams.get(FormFilterNames.MAIN_WORKSHOP) || '0',
    10,
  );
  const repairWorkshopBranchId = parseInt(
    searchParams.get(FormFilterNames.REPAIR_WORKSHOP) || '0',
    10,
  );
  const repairPartnerId = parseInt(
    searchParams.get(FormFilterNames.REPAIR_PARTNER) || '0',
    10,
  );
  const damageArea = searchParams.get(FormFilterNames.AREA) || '';
  const damagePart = searchParams.get(FormFilterNames.PART) || '';
  const repairMethod = searchParams.get(FormFilterNames.REPAIR_METHOD) || '';
  const activeOnly = searchParams.get(FormFilterNames.ACTIVE_ONLY) === 'true';

  const mainWorkshop = allLocations.find(
    location => location.id === mainWorkshopBranchId,
  );
  const repairWorkshop = allLocations.find(
    location => location.id === repairWorkshopBranchId,
  );
  const repairPartner = allPartners.find(
    partner => partner.id === repairPartnerId,
  );
  const { control, handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      mainWorkshop: mainWorkshop
        ? {
            branchId: mainWorkshop.id,
            name: mainWorkshop.name,
          }
        : null,
      repairWorkshop: repairWorkshop
        ? {
            branchId: repairWorkshop.id,
            name: repairWorkshop.name,
          }
        : null,
      repairPartner: repairPartner || null,
      damageArea,
      damagePart,

      repairMethod,
      activeOnly,
    },
  });

  const handleOnSubmit = (values: FormData) => {
    const currentSearchParams = searchParams.toString();

    if (values.mainWorkshop) {
      searchParams.set(
        FormFilterNames.MAIN_WORKSHOP,
        String(values.mainWorkshop.branchId),
      );
    } else {
      searchParams.delete(FormFilterNames.MAIN_WORKSHOP);
    }
    if (values.repairWorkshop) {
      searchParams.set(
        FormFilterNames.REPAIR_WORKSHOP,
        String(values.repairWorkshop.branchId),
      );
    } else {
      searchParams.delete(FormFilterNames.REPAIR_WORKSHOP);
    }
    if (values.repairPartner) {
      searchParams.set(
        FormFilterNames.REPAIR_PARTNER,
        String(values.repairPartner.id),
      );
    } else {
      searchParams.delete(FormFilterNames.REPAIR_PARTNER);
    }
    if (values.damageArea) {
      searchParams.set(FormFilterNames.AREA, values.damageArea);
    } else {
      searchParams.delete(FormFilterNames.AREA);
    }
    if (values.damagePart) {
      searchParams.set(FormFilterNames.PART, values.damagePart);
    } else {
      searchParams.delete(FormFilterNames.PART);
    }
    if (values.repairMethod) {
      searchParams.set(FormFilterNames.REPAIR_METHOD, values.repairMethod);
    } else {
      searchParams.delete(FormFilterNames.REPAIR_METHOD);
    }
    if (values.activeOnly) {
      searchParams.set(FormFilterNames.ACTIVE_ONLY, String(values.activeOnly));
    } else {
      searchParams.delete(FormFilterNames.ACTIVE_ONLY);
    }

    if (currentSearchParams !== searchParams.toString()) {
      searchParams.delete('p');
    }

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className={cn.searchInputContainer}
    >
      <div className={cn.row}>
        <Col sm={11} className={cns('d-flex', 'flex-wrap', cn.filtersWrapper)}>
          <div className={cn.searchField}>
            <WorkshopFilter
              name="mainWorkshop"
              qaSelector="mainWorkshopFilter"
              control={control}
              placeholder={__('repairCost.mainWorkshop')}
            />
          </div>
          <div className={cn.searchField}>
            <PartnerFilter
              name="repairPartner"
              qaSelector="repairPartnerFilter"
              placeholder={__('repairCost.repairPartner')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <WorkshopFilter
              name="repairWorkshop"
              qaSelector="repairWorkshopFilter"
              control={control}
              placeholder={__('repairCost.repairWorkshop')}
            />
          </div>
          <div className={cn.searchField}>
            <DamageAreaFilter
              name="damageArea"
              qaSelector="damageAreaFilter"
              placeholder={__('repairCost.area')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <DamagePartFilter
              name="damagePart"
              qaSelector="damagePartFilter"
              placeholder={__('repairCost.part')}
              control={control}
            />
          </div>
          <div className={cn.searchField}>
            <RepairMethodFilter
              name="repairMethod"
              qaSelector="repairMethodFilter"
              placeholder={__('repairCost.repairMethod')}
              control={control}
            />
          </div>
          <div className={`${cn.searchField} d-flex align-items-center`}>
            <ActiveOnlyFilter register={register} />
          </div>
        </Col>

        <Col sm={1}>
          <Button
            variant="primary"
            type="submit"
            data-qa-selector="searchButton"
          >
            {__('action.search')}
          </Button>
        </Col>
      </div>
    </form>
  );
};

export default RepairCostSearch;
