import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Option } from 'types';

import ServiceTypeSelectControl from 'components/ServiceTypeSelectControl';
import CollapsableContent from 'components/UI/CollapsableContent';
import {
  RefurbishmentServiceType,
  VehicleServiceCostInput,
} from 'graphqlTypes';
import { useCreateVehicleServiceCost } from 'modules/api/costTable';
import { unitsFromMajorToMinor } from 'modules/currency';
import { formatString } from 'modules/i18n';
import { addSwPrefixToSubServiceOptions } from 'routes/CostTableRoute/helpers/addSwPrefixToSubServiceOptions';
import {
  MAX_CONSUMABLE_COST,
  MAX_LABOR_TIME_IN_HOURS,
  MAX_SPARE_PART_COST,
} from './constants';
import CostTableCost from './CostTableCost';
import InlineFieldError from './InlineFieldError';
import LaborRate from './LaborRate';
import LaborTime from './LaborTime';
import LocationSelectControl from './LocationSelectControl';
import MainWorkshopSelectControl from './MainWorkshopSelectControl';
import MaterialCost from './MaterialCost';
import PartnerSelectControl from './PartnerSelectControl';
import { NewRowFormData } from './types';
import {
  createLaborTotalPriceMinorUnits,
  createTotalMaterialCostMinorUnits,
  createTotalPriceMinorUnits,
  mapCountryToCurrencyCode,
} from './utils';

import cn from './CostTableRow.module.scss';

type Props = {
  onCancelClick: (id: string) => void;
  onRowSaved: () => void;
};

const CostTableNewRow = ({ onCancelClick, onRowSaved }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<NewRowFormData>({
    defaultValues: {
      vehicleServiceName: {},
      active: false,
      currencyCode: '',
      consumableCost: 0,
      sparePartCost: 0,
      laborTime: 0,
      laborRate: 0,
      isDefault: false,
      isAutoCreate: false,
    },
  });

  const [createVehicleServiceCost] = useCreateVehicleServiceCost();

  const mainWorkshop = watch('mainWorkshop');
  const refurbishmentPartner = watch('refurbishmentPartner');
  const currencyCode = watch('currencyCode');

  useEffect(() => {
    if (mainWorkshop) {
      setValue(
        'currencyCode',
        mapCountryToCurrencyCode(mainWorkshop.countryCode),
      );

      setValue('refurbishmentPartner', undefined);
      setValue('location', undefined);
    }
  }, [mainWorkshop]);

  useEffect(() => {
    if (refurbishmentPartner) {
      setValue('location', undefined);
    }
  }, [refurbishmentPartner]);

  const consumableCost = watch('consumableCost');
  const sparePartCost = watch('sparePartCost');
  const laborTime = watch('laborTime');
  const laborRate = watch('laborRate');

  const totalMaterialCost = createTotalMaterialCostMinorUnits(
    Number(consumableCost),
    Number(sparePartCost),
  );
  const laborTotalPriceMinorUnits = createLaborTotalPriceMinorUnits(
    Number(laborTime),
    Number(laborRate),
  );
  const totalPriceMinorUnits = createTotalPriceMinorUnits(
    Number(consumableCost),
    Number(sparePartCost),
    Number(laborTime),
    Number(laborRate),
  );

  const disableAutoCreate =
    watch('vehicleServiceName').type ===
    RefurbishmentServiceType.VehicleServiceCost;

  const handleServiceTypeChange = (
    value: NewRowFormData['vehicleServiceName'],
  ) => {
    if (value.type === RefurbishmentServiceType.VehicleServiceCost) {
      setValue('isAutoCreate', false);
    }
  };

  const onSubmit = (formData: NewRowFormData) => {
    if (formData.refurbishmentPartner && formData.location) {
      const input: VehicleServiceCostInput = {
        refurbishmentPartnerId:
          formData.refurbishmentPartner.refurbishmentPartnerId,
        mainWorkshopBranchId: formData.mainWorkshop.branchId,
        locationBranchId: formData.location.branchId,
        vehicleServiceName: formData.vehicleServiceName.name,
        serviceCost: {
          laborRateMinorUnits: unitsFromMajorToMinor(formData.laborRate),
          laborTimeHours: formData.laborTime,
          consumableCostMinorUnits: unitsFromMajorToMinor(
            formData.consumableCost,
          ),
          sparePartCostMinorUnits: unitsFromMajorToMinor(
            formData.sparePartCost,
          ),
          currencyCode: formData.currencyCode,
        },
        active: formData.active,
        isDefault: formData.isDefault,
        isAutoCreate: formData.isAutoCreate,
      };
      createVehicleServiceCost(input).then(() => onRowSaved());
    }
  };

  const form = 'costTable-new-row';

  return (
    <tr className="table-info" key="row-new" data-qa-selector="row-new">
      <td data-qa-selector="mainWorkshop">
        <MainWorkshopSelectControl
          form={form}
          control={control}
          name="mainWorkshop"
          qaSelector="mainWorkshop"
        />
        <InlineFieldError name="mainWorkshop" errors={errors} />
      </td>
      <td data-qa-selector="partner">
        {mainWorkshop && (
          <PartnerSelectControl
            form={form}
            control={control}
            name="refurbishmentPartner"
            qaSelector="refurbishmentPartner"
            mainWorkshopName={mainWorkshop.name}
          />
        )}
        <InlineFieldError name="refurbishmentPartner" errors={errors} />
      </td>
      <td data-qa-selector="location">
        {mainWorkshop && refurbishmentPartner && (
          <LocationSelectControl
            form={form}
            control={control}
            name="location"
            qaSelector="location"
            mainWorkshopName={mainWorkshop.name}
            refurbishmentPartnerId={refurbishmentPartner.refurbishmentPartnerId}
          />
        )}
        <InlineFieldError name="location" errors={errors} />
      </td>
      <td data-qa-selector="serviceType">
        <ServiceTypeSelectControl
          form={form}
          control={control}
          name="vehicleServiceName"
          serviceType={[
            RefurbishmentServiceType.Vehicle,
            RefurbishmentServiceType.ServiceBookMainService,
            RefurbishmentServiceType.ServiceBookSubService,
            RefurbishmentServiceType.VehicleServiceCost,
            RefurbishmentServiceType.Overhead,
          ]}
          onChange={handleServiceTypeChange}
          optionMapping={addSwPrefixToSubServiceOptions}
        />
        <InlineFieldError name="vehicleServiceName" errors={errors} />
      </td>
      <td data-qa-selector="laborTotal">
        {currencyCode && (
          <CollapsableContent
            name={`laborTotal-${form}`}
            collapsed={false}
            header={
              <CostTableCost
                costMinorUnits={laborTotalPriceMinorUnits}
                currencyCode={currencyCode}
                qaSelector="laborTotalRO"
              />
            }
          >
            <div data-qa-selector="laborTime">
              <LaborTime
                form={form}
                control={control}
                name="laborTime"
                timeInHours={0}
                editable
                rules={{
                  min: { value: 0, message: __('field.positive') },
                  max: {
                    value: MAX_LABOR_TIME_IN_HOURS,
                    message: formatString(__('field.lowerOrEqualTo'), {
                      value: `${MAX_LABOR_TIME_IN_HOURS} ${__(
                        'costTable.hours',
                      )}`,
                    }),
                  },
                }}
              />
              <InlineFieldError name="laborTime" errors={errors} />
            </div>
            <div data-qa-selector="laborRate" className="mt-2">
              <LaborRate
                form={form}
                control={control}
                name="laborRate"
                currencyCode={currencyCode}
                laborRateMinorUnits={0}
                editable
                rules={{
                  min: { value: 0, message: __('field.positive') },
                }}
              />
              <InlineFieldError name="laborRate" errors={errors} />
            </div>
          </CollapsableContent>
        )}
      </td>
      <td data-qa-selector="materialTotal">
        {currencyCode && (
          <CollapsableContent
            name={`materialTotal-${form}`}
            collapsed={false}
            header={
              <CostTableCost
                costMinorUnits={totalMaterialCost}
                currencyCode={currencyCode}
                qaSelector="materialTotalRO"
              />
            }
          >
            <div data-qa-selector="sparePartCost">
              <MaterialCost
                form={form}
                name="sparePartCost"
                currencyCode={currencyCode}
                control={control}
                rules={{
                  min: { value: 0, message: __('field.positive') },
                  max: {
                    value: MAX_SPARE_PART_COST,
                    message: formatString(__('field.lowerOrEqualTo'), {
                      value: `${MAX_SPARE_PART_COST}`,
                    }),
                  },
                }}
                suffix={__('costTable.sparePart')}
                data-qa-selector="sparePartCostEditable"
              />
              <InlineFieldError name="sparePartCost" errors={errors} />
            </div>
            <div data-qa-selector="consumableCost" className="mt-2">
              <MaterialCost
                form={form}
                name="consumableCost"
                currencyCode={currencyCode}
                control={control}
                rules={{
                  min: { value: 0, message: __('field.positive') },
                  max: {
                    value: MAX_CONSUMABLE_COST,
                    message: formatString(__('field.lowerOrEqualTo'), {
                      value: `${MAX_CONSUMABLE_COST}`,
                    }),
                  },
                }}
                suffix={__('costTable.consumable')}
                data-qa-selector="consumableCostEditable"
              />
              <InlineFieldError name="consumableCost" errors={errors} />
            </div>
          </CollapsableContent>
        )}
      </td>
      <td data-qa-selector="totalPrice">
        {currencyCode && (
          <CostTableCost
            costMinorUnits={totalPriceMinorUnits}
            currencyCode={currencyCode}
            qaSelector="totalPriceRO"
          />
        )}
      </td>
      <td data-qa-selector="isAutoCreate">
        {currencyCode && (
          <Form.Check
            id="costTable-isAutoCreate-new-row"
            type="checkbox"
            {...register('isAutoCreate')}
            disabled={disableAutoCreate}
            custom
          />
        )}
      </td>
      <td data-qa-selector="isDefault">
        {currencyCode && (
          <Form.Check
            id="costTable-isDefault-new-row"
            type="checkbox"
            {...register('isDefault')}
            custom
          />
        )}
      </td>
      <td data-qa-selector="active">
        {currencyCode && (
          <Form.Check
            id="costTable-active-new-row"
            type="checkbox"
            {...register('active')}
            custom
          />
        )}
      </td>
      <td data-qa-selector="actions">
        <form onSubmit={handleSubmit(onSubmit)} id={form}>
          <ClearIcon
            color="primary"
            onClick={() => {
              reset();
              onCancelClick('new-row');
            }}
            data-qa-selector="cancelInNewMode"
            className={cn.icon}
          />
          <button
            type="submit"
            data-qa-selector="acceptInNewMode"
            className={cn.button}
          >
            <DoneIcon color="primary" />
          </button>
        </form>
      </td>
    </tr>
  );
};

export default CostTableNewRow;
