import React from 'react';

import { RefurbishmentServiceName } from 'graphqlTypes';
import { AddOptions } from 'routes/RefurbishmentRoute/components/WheelCheckpoints/mapData';
import { EntryCheckItem, PayloadType } from 'routes/RefurbishmentRoute/types';
import BrakeYesNoItem from './Common/BrakeYesNoItem';

type Props = {
  items?: EntryCheckItem[];
  disabled: boolean;
  setAddOption(otpion: AddOptions): void;
};

const BrakeCaliperInGoodCondition = ({
  items,
  disabled,
  setAddOption,
}: Props) => {
  const entryCheckItem = items?.find(
    item => item.payloadType === PayloadType.IsBrakeCaliperInGoodCondition,
  );

  if (!entryCheckItem) return null;

  return (
    <BrakeYesNoItem
      entryCheckItem={entryCheckItem}
      label={__('entryCheck.brakeCheckpoints.brakeCaliperCondition')}
      required
      disabled={disabled}
      qaSelector="brakeCaliper"
      onDecline={() =>
        setAddOption({
          addService: RefurbishmentServiceName.Other,
        })
      }
    />
  );
};

export default BrakeCaliperInGoodCondition;
