import cns from 'classnames';

import Badge from 'components/Badge';
import {
  RefurbishmentStateTranslation,
  RefurbishmentTypeTranslation,
} from 'graphqlTypes';
import { formatDate, getTranslation } from 'modules/i18n';

import cn from '../VehicleHeader.module.scss';

type Props = {
  isCompleted?: boolean;
  readyForExitCheck?: boolean;
  subState?: string;
  refurbishmentType?: RefurbishmentTypeTranslation;
  isAutoOrdered?: boolean;
  state: RefurbishmentStateTranslation;
  lastStateUpdatedOn?: Date | null;
};

const RefurbishmentBadges = ({
  isCompleted,
  readyForExitCheck,
  subState,
  refurbishmentType,
  isAutoOrdered,
  state,
  lastStateUpdatedOn,
}: Props) => {
  return (
    <div className={cns(cn.refurbishmentBadges, 'refurbishmentBadges')}>
      <div className="d-flex justify-content-end">
        {isAutoOrdered && (
          <Badge
            color="warning"
            size="m"
            qaSelector="refurbishment_auto_ordered"
            className="mr-2"
          >
            {__('refurbishment.overview.autoOrdered')}
          </Badge>
        )}
        {refurbishmentType && (
          <Badge
            color="warning"
            size="m"
            qaSelector="entryCheckRefurbishmentType"
          >
            {refurbishmentType?.translation}
          </Badge>
        )}
      </div>

      <div className={refurbishmentType || isAutoOrdered ? 'mt-2' : ''}>
        <Badge
          color={isCompleted ? 'success' : 'info'}
          size="m"
          qaSelector="entryCheckRefurbishmentStatus"
        >
          {subState || getTranslation(state)} on{' '}
          {lastStateUpdatedOn ? formatDate(lastStateUpdatedOn) : ''}
        </Badge>
      </div>

      {readyForExitCheck && (
        <Badge
          color="success"
          size="m"
          className="mt-2"
          qaSelector="entryCheckReadyForExitCheck"
        >
          {__('qualityCheck.readyForExitCheck')}
        </Badge>
      )}
    </div>
  );
};

export default RefurbishmentBadges;
