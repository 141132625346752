import React from 'react';
import { capitalize, map } from 'lodash/fp';

import SelectInput from 'components/Form/SelectInput';

interface Props {
  name: string;
  control: any;
  isDisabled?: boolean;
  hasPA3?: boolean;
}

const DamageGroupSelectControl = ({
  name,
  control,
  isDisabled = false,
  hasPA3,
}: Props) => {
  const DAMAGE_GROUPS = [
    'FRONT',
    'RIGHT',
    'BACK',
    'LEFT',
    'ROOF',
    'INTERIOR',
    'UNDERBODY',
  ];

  const DAMAGE_GROUPS_PA3 = ['INTERIOR', 'UNDERBODY'];

  const damageGroupsToUse = hasPA3 ? DAMAGE_GROUPS_PA3 : DAMAGE_GROUPS;

  const options = map(damageGroup => ({
    label: capitalize(damageGroup as string),
    value: damageGroup,
  }))(damageGroupsToUse);

  return (
    <SelectInput
      name={name}
      options={options}
      isLoading={false}
      control={control}
      rules={{
        required: __('field.required'),
      }}
      disabled={isDisabled}
      dataQa="damage-group-select"
      dataQaOption="damage-group-option"
    />
  );
};

export default DamageGroupSelectControl;
