import { useQuery } from '@apollo/react-hooks';

import {
  Query,
  QueryVehicleAllDamageTypesArgs,
  VehicleModelVersion,
} from 'graphqlTypes';
import VEHICLE_ALL_DAMAGE_TYPES from './gql/vehicleAllDamageTypes';

const useVehicleAllDamageTypes = (vehicleModelVersion: VehicleModelVersion) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'vehicleAllDamageTypes'>,
    QueryVehicleAllDamageTypesArgs
  >(VEHICLE_ALL_DAMAGE_TYPES, {
    variables: {
      vehicleModelVersion,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    data: data?.vehicleAllDamageTypes,
    ...rest,
  };
};

export default useVehicleAllDamageTypes;
