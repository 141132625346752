import { get } from 'lodash/fp';

import {
  AwsImportUploadCredentialsInput,
  Query,
  QueryBuildRepairMethodImportUploadCredentialsArgs,
} from 'graphqlTypes';
import BUILD_REPAIR_METHOD_IMPORT_UPLOAD_CREDENTIALS from './gql/buildRepairMethodImportUploadCredentials';
import usePromiseQuery from '../helpers/usePromiseQuery';

const usePromiseBuildRepairMethodImportUploadCredentials = () => {
  const query = usePromiseQuery<
    Query['buildRepairMethodImportUploadCredentials'],
    QueryBuildRepairMethodImportUploadCredentialsArgs
  >(BUILD_REPAIR_METHOD_IMPORT_UPLOAD_CREDENTIALS, {
    fetchPolicy: 'network-only',
    context: { disableBatch: true },
  });

  const getData = async ({
    importId,
    extension,
  }: AwsImportUploadCredentialsInput) => {
    const { data } = await query({
      awsImportUploadCredentialsInput: {
        importId,
        extension,
      },
    });

    return get(['buildRepairMethodImportUploadCredentials'], data);
  };

  return getData;
};

export default usePromiseBuildRepairMethodImportUploadCredentials;
