import { CurrencyCode } from 'graphqlTypes';
import { unitsFromMajorToMinor } from 'modules/currency';

export function createTotalMaterialCostMinorUnits(
  consumableCost: number,
  sparePartCost: number,
): number {
  if (consumableCost < 0 || sparePartCost < 0) {
    return NaN;
  }

  return unitsFromMajorToMinor(consumableCost + sparePartCost);
}

export function createLaborTotalPriceMinorUnits(
  laborTime: number,
  laborRate: number,
): number {
  if (laborTime < 0 || laborRate < 0) {
    return NaN;
  }

  return laborTime * unitsFromMajorToMinor(laborRate);
}

export function createTotalPriceMinorUnits(
  consumableCost: number,
  sparePartCost: number,
  laborTime: number,
  laborRate: number,
): number {
  const materialCost = createTotalMaterialCostMinorUnits(
    consumableCost,
    sparePartCost,
  );
  const laborTotal = createLaborTotalPriceMinorUnits(laborTime, laborRate);
  if (isNaN(materialCost) || isNaN(laborTotal)) {
    return NaN;
  }

  return materialCost + laborTotal;
}

export function mapCountryToCurrencyCode(countryCode: string): CurrencyCode {
  if (countryCode === 'PL') {
    return CurrencyCode.Pln;
  }
  if (countryCode === 'SE') {
    return CurrencyCode.Sek;
  }

  return CurrencyCode.Eur;
}
