import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import {
  useUpdateQualityCheckApprovalDamageItemV2,
  useUpdateQualityCheckApprovalServiceItem,
} from 'modules/api/qualityCheckApproval';
import {
  ReworkAuthMap,
  StepName,
} from 'routes/RefurbishmentRoute/ReworkAuthorizationRoute/types';

type Props = ButtonProps & {
  items: ReworkAuthMap;
  section: string;
};

const ApproveReworkAuthorizationItems = ({
  items,
  section,
  ...rest
}: Props) => {
  const [
    updateQualityCheckApprovalDamageItem,
  ] = useUpdateQualityCheckApprovalDamageItemV2();
  const [
    updateQualityCheckApprovalServiceItem,
  ] = useUpdateQualityCheckApprovalServiceItem();

  const handleClick = () => {
    if (section === StepName.VehicleWheels) {
      items.wheels.forEach(wheel =>
        wheel.damages
          .filter(damage => damage.previousValues)
          .forEach(damage =>
            updateQualityCheckApprovalDamageItem(damage.itemId, {
              ...damage.previousValues,
              newService: damage.previousValues?.newService ?? null,
            }),
          ),
      );
    }
    if (section === StepName.Damages) {
      items.damages
        .filter(damage => damage.previousValues)
        .forEach(damage =>
          updateQualityCheckApprovalDamageItem(damage.itemId, {
            ...damage.previousValues,
            newService: damage.previousValues?.newService ?? null,
          }),
        );
    }

    if (section === StepName.VehicleServices) {
      items.services
        .filter(service => service.previousValues)
        .forEach(service =>
          updateQualityCheckApprovalServiceItem(
            service.itemId,
            service.previousValues!,
          ),
        );
    }
  };

  return (
    <Button onClick={handleClick} variant="outline-danger" {...rest}>
      Approve All
    </Button>
  );
};

export default ApproveReworkAuthorizationItems;
