import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateReviewArgs,
  UpdateReviewInput,
} from 'graphqlTypes';
import QUALITY_CHECK_REVIEW from './gql/qualityCheckReviewV2';
import UPDATE_REVIEW from './gql/updateReview';

const useUpdateReview = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateReview'>,
    MutationUpdateReviewArgs
  >(UPDATE_REVIEW);
  const updateReview = (id: string, updateReviewInput: UpdateReviewInput) => {
    return mutation({
      variables: {
        id,
        updateReviewInput,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUALITY_CHECK_REVIEW,
          variables: {
            id,
          },
        },
      ],
    });
  };

  return [updateReview, options] as const;
};

export default useUpdateReview;
