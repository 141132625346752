import gql from 'graphql-tag';

export const TransitionFragment = gql`
  fragment TransitionFragment on Transition {
    id
    stateTo
    transitionDate
    sourceType
    createdBy {
      firstName
      lastName
    }
  }
`;

export const WheelSetFragment = gql`
  fragment WheelSetFragment on WheelSet {
    isPrimary
    wheels {
      position
      season
      dot
      brakeCondition
      manufacturer
      loadIndex
      speedIndex
      imageUrl
      wheelSize {
        value
        unit
        height
      }
      rimSize {
        value
        unit
      }
      profile {
        depth
        unit
      }
      rimType
    }
  }
`;

export const VehicleFragment = gql`
  fragment VehicleFragment on Vehicle {
    vin
    stockNumber
    make
    model
    subModel
    registration
    mileage {
      distance
      unit
    }
    fuelType
    gearType
    lastServiceMileage {
      distance
      unit
    }
    lastServiceOn
    inspectionExpiryDate
    sourcingCountry
    wheelSets {
      ...WheelSetFragment
    }
    registrationUrl
    serviceBookImageUrls
    technicalInspectionCertificateUrls
    technicalInspectionCertificateIssueDate
    technicalInspectionCertificateIssueMileage {
      distance
      unit
    }
  }
  ${WheelSetFragment}
`;

export const DocumentFragment = gql`
  fragment DocumentFragment on Document {
    id
    attachedTo
    createdOn
    state
    sourceType
    url
    fileName
    description
    type
    createdBy {
      id
      firstName
      lastName
      refurbishmentProvidersData {
        location {
          id
          name
        }
        partner {
          id
          name
        }
      }
    }
  }
`;

export const DamageFragment = gql`
  fragment DamageFragment on Damage {
    id
    imageUrl
    photoId
    area
    subArea
    part
    type
    state
    customerDisplay
  }
`;

export const ServiceFragment = gql`
  fragment ServiceFragment on Service {
    id
    damageId
    serviceData {
      name
      type
    }
    budgetMinorUnits
    laborRateMinorUnits
    laborTimeHours
    sparePartCostMinorUnits
    consumableCostMinorUnits
    comment
    state
    stateComment
    createdOn
    relatedServiceId
    partnerWorkshopConnectionId
    isAutoCreate
    isDoneBeforeEntryCheck
  }
`;

export const PartnerWorkshopConnectionRefurbishmentResponseFragment = gql`
  fragment PartnerWorkshopConnectionRefurbishmentResponseFragment on PartnerWorkshopConnectionRefurbishmentResponse {
    id
    isDefault
    mainWorkshop {
      id
      name
      branchId
    }
    repairWorkshop {
      id
      name
      branchId
    }
    repairPartner {
      id
      name
      refurbishmentPartnerId
      type
    }
  }
`;

const DamageSparePartFragment = gql`
  fragment DamageSparePartFragment on DamageSparePart {
    id
    refurbishmentServiceId
    status
    sparePartCostMinorUnits
    comment
    lastStatusUpdatedOn
    part
    type
    serviceName
  }
`;

const ServiceSparePartFragment = gql`
  fragment ServiceSparePartFragment on ServiceSparePart {
    id
    refurbishmentServiceId
    status
    sparePartCostMinorUnits
    comment
    lastStatusUpdatedOn
    serviceName
  }
`;

export const SparePartsFragment = gql`
  fragment SparePartsFragment on SpareParts {
    damageSpareParts {
      ...DamageSparePartFragment
    }
    vehicleServiceSpareParts {
      ...ServiceSparePartFragment
    }
    serviceWorkSpareParts {
      ...ServiceSparePartFragment
    }
  }
  ${DamageSparePartFragment}
  ${ServiceSparePartFragment}
`;
