import { useState } from 'react';

import {
  PartnerWorkshopConnectionRefurbishmentResponse,
  RefurbishmentServiceName,
} from 'graphqlTypes';
import useGetDefaultServiceCosts from './useGetDefaultVehicleServiceCosts';

const useGetDefaultVehicleServiceCostAndLaborRate = (
  partnerWorkshopConnections?:
    | PartnerWorkshopConnectionRefurbishmentResponse[]
    | null,
) => {
  const getDefaultVehicleServiceCost = useGetDefaultServiceCosts();
  const [isLoading, setIsLoading] = useState(false);

  const getDefaultVehicleServiceCostAndLaborRate = async (
    serviceName?: string,
    partnerWorkshopConnectionId?: string,
  ) => {
    const selectedPartnerWorkshopConnection = partnerWorkshopConnections?.find(
      pwc => pwc.id === partnerWorkshopConnectionId,
    );

    setIsLoading(true);

    const serviceCost = await getDefaultVehicleServiceCost(
      serviceName,
      selectedPartnerWorkshopConnection,
    );

    const laborRateMinorUnits =
      typeof serviceCost?.laborRateMinorUnits === 'number'
        ? serviceCost.laborRateMinorUnits
        : (
            await getDefaultVehicleServiceCost(
              RefurbishmentServiceName.LaborRate,
              selectedPartnerWorkshopConnection,
            )
          )?.laborRateMinorUnits;

    setIsLoading(false);

    return {
      serviceCost,
      laborRateMinorUnits,
    };
  };

  return { getDefaultVehicleServiceCostAndLaborRate, isLoading };
};

export default useGetDefaultVehicleServiceCostAndLaborRate;
