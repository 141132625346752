import React from 'react';
import { Form } from 'react-bootstrap';

import { RepairMethod } from 'graphqlTypes';
import { Role, useHasRole } from 'modules/acl';
import useUpdateRepairMethod from 'modules/api/costTable/useUpdateRepairMethod';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';

// import cn from './RepairMethodRow.module.scss'; // TODO use disabled class when condition for disabled checkbox is added

type Props = {
  item: RepairMethod;
};

const RepairMethodRow = ({ item }: Props) => {
  const { logError } = useLogger();
  const { error } = useNotification();
  const [updateRepairMethod] = useUpdateRepairMethod();
  const hasRepairMethodAdminRole =
    useHasRole(Role.REPAIR_METHOD_ADMIN) || false;

  const updateRepairMethodData = (updatedData: { active?: boolean }) => {
    // TODO Add another fields to update - to develop in another tasks
    const updateRepairMethodInput = {
      id: item.id,
      repairMethodUpdateInput: {
        ...updatedData,
      },
    };
    try {
      updateRepairMethod(updateRepairMethodInput);
    } catch (errorData) {
      logError({ error: errorData });
      error(__('repairMethod.updateDataFailed'));
    }
  };

  return (
    <tr key={item.id} data-qa-selector={`row-${item.id}`} data-qa-id={item.id}>
      <td data-qa-selector="mainWorkshop">{item.mainWorkshop.name}</td>
      <td data-qa-selector="area">{item.area.translation}</td>
      <td data-qa-selector="damageType">{item.damageType.translation}</td>
      <td data-qa-selector="part">{item.part.translation}</td>
      <td data-qa-selector="attribute1Name">{item.attribute1Name}</td>
      <td data-qa-selector="attribute1Value">{item.attribute1Value}</td>
      <td data-qa-selector="attribute2Name">{item.attribute2Name}</td>
      <td data-qa-selector="attribute2Value">{item.attribute2Value}</td>
      <td data-qa-selector="quantityThreshold">{item.quantityThreshold}</td>
      <td data-qa-selector="repairMethodBelowThreshold">
        {item.repairMethodBelowThreshold.translation}
      </td>
      <td data-qa-selector="repairMethodEqualOrAboveThreshold">
        {item.repairMethodEqualOrAboveThreshold.translation}
      </td>
      <td data-qa-selector="active">
        <Form.Check
          type="checkbox"
          id={`repairMethod-active-${item.id}`}
          defaultChecked={item.active}
          disabled={!hasRepairMethodAdminRole}
          onChange={event =>
            updateRepairMethodData({ active: event?.target.checked })
          }
          custom
        />
      </td>
    </tr>
  );
};

export default RepairMethodRow;
