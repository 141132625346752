import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { ReactSelectInputProps } from 'components/Form/ReactSelectInput/ReactSelectInput';
import { FormFilterNames } from './constants';
import { SortDirection } from '../../graphqlTypes';

import cn from './Filter.module.scss';

type Props = Omit<
  ReactSelectInputProps,
  'name' | 'dataQa' | 'dataQaOption' | 'dataQaMultiValueContainer' | 'options'
> & {
  className?: string;
};

export const dateSortOptions = [
  {
    label: __('refurbishment.overview.lastTransitionDateDESC'),
    value: SortDirection.Desc,
  },
  {
    label: __('refurbishment.overview.lastTransitionDateASC'),
    value: SortDirection.Asc,
  },
];
const LastTransitionDateSortSelect = (props: Props) => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'LastTransitionDateSortSelect' must be used inside a 'Filter' component.",
    );
  }
  const { control } = formMethods;

  return (
    <div
      className={cns(cn.lastTransitionDateContainer)}
      data-qa-selector="refurbishment_lastTransitionDate"
    >
      <ReactSelectInput
        {...props}
        className={cns(cn.lastTransitionDate)}
        name={FormFilterNames.SORT_DATE}
        control={control}
        options={dateSortOptions}
        shouldUnregister
        defaultValue={dateSortOptions[0].value}
        dataQa="sort_lastTransitionDate"
        dataQaOption="sort_lastTransitionDate_option"
        dataQaMultiValueContainer="sort_lastTransitionDate_option_selected"
      />
    </div>
  );
};

export default LastTransitionDateSortSelect;
