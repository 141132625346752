import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationUpdateRepairMethodArgs } from 'graphqlTypes';
import UPDATE_REPAIR_METHOD from './gql/updateRepairMethod';

const useUpdateRepairMethod = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateRepairMethod'>,
    MutationUpdateRepairMethodArgs
  >(UPDATE_REPAIR_METHOD);

  const updateRepairMethod = (variables: MutationUpdateRepairMethodArgs) =>
    mutation({ variables });

  return [
    updateRepairMethod,
    {
      data: data?.updateRepairMethod,
      ...rest,
    },
  ] as const;
};

export default useUpdateRepairMethod;
