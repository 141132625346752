import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateTaskItemStateAndRefurbishmentStateArgs,
  RefurbishmentTaskItemExcludingCost,
  RefurbishmentTaskItemIncludingCost,
  RefurbishmentTaskItemState,
} from 'graphqlTypes';
import {
  RefurbishmentTaskItemExcludingCostFragment,
  RefurbishmentTaskItemIncludingCostFragment,
} from './gql/fragments';
import UPDATE_TASK_ITEMS_STATE_AND_REFURBISHMENT_STATE from './gql/updateTaskItemStateAndRefurbishmentState';

const useUpdateTaskItemStateAndRefurbishmentState = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateTaskItemStateAndRefurbishmentState'>,
    MutationUpdateTaskItemStateAndRefurbishmentStateArgs
  >(UPDATE_TASK_ITEMS_STATE_AND_REFURBISHMENT_STATE);

  const updateTaskItemStateAndRefurbishmentState = (
    taskItemId: string,
    refurbishmentTaskItemState: RefurbishmentTaskItemState,
  ) => {
    return mutation({
      variables: { taskItemId, refurbishmentTaskItemState },
      update: (proxy, mutationResult) => {
        const state =
          mutationResult.data?.updateTaskItemStateAndRefurbishmentState
            ?.refurbishmentTaskItemState.key;
        if (!state) return;

        const itemIncludingCost = proxy.readFragment<
          RefurbishmentTaskItemIncludingCost
        >({
          fragment: RefurbishmentTaskItemIncludingCostFragment,
          fragmentName: 'RefurbishmentTaskItemIncludingCostFragment',
          id: `RefurbishmentTaskItemIncludingCost:${taskItemId}`,
        });
        const itemExcludingCost = proxy.readFragment<
          RefurbishmentTaskItemExcludingCost
        >({
          fragment: RefurbishmentTaskItemExcludingCostFragment,
          fragmentName: 'RefurbishmentTaskItemExcludingCostFragment',
          id: `RefurbishmentTaskItemExcludingCost:${taskItemId}`,
        });

        if (itemIncludingCost) {
          proxy.writeFragment<RefurbishmentTaskItemIncludingCost>({
            fragment: RefurbishmentTaskItemIncludingCostFragment,
            fragmentName: 'RefurbishmentTaskItemIncludingCostFragment',
            id: `RefurbishmentTaskItemIncludingCost:${taskItemId}`,
            data: {
              ...itemIncludingCost,
              state,
            },
          });
        } else if (itemExcludingCost) {
          proxy.writeFragment<RefurbishmentTaskItemExcludingCost>({
            fragment: RefurbishmentTaskItemExcludingCostFragment,
            fragmentName: 'RefurbishmentTaskItemExcludingCostFragment',
            id: `RefurbishmentTaskItemExcludingCost:${taskItemId}`,
            data: {
              ...itemExcludingCost,
              state,
            },
          });
        }
      },
    });
  };

  return [
    updateTaskItemStateAndRefurbishmentState,
    {
      data: data?.updateTaskItemStateAndRefurbishmentState,
      ...rest,
    },
  ] as const;
};

export default useUpdateTaskItemStateAndRefurbishmentState;
