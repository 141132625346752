import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryUserArgs } from 'graphqlTypes';
import CURRENT_USER from './gql/currentUser';

const useGetCurrentUser = () => {
  const [getData, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'user'>,
    QueryUserArgs
  >(CURRENT_USER);

  const getUser = (uuid: string) => {
    getData({
      variables: { id: uuid },
    });
  };

  return [
    getUser,
    {
      user: get('user', data),
      ...rest,
    },
  ] as const;
};

export default useGetCurrentUser;
