import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import CustomModal from 'components/UI/CustomModal';
import {
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import { useLogger } from 'modules/logger';
import ProductionLocationForm, {
  ALL_LOCATIONS_VALUE,
  ProductionLocationFormData,
} from './ProductionLocationForm';

type Props = {
  userPartnerWorkshopConnections: UserPartnerWorkshopConnection[];
  loading: boolean;
  onChange(values: UserPartnerWorkshopConnectionInput): Promise<any>;
};

const AddProductionLocation = ({
  userPartnerWorkshopConnections,
  loading,
  onChange,
}: Props) => {
  const { logError } = useLogger();
  const [show, setShow] = useState(false);

  const handleOnSubmit = async (values: ProductionLocationFormData) => {
    const partnerWorkshopConnectionIds = userPartnerWorkshopConnections
      .filter(group => !group.isAllLocations)
      .flatMap(group =>
        group.partnerWorkshopConnections?.map(connection => connection?.id),
      )
      .filter((id): id is string => !!id);

    const userMainWorkshopIds = userPartnerWorkshopConnections
      .filter(group => group.isAllLocations)
      .map(group => group.mainWorkshop.id);

    const isAllPartners = values.partnerLocations.includes(ALL_LOCATIONS_VALUE);

    try {
      if (isAllPartners) {
        await onChange({
          partnerWorkshopConnectionIds,
          userMainWorkshopIds: [
            ...userMainWorkshopIds,
            ...(values.mainWorkshop?.id ? [values.mainWorkshop.id] : []),
          ],
        });
      } else {
        await onChange({
          partnerWorkshopConnectionIds: [
            ...partnerWorkshopConnectionIds,
            ...values.partnerLocations,
          ],
          userMainWorkshopIds,
        });
      }
      setShow(false);
    } catch (error) {
      logError({ error });
    }
  };

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className="d-flex justify-content-end">
      <Button
        variant="primary"
        onClick={handleOpen}
        data-qa-selector="addProductionLocation"
      >
        {__('user.add.productionLocation')}
      </Button>

      <CustomModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__('user.add.productionLocation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show && (
            <ProductionLocationForm
              loading={loading}
              onSubmit={handleOnSubmit}
              onCancel={handleClose}
            />
          )}
        </Modal.Body>
      </CustomModal>
    </div>
  );
};

export default AddProductionLocation;
