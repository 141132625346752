import BE from 'flag-icon-css/flags/4x3/be.svg';
import DE from 'flag-icon-css/flags/4x3/de.svg';
import ES from 'flag-icon-css/flags/4x3/es.svg';
import FR from 'flag-icon-css/flags/4x3/fr.svg';
import GB from 'flag-icon-css/flags/4x3/gb.svg';
import IT from 'flag-icon-css/flags/4x3/it.svg';
import NL from 'flag-icon-css/flags/4x3/nl.svg';
import PL from 'flag-icon-css/flags/4x3/pl.svg';
import SE from 'flag-icon-css/flags/4x3/se.svg';

export const LOCALE = __LOCALE__;
export const LOCALES = process.env.REACT_APP_LOCALES!.split(',');
export const LANGUAGES: Record<string, string> = {
  'en-GB': 'EN',
  'de-DE': 'DE',
  'es-ES': 'ES',
  'FR-FR': 'FR-FR',
  'fr-BE': 'FR-BE',
  'it-IT': 'IT',
  'nl-BE': 'NL',
  'pl-PL': 'PL',
  'sv-SE': 'SE',
};
export const LANGUAGE_FLAGS: Record<string, string> = {
  'en-GB': GB,
  'de-DE': DE,
  'es-ES': ES,
  'FR-FR': FR,
  'fr-BE': BE,
  'it-IT': IT,
  'nl-BE': NL,
  'pl-PL': PL,
  'sv-SE': SE,
};
