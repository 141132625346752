import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryEntryCheckAuthorizationArgs } from 'graphqlTypes';
import ENTRY_CHECK_AUTHORIZATION from './gql/entryCheckAuthorization';

const useLazyGetEntryCheckForAuthorization = () => {
  const [query, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'entryCheckAuthorization'>,
    QueryEntryCheckAuthorizationArgs
  >(ENTRY_CHECK_AUTHORIZATION, { fetchPolicy: 'network-only' });

  const getEntryCheckForAuthorization = async (
    refurbishmentId: string,
  ): Promise<void> => query({ variables: { refurbishmentId } });

  return [
    getEntryCheckForAuthorization,
    {
      data: get(['entryCheckAuthorization'], data),
      id: get(['entryCheckAuthorization', 'id'], data),
      refurbishment: get(['entryCheckAuthorization', 'refurbishment'], data),
      state: get(['entryCheckAuthorization', 'state'], data),
      items: get(['entryCheckAuthorization', 'items'], data),
      ...rest,
    },
  ] as const;
};

export default useLazyGetEntryCheckForAuthorization;
