import { useQuery } from '@apollo/react-hooks';
import { flow, get, last, split } from 'lodash/fp';

import BUILD_IMAGE_UPLOAD_CREDENTIALS from './gql/buildImageUploadCredentials';

const getExt = flow(get(['name']), split('.'), last);

const getUploadDocumentInfos = ({ id, type, fileList }) => {
  if (!id || !type || !fileList) {
    return null;
  }

  return Array.from(fileList).map(file => ({
    id,
    fileExt: getExt(file),
    documentTypeId: type,
  }));
};

const useBuildImageUploadCredentials = ({ id, type, fileList }) => {
  const uploadDocumentInfos = getUploadDocumentInfos({ id, type, fileList });

  return useQuery(BUILD_IMAGE_UPLOAD_CREDENTIALS, {
    skip: !uploadDocumentInfos,
    variables: {
      awsUploadCredentialsInput: {
        uploadDocumentInfos,
      },
    },
  });
};

export default useBuildImageUploadCredentials;
