import { useLocation, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { SortDirection } from 'graphqlTypes';

import cn from './SortableTableHeader.module.scss';

type Props = {
  header: string;
  sortField: string;
};

const SortableTableHeader = ({ header, sortField }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const currentSortField = searchParams.get('sortField') || '';
  const currentSortDir = searchParams.get('sortDir') || '';

  const handleSortDirChange = (sortDirection: SortDirection) => {
    searchParams.set('sortField', sortField);
    searchParams.set('sortDir', sortDirection);

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <div className="btn-group" role="group">
      <span>{header}</span>
      <div
        className="btn-group-vertical ml-1"
        data-qa-selector="sortableTable"
        data-qa-sort-field={sortField}
      >
        <button
          type="button"
          onClick={() => handleSortDirChange(SortDirection.Asc)}
          className={cn.button}
          data-qa-selector="sortASC"
        >
          <ArrowDropUpIcon
            color={
              currentSortField === sortField && currentSortDir === 'ASC'
                ? 'inherit'
                : 'disabled'
            }
            fontSize="inherit"
            viewBox="6 6 12 6"
          />
        </button>
        <button
          type="button"
          onClick={() => handleSortDirChange(SortDirection.Desc)}
          className={cn.button}
          data-qa-selector="sortDESC"
        >
          <ArrowDropDownIcon
            color={
              currentSortField === sortField && currentSortDir === 'DESC'
                ? 'inherit'
                : 'disabled'
            }
            fontSize="inherit"
            viewBox="6 6 12 6"
          />
        </button>
      </div>
    </div>
  );
};

export default SortableTableHeader;
