import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { MainWorkshop } from './mainWorkshop';

const GET_CURRENT_MAIN_WORKSHOP = gql`
  query getCurrentMainWorkshop {
    currentMainWorkshop @client {
      branchId
      name
    }
  }
`;

export const useGetCurrentMainWorkshop = () => {
  const { data, ...rest } = useQuery<{
    currentMainWorkshop: MainWorkshop;
  }>(GET_CURRENT_MAIN_WORKSHOP);

  return {
    currentMainWorkshop: data && data.currentMainWorkshop,
    ...rest,
  };
};
