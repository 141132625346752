import { useContext } from 'react';

import { NotificationContext } from './NotificationProvider';

interface NotifyParams {
  text: string;
  type: string;
  timeout: number;
}

interface NotifyOptionalParams {
  timeout?: number;
}

const SUCCESS_TIMEOUT = 2000;

const ERROR_TIMEOUT = 5000;

const INFO_TIMEOUT = 2000;

let currentId = 0;

const useNotification = () => {
  const contextData = useContext(NotificationContext);

  if (!contextData) {
    throw new Error(
      'useNotification must be used within an NotificationProvider',
    );
  }

  const { dispatch } = contextData;

  const notify = ({ text, type, timeout }: NotifyParams) => {
    currentId += 1;
    const id = currentId;

    dispatch({
      type: 'ADD',
      payload: { id, type, text },
    });

    setTimeout(() => {
      dispatch({
        type: 'EXPIRE',
        payload: { id },
      });
    }, timeout);
  };

  const success = (text: string, options?: NotifyOptionalParams) => {
    const defaultOptions = {
      timeout: SUCCESS_TIMEOUT,
      ...options,
    };

    const { timeout } = defaultOptions;

    notify({
      text,
      type: 'success',
      timeout,
    });
  };

  const error = (text: string, options?: NotifyOptionalParams) => {
    const defaultOptions = {
      timeout: ERROR_TIMEOUT,
      ...options,
    };

    const { timeout } = defaultOptions;

    notify({
      text,
      type: 'danger',
      timeout,
    });
  };

  const info = (text: string, options?: NotifyOptionalParams) => {
    const defaultOptions = {
      timeout: INFO_TIMEOUT,
      ...options,
    };

    const { timeout } = defaultOptions;

    notify({
      text,
      type: 'info',
      timeout,
    });
  };

  return { success, error, info };
};

export default useNotification;
