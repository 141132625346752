import {
  PartnerWorkshopConnectionRefurbishmentResponse,
  RefurbishmentServiceName,
} from 'graphqlTypes';
import useDefaultVehicleServiceCosts from './useGetDefaultVehicleServiceCosts';

const useGetDefaultLaborRate = (
  partnerWorkshopConnections?:
    | PartnerWorkshopConnectionRefurbishmentResponse[]
    | null,
) => {
  const getDefaultVehicleServiceCost = useDefaultVehicleServiceCosts();

  const getDefaultLaborRate = async (
    partnerWorkshopConnectionId: string | undefined,
  ) => {
    const partnerWorkshopData = partnerWorkshopConnections?.find(
      partner => partner.id === partnerWorkshopConnectionId,
    );

    const laborRateMinorUnits = (
      await getDefaultVehicleServiceCost(
        RefurbishmentServiceName.LaborRate,
        partnerWorkshopData,
      )
    )?.laborRateMinorUnits;

    return laborRateMinorUnits || 0;
  };

  return {
    getDefaultLaborRate,
  };
};

export default useGetDefaultLaborRate;
