import { Link } from 'react-router-dom';

import Badge from 'components/Badge';
import { InformMessage } from 'graphqlTypes';
import { formatDateTime } from 'modules/i18n';
import { getUrl } from 'modules/routeUtils';
import { InformMessageStatus } from 'routes/MonitoringDashboardRoute/types';
import Comment from './Comment';
import Content from './Content';
import Review from './Review';

import cn from './MonitoringRow.module.scss';

const getStatusColor = (status?: string | null) => {
  switch (status) {
    case InformMessageStatus.TO_SEND:
      return 'warning';
    case InformMessageStatus.SENT:
      return 'warning';
    case InformMessageStatus.SENDING_ERROR:
      return 'danger';
    case InformMessageStatus.MAX_DELIVERY_ATTEMPTS_REACHED:
      return 'danger';
    case InformMessageStatus.VPC_PROCESSING_ERROR:
      return 'danger';
    case InformMessageStatus.RETRIED:
      return 'warning';
    case InformMessageStatus.SUCCESS:
      return 'success';
    case InformMessageStatus.CANCELED:
    case InformMessageStatus.DRY_RUN:
      return 'warning';
    default:
      return '';
  }
};

type Props = {
  message: InformMessage;
  checkboxArea: React.ReactNode;
};

const MonitoringRow = ({ message, checkboxArea }: Props) => {
  const statusColor = getStatusColor(message.status);

  return (
    <tr data-qa-selector="message-row" data-qa-id={message.id}>
      <td>{checkboxArea}</td>
      <td>
        <div>
          {message.stockNumber ? (
            <a
              href={`${process.env.REACT_APP_ADMIN_CAR_URL}car/view/${message.stockNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              data-qa-selector="vin"
            >
              {message.vin}
            </a>
          ) : (
            <span data-qa-selector="vin">{message.vin}</span>
          )}
        </div>
        <div data-qa-selector="stockNumber">{message.stockNumber}</div>
        <div className={cn.id} data-qa-selector="message-id">
          {message.id}
        </div>
      </td>
      <td>
        {!!message.refurbishmentId && (
          <Link
            to={getUrl('REFURBISHMENT_DETAIL', {
              id: message.refurbishmentId,
            })}
            data-qa-selector="refurbishmentId"
          >
            {message.refId}
          </Link>
        )}
      </td>
      <td>
        <div data-qa-selector="serviceName">{message.serviceName}</div>
        <div data-qa-selector="carPart">{message.carPart}</div>
        <div data-qa-selector="serviceCode">{message.serviceCode}</div>
        <div className={cn.id} data-qa-selector="serviceId">
          {message.serviceId}
        </div>
      </td>
      <td>
        <div data-qa-selector="messageType">{message.messageType}</div>
      </td>
      <td className={cn.timestamp} data-qa-selector="createdAt">
        {formatDateTime(message.createdAt)}
      </td>
      <td>
        {statusColor && (
          <Badge color={statusColor} qaSelector="status">
            {message.status}
          </Badge>
        )}
      </td>
      <td data-qa-selector="tenant">{message.tenant.name}</td>
      <td data-qa-selector="errorCode">{message.errorCode}</td>
      <td data-qa-selector="errorText">{message.errorText}</td>
      <td>
        <div data-qa-selector="direction">{message.direction}</div>
      </td>
      <td>
        <Review message={message} />
      </td>
      <td>
        <Comment message={message} />
      </td>
      <td data-qa-selector="content">
        <Content content={message.content} />
      </td>
    </tr>
  );
};

export default MonitoringRow;
