export const countries = new Map([
  ['AT', __('country.at')],
  ['BE', __('country.be')],
  ['FR', __('country.fr')],
  ['DE', __('country.de')],
  ['IT', __('country.it')],
  ['NL', __('country.nl')],
  ['PL', __('country.pl')],
  ['ES', __('country.es')],
  ['SE', __('country.se')],
]);

export const locales = new Map([
  ['en-GB', __('localization.en-GB')],
  ['de-DE', __('localization.de-DE')],
  ['es-ES', __('localization.es-ES')],
  ['it-IT', __('localization.it-IT')],
  ['sv-SE', __('localization.sv-SE')],
  ['fr-BE', __('localization.fr-BE')],
  ['FR-FR', __('localization.fr-FR')],
  ['nl-BE', __('localization.nl-BE')],
  ['pl-PL', __('localization.pl-PL')],
]);
