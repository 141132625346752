import gql from 'graphql-tag';

import FRAGMENT_USER_FIELDS from './fragmentUserFields';

export default gql`
  mutation updateUser($id: UUID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      ...userFields
    }
  }
  ${FRAGMENT_USER_FIELDS}
`;
