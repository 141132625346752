import { Button } from 'react-bootstrap';

import FormButtonModal from 'components/Form/FormButtonModal/FormButtonsModal';
import { ImportTask } from 'graphqlTypes';
import useCancelRepairCostImport from 'modules/api/costTable/useCancelRepairCostImport';
import useStartRepairCostImport from 'modules/api/costTable/useStartRepairCostImport';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { ImportRepairCostPhase } from './ImportRepairCostModal';
import ImportRepairCostStatistics from './ImportRepairCostStatistics';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairCostPhase>>;
  getRepairCostImportTask: (uuid: string) => void;
  repairCostImportTaskData?: ImportTask;
};

const ImportRepairCostProceed = ({
  closeModal,
  setActivePhase,
  getRepairCostImportTask,
  repairCostImportTaskData,
}: Props) => {
  const { logError } = useLogger();
  const { error } = useNotification();

  const [
    startRepairCostImport,
    // { startRepairCostImportData }, TODO
  ] = useStartRepairCostImport();
  const [cancelRepairCostImport] = useCancelRepairCostImport();

  const proceedWithImport = async () => {
    try {
      setActivePhase(ImportRepairCostPhase.IMPORT_LOADING);

      const id = repairCostImportTaskData?.id || '';
      await startRepairCostImport(id);
      getRepairCostImportTask(id);
    } catch (errorData) {
      logError({ error: errorData });
      error(__('refurbishment.detail.failedImport'));
      setActivePhase(ImportRepairCostPhase.PROCEEDING_WITH_IMPORT);
    }
  };

  const cancelImport = async () => {
    const id = repairCostImportTaskData?.id || '';

    try {
      await cancelRepairCostImport(id);
      setActivePhase(ImportRepairCostPhase.UPLOADING);
      closeModal();
    } catch (errorData) {
      logError({ error: errorData });
      error(__('refurbishment.detail.cancelImportFailed'));
    }
  };

  return (
    <>
      <h3>{__('repairCost.proceedWithImport')}</h3>
      <ImportRepairCostStatistics
        statistics={repairCostImportTaskData?.statistics}
      />
      <FormButtonModal>
        <Button
          variant="secondary"
          onClick={cancelImport}
          data-qa-selector="closeImportRepairCostModal"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="ml-4"
          data-qa-selector="proceedActionRepairCostModal"
          onClick={proceedWithImport}
        >
          {__('repairCost.proceedAction')}
        </Button>
      </FormButtonModal>
    </>
  );
};

export default ImportRepairCostProceed;
