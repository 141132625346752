import { TaskItemList } from './components/TaskItemList';
import { TaskItemListServiceWork } from './components/TaskItemListServiceWork';
import { Step } from './types';

export const steps = {
  [Step.Services]: TaskItemListServiceWork,
  [Step.Front]: TaskItemList,
  [Step.Right]: TaskItemList,
  [Step.Back]: TaskItemList,
  [Step.Left]: TaskItemList,
  [Step.Roof]: TaskItemList,
  [Step.Interior]: TaskItemList,
  [Step.Underbody]: TaskItemList,
  [Step.Engine]: TaskItemList,
  [Step.Wheels]: TaskItemList,
  [Step.VehicleServices]: TaskItemList,
};
