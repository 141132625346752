import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { trim } from 'lodash/fp';

import FieldError from 'components/Form/FieldError';
import FormButtonsModal from 'components/Form/FormButtonModal';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import { PartnerInput, PartnerType } from 'graphqlTypes';
import { countryOptions } from 'modules/i18n';
import { partnerTypeTranslation } from '../../partnerTranslation';

type Props = {
  data?: PartnerInput;
  loading: boolean;
  onSubmit(values: PartnerInput): Promise<any>;
  onCancel?(): void;
};

const PartnerForm = ({ data, loading, onSubmit, onCancel }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm<PartnerInput>({
    defaultValues: {
      name: data?.name || '',
      contactName: data?.contactName || '',
      phone: data?.phone || '',
      partnerType: data?.partnerType,
      partnerEmail: data?.partnerEmail || '',
      email1: data?.email1 || '',
      email2: data?.email2 || '',
      address: data?.address || '',
      postcode: data?.postcode || '',
      city: data?.city || '',
      country: data?.country,
      active: data?.active,
    },
  });

  const partnerTypeOptions = [
    {
      value: PartnerType.Internal,
      label: partnerTypeTranslation[PartnerType.Internal],
    },
    {
      value: PartnerType.External,
      label: partnerTypeTranslation[PartnerType.External],
    },
  ];

  const notEmpty = (value: string) =>
    trim(value) !== '' || __('field.required');

  const onFormSubmit = (values: PartnerInput) => {
    onSubmit(values);
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} data-qa-selector="form">
      <Form.Group controlId="name">
        <Form.Label>{__('partners.name')}</Form.Label>
        <Form.Control
          {...register('name', {
            validate: notEmpty,
          })}
          isInvalid={!!errors.name}
          data-qa-selector="name"
        />
        <FieldError name="name" errors={errors} />
      </Form.Group>

      <Form.Group controlId="contactName">
        <Form.Label>{__('partners.contactName')}</Form.Label>
        <Form.Control
          {...register('contactName', {
            validate: notEmpty,
          })}
          isInvalid={!!errors.contactName}
          data-qa-selector="contactName"
        />
        <FieldError name="contactName" errors={errors} />
      </Form.Group>

      <Row>
        <Col sm={6}>
          <Form.Group controlId="phone">
            <Form.Label>{__('partners.phone')}</Form.Label>
            <Form.Control
              {...register('phone')}
              isInvalid={!!errors.phone}
              data-qa-selector="phone"
            />
            <FieldError name="phone" errors={errors} />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId="partnerType">
            <Form.Label>{__('partners.partnerType')}</Form.Label>
            <ReactSelectInput
              name="partnerType"
              placeholder={__('partners.select')}
              options={partnerTypeOptions}
              rules={{
                required: __('field.required'),
              }}
              dataQa="partner_type"
              dataQaOption="partner_type_option"
              control={control}
            />
            <FieldError name="partnerType" errors={errors} />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="partnerEmail">
        <Form.Label>{__('partners.partnerEmail')}</Form.Label>
        <Form.Control
          {...register('partnerEmail', {
            validate: notEmpty,
          })}
          isInvalid={!!errors.partnerEmail}
          data-qa-selector="partnerEmail"
        />
        <FieldError name="partnerEmail" errors={errors} />
      </Form.Group>

      <Form.Group controlId="email1">
        <Form.Label>{__('partners.email1')}</Form.Label>
        <Form.Control
          {...register('email1')}
          isInvalid={!!errors.email1}
          data-qa-selector="email1"
        />
        <FieldError name="email1" errors={errors} />
      </Form.Group>

      <Form.Group controlId="email2">
        <Form.Label>{__('partners.email2')}</Form.Label>
        <Form.Control
          {...register('email2')}
          isInvalid={!!errors.email2}
          data-qa-selector="email2"
        />
        <FieldError name="email2" errors={errors} />
      </Form.Group>

      <Form.Group controlId="address">
        <Form.Label>{__('partners.address')}</Form.Label>
        <Form.Control
          {...register('address')}
          isInvalid={!!errors.address}
          data-qa-selector="address"
        />
        <FieldError name="address" errors={errors} />
      </Form.Group>

      <Row>
        <Col sm={6}>
          <Form.Group controlId="postCode">
            <Form.Label>{__('partners.postcode')}</Form.Label>
            <Form.Control
              {...register('postcode')}
              isInvalid={!!errors.postcode}
              data-qa-selector="postcode"
            />
            <FieldError name="postcode" errors={errors} />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId="city">
            <Form.Label>{__('partners.city')}</Form.Label>
            <Form.Control
              {...register('city')}
              isInvalid={!!errors.city}
              data-qa-selector="city"
            />
            <FieldError name="city" errors={errors} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <Form.Group controlId="country">
            <Form.Label>{__('user.detail.country')}</Form.Label>
            <ReactSelectInput
              name="country"
              placeholder={__('partners.select')}
              options={countryOptions}
              rules={{
                required: __('field.required'),
              }}
              isInvalid={!!errors.country}
              dataQa="partner_country"
              dataQaOption="partner_country_option"
              control={control}
            />
            <FieldError name="country" errors={errors} />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId="active">
            <Form.Label>{__('partners.active')}</Form.Label>
            <Form.Check
              {...register('active')}
              name="active"
              type="checkbox"
              id="active"
              custom
              data-qa-selector="active"
            />
          </Form.Group>
        </Col>
      </Row>

      <FormButtonsModal>
        <Button
          variant="link"
          disabled={loading}
          onClick={onCancel}
          data-qa-selector="form-cancel"
        >
          {__('partners.cancel')}
        </Button>
        <Button
          variant="secondary"
          type="submit"
          disabled={loading || isSubmitting}
          className="btn-wide ml-4"
          data-qa-selector="form-submit"
        >
          {__('partners.confirm')}
        </Button>
      </FormButtonsModal>
    </Form>
  );
};

export default PartnerForm;
