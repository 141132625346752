import gql from 'graphql-tag';

export const GET_FILTERS = gql`
  query getCurrentFilter {
    currentFilter @client {
      refurbishment
      exitcheck
    }
  }
`;
