import { components, MenuProps } from 'react-select';

const createMenu = (selector: string) => (props: MenuProps) => {
  return (
    <div data-qa-selector={selector}>
      {' '}
      <components.Menu {...props} />
    </div>
  );
};

export default createMenu;
