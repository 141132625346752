import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryRefurbishmentDetailsByRefIdArgs } from 'graphqlTypes';
import REFURBISHMENT_DETAILS_BY_REF_ID from './gql/refurbishmentDetailsByRefId';

const useGetRefurbishmentDetailsByRefId = () => {
  const [query, { data, ...rest }] = useLazyQuery<
    Pick<Query, 'refurbishmentDetailsByRefId'>,
    QueryRefurbishmentDetailsByRefIdArgs
  >(REFURBISHMENT_DETAILS_BY_REF_ID);

  const getRefurbishmentDetailsByRefId = (refId: string): void =>
    query({ variables: { refId } });

  return [
    getRefurbishmentDetailsByRefId,
    {
      data: get(['refurbishmentDetailsByRefId'], data),
      ...rest,
    },
  ] as const;
};

export default useGetRefurbishmentDetailsByRefId;
