import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Mutation,
  MutationCompleteQualityCheckReviewArgs,
  ReviewState,
} from 'graphqlTypes';
import COMPLETE_QUALITY_CHECK_REVIEW from './gql/completeQualityCheckReview';

const useCompleteQualityCheckReview = () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'completeQualityCheckReview'>,
    MutationCompleteQualityCheckReviewArgs
  >(COMPLETE_QUALITY_CHECK_REVIEW);

  const completeQualityCheckReview = (phaseId: string, reviewId: string) => {
    return mutation({
      variables: { id: phaseId },
      update(cache, { data }) {
        if (data?.completeQualityCheckReview) {
          cache.writeFragment({
            id: `Review:${reviewId}`,
            fragment: gql`
              fragment review on Review {
                state
              }
            `,
            data: {
              state: ReviewState.Completed,
              __typename: 'Review',
            },
          });
        }
      },
    });
  };

  return [completeQualityCheckReview, options] as const;
};

export default useCompleteQualityCheckReview;
