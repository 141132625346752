import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { FormFilterNames } from 'components/Filter/constants';
import { useGetProduction } from 'modules/api/production';

const useGetProductionOverview = () => {
  const { search } = useLocation();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });
  const [filterValues, setFilterValuesInner] = useState<null | Record<
    string,
    any
  >>(null);

  const page = Math.max(Number(searchParams?.p) - 1 || 0, 0);
  const refId = filterValues?.[FormFilterNames.REFURBISHMENT_NUMBER];
  const stockNumber = filterValues?.[FormFilterNames.STOCK_NUMBER];
  const vinNumber = filterValues?.[FormFilterNames.VIN_NUMBER];
  const refurbishmentStates = filterValues?.[FormFilterNames.STATE];
  const refurbishmentTypes = filterValues?.[FormFilterNames.REFURBISHMENT_TYPE];
  const branchIds = filterValues?.[FormFilterNames.BRANCH];

  const { data, loading } = useGetProduction({
    page,
    pageSize: 50,
    filter: {
      ...(refId ? { refId } : {}),
      ...(stockNumber ? { stockNumber } : {}),
      ...(vinNumber ? { vinNumber } : {}),
      ...(refurbishmentStates ? { refurbishmentStates } : {}),
      ...(refurbishmentTypes ? { refurbishmentTypes } : {}),
      ...(branchIds ? { branchIds } : {}),
    },
  });

  const setFilterValues = (values: object) => {
    setFilterValuesInner(values);
  };

  return { data, loading, setFilterValues };
};

export default useGetProductionOverview;
