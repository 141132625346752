import createProviderOptions from './createProviderOptions';
import receiveResponse from './receiveResponse';
import sendRequest from './sendRequest';
import createApi from '../../utils/createApi';

export default options =>
  createApi({
    createProviderOptions,
    receiveResponse,
    sendRequest,
    ...options,
  });
