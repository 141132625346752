import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import RIM_SIZES from './gql/rimSizes';

const useRimSizes = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'rimSizes'>, null>(RIM_SIZES);

  return {
    data: get('rimSizes', data),
    ...rest,
  };
};

export default useRimSizes;
