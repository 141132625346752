import { Option } from 'types';

import { ServiceTypeSelectValue } from 'components/ServiceTypeSelectControl/ServiceTypeSelectControl';
import { RefurbishmentServiceType } from 'graphqlTypes';

export const addSwPrefixToSubServiceOptions = (
  option: Option<ServiceTypeSelectValue>,
): Option<ServiceTypeSelectValue> => {
  if (option.value.type === RefurbishmentServiceType.ServiceBookSubService) {
    option.label = `${__('costTable.swPrefix')} ${option.label}`;
  }
  return option;
};
