/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { useRef } from 'react';
import { invokeArgs, noop } from 'lodash/fp';

import prepareFiles, { PreparedFile } from './prepareFiles';

type DndEventHandler = (event?: React.DragEvent<HTMLSpanElement>) => void;
type ChangeEventHandler = (event?: React.ChangeEvent<HTMLInputElement>) => void;

type FilesHandler = (event: PreparedFile[]) => void;

interface Props {
  accept?: string;
  children: React.ReactNode;
  className?: string;
  multiple?: boolean;
  onDrag?: DndEventHandler;
  onDragEnd?: DndEventHandler;
  onDragEnter?: DndEventHandler;
  onDragLeave?: DndEventHandler;
  onDragOver?: DndEventHandler;
  onDrop?: DndEventHandler;
  onFiles?: FilesHandler;
  onInputChange?: ChangeEventHandler;
}

const FileDropzone = ({
  children,
  className,
  multiple = false,
  onDrag,
  onDragEnd,
  onDragEnter,
  onDragLeave = noop,
  onDragOver = noop,
  onDrop = noop,
  onFiles = noop,
  onInputChange = noop,
  accept,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFiles = async (files: FileList | null) => {
    const preparedFiles = await prepareFiles(files);

    onFiles(preparedFiles);
  };

  return (
    <div
      className={className}
      onClick={e => invokeArgs(['current', 'click'], [e], inputRef)}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={e => {
        onDrop(e);

        handleFiles(e.dataTransfer.files);
      }}
    >
      <input
        accept={accept}
        style={{ display: 'none' }}
        type="file"
        multiple={multiple}
        ref={inputRef}
        onChange={e => {
          onInputChange(e);

          handleFiles(e.target.files);

          if (inputRef && inputRef.current) {
            inputRef.current.value = '';
          }
        }}
        data-qa-selector="file-dropzone-input"
      />
      {children}
    </div>
  );
};

export default FileDropzone;
