import gql from 'graphql-tag';

export default gql`
  query documentCategories($country: String!, $topCategory: String!) {
    documentCategories(country: $country, topCategory: $topCategory) {
      topCategory
      categories {
        name
        documentTypes {
          id
          name
          description
          key
        }
      }
    }
  }
`;
