import gql from 'graphql-tag';

export default gql`
  query carParts {
    carParts {
      id
      name
      section
      subSection
    }
  }
`;
