import { useMutation } from '@apollo/react-hooks';

import {
  EntryCheckItemValueSourceType,
  Mutation,
  MutationDeleteEntryCheckItemsArgs,
} from 'graphqlTypes';
import DELETE_ENTRY_CHECK_ITEMS from './gql/deleteEntryCheckItems';
import ENTRY_CHECK from './gql/entryCheck';
import ENTRY_CHECK_AUTHORIZATION from './gql/entryCheckAuthorization';
import ENTRY_CHECK_POST_AUTHORIZATION from './gql/entryCheckPostAuthorization';

export default () => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'deleteEntryCheckItems'>,
    MutationDeleteEntryCheckItemsArgs
  >(DELETE_ENTRY_CHECK_ITEMS);

  const deleteEntryCheckItems = (
    variables: MutationDeleteEntryCheckItemsArgs & {
      refurbishmentId: string;
      sourceType: EntryCheckItemValueSourceType;
    },
  ) =>
    mutation({
      variables,
      update(cache, { data }) {
        if (data && data.deleteEntryCheckItems) {
          const { refurbishmentId, ids, sourceType } = variables;

          let query;
          let cacheKey;

          switch (sourceType) {
            case EntryCheckItemValueSourceType.EntryCheck:
              query = ENTRY_CHECK;
              cacheKey = 'entryCheck';
              break;
            case EntryCheckItemValueSourceType.Authorization:
              query = ENTRY_CHECK_AUTHORIZATION;
              cacheKey = 'entryCheckAuthorization';
              break;
            case EntryCheckItemValueSourceType.PostAuthorization:
              query = ENTRY_CHECK_POST_AUTHORIZATION;
              cacheKey = 'entryCheckPostAuthorization';
              break;
            default:
              throw new Error(`Unsupported source type: ${sourceType}`);
          }

          const entryCheck: any = cache.readQuery({
            query,
            variables: {
              refurbishmentId,
            },
          });

          if (entryCheck) {
            const entryCheckItems = entryCheck[cacheKey].items.filter(
              (item: any) => !ids.includes(item.id),
            );

            cache.writeQuery({
              query,
              data: {
                [cacheKey]: {
                  ...entryCheck[cacheKey],
                  items: entryCheckItems,
                },
              },
              variables: {
                refurbishmentId,
              },
            });
          }
        }
      },
    });

  return [deleteEntryCheckItems, options] as const;
};
