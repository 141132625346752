import { flow, get, map } from 'lodash/fp';

type StringOrNull = string | null;

export type PreparedFile = {
  file: File;
  base64Url: StringOrNull;
};

type PrepareFiles = (files: FileList | null) => Promise<PreparedFile[]>;

export const getBase64Url = (file: File): Promise<StringOrNull> => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = (e: Event) => {
      const base64Url: StringOrNull = get(['target', 'result'], e);

      resolve(base64Url);
    };

    reader.readAsDataURL(file);
  });
};

export const isImage = (file: File) => file.type.indexOf('image/') === 0;

const prepareFiles: PrepareFiles = flow(
  map(async file => {
    let base64Url: StringOrNull = null;

    if (isImage(file)) {
      base64Url = await getBase64Url(file);
    }

    const result: PreparedFile = { file, base64Url };

    return Promise.resolve(result);
  }),
  (p: Promise<PreparedFile>[]) => Promise.all(p),
);

export default prepareFiles;
