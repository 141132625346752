import gql from 'graphql-tag';

import { EntryCheckAuthorizationItemFragment } from './fragments';

export default gql`
  mutation createEntryCheckItemsForAuthorization(
    $inputs: [EntryCheckItemCreateInput!]!
  ) {
    createEntryCheckItemsForAuthorization(inputs: $inputs) {
      ...EntryCheckAuthorizationItemFragment
    }
  }
  ${EntryCheckAuthorizationItemFragment}
`;
