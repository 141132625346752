import { Control, FieldValues, Path } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import useGetAllPartners from 'modules/api/partners/useGetAllPartners';

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, object>;
  qaSelector: string;
};

type Value = {
  name: string;
  refurbishmentPartnerId: string;
};

const PartnerSelectControl = <T extends FieldValues>({
  name,
  control,
  qaSelector,
}: Props<T>) => {
  const { data, loading } = useGetAllPartners();

  const options =
    data
      ?.map(item => ({
        label: item.name,
        value: {
          name: item.name,
          refurbishmentPartnerId: item.id,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <ReactSelectInput
      name={name}
      control={control}
      options={options}
      isLoading={loading}
      isMulti
      closeMenuOnSelect={false}
      inputId={name}
      rules={{
        required: __('field.required'),
      }}
      equalFn={(value1: Value, value2: Value) =>
        value1?.refurbishmentPartnerId === value2?.refurbishmentPartnerId
      }
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
      dataQaMultiValueContainer={`${qaSelector}-multiValue`}
    />
  );
};

export default PartnerSelectControl;
