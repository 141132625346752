import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import {
  Query,
  QueryProviderTotalCostsPostAuthorizationArgs,
} from 'graphqlTypes';
import PROVIDER_TOTAL_COSTS_POST_AUTHORIZATION from './gql/providerTotalCostsPostAuthorization';

const useGetProviderTotalCostsForPostAuthorization = (entryCheckId: string) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'providerTotalCostsPostAuthorization'>,
    QueryProviderTotalCostsPostAuthorizationArgs
  >(PROVIDER_TOTAL_COSTS_POST_AUTHORIZATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      entryCheckId,
    },
  });

  return {
    data: get('providerTotalCostsPostAuthorization', data),
    ...rest,
  };
};

export default useGetProviderTotalCostsForPostAuthorization;
