import React, { useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import cns from 'classnames';

import arrowDown from 'images/arrow-down.svg';
import arrowUp from 'images/arrow-up.svg';

import cn from './CollapsableSection.module.scss';

type Props = {
  name: string;
  children: React.ReactNode;
  showName?: string;
  hideName?: string;
  header: React.ReactNode;
  open?: boolean;
  disabled?: boolean;
  className?: string;
  classNameHeader?: string;
  dataQaSelector?: string;
};

const CollapsableSection = ({
  name,
  children,
  showName = '',
  hideName = '',
  header,
  open = false,
  disabled = false,
  className,
  classNameHeader,
  dataQaSelector,
}: Props) => {
  const [isOpen, setIsOpen] = useState(open);
  const collapseId = `collapsableSection-${name}`;
  const toggleImg = isOpen ? arrowUp : arrowDown;
  const toggleName = isOpen ? hideName : showName;

  useEffect(() => {
    if (isOpen !== open) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <div className={className} data-qa-selector={dataQaSelector}>
      <div className={cns(cn.header, classNameHeader)}>
        {header}
        <Button
          variant="link"
          aria-controls={collapseId}
          aria-expanded={isOpen}
          className={cns(cn.whiteSpace, cn.collapseButton, 'button-collapse', {
            [cn.disabled]: disabled,
          })}
          onClick={() => !disabled && setIsOpen(!isOpen)}
          data-qa-selector={`${collapseId}-button`}
        >
          {toggleName}{' '}
          <img
            src={toggleImg}
            alt={toggleName}
            className={cns({
              [cn.disabled]: disabled,
            })}
          />
        </Button>
      </div>
      <Collapse in={isOpen}>
        <div id={collapseId} className="pt-4">
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsableSection;
