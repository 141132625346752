import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateQualityCheckRefurbishmentItemArgs,
  RefurbishmentItemUpdateInput,
} from 'graphqlTypes';
import QUALITY_CHECK_REVIEW_V2 from './gql/qualityCheckReviewV2';
import UPDATE_QUALITY_CHECK_VEHICLE_ITEM from './gql/updateQualityCheckRefurbishmentItem';

const useUpdateQualityCheckRefurbishmentItem = (reviewId: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckRefurbishmentItem'>,
    MutationUpdateQualityCheckRefurbishmentItemArgs
  >(UPDATE_QUALITY_CHECK_VEHICLE_ITEM);

  const updateQualityCheckRefurbishmentItem = (
    itemId: string,
    refurbishmentItemUpdateInput: RefurbishmentItemUpdateInput,
  ) => {
    const refurbishmentItemUpdateInputFixed = Object.entries(
      refurbishmentItemUpdateInput,
    ).reduce<Record<string, any>>((acc, [key, value]) => {
      acc[key] = acc[key] === null ? undefined : value;
      return acc;
    }, {});

    return mutation({
      variables: {
        itemId,
        refurbishmentItemUpdateInput: refurbishmentItemUpdateInputFixed,
      },
      refetchQueries: [
        {
          query: QUALITY_CHECK_REVIEW_V2,
          variables: {
            id: reviewId,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return [updateQualityCheckRefurbishmentItem, options] as const;
};

export default useUpdateQualityCheckRefurbishmentItem;
