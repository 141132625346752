import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { OperationVariables, QueryOptions } from 'apollo-client';
import { DocumentNode } from 'graphql';

const usePromiseQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: Omit<QueryOptions<TVariables>, 'query' | 'variables'>,
) => {
  const client = useApolloClient();

  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
        ...options,
      }),
    [client],
  );
};

export default usePromiseQuery;
