import { useContext } from 'react';

import DynamicTranslateContext from './context';

function useDynamicTranslate() {
  const { loading, messages } = useContext(DynamicTranslateContext);
  return (key: string) => {
    return loading || !messages ? __('loading') : messages[key] || key;
  };
}

export default useDynamicTranslate;
