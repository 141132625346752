import { get } from 'lodash/fp';

import { isImperfectionService } from 'helpers/imperfections';
import { unitsFromMajorToMinor } from 'modules/currency';
import { getKey } from 'modules/i18n';
import { DamageFormData } from 'routes/RefurbishmentRoute/types';

export const damageFormToInput = (
  values: DamageFormData,
  hasSplitCost?: boolean,
) => {
  return {
    area:
      getKey(get(['carPart', 'section'], values)) ||
      get(['carPart', 'section'], values),
    subArea:
      getKey(get(['carPart', 'subSection'], values)) ||
      get(['carPart', 'subSection'], values),
    partName:
      getKey(get(['carPart', 'name'], values)) ||
      get(['carPart', 'name'], values),
    typeName: get(['typeName'], values),
    imageUrl: get(['imageUrl', '0', 'url'], values),
    name: get(['name', 'name'], values),
    type: get(['name', 'type'], values),
    budgetMinorUnits: unitsFromMajorToMinor(get(['budgetMajorUnits'], values)),
    ...(hasSplitCost && {
      sparePartCostMinorUnits: unitsFromMajorToMinor(
        get(['sparePartCostMajorUnits'], values),
      ),
      consumableCostMinorUnits: unitsFromMajorToMinor(
        get(['consumableCostMajorUnits'], values),
      ),
      laborTimeHours: get(['laborTimeHours'], values),
      laborRateMinorUnits: unitsFromMajorToMinor(
        get(['laborRateMajorUnits'], values),
      ),
    }),
    comment: get(['comment'], values) || null,
    isSecondaryWheel: get(['isSecondaryWheel'], values),
    cancelReason: null,
    cancelComment: null,
    displaySection: get(['displaySection'], values),
    ...(isImperfectionService(values.name?.name)
      ? {}
      : {
          partnerWorkshopConnectionId: get(
            ['partnerWorkshopConnectionId'],
            values,
          ),
        }),
  };
};
