import { Maybe, Scalars } from 'graphqlTypes';
import { sendReq } from 'modules/apiRest';

export default (
  file: File,
  formData?: Maybe<Scalars['Map']>,
  host?: Maybe<string>,
) =>
  sendReq(
    'UPLOAD_FILE',
    host,
    { ...formData, 'Content-Type': file.type },
    file,
  );
