import { components, SingleValueProps } from 'react-select';

export type CreateSingleValueParams = {
  renderSingleValue?: (
    children: React.ReactNode,
    optionData: {
      value: any;
    },
  ) => React.ReactNode;
};

const createSingleValue = ({ renderSingleValue }: CreateSingleValueParams) => (
  props: SingleValueProps<any>,
) => {
  const {
    data: { value },
    children,
  } = props;

  return (
    <components.SingleValue {...props}>
      {renderSingleValue ? renderSingleValue(children, { value }) : children}
    </components.SingleValue>
  );
};

export default createSingleValue;
