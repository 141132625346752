import React from 'react';
import { Col, Row } from 'react-bootstrap';
import cns from 'classnames';

import CheckButton from 'components/Buttons/CheckButton';
import Divider from 'components/Divider';
import DataItem from 'components/UI/DataItem';
import { Maybe, OptionalBooleanType } from 'graphqlTypes';

type Props = {
  label: string;
  value: Maybe<boolean> | Maybe<OptionalBooleanType>;
  qaSelector?: string;
  editDisabled: boolean;
  showDivider?: boolean;
  loading?: boolean;
  className?: string;
  dividerClassName?: string;
  required?: boolean;
  onAcceptSelect?: () => void;
  onDeclineSelect?: () => void;
};

const YesNoChecker = ({
  label,
  required,
  value,
  qaSelector,
  editDisabled,
  showDivider = true,
  loading = false,
  className,
  dividerClassName,
  onAcceptSelect,
  onDeclineSelect,
}: Props) => {
  let optionalBooleanValue = null;
  if (value !== null) {
    optionalBooleanValue = value
      ? OptionalBooleanType.Yes
      : OptionalBooleanType.No;
  }

  return (
    <div
      data-qa-selector={qaSelector}
      className={cns(['flex-grow-1 justify-content-between', className])}
    >
      <Row>
        <Col sm className="d-flex align-items-center">
          <DataItem.Label required={required}>{label}</DataItem.Label>
        </Col>
        <Col sm className="d-flex justify-content-end">
          <CheckButton
            value={optionalBooleanValue}
            editDisabled={editDisabled}
            loading={loading}
            displayNotApplicable={false}
            onAcceptSelect={onAcceptSelect}
            onDeclineSelect={onDeclineSelect}
          />
        </Col>
      </Row>
      {showDivider && (
        <Divider className={cns(dividerClassName, 'mx-0 my-3')} />
      )}
    </div>
  );
};

export default YesNoChecker;
