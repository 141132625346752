import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  Item,
  Mutation,
  MutationUpdateQualityCheckApprovalServiceItemArgs,
  RefurbishmentServiceState,
  ServiceItemPayload,
  ServiceItemUpdateInput,
} from 'graphqlTypes';
import { getRefurbishmentItemIdFromCache } from './common/getRefurbishmentItemIdFromCache';
import { ItemFragmentQCA } from './gql/fragments';
import QUALITY_CHECK_APPROVAL_ITEMS from './gql/qualityCheckApprovalItems';
import UPDATE_QUALITY_CHECK_APPROVAL_SERVICE_ITEM from './gql/updateQualityCheckApprovalServiceItem';

const useUpdateQualityCheckApprovalServiceItem = (reviewId?: string) => {
  const [mutation, options] = useMutation<
    Pick<Mutation, 'updateQualityCheckApprovalServiceItem'>,
    MutationUpdateQualityCheckApprovalServiceItemArgs
  >(UPDATE_QUALITY_CHECK_APPROVAL_SERVICE_ITEM);
  const { cache } = useApolloClient();

  const updateQualityCheckApprovalServiceItem = (
    itemId: string,
    serviceItemUpdateInput: ServiceItemUpdateInput,
  ) => {
    const item = cache.readFragment<Item>({
      id: `Item:${itemId}`,
      fragment: ItemFragmentQCA,
      fragmentName: 'ItemFragmentQCA',
    });

    const refurbishmentItemId = reviewId
      ? getRefurbishmentItemIdFromCache(cache, reviewId)
      : undefined;

    return mutation({
      variables: {
        itemId,
        serviceItemUpdateInput,
      },
      optimisticResponse: item
        ? {
            updateQualityCheckApprovalServiceItem: {
              ...item,
              data: {
                ...item.data,
                serviceState: serviceItemUpdateInput.state,
                stateComment: serviceItemUpdateInput.stateComment,
              } as ServiceItemPayload & {
                serviceState: RefurbishmentServiceState;
              },
            },
          }
        : undefined,
      refetchQueries: refurbishmentItemId
        ? [
            {
              query: QUALITY_CHECK_APPROVAL_ITEMS,
              variables: {
                ids: [refurbishmentItemId],
              },
            },
          ]
        : undefined,
    });
  };

  return [updateQualityCheckApprovalServiceItem, options] as const;
};

export default useUpdateQualityCheckApprovalServiceItem;
