import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import {
  PartnerWorkshopConnection,
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import { useLogger } from 'modules/logger';
import ProductionLocationForm, {
  ALL_LOCATIONS_VALUE,
  ProductionLocationFormData,
} from './ProductionLocationForm';

type Props = {
  userPartnerWorkshopConnections: UserPartnerWorkshopConnection[];
  userPartnerWorkshopConnectionItem: UserPartnerWorkshopConnection;
  loading: boolean;
  onChange(values: UserPartnerWorkshopConnectionInput): Promise<any>;
};

const EditProductionLocation = ({
  userPartnerWorkshopConnections,
  userPartnerWorkshopConnectionItem,
  loading,
  onChange,
}: Props) => {
  const { logError } = useLogger();
  const [show, setShow] = useState(false);

  const handleOnSubmit = async (values: ProductionLocationFormData) => {
    const isAllLocations = values.partnerLocations.includes(
      ALL_LOCATIONS_VALUE,
    );

    const currentItemConnectionIds =
      userPartnerWorkshopConnectionItem?.partnerWorkshopConnections
        ?.filter(
          (connection): connection is PartnerWorkshopConnection => !!connection,
        )
        .map(connection => connection.id) || [];
    const connectionsToAdd = values.partnerLocations.filter(
      id =>
        !currentItemConnectionIds.includes(id) && id !== ALL_LOCATIONS_VALUE,
    );
    const connectionsToRemove = currentItemConnectionIds.filter(
      id => !values.partnerLocations.includes(id),
    );

    const partnerWorkshopConnectionIds = userPartnerWorkshopConnections
      .filter(group => !group.isAllLocations)
      .flatMap(group =>
        group.partnerWorkshopConnections?.map(connection => connection?.id),
      )
      .filter((id): id is string => !!id)
      .filter(id => !connectionsToRemove.includes(id))
      .concat(connectionsToAdd)
      .concat(!isAllLocations ? values.partnerLocations || [] : []);

    const userMainWorkshopIds = userPartnerWorkshopConnections
      .filter(group => group.isAllLocations)
      .map(group => group.mainWorkshop.id)
      .filter(
        id =>
          isAllLocations ||
          id !== userPartnerWorkshopConnectionItem.mainWorkshop.id,
      )
      .concat(isAllLocations ? values.mainWorkshop?.id || [] : []);

    try {
      await onChange({
        partnerWorkshopConnectionIds: [
          ...new Set(partnerWorkshopConnectionIds),
        ],
        userMainWorkshopIds: [...new Set(userMainWorkshopIds)],
      });
      setShow(false);
    } catch (error) {
      logError({ error });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" data-qa-selector="edit" onClick={handleShow}>
        {__('action.edit')}
      </Button>

      <CustomModal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{__('workshopPartnerConnection.editTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show && (
            <ProductionLocationForm
              defaultValues={userPartnerWorkshopConnectionItem}
              currentConnections={[]}
              loading={loading}
              onCancel={handleClose}
              onSubmit={handleOnSubmit}
            />
          )}
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default EditProductionLocation;
