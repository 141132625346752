import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import cns from 'classnames';
import { isEqual } from 'lodash/fp';

import createClearIndicator from './createClearIndicator';
import createControl from './createControl';
import createMenu from './createMenu';
import createMultiValueContainer from './createMultiValueContainer';
import createOption from './createOption';

import cn from './ReactSelectInput.module.scss';

type ReactSelectInputProps<T extends FieldValues = FieldValues> = {
  name: ControllerProps<T>['name'];
  control: ControllerProps<T>['control'];
  defaultValue?: ControllerProps<T>['defaultValue'];
  rules?: ControllerProps<T>['rules'];
  options: ReactSelectProps['options'];
  placeholder?: ReactSelectProps['placeholder'];
  disabled?: ReactSelectProps['isDisabled'];
  onChange?: (values: any) => void;
  className?: ReactSelectProps['className'];
  inputId?: ReactSelectProps['inputId'];
  filterOption?: ReactSelectProps['filterOption'];
  closeMenuOnSelect?: ReactSelectProps['closeMenuOnSelect'];
  isClearable?: ReactSelectProps['isClearable'];
  isLoading?: ReactSelectProps['isLoading'];
  isMulti?: ReactSelectProps['isMulti'];
  isSearchable?: ReactSelectProps['isSearchable'];
  components?: ReactSelectProps['components'];
  styles?: ReactSelectProps['styles'];
  dataQa: string;
  dataQaOption: string;
  dataQaMultiValueContainer?: string;
  dataQaClearContainer?: string;
  isInvalid?: boolean;
};

/**
 * @deprecated use ReactSelectInput instead
 *
 * This control implements an old and wrong behavior. To set the default value of the form,
 * you need to pass an object with the label and the value, but internally only the value
 * is used. Also, the forms that use this components returns the object with the label
 * and value when submitted. The new ReactSelectInput only requires the value as
 * defaultValue and when submitting the form, it only returns the value.
 */
export const ReactSelectInputLegacy = <T extends FieldValues>({
  name,
  options = [],
  control,
  defaultValue,
  rules,
  dataQa,
  dataQaOption,
  dataQaMultiValueContainer,
  dataQaClearContainer,
  components,
  isInvalid,
  className,
  styles,
  disabled,
  onChange,
  ...rest
}: ReactSelectInputProps<T>) => {
  const replacedComponents = {
    ...components,
    Option: createOption({ dataQaOption }),
    Control: createControl(dataQa),
    Menu: createMenu(`${dataQa}-menu`),
  };

  if (dataQaMultiValueContainer) {
    replacedComponents.MultiValueContainer = createMultiValueContainer(
      dataQaMultiValueContainer,
    );
  }

  if (rest.isMulti && !dataQaMultiValueContainer) {
    throw new Error(
      'You must set dataQaMultiValueContainer if you set isMulti as true',
    );
  }

  if (dataQaClearContainer) {
    replacedComponents.ClearIndicator = createClearIndicator(
      dataQaClearContainer,
    );
  }

  const invalidClass = isInvalid ? cn.isInvalid : '';

  const valueFilter = (values: any[] | any) =>
    options[rest.isMulti ? 'filter' : 'find']((option: any) =>
      [values]?.flat()?.find(value => isEqual(value?.value, option.value)),
    );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { value, onChange: onFieldChange, ...field } }) => (
        <ReactSelect
          {...rest}
          {...field}
          options={options}
          components={replacedComponents}
          className={cns(className, cn.select, invalidClass)}
          classNamePrefix="react-select"
          styles={
            styles ?? {
              menuPortal: (existingStyles: any) => ({
                ...existingStyles,
                zIndex: 9999,
              }),
            }
          }
          menuPortalTarget={document.body}
          onChange={values => {
            onFieldChange(values);
            if (onChange) onChange(values);
          }}
          value={valueFilter(value)}
          defaultValue={valueFilter(value)}
          isDisabled={disabled}
        />
      )}
    />
  );
};
