import { useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationUpdateRefurbishmentStateAndTaskStateArgs,
  RefurbishmentState,
} from 'graphqlTypes';
import UPDATE_REFURBISHMENT_STATE_AND_TASK_STATE from './gql/updateRefurbishmentStateAndTaskState';

const useUpdateRefurbishmentStateAndTaskState = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'updateRefurbishmentStateAndTaskState'>,
    MutationUpdateRefurbishmentStateAndTaskStateArgs
  >(UPDATE_REFURBISHMENT_STATE_AND_TASK_STATE);

  const updateRefurbishmentStateAndTaskState = (
    taskId: string,
    refurbishmentId: string,
    refurbishmentState: RefurbishmentState,
  ) => {
    return mutation({
      variables: {
        taskId,
        refurbishmentId,
        refurbishmentState,
      },
    });
  };

  return [
    updateRefurbishmentStateAndTaskState,
    {
      data: data?.updateRefurbishmentStateAndTaskState,
      ...rest,
    },
  ] as const;
};

export default useUpdateRefurbishmentStateAndTaskState;
