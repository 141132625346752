import { ApolloCache } from 'apollo-cache';

import {
  ItemType,
  PhaseName,
  Query,
  QueryQualityCheckReviewArgs,
} from 'graphqlTypes';
import QUALITY_CHECK_REVIEW from '../gql/qualityCheckReviewV2';

export const getRefurbishmentItemIdFromCache = (
  cache: ApolloCache<object>,
  reviewId: string,
) => {
  const queryResult = cache.readQuery<
    Pick<Query, 'qualityCheckReview'>,
    QueryQualityCheckReviewArgs
  >({
    query: QUALITY_CHECK_REVIEW,
    variables: {
      id: reviewId,
    },
  });

  return queryResult?.qualityCheckReview.items
    ?.find(({ itemType }) => itemType === ItemType.Refurbishment)
    ?.values?.find(
      ({ phaseName }) => phaseName === PhaseName.QualityCheckReview,
    )?.id;
};
