import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import cns from 'classnames';

import { FormFilterNames } from './constants';

import cn from './Filter.module.scss';

type Props = {
  className?: string;
};

const ReworkOnlyCheck = ({ className }: Props) => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'ReworkOnlyCheck' must be used inside a 'Filter' component.",
    );
  }
  const { register } = formMethods;

  return (
    <div className={cns(cn.col, className)}>
      <Form.Check
        {...register(FormFilterNames.REWORK_ONLY)}
        id="hasRework"
        type="checkbox"
        label={__('refurbishment.overview.hasRework')}
        custom
        data-qa-selector="filterHasRework"
      />
    </div>
  );
};

export default ReworkOnlyCheck;
