import React from 'react';

import { ErrorPage } from 'components/ErrorPage';
import { ErrorType } from 'modules/errorHandler';
import useHasRole from '../hooks/useHasRole';
import { Role } from '../role';

type RoleAccessProps = {
  roles: Role | Role[];
  children: React.ReactNode;
};

const RoleAccess = ({ roles, children }: RoleAccessProps) => {
  const hasRoles = useHasRole(roles);
  if (hasRoles === undefined) return null;
  return hasRoles ? (
    <>{children}</>
  ) : (
    <ErrorPage type={ErrorType.AccessDenied} />
  );
};

export default RoleAccess;
