import gql from 'graphql-tag';

export default gql`
  query serviceTypes {
    serviceTypes {
      name
      type
    }
  }
`;
