import gql from 'graphql-tag';

export default gql`
  query brakePadConditions {
    brakePadConditions {
      id
      recordKey
      rangeStartInPercentInclusive
      rangeEndInPercentExclusive
    }
  }
`;
