import gql from 'graphql-tag';

import { PartnerWorkshopConnectionFragment } from './fragments';

export default gql`
  query refurbishmentActiveGroupedPartnerWorkshopConnections(
    $page: Int!
    $pageSize: Int!
    $refurbishmentId: UUID!
  ) {
    refurbishmentActiveGroupedPartnerWorkshopConnections(
      page: $page
      pageSize: $pageSize
      refurbishmentId: $refurbishmentId
    ) {
      items {
        groupedPartnerWorkshopConnections {
          ...PartnerWorkshopConnectionFragment
        }
      }
      pageInfo {
        page
        pageSize
        totalEntityCount
        totalPageCount
      }
    }
  }
  ${PartnerWorkshopConnectionFragment}
`;
