import gql from 'graphql-tag';

export default gql`
  mutation cancelRepairMethodImport($id: UUID!) {
    cancelRepairMethodImport(id: $id) {
      id
      type
      name
      uri
      state
      validationDebugLog
      validatedOn
      importedOn
      importDebugLog
      statistics {
        total
        created
        updated
        duplicated
        failed
      }
      createdBy {
        id
      }
      createdOn
      updatedBy {
        id
      }
      updatedOn
    }
  }
`;
