import { Table } from 'react-bootstrap';

import SortableTableHeader from 'components/SortableTableHeader';
import useMergeRefs from 'helpers/useMergeRefs';
import useResizeContainer from 'routes/MonitoringDashboardRoute/hooks/useResizeContainer';
import { SortField } from 'routes/MonitoringDashboardRoute/types';

import cn from './MonitoringTable.module.scss';

type Props = {
  tableContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
};

const MonitoringTable = ({ children, tableContainerRef }: Props) => {
  const containerRef = useResizeContainer();
  const mergedRef = useMergeRefs([containerRef, tableContainerRef]);

  return (
    <div className={cn.tableContainer} ref={mergedRef}>
      <Table className={cn.table}>
        <thead className={cn.header}>
          <tr>
            <th>&nbsp;</th>
            <th>
              <SortableTableHeader
                header={__('monitoring.vinStockNumber')}
                sortField={SortField.VIN}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.refurbishment')}
                sortField={SortField.REF_ID}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.serviceNameCarPartCode')}
                sortField={SortField.SERVICE_CODE}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.messageType')}
                sortField={SortField.MESSAGE_TYPE}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.timestamp')}
                sortField={SortField.CREATED_AT}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.status')}
                sortField={SortField.STATUS}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.tenant')}
                sortField={SortField.TENANT}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.errorCode')}
                sortField={SortField.ERROR_CODE}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.errorText')}
                sortField={SortField.ERROR_TEXT}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.direction')}
                sortField={SortField.DIRECTION}
              />
            </th>
            <th>
              <SortableTableHeader
                header={__('monitoring.reviewed')}
                sortField={SortField.REVIEWED}
              />
            </th>
            <th>{__('monitoring.comment')}</th>
            <th>{__('monitoring.content')}</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </div>
  );
};

export default MonitoringTable;
