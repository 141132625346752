import { useContext } from 'react';

import { getImageUrlWithoutPlaceholders, ImageSize } from 'helpers/images';
import ImageStorageContext from './context';

/**
 * App uploads images to S3 bucket but should read them from CDN because S3 bucket will be private.
 * The problem is that files in CDN doesn't appear immediately so it's needed to remember uploaded files
 * and use remembered files where possible
 */
function useAdImage() {
  const contextData = useContext(ImageStorageContext);
  if (!contextData) {
    throw new Error(
      'useAdImageStorage must be used within an ImageStorageProvider',
    );
  }
  const { urlToObjectUrl, storeUploadedImage, bucketName, cdn } = contextData;
  return {
    getImageUrl: (
      imageUrl?: string | null,
      size: ImageSize = ImageSize.ORIGINAL,
    ) => {
      if (imageUrl) {
        const objectUrl = urlToObjectUrl.get(imageUrl);
        if (objectUrl) {
          return objectUrl;
        }
      }

      const url = getImageUrlWithoutPlaceholders(imageUrl, size);
      if (!imageUrl) {
        return url;
      }

      const uuidPattern =
        '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';
      const regExp = new RegExp(
        `https://([a-z0-9\\-]+).s3.amazonaws.com/manual-upload/(${uuidPattern})/[a-z]+/([^/]+)`,
      );
      const match = imageUrl.match(regExp);
      if (match) {
        const imageBucketName = match[1];
        const adId = match[2];
        const file = match[3];
        if (imageBucketName === bucketName && cdn) {
          return `${cdn}/img/${adId}/${file}`;
        }
      }

      return url;
    },
    storeUploadedImage,
  };
}

export default useAdImage;
