import { RefurbishmentType } from 'graphqlTypes';

export const refurbishmentTypesTranslated = {
  [RefurbishmentType.Refurbishment]: __('refurbishmentType.refurbishment'),
  [RefurbishmentType.ClaimRepair]: __('refurbishmentType.claimRepair'),
  [RefurbishmentType.ReEntryCheck]: __('refurbishmentType.reEntryCheck'),
  [RefurbishmentType.RefurbishmentAtHandoverLocation]: __(
    'refurbishmentType.refurbAtHandoverLocation',
  ),
};
