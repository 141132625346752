import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import cns from 'classnames';

import ClickOutside from 'components/ClickOutside/ClickOutside';
import DatePickerInput from 'components/Form/DatePickerInput';
import { Maybe, RefurbishmentState } from 'graphqlTypes';
import { Role, useHasRole } from 'modules/acl';
import { useUpdateRefurbishmentBeautyShotsTakenOn } from 'modules/api/refurbishments';
import { formatDate } from 'modules/i18n';
import { useLogger } from 'modules/logger';
import { isReturningCarToAuto1 } from '../../../helpers/isReturningCarToAuto1';

import cn from '../VehicleHeader.module.scss';

type Props = {
  qualityCheckReviewId?: string;
  beautyShotsTakenOn?: Maybe<Date>;
  refurbishmentId: string;
  refurbishmentState: RefurbishmentState;
  className?: string;
};

type FormData = {
  beautyShotsTakenOnDate: Date;
};

const BeautyShotsTakenOn = ({
  qualityCheckReviewId,
  beautyShotsTakenOn,
  refurbishmentId,
  refurbishmentState,
  className,
}: Props) => {
  const { logError } = useLogger();
  const [
    updateRefurbishmentBeautyShotsTakenOn,
  ] = useUpdateRefurbishmentBeautyShotsTakenOn();
  const hasPhotographerRole = useHasRole(Role.PHOTOGRAPHER);
  const [editable, setEditable] = useState(false);

  const beautyShotsTakenOnDate = beautyShotsTakenOn
    ? new Date(beautyShotsTakenOn)
    : undefined;

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { beautyShotsTakenOnDate },
  });

  const handleUpdateRefurbishment = (value?: Date) => {
    try {
      updateRefurbishmentBeautyShotsTakenOn(
        refurbishmentId,
        value || null,
        qualityCheckReviewId,
      ).then(() => {
        if (value) {
          setValue('beautyShotsTakenOnDate', value);
        }
      });
    } catch (error) {
      logError({ error });
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateRefurbishment(event.target.checked ? new Date() : undefined);
  };

  const disabled =
    !hasPhotographerRole ||
    !beautyShotsTakenOnDate ||
    isReturningCarToAuto1(refurbishmentState);

  return (
    <div
      className={cns(className, cn.beautyShotsTakenOn, 'beautyShotsTakenOn')}
      data-qa-selector="beautyShotsTakenOn"
    >
      <Form.Check
        type="checkbox"
        id="beautyShotsTakenOnDate"
        name="beautyShotsTakenOnDate"
        custom
        label={__('refurbishment.header.beautyShotsTaken')}
        defaultChecked={beautyShotsTakenOnDate !== undefined}
        onChange={handleOnChange}
        disabled={
          !hasPhotographerRole || isReturningCarToAuto1(refurbishmentState)
        }
        data-qa-selector="beautyShotsTakenOnSelector"
        className={cns('font-weight-bold', cn.checkbox)}
      />
      <div className={cns('ml-3', cn.datePicker)}>
        {editable ? (
          <ClickOutside onClickOutside={() => setEditable(false)}>
            <DatePickerInput
              name="beautyShotsTakenOnDate"
              control={control}
              errors={errors}
              dataQaSelector="beautyShotsTakenOnDatePicker"
              onSelect={newValue => {
                handleUpdateRefurbishment(newValue);
                setEditable(false);
              }}
              disabled={
                !editable ||
                !beautyShotsTakenOnDate ||
                isReturningCarToAuto1(refurbishmentState)
              }
              maxDate={new Date()}
              open
            />
          </ClickOutside>
        ) : (
          <div className="d-flex">
            <div
              className={cn.value}
              data-qa-selector="beautyShotsTakenOnValue"
            >
              {beautyShotsTakenOnDate ? formatDate(beautyShotsTakenOnDate) : ''}
            </div>

            {!disabled && (
              <Button
                className="ml-1"
                onClick={() => setEditable(true)}
                data-qa-selector="beautyShotsTakenOnEdit"
                variant="link"
                type="button"
              >
                ({__('refurbishment.header.beautyShot.edit')})
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BeautyShotsTakenOn;
