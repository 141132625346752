import { flow, fromPairs, map, toPairs } from 'lodash/fp';
import { createSelector } from 'reselect';

export const select = createSelector;

export const or = <P>(valueB: P) => <Q>(valueA: Q): P | Q => valueA || valueB;

export const branch = <P, T>(fn1: (value: T) => P, fn2: (value: T) => P) => (
  value: T,
) => fn1(value) ?? fn2(value);

export const struct = <S, T>(mapping: Partial<Record<keyof T, Function>>) => (
  value?: S,
) =>
  flow(
    toPairs,
    map(([key, fn]: [string, Function]): [string, any] => [key, fn(value)]),
    fromPairs,
  )(mapping);

export const not = (value: any): boolean => !value;

export const getArr = (arr: any[]) => (value: any) => map(fn => fn(value), arr);

export const arrayFromMap = <K, V>(arg: Map<K, V>) => Array.from(arg);

export const mapFromArray = <K, V>(arg: [K, V][]) => new Map(arg);

// For debug purposes
export const log = <T>(value: T): T => {
  // eslint-disable-next-line no-console
  console.log(value);
  return value;
};

export const logOther = (other: any) => <T>(value: T): T => {
  // eslint-disable-next-line no-console
  console.log(other);
  return value;
};
