import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import GET_INFORM_TENANTS from './gql/getInformTenants';

const useGetInformTenants = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'getInformTenants'>, null>(
    GET_INFORM_TENANTS,
  );

  return {
    data: data?.getInformTenants,
    ...rest,
  };
};

export default useGetInformTenants;
