import gql from 'graphql-tag';

export default gql`
  mutation assignUserPartnerWorkshopConnectionPermissions(
    $userId: UUID!
    $input: UserPartnerWorkshopConnectionInput!
  ) {
    assignUserPartnerWorkshopConnectionPermissions(
      userId: $userId
      input: $input
    )
  }
`;
