import React, { useEffect, useReducer, useRef } from 'react';
import { Form, Table } from 'react-bootstrap';

import Text from 'components/UI/Text';
import { useGetGroups } from 'modules/api/groups';

const reducer = (state: string[], target: HTMLInputElement) => {
  if (target.checked) {
    return [...state, target.id];
  }
  return state.filter(id => id !== target.id);
};

interface Props {
  initialData?: string[] | null;
  onChange(groups: string[]): void;
}

const Groups = ({ initialData = [], onChange }: Props) => {
  const { data } = useGetGroups();
  const [state, dispatch] = useReducer(reducer, initialData || []);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    onChange(state);
  }, [state]);

  return (
    <>
      <Text size="l" weight="bold" tag="h3">
        {__('user.detail.groups')}
      </Text>
      <Table borderless>
        <tbody>
          {!!data &&
            data.map(({ id, name, description }) => (
              <tr
                key={id}
                className="border-bottom"
                data-qa-selector={`row_${id}`}
              >
                <td className="w-25" data-qa-selector="group_name">
                  <Form.Check
                    id={id}
                    type="checkbox"
                    label={name}
                    custom
                    checked={state.includes(id)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(event.target)
                    }
                    data-qa-selector={`group_${id}`}
                  />
                </td>
                <td className="w-75" data-qa-selector="group_description">
                  {description}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default Groups;
