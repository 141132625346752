import { createContext } from 'react';

export type Messages = { [key: string]: string };

export type MessagesModule = { default: Messages };

type ContextData = {
  loading: boolean;
  messages: Messages | null;
};

const DynamicTranslateContext = createContext<ContextData>({
  loading: false,
  messages: {},
});

export default DynamicTranslateContext;
