import { RefurbishmentState } from 'graphqlTypes';

export const isReturningCarToAuto1 = (
  refurbishmentState: RefurbishmentState,
): boolean => {
  return (
    refurbishmentState === RefurbishmentState.FlagForReturnToAuto1 ||
    refurbishmentState === RefurbishmentState.ReturnToAuto1Candidate
  );
};
