import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import { ACL, Role } from 'modules/acl';
import { useGetAllLocations } from 'modules/api/locations';
import { useGetServiceTypes } from 'modules/api/services';
import CostTableList from './components/CostTableList';
import CostTableSearch from './components/CostTableSearch';

const CostTableRoute = () => {
  const [addNewItem, setAddNewItem] = useState(false);
  const {
    data: allLocations,
    loading: allLocationsLoading,
  } = useGetAllLocations();
  const {
    data: allServiceTypes,
    loading: allServiceTypesLoading,
  } = useGetServiceTypes();

  if (
    allLocationsLoading ||
    allServiceTypesLoading ||
    !allLocations ||
    !allServiceTypes
  ) {
    return <LoadingIndicator />;
  }

  return (
    <PageContainer fluid>
      <Helmet>
        <title>{__('costTable.title')}</title>
      </Helmet>
      <PageHeader
        title={__('costTable.title')}
        subTitle={__('costTable.subTitle')}
        actions={
          <ACL roles={Role.COST_TABLE_WRITE}>
            <Button
              variant="outline-primary"
              onClick={() => setAddNewItem(true)}
            >
              {__('costTable.addVehicleService')}
            </Button>
          </ACL>
        }
      />
      <CostTableSearch
        allLocations={allLocations}
        allServiceTypes={allServiceTypes}
      />
      <CostTableList
        addNewItem={addNewItem}
        clearAddNewItem={() => setAddNewItem(false)}
        allServiceTypes={allServiceTypes}
      />
    </PageContainer>
  );
};

export default CostTableRoute;
