import { SparePartStatus } from 'graphqlTypes';
import { SparePartValue } from 'routes/RefurbishmentRoute/types';

type Params = {
  sparePart?: SparePartValue | null;
  newSparePartCost?: number | null;
  currentSparePartCost?: number | null;
  serviceComment?: string | null;
};

const calculateSparePart = ({
  sparePart,
  newSparePartCost,
  currentSparePartCost,
  serviceComment,
}: Params): SparePartValue | null => {
  if (newSparePartCost && (!currentSparePartCost || !sparePart)) {
    return {
      status: SparePartStatus.SparePartsNotOrdered,
      comment: serviceComment ?? null,
      lastStatusUpdatedOn: null,
    };
  }

  if (!sparePart || !newSparePartCost) return null;

  return {
    ...sparePart,
    comment: serviceComment ?? null,
  };
};

export default calculateSparePart;
