import { useQuery } from '@apollo/react-hooks';

import { Query } from 'graphqlTypes';
import REFURBISHMENT_TASK_ITEMS_INCLUDING_COSTS from './gql/refurbishmentTaskItemsIncludingCosts';

const useRefurbishmentTaskItemsIncludingCosts = (
  taskId: string = '',
  userCanReadCosts: boolean | undefined,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'refurbishmentTaskItemsIncludingCosts'>
  >(REFURBISHMENT_TASK_ITEMS_INCLUDING_COSTS, {
    variables: { taskId },
    skip: !taskId || userCanReadCosts !== true,
    fetchPolicy: 'cache-and-network',
  });

  return [
    {
      data: data?.refurbishmentTaskItemsIncludingCosts,
      ...rest,
    },
  ] as const;
};

export default useRefurbishmentTaskItemsIncludingCosts;
