import { Control } from 'react-hook-form';

import ReactSelectInput from 'components/Form/ReactSelectInput';
import { useGetInformMessageTypes } from 'modules/api/informMessage';
import { FormType } from './types';

type Props = {
  control: Control<FormType, any>;
  name: keyof FormType;
  form: string;
};

const MessageType = ({ control, name, form }: Props) => {
  const { data, loading } = useGetInformMessageTypes();

  const options = [
    { label: __('monitoring.filter.allOption'), value: '' },
    ...(data?.map(option => ({
      label: option,
      value: option,
    })) || []),
  ];

  return (
    <ReactSelectInput
      control={control}
      name={name}
      form={form}
      options={options}
      isLoading={loading}
      styles={{
        menu: base => ({ ...base, width: 'max-content', minWidth: '100%' }),
      }}
      dataQa="messageType"
      dataQaOption="messageTypeOption"
    />
  );
};

export default MessageType;
