import { Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import ImportHistory from './ImportHistory';

type Props = {
  isModalVisible: boolean;
  closeModal: () => void;
};

const ImportHistoryModal = ({ isModalVisible, closeModal }: Props) => {
  return (
    <CustomModal
      size="xl"
      show={isModalVisible}
      onHide={closeModal}
      qaSelector="repairCostImportHistoryModal"
    >
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>{__('repairCost.importHistoryModalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{isModalVisible && <ImportHistory />}</Modal.Body>
    </CustomModal>
  );
};

export default ImportHistoryModal;
