import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import { vinStockRefRegExp } from 'components/Filter/helpers';
import DateRangePickerInput from 'components/Form/DateRangePickerInput';
import FieldError from 'components/Form/FieldError';
import SelectInput from 'components/Form/SelectInput';
import LoadingIndicator from 'components/LoadingIndicator';
import { InformMessageFilterInput } from 'graphqlTypes';
import {
  cleanValuesToInformMessageFilterInput,
  cleanValuesToQueryStringValues,
  formValuesToCleanValues,
  searchParamsToFormValues,
} from './mapping';
import MessageType from './MessageType';
import { directionOptions, reviewedOptions } from './options';
import StatusOptions from './StatusOptions';
import Tenant from './Tenant';
import { FormType } from './types';

import cn from './MonitoringFilter.module.scss';

type Props = {
  loading: boolean;
  checkBoxArea: React.ReactNode;
  retryArea: React.ReactNode;
  filterButtonContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  onChange: (values: InformMessageFilterInput) => void;
};

const MonitoringFilter = ({
  loading,
  checkBoxArea,
  retryArea,
  filterButtonContainerRef,
  onChange,
}: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

  const { sortField, sortDir } = searchParams;
  const sort = {
    sortField,
    sortDir,
  };

  const form = 'monitoring-filter-form';

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: async () => searchParamsToFormValues(searchParams),
  });

  useEffect(() => {
    const formValues = searchParamsToFormValues(searchParams);
    reset(formValues);

    const cleanValues = formValuesToCleanValues(formValues);
    const informMessageFilterInput = cleanValuesToInformMessageFilterInput(
      cleanValues,
    );
    onChange(informMessageFilterInput);
  }, [search]);

  const onSubmitHandler = (values: FormType) => {
    const cleanValues = formValuesToCleanValues(values);
    const cleanQueryStringValues = cleanValuesToQueryStringValues(cleanValues);
    const informMessageFilterInput = cleanValuesToInformMessageFilterInput(
      cleanValues,
    );

    onChange(informMessageFilterInput);
    navigate({
      pathname,
      search: qs.stringify({ ...cleanQueryStringValues, ...sort }),
    });
  };

  const clearFilter = () => {
    onChange({});
    navigate({
      pathname,
      search: qs.stringify(sort),
    });
  };

  return (
    <>
      <tr data-qa-selector="filter">
        <td>&nbsp;</td>
        <td>
          <Form.Control
            {...register('vinOrStockNumber', {
              pattern: {
                value: vinStockRefRegExp,
                message: __('refurbishment.overview.wrongVinStockRefNumber'),
              },
            })}
            className={cn.searchInput}
            placeholder={__('monitoring.filter.vinOrStockNumber')}
            form={form}
            data-qa-selector="vinOrStockNumber"
          />
          {!!errors && <FieldError name="vinOrStockNumber" errors={errors} />}
        </td>
        <td>
          <Form.Control
            {...register('refId')}
            placeholder={__('monitoring.filter.refId')}
            form={form}
            data-qa-selector="refId"
          />
        </td>
        <td>
          <Form.Control
            {...register('serviceCode')}
            placeholder={__('monitoring.filter.serviceCode')}
            form={form}
            data-qa-selector="serviceCode"
          />
        </td>
        <td className={cn.messageType}>
          <MessageType control={control} name="messageType" form={form} />
        </td>
        <td className={cn.createdDate}>
          <DateRangePickerInput
            control={control}
            name="createdDate"
            maxDate={new Date()}
            errors={errors}
            form={form}
            dataQaSelector="createdDate"
          />
        </td>
        <td className={cn.status}>
          <StatusOptions control={control} name="status" form={form} />
        </td>
        <td className={cn.tenant}>
          <Tenant control={control} name="tenant" form={form} />
        </td>
        <td>
          <Form.Control
            {...register('errorCode')}
            placeholder={__('monitoring.filter.errorCode')}
            form={form}
            data-qa-selector="errorCode"
          />
        </td>
        <td>
          <Form.Control
            {...register('errorText')}
            placeholder={__('monitoring.filter.errorText')}
            form={form}
            data-qa-selector="errorText"
          />
        </td>
        <td className={cn.direction}>
          <SelectInput
            control={control}
            name="direction"
            options={directionOptions}
            dataQa="direction"
            dataQaOption="directionOption"
            form={form}
          />
        </td>
        <td>
          <SelectInput
            control={control}
            name="reviewed"
            options={reviewedOptions}
            dataQa=""
            dataQaOption=""
            form={form}
          />
        </td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr className={cn.actionArea}>
        <td>{checkBoxArea}</td>
        <td>{retryArea}</td>
        <td colSpan={11}>
          {loading && <LoadingIndicator className={cn.loadingIndicator} />}
        </td>
        <td>
          <div
            ref={filterButtonContainerRef}
            className={cn.containerFilterButtons}
          >
            <form onSubmit={handleSubmit(onSubmitHandler)} id={form}>
              <Button
                variant="primary"
                type="submit"
                data-qa-selector="filter_button"
              >
                {__('action.filter')}
              </Button>
              <Button
                variant="link"
                onClick={clearFilter}
                data-qa-selector="reset"
              >
                {__('action.reset')}
              </Button>
            </form>
          </div>
        </td>
      </tr>
    </>
  );
};

export default MonitoringFilter;
