import cns from 'classnames';

import Text from 'components/UI/Text';
import { CurrencyCode } from 'graphqlTypes';
import { unitsFromMinorToMajor } from 'modules/currency';
import { formatCurrency } from 'modules/i18n';

import cn from '../VehicleHeader.module.scss';

type Props = {
  actualGrossProfitMinorUnits: number;
  targetGrossProfitMinorUnits: number;
  minimalGrossProfitMinorUnits: number;
  currencyCode: CurrencyCode;
  className?: string;
};

const GrossProfit = ({
  actualGrossProfitMinorUnits,
  targetGrossProfitMinorUnits,
  minimalGrossProfitMinorUnits,
  currencyCode,
  className,
}: Props) => {
  if (
    isNaN(actualGrossProfitMinorUnits) ||
    isNaN(targetGrossProfitMinorUnits) ||
    isNaN(minimalGrossProfitMinorUnits)
  ) {
    return null;
  }

  const deltaTargetMinorUnits =
    actualGrossProfitMinorUnits - targetGrossProfitMinorUnits;
  const deltaMinimumMinorUnits =
    actualGrossProfitMinorUnits - minimalGrossProfitMinorUnits;

  const actualGrossProfit = formatCurrency(
    unitsFromMinorToMajor(actualGrossProfitMinorUnits),
    currencyCode,
  );
  const deltaTarget = formatCurrency(
    unitsFromMinorToMajor(deltaTargetMinorUnits),
    currencyCode,
  );
  const deltaMinimum = formatCurrency(
    unitsFromMinorToMajor(deltaMinimumMinorUnits),
    currencyCode,
  );

  return (
    <div className={cns(className, cn.grossProfit, 'grossProfit')}>
      <div>
        <Text>{__('vehicleHeader.actualGP')}: </Text>
        <Text
          weight="bold"
          data-qa-selector="actualGrossProfit"
          color={actualGrossProfitMinorUnits >= 0 ? 'success' : 'danger'}
        >
          {actualGrossProfit}
        </Text>
      </div>
      <div>
        <Text>{__('vehicleHeader.deltaWithTargetGP')}: </Text>
        <Text
          weight="bold"
          data-qa-selector="deltaTargetGrossProfit"
          color={deltaTargetMinorUnits >= 0 ? 'success' : 'danger'}
        >
          {deltaTarget}
        </Text>
      </div>
      <div>
        <Text>{__('vehicleHeader.deltaWithMinGP')}: </Text>
        <Text
          weight="bold"
          data-qa-selector="deltaTargetMinimumGrossProfit"
          color={deltaMinimumMinorUnits >= 0 ? 'success' : 'danger'}
        >
          {deltaMinimum}
        </Text>
      </div>
    </div>
  );
};

export default GrossProfit;
