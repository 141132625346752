import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIndex } from 'lodash/fp';

import { ProgressItem, ProgressResult } from '../types';

const FOCUSED_TAB_KEY = 'tab';

const findLastStep = (progressList: [string, ProgressItem][]) =>
  findIndex(([, { total, completed }]) => total > completed, progressList);

interface UseStep<T extends string> {
  stepComponents: Record<string, any>;
  progress: ProgressResult<T>;
  initialStepUsesProgress?: boolean;
  onStepChange?(stepKey: string): void;
  onBeforeStepChange?(oldStepKey: string, newStepKey: string): boolean;
}

export default <T extends string>({
  stepComponents,
  progress,
  initialStepUsesProgress = true,
  onStepChange,
  onBeforeStepChange,
}: UseStep<T>) => {
  const progressList = Array.from(progress);
  const lastStep = findLastStep(progressList);
  const maxStepIndex = progressList.length - 1;
  const initialStep = initialStepUsesProgress
    ? Math.min(maxStepIndex, Math.max(lastStep, 0))
    : 0;

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const focusedTab = searchParams.get(FOCUSED_TAB_KEY);
  const focusedStepIndex = progressList.findIndex(
    ([key]) => key === focusedTab,
  );
  const focusedStep = focusedStepIndex >= 0 ? focusedStepIndex : undefined;

  const [step, setStep] = useState(focusedStep ?? initialStep);

  const itemKey = progressList?.[step]?.[0] || progressList[0][0];
  const stepName = progress.get(itemKey)?.name;
  const items = progress.get(itemKey)?.items;
  const StepComponent = stepComponents[itemKey];

  const setNewStep = (newStep: number) => {
    if (onBeforeStepChange) {
      const newItemKey = progressList?.[newStep]?.[0] || progressList[0][0];
      if (!onBeforeStepChange(itemKey, newItemKey)) {
        return;
      }
    }
    setStep(newStep);
    if (onStepChange) onStepChange(itemKey);

    if (focusedTab) {
      searchParams.delete(FOCUSED_TAB_KEY);
      navigate(
        {
          pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
  };

  const goToPrevStep = async () => {
    if (step > 0) {
      setNewStep(step - 1);
    }
  };

  const goToNextStep = async () => {
    if (step < maxStepIndex) {
      setNewStep(step + 1);
    }
  };

  const goToCustomStep = async (newStep: number) => {
    if (newStep >= 0 && newStep <= maxStepIndex) {
      setNewStep(newStep);
    }
  };

  return {
    StepComponent,
    currentStep: step,
    currentStepName: stepName,
    currentSliceKey: itemKey,
    currentStepItems: items,
    goToCustomStep,
    goToNextStep,
    goToPrevStep,
    isFirstStep: step === 0,
    isLastStep: step >= maxStepIndex,
    isCompletedStep: lastStep > step || lastStep === -1,
  };
};
