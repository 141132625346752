import { ApolloCache } from 'apollo-cache';

import {
  ItemType,
  PhaseName,
  Query,
  QueryQualityCheckApprovalArgs,
} from 'graphqlTypes';
import QUALITY_CHECK_APPROVAL from '../gql/qualityCheckApproval';

export const getRefurbishmentItemIdFromCache = (
  cache: ApolloCache<object>,
  reviewId: string,
) => {
  const queryResult = cache.readQuery<
    Pick<Query, 'qualityCheckApproval'>,
    QueryQualityCheckApprovalArgs
  >({
    query: QUALITY_CHECK_APPROVAL,
    variables: {
      id: reviewId,
    },
  });

  return queryResult?.qualityCheckApproval.items
    ?.find(({ itemType }) => itemType === ItemType.Refurbishment)
    ?.values?.find(
      ({ phaseName }) => phaseName === PhaseName.QualityCheckApproval,
    )?.id;
};
