import React from 'react';
import { Helmet } from 'react-helmet';

import PageContainer from 'components/Layout/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import { ACL, Role } from 'modules/acl';
import AddPartner from './components/AddPartner';
import PartnerSearch from './components/PartnerSearch';
import PartnersTable from './components/PartnerSearchTable';

const PartnerRoute = () => {
  return (
    <PageContainer fluid>
      <Helmet>
        <title>{__('partners.title')}</title>
      </Helmet>
      <PageHeader
        title={__('partners.title')}
        subTitle={__('partners.subTitle')}
        actions={
          <ACL roles={Role.PARTNER_WRITE}>
            <AddPartner />
          </ACL>
        }
      />
      <PartnerSearch />
      <PartnersTable />
    </PageContainer>
  );
};

export default PartnerRoute;
