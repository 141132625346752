import React, { useContext } from 'react';

import useDeveloperModeEnabled from 'modules/developerMode/useDeveloperModeEnabled';
import { FeatureFlag } from 'modules/feature';
import DeveloperModeContext from '../../context';

const flagReg = /^REACT_APP_ENABLE_(?<flag>[A-Z_]*)/;
const flagAcronyms = Object.values(FeatureFlag)
  .map(flag => ({
    key: flag,
    acronym: flag
      .match(flagReg)
      ?.groups?.flag.split('_')
      .map(word => word[0])
      .join(''),
  }))
  .filter(
    (flag?: {
      key: FeatureFlag;
      acronym?: string;
    }): flag is { key: FeatureFlag; acronym?: string } => !!flag?.acronym,
  );

const DeveloperModeToggle = () => {
  const { flagsStatus, switchFlag } = useContext(DeveloperModeContext);
  const developerModeEnabled = useDeveloperModeEnabled();

  if (!developerModeEnabled) return null;

  return (
    <div className="d-flex">
      {flagAcronyms.map((flag, index) => (
        <span style={{ opacity: 0.3 }} key={index}>
          {index ? '| ' : ''}
          <span
            onClick={() => switchFlag(flag.key)}
            onKeyPress={() => switchFlag(flag.key)}
            role="button"
            tabIndex={index}
            style={{ cursor: 'pointer' }}
            className="text-monospace text-uppercase mr-2"
            data-qa-selector={flag.key}
            title={flag.key}
          >
            {flag.acronym}: {flagsStatus[flag.key] ? 'ON' : 'OFF'}
          </span>
        </span>
      ))}
    </div>
  );
};

export default DeveloperModeToggle;
