import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import FormButtonModal from 'components/Form/FormButtonModal';
import CustomModal from 'components/UI/CustomModal';
import Text from 'components/UI/Text';
import { QueryActiveGroupedPartnerWorkshopConnectionsArgs } from 'graphqlTypes';
import { useRemovePartnerWorkshopConnections } from 'modules/api/partnerWorkshop';
import { useLogger } from 'modules/logger';
import { PartnerWorkshopItem } from '../../types';

type Props = {
  item: PartnerWorkshopItem;
  partnerWorkshopConnectionArgs: QueryActiveGroupedPartnerWorkshopConnectionsArgs;
};

const DeletePartnerWorkshop = ({
  item,
  partnerWorkshopConnectionArgs,
}: Props) => {
  const { logError } = useLogger();
  const [show, setShow] = useState(false);

  const [removeConnection, { loading }] = useRemovePartnerWorkshopConnections();

  const handleClose = () => setShow(false);

  const handleConfirm = async () => {
    const removedPartnerIds = item.repairPartners.map(
      partner => partner.idConnection,
    );

    try {
      await removeConnection(removedPartnerIds, partnerWorkshopConnectionArgs);
    } catch (error) {
      logError({ error });
    } finally {
      setShow(false);
    }
  };

  return (
    <div>
      <Button
        variant="link"
        onClick={() => setShow(true)}
        data-qa-selector="delete_connections"
      >
        {__('action.delete')}
      </Button>

      <CustomModal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {__('partnerWorkshopConnections.deleteConnections')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text className="mb-4" size="l" weight="bold" tag="p">
            {__('partnerWorkshopConnections.deleteConnectionsConfirmation')}
          </Text>
          <Table size="sm">
            <thead>
              <tr>
                <th>{__('partnerWorkshopConnections.mainWorkshop')}</th>
                <th>{__('partnerWorkshopConnections.repairWorkshop')}</th>
                <th>{__('partnerWorkshopConnections.partners')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> {item.mainWorkshop.name}</td>
                <td>{item.repairWorkshop.name}</td>
                <td>
                  <ul className="pl-3">
                    {item.repairPartners.map(e => (
                      <li key={e.name}>{e.name}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
          <FormButtonModal>
            <Button
              variant="link"
              onClick={handleClose}
              data-qa-selector="cancel_delete_connection"
            >
              {__('action.cancel')}
            </Button>
            <Button
              variant="secondary"
              onClick={handleConfirm}
              type="submit"
              disabled={loading}
              className="btn-wide ml-4"
              data-qa-selector="confirm_delete_connection"
            >
              {__('action.confirm')}
            </Button>
          </FormButtonModal>
        </Modal.Body>
      </CustomModal>
    </div>
  );
};

export default DeletePartnerWorkshop;
