import { get, map } from 'lodash/fp';

import { DamageType } from 'graphqlTypes';
import { getKey, getTranslation } from 'modules/i18n';

const getDamageTypesOptions = (damageTypes?: DamageType[]) =>
  map(
    damage => ({
      label: getTranslation(get(['name'], damage)),
      value: getKey(get(['name'], damage)),
    }),
    damageTypes,
  );

export default getDamageTypesOptions;
