import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import TIRE_WIDTHS from './gql/tireWidths';

const useTireWidths = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'tireWidths'>, null>(
    TIRE_WIDTHS,
  );

  return {
    data: get('tireWidths', data),
    ...rest,
  };
};

export default useTireWidths;
