import React from 'react';

import Text from '../Text';

const size: Record<string, 'm' | 'l' | 'xl'> = {
  h1: 'xl',
  h2: 'l',
  h3: 'm',
};

const color: Record<string, 'primary' | 'body'> = {
  h1: 'primary',
  h2: 'body',
  h3: 'body',
};

interface Props {
  children: React.ReactNode;
  level: 'h1' | 'h2' | 'h3';
  className?: string;
}

const Title = ({ children, level, className }: Props) => {
  return (
    <Text
      tag={level}
      size={size[level]}
      color={color[level]}
      weight="bold"
      className={className}
    >
      {children}
    </Text>
  );
};

export default Title;
