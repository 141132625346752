import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  Mutation,
  MutationRemoveRefurbishmentPartnerWorkshopConnectionArgs,
  RefurbishmentDetails,
} from 'graphqlTypes';
import { RefurbishmentDetailsPartnerWorkshopConnectionsFragment } from './gql/fragments';
import REMOVE_REFURBISHMENT_PARTNER_WORKSHOP_CONNECTION from './gql/removeRefurbishmentPartnerWorkshopConnection';

const useRemoveRefurbishmentPartnerWorkshopConnection = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'removeRefurbishmentPartnerWorkshopConnection'>,
    MutationRemoveRefurbishmentPartnerWorkshopConnectionArgs
  >(REMOVE_REFURBISHMENT_PARTNER_WORKSHOP_CONNECTION);
  const { cache } = useApolloClient();

  const removeRefurbishmentPartnerWorkshopConnection = (
    refurbishmentId: string,
    partnerWorkshopConnectionId: string,
  ) =>
    mutation({
      variables: { refurbishmentId, partnerWorkshopConnectionId },
      update: (client, result) => {
        if (!result.data?.removeRefurbishmentPartnerWorkshopConnection) return;

        const refurbishment = cache.readFragment<RefurbishmentDetails>({
          id: `RefurbishmentDetails:${refurbishmentId}`,
          fragment: RefurbishmentDetailsPartnerWorkshopConnectionsFragment,
          fragmentName:
            'RefurbishmentDetailsPartnerWorkshopConnectionsFragment',
        });

        if (refurbishment) {
          cache.writeFragment({
            id: `RefurbishmentDetails:${refurbishmentId}`,
            fragment: RefurbishmentDetailsPartnerWorkshopConnectionsFragment,
            fragmentName:
              'RefurbishmentDetailsPartnerWorkshopConnectionsFragment',
            data: {
              partnerWorkshopConnections:
                refurbishment.partnerWorkshopConnections?.filter(
                  connection => connection.id !== partnerWorkshopConnectionId,
                ) || [],
              __typename: 'RefurbishmentDetails',
            },
          });
        }
      },
    });

  return [
    removeRefurbishmentPartnerWorkshopConnection,
    {
      data: data?.removeRefurbishmentPartnerWorkshopConnection,
      ...rest,
    },
  ] as const;
};

export default useRemoveRefurbishmentPartnerWorkshopConnection;
