import React from 'react';
import cns from 'classnames';

import cn from './Text.module.scss';

export type TextProps = {
  children: React.ReactNode;
  className?: string;
  size?: 's' | 'm' | 'l' | 'xl';
  color?: 'body' | 'primary' | 'success' | 'muted' | 'highlight' | 'danger';
  weight?: 'light' | 'normal' | 'bold';
  tag?: 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4';
  decoration?: 'none' | 'line-through' | 'underline';
  qaSelector?: string;
  qaValue?: string;
};

const Text = ({
  children,
  className,
  size = 'm',
  color = 'body',
  weight = 'normal',
  tag: Tag = 'span',
  decoration = 'none',
  qaSelector,
  qaValue,
}: TextProps) => {
  return (
    <Tag
      className={cns(
        className,
        cn[size],
        cn[color],
        cn[weight],
        cn[decoration],
      )}
      data-qa-selector={qaSelector}
      data-qa-value={qaValue}
    >
      {children}
    </Tag>
  );
};

export default Text;
