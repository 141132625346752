import React, { memo } from 'react';

interface Props {
  className?: string;
}

export default memo(({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1.3em"
    height="1em"
    viewBox="0 0 26 20"
  >
    <g fill="currentColor">
      <path d="M24.333 3.333H19.61L18.603.65a1 1 0 00-.936-.649H8.333a1 1 0 00-.936.649L6.39 3.333H1.667C.747 3.333 0 4.08 0 5v13.333C0 19.253.746 20 1.667 20h22.666c.92 0 1.667-.746 1.667-1.667V5c0-.92-.746-1.667-1.667-1.667zM13 4.467a6.34 6.34 0 016.333 6.333A6.34 6.34 0 0113 17.133 6.34 6.34 0 016.667 10.8 6.34 6.34 0 0113 4.467z" />
      <path d="M8.667 11.8A4.338 4.338 0 0013 16.133a4.338 4.338 0 004.333-4.333A4.338 4.338 0 0013 7.467 4.338 4.338 0 008.667 11.8z" />
    </g>
  </svg>
));
