import { Pagination as BPagination } from 'react-bootstrap';
import { range } from 'lodash/fp';

interface Props {
  page: number;
  totalPageCount: number;
  setPage: (page: number) => void;
}

const MAX_BUTTONS = 5;

const Pagination = ({ page, totalPageCount, setPage }: Props) => {
  const half = Math.floor(MAX_BUTTONS / 2);
  const start = Math.max(page - half, 0);
  const end = Math.min(page + half + 1, totalPageCount);
  const buttons = range(start, end);

  const prev = (step: number) => {
    setPage(Math.max(page - step, 0));
  };
  const next = (step: number) => {
    setPage(Math.min(page + step, totalPageCount - 1));
  };

  return (
    <>
      <BPagination>
        <BPagination.First
          data-qa-selector="paginationButtonFirst"
          onClick={() => setPage(0)}
        />
        <BPagination.Prev
          onClick={() => prev(1)}
          data-qa-selector="paginationButtonPrev"
        />

        {start !== 0 && (
          <BPagination.Ellipsis onClick={() => prev(MAX_BUTTONS)} />
        )}

        {buttons.map(index => (
          <BPagination.Item
            key={index}
            active={page === index}
            onClick={() => setPage(index)}
            data-qa-selector={`paginationButton${index}`}
          >
            {index + 1}
          </BPagination.Item>
        ))}

        {end !== totalPageCount && (
          <BPagination.Ellipsis onClick={() => next(MAX_BUTTONS)} />
        )}

        <BPagination.Next
          data-qa-selector="paginationButtonNext"
          onClick={() => next(1)}
        />
        <BPagination.Last
          data-qa-selector="paginationButtonLast"
          onClick={() => setPage(totalPageCount - 1)}
        />
      </BPagination>
    </>
  );
};

export default Pagination;
