import React from 'react';
import { FormControlProps } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ControllerProps, FieldValues } from 'react-hook-form';

import DecimalInput from 'components/Form/DecimalInput';
import { formatSymbol } from 'modules/i18n';

import cn from './MaterialCost.module.scss';

type Props<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> &
  React.InputHTMLAttributes<HTMLInputElement> &
  FormControlProps & {
    currencyCode: string;
    suffix: string;
  };

const MaterialCost = <T extends FieldValues>({
  currencyCode,
  suffix,
  ...rest
}: Props<T>) => {
  return (
    <div className={cn.wrapper}>
      <div className={cn.prefix}>{formatSymbol(currencyCode)}</div>
      <Form.Control {...rest} as={DecimalInput} className={cn.input} />
      <div className={cn.suffix}>{suffix}</div>
    </div>
  );
};

export default MaterialCost;
