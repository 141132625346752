import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import CostDetailsInput from 'components/Form/CostDetailsInput';
import FieldError from 'components/Form/FieldError';
import PriceInputCurrency from 'components/Form/PriceInputCurrency';
import ServiceTypeSelect from 'components/ServiceTypeSelectControl';
import { WorkshopPartnerControl } from 'components/WorkshopPartnerControl';
import {
  CompositeType,
  PartnerType,
  RefurbishmentDetails,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import { isImperfectionService } from 'helpers/imperfections';
import { unitsFromMinorToMajor } from 'modules/currency';
import { calculateCostDetailsMajorUnits } from 'routes/RefurbishmentRoute/helpers/calculateCostDetails';
import {
  CostUnitSuffix,
  LaborTimeSuffix,
} from 'routes/RefurbishmentRoute/helpers/suffixes';
import useGetDefaultLaborRate from 'routes/RefurbishmentRoute/hooks/useGetDefaultLaborRate';
import CarPartSelectControl from './CarPartSelectControl';
import DamageGroupSelectControl from './DamageGroupSelectControl';
import DamageTypeSelectControl from './DamageTypeSelectControl';
import { DamageFormData, SliceKeyDamage, WheelPosition } from '../../types';
import AdImageUploadControl from '../AdImageUploadControl';

import cn from './DamageForm.module.scss';

export const validateBudget = <T extends Pick<Partial<DamageFormData>, 'name'>>(
  getValues: () => T,
) => (currentBudget: any) => {
  const currentServiceName = getValues().name;

  if (currentBudget > Number.MAX_SAFE_INTEGER) {
    return __('field.valueTooBig');
  }

  if (
    currentBudget > 0 ||
    !currentServiceName ||
    isImperfectionService(currentServiceName.name)
  ) {
    return true;
  }

  if (
    currentServiceName.name === RefurbishmentServiceName.PartOfOtherService &&
    currentBudget < 0
  ) {
    return __('field.positive');
  }

  if (
    currentServiceName.name === RefurbishmentServiceName.Photos &&
    currentBudget < 0
  ) {
    return __('field.positive');
  }

  if (
    !isImperfectionService(currentServiceName.name) &&
    currentServiceName.name !== RefurbishmentServiceName.PartOfOtherService &&
    currentServiceName.name !== RefurbishmentServiceName.Photos
  ) {
    return __('field.greaterToZero');
  }

  return true;
};

export const validateRequiredComment = <
  T extends Pick<Partial<DamageFormData>, 'name'>
>(
  getValues: () => T,
) => (currentComment: any) => {
  const currentServiceName = getValues().name;
  return (
    !currentServiceName ||
    currentServiceName.name !== RefurbishmentServiceName.Other ||
    (!!currentComment && !!currentComment.trim()) ||
    __('entryCheck.editDamage.otherServiceCommentValidationMessage')
  );
};

type Props = {
  refurbishment: RefurbishmentDetails;
  hasPA3?: boolean;
  damage?: Partial<DamageFormData>;
  damageGroup?: SliceKeyDamage | WheelPosition;
  isNew: boolean;
  errorMessage?: string | null;
  onSubmit(data: Partial<DamageFormData>): Promise<any>;
  onChange?(): void;
  onCancel(): void;
  disabled?: boolean;
  budgetRequired: boolean; // Not required for split cost, remove if PriceInputCurrency is removed
  isImperfectionRepairMethods: boolean;
  disableCarPartChange?: boolean;
  hasSplitCost?: boolean;
  hideCost?: boolean;
};

const DamageForm = ({
  refurbishment,
  hasPA3,
  damage,
  damageGroup,
  isNew,
  errorMessage,
  onSubmit,
  onChange,
  onCancel,
  disabled,
  budgetRequired,
  isImperfectionRepairMethods,
  disableCarPartChange = false,
  hasSplitCost = false,
  hideCost = false,
}: Props) => {
  const [isUploading, setUploading] = useState(false);
  const { getDefaultLaborRate } = useGetDefaultLaborRate(
    refurbishment.partnerWorkshopConnections,
  );

  const defaultPartnerWorkshopConnectionId =
    damage?.partnerWorkshopConnectionId ||
    refurbishment.partnerWorkshopConnections?.find(
      connection => connection.isDefault,
    )?.id;

  const getDefaultValues = async (): Promise<Partial<DamageFormData>> => {
    const partnerWorkshopConnectionId = defaultPartnerWorkshopConnectionId;

    const laborRateMinorUnits = hasSplitCost
      ? await getDefaultLaborRate(partnerWorkshopConnectionId)
      : 0;

    return {
      ...damage,
      laborRateMajorUnits: hasSplitCost
        ? unitsFromMinorToMajor(laborRateMinorUnits)
        : damage?.laborRateMajorUnits || 0,
      partnerWorkshopConnectionId,
    };
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    trigger,
    resetField,
    formState: { errors, isSubmitting, isLoading: isFormLoading },
  } = useForm<Partial<DamageFormData>>({
    defaultValues: getDefaultValues,
  });

  const selectedDamageGroup = watch('damageGroup');
  const selectedCarPart = watch('carPart');
  const selectedTypeName = watch('typeName');
  const selectedServiceName = watch('name');
  const budget = watch('budgetMajorUnits');
  const selectedImageUrl = watch('imageUrl');
  const comment = watch('comment');
  const partnerWorkshopConnectionId = watch('partnerWorkshopConnectionId');
  const laborRateMajorUnits = watch('laborRateMajorUnits');

  const isNoRepair = isImperfectionService(selectedServiceName?.name);
  const isBudgetOptional =
    selectedServiceName?.name === RefurbishmentServiceName.PartOfOtherService ||
    selectedServiceName?.name === RefurbishmentServiceName.Other;

  const isExternal =
    refurbishment.partnerWorkshopConnections?.find(
      pwc => pwc.id === partnerWorkshopConnectionId,
    )?.repairPartner.type === PartnerType.External;

  useEffect(() => {
    if (isNew) return;
    trigger('imageUrl');
  }, [selectedTypeName, selectedImageUrl, isNew]);

  useEffect(() => {
    trigger(['comment', 'budgetMajorUnits']);
  }, [selectedServiceName, comment, budget]);

  const handleServiceTypeChange = ({
    name,
  }: {
    name: RefurbishmentServiceName;
  }) => {
    resetField('consumableCostMajorUnits');
    resetField('sparePartCostMajorUnits');
    resetField('laborTimeHours');

    const isNoRepairService = isImperfectionService(name);

    if (isNoRepairService) {
      setValue('budgetMajorUnits', 0);
      setValue('consumableCostMajorUnits', 0);
      setValue('sparePartCostMajorUnits', 0);
      setValue('laborRateMajorUnits', 0);
      setValue('laborTimeHours', 0);
      setValue('partnerWorkshopConnectionId', null);
    }
  };

  const handlePartnerWorkshopConnectionChange = async (
    partnerWorkshopConnection: string,
  ) => {
    const laborRateMinorUnits = await getDefaultLaborRate(
      partnerWorkshopConnection,
    );
    setValue('laborRateMajorUnits', unitsFromMinorToMajor(laborRateMinorUnits));
  };

  const handleOnSubmit = async (values: Partial<DamageFormData>) => {
    const isNoRepairValue = isImperfectionService(values.name?.name);

    const { totalCost } = calculateCostDetailsMajorUnits(
      Number(values.consumableCostMajorUnits) || 0,
      Number(values.sparePartCostMajorUnits) || 0,
      Number(values.laborTimeHours) || 0,
      Number(values.laborRateMajorUnits) || 0,
    );

    const costDetails = {
      budgetMajorUnits: isNoRepairValue ? 0 : totalCost,
      consumableCostMajorUnits: isNoRepairValue
        ? 0
        : Number(values.consumableCostMajorUnits),
      sparePartCostMajorUnits: isNoRepairValue
        ? 0
        : Number(values.sparePartCostMajorUnits),
      laborTimeHours:
        isNoRepairValue || !values.laborTimeHours
          ? 0
          : Number(values.laborTimeHours),
      laborRateMajorUnits: Number(values.laborRateMajorUnits),
    };

    await onSubmit({
      ...values,
      budgetMajorUnits: isNoRepairValue ? 0 : Number(values.budgetMajorUnits),
      ...(hasSplitCost ? costDetails : {}),
      comment: values.comment ? values.comment.trim() : '',
      isSecondaryWheel: damage?.isSecondaryWheel,
      partnerWorkshopConnectionId: isNoRepairValue
        ? null
        : values.partnerWorkshopConnectionId,
    });
  };

  const validateCostDetails = (value: string) => {
    if (isBudgetOptional) {
      return true;
    }

    if (!isNoRepair && Number(value) <= 0) {
      return __('field.greaterToZero');
    }

    return true;
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)} onChange={onChange}>
      {!damageGroup && (
        <Form.Group controlId="damageGroup">
          <Form.Label>{__('refurbishment.detail.damageGroup')}</Form.Label>
          <DamageGroupSelectControl
            name="damageGroup"
            control={control}
            isDisabled={!isNew}
            hasPA3={hasPA3}
          />
          <FieldError name="damageGroup" errors={errors} />
        </Form.Group>
      )}

      <Form.Group controlId="carPart">
        <Form.Label>{__('refurbishment.detail.carPart')}</Form.Label>
        <CarPartSelectControl
          name="carPart"
          control={control}
          damageGroup={damageGroup || (selectedDamageGroup as SliceKeyDamage)}
          isDisabled={!isNew}
        />
        <FieldError name="carPart" errors={errors} />
      </Form.Group>

      <Form.Group controlId="typeName">
        <Form.Label>{__('refurbishment.detail.damage')}</Form.Label>
        <DamageTypeSelectControl
          name="typeName"
          control={control}
          selectedCarPart={selectedCarPart}
          disabled={disableCarPartChange}
        />
        <FieldError name="typeName" errors={errors} />
      </Form.Group>

      <Form.Group controlId="name">
        <Form.Label>{__('refurbishment.detail.service')}</Form.Label>
        <ServiceTypeSelect
          name="name"
          control={control}
          serviceType={RefurbishmentServiceType.Damage}
          isImperfectionRepairMethods={isImperfectionRepairMethods}
          onChange={handleServiceTypeChange}
        />
        <FieldError name="name" errors={errors} />
      </Form.Group>

      {!isNoRepair && (
        <WorkshopPartnerControl
          partnerWorkshopConnections={refurbishment.partnerWorkshopConnections}
          name="partnerWorkshopConnectionId"
          control={control}
          errors={errors}
          onChange={handlePartnerWorkshopConnectionChange}
        />
      )}

      {!hideCost &&
        !isNoRepair &&
        (hasSplitCost ? (
          <CostDetailsInput
            control={control}
            setValue={setValue}
            currencyCode={refurbishment.currencyCode}
            errors={errors}
            laborTimeValidation={validateCostDetails}
            laborRateMajorUnits={laborRateMajorUnits || 0}
            disabled={isNoRepair || !partnerWorkshopConnectionId}
            isExternal={isExternal}
            suffix={CostUnitSuffix.MajorUnits}
            laborTimeSuffix={LaborTimeSuffix.Hours}
            className="mb-4"
          />
        ) : (
          <Form.Group controlId="budgetMajorUnits">
            <Form.Label>{__('refurbishment.detail.budget')}</Form.Label>
            <PriceInputCurrency<Partial<DamageFormData>>
              name="budgetMajorUnits"
              currencyCode={refurbishment.currencyCode}
              control={control}
              rules={{
                validate: budgetRequired
                  ? validateBudget(getValues)
                  : () => true,
              }}
              disabled={isNoRepair}
              data-qa-selector="damage-form-budget"
            />
            <FieldError name="budgetMajorUnits" errors={errors} />
          </Form.Group>
        ))}

      <Form.Group>
        {!isFormLoading && (
          <AdImageUploadControl
            control={control}
            adId={refurbishment.adId}
            name="imageUrl"
            rules={{
              required: __('field.required'),
            }}
            compositeType={CompositeType.Damages}
            onLoading={isLoading => setUploading(isLoading)}
          />
        )}
        <FieldError name="imageUrl" errors={errors} />
      </Form.Group>

      <Form.Group controlId="comment">
        <Form.Label>{__('refurbishment.detail.comment')}</Form.Label>
        <Form.Control
          {...register('comment', {
            validate: validateRequiredComment(getValues),
          })}
          as="textarea"
          rows={3}
          data-qa-selector="damage-form-comment"
        />
        <FieldError name="comment" errors={errors} />
      </Form.Group>

      <div className="text-danger">{errorMessage}</div>
      <div className={cn.actions}>
        <Button
          variant="link"
          onClick={onCancel}
          data-qa-selector="damage-form-cancel"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="secondary"
          type="submit"
          className="btn-wide ml-4"
          data-qa-selector="damage-form-submit"
          disabled={isSubmitting || isUploading || disabled}
        >
          {isNew ? __('action.add') : __('action.edit')}
        </Button>
      </div>
    </Form>
  );
};

export default DamageForm;
