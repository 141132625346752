import React from 'react';
import { ButtonProps, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { trim } from 'lodash/fp';

import FieldError from 'components/Form/FieldError';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import Text from 'components/UI/Text';
import { useSearchUsersPromise } from 'modules/api/users';
import { countryOptions, localeOptions } from 'modules/i18n';
import { UserInfoForm } from '../types';

interface Props {
  defaultValues?: UserInfoForm;
  submitButton(buttonProps: ButtonProps): React.ReactNode;
  isEdit?: boolean;
  onSubmit(data: UserInfoForm): void;
}

const Info = ({
  submitButton,
  onSubmit,
  defaultValues,
  isEdit = false,
}: Props) => {
  const searchUsers = useSearchUsersPromise();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm<UserInfoForm>({
    defaultValues,
  });

  const notEmpty = (value: string) =>
    trim(value) !== '' || __('field.required');

  const trimSpacesOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const trimmed = event.target.value.trim();
    if (event.target.value !== trimmed) {
      setValue(event.target.name as keyof UserInfoForm, trimmed);
    }
  };

  const handleOnSubmit = async (values: UserInfoForm) => {
    if (!isEdit) {
      const { email } = values;
      const { data } = await searchUsers({
        filter: { email: email.trim() },
        page: 0,
        pageSize: 1,
      });
      if (data.length > 0) {
        setError('email', {
          type: 'emailAlreadyExists',
          message: __('field.emailAlreadyExists'),
        });
        return;
      }
    }
    onSubmit(values);
  };

  return (
    <>
      <Text size="l" weight="bold" tag="h3" className="mb-4">
        {__('user.detail.info')}
      </Text>
      <Form onSubmit={handleSubmit(handleOnSubmit)} id="user-form">
        <Form.Row>
          <Form.Group as={Col} controlId="firstName">
            <Form.Label>{__('user.detail.firstName')}</Form.Label>
            <Form.Control
              {...register('firstName', {
                validate: notEmpty,
              })}
              defaultValue={defaultValues ? defaultValues.firstName : ''}
              onBlur={trimSpacesOnBlur}
              isInvalid={!!errors.firstName}
              data-qa-selector="first_name"
            />
            <FieldError name="firstName" errors={errors} />
          </Form.Group>
          <Form.Group as={Col} controlId="lastName">
            <Form.Label>{__('user.detail.lastName')}</Form.Label>
            <Form.Control
              {...register('lastName', {
                validate: notEmpty,
              })}
              defaultValue={defaultValues ? defaultValues.lastName : ''}
              onBlur={trimSpacesOnBlur}
              isInvalid={!!errors.lastName}
              data-qa-selector="last_name"
            />

            <FieldError name="lastName" errors={errors} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label>{__('user.detail.email')}</Form.Label>
            <Form.Control
              {...register('email', {
                validate: notEmpty,
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  message: __('field.notValidEmail'),
                },
              })}
              defaultValue={defaultValues ? defaultValues.email : ''}
              disabled={isEdit}
              isInvalid={!!errors.email}
              data-qa-selector="email"
            />
            <FieldError name="email" errors={errors} />
          </Form.Group>
          <Form.Group as={Col} controlId="user-info-active">
            <Form.Label>{__('user.detail.active')}</Form.Label>
            <Form.Check
              {...register('active')}
              id="user-info-active"
              type="checkbox"
              label=""
              custom
              data-qa-selector="active"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>{__('user.detail.country')}</Form.Label>
            <ReactSelectInput
              name="country"
              placeholder={__('action.select')}
              options={countryOptions}
              rules={{
                required: __('field.required'),
              }}
              isInvalid={!!errors.country}
              dataQa="user_country"
              dataQaOption="user_country_option"
              control={control}
            />
            <FieldError name="country" errors={errors} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{__('user.detail.locale')}</Form.Label>
            <ReactSelectInput
              name="locale"
              placeholder={__('action.select')}
              options={localeOptions}
              rules={{
                required: __('field.required'),
              }}
              isInvalid={!!errors.locale}
              dataQa="user_locale"
              dataQaOption="user_locale_option"
              control={control}
            />
            <FieldError name="locale" errors={errors} />
          </Form.Group>
        </Form.Row>
        {submitButton({})}
      </Form>
    </>
  );
};

export default Info;
