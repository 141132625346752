import { useQuery } from '@apollo/react-hooks';

import { PartnerWorkshopConnection, Query } from 'graphqlTypes';
import REFURBISHMENT_PARTNER_WORKSHOP_CONNECTIONS from './gql/refurbishmentPartnerWorkshopConnections';

type TempQuery = Query & {
  refurbishmentPartnerWorkshopConnections: PartnerWorkshopConnection[];
};

type QueryRefurbishmentPartnerWorkshopConnectionsArgs = {
  refurbishmentId: string;
};

const useRefurbishmentPartnerWorkshopConnections = (
  refurbishmentId: string,
) => {
  const { data, ...rest } = useQuery<
    Pick<TempQuery, 'refurbishmentPartnerWorkshopConnections'>,
    QueryRefurbishmentPartnerWorkshopConnectionsArgs
  >(REFURBISHMENT_PARTNER_WORKSHOP_CONNECTIONS, {
    variables: {
      refurbishmentId,
    },
  });

  return {
    data: data?.refurbishmentPartnerWorkshopConnections,
    ...rest,
  };
};

export default useRefurbishmentPartnerWorkshopConnections;
