import React, { createRef, Ref, RefObject, useEffect, useRef } from 'react';
import cns from 'classnames';
import { get } from 'lodash/fp';

import CollapsableSidebarGroup from './CollapsableSidebarGroup';
import { ProgressItem } from '../../types';

import cn from './Sidebar.module.scss';

interface IndexEvent {
  index: number;
}

interface Props<T extends string> {
  items: Map<T, ProgressItem>;
  selectedItemIndex: number;
  displayProgress?: boolean;
  title: string;
  onStepClick: (arg0: IndexEvent) => void;
}

interface ItemProps {
  id: string;
  item: ProgressItem;
  isSelected: boolean;
  isDisabled: boolean;
  displayProgress: boolean;
  onClick: Function;
}

const Item = React.forwardRef(
  (
    { id, item, isSelected, isDisabled, displayProgress, onClick }: ItemProps,
    ref?: Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        type="button"
        data-qa-selector={`sidebarItem${id}`}
        className={cns(cn.sidebarButton, {
          [cn.selected]: isSelected,
          [cn.disabled]: isDisabled,
        })}
        ref={ref}
        onClick={() => onClick()}
      >
        <span className={cn.label}>{item.name}</span>

        {displayProgress && !Number.isNaN(item.total) && (
          <span
            className={cns(cn.status, {
              [cn.completed]:
                item.completed === item.total || Number.isNaN(item.completed),
            })}
            data-qa-selector={`sidebarItem${id}Progress`}
          >
            {`${Number.isNaN(item.completed) ? '' : `${item.completed} / `}${
              item.total
            }`}
          </span>
        )}
      </button>
    );
  },
);

const Sidebar = <T extends string>({
  items,
  selectedItemIndex,
  displayProgress = true,
  title,
  onStepClick,
}: Props<T>) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refItems = useRef<RefObject<HTMLButtonElement>[]>(
    Array.from({ length: items.size }, () => createRef<HTMLButtonElement>()),
  );

  if (!items) {
    return null;
  }

  useEffect(() => {
    const container = get(['current'], refContainer);
    const item = get(['current', selectedItemIndex, 'current'], refItems);
    if (container && item) {
      const itemRightSide =
        item.offsetLeft + item.clientWidth - container.scrollLeft;
      const itemLeftSide = item.offsetLeft - container.scrollLeft;
      if (itemRightSide > container.clientWidth) {
        container.scrollLeft =
          item.offsetLeft + item.clientWidth - container.clientWidth + 16;
      } else if (itemLeftSide < 0) {
        container.scrollLeft = item.offsetLeft - 16;
      }
    }
  }, [selectedItemIndex]);

  const children: React.ReactNode[] = [];
  const groupChildrenMap = new Map<string, React.ReactNode[]>();

  [...items].forEach(([key, value], index) => {
    const item = (
      <Item
        key={key}
        id={key}
        item={value}
        isSelected={index === selectedItemIndex}
        isDisabled={!!value.disabled}
        displayProgress={displayProgress}
        ref={refItems.current[index]}
        onClick={() => onStepClick({ index })}
      />
    );

    if (value.group) {
      if (!groupChildrenMap.get(value.group.id)) {
        groupChildrenMap.set(value.group.id, [item]);

        children.push(
          <CollapsableSidebarGroup
            key={index}
            name={value.group.name}
            qaSelector={`sidebar-group-${value.group.id}`}
            isDisabled={value.group.disabled}
            isHighlighted={selectedItemIndex >= index}
          >
            <>{groupChildrenMap.get(value.group.id)}</>
          </CollapsableSidebarGroup>,
        );
      } else {
        const groupChildren = groupChildrenMap.get(value.group.id);
        if (groupChildren) {
          groupChildren.push(item);
        }
      }
    } else {
      children.push(item);
    }
  });

  return (
    <div className={cn.sidebarContainer}>
      <p className={cn.title}>{title}</p>
      <div className={cn.itemsContainer} ref={refContainer}>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
