/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash/fp';

import { sendReq } from 'modules/apiRest';
import { getJSONItem, setJSONItem } from 'modules/safetyLocalStorage';
import assert from './assert';
import { WRONG_PASSWORD, WRONG_USERNAME } from './assertMessages';
import setupApi from './setupApi';

const LS_KEY = 'authData';

interface AuthDataService {
  access_token: string;
  token_type: 'bearer';
  refresh_token: string;
  expires_in: number;
  scope: 'refurbishment';
  user_uuid: string;
  user_chksum: string;
  jti: string;
}

interface AuthInfo {
  accessToken: string;
  refreshToken: string;
  isAuthorized: boolean;
  userUuid: string;
  userName: string;
}

interface ContextData {
  auth: AuthDataService;
  setAuth: React.Dispatch<any>;
  refreshPromiseRef: React.MutableRefObject<any>;
}

const AuthContext = createContext<ContextData | null>(null);

export const useAuth = () => {
  const contextData = useContext(AuthContext);
  if (!contextData) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }

  const { auth, setAuth, refreshPromiseRef } = contextData;

  const signInByUsernameAndPassword = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    assert(!username, WRONG_USERNAME);
    assert(!password, WRONG_PASSWORD);

    const { result } = await sendReq(
      'AUTH_BY_USERNAME_AND_PASSWORD',
      username,
      password,
    );

    setAuth(result);
  };

  const tryToRefreshAuth = async () => {
    const accessToken = get(['access_token'], auth);
    const refreshToken = get(['refresh_token'], auth);

    let newAuth;

    if (!refreshToken) {
      return undefined;
    }

    try {
      ({ result: newAuth } = await sendReq('REFRESH_TOKEN', {
        accessToken,
        refreshToken,
      }));
    } catch (e) {
      if (e) {
        console.error(e);
      }
    }

    setAuth(newAuth);

    return newAuth;
  };

  const tryToRefreshAuthOncePerTime = async () => {
    if (refreshPromiseRef.current) {
      return refreshPromiseRef.current;
    }

    let result;

    try {
      refreshPromiseRef.current = tryToRefreshAuth();

      result = await refreshPromiseRef.current;
    } finally {
      refreshPromiseRef.current = undefined;
    }

    return result;
  };

  const signOut = async (callback?: () => void) => {
    setAuth(null);
    if (callback) callback();
  };

  const info = useMemo<AuthInfo>(() => {
    const accessToken = get(['access_token'], auth);
    const refreshToken = get(['refresh_token'], auth);
    const payload = accessToken ? jwtDecode(accessToken) : null;

    return {
      accessToken,
      refreshToken,
      isAuthorized: !!accessToken,
      userUuid: get(['user_uuid'], payload),
      userName: get(['user_name'], payload),
      roles: get(['user_roles'], payload),
    };
  }, [auth]);

  return {
    info,
    tryToRefreshAuthOncePerTime,
    signInByUsernameAndPassword,
    signOut,
  };
};

interface Props {
  children: React.ReactNode;
  apiOrigin?: string;
  apiRoute: string;
}

const AuthProvider = ({ children, apiOrigin = '', apiRoute }: Props) => {
  const [auth, setAuthState] = useState(getJSONItem(LS_KEY));
  const refreshPromiseRef = useRef();

  setupApi({ apiOrigin, apiRoute });

  const setAuth = (authData: object) => {
    setAuthState(authData);
    setJSONItem(LS_KEY, authData);
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, refreshPromiseRef }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
