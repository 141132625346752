import { Button } from 'react-bootstrap';

import FormButtonModal from 'components/Form/FormButtonModal/FormButtonsModal';
import { ImportTask } from 'graphqlTypes';
import { saveToDiskCsvFile } from 'helpers/saveToDiskCsvFile';
import { usePromiseDownloadRepairCostImportTaskValidationResult } from 'modules/api/costTable';
import { useLogger } from 'modules/logger';
import { useNotification } from 'modules/notification';
import { ImportRepairCostPhase } from './ImportRepairCostModal';
import ImportRepairCostStatistics from './ImportRepairCostStatistics';

type Props = {
  closeModal: () => void;
  setActivePhase: React.Dispatch<React.SetStateAction<ImportRepairCostPhase>>;
  repairCostImportTaskData?: ImportTask;
};

const ImportRepairCostReUpload = ({
  closeModal,
  setActivePhase,
  repairCostImportTaskData,
}: Props) => {
  const { logError } = useLogger();
  const { error } = useNotification();
  const downloadRepairCostImportTaskValidationResult = usePromiseDownloadRepairCostImportTaskValidationResult();

  const reUploadFile = () => setActivePhase(ImportRepairCostPhase.UPLOADING);

  const downloadCsvLogFile = async () => {
    try {
      const id = repairCostImportTaskData?.id || '';
      const csv = await downloadRepairCostImportTaskValidationResult(id);

      saveToDiskCsvFile(csv, `${id}.csv`);
    } catch (errorData) {
      logError({ error: errorData });
      error(__('repairCost.failedCsvLogDownload'));
    }
  };

  return (
    <>
      <h3>{__('repairCost.reUploadFile')}</h3>
      <ImportRepairCostStatistics
        statistics={repairCostImportTaskData?.statistics}
      />
      <Button variant="link" className="p-0 mt-3" onClick={downloadCsvLogFile}>
        {__('repairCost.downloadValidationLog')}
      </Button>
      <FormButtonModal>
        <Button
          variant="secondary"
          onClick={() => {
            setActivePhase(ImportRepairCostPhase.UPLOADING);
            closeModal();
          }}
          data-qa-selector="closeImportRepairCostModal"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="ml-4"
          data-qa-selector="reUploadFileRepairCostModal"
          onClick={reUploadFile}
        >
          {__('repairCost.reUploadFileAction')}
        </Button>
      </FormButtonModal>
    </>
  );
};

export default ImportRepairCostReUpload;
