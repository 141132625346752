import { BadgeColor } from 'components/Badge';
import {
  DamageCancellationReason,
  DamageItemPayloadV2,
  EntryCheckItem as EntryCheckItemBackend,
  EntryCheckItemValue as EntryCheckItemValueBackend,
  EntryCheckItemValueState,
  GraphQlJsonPayload,
  Maybe,
  Mileage,
  PartnerWorkshopConnectionRefurbishmentResponse,
  RefurbishmentDetails,
  RefurbishmentServiceState,
  RefurbishmentServiceType,
  Scalars,
  SparePartStatus,
  StringTranslation,
  Transition,
  Unit,
  WheelProfileUnit,
  WheelSetDeleteReason,
} from 'graphqlTypes';
import { DamageDisplay, DamageValues } from './damages';
import { PayloadTypeService, ServiceDisplay, ServiceValues } from './services';
import DamageEntryCheckAuth from '../EntryCheckAuthorizationRoute/DamageEntryCheckAuth';
import DamageEntryCheckPostAuth from '../EntryCheckPostAuthorizationRoute/DamageEntryCheckPostAuth';
import DamageQualityCheck from '../QualityCheckRoute/DamageQualityCheck/DamageQualityCheck';
import DamageQualityCheckAuth from '../ReworkAuthorizationRoute/DamageQualityCheckAuth';
import ServiceQualityCheckApproval from '../ReworkAuthorizationRoute/ServiceQualityCheckApproval';

export enum CompareStates {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Changed = 'CHANGED',
  ChangedInAuthorization = 'CHANGED_IN_AUTHORIZATION',
  New = 'NEW',
  NewInAuthorization = 'NEW_IN_AUTHORIZATION',
  Unknown = 'UNKNOWN',
}

export enum VerifyState {
  NotReviewed = 'NOT_REVIEWED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export enum EntryCheckItemValueSourceType {
  EntryCheck = 'ENTRY_CHECK',
  Authorization = 'AUTHORIZATION',
  PostAuthorization = 'POST_AUTHORIZATION',
  Retail = 'RETAIL',
}

export type EntryCheckItemValue = Omit<
  EntryCheckItemValueBackend,
  'sourceType'
> & {
  value: GraphQlJsonPayload;
  payloadType: PayloadType;
  sourceType: EntryCheckItemValueSourceType;
};

export type EntryCheckItem = EntryCheckItemBackend & {
  payloadType: PayloadType;
  valuesExtended: Array<EntryCheckItemValue>;
};

export enum EntryCheckType {
  Detail = 'DETAIL',
  Inspection = 'INSPECTION',
  Damage = 'DAMAGE',
  Service = 'SERVICE',
  ServiceWork = 'SERVICE_WORK',
  SubService = 'SUB_SERVICE',
  Wheel = 'WHEEL',
  WheelSet = 'WHEEL_SET',
  WheelCheck = 'WHEEL_CHECK',
  Engine = 'ENGINE',
  Refurbishment = 'REFURBISHMENT',
  Underbody = 'UNDERBODY',
  Interior = 'INTERIOR',
}

export enum PayloadType {
  FuelType = 'FUEL_TYPE',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Mileage = 'MILEAGE',
  WheelSeason = 'WHEEL_SEASON',
  WheelProfile = 'WHEEL_PROFILE',
  WheelRimType = 'WHEEL_RIM_TYPE',
  WheelSize = 'WHEEL_SIZE',
  WheelRimSize = 'RIM_SIZE',
  WheelSpeedIndexPayload = 'WHEEL_SPEED_INDEX',
  WheelLoadIndexPayload = 'WHEEL_LOAD_INDEX',
  WheelManufacturerPayload = 'WHEEL_MANUFACTURER',
  WheelTirePressure = 'TIRE_PRESSURE',
  WheelDotPayload = 'WHEEL_DOT',
  WheelImagePayload = 'WHEEL_IMAGE',
  BrakeDamage = 'BRAKE_DAMAGE',
  DamageImage = 'DAMAGE_IMAGE',
  DamageType = 'DAMAGE_TYPE',
  DamageServiceName = 'DAMAGE_SERVICE_NAME',
  VehicleServiceName = 'VEHICLE_SERVICE_NAME',
  ServiceBudget = 'SERVICE_BUDGET',
  ServiceComment = 'SERVICE_COMMENT',
  DamageCustomerDisplay = 'DAMAGE_CUSTOMER_DISPLAY',
  ServiceCustomerDisplay = 'SERVICE_CUSTOMER_DISPLAY',
  DamageCancellation = 'DAMAGE_CANCELLATION',
  ProviderTotalCost = 'PROVIDER_TOTAL_COSTS',
  BeautyShot = 'BEAUTY_SHOT',
  WheelSwitch = 'WHEEL_SETS_SWITCH',
  WheelDelete = 'WHEEL_SET_DELETE',
  ServiceItem = 'SERVICE_ITEM',
  Smoker = 'SMOKER',
  AccidentCar = 'ACCIDENT_CAR',
  OriginalEngine = 'ORIGINAL_ENGINE',
  OriginalUpholstery = 'ORIGINAL_UPHOLSTERY',
  WasInCommercialUse = 'WAS_IN_COMMERCIAL_USE',
  DoesHoodRelease = 'HOOD_RELEASE',
  BlanketInsulatorAvailable = 'BLANKET_INSULATOR',
  OilCoolerCondition = 'OIL_COOLER',
  AlternatorCondition = 'ALTERNATOR',
  WindshieldFluidLevel = 'WINDSHIELD_FLUID_LEVEL',
  PowerSteeringCondition = 'POWER_STEERING',
  IgnitionSystemOperationCondition = 'IGNITION_SYSTEM_OPERATION',
  OilLevel = 'OIL_LEVEL',
  Battery = 'BATTERY',
  AbsOpticalCondition = 'ABS_OPTICAL_CONDITION',
  BrakeMasterCondition = 'BRAKE_MASTER_CONDITION',
  BrakeFluid = 'BRAKE_FLUID',
  AcSystemLeaks = 'AC_SYSTEM_LEAKS',
  AacCondenser = 'AAC_CONDENSER',
  CoolantRecoveryCondition = 'COOLANT_RECOVERY_CONDITION',
  CoolantHosesCondition = 'COOLANT_HOSES_CONDITION',
  RadiatorCondition = 'RADIATOR_CONDITION',
  RadiatorFanCondition = 'RADIATOR_FAN_CONDITION',
  WaterLeaksCondition = 'WATER_LEAKS_CONDITION',
  WaterPumpCondition = 'WATER_PUMP_CONDITION',
  Coolant = 'COOLANT',
  EngineRunning = 'ENGINE_RUNNING',
  EngineSound = 'ENGINE_SOUND',
  Intercooler = 'INTERCOOLER',
  FuelSystem = 'FUEL_SYSTEM',
  VBelt = 'V_BELT',
  Cambelt = 'CAMBELT',
  TimingChain = 'TIMING_CHAIN',
  BatteryTester = 'BATTERY_TESTER',
  SteeringCircuit = 'STEERING_CIRCUIT',
  SteeringDustCaps = 'STEERING_DUST_CAPS',
  SteeringPump = 'STEERING_PUMP',
  SteeringRack = 'STEERING_RACK',
  SteeringReservoir = 'STEERING_RESERVOIR',
  EngineMount = 'ENGINE_MOUNT',
  TorqueSupport = 'TORQUE_SUPPORT',
  TurboCharger = 'TURBOCHARGER',
  ManiFold = 'MANIFOLD',
  FuelTank = 'FUEL_TANK',
  JackPointFrontLeft = 'JACK_POINT_FRONT_LEFT',
  JackPointFrontRight = 'JACK_POINT_FRONT_RIGHT',
  JackPointRearLeft = 'JACK_POINT_REAR_LEFT',
  JackPointRearRight = 'JACK_POINT_REAR_RIGHT',
  SillLeft = 'SILL_LEFT',
  SillRight = 'SILL_RIGHT',
  UnderbodyFront = 'UNDERBODY_FRONT',
  UnderbodyLeft = 'UNDERBODY_LEFT',
  UnderbodyRear = 'UNDERBODY_REAR',
  UnderbodyRight = 'UNDERBODY_RIGHT',
  UnderrideGuardEngine = 'UNDERRIDE_GUARD_ENGINE',
  UnderrideGuardLeft = 'UNDERRIDE_GUARD_LEFT',
  UnderrideGuardRear = 'UNDERRIDE_GUARD_REAR',
  UnderrideGuardRight = 'UNDERRIDE_GUARD_RIGHT',
  TransmissionLeaksPayload = 'TRANSMISSION_LEAKS',
  TransmissionMountPayload = 'TRANSMISSION_MOUNT',
  ManualTransmissionCablesPayload = 'MANUAL_TRANSMISSION_CABLES',
  TorqueRodsFrontPayload = 'TORQUE_RODS_FRONT',
  TorqueRodsRearPayload = 'TORQUE_RODS_REAR',
  DifferentialPayload = 'DIFFERENTIAL',
  PropellerShaftPayload = 'PROPELLER_SHAFT',
  UniversalJointsFLPayload = 'UNIVERSAL_JOINTS_FL',
  UniversalJointsFRPayload = 'UNIVERSAL_JOINTS_FR',
  UniversalJointsRLPayload = 'UNIVERSAL_JOINTS_RL',
  UniversalJointsRRPayload = 'UNIVERSAL_JOINTS_RR',
  DriveshaftFlPayload = 'DRIVESHAFT_FL',
  DriveshaftFrPayload = 'DRIVESHAFT_FR',
  DriveshaftRlPayload = 'DRIVESHAFT_RL',
  DriveshaftRrPayload = 'DRIVESHAFT_RR',
  AxleJointFlPayload = 'AXLE_JOINT_FL',
  AxleJointFrPayload = 'AXLE_JOINT_FR',
  AxleJointRlPayload = 'AXLE_JOINT_RL',
  AxleJointRrPayload = 'AXLE_JOINT_RR',
  TrackRodEndLeftPayload = 'TRACK_ROD_END_LEFT',
  TrackRodEndRightPayload = 'TRACK_ROD_END_RIGHT',
  BrakeLineFl = 'BRAKE_LINE_FL',
  BrakeLineRl = 'BRAKE_LINE_RL',
  BrakeLineFr = 'BRAKE_LINE_FR',
  BrakeLineRr = 'BRAKE_LINE_RR',
  HandbrakeCableLeft = 'HANDBRAKE_CABLE_LEFT',
  HandbrakeCableRight = 'HANDBRAKE_CABLE_RIGHT',
  ParticleFilter = 'PARTICLE_FILTER',
  CatalyticConverter = 'CATALYTIC_CONVERTER',
  CenterExhaust = 'CENTER_EXHAUST',
  BackExhaust = 'BACK_EXHAUST',
  ExhaustAmount = 'EXHAUST_MOUNT',
  IsWheelBearingOk = 'IS_WHEEL_BEARING_OK',
  AreSpringsOk = 'ARE_SPRINGS_OK',
  IsShockAbsorberOk = 'IS_SHOCK_ABSORBER_OK',
  DoesBallJointWork = 'DOES_BALL_JOINT_WORK',
  DoesDustCoverExist = 'DOES_DUST_COVER_EXIST',
  IsBumpStopOk = 'IS_BUMP_STOP_OK',
  IsStrutBearingOk = 'IS_STRUT_BEARING_OK',
  AreSupportArmsOk = 'ARE_SUPPORT_ARMS_OK',
  IsControlArmOk = 'IS_CONTROL_ARM_OK',
  IsStabilizerBearingOk = 'IS_STABILIZER_BEARING_OK',
  IsRimInGoodCondition = 'IS_RIM_IN_GOOD_CONDITION',
  DoesRimCenterCapExists = 'DOES_RIM_CENTER_CAP_EXISTS',
  IsTireInGoodCondition = 'IS_TIRE_IN_GOOD_CONDITION',
  IsTireDirectionCorrect = 'IS_TIRE_DIRECTION_CORRECT',
  IsTirePressureCorrect = 'IS_TIRE_PRESSURE_CORRECT',
  AreRimsAndTiresLicensedForThisCar = 'ARE_RIMS_AND_TIRES_LICENSED_FOR_THIS_CAR',
  BrakeType = 'BRAKE_TYPE',
  DoesFluidCapExists = 'DOES_FLUID_CAP_EXISTS',
  AreBrakeHosesInGoodCondition = 'ARE_BRAKE_HOSES_IN_GOOD_CONDITION',
  BrakePadCondition = 'BRAKE_PAD_CONDITION',
  BrakeDiskCondition = 'BRAKE_DISK_CONDITION',
  IsBrakeCaliperInGoodCondition = 'IS_BRAKE_CALIPER_IN_GOOD_CONDITION',
  HasChargingCable = 'HAS_CHARGING_CABLE',
  DoesTheCarRequireAService = 'DOES_THE_CAR_REQUIRE_A_SERVICE',
  InspectionNeeded = 'INSPECTION_NEEDED',
  InspectionDoneBeforeEntryCheck = 'INSPECTION_DONE_BEFORE_ENTRY_CHECK',
  InspectionDidCarPassInspection = 'INSPECTION_DID_CAR_PASS_INSPECTION',
  InspectionLastDate = 'INSPECTION_LAST_DATE',
  InspectionLastMileage = 'INSPECTION_LAST_MILEAGE',
  InspectionLastDueDate = 'INSPECTION_LAST_DUE_DATE',
  ServiceState = 'SERVICE_STATE',
  PartnerWorkshopConnection = 'PARTNER_WORKSHOP_CONNECTION',
  KeysCount = 'KEYS_COUNT',
  ServiceBook = 'SERVICE_BOOK',
  DamageItem = 'DAMAGE_ITEM',
  DamageItemV2 = 'DAMAGE_ITEM_V2',
  EcCommon = 'EC_COMMON_PAYLOAD',
}

export type PayloadTypeDamage = Extract<
  PayloadType,
  | PayloadType.DamageType
  | PayloadType.DamageImage
  | PayloadType.DamageServiceName
  | PayloadType.ServiceBudget
  | PayloadType.ServiceComment
  | PayloadType.DamageCustomerDisplay
  | PayloadType.DamageCancellation
  | PayloadType.PartnerWorkshopConnection
  | PayloadType.EcCommon
>;

export enum SliceKey {
  Details = 'DETAILS',
  ServiceBook = 'SERVICE_BOOK',
  Inspection = 'INSPECTION',
  Exterior = 'EXTERIOR',
  Front = 'FRONT',
  Right = 'RIGHT',
  Back = 'BACK',
  Left = 'LEFT',
  Roof = 'ROOF',
  Interior = 'INTERIOR',
  Underbody = 'UNDERBODY',
  Engine = 'ENGINE',
  Wheels = 'WHEELS',
  VehicleServices = 'VEHICLE_SERVICES',
  BudgetCompletion = 'BUDGET_COMPLETION', // Displayed if SPLIT_COST flag is NOT present
  Summary = 'SUMMARY', // Displayed if SPLIT_COST flag is present
  CostCalculation = 'COST_CALCULATION',
}

export type SliceKeyDamage =
  | SliceKey.Front
  | SliceKey.Right
  | SliceKey.Back
  | SliceKey.Left
  | SliceKey.Roof
  | SliceKey.Interior
  | SliceKey.Underbody
  | SliceKey.Wheels
  | SliceKey.Engine;

export interface SliceItemValue {
  type: EntryCheckType;
  entryCheckItems: EntryCheckItem[];
  wheelSecondary?: boolean;
  wheelPosition?: WheelPosition;
}

export interface SliceItem {
  name: string;
  items: SliceItemValue[];
  DAMAGE?: SliceItemValue[];
}

export type SliceResult = Map<SliceKey, SliceItem>;

export type ProgressResult<T extends string> = Map<T, ProgressItem>;

export interface ProgressItem {
  name: string;
  total: number;
  completed: number;
  items?: any;
  group?: ProgressItemGroup;
  disabled?: boolean;
}

type ProgressItemGroup = {
  id: string;
  name: string;
  disabled: boolean;
};

export enum DamageArea {
  Body = 'damage-body',
  Tires = 'damage-tyresAndBrakes',
  Brakes = 'damage-tiresAndBrakes-brakes',
  Interior = 'damage-interior',
  Underbody = 'damage-underbody',
  Engine = 'damage-engine',
}

export enum DamageSubArea {
  BodyFront = 'damage-body-Front',
  BodyRight = 'damage-body-right',
  FrontRight = 'front_right',
  BackRight = 'back_right',
  BodyBack = 'damage-body-back',
  BodyLeft = 'damage-body-left',
  FrontLeft = 'front_left',
  BackLeft = 'back_left',
  BodyRoof = 'damage-body-roof',
}

export interface DamageInput {
  area: string;
  subArea: string;
  typeName: string | null;
  partName: string;
  imageUrl: string | null;
  name: string;
  type: string | null;
  budgetMinorUnits: number | null;
  laborCostMinorUnits?: number | null;
  laborRateMinorUnits?: number | null;
  laborTimeHours?: number | null;
  materialCostMinorUnits?: number | null;
  consumableCostMinorUnits?: number | null;
  sparePartCostMinorUnits?: number | null;
  comment?: string | null;
  isSecondaryWheel?: boolean | null;
  cancelReason?: string | null;
  cancelComment?: string | null;
  ids?: Record<PayloadTypeDamage, string | null>;
  displaySection?: string | null;
  partnerWorkshopConnectionId?: string | null;
}

export interface DamageInputPA3 {
  area: string;
  subArea: string;
  typeName: string | null;
  partName: string;
  imageUrl: string | null;
  name: string;
  type: RefurbishmentServiceType | null;
  budgetMinorUnits: number | null;
  laborRateMinorUnits?: number | null;
  laborTimeHours?: number | null;
  consumableCostMinorUnits?: number | null;
  sparePartCostMinorUnits?: number | null;
  comment?: string | null;
  isPA3Damage?: Maybe<Scalars['Boolean']>;
  attribute1Name: string;
  attribute1Value: string;
  attribute2Name: string;
  attribute2Value: string;
  quantity: string;
  isSecondaryWheel?: boolean | null;
  cancelReason?: string | null;
  cancelComment?: string | null;
  ids?: Record<PayloadTypeDamage, string | null>;
  displaySection?: string | null;
  partnerWorkshopConnectionId?: string | null;
}

export enum DamageState {
  New = 'NEW',
  Repaired = 'REPAIRED',
  CustomerView = 'CUSTOMER_VIEW',
  Rejected = 'REJECTED',
  Rework = 'REWORK',
}
/**
 * @deprecated
 * Use instances of DamageQualityCheck and DamageQualityCheckAuth
 */
export interface DamageItem {
  damageId?: string;
  itemId: string;
  isNew: boolean;
  isCompleted: boolean;
  isApproved?: boolean;
  isEdited?: boolean;
  hasNewImage?: boolean;
  servicePerformed?: boolean;
  damageVisible?: boolean;
  area: string;
  subArea: string;
  values: DamageValues;
  currentDisplay: DamageDisplay;
  createdOn: Date;
  photoUpdatedOn?: Date;
  state?: DamageState;
  previousValues?: DamageValues;
  previousDisplay?: DamageDisplay;
}

export interface DamageCancellationInput {
  cancelReason: DamageCancellationReason;
  cancelComment?: string;
}

export interface ServiceInput {
  type: Maybe<RefurbishmentServiceType>;
  name: string;
  budgetMinorUnits?: Maybe<number>;
  laborCostMinorUnits?: number;
  laborRateMinorUnits?: Maybe<number>;
  laborTimeHours?: Maybe<number>;
  materialCostMinorUnits?: number;
  consumableCostMinorUnits?: Maybe<number>;
  sparePartCostMinorUnits?: Maybe<number>;
  comment?: Maybe<string>;
  serviceState?: Maybe<RefurbishmentServiceState>;
  ids?: Record<PayloadTypeService, Maybe<string>>;
  partnerWorkshopConnectionId?: Maybe<string>;
  isAutoCreate?: Maybe<boolean>;
}

export interface ServiceItem {
  itemId: string;
  isNew: boolean;
  isCompleted: boolean;
  isApproved?: boolean;
  isEdited?: boolean;
  values: ServiceValues;
  currentDisplay: ServiceDisplay;
  createdOn: Date;
  previousValues?: ServiceValues;
  previousDisplay?: ServiceDisplay;
  serviceObj?: ServiceQualityCheckApproval;
}

export enum WheelPosition {
  bwl = 'bwl',
  bwr = 'bwr',
  fwl = 'fwl',
  fwr = 'fwr',
}

export interface WheelValues {
  season?: Maybe<number>;
  profile: {
    depth?: Maybe<number>;
    unit?: Maybe<WheelProfileUnit>;
  };
  rimType?: Maybe<number>;
  size: {
    width: {
      value?: Maybe<number>;
      unit?: Maybe<Unit>;
    };
    height?: Maybe<number>;
  };
  rimSize: {
    value?: Maybe<number>;
    unit?: Maybe<Unit>;
  };
  speedIndex?: Maybe<string>;
  loadIndex?: Maybe<number>;
  manufacturer?: Maybe<string>;
  tirePressure?: Maybe<number>;
  dot?: Maybe<string>;
  imageUrl?: Maybe<string>;
}

export type WheelBrakeValues = WheelValues & {
  brakeCondition?: Maybe<string>;
  brakeDamageId?: Maybe<number>;
};

export interface BrakeConditionValues {
  typeName: string;
  area: string;
  subArea: string;
}
export interface WheelDisplay {
  season?: string;
  profile?: string;
  rimType?: string;
  imageUrl?: string;
  width?: string;
  height?: string;
  rimSize?: string;
  speedIndex?: string;
  loadIndex?: string;
  manufacturer?: string;
  tirePressure?: string;
  dot?: string;
  brakeCondition?: string;
}

export interface WheelItem<
  T extends DamageQualityCheck | DamageQualityCheckAuth
> {
  itemVehicleId: string;
  itemDamageId?: string;
  isPrimary: boolean;
  includeBrakeCondition: boolean;
  position: WheelPosition;
  values: WheelBrakeValues;
  currentDisplay: WheelDisplay;
  originalDisplay: WheelDisplay;
  /**
   * @deprecated
   * Consider refactoring your code to use `damagesMapped` instead of `damages`
   */
  damages: DamageItem[]; // TODO: remove
  damagesMapped: T[];
  tireDataUpToDate?: boolean | null;
}

export interface BrakeConditionDisplay {
  typeName?: string;
}
interface EntryCheckItemValueData {
  id: string; // Id from EntryCheckItemValue
  state: EntryCheckItemValueState;
  payloadType: PayloadType;
}
export type WheelEntryCheckItemValue = Record<
  keyof WheelValues,
  EntryCheckItemValueData | undefined
>;

export interface EntryCheckItemData {
  id?: string; // Id from EntryCheckItem
  idValueAuth?: string; // Id from EntryCheckItemValue for auth values
  state: EntryCheckItemValueState;
  payloadType: PayloadType;
}

type Wheel = {
  isPrimary: boolean;
  position: WheelPosition;
  /**
   * @deprecated use damagesMapped instead
   */
  damages?: EntryCheckItem[][];
  checkpoints: EntryCheckItem[];
};
export type WheelEntryCheck = Wheel & {
  values: WheelValues;
  currentDisplay: WheelDisplay;
  originalDisplay: WheelDisplay;
  entryCheckItemValues: WheelEntryCheckItemValue;
  state: EntryCheckItemValueState;
  hasCompleteOriginalData: boolean;
  brakeCondition?: {
    values: BrakeConditionValues;
    currentDisplay: string;
    originalDisplay?: string;
    entryCheckItemValues: EntryCheckItemValueData;
    state: EntryCheckItemValueState;
  };
};
export type WheelAuth<
  T extends DamageEntryCheckAuth | DamageEntryCheckPostAuth
> = Wheel & {
  values: Partial<Record<EntryCheckItemValueSourceType, WheelValues>>;
  display: Partial<Record<EntryCheckItemValueSourceType, WheelDisplay>>;
  entryCheckItems: Record<keyof WheelValues, EntryCheckItemData>;
  verified: VerifyState;
  compareState: CompareStates;
  selectedSource?: EntryCheckItemValueSourceType;
  brakeCondition?: {
    values: Record<
      EntryCheckItemValueSourceType,
      BrakeConditionValues | undefined
    >;
    display: Record<EntryCheckItemValueSourceType, BrakeConditionDisplay>;
    entryCheckItem: EntryCheckItemData;
    verified: VerifyState;
    compareState: CompareStates;
    selectedSource?: EntryCheckItemValueSourceType;
  };
  damagesMapped: T[];
};

type WheelSets = {
  switched: boolean;
  deletedReason: string | undefined;
};

export type WheelSetsEntryCheck = WheelSets & {
  primaryWheels: WheelEntryCheck[];
  secondaryWheels: WheelEntryCheck[];
  rimTireLicensed?: EntryCheckItem[];
};

export type WheelSetsAuth<
  T extends DamageEntryCheckAuth | DamageEntryCheckPostAuth
> = {
  switched: {
    id: string;
    value: boolean;
    needReview: boolean;
    verified: VerifyState;
  };
  deleted: {
    id: string;
    value: boolean;
    reason?: Maybe<WheelSetDeleteReason>;
    deletedBy?: Maybe<string>;
    deletedOn?: Maybe<string>;
    needReview: boolean;
    verified: VerifyState;
  };
  primaryWheels: WheelAuth<T>[];
  secondaryWheels: WheelAuth<T>[];
  rimTireLicensed?: EntryCheckItem[];
};

export interface VehicleDetailsValues {
  mileage: Mileage;
  inspectionMileage: Mileage;
  maintenanceMileage: Mileage;
  maintenanceDate: Date;
  inspectionDate: Date;
  inspectionDueDate: Date;
  serviceCompany?: StringTranslation;
  serviceBookType?: StringTranslation;
  serviceDueDate?: Date;
  serviceDueMileage?: Mileage;
  inspectionNeeded: boolean;
  doneBeforeEntryCheck: boolean;
  carPassInspection: boolean;
  lastInspectionDate: Date;
  lastInspectionMileage: Mileage;
  lastInspectionDueDate: Date;
}

export interface VehicleDetailsDisplay {
  mileage: string;
  inspectionMileage: string;
  maintenanceMileage: string;
  fuelType: string;
  lastServiceMileage: string; // Maintenance last KM
  lastServiceOn: string; // Old maintenance date
  maintenanceDate: string; // New maintenance date
  inspectionDate: string; // New inspection date
  inspectionDueDate: string;
  technicalInspectionCertificateIssueDate: string; // Old inspection date
  technicalInspectionCertificateIssueMileage: string; // Inspection last KM
  gearType: string;
  registration: string;
  registrationUrl: string;
  sourcingCountry: string;
  transitionCurrentSet: Array<Transition>;
  serviceCompany: string;
  serviceBookType: number;
  serviceDueDate: string;
  serviceDueMileage: string;
}

interface BatteryDetailsDisplay {
  isLeased?: string;
  hasConditionReport?: string;
  conditionStatus?: string;
  capacity?: string;
  range?: number;
  batteryDocumentUrl?: string;
}

export interface VehicleDetails {
  itemId: string;
  values: VehicleDetailsValues;
  currentDisplay: VehicleDetailsDisplay;
  originalDisplay: Partial<VehicleDetailsDisplay>;
  electricBatteryDetails: BatteryDetailsDisplay;
  fuelTypeElectricOrHybrid: boolean;
  isServiceWorkRequired: Maybe<boolean>;
}

export enum Tags {
  QualityCheckAuthorization = 'QUALITY_CHECK_AUTHORIZATION',
  FourHundredWheelCheckPoints = 'FOUR_HUNDRED_WHEEL_CHECK_POINTS',
  FourHundredCheckPointsServices = 'FOUR_HUNDRED_CHECK_POINTS_SERVICES',
  ImperfectionPhotos = 'IMPERFECTION_PHOTOS',
  ServiceBook = 'SERVICE_BOOK',
  ImprovedTireDataAccuracy = 'IMPROVED_TIRE_DATA_ACCURACY',
  PA3 = 'PA3',
  SplitCost = 'SPLIT_COST',
  SpareParts = 'SPARE_PARTS_MANAGEMENT',
  CostGateAutoAuthorization = 'COST_GATE_AUTO_AUTHORIZATION',
}

interface SplitBudget {
  materialCost: number;
  laborCost: number;
  newBudget: number;
  totalServicesAdded: number;
  totalServicesAddedPrice: string;
  totalServicesRejected: number;
  totalServicesRejectedPrice: string;
}

export interface DetailedBudget {
  newBudget: string;
  oldBudget: string;
  budgetByPartnerWorkshopConnection: Map<string, SplitBudget>;
}

export interface Budgets {
  oldBudget: string; // used by V2 too
  newBudget: string; // used by V2 too
  newDamagesBudget: string;
  newServicesBudget: string;
  // V2
  totalServicesAdded: number;
  totalServicesAddedPrice: string;
  totalServicesRejected: number;
  totalServicesRejectedPrice: string;
  newBudgetInMinorUnits: number;
}

export type SectionProgress = {
  total: number;
  completed: number;
};

export type CarPart = {
  name: string;
  section: string;
  subSection: string;
};

export enum ServiceBookType {
  DigitalServiceBook = 'digital_service_book',
  PaperServiceBook = 'paper_service_book',
  ServiceDocument = 'service_document',
}

export enum DocumentType {
  ProofOfService = 'DOCUMENT_REFURBISHMENT_PROVIDER_TYPE_PROOF_OF_SERVICE',
}

export type PartnerWorkshopConnectionRefurbishment = PartnerWorkshopConnectionRefurbishmentResponse & {
  inUse: boolean;
};

export type BadgeData = {
  text: string;
  color: BadgeColor;
  qaSelector?: string;
  qaSelectorState?: string;
};

export type DamageItemPayloadModified = Omit<DamageItemPayloadV2, 'state'> & {
  damageState: DamageItemPayloadV2['state'];
};

export type Additional = Pick<
  RefurbishmentDetails,
  'currencyCode' | 'partnerWorkshopConnections' | 'tags'
> & {
  /**
   * Refurbishment ID
   */
  id: string;
  /**
   * Tags from entry check and quality check
   */
  statusTags?: string[] | null;
};

export type SparePartValue = {
  status: SparePartStatus;
  comment: string | null;
  lastStatusUpdatedOn: Date | null;
};

export type SparePartDisplay = {
  status: string;
  comment: string;
  lastStatusUpdatedOn: string;
};
