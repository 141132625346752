import gql from 'graphql-tag';

import { EntryCheckItemFragment } from './fragments';

export default gql`
  mutation updateEntryCheckItems($inputs: [EntryCheckItemUpdateEntryInput!]!) {
    updateEntryCheckItems(inputs: $inputs) {
      ...EntryCheckItemFragment
    }
  }
  ${EntryCheckItemFragment}
`;
