import { useQuery } from '@apollo/react-hooks';

import { Query, QueryRepairMethodSearchArgs } from 'graphqlTypes';
import REPAIR_METHOD_SEARCH from './gql/repairMethodSearch';

const useRepairMethodSearch = (variables: QueryRepairMethodSearchArgs) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'repairMethodSearch'>,
    QueryRepairMethodSearchArgs
  >(REPAIR_METHOD_SEARCH, {
    variables,
    fetchPolicy: 'no-cache',
  });

  return {
    data: data?.repairMethodSearch,
    ...rest,
  };
};

export default useRepairMethodSearch;
