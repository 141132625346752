import React from 'react';
import { flow, get, map, omit } from 'lodash/fp';

import SelectInput from 'components/Form/SelectInput';
import { struct } from 'helpers/fp';
import useCarParts from '../../hooks/useCarParts';
import { SliceKeyDamage, WheelPosition } from '../../types';

interface Props {
  name: string;
  control: any;
  damageGroup: SliceKeyDamage | WheelPosition;
  isDisabled?: boolean;
}

const CarPartSelectControl = ({
  name,
  control,
  damageGroup,
  isDisabled = false,
}: Props) => {
  const { carParts, loading } = useCarParts();

  const options = map(
    struct({
      label: get(['label']),
      value: flow(get('value'), omit('id')),
    }),
    carParts[damageGroup],
  );

  return (
    <SelectInput
      name={name}
      options={options}
      isLoading={loading}
      control={control}
      rules={{
        required: __('field.required'),
      }}
      disabled={isDisabled}
      dataQa="car-part-select"
      dataQaOption="car-part-option"
    />
  );
};

export default CarPartSelectControl;
