import gql from 'graphql-tag';

export default gql`
  query providerTotalSplitCosts($entryCheckId: UUID!) {
    providerTotalSplitCosts(entryCheckId: $entryCheckId) {
      costs {
        partnerWorkshopConnectionId
        costs {
          materialCostMinorUnits
          laborCostMinorUnits
        }
      }
      totalBudget
      totalBudgetIncludingInternalLabor
    }
  }
`;
