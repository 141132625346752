export const enum InformMessageStatus {
  TO_SEND = 'TO_SEND',
  SENT = 'SENT',
  SENDING_ERROR = 'SENDING_ERROR',
  MAX_DELIVERY_ATTEMPTS_REACHED = 'MAX_DELIVERY_ATTEMPTS_REACHED',
  VPC_PROCESSING_ERROR = 'VPC_PROCESSING_ERROR',
  RETRIED = 'RETRIED',
  SUCCESS = 'SUCCESS',
  DRY_RUN = 'DRY_RUN',
  CANCELED = 'CANCELED',
}

export const enum SortField {
  VIN = 'vin',
  REF_ID = 'refId',
  SERVICE_CODE = 'serviceCode',
  MESSAGE_TYPE = 'messageType',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  TENANT = 'tenant',
  ERROR_CODE = 'errorCode',
  ERROR_TEXT = 'errorText',
  DIRECTION = 'direction',
  REVIEWED = 'reviewed',
}
