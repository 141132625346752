import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import {
  createPlaceholder,
  createSingleValue,
} from 'components/CostTableFilter';
import ReactSelectInput from 'components/Form/ReactSelectInput';
import { useGetAllLocations } from 'modules/api/locations';

type Props<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, object>;
  qaSelector: string;
  selectedCountries?: Array<string>;
};

const MainWorkshopFilter = <T extends FieldValues>({
  name,
  control,
  qaSelector,
  selectedCountries,
}: Props<T>) => {
  const { data, loading } = useGetAllLocations();

  const options =
    data
      ?.filter(item => item.country)
      ?.filter(
        item =>
          !selectedCountries ||
          selectedCountries?.length === 0 ||
          (selectedCountries?.length === 1 && selectedCountries[0] === '') ||
          (item.country && selectedCountries.indexOf(item.country) >= 0),
      )
      ?.map(item => ({
        label: item.name,
        value: { name: item.name, branchId: item.id },
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <ReactSelectInput
      name={name}
      inputId={name}
      options={options}
      isLoading={loading}
      control={control}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
      dataQaMultiValueContainer={`${qaSelector}-multiValue`}
      placeholder={__('repairMethod.mainWorkshop')}
      isClearable
      isMulti
      components={{
        SingleValue: createSingleValue(),
        Placeholder: createPlaceholder(),
      }}
    />
  );
};

export default MainWorkshopFilter;
