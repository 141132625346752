import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import ImportHistoryModal from './ImportHistoryModal';
import ImportRepairMethodModal from './ImportRepairMethodModal';

export const enum RepairMethodImportModalType {
  'IMPORT_HISTORY' = 'importHistory',
  'IMPORT_REPAIR_METHOD' = 'importRepairMethod',
}

const RepairMethodImport = () => {
  const [
    activeModal,
    setActiveModal,
  ] = useState<null | RepairMethodImportModalType>(null);

  const showModal = (modalName: RepairMethodImportModalType) =>
    setActiveModal(modalName);

  const closeModal = () => setActiveModal(null);

  return (
    <>
      <ImportHistoryModal
        isModalVisible={
          activeModal === RepairMethodImportModalType.IMPORT_HISTORY
        }
        closeModal={closeModal}
      />

      <ImportRepairMethodModal
        isModalVisible={
          activeModal === RepairMethodImportModalType.IMPORT_REPAIR_METHOD
        }
        closeModal={closeModal}
      />

      <Button
        variant="outline-primary"
        onClick={() => showModal(RepairMethodImportModalType.IMPORT_HISTORY)}
        data-qa-selector="importHistoryButton"
        className="ml-4"
      >
        {__('repairMethod.openImportHistory')}
      </Button>

      <Button
        variant="outline-primary"
        onClick={() =>
          showModal(RepairMethodImportModalType.IMPORT_REPAIR_METHOD)
        }
        className="ml-4"
        data-qa-selector="importRepairMethodsButton"
      >
        {__('repairMethod.importRepairMethods')}
      </Button>
    </>
  );
};

export default RepairMethodImport;
