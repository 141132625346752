import gql from 'graphql-tag';

export default gql`
  query vehicleAllDamageParts($vehicleModelVersion: VehicleModelVersion!) {
    vehicleAllDamageParts(vehicleModelVersion: $vehicleModelVersion) {
      part
      area
    }
  }
`;
