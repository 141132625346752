import React from 'react';
import { Button } from 'react-bootstrap';

import { ImportTask } from 'graphqlTypes';
import { formatDateTime } from 'modules/i18n';

type Props = {
  item: ImportTask;
  order: number;
  onDownloadFile: (id: string) => void;
};

const ImportRow = ({ item, order, onDownloadFile }: Props) => {
  return (
    <tr key={item.id} data-qa-selector={`row-${item.id}`} data-qa-id={item.id}>
      <td data-qa-selector="order">{order}</td>
      <td data-qa-selector="fileName">{item.name}</td>
      <td data-qa-selector="uploadedOn">{formatDateTime(item.createdOn)}</td>
      <td data-qa-selector="userName">{`${item.createdBy.firstName} ${item.createdBy.lastName}`}</td>
      <td data-qa-selector="downloadLink">
        <Button variant="link" onClick={() => onDownloadFile(item.id)}>
          {__('repairCostAndMethod.fileLink')}
        </Button>
      </td>
    </tr>
  );
};

export default ImportRow;
