import { useContext } from 'react';

import { ErrorHandlerContext } from './ErrorHandlerProvider';

const useHandleError = () => {
  const context = useContext(ErrorHandlerContext);

  if (context === undefined) {
    throw new Error(
      'useHandleError must be used within an ErrorHandlerProvider',
    );
  }

  return context;
};

export default useHandleError;
