import gql from 'graphql-tag';

export default gql`
  query buildAdImageUploadCredentials(
    $awsImageUploadCredentialsInput: AwsImageUploadCredentialsInput!
  ) {
    buildAdImageUploadCredentials(
      awsImageUploadCredentialsInput: $awsImageUploadCredentialsInput
    ) {
      awsImageUploadCredentials {
        bucketName
        formData
      }
    }
  }
`;
