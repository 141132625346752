import gql from 'graphql-tag';

export default gql`
  query productionOverview(
    $page: Int!
    $pageSize: Int!
    $filter: ProductionOverviewFilter!
  ) {
    productionOverview(page: $page, pageSize: $pageSize, filter: $filter) {
      items {
        id
        adId
        refId
        branchName
        lastStatusUpdatedOn
        modelInfo {
          make
          model
          subModel
        }
        partnerName
        refurbishmentType
        retailStockCountry
        state
        stockNumber
        vinNumber
      }
      pageInfo {
        page
        pageSize
        totalEntityCount
        totalPageCount
      }
    }
  }
`;
