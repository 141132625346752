import { useEffect, useState } from 'react';
import { isEqual } from 'lodash/fp';

import { InformMessage } from 'graphqlTypes';

type Selected = {
  id: string;
  selected: boolean;
};

export type MessageSelected = InformMessage & Selected;

const useSelectMessages = (messages?: InformMessage[]) => {
  const [selectedState, setSelectedState] = useState<Selected[]>([]);

  useEffect(() => {
    const currentMessagesIds = selectedState.map(message => message.id).sort();
    const newMessagesIds = messages?.map(message => message.id).sort() || [];
    if (!isEqual(currentMessagesIds, newMessagesIds)) {
      const newState =
        messages?.map(message => ({
          id: message.id,
          selected: false,
        })) || [];
      setSelectedState(newState);
    }
  }, [messages]);

  const selectedMessages = messages?.map(message => {
    const selectedMessage = selectedState.find(
      ({ id }) => id === message.id,
    ) || { id: message.id, selected: false };

    return { ...selectedMessage, ...message };
  });

  const hasSelectedMessages = selectedState.some(({ selected }) => selected);

  const setSelectedMessage = (id: string, selected: boolean) => {
    const newSelectedState = selectedState.map(selectState =>
      selectState.id === id ? { id, selected } : selectState,
    );

    setSelectedState(newSelectedState);
  };

  const setAllSelectedMessages = (selected: boolean) => {
    const newSelectedState = selectedState.map(selectState => ({
      ...selectState,
      selected,
    }));

    setSelectedState(newSelectedState);
  };

  return {
    messages: selectedMessages,
    hasSelectedMessages,
    setSelectedMessage,
    setAllSelectedMessages,
  };
};

export default useSelectMessages;
