import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaPencilAlt } from 'react-icons/fa';

import FormButtonModal from 'components/Form/FormButtonModal';
import { useUpdateGroup } from 'modules/api/groups';

interface Props {
  id: string;
  name: string;
  description: string | null | undefined;
}

interface FormData {
  name: string;
  description: string | null;
}

const EditGroup = ({ id, name, description }: Props) => {
  const [show, setShow] = useState(false);
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name,
      description,
    },
  });
  const [updateGroup] = useUpdateGroup();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const onSubmit = (values: FormData) => {
    const { name: newName, description: newDescription } = values;

    updateGroup(id, { name: newName, description: newDescription });
    handleClose();
  };

  return (
    <>
      <Button
        variant="primary"
        className="float-right"
        onClick={handleShow}
        data-qa-selector="edit"
      >
        <FaPencilAlt />{' '}
        <strong className="pl-2">{__('group.detail.editGroup')}</strong>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__('group.detail.editGroup')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>{__('group.overview.name')}</Form.Label>
              <Form.Control
                type="text"
                {...register('name')}
                data-qa-selector="edit_name_input"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{__('group.overview.description')}</Form.Label>
              <Form.Control
                type="text"
                {...register('description')}
                data-qa-selector="edit_group_description"
              />
            </Form.Group>
            <FormButtonModal>
              <Button
                variant="secondary"
                onClick={handleClose}
                data-qa-selector="cancel_edit_group"
              >
                {__('action.cancel')}
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ml-4"
                data-qa-selector="confirm_edit_group"
              >
                {__('action.confirm')}
              </Button>
            </FormButtonModal>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditGroup;
