import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { trim } from 'lodash/fp';

import CostDetailsInput from 'components/Form/CostDetailsInput';
import FieldError from 'components/Form/FieldError';
import PriceInputCurrency from 'components/Form/PriceInputCurrency';
import ServiceTypeSelectControl from 'components/ServiceTypeSelectControl';
import { WorkshopPartnerControl } from 'components/WorkshopPartnerControl';
import {
  PartnerType,
  RefurbishmentDetails,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import { unitsFromMinorToMajor } from 'modules/currency';
import { calculateCostDetailsMajorUnits } from 'routes/RefurbishmentRoute/helpers/calculateCostDetails';
import useGetDefaultVehicleServiceCostAndLaborRate from 'routes/RefurbishmentRoute/hooks/useGetDefaultVehicleServiceCostAndLaborRate';

import cn from './ServiceForm.module.scss';

export const validateBudget = (budget: any = 0) => {
  const nBudget = Number(budget);

  if (isNaN(nBudget)) return __('field.required');
  if (nBudget < 0) return __('field.positive');
  if (nBudget > Number.MAX_SAFE_INTEGER) return __('field.valueTooBig');

  return true;
};

export interface ServiceFormData {
  service: {
    name: RefurbishmentServiceName;
    type: RefurbishmentServiceType;
  };
  consumableCost?: number | null;
  sparePartCost?: number | null;
  laborTime?: number | null;
  laborRate?: number | null;
  budgetMajorUnits?: number | null;
  comment?: string | null;
  partnerWorkshopConnectionId?: string | null;
}

interface Props {
  refurbishment: RefurbishmentDetails;
  isNew: boolean;
  defaultValues?: Partial<ServiceFormData>;
  errorMessage?: string | null;
  onSubmit(data: ServiceFormData): Promise<any>;
  onChange?(): void;
  onCancel(): void;
  disabled?: boolean;
  hideMaintenanceService?: boolean;
  selectedService?: RefurbishmentServiceName;
  disableSelectServiceType?: boolean;
  hasSplitCost?: boolean;
  hideCost?: boolean;
}

const ServiceForm = ({
  refurbishment,
  isNew,
  defaultValues,
  errorMessage,
  onSubmit,
  onChange,
  onCancel,
  disabled,
  hideMaintenanceService = false,
  selectedService,
  disableSelectServiceType = false,
  hasSplitCost = false,
  hideCost = false,
}: Props) => {
  const {
    getDefaultVehicleServiceCostAndLaborRate,
    isLoading: isUpdateLoading,
  } = useGetDefaultVehicleServiceCostAndLaborRate(
    refurbishment.partnerWorkshopConnections,
  );

  const getDefaultValues = async () => {
    const defaultPartnerWorkshopConnectionId =
      defaultValues?.partnerWorkshopConnectionId ||
      refurbishment.partnerWorkshopConnections?.find(
        connection => connection.isDefault,
      )?.id;

    const laborRateMinorUnits =
      hasSplitCost && defaultValues?.service?.name
        ? (
            await getDefaultVehicleServiceCostAndLaborRate(
              defaultValues.service.name,
              defaultPartnerWorkshopConnectionId,
            )
          ).laborRateMinorUnits
        : 0;

    return {
      ...defaultValues,
      ...(selectedService
        ? {
            budgetMajorUnits: 0,
            service: {
              name: selectedService,
              type: RefurbishmentServiceType.Vehicle,
            },
          }
        : {}),
      laborRate: hasSplitCost
        ? unitsFromMinorToMajor(laborRateMinorUnits)
        : defaultValues?.laborRate || 0,
      partnerWorkshopConnectionId: defaultPartnerWorkshopConnectionId,
    };
  };

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting, errors, isLoading: isFormLoading },
  } = useForm<Partial<ServiceFormData>>({
    defaultValues: getDefaultValues,
  });

  const isLoading = isUpdateLoading || isFormLoading;

  const laborRate = watch('laborRate');
  const currentPartnerWorkshopConnectionId = watch(
    'partnerWorkshopConnectionId',
  );

  const isExternal =
    refurbishment.partnerWorkshopConnections?.find(
      pwc => pwc.id === currentPartnerWorkshopConnectionId,
    )?.repairPartner.type === PartnerType.External;

  const isLaborRateMissing = hasSplitCost && !laborRate;

  const validateRequiredComment = (
    currentComment: ServiceFormData['comment'],
  ) => {
    const currentServiceName = getValues('service');
    return (
      !currentServiceName ||
      currentServiceName.name !== RefurbishmentServiceName.Other ||
      (!!currentComment && !!trim(currentComment)) ||
      __('entryCheck.editDamage.otherServiceCommentValidationMessage')
    );
  };

  const updateCostData = async (
    partnerWorkshopConnectionId: string,
    serviceName: string,
  ) => {
    const {
      serviceCost,
      laborRateMinorUnits,
    } = await getDefaultVehicleServiceCostAndLaborRate(
      serviceName,
      partnerWorkshopConnectionId,
    );

    const consumableCostMajorUnits = unitsFromMinorToMajor(
      serviceCost?.consumableCostMinorUnits,
    );
    const sparePartCostMajorUnits = unitsFromMinorToMajor(
      serviceCost?.sparePartCostMinorUnits,
    );
    const laborRateMajorUnits = unitsFromMinorToMajor(laborRateMinorUnits);

    setValue(
      'consumableCost',
      Number.isFinite(consumableCostMajorUnits)
        ? consumableCostMajorUnits
        : null,
    );
    setValue(
      'sparePartCost',
      Number.isFinite(sparePartCostMajorUnits) ? sparePartCostMajorUnits : null,
    );
    setValue(
      'laborTime',
      Number.isFinite(serviceCost?.laborTimeHours)
        ? serviceCost?.laborTimeHours
        : null,
    );
    setValue('laborRate', laborRateMajorUnits || 0);
  };

  const handlePartnerWorkshopConnectionChange = (
    partnerWorkshopConnection: string,
  ) => {
    const refurbishmentService = getValues('service');
    if (refurbishmentService?.name && hasSplitCost) {
      updateCostData(partnerWorkshopConnection, refurbishmentService.name);
    }
  };

  const handleVehicleServiceChange = (serviceName: string) => {
    const partnerWorkshopConnection = getValues('partnerWorkshopConnectionId');
    if (partnerWorkshopConnection && hasSplitCost) {
      updateCostData(partnerWorkshopConnection, serviceName);
    }
  };

  const handleOnSubmit = async (values: Partial<ServiceFormData>) => {
    const { totalCost } = calculateCostDetailsMajorUnits(
      values.consumableCost || 0,
      values.sparePartCost || 0,
      values.laborTime || 0,
      values.laborRate || 0,
    );

    await onSubmit({
      ...values,
      service: values.service!,
      budgetMajorUnits: hasSplitCost
        ? totalCost
        : Number(values.budgetMajorUnits),
      comment: values.comment ? values.comment.trim() : '',
    });
  };

  const validateCostDetails = (value: string) => {
    const currentServiceName = getValues('service');
    const isBudgetOptional =
      currentServiceName?.name === RefurbishmentServiceName.Other;

    if (isBudgetOptional) {
      return true;
    }

    if (Number(value) <= 0) {
      return __('field.greaterToZero');
    }

    return true;
  };

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)} onChange={onChange}>
      <Form.Group controlId="addNewService.service">
        <Form.Label>{__('refurbishment.detail.service')}</Form.Label>
        <ServiceTypeSelectControl
          name="service"
          control={control}
          serviceType={RefurbishmentServiceType.Vehicle}
          hideMaintenanceService={hideMaintenanceService}
          disabled={disableSelectServiceType}
          onChange={refurbishmentService =>
            handleVehicleServiceChange(refurbishmentService.name)
          }
        />
        <FieldError name="service" errors={errors} />
      </Form.Group>

      <WorkshopPartnerControl
        partnerWorkshopConnections={refurbishment.partnerWorkshopConnections}
        name="partnerWorkshopConnectionId"
        control={control}
        errors={errors}
        onChange={handlePartnerWorkshopConnectionChange}
      />

      {!hideCost &&
        (hasSplitCost ? (
          <CostDetailsInput
            laborRateMajorUnits={laborRate || 0}
            control={control}
            setValue={setValue}
            currencyCode={refurbishment.currencyCode}
            errors={errors}
            laborTimeValidation={validateCostDetails}
            isExternal={isExternal}
            disabled={isLoading}
          />
        ) : (
          <Form.Group controlId="addNewService.budget">
            <Form.Label>{__('entryCheck.serviceForm.budget')}</Form.Label>
            <PriceInputCurrency
              name="budgetMajorUnits"
              currencyCode={refurbishment.currencyCode}
              control={control}
              rules={{
                validate: validateBudget,
              }}
              data-qa-selector="price"
            />
            <FieldError name="budget" errors={errors} />
          </Form.Group>
        ))}

      <Form.Group controlId="addNewService.comment">
        <Form.Label>{__('refurbishment.detail.comment')}</Form.Label>
        <Form.Control
          {...register('comment', {
            validate: validateRequiredComment,
          })}
          as="textarea"
          rows={3}
          data-qa-selector="comment"
        />
        <FieldError name="comment" errors={errors} />
      </Form.Group>
      <div className="text-danger">{errorMessage}</div>
      <div className={cn.actions}>
        <Button
          variant="link"
          onClick={onCancel}
          data-qa-selector="service-form-cancel"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="secondary"
          type="submit"
          className="btn-wide ml-4"
          data-qa-selector="service-form-submit"
          disabled={isSubmitting || disabled || isLoading || isLaborRateMissing}
        >
          {isNew ? __('action.add') : __('action.edit')}
        </Button>
      </div>
    </Form>
  );
};

export default ServiceForm;
