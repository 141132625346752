import gql from 'graphql-tag';

import { PartnerDetailsFragment } from './fragments';

export default gql`
  mutation createPartner($partnerInput: PartnerInput!) {
    createPartner(partnerInput: $partnerInput) {
      ...PartnerDetailsFragment
    }
  }
  ${PartnerDetailsFragment}
`;
