import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Mutation, MutationPostAuthorizeEntryCheckArgs } from 'graphqlTypes';
import POST_AUTHORIZE_ENTRY_CHECK from './gql/postAuthorizeEntryCheck';

const usePostAuthorizeEntryCheck = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'postAuthorizeEntryCheck'>,
    MutationPostAuthorizeEntryCheckArgs
  >(POST_AUTHORIZE_ENTRY_CHECK);

  const postAuthorizeEntryCheck = (id: string) => {
    return mutation({
      variables: { id },
    });
  };

  return [
    postAuthorizeEntryCheck,
    {
      data: get('postAuthorizeEntryCheck', data),
      ...rest,
    },
  ] as const;
};

export default usePostAuthorizeEntryCheck;
