import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query } from 'graphqlTypes';
import TIRE_HEIGHTS from './gql/tireHeights';

const useTireHeights = () => {
  const { data, ...rest } = useQuery<Pick<Query, 'tireHeights'>, null>(
    TIRE_HEIGHTS,
  );

  return {
    data: get('tireHeights', data),
    ...rest,
  };
};

export default useTireHeights;
