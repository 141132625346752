import { Link } from 'react-router-dom';
import cns from 'classnames';

import Badge from 'components/Badge';
import Text from 'components/UI/Text';
import { RefurbishmentTask } from 'graphqlTypes';
import { ImageSize } from 'helpers/images';
import useAdImage from 'modules/adImage/useAdImage';
import { formatDate } from 'modules/i18n/date';
import { getUrl } from 'modules/routeUtils';
import TaskItemAction from '../TaskItemAction';

import cn from './TaskItem.module.scss';

type Props = {
  task: RefurbishmentTask;
  onStateChange?(): void;
};

const TaskItem = ({ task, onStateChange }: Props) => {
  const { getImageUrl } = useAdImage();

  return (
    <div
      data-qa-selector={`task-item-${task.id}`}
      className={cns(cn.item, 'border rounded')}
    >
      <div className={cn.mainInfo}>
        <img
          src={getImageUrl(task.vehicle.overviewImageFullUrl, ImageSize.SMALL)}
          alt={task.id}
          className={cn.image}
          data-qa-selector="refurbishment_vehicle_image"
        />
        <div className="flex-grow-1">
          <div className="d-flex">
            <Link
              to={getUrl('REFURBISHMENT_TASK_DETAIL', { id: task.id })}
              className="flex-grow-1"
            >
              <Text
                color="primary"
                size="xl"
                tag="div"
                qaSelector="vehicle_model"
              >
                {task.vehicle.make} {task.vehicle.model} {task.vehicle.subModel}
              </Text>
            </Link>

            <div className={cn.stateTasksCountContainer}>
              <Badge color="info" size="m" qaSelector="refurbishment_state">
                {task.refurbishment.refurbishmentState.translation}
              </Badge>
              <div data-qa-selector="tasks_count">
                <Text weight="bold">
                  <span data-qa-selector="completed_tasks">
                    {task.completedTaskItemsCount}
                  </span>{' '}
                  {__('refurbishmentTaskList.of')}{' '}
                  <span data-qa-selector="total_tasks">
                    {task.totalTaskItemsCount}
                  </span>
                </Text>{' '}
                {__('refurbishmentTaskList.tasksCompleted')}
              </div>
            </div>
          </div>

          <div>
            {__('refurbishmentTaskList.stockNumber')}:{' '}
            <Text weight="bold" qaSelector="stock_number">
              {task.vehicle.stockNumber}
            </Text>
          </div>

          <div>
            {__('refurbishmentTaskList.refurbishmentNumber')}:{' '}
            <Text weight="bold" qaSelector="refurbishment_number">
              {task.refurbishment.refurbishmentNumber}
            </Text>
          </div>

          <div>
            {__('refurbishmentTaskList.vinNumber')}:{' '}
            <Text weight="bold" qaSelector="vin">
              {task.vehicle.vin}
            </Text>
          </div>

          <div>
            <div>
              {__('refurbishmentTaskList.partnerLocation')}:{' '}
              {task.currentPartnerWorkshopConnection && (
                <>
                  <Text weight="bold" qaSelector="repair_workshop">
                    {task.currentPartnerWorkshopConnection?.repairWorkshop.name}
                  </Text>
                  <Text weight="bold"> / </Text>
                  <Text weight="bold" qaSelector="repair_partner">
                    {task.currentPartnerWorkshopConnection?.repairPartner.name}
                  </Text>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={cn.itemBottom}>
        <div>
          <Text color="muted" size="s">
            {__('refurbishmentTaskList.orderDate')}:
          </Text>{' '}
          <Text color="muted" size="s" qaSelector="order_date">
            {formatDate(task.refurbishment.orderedOn)}
          </Text>{' '}
          {task.refurbishment.latestReworkDate && (
            <>
              <Text color="muted" size="s">
                | {__('refurbishmentTaskList.reworkDate')}:
              </Text>{' '}
              <Text color="muted" size="s" qaSelector="rework_date">
                {formatDate(task.refurbishment.latestReworkDate)}
              </Text>
            </>
          )}
        </div>
        <TaskItemAction task={task} onStateChange={onStateChange} />
      </div>
    </div>
  );
};

export default TaskItem;
