import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorType } from './constants';

export const ErrorHandlerContext = createContext<
  ((errorType: ErrorType) => void) | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
  renderErrorComponent(errorType: ErrorType): React.ReactNode;
}

const ErrorHandlerProvider = ({ children, renderErrorComponent }: Props) => {
  const { pathname } = useLocation();
  const [errorType, setErrorType] = useState<ErrorType | null>(null);

  useEffect(() => {
    if (errorType) setErrorType(null);
  }, [pathname]);

  const renderContent = () => {
    if (errorType) {
      return renderErrorComponent(errorType);
    }

    return children;
  };

  return (
    <ErrorHandlerContext.Provider value={setErrorType}>
      {renderContent()}
    </ErrorHandlerContext.Provider>
  );
};

export default ErrorHandlerProvider;
