interface NotificationItem {
  id: number;
  text: string;
  type: string;
  isOutdated: boolean;
}

interface AddAction {
  type: 'ADD';
  payload: {
    id: number;
    type: string;
    text: string;
  };
}

interface ExpireAction {
  type: 'EXPIRE';
  payload: {
    id: number;
  };
}

interface RemoveAction {
  type: 'REMOVE';
  payload: {
    id: number;
  };
}

type Action = AddAction | ExpireAction | RemoveAction;

export const notificationReducer = (
  state: Array<NotificationItem>,
  action: Action,
): Array<NotificationItem> => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          id: action.payload.id,
          type: action.payload.type,
          text: action.payload.text,
          isOutdated: false,
        },
      ];
    case 'EXPIRE':
      return state.map((item: NotificationItem) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            isOutdated: true,
          };
        }

        return item;
      });
    case 'REMOVE':
      return state.filter(
        (item: NotificationItem) => item.id !== action.payload.id,
      );
    default:
      return state;
  }
};
