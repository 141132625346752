import gql from 'graphql-tag';

export default gql`
  mutation updateReview($id: UUID!, $updateReviewInput: UpdateReviewInput!) {
    updateReview(id: $id, updateReviewInput: $updateReviewInput) {
      id
      readyForImperfectionPhotosOn
    }
  }
`;
