import gql from 'graphql-tag';

import { RefurbishmentItemPayloadFragment } from './fragments';

export default gql`
  query qualityCheckApprovalItems($ids: [UUID!]!) {
    qualityCheckApprovalItems(ids: $ids) {
      id
      data {
        ...RefurbishmentItemPayloadFragment
      }
    }
  }
  ${RefurbishmentItemPayloadFragment}
`;
