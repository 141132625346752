import LoadingIndicator from 'components/LoadingIndicator';
import { RefurbishmentTaskAggregate } from 'graphqlTypes';
import TaskItem from '../TaskItem';

type Params = {
  data?: RefurbishmentTaskAggregate;
  loading: boolean;
  refetch(): void;
};

const TaskList = ({ data, loading, refetch }: Params) => {
  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  return (
    <div className="mt-3">
      {data.items.map(item => (
        <TaskItem key={item.id} task={item} onStateChange={refetch} />
      ))}
    </div>
  );
};

export default TaskList;
