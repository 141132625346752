import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

import { SortDirection } from 'graphqlTypes';
import { SortField } from '../types';

const useDefaultSorting = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

  const { sortField, sortDir } = searchParams;

  const sort =
    sortField && sortDir
      ? {
          [sortField]: sortDir,
        }
      : null;

  useEffect(() => {
    if (!sort) {
      navigate(
        {
          search: qs.stringify({
            ...searchParams,
            sortField: SortField.CREATED_AT,
            sortDir: SortDirection.Desc,
          }),
        },
        { replace: true },
      );
    }
  }, [sort]);
};

export default useDefaultSorting;
