import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import {
  DamageState,
  RefurbishmentServiceState,
  WheelSetSwitchVerificationStatus,
} from 'graphqlTypes';
import {
  useUpdateQualityCheckDamageItemV2,
  useUpdateQualityCheckRefurbishmentItem,
  useUpdateQualityCheckServiceItem,
  useUpdateQualityCheckVehicleItem,
} from 'modules/api/qualityCheck';
import DamageQualityCheck from 'routes/RefurbishmentRoute/QualityCheckRoute/DamageQualityCheck';
import ServiceQualityCheck from 'routes/RefurbishmentRoute/QualityCheckRoute/ServiceQualityCheck';
import {
  QualityCheckMap,
  StepName,
} from 'routes/RefurbishmentRoute/QualityCheckRoute/types';
import {
  DamageItem,
  DamageState as RouteDamageState,
  WheelItem,
} from 'routes/RefurbishmentRoute/types';

type Props = ButtonProps & {
  items: QualityCheckMap;
  section: string;
  hasSplitCost?: boolean;
};

const ApproveQualityCheckItems = ({
  items,
  section,
  hasSplitCost,
  ...rest
}: Props) => {
  const [updateQualityCheckDamageItem] = useUpdateQualityCheckDamageItemV2();
  const [updateQualityCheckServiceItem] = useUpdateQualityCheckServiceItem();
  const [updateQualityCheckVehicleItem] = useUpdateQualityCheckVehicleItem();
  const [
    updateQualityCheckRefurbishmentItem,
  ] = useUpdateQualityCheckRefurbishmentItem(items.reviewId);

  const approveDamage = (damage: DamageItem) => {
    const { itemId, values } = damage;
    const {
      area,
      subArea,
      partName,
      typeName,
      imageUrl,
      customerDisplay,
      isSecondaryWheel,
      currentService,
    } = values;

    updateQualityCheckDamageItem(itemId, {
      area,
      subArea,
      partName,
      typeName,
      imageUrl,
      customerDisplay,
      isSecondaryWheel,
      state: DamageState.Fixed,
      stateComment: null,
      currentService: {
        ...currentService,
        state: RefurbishmentServiceState.Performed,
        stateComment: null,
      },
      newService: null,
    });
  };

  const approveService = (service: ServiceQualityCheck) => {
    const values = service.values[service.currentPhase];
    if (!values) return;
    const {
      budgetMinorUnits,
      consumableCostMinorUnits,
      sparePartCostMinorUnits,
      laborTimeHours,
      laborRateMinorUnits,
      serviceName,
      serviceComment,
      serviceType,
      stateComment,
    } = values;
    updateQualityCheckServiceItem(values.id, {
      budgetMinorUnits,
      ...(hasSplitCost && {
        consumableCostMinorUnits,
        sparePartCostMinorUnits,
        laborTimeHours,
        laborRateMinorUnits,
      }),
      serviceName,
      serviceComment,
      serviceType,
      state: RefurbishmentServiceState.Performed,
      stateComment,
    });
  };

  const approveWheel = (
    wheel: WheelItem<DamageQualityCheck>,
    itemVehicleId: string,
  ) => {
    updateQualityCheckVehicleItem(itemVehicleId, {
      wheelSets: [
        {
          isPrimary: wheel.isPrimary,
          wheels: [
            {
              position: wheel.position,
              tireDataUpToDate: true,
            },
          ],
        },
      ],
    });
  };

  const approveWheelSetSwitch = (itemId: string) => {
    updateQualityCheckRefurbishmentItem(itemId, {
      wheelSetsSwitched: false,
      wheelSetSwitchVerificationStatus:
        WheelSetSwitchVerificationStatus.NotNeeded,
    });
  };

  const handleClick = () => {
    if (section === StepName.VehicleWheels) {
      items.wheels.forEach(wheel =>
        wheel.damages
          .filter(
            damage =>
              damage.values.state !== DamageState.Fixed &&
              !(
                damage.state &&
                [
                  RouteDamageState.Rejected,
                  RouteDamageState.CustomerView,
                ].includes(damage.state)
              ),
          )
          .forEach(damage => approveDamage(damage)),
      );
      items.wheels
        .filter(wheel => !wheel.tireDataUpToDate)
        .forEach(wheel => approveWheel(wheel, items.vehicleDetails.itemId));
      if (
        items.refurbishmentSummary.hasSecondaryWheels &&
        !items.refurbishmentSummary.wheelSets.switchedAction
      ) {
        approveWheelSetSwitch(items.refurbishmentSummary.itemId);
      }
    }

    if (section === StepName.Damages) {
      items.damages
        .filter(damage => damage.values.state !== DamageState.Fixed)
        .forEach(damage => approveDamage(damage));
    }

    if (section === StepName.VehicleServices) {
      items.services
        .filter(
          service =>
            service.values[service.currentPhase]?.state !==
            RefurbishmentServiceState.Performed,
        )
        .forEach(service => approveService(service));
    }
  };

  return (
    <Button onClick={handleClick} variant="outline-danger" {...rest}>
      Approve All
    </Button>
  );
};

export default ApproveQualityCheckItems;
