import { Button, Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import Text from 'components/UI/Text';
import { Maybe, RepairCost } from 'graphqlTypes';

type Props = {
  isModalVisible: boolean;
  updateRepairCostItem: (
    updatedData: { active?: boolean; isDefault?: boolean },
    item: RepairCost,
  ) => void;
  currentItem: Maybe<RepairCost>;
  closeModal: () => void;
};

const RepairCostDefultCostConfirmationModal = ({
  isModalVisible,
  currentItem,
  updateRepairCostItem,
  closeModal,
}: Props) => {
  const onConfirmationHandler = () => {
    if (currentItem) {
      updateRepairCostItem({ isDefault: !currentItem.isDefault }, currentItem);
    }
  };

  return (
    <CustomModal
      show={isModalVisible}
      size="sm"
      onHide={closeModal}
      qaSelector="repairCostRepairCostDefultCostConfirmationModal"
    >
      <Modal.Header closeButton className="pb-0"></Modal.Header>
      <Modal.Body>
        <Text tag="p" size="l" className="text-center">
          {__('repairCost.replaceDefaultQuestion')}
        </Text>
        <div className="d-flex justify-content-center mt-4">
          <Button
            variant="primary"
            type="submit"
            data-qa-selector="confirmImportRepairCostModal"
            onClick={onConfirmationHandler}
          >
            {__('action.confirm')}
          </Button>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};

export default RepairCostDefultCostConfirmationModal;
