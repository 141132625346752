import React, { useEffect, useRef } from 'react';

interface Props {
  onClickOutside(): void;
  children: React.ReactNode;
}

const ClickOutside = ({ onClickOutside, children }: Props) => {
  const wrapper = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapper &&
      wrapper.current &&
      !wrapper.current.contains(event.target as Element)
    ) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return <div ref={wrapper}>{children}</div>;
};

export default ClickOutside;
