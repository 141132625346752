import React, { useEffect, useState } from 'react';

import { LOCALE } from '../config';
import DynamicTranslateContext, { Messages, MessagesModule } from '../context';

type Props = {
  children: React.ReactNode;
};

const translations = {
  'de-DE': () => ({ load: import('../../../../../translations/de-DE.json') }),
  'en-GB': () => ({ load: import('../../../../../translations/en-GB.json') }),
  'es-ES': () => ({ load: import('../../../../../translations/es-ES.json') }),
  'fr-BE': () => ({ load: import('../../../../../translations/fr-BE.json') }),
  'FR-FR': () => ({ load: import('../../../../../translations/FR-FR.json') }),
  'it-IT': () => ({ load: import('../../../../../translations/it-IT.json') }),
  'nl-BE': () => ({ load: import('../../../../../translations/nl-BE.json') }),
  'pl-PL': () => ({ load: import('../../../../../translations/pl-PL.json') }),
  'sv-SE': () => ({ load: import('../../../../../translations/sv-SE.json') }),
};

const DynamicTranslateProvider = ({ children }: Props) => {
  // @ts-ignore
  const translation = translations[LOCALE];

  const [messages, setMessages] = useState<Messages | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (translation) {
      translation().load.then((json: MessagesModule) => {
        setMessages(json.default);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [translation]);

  return (
    <DynamicTranslateContext.Provider value={{ messages, loading }}>
      {children}
    </DynamicTranslateContext.Provider>
  );
};

export default DynamicTranslateProvider;
