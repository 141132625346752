import { get } from 'lodash/fp';

import {
  Query,
  QueryDownloadRepairMethodImportTaskOriginalFileArgs,
  Scalars,
} from 'graphqlTypes';
import QUERY from './gql/downloadRepairMethodImportTaskOriginalFile';
import usePromiseQuery from '../helpers/usePromiseQuery';

const usePromiseDownloadRepairMethodImportTaskOriginalFile = () => {
  const query = usePromiseQuery<
    Query['downloadRepairMethodImportTaskOriginalFile'],
    QueryDownloadRepairMethodImportTaskOriginalFileArgs
  >(QUERY, {
    fetchPolicy: 'network-only',
    context: { disableBatch: true },
  });

  const getData = async (id: Scalars['UUID']) => {
    const { data } = await query({
      id,
    });

    return get(['downloadRepairMethodImportTaskOriginalFile'], data);
  };

  return getData;
};

export default usePromiseDownloadRepairMethodImportTaskOriginalFile;
