import React from 'react';

import useDeveloperModeEnabled from 'modules/developerMode/useDeveloperModeEnabled';
import { FeatureFlag } from 'modules/feature';
import DeveloperModeContext from '../../context';
import useDeveloperModeFeatureFlags from '../../useDeveloperModeFeatureFlags';

type Props = {
  children: React.ReactNode;
};

const DeveloperModeProvider = ({ children }: Props) => {
  const { flagsStatus, switchFlag } = useDeveloperModeFeatureFlags();
  const developerModeEnabled = useDeveloperModeEnabled();
  const developerMode =
    !!developerModeEnabled && flagsStatus[FeatureFlag.DEVELOPER_MODE];

  return (
    <DeveloperModeContext.Provider
      value={{
        developerMode,
        flagsStatus,
        switchFlag,
      }}
    >
      {children}
    </DeveloperModeContext.Provider>
  );
};

export default DeveloperModeProvider;
