import { useLocation, useNavigate } from 'react-router-dom';

import { Pagination } from 'components/TableInfoPagination';

type Props = {
  totalPageCount: number;
};

const RepairMethodPagination = ({ totalPageCount }: Props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const page = Math.max(Number(searchParams.get('p')) - 1 || 0, 0);

  const onSetPage = (newPage: number) => {
    if (newPage) {
      searchParams.set('p', (newPage + 1).toString());
    } else {
      searchParams.delete('p');
    }

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Pagination
      page={page}
      totalPageCount={totalPageCount}
      setPage={onSetPage}
    />
  );
};

export default RepairMethodPagination;
