import gql from 'graphql-tag';

export default gql`
  query getInformTenants {
    getInformTenants {
      id
      name
    }
  }
`;
