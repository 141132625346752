import { useEffect, useState } from 'react';
import {
  filter,
  flow,
  get,
  identity,
  last,
  map,
  split,
  toLower,
  toPairs,
} from 'lodash/fp';
import { v4 as uuidv4 } from 'uuid';

import {
  AwsImageUploadCredential,
  AwsImageUploadCredentialsResponse,
  CompositeType,
  UploadImagesInfo,
} from 'graphqlTypes';
import useAdImage from 'modules/adImage/useAdImage';
import { useLazyBuildAdImageUploadCredentials } from 'modules/api/refurbishments';
import { sendReq } from 'modules/apiRest';
import { useLogger } from 'modules/logger';
import { struct } from '../fp';

type BucketName = AwsImageUploadCredential['bucketName'];
type AdId = string;
type Files = File[];
type UploadId = string;
type Extension = string;

interface GetImageUrl {
  bucketName: BucketName;
  adId: AdId;
  uploadId: UploadId;
  extension: Extension;
  compositeType: CompositeType;
}

interface GetHost {
  bucketName: BucketName;
}

export type ImageFile = {
  file: File;
  url: string;
  uploadId: string;
  extension: string;
  objectUrl: string;
};

type Data = string[] | undefined | Array<ImageFile>;

type UploadDataItem = {
  file: File;
  adId: AdId;
  uploadId: UploadId;
  type: CompositeType;
  extension: Extension;
};

type UploadData = Array<UploadDataItem> | null;

type AwsImageUploadCredentials = AwsImageUploadCredentialsResponse['awsImageUploadCredentials'];

interface UploadFiles {
  uploadData: UploadData;
  awsImageUploadCredentials: AwsImageUploadCredentials;
  compositeType: CompositeType;
}

interface CreateUploadData {
  adId: AdId;
  files: Files;
  compositeType: CompositeType;
}

const getExt = flow(get(['name']), split('.'), last);
const getHost = ({ bucketName }: GetHost) =>
  `https://${bucketName}.s3.amazonaws.com/`;

const getImageUrl = ({
  bucketName,
  adId,
  uploadId,
  extension,
  compositeType,
}: GetImageUrl): string =>
  `${getHost({
    bucketName,
  })}manual-upload/${adId}/${toLower(compositeType)}/${uploadId}.${extension}`;

export const uploadFiles = ({
  uploadData,
  awsImageUploadCredentials,
  compositeType,
}: UploadFiles) => {
  const promises: Promise<string>[] = flow(
    toPairs,
    map(
      ([i, { file, uploadId, adId, extension }]: [number, UploadDataItem]) => {
        const { bucketName, formData } = awsImageUploadCredentials[i];

        return (sendReq(
          'UPLOAD_FILE',
          getHost({ bucketName }),
          { ...formData, 'Content-Type': file.type },
          file,
        ) as Promise<any>).then(
          ({ headers }: { headers: { Location?: string } }) => {
            const url =
              headers.Location ||
              getImageUrl({
                bucketName,
                adId,
                uploadId,
                extension,
                compositeType,
              });
            const objectUrl = file ? URL.createObjectURL(file) : undefined;

            return {
              file,
              url,
              uploadId,
              extension,
              objectUrl,
            };
          },
        );
      },
    ),
  )(uploadData);

  return Promise.all(promises);
};

export const createUploadData = ({
  adId,
  files,
  compositeType,
}: CreateUploadData): UploadData => {
  return Array.from(files).map(file => ({
    file,
    adId,
    uploadId: uuidv4(),
    type: compositeType,
    extension: toLower(getExt(file)),
  }));
};

interface UseAdImageUpload {
  adId?: AdId;
  files?: Files;
  compositeType: CompositeType;
}

export default ({ adId, files = [], compositeType }: UseAdImageUpload) => {
  const { logError } = useLogger();
  const {
    getData: getCredentials,
    result: credentialsResult,
  } = useLazyBuildAdImageUploadCredentials();
  const { storeUploadedImage } = useAdImage();

  const [uploadData, setUploadData] = useState<UploadData>(null);
  const [data, setData] = useState<Data>();

  const awsImageUploadCredentials = get(
    ['data', 'buildAdImageUploadCredentials', 'awsImageUploadCredentials'],
    credentialsResult,
  );

  useEffect(() => {
    if (!adId || !files.length) {
      return;
    }

    const uploadDataObject = createUploadData({ adId, files, compositeType });
    const uploadImagesInfos: UploadImagesInfo[] = map(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      ({ adId, uploadId, type, extension }) => ({
        adId,
        uploadId,
        type,
        extension,
      }),
      uploadDataObject,
    );

    setUploadData(uploadDataObject);
    getCredentials({ uploadImagesInfos });
  }, [adId, files]);

  useEffect(() => {
    if (!awsImageUploadCredentials || !uploadData) {
      return;
    }

    uploadFiles({ uploadData, awsImageUploadCredentials, compositeType })
      .then(result => {
        if (result && Array.isArray(result)) {
          const items = flow(
            map(
              struct({
                url: get(['url']),
                objectUrl: get(['objectUrl']),
              }),
            ),
            filter(identity),
          )(result);

          items.forEach(item => {
            if (item.url && item.objectUrl) {
              storeUploadedImage(item.url, item.objectUrl);
            }
          });
        }
        setData(result);
      })
      .catch(error => {
        logError({ error });
        window.alert('Image upload error'); // eslint-disable-line no-alert
      })
      .finally(() => {
        setUploadData(null);
      });
  }, [awsImageUploadCredentials]);

  const cancel = () => {
    setUploadData(null);
  };

  return {
    cancel,
    data,
    isUploading: !!uploadData,
  };
};
