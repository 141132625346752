import {
  RefurbishmentDetails,
  ReviewState,
  WheelSetDeleteReasonTranslation,
} from 'graphqlTypes';
import {
  Budgets,
  DamageItem,
  DetailedBudget,
  PartnerWorkshopConnectionRefurbishment,
  ServiceItem,
  VehicleDetails,
  WheelItem,
} from 'routes/RefurbishmentRoute/types';
import DamageQualityCheckAuth from './DamageQualityCheckAuth';
import ServiceWorkQualityCheckApproval from './ServiceWorkQualityCheckApproval';
import { PricingBudgetInput } from '../EntryCheckAuthorizationRoute/types';
import { PartnersList } from '../helpers/mapData/getDetailedBudgets';
import { RefurbishmentSummary } from '../QualityCheckRoute/types';

export enum StepName {
  VehicleWheels = 'VEHICLE_WHEELS',
  RefurbishmentDefaults = 'REFURBISHMENT_DEFAULTS',
  ServiceBook = 'SERVICE_BOOK',
  Damages = 'DAMAGES',
  VehicleServices = 'VEHICLE_SERVICES',
  SummaryCustomer = 'SUMMARY_CUSTOMER',
  Summary = 'SUMMARY',
}

export type WheelSetsInfo = {
  switched: {
    original: boolean;
    current: boolean;
    selected: boolean;
  };
  deleted: {
    original: boolean;
    current: boolean;
    selected: boolean;
    reason: WheelSetDeleteReasonTranslation;
  };
};

export interface ReworkAuthMap {
  id: string;
  state: ReviewState;
  phaseId: string;
  refurbishmentSummary: RefurbishmentSummary;
  vehicleDetails: VehicleDetails;
  wheels: Array<WheelItem<DamageQualityCheckAuth>>;
  wheelSetsInfo: WheelSetsInfo;
  /**
   * @deprecated use `damagesMapped` instead
   */
  damages: Array<DamageItem>;
  damagesMapped: Array<DamageQualityCheckAuth>;
  services: Array<ServiceItem>;
  serviceWork?: ServiceWorkQualityCheckApproval;
  budgets: Omit<Budgets, 'newDamagesBudget' | 'newServicesBudget'>;
  detailedBudget: DetailedBudget;
  disabled: boolean;
  refurbishment: RefurbishmentDetails;
  hasServiceBook: boolean;
  partnerWorkshopConnectionRefurbishments?: PartnerWorkshopConnectionRefurbishment[];
  pricingBudgetCosts: PricingBudgetInput[] | null;
  hasWriteRole: boolean;
  hasSplitCost: boolean;
  partnersList: PartnersList[];
  isImperfectionRepairMethods: boolean;
}
