import React from 'react';

import CollapsableSection from 'components/UI/CollapsableSection';
import Text from 'components/UI/Text';
import { RepairPartner } from 'graphqlTypes';

type Props = {
  partner: RepairPartner;
  count: number;
  children: React.ReactNode;
};

export const PartnerGroup = ({ partner, count, children }: Props) => {
  return (
    <CollapsableSection
      header={
        <Text size="xl" color="primary" weight="bold" className="pr-5">
          {partner.name} ({count})
        </Text>
      }
      name={partner.name}
      hideName={__('refurbishmentDetails.hideAllServices')}
      showName={__('refurbishmentDetails.showAllServices')}
      open
      dataQaSelector="partner-group-container"
    >
      {children}
    </CollapsableSection>
  );
};
