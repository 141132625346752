import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import cns from 'classnames';

import FieldError from 'components/Form/FieldError';
import { FormFilterNames } from './constants';
import { vinStockRefRegExp } from './helpers';

import cn from './Filter.module.scss';

const RefVinStockInput = () => {
  const formMethods = useFormContext();
  if (!formMethods) {
    throw new Error(
      "'RefVinStockInput' must be used inside a 'Filter' component.",
    );
  }
  const {
    register,
    formState: { errors },
  } = formMethods;

  return (
    <div className={cns(cn.col, cn.input, cn.searchInputContainer)}>
      <SearchIcon className={cn.searchIcon} />
      <Form.Control
        {...register(FormFilterNames.REFURBISHMENT_STOCK_VIN, {
          pattern: {
            value: vinStockRefRegExp,
            message: __('refurbishment.overview.wrongVinStockRefNumber'),
          },
        })}
        placeholder={__('refurbishment.overview.vinStockRefNumber')}
        className={cn.searchInput}
        data-qa-selector="stock_number_filter"
      />
      {!!errors && (
        <FieldError
          name={FormFilterNames.REFURBISHMENT_STOCK_VIN}
          errors={errors}
        />
      )}
    </div>
  );
};

export default RefVinStockInput;
