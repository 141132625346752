import { CancelToken } from 'axios';
import { flow, forEach, toPairs } from 'lodash/fp';

export default options => {
  const {
    pb,
    url,
    body,
    query,
    method,
    timeout,
    headers,
    paramsSerializer,
    responseType,
  } = options;

  let { attach, fields } = options;

  const cancelSource = CancelToken.source();

  const axiosOptions = {
    url,
    method,
    headers,
    timeout,
    paramsSerializer,
    responseType,
    onUploadProgress: pb.trickle,
    onDownloadProgress: pb.trickle,
    cancelToken: cancelSource.token,
    cancel: cancelSource.cancel,
  };

  if (body) {
    axiosOptions.data = body;
  }

  if (query) {
    axiosOptions.params = query;
  }

  if (attach || fields) {
    const fd = new global.FormData();

    attach = attach || {};
    fields = fields || {};

    const data = { ...attach, ...fields };

    flow(
      toPairs,
      forEach(([key, value]) => {
        fd.append(key, value);
      }),
    )(data);

    axiosOptions.data = fd;
  }

  return axiosOptions;
};
