import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Role, RoleAccess } from 'modules/acl';

const DetailRoute = lazy(() => import('./DetailRoute'));
const EntryCheckAuthorizationRoute = lazy(() =>
  import('./EntryCheckAuthorizationRoute'),
);
const EntryCheckPostAuthorizationRoute = lazy(() =>
  import('./EntryCheckPostAuthorizationRoute'),
);
const EntryCheckRoute = lazy(() => import('./EntryCheckRoute'));
const ListRoute = lazy(() => import('./ListRoute'));
const QualityCheckRedirectRoute = lazy(() =>
  import('./QualityCheckRedirectRoute'),
);
const QualityCheckRoute = lazy(() => import('./QualityCheckRoute'));
const ReworkAuthorizationRoute = lazy(() =>
  import('./ReworkAuthorizationRoute'),
);

const RefurbishmentRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RoleAccess roles={Role.READ}>
            <ListRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/entry-check"
        element={
          <RoleAccess roles={Role.ENTRY_CHECK_READ}>
            <EntryCheckRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/entry-check-authorization"
        element={
          <RoleAccess roles={Role.AUTHORIZE_ENTRY_CHECK_READ}>
            <EntryCheckAuthorizationRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/entry-check-post-authorization"
        element={
          <RoleAccess roles={Role.POST_AUTHORIZATION_READ}>
            <EntryCheckPostAuthorizationRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/quality-check/:reviewId"
        element={
          <RoleAccess roles={Role.QUALITY_CHECK_READ}>
            <QualityCheckRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/quality-check"
        element={
          <RoleAccess roles={Role.QUALITY_CHECK_READ}>
            <QualityCheckRedirectRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id/rework-authorization/:reviewId"
        element={
          <RoleAccess roles={Role.QUALITY_CHECK_APPROVAL_READ}>
            <ReworkAuthorizationRoute />
          </RoleAccess>
        }
      />
      <Route
        path=":id"
        element={
          <RoleAccess roles={Role.READ}>
            <DetailRoute />
          </RoleAccess>
        }
      />
    </Routes>
  );
};

export default RefurbishmentRoute;
