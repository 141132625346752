import { useEffect, useState } from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { filter, flow, get, identity, map, noop } from 'lodash/fp';

import ImageUpload from 'components/ImageUpload';
import { CompositeType } from 'graphqlTypes';
import useAdImageUpload from 'helpers/adImageUpload/useAdImageUpload';

interface AdImageUploadProps {
  onChange?: Function;
  onLoading?: (isLoading: boolean) => void;
  adId: string;
  value?: object[];
  compositeType: CompositeType;
  onPhotoUploaded?: Function;
}

const AdImageUpload = ({
  onChange = noop,
  onLoading,
  adId,
  value,
  compositeType,
  onPhotoUploaded = noop,
}: AdImageUploadProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const { isUploading, data = value } = useAdImageUpload({
    adId,
    files,
    compositeType,
  });

  useEffect(() => {
    if (data && !isUploading) {
      onChange(data);
      if (value !== data) {
        onPhotoUploaded(data);
      }
    }

    if (onLoading) {
      onLoading(isUploading);
    }
  }, [data, isUploading]);

  return (
    <ImageUpload
      value={value}
      onChange={(d: Array<object>) => {
        const f = flow(map(get(['file'])), filter(identity))(d);

        if (f.length) {
          setFiles(f);
        } else {
          onChange();
        }
      }}
    />
  );
};

type AdImageUploadControlProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> & {
  adId: string;
  compositeType: CompositeType;
  onLoading?: (isLoading: boolean) => void;
  onPhotoUploaded?: Function;
};

const AdImageUploadControl = <T extends FieldValues>({
  adId,
  control,
  name,
  rules,
  compositeType,
  onLoading,
  onPhotoUploaded = noop,
}: AdImageUploadControlProps<T>) => {
  return (
    <Controller
      control={control}
      rules={{
        ...rules,
      }}
      name={name}
      render={({ field: { onChange, value } }) => (
        <AdImageUpload
          adId={adId}
          compositeType={compositeType}
          onLoading={onLoading}
          onPhotoUploaded={onPhotoUploaded}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

export default AdImageUploadControl;
