import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';
import cns from 'classnames';

import LoadingIndicator from 'components/LoadingIndicator';
import SortableTableHeader from 'components/SortableTableHeader';
import {
  QueryVehicleServiceCostSearchArgs,
  RefurbishmentServiceType,
  ServiceData,
  SortDirection,
  VehicleServiceCostFilterV2,
  VehicleServiceCostSortInput,
} from 'graphqlTypes';
import { Role, useHasRole } from 'modules/acl';
import { useVehicleServiceCostSearch } from 'modules/api/costTable';
import { useGetServiceTypes } from 'modules/api/services';
import CostTableNewRow from './CostTableNewRow';
import CostTablePagination from './CostTablePagination';
import CostTableRow from './CostTableRow';
import { CostTableRowMode } from './types';

import cn from './CostTableList.module.scss';

type Props = {
  addNewItem: boolean;
  clearAddNewItem(): void;
  allServiceTypes: ServiceData[];
};

const CostTableList = ({
  addNewItem,
  clearAddNewItem,
  allServiceTypes,
}: Props) => {
  const { data: serviceTypes } = useGetServiceTypes();
  const [selectedRow, setSelectedRow] = useState<{
    id: string;
    mode: CostTableRowMode;
  } | null>(null);
  const currentSelectedRow = addNewItem ? null : selectedRow;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const page = Math.max(Number(searchParams.get('p')) - 1 || 0, 0);
  const mainWorkshop = searchParams.get('w') || '';
  const vehicleServiceName = searchParams.get('t') || '';
  const displaySubServices = searchParams.get('s') || '';
  const sortField = searchParams.get('sortField') || '';
  const sortDir = searchParams.get('sortDir') || '';
  const hasCostTableWriteRole = useHasRole(Role.COST_TABLE_WRITE) || false;
  const filter: VehicleServiceCostFilterV2 = {};
  if (mainWorkshop) {
    filter.mainWorkshop = parseInt(mainWorkshop, 10);
  }
  if (vehicleServiceName) {
    filter.vehicleServiceNames = [vehicleServiceName];
  }
  if (displaySubServices && serviceTypes) {
    const subServiceTypes = serviceTypes
      .filter(
        service =>
          service.type === RefurbishmentServiceType.ServiceBookSubService,
      )
      .map(service => service.name.key);
    filter.vehicleServiceNames = [vehicleServiceName, ...subServiceTypes];
  }

  let sort: VehicleServiceCostSortInput | undefined;
  if (sortField === 'mainWorkshop') {
    sort = {
      mainWorkshop: sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc,
    };
  } else if (sortField === 'refurbishmentPartnerName') {
    sort = {
      refurbishmentPartnerName:
        sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc,
    };
  } else if (sortField === 'serviceName') {
    sort = {
      serviceName: sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc,
    };
  }

  const vehicleServiceCostSearchArgs: QueryVehicleServiceCostSearchArgs = {
    page,
    pageSize: 50,
    filter,
    sort,
  };

  const { data, loading, refetch } = useVehicleServiceCostSearch(
    vehicleServiceCostSearchArgs,
  );

  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  const { items } = data;

  const handleRowEdit = (id: string) => {
    setSelectedRow({
      id,
      mode: CostTableRowMode.EDIT,
    });
  };

  const handleRowCancel = () => {
    setSelectedRow(null);
    clearAddNewItem();
  };

  const handleRowSaved = () => {
    setSelectedRow(null);
    clearAddNewItem();
    refetch();
  };

  const calculateMode = (id: string): CostTableRowMode => {
    if (currentSelectedRow === null) {
      return CostTableRowMode.DISPLAY;
    }
    if (currentSelectedRow.id === id) {
      return currentSelectedRow.mode;
    }
    return CostTableRowMode.DISABLED;
  };

  return (
    <>
      <Table
        striped
        responsive
        className={cns(
          currentSelectedRow?.mode === CostTableRowMode.EDIT ? cn.editMode : '',
          addNewItem ? cn.newMode : '',
        )}
      >
        <thead>
          <tr>
            <th className={cn.mainWorkshop}>
              <SortableTableHeader
                header={__('costTable.mainWorkshop')}
                sortField="mainWorkshop"
              />
            </th>
            <th className={cn.partner}>
              <SortableTableHeader
                header={__('costTable.partner')}
                sortField="refurbishmentPartnerName"
              />
            </th>
            <th className={cn.repairWorkshop}>
              {__('costTable.repairWorkshop')}
            </th>
            <th className={cn.serviceType}>
              <SortableTableHeader
                header={__('costTable.serviceType')}
                sortField="serviceName"
              />
            </th>
            <th className={cn.laborTotal}>{__('costTable.labourTotal')}</th>
            <th className={cn.materialTotal}>
              {__('costTable.materialTotal')}
            </th>
            <th className={cn.totalPrice}>{__('costTable.totalPrice')}</th>
            <th>{__('costTable.isAutoCreate')}</th>
            <th>{__('costTable.isDefault')}</th>
            <th>{__('costTable.active')}</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {addNewItem && (
            <CostTableNewRow
              key="new-row"
              onCancelClick={handleRowCancel}
              onRowSaved={handleRowSaved}
            />
          )}
          {items.map(item => {
            return (
              <CostTableRow
                key={item.id}
                item={item}
                allServiceTypes={allServiceTypes}
                editable={hasCostTableWriteRole}
                mode={calculateMode(item.id)}
                onEditClick={handleRowEdit}
                onCancelClick={handleRowCancel}
                onRowSaved={handleRowSaved}
              />
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <CostTablePagination totalPageCount={data.pageInfo.totalPageCount} />
      </div>
    </>
  );
};

export default CostTableList;
