import { useMutation } from '@apollo/react-hooks';

import { Mutation, MutationStartRepairMethodImportArgs } from 'graphqlTypes';
import START_REPAIR_METHOD_IMPORT from './gql/startRepairMethodImport';

const useStartRepairMethodImport = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'startRepairMethodImport'>,
    MutationStartRepairMethodImportArgs
  >(START_REPAIR_METHOD_IMPORT);

  const startRepairMethodImport = (id: string) =>
    mutation({ variables: { id } });

  return [
    startRepairMethodImport,
    {
      startRepairMethodImportData: data?.startRepairMethodImport,
      ...rest,
    },
  ] as const;
};

export default useStartRepairMethodImport;
