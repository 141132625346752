import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';

import cn from './MonitoringRow.module.scss';

type Props = {
  content?: string | null;
};

const Content = ({ content }: Props) => {
  const [showModal, setShowModal] = useState(false);

  if (!content) return null;

  const formattedContent = JSON.stringify(JSON.parse(content), null, 2);

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShowModal(true)}
        className={cn.content}
      >
        {__('action.seeMore')}
      </Button>
      <CustomModal
        show={showModal}
        size="xl"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{__('monitoring.content')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{formattedContent}</pre>
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default Content;
