import React from 'react';
import Button from 'react-bootstrap/Button';
import cns from 'classnames';

import cn from './StepHeader.module.scss';

interface Props {
  header: string | React.ReactNode;
  subHeader?: string;
  sideArea?: string | React.ReactNode;
  tutorialButtonUrl?: string;
}

const StepHeader = ({
  header,
  subHeader,
  sideArea,
  tutorialButtonUrl,
}: Props) => {
  const secondRow = !!(subHeader || (sideArea && tutorialButtonUrl));

  return (
    <div className={cns(cn.header, { [cn.rowGap]: secondRow })}>
      <h4>{header}</h4>
      {!!tutorialButtonUrl && (
        <div className={cn.buttonSideArea}>
          <Button
            variant="primary"
            href={tutorialButtonUrl}
            data-qa-selector="refurbStandardHelp"
            as="a"
            target="_blank"
          >
            {__('entryCheck.standard.help')}
          </Button>
        </div>
      )}
      {!!subHeader && <div className={cn.subHeader}>{subHeader}</div>}
      {!!sideArea && (
        <div className={tutorialButtonUrl ? cn.sideArea : cn.buttonSideArea}>
          {sideArea}
        </div>
      )}
    </div>
  );
};

export default StepHeader;
