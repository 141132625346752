import React from 'react';

import PageContainer from 'components/Layout/PageContainer';
import { ErrorType } from 'modules/errorHandler';

const errorTranslations = {
  [ErrorType.AccessDenied]: __('error.accessDenied'),
  [ErrorType.NotFound]: __('error.notFound'),
  [ErrorType.UnexpectedError]: __('error.unexpectedError'),
};

interface Props {
  type: ErrorType;
}

const ErrorPage = ({ type }: Props) => {
  return (
    <PageContainer>
      <h1 className="text-center">{errorTranslations[type]}</h1>
    </PageContainer>
  );
};

export default ErrorPage;
