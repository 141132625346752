import { useState } from 'react';

import { ImportHistoryTable } from 'components/ImportHistoryTable';
import LoadingIndicator from 'components/LoadingIndicator';
import { Pagination } from 'components/TableInfoPagination';
import { saveToDiskCsvFile } from 'helpers/saveToDiskCsvFile';
import {
  usePromiseDownloadRepairCostImportTaskOriginalFile,
  useSearchRepairCostImportTasks,
} from 'modules/api/costTable';

const ImportHistory = () => {
  const [page, setPage] = useState(0);

  const searchArgs = {
    page,
    pageSize: 15,
  };

  const { data, loading } = useSearchRepairCostImportTasks(searchArgs);
  const downloadRepairCostImportTaskOriginalFile = usePromiseDownloadRepairCostImportTaskOriginalFile();

  const onSetPage = (newPage: number) => {
    setPage(newPage);
  };

  const onDownloadFile = async (id: string) => {
    const csv = await downloadRepairCostImportTaskOriginalFile(id);
    saveToDiskCsvFile(csv, `${id}.csv`);
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <ImportHistoryTable data={data} onDownloadFile={onDownloadFile} />

      <div className="d-flex justify-content-end">
        <Pagination
          page={page}
          totalPageCount={data.pageInfo.totalPageCount}
          setPage={onSetPage}
        />
      </div>
    </>
  );
};

export default ImportHistory;
