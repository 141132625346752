import gql from 'graphql-tag';

export default gql`
  mutation updateRefurbishment($id: UUID!, $input: RefurbishmentUpdateInput!) {
    updateRefurbishment(id: $id, input: $input) {
      refurbishmentDetails {
        beautyShotsTakenOn
      }
    }
  }
`;
