import gql from 'graphql-tag';

export default gql`
  mutation updateRefurbishmentTaskCurrentPartnerWorkshopConnection(
    $taskId: UUID!
    $currentPartnerWorkshopConnectionId: UUID!
  ) {
    updateRefurbishmentTaskCurrentPartnerWorkshopConnection(
      taskId: $taskId
      currentPartnerWorkshopConnectionId: $currentPartnerWorkshopConnectionId
    ) {
      taskId
      currentPartnerWorkshopConnection {
        id
        repairWorkshop {
          id
          name
        }
        repairPartner {
          id
          name
        }
      }
    }
  }
`;
