import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FieldError from 'components/Form/FieldError';
import { CompositeType } from 'graphqlTypes';
import { DamageImageFormData } from 'routes/RefurbishmentRoute/types';
import AdImageUploadControl from '../AdImageUploadControl';

import cn from './DamageForm.module.scss';

interface DamageImageFormProps {
  adId: string;
  damage?: DamageImageFormData;
  onSubmit(data: DamageImageFormData): Promise<any>;
  onChange?(): void;
  onCancel(): void;
  disabled?: boolean;
}

const useDamageForm = ({
  damage,
  onSubmit,
}: Pick<DamageImageFormProps, 'damage' | 'onSubmit'>) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<DamageImageFormData>({ defaultValues: damage });

  const handleOnSubmit = async (values: DamageImageFormData) => {
    await onSubmit({
      ...values,
    });
  };

  return {
    handleSubmit,
    handleOnSubmit,
    control,
    errors,
    isSubmitting,
  };
};

const DamageImageForm = ({
  adId,
  damage,
  onSubmit,
  onChange,
  onCancel,
  disabled,
}: DamageImageFormProps) => {
  const [isUploading, setUploading] = useState(false);

  const {
    handleSubmit,
    handleOnSubmit,
    control,
    errors,
    isSubmitting,
  } = useDamageForm({ damage, onSubmit });

  return (
    <Form onSubmit={handleSubmit(handleOnSubmit)} onChange={onChange}>
      <Form.Group>
        <AdImageUploadControl
          control={control}
          adId={adId}
          name="imageUrl"
          rules={{
            required: __('field.required'),
          }}
          compositeType={CompositeType.Damages}
          onLoading={isLoading => setUploading(isLoading)}
        />
        <FieldError name="imageUrl" errors={errors} />
      </Form.Group>
      <div className={cn.actions}>
        <Button
          variant="link"
          onClick={onCancel}
          data-qa-selector="damage-form-cancel"
        >
          {__('action.cancel')}
        </Button>
        <Button
          variant="secondary"
          type="submit"
          className="btn-wide ml-4"
          data-qa-selector="damage-form-submit"
          disabled={isSubmitting || isUploading || disabled}
        >
          {__('action.edit')}
        </Button>
      </div>
    </Form>
  );
};

export default DamageImageForm;
