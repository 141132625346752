import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  BrakeTypePayload,
  EntryCheckItemValueSourceType,
  RefurbishmentDetails,
} from 'graphqlTypes';
import CollapsableCheckSection from 'routes/RefurbishmentRoute/components/CollapsableCheckSection';
import { AddDamageModal } from 'routes/RefurbishmentRoute/components/DamageForm';
import { ServiceModal } from 'routes/RefurbishmentRoute/components/ServiceForm';
import { AddOptions } from 'routes/RefurbishmentRoute/components/WheelCheckpoints/mapData';
import {
  EntryCheckItem,
  PayloadType,
  SliceKey,
  WheelPosition,
} from 'routes/RefurbishmentRoute/types';
import {
  BrakeCaliperInGoodCondition,
  BrakeDiskInGoodCondition,
  BrakeFluidCapExists,
  BrakeHosesInGoodCondition,
  BrakePadInGoodCondition,
  BrakeTypeSelect,
} from './Fields';
import { getBrakeCheckpoints } from './mapData';

type Props = {
  refurbishment: RefurbishmentDetails;
  entryCheckId: string;
  position: WheelPosition;
  items?: EntryCheckItem[];
  disabled: boolean;
  isImperfectionRepairMethods: boolean;
  hasSplitCost?: boolean;
  hasSparePartsManagement: boolean;
};

export enum BrakeType {
  DRUM = 'drum',
  DISK = 'disk',
  CERAMIC = 'ceramic',
}

const BrakeCheckpoints = ({
  refurbishment,
  entryCheckId,
  position,
  items,
  disabled,
  isImperfectionRepairMethods,
  hasSplitCost,
  hasSparePartsManagement,
}: Props) => {
  const [addOptions, setAddOptions] = useState<AddOptions | false>(false);
  const brakeItems = getBrakeCheckpoints(position, items);

  if (!brakeItems || !brakeItems.length) return null;

  const brakeType = (brakeItems.find(
    item => item.payloadType === PayloadType.BrakeType,
  )?.values?.[0]?.proposedValue as BrakeTypePayload)?.brakeType;

  return (
    <>
      <CollapsableCheckSection name={__('entryCheck.brakeCheckpoints.title')}>
        <BrakeTypeSelect items={brakeItems} disabled={disabled} />
        {(brakeType === BrakeType.DISK || brakeType === BrakeType.CERAMIC) && (
          <>
            <Row>
              <Col>
                <BrakePadInGoodCondition
                  items={items}
                  disabled={disabled}
                  position={position}
                  setAddOption={setAddOptions}
                />
              </Col>
              <Col>
                <BrakeDiskInGoodCondition
                  items={items}
                  disabled={disabled}
                  brakeType={brakeType}
                  position={position}
                  setAddOption={setAddOptions}
                />
              </Col>
            </Row>
            <BrakeCaliperInGoodCondition
              items={items}
              disabled={disabled}
              setAddOption={setAddOptions}
            />
          </>
        )}
        <BrakeFluidCapExists
          items={items}
          disabled={disabled}
          position={position}
          setAddOption={setAddOptions}
        />
        <BrakeHosesInGoodCondition
          items={items}
          disabled={disabled}
          position={position}
          setAddOption={setAddOptions}
        />
      </CollapsableCheckSection>
      {!disabled && (
        <>
          <AddDamageModal
            id={entryCheckId}
            refurbishment={refurbishment}
            sourceType={EntryCheckItemValueSourceType.EntryCheck}
            isSecondaryWheel={false}
            damageGroup={SliceKey.Underbody}
            damage={(addOptions && addOptions.addDamage) || undefined}
            show={!!(addOptions && addOptions.addDamage)}
            setShow={setAddOptions}
            isImperfectionRepairMethods={isImperfectionRepairMethods}
            hasSparePartsManagement={hasSparePartsManagement}
            hasSplitCost={hasSplitCost}
            hideCost={hasSplitCost}
          />
          <ServiceModal
            id={entryCheckId}
            refurbishment={refurbishment}
            sourceType={EntryCheckItemValueSourceType.EntryCheck}
            setShow={setAddOptions}
            show={!!(addOptions && addOptions.addService)}
            selectedService={(addOptions && addOptions.addService) || undefined}
            hasSparePartsManagement={hasSparePartsManagement}
            hasSplitCost={hasSplitCost}
            hideCost={hasSplitCost}
          />
        </>
      )}
    </>
  );
};

export default BrakeCheckpoints;
