import gql from 'graphql-tag';

export default gql`
  query userSearch($page: Int!, $pageSize: Int!, $filter: UserSearchInput) {
    userSearch(page: $page, pageSize: $pageSize, filter: $filter) {
      items {
        id
        email
        firstName
        lastName
        active
        groups {
          id
          name
        }
        refurbishmentProvidersData {
          partner {
            id
            name
          }
          location {
            id
            name
          }
        }
        userPartnerWorkshopConnections {
          mainWorkshop {
            id
            name
            branchId
          }
          isAllLocations
          partnerWorkshopConnections {
            id
            repairWorkshop {
              id
              name
              branchId
            }
            repairPartner {
              id
              name
              refurbishmentPartnerId
            }
          }
        }
      }
      pageInfo {
        page
        pageSize
        totalEntityCount
        totalPageCount
      }
    }
  }
`;
