import IntlMessageFormat from 'intl-messageformat';
import { curry } from 'lodash/fp';

import { LOCALE } from '../config';

export const stringFormatter = curry((message, values) => {
  try {
    return new IntlMessageFormat(message, LOCALE).format(values);
  } catch (e) {
    return message;
  }
});
