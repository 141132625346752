/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { noop } from 'lodash/fp';

import FileDropzone, { PreparedFile } from 'components/FileDropzone';
import useAdImage from 'modules/adImage/useAdImage';
import CameraSvg from './img/CameraSvg';

import cn from './ImageUpload.module.scss';

type ValueItem = {
  url?: string | null;
  base64Url?: string | null;
  objectUrl?: string;
};

interface Props {
  onChange?: (e: PreparedFile[]) => void;
  value?: ValueItem[];
  qaSelector?: string;
}

const removeItemByIndex = (index: number, arr: Array<any>): Array<any> => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];

const ImageUpload = ({ onChange = noop, value = [], qaSelector }: Props) => {
  const { getImageUrl } = useAdImage();
  const bodyElement = (
    <FileDropzone accept="image/*" onFiles={onChange} className={cn.root}>
      <div className={cn.body} data-qa-selector={qaSelector}>
        <CameraSvg className={cn.icon} />
        <span className={cn.text}>{__('action.takeUploadPhoto')}</span>
      </div>
    </FileDropzone>
  );

  const images = value.map((image: ValueItem) => ({
    ...image,
    url: image.url ? getImageUrl(image.url) : null,
  }));

  return images.length ? (
    <Row>
      <Col sm={4}>
        {images.map(({ objectUrl, base64Url, url }, index) => (
          <div
            onClick={() => onChange(removeItemByIndex(index, value))}
            style={{ backgroundImage: `url(${objectUrl || base64Url || url})` }}
            className={cn.img}
            key={index}
            data-qa-selector="uploaded-image"
          />
        ))}
      </Col>
      <Col sm={8}>{bodyElement}</Col>
    </Row>
  ) : (
    bodyElement
  );
};

export default ImageUpload;
