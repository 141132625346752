import React, { useState } from 'react';
import { Badge, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { OverviewData } from '../../hooks/useGetUserOverview';

import cn from './OverviewTable.module.scss';

interface Props {
  data: OverviewData[];
}

const renderLocations = (providers: string[]) =>
  providers.map(provider => <div key={provider}>{provider}</div>);

const CollapsibleLocations = ({ providers }: { providers: string[] }) => {
  const [seeAll, setSeeAll] = useState(false);

  if (providers.length > 1) {
    return (
      <>
        <div className={[cn.providers, seeAll ? cn.hide : cn.show].join(' ')}>
          <div>
            {providers.length} {__('user.overview.providers').toLowerCase()}
          </div>
          <Button
            className={cn.providersButton}
            variant="link"
            onClick={() => setSeeAll(true)}
            data-qa-selector="show_providers"
          >
            {__('user.overview.seeAll')}
          </Button>
        </div>
        <div className={[cn.providers, !seeAll ? cn.hide : cn.show].join(' ')}>
          {renderLocations(providers)}
          <Button
            className={cn.providersButton}
            variant="link"
            onClick={() => setSeeAll(false)}
            data-qa-selector="hide_providers"
          >
            {__('user.overview.hide')}
          </Button>
        </div>
      </>
    );
  }

  return <>{renderLocations(providers)}</>;
};

const OverviewTable = ({ data }: Props) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>{__('user.overview.name')}</th>
          <th>{__('user.overview.email')}</th>
          <th>{__('user.overview.groups')}</th>
          <th>{__('user.main.workshop')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({
            id,
            email,
            firstName,
            lastName,
            groups,
            productionLocations,
            active,
          }) => (
            <tr
              key={id}
              className={active ? '' : cn.inactive}
              data-qa-selector={`row_${id}`}
            >
              <td className={cn.primary}>
                <div>
                  <Link
                    to={id}
                    data-qa-selector="user_name"
                    className={cn.name}
                  >
                    {firstName} {lastName}
                  </Link>
                </div>
                <div>
                  {!active && (
                    <Badge variant="secondary" data-qa-selector="inactive">
                      {__('user.overview.inactive')}
                    </Badge>
                  )}
                </div>
              </td>
              <td className={cn.email} data-qa-selector="user_email">
                {email}
              </td>
              <td data-qa-selector="user_groups">
                {groups.map(group => (
                  <div key={group}>{group}</div>
                ))}
              </td>
              <td data-qa-selector="user_production_locations">
                <CollapsibleLocations providers={productionLocations} />
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
};

export default OverviewTable;
