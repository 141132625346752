import { FormFilterNames } from 'components/Filter/constants';
import { useRefurbishmentTasks } from 'modules/api/refurbishmentTasks';

export const useTaskListSearchParams = () => {
  const [
    getRefurbishmentTasks,
    { data, loading, refetch },
  ] = useRefurbishmentTasks();

  const setFilterValues = (values: Record<string, any>) => {
    const refurbishmentNumber = values?.[FormFilterNames.REFURBISHMENT_NUMBER];
    const stockNumber = values?.[FormFilterNames.STOCK_NUMBER];
    const vinNumber = values?.[FormFilterNames.VIN_NUMBER];
    const refurbishmentStates = values?.[FormFilterNames.STATE];
    const page = Math.max(Number(values?.p) - 1 || 0, 0);

    getRefurbishmentTasks({
      page,
      pageSize: 20,
      filter: {
        ...(refurbishmentNumber ? { refurbishmentNumber } : {}),
        ...(stockNumber ? { stockNumber } : {}),
        ...(vinNumber ? { vinNumber } : {}),
        ...(refurbishmentStates ? { refurbishmentStates } : {}),
      },
    });
  };

  return {
    data,
    loading,
    refetch,
    setFilterValues,
  };
};

export default useTaskListSearchParams;
