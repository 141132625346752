import React from 'react';
import { Col, Row } from 'react-bootstrap';
import cns from 'classnames';

import {
  Maybe,
  PartnerWorkshopConnectionRefurbishmentResponse,
  RefurbishmentStateTranslation,
  RefurbishmentTypeTranslation,
} from 'graphqlTypes';
import BeautyShot from './SubComponents/BeautyShot';
import BeautyShotsTakenOn from './SubComponents/BeautyShotsTakenOn';
import GrossProfit from './SubComponents/GrossProfit';
import RefurbishmentBadges from './SubComponents/RefurbishmentBadges';
import { SelectedWorkshop } from './SubComponents/SelectedWorkshop';
import VehicleInfo from './SubComponents/VehicleInfo';

import cn from './VehicleHeader.module.scss';

type Props = {
  isCompleted?: boolean;
  readyForExitCheck?: boolean;
  subState?: string;
  refId: string;
  refurbishmentType?: RefurbishmentTypeTranslation;
  vin: string;
  stockNumber: string;
  partnerWorkshopConnections?: Maybe<
    Array<PartnerWorkshopConnectionRefurbishmentResponse>
  >;
  state: RefurbishmentStateTranslation;
  lastStateUpdatedOn?: Maybe<Date>;
  make: string;
  model: string;
  subModel: string;
  isAutoOrdered?: boolean;
  className?: string;
  leftColumnArea?: React.ReactNode;
  rightColumnArea?: React.ReactNode;
};

const VehicleHeader = ({
  isCompleted,
  readyForExitCheck,
  subState,
  refId,
  refurbishmentType,
  vin,
  stockNumber,
  partnerWorkshopConnections,
  state,
  lastStateUpdatedOn,
  make,
  model,
  subModel,
  isAutoOrdered,
  className,
  leftColumnArea,
  rightColumnArea,
}: Props) => {
  return (
    <div className={cns(cn.vehicleHeader, className)}>
      <div className={cn.innerContainer}>
        <Row>
          <Col>
            <VehicleInfo
              stockNumber={stockNumber}
              refId={refId}
              vin={vin}
              make={make}
              model={model}
              subModel={subModel}
            />
            {leftColumnArea}
          </Col>

          <Col md="auto">
            <RefurbishmentBadges
              isCompleted={isCompleted}
              readyForExitCheck={readyForExitCheck}
              subState={subState}
              refurbishmentType={refurbishmentType}
              isAutoOrdered={isAutoOrdered}
              state={state}
              lastStateUpdatedOn={lastStateUpdatedOn}
            />
            {rightColumnArea}
          </Col>
        </Row>

        <SelectedWorkshop
          partnerWorkshopConnections={partnerWorkshopConnections}
          className={refurbishmentType || readyForExitCheck ? 'mt-1' : 'mt-2'}
        />
      </div>
    </div>
  );
};

VehicleHeader.BeautyShotsTakenOn = BeautyShotsTakenOn;
VehicleHeader.BeautyShot = BeautyShot;
VehicleHeader.GrossProfit = GrossProfit;

export default VehicleHeader;
