import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import LoadingIndicator from 'components/LoadingIndicator';
import CustomModal from 'components/UI/CustomModal';
import { ImportTaskState } from 'graphqlTypes';
import useGetRepairCostImportTask from 'modules/api/costTable/useGetRepairCostImportTask';
import ImportRepairCostImportResult from './ImportRepairCostImportResult';
import ImportRepairCostProceed from './ImportRepairCostProceed';
import ImportRepairCostReUpload from './ImportRepairCostReUpload';
import ImportRepairCostUpload from './ImportRepairCostUpload';

export const enum ImportRepairCostPhase {
  'UPLOADING' = 'uploading',
  'VALIDATION_LOADING' = 'validationLoading',
  'RE_UPLOADING' = 'reUploading',
  'PROCEEDING_WITH_IMPORT' = 'proceedingWithImport',
  'IMPORT_LOADING' = 'importLoading',
  'DISPLAYING_IMPORT_RESULT' = 'displayingImportResult',
}

type Props = {
  isModalVisible: boolean;
  closeModal: () => void;
};

const ImportRepairCostModal = ({ isModalVisible, closeModal }: Props) => {
  const [activePhase, setActivePhase] = useState<ImportRepairCostPhase>(
    ImportRepairCostPhase.UPLOADING,
  );

  const [
    getRepairCostImportTask,
    { repairCostImportTaskData, stopPolling },
  ] = useGetRepairCostImportTask();

  useEffect(() => {
    const currentImportState = repairCostImportTaskData?.state;

    if (activePhase === ImportRepairCostPhase.VALIDATION_LOADING) {
      if (currentImportState === ImportTaskState.ValidationFinished) {
        stopPolling();
        setActivePhase(ImportRepairCostPhase.PROCEEDING_WITH_IMPORT);
      } else if (currentImportState === ImportTaskState.ValidationFailed) {
        stopPolling();
        setActivePhase(ImportRepairCostPhase.RE_UPLOADING);
      }
    }

    if (activePhase === ImportRepairCostPhase.IMPORT_LOADING) {
      if (
        currentImportState === ImportTaskState.ImportFinished ||
        currentImportState === ImportTaskState.ImportFailed
      ) {
        stopPolling();
        setActivePhase(ImportRepairCostPhase.DISPLAYING_IMPORT_RESULT);
      }
    }
  }, [repairCostImportTaskData]);

  const renderContent = () => {
    switch (activePhase) {
      case ImportRepairCostPhase.VALIDATION_LOADING:
      case ImportRepairCostPhase.IMPORT_LOADING:
        return (
          <>
            <h3>{__('repairCost.importInProgress')}</h3>
            <LoadingIndicator />
          </>
        );
      case ImportRepairCostPhase.RE_UPLOADING:
        return (
          <ImportRepairCostReUpload
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            repairCostImportTaskData={repairCostImportTaskData}
          />
        );
      case ImportRepairCostPhase.PROCEEDING_WITH_IMPORT:
        return (
          <ImportRepairCostProceed
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            repairCostImportTaskData={repairCostImportTaskData}
            getRepairCostImportTask={getRepairCostImportTask}
          />
        );
      case ImportRepairCostPhase.DISPLAYING_IMPORT_RESULT:
        return (
          <ImportRepairCostImportResult
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            currentImportState={repairCostImportTaskData?.state}
          />
        );
      default:
        return (
          <ImportRepairCostUpload
            closeModal={closeModal}
            setActivePhase={setActivePhase}
            getRepairCostImportTask={getRepairCostImportTask}
            repairCostImportTaskData={repairCostImportTaskData}
            stopPolling={stopPolling}
          />
        );
    }
  };

  return (
    <CustomModal
      show={isModalVisible}
      onHide={() => {
        if (stopPolling) {
          stopPolling();
        }
        setActivePhase(ImportRepairCostPhase.UPLOADING);
        closeModal();
      }}
      qaSelector="repairCostImportRepairCostModal"
    >
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>{__('repairCost.importRepairCostModalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
    </CustomModal>
  );
};

export default ImportRepairCostModal;
