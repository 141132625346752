import gql from 'graphql-tag';

export default gql`
  mutation updateInformMessage($id: UUID!, $input: InformMessageUpdateInput!) {
    updateInformMessage(id: $id, input: $input) {
      id
      reviewed
      reviewedBy {
        id
        firstName
        lastName
      }
      comment
    }
  }
`;
