import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { Query, QueryExitCheckArgs } from 'graphqlTypes';
import EXIT_CHECK_DETAILS from './gql/exitCheck';

type UseExitCheckReturn = {
  data: Pick<Query, 'exitCheck'> | undefined;
  loading: boolean;
};

function useExitCheck(
  forceFetchFromServer: boolean = false,
): UseExitCheckReturn {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    throw Error('id is missing');
  }
  const { data, loading } = useQuery<
    Pick<Query, 'exitCheck'>,
    QueryExitCheckArgs
  >(EXIT_CHECK_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: forceFetchFromServer ? 'network-only' : 'cache-first',
  });

  return { data, loading };
}

export { useExitCheck };
