import React from 'react';
import cns from 'classnames';

import DataItem from 'components/UI/DataItem';
import { DataItemProps } from 'components/UI/DataItem/DataItem';
import { has } from 'routes/RefurbishmentRoute/helpers/has';

import cn from './CostDetails.module.scss';

export type SplitCost = {
  materialCost?: string | null;
  consumableCost?: string | null;
  sparePartCost?: string | null;
  laborCost?: string | null;
  laborRate?: string | null;
  laborTime?: string | null;
  totalCost?: string | null;
};

type Labels = {
  laborCost?: string;
  laborTime?: string;
  laborRate?: string;
};

export type CostDetailsProps = {
  splitCost: SplitCost;
  labels?: Labels;
  children?: React.ReactNode;
  className?: string;
};

type ItemProps = Partial<Omit<DataItemProps, 'children'>> & {
  children: React.ReactNode;
};

const MaterialCostItem = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.materialCost')}
    className={cns(cn.materialCost, 'materialCost')}
    qaSelector="materialCost"
    {...rest}
  >
    {children}
  </DataItem>
);

const ConsumableCostItem = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.consumableCost')}
    className={cns(cn.consumableCost, 'consumableCost')}
    qaSelector="consumableCost"
    {...rest}
  >
    {children}
  </DataItem>
);

const SparePartCost = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.sparePartCost')}
    className={cns(cn.sparePartCost, 'sparePartCost')}
    qaSelector="sparePartCost"
    {...rest}
  >
    {children}
  </DataItem>
);

const LaborCost = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.laborCost')}
    className={cns(cn.laborCost, 'laborCost')}
    qaSelector="laborCost"
    {...rest}
  >
    {children}
  </DataItem>
);

const LaborRate = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.laborRate')}
    className={cns(cn.laborRate, 'laborRate')}
    qaSelector="laborRate"
    {...rest}
  >
    {children}
  </DataItem>
);

const LaborTime = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.laborTime')}
    className={cns(cn.laborTime, 'laborTime')}
    qaSelector="laborTime"
    {...rest}
  >
    {children}
  </DataItem>
);

const TotalCost = ({ children, ...rest }: ItemProps) => (
  <DataItem
    label={__('refurbishment.totalCost')}
    className={cns(cn.totalCost, 'totalCost')}
    qaSelector="totalCost"
    {...rest}
  >
    {children}
  </DataItem>
);

const CostDetails = ({
  splitCost,
  labels,
  children,
  className,
}: CostDetailsProps) => {
  const {
    materialCost,
    consumableCost,
    sparePartCost,
    laborCost,
    laborRate,
    laborTime,
    totalCost,
  } = splitCost;

  return (
    <div className={cns(cn.costDetails, className)}>
      {has(materialCost) && <MaterialCostItem>{materialCost}</MaterialCostItem>}
      {has(consumableCost) && (
        <ConsumableCostItem>{consumableCost}</ConsumableCostItem>
      )}
      {has(sparePartCost) && <SparePartCost>{sparePartCost}</SparePartCost>}
      {has(laborCost) && (
        <LaborCost {...(labels?.laborCost ? { label: labels.laborCost } : {})}>
          {laborCost}
        </LaborCost>
      )}
      {has(laborRate) && (
        <LaborRate {...(labels?.laborRate ? { label: labels.laborRate } : {})}>
          {laborRate}
        </LaborRate>
      )}
      {has(laborTime) && (
        <LaborTime {...(labels?.laborTime ? { label: labels.laborTime } : {})}>
          {laborTime}
        </LaborTime>
      )}
      {has(totalCost) && <TotalCost>{totalCost}</TotalCost>}
      {children}
    </div>
  );
};

CostDetails.MaterialCostItem = MaterialCostItem;
CostDetails.ConsumableCostItem = ConsumableCostItem;
CostDetails.SparePartCost = SparePartCost;
CostDetails.LaborCost = LaborCost;
CostDetails.LaborRate = LaborRate;
CostDetails.LaborTime = LaborTime;
CostDetails.TotalCost = TotalCost;

export default CostDetails;
