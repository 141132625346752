import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import CustomModal from 'components/UI/CustomModal';
import {
  EntryCheckItemValueSourceType,
  RefurbishmentDetails,
  RefurbishmentServiceName,
  RefurbishmentServiceType,
} from 'graphqlTypes';
import { useLogger } from 'modules/logger';
import { serviceFormToInput } from './mapData';
import ServiceForm, { ServiceFormData } from './ServiceForm';
import useCreateService from '../../hooks/useCreateService';

interface Props {
  id: string;
  refurbishment: RefurbishmentDetails;
  sourceType: EntryCheckItemValueSourceType;
  setShow: Function;
  show: boolean;
  service?: Partial<ServiceFormData>;
  selectedService?: RefurbishmentServiceName;
  description?: string;
  hideMaintenanceService?: boolean;
  hideCost?: boolean;
  validateSubmit?(data: ServiceFormData): string | null;
  onClose?: () => void;
  hasSplitCost?: boolean;
  hasSparePartsManagement: boolean;
}

const ServiceModal = ({
  id,
  refurbishment,
  sourceType,
  setShow,
  show,
  service,
  selectedService,
  description,
  hideMaintenanceService = false,
  hideCost,
  validateSubmit,
  onClose,
  hasSplitCost,
  hasSparePartsManagement,
}: Props) => {
  const { logError } = useLogger();
  const [createService] = useCreateService({
    entryCheckId: id,
    refurbishmentId: refurbishment.id,
    sourceType,
    hasSparePartsManagement,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const currentService = () => {
    if (service) return service;
    if (selectedService) {
      return {
        budget: 0,
        service: {
          name: selectedService,
          type: RefurbishmentServiceType.Vehicle,
        },
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = async (serviceValues: ServiceFormData) => {
    if (validateSubmit) {
      const msg = validateSubmit(serviceValues);
      setErrorMessage(msg);

      if (msg) {
        return;
      }
    }

    const serviceInput = serviceFormToInput(serviceValues, hasSplitCost);
    try {
      await createService(serviceInput);
      setShow(false);
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <CustomModal size="ml" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{__('entryCheck.addService.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!description && <div className="mb-4">{description}</div>}
        <ServiceForm
          onSubmit={onSubmit}
          onCancel={handleClose}
          isNew
          disabled={!show}
          defaultValues={currentService()}
          errorMessage={errorMessage}
          hideMaintenanceService={hideMaintenanceService}
          refurbishment={refurbishment}
          hideCost={hideCost}
          hasSplitCost={hasSplitCost}
        />
      </Modal.Body>
    </CustomModal>
  );
};

export default ServiceModal;
