import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { get } from 'lodash/fp';

import {
  EntryCheckItemCreateInput,
  Mutation,
  MutationCreateEntryCheckItemsArgs,
  Query,
  QueryEntryCheckArgs,
} from 'graphqlTypes';
import CREATE_ENTRY_CHECK_ITEMS from './gql/createEntryCheckItems';
import ENTRY_CHECK from './gql/entryCheck';

export type CreateEntryCheckItemsPromise = Promise<
  ExecutionResult<Pick<Mutation, 'createEntryCheckItems'>>
>;

const useCreateEntryCheckItems = () => {
  const [mutation, { data, ...rest }] = useMutation<
    Pick<Mutation, 'createEntryCheckItems'>,
    MutationCreateEntryCheckItemsArgs
  >(CREATE_ENTRY_CHECK_ITEMS);

  const createEntryCheckItem = (
    inputs: EntryCheckItemCreateInput[],
    refurbishmentId: string,
  ) =>
    mutation({
      variables: { inputs },
      update(cache, { data: updatedData }) {
        const entryCheck = cache.readQuery<
          Pick<Query, 'entryCheck'>,
          QueryEntryCheckArgs
        >({
          query: ENTRY_CHECK,
          variables: {
            refurbishmentId,
          },
        });

        if (entryCheck && updatedData) {
          cache.writeQuery<Pick<Query, 'entryCheck'>, QueryEntryCheckArgs>({
            query: ENTRY_CHECK,
            data: {
              entryCheck: {
                ...entryCheck.entryCheck!,
                items: [
                  ...entryCheck.entryCheck!.items,
                  ...updatedData.createEntryCheckItems,
                ],
              },
            },
            variables: {
              refurbishmentId,
            },
          });
        }
      },
    });

  return [
    createEntryCheckItem,
    {
      data: get('createEntryCheckItems', data),
      ...rest,
    },
  ] as const;
};

export default useCreateEntryCheckItems;
