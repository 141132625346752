import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';

import { Query, QueryQualityCheckReviewArgs } from 'graphqlTypes';
import QUALITY_CHECK_REVIEW_V2 from './gql/qualityCheckReviewV2';

const useQualityCheckReview = (
  id: string = '',
  options?: QueryHookOptions<
    Pick<Query, 'qualityCheckReview'>,
    QueryQualityCheckReviewArgs
  >,
) => {
  const { data, ...rest } = useQuery<
    Pick<Query, 'qualityCheckReview'>,
    QueryQualityCheckReviewArgs
  >(QUALITY_CHECK_REVIEW_V2, {
    variables: { id },
    fetchPolicy: 'network-only',
    ...options,
    skip: options?.skip || !id,
  });

  return {
    data: get('qualityCheckReview', data),
    ...rest,
  };
};

export default useQualityCheckReview;
