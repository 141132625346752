import React, { useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { LOCALE, LOCALES } from '../config';

type Props = {
  children: React.ReactNode;
};

const Language = ({ children }: Props) => {
  const { lang } = useParams<{ lang: string }>();
  const lastLang = useRef<string | null>(null);

  if (!lang || !LOCALES.includes(lang)) {
    return <Navigate to={`/${LOCALE}/not-found`} />;
  }

  if (lastLang.current && lang !== lastLang.current) {
    window.location.assign(location.pathname);
  }

  if (!lastLang.current) {
    lastLang.current = lang;
  }

  return <>{children}</>;
};

const RouteLanguage = ({ children }: Props) => {
  return (
    <Routes>
      <Route path="/:lang/*" element={<Language>{children}</Language>} />
      <Route path="/" element={<Navigate to={`/${LOCALE}/`} />} />
    </Routes>
  );
};

export default RouteLanguage;
