import { flow, get, map } from 'lodash/fp';

import { User } from 'graphqlTypes';
import { UserCreateInfo, UserInfoForm, UserUpdateInfo } from './types';

export const userToDetail = (user: User) => ({
  ...user,
  groupIds: flow(
    get<User, keyof User>('groups'),
    map(group => group.id),
  )(user),
});

export const detailUserToForm = (user: User): UserInfoForm => {
  const { email, active, country, locale } = user;

  return {
    email,
    active,
    firstName: get('firstName', user) || '',
    lastName: get('lastName', user) || '',
    country: country || '',
    locale: locale || '',
  };
};

export const templateUserToForm = (user: User): UserInfoForm => {
  const { country, locale } = user;

  return {
    email: '',
    firstName: '',
    lastName: '',
    country: country || '',
    locale: locale || '',
    active: false,
  };
};

export const formToUserCreateInfo = (
  formData: UserInfoForm,
): UserCreateInfo => ({
  ...formData,
  email: formData.email.toLowerCase(),
});

export const formToUserUpdateInfo = (
  formData: UserInfoForm,
): UserUpdateInfo => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email, country, locale, ...userInfo } = formData;

  return {
    ...userInfo,
    country,
    locale,
  };
};
