import React from 'react';
import { components, SingleValueProps } from 'react-select';
import SearchIcon from '@mui/icons-material/Search';

import cn from './Filter.module.scss';

const createSingleValue = () => ({ children, ...rest }: SingleValueProps) => {
  return (
    <components.SingleValue {...rest}>
      <SearchIcon className={cn.searchIcon} />
      {children}
    </components.SingleValue>
  );
};

export default createSingleValue;
