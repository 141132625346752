import React from 'react';

interface Props {
  className?: string;
}

const LeftArrow = ({ className }: Props) => (
  <svg
    className={className}
    id="icon-left-arrow"
    viewBox="0 0 7 12"
    width={7}
    height={12}
  >
    <path
      d="M5.698 0L7 1.371 2.605 6 7 10.629 5.698 12 1.302 7.371 0 6z"
      fillRule="evenodd"
    />
  </svg>
);

export default LeftArrow;
