import React from 'react';

import Text from 'components/UI/Text';
import { Workshop } from 'graphqlTypes';

type Props = {
  workshop: Workshop;
  children: React.ReactNode;
};

export const WorkshopGroup = ({ workshop, children }: Props) => {
  return (
    <div data-qa-selector="workshop-group-container">
      <Text weight="bold" tag="div" className="mb-4">
        {workshop.name}
      </Text>
      {children}
    </div>
  );
};
