import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import LoadingIndicator from 'components/LoadingIndicator';
import SortableTableHeader from 'components/SortableTableHeader';
import {
  PartnerSearchFilterInput,
  PartnerSearchSortInput,
  SortDirection,
} from 'graphqlTypes';
import usePartnerSearch from 'modules/api/partners/usePartnerSearch';
import PartnerSearchPagination from './PartnerSearchPagination';
import PartnerSearchRow from './PartnerSearchRow';
import { FormFilterNames } from '../../FormFilterNames';

export const usePartnerSearchParams = () => {
  const { search } = useLocation();
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true });
  const page = Math.max(Number(searchParams?.p) - 1 || 0, 0);

  const sort: PartnerSearchSortInput = {
    name: SortDirection.Desc,
  };

  const country = searchParams[FormFilterNames.COUNTRY] || '';
  const name = searchParams[FormFilterNames.PARTNER_NAME] || '';
  const sortDir = searchParams.sortDir || '';
  const active = searchParams[FormFilterNames.ACTIVE_ONLY] || '';

  const filter: PartnerSearchFilterInput = {};

  if (country) {
    filter.country = country;
  }
  if (name) {
    filter.name = name;
  }
  if (active) {
    filter.active = true;
  }

  sort.name = sortDir === 'ASC' ? SortDirection.Asc : SortDirection.Desc;

  return {
    page,
    pageSize: 50,
    sort,
    filter,
  };
};

const PartnersTable = () => {
  const searchArgs = usePartnerSearchParams();

  const { data, loading } = usePartnerSearch(searchArgs);

  if (loading) return <LoadingIndicator />;

  if (!data) return null;

  const {
    partnerSearch: { items, pageInfo },
  } = data;

  return (
    <>
      <Table striped responsive>
        <thead>
          <tr>
            <th>id</th>
            <th>
              <SortableTableHeader
                header={__('partners.name')}
                sortField="name"
              />
            </th>
            <th>{__('partners.contactName')}</th>
            <th>{__('partners.phone')}</th>
            <th>{__('partners.type')}</th>
            <th>{__('partners.partnerEmail')}</th>
            <th>{__('partners.email1')}</th>
            <th>{__('partners.email2')}</th>
            <th>{__('partners.address')}</th>
            <th>{__('partners.country')}</th>
            <th>{__('partners.active')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            return <PartnerSearchRow key={item.id} item={item} />;
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <PartnerSearchPagination totalPageCount={pageInfo.totalPageCount} />
      </div>
    </>
  );
};

export default PartnersTable;
