import { useMemo } from 'react';
import { forEach, get } from 'lodash/fp';
import { Option } from 'types';

import { CarPart } from 'graphqlTypes';
import { useGetCarParts } from 'modules/api/damages';
import { getKey, getTranslation } from 'modules/i18n';
import {
  DamageArea,
  DamageSubArea,
  SliceKey,
  SliceKeyDamage,
  WheelPosition,
} from '../types';

export const getCarPartOptions = (carParts?: CarPart[] | null) => {
  const partGroups: Record<SliceKeyDamage | WheelPosition, Option<any>[]> = {
    [SliceKey.Front]: [],
    [SliceKey.Right]: [],
    [SliceKey.Back]: [],
    [SliceKey.Left]: [],
    [SliceKey.Roof]: [],
    [SliceKey.Interior]: [],
    [SliceKey.Underbody]: [],
    [SliceKey.Engine]: [],
    [SliceKey.Wheels]: [],
    [WheelPosition.fwr]: [],
    [WheelPosition.bwr]: [],
    [WheelPosition.fwl]: [],
    [WheelPosition.bwl]: [],
  };

  forEach(part => {
    const section = getKey(get(['section'], part));
    const subSection = getKey(get(['subSection'], part));
    const name = getKey(get(['name'], part));
    const id = get(['id'], part);
    const partOption = {
      value: {
        id,
        name,
        section,
        subSection,
      },
      label: getTranslation(get(['name'], part)),
    };
    switch (section) {
      case DamageArea.Body:
      case DamageArea.Tires: {
        if (!subSection) {
          break;
        }
        switch (subSection) {
          case DamageSubArea.BodyFront:
            partGroups[SliceKey.Front].push(partOption);
            break;
          case DamageSubArea.BodyRight:
            partGroups[SliceKey.Right].push(partOption);
            break;
          case DamageSubArea.BodyBack:
            partGroups[SliceKey.Back].push(partOption);
            break;
          case DamageSubArea.BodyLeft:
            partGroups[SliceKey.Left].push(partOption);
            break;
          case DamageSubArea.BodyRoof:
            partGroups[SliceKey.Roof].push(partOption);
            break;
          case DamageSubArea.FrontRight: {
            partGroups[SliceKey.Wheels].push(partOption);
            partGroups[WheelPosition.fwr].push(partOption);
            break;
          }
          case DamageSubArea.BackRight: {
            partGroups[SliceKey.Wheels].push(partOption);
            partGroups[WheelPosition.bwr].push(partOption);
            break;
          }
          case DamageSubArea.FrontLeft: {
            partGroups[SliceKey.Wheels].push(partOption);
            partGroups[WheelPosition.fwl].push(partOption);
            break;
          }
          case DamageSubArea.BackLeft: {
            partGroups[SliceKey.Wheels].push(partOption);
            partGroups[WheelPosition.bwl].push(partOption);
            break;
          }
          default:
        }
        break;
      }
      case DamageArea.Interior:
        partGroups[SliceKey.Interior].push(partOption);
        break;
      case DamageArea.Underbody:
      case DamageArea.Engine:
        partGroups[SliceKey.Underbody].push(partOption);
        break;
      default:
    }
  }, carParts);

  return partGroups;
};

const useCarParts = () => {
  const { data, ...rest } = useGetCarParts();
  const carParts = useMemo(() => getCarPartOptions(data), [data]);

  return {
    carParts,
    ...rest,
  };
};

export default useCarParts;
