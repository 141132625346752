import { Control, FieldValues, Path } from 'react-hook-form';

import SelectInputV2 from 'components/Form/SelectInput';
import { useActiveGroupedPartnerWorkshopConnections } from 'modules/api/partnerWorkshop';

type Props<T extends FieldValues> = {
  name: Path<T>;
  form: string;
  control: Control<T, object>;
  qaSelector: string;
  mainWorkshopName: string;
  refurbishmentPartnerId: number;
};

const LocationSelectControl = <T extends FieldValues>({
  name,
  form,
  control,
  qaSelector,
  mainWorkshopName,
  refurbishmentPartnerId,
}: Props<T>) => {
  const { data, loading } = useActiveGroupedPartnerWorkshopConnections({
    page: 0,
    pageSize: 999999,
    filter: {
      mainWorkshopName,
    },
  });

  const options =
    data?.items?.[0].groupedPartnerWorkshopConnections
      .flat()
      .filter(item => {
        return (
          item.repairPartner.refurbishmentPartnerId === refurbishmentPartnerId
        );
      })
      ?.map(item => ({
        label: item.repairWorkshop.name,
        value: {
          name: item.repairWorkshop.name,
          branchId: item.repairWorkshop.branchId,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label, __LOCALE__)) || [];

  return (
    <SelectInputV2
      name={name}
      form={form}
      options={options}
      isLoading={loading}
      control={control}
      rules={{
        required: __('field.required'),
      }}
      dataQa={`${qaSelector}-select`}
      dataQaOption={`${qaSelector}-option`}
    />
  );
};

export default LocationSelectControl;
