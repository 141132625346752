export enum Role {
  AUTHORIZE_ENTRY_CHECK_READ = 'AUTHORIZE_ENTRY_CHECK_READ',
  AUTHORIZE_ENTRY_CHECK_WRITE = 'AUTHORIZE_ENTRY_CHECK_WRITE',
  POST_AUTHORIZATION_READ = 'POST_AUTHORIZATION_READ',
  POST_AUTHORIZATION_WRITE = 'POST_AUTHORIZATION_WRITE',
  DEVELOPER = 'DEVELOPER',
  ENTRY_CHECK_COST_CALCULATION_WRITE = 'ENTRY_CHECK_COST_CALCULATION_WRITE',
  ENTRY_CHECK_READ = 'ENTRY_CHECK_READ',
  ENTRY_CHECK_WRITE = 'ENTRY_CHECK_WRITE',
  ENTRY_CHECK_CAT_INSPECTOR = 'ENTRY_CHECK_CAT_INSPECTOR',
  EXIT_CHECK_CANCEL = 'EXIT_CHECK_CANCEL',
  EXIT_CHECK_CONFIRM_REPAIRS = 'EXIT_CHECK_CONFIRM_REPAIRS',
  EXIT_CHECK_CREATE = 'EXIT_CHECK_CREATE',
  EXIT_CHECK_PERFORM_REPAIRS = 'EXIT_CHECK_PERFORM_REPAIRS',
  EXIT_CHECK_PREPARE = 'EXIT_CHECK_PREPARE',
  EXIT_CHECK_READ_ONLY = 'EXIT_CHECK_READ_ONLY',
  EXIT_CHECK_START = 'EXIT_CHECK_START',
  EXIT_CHECK_START_REPAIRS = 'EXIT_CHECK_START_REPAIRS',
  EXIT_CHECK_VIEW = 'EXIT_CHECK_VIEW',
  PARTNER_LOCATION_CONNECTIONS_READ = 'PARTNER_LOCATION_CONNECTIONS_READ',
  PARTNER_LOCATION_CONNECTIONS_WRITE = 'PARTNER_LOCATION_CONNECTIONS_WRITE',
  QUALITY_CHECK_APPROVAL_READ = 'QUALITY_CHECK_APPROVAL_READ',
  QUALITY_CHECK_APPROVAL_WRITE = 'QUALITY_CHECK_APPROVAL_WRITE',
  QUALITY_CHECK_READ = 'QUALITY_CHECK_READ',
  QUALITY_CHECK_WRITE = 'QUALITY_CHECK_WRITE',
  READ = 'READ',
  SET_CURRENT_PARTNER_LOCATION_CONNECTION = 'SET_CURRENT_PARTNER_LOCATION_CONNECTION',
  VEHICLE_INSPECTOR = 'VEHICLE_INSPECTOR',
  WRITE = 'WRITE',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  RETURN_TO_AUTO1 = 'RETURN_TO_AUTO1',
  RETURN_TO_AUTO1_ADMIN = 'RETURN_TO_AUTO1_ADMIN',
  TASK_READ = 'TASK_READ',
  TASK_COST_READ = 'TASK_COST_READ',
  TASK_WRITE = 'TASK_WRITE',
  COST_TABLE_READ = 'COST_TABLE_READ',
  COST_TABLE_WRITE = 'COST_TABLE_WRITE',
  SET_REFURBISHMENT_STATE_COMPLETED = 'SET_REFURBISHMENT_STATE_COMPLETED',
  SET_REFURBISHMENT_STATE_COMPLETED_WMS_FLOW = 'SET_REFURBISHMENT_STATE_COMPLETED_WMS_FLOW',
  REPAIR_COST_READ = 'REPAIR_COST_READ',
  REPAIR_COST_ADMIN = 'REPAIR_COST_ADMIN',
  REPAIR_METHOD_READ = 'REPAIR_METHOD_READ',
  REPAIR_METHOD_ADMIN = 'REPAIR_METHOD_ADMIN',
  PRODUCTION_READ = 'PRODUCTION_READ',
  PARTNER_READ = 'PARTNER_READ',
  PARTNER_WRITE = 'PARTNER_WRITE',
  USERS_ADMIN = 'USERS_ADMIN',
  GROUPS_ADMIN = 'GROUPS_ADMIN',
  CAT_SCAN_READ = 'CAT SCAN READ',
  INFORM_MONITORING = 'INFORM_MONITORING',
  ENTRY_CHECK_EXTERIOR = 'ENTRY_CHECK_EXTERIOR',
}
