import cns from 'classnames';

import cn from './PageHeader.module.scss';

interface Props {
  title: string;
  subTitle?: string;
  actions?: React.ReactNode;
  className?: string;
}

const PageHeader = ({
  title,
  subTitle,
  actions,
  className,
  ...rest
}: Props) => {
  return (
    <div className={cns([cn.pageHeader, className])} {...rest}>
      <div className="title">
        <h1>{title}</h1>
        {!!subTitle && <span>{subTitle}</span>}
      </div>
      {!!actions && <div className="actions">{actions}</div>}
    </div>
  );
};

export default PageHeader;
