import React, { useEffect, useState } from 'react';

import cn from './ImageViewer.module.scss';

interface Props {
  url: string;
  children: React.ReactNode;
  alt?: string;
  className?: string;
  qaSelector?: string;
}

const ImageViewer = ({
  url,
  children,
  alt = '',
  className,
  qaSelector,
}: Props) => {
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullscreen = () => setFullscreen(!fullscreen);

  useEffect(() => {
    if (fullscreen) {
      window.document.body.style.overflow = 'hidden';
    }
    return () => {
      window.document.body.style.overflow = 'unset';
    };
  }, [fullscreen]);

  return (
    <>
      <div
        onClick={() => toggleFullscreen()}
        onKeyPress={() => toggleFullscreen()}
        className={[cn.container, className].join(' ')}
        role="button"
        tabIndex={0}
        data-qa-selector={qaSelector}
      >
        {children}
      </div>
      {fullscreen ? (
        <div
          onClick={() => toggleFullscreen()}
          onKeyPress={() => toggleFullscreen()}
          className={cn.fullscreen}
          role="button"
          tabIndex={0}
          data-qa-selector={`${qaSelector}Zoom`}
        >
          <img src={url} alt={alt} className={cn.image} />
        </div>
      ) : null}
    </>
  );
};

export default ImageViewer;
