import gql from 'graphql-tag';

export default gql`
  query partners {
    partners {
      partners {
        id
        name
      }
    }
  }
`;
