import cns from 'classnames';

import Pagination from './Pagination';
import TableInfo from './TableInfo';
import { useSetPage } from '.';

import cn from './TableInfoPagination.module.scss';

interface Props {
  totalEntityCount?: number | null;
  totalPageCount?: number;
  page?: number;
  className?: string;
}

const TableInfoPagination = ({
  totalEntityCount,
  totalPageCount = 0,
  page = 0,
  className,
}: Props) => {
  const { setPage } = useSetPage();

  return (
    <div
      className={cns(cn.infoPaginationRow, className)}
      data-qa-selector="table-info-pagination"
    >
      <div className={cn.tableInfo}>
        {totalEntityCount !== undefined && totalEntityCount !== null && (
          <TableInfo results={totalEntityCount} />
        )}
      </div>
      <Pagination
        page={page}
        totalPageCount={totalPageCount}
        setPage={setPage}
      />
    </div>
  );
};

export default TableInfoPagination;
