import React from 'react';

import { useBrakePadConditions } from 'modules/api/wheelAttributes';
import { SelectOption } from 'routes/ExitCheckRoute/types';
import {
  AddOptions,
  brakeReplaceDamageServices,
} from 'routes/RefurbishmentRoute/components/WheelCheckpoints/mapData';
import { BrakeCheckpointsSelectGraphQlJsonPayload } from 'routes/RefurbishmentRoute/helpers/mapData/getCheckpoints';
import {
  EntryCheckItem,
  PayloadType,
  WheelPosition,
} from 'routes/RefurbishmentRoute/types';
import BrakeSelectItem from './Common/BrakeSelectItem';

const BRAKE_PAD_LESS_25_PERCENT =
  'brake_pad_condition_greater_than_or_equal_0_and_less_than_25_percent';
const BRAKE_PAD_25_PERCENT = 'brake_pad_condition_25_percent';

type Props = {
  items?: EntryCheckItem[];
  disabled: boolean;
  position: WheelPosition;
  setAddOption(otpion: AddOptions): void;
};

const BrakePadInGoodCondition = ({
  items,
  disabled,
  position,
  setAddOption,
}: Props) => {
  const entryCheckItem = items?.find(
    item => item.payloadType === PayloadType.BrakePadCondition,
  );
  const { data, loading } = useBrakePadConditions();

  if (!entryCheckItem || !data) return null;

  const value = (entryCheckItem?.values?.[0]
    ?.proposedValue as BrakeCheckpointsSelectGraphQlJsonPayload)
    ?.brakeCondition;

  return (
    <BrakeSelectItem
      label={__('entryCheck.brakeCheckpoints.brakePadCondition')}
      required
      name="brakePad"
      options={data.map<SelectOption>(
        item =>
          ({
            label: item.recordKey.translation,
            value: item.recordKey.key,
          } as SelectOption),
      )}
      value={value}
      entryCheckItem={entryCheckItem}
      loading={loading}
      disabled={disabled}
      updateEntryCheckItemValueField="condition"
      onChange={selectedValue => {
        if (
          selectedValue === BRAKE_PAD_LESS_25_PERCENT ||
          selectedValue === BRAKE_PAD_25_PERCENT
        ) {
          setAddOption({
            addService: brakeReplaceDamageServices[position],
          });
        }
      }}
    />
  );
};

export default BrakePadInGoodCondition;
