import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import { NotificationType } from './constants';

import cn from './Notifications.module.scss';

interface RemoveProps {
  id: number;
  onRemove(id: number): void;
}

const Remove = ({ id, onRemove }: RemoveProps) => {
  useEffect(() => () => onRemove(id), []);
  return null;
};

interface Props {
  list: Array<{
    id: number;
    text: string;
    type?: NotificationType;
    isOutdated?: boolean;
  }>;
  onClose(id: number): void;
  onRemove(id: number): void;
}

const Notifications = ({ list, onClose, onRemove }: Props) => {
  return (
    <div className={cn.notifications} data-qa-selector="notifications-block">
      {list.map(({ id, text, type, isOutdated = false }) => (
        <Alert
          variant={type || NotificationType.Info}
          dismissible
          show={!isOutdated}
          onClose={() => onClose(id)}
          data-qa-selector={`notification-{$id}`}
          key={id}
        >
          <>
            {text}
            <Remove id={id} onRemove={onRemove} />
          </>
        </Alert>
      ))}
    </div>
  );
};

export default Notifications;
