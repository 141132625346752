import { useLazyQuery } from '@apollo/react-hooks';
import { flow, get, last, split } from 'lodash/fp';

import BUILD_IMAGE_UPLOAD_CREDENTIALS from './gql/buildImageUploadCredentials';

const getExt = flow(get(['name']), split('.'), last);

const useLazyBuildImageUploadCredentials = () => {
  const [query, data] = useLazyQuery(BUILD_IMAGE_UPLOAD_CREDENTIALS, {
    fetchPolicy: 'network-only',
  });

  const buildImageUploadCredentials = (
    id: string,
    documentTypeId: string,
    files: File[],
  ) => {
    const uploadDocumentInfos = files.map(file => ({
      id,
      fileExt: getExt(file),
      documentTypeId,
    }));

    return query({
      variables: {
        awsUploadCredentialsInput: {
          uploadDocumentInfos,
        },
      },
    });
  };

  return [buildImageUploadCredentials, data] as const;
};

export default useLazyBuildImageUploadCredentials;
