import React from 'react';

import cn from './StepContainer.module.scss';

type Props = {
  children: React.ReactNode;
};

const StepContainer = ({ children }: Props) => {
  return <div className={cn.root}>{children}</div>;
};

export default StepContainer;
