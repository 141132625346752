import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Divider from 'components/Divider';
import PageContainer from 'components/Layout/PageContainer/PageContainer';
import PageHeader from 'components/Layout/PageHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  RefurbishmentProviderData,
  User,
  UserCreateInputV2,
  UserPartnerWorkshopConnection,
  UserPartnerWorkshopConnectionInput,
} from 'graphqlTypes';
import { useActiveGroupedPartnerWorkshopConnections } from 'modules/api/partnerWorkshop';
import { useCreateUserV2, useGetUser } from 'modules/api/users';
import { useLogger } from 'modules/logger';
import { getUrl } from 'modules/routeUtils';
import { ErrorType, useHandleError } from '../../../modules/errorHandler';
import Groups from '../components/Groups';
import Info from '../components/Info';
import ProductionLocations from '../components/ProductionLocations';
import {
  formToUserCreateInfo,
  templateUserToForm,
  userToDetail,
} from '../mapData';
import { UserInfoForm } from '../types';
import { getUserPartnerWorkshopConnections } from '../utils';

import cn from './DuplicateRoute.module.scss';

const DuplicateRoute = () => {
  const { logError } = useLogger();
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  const [
    userPartnerWorkshopConnections,
    setUserPartnerWorkshopConnections,
  ] = useState<UserPartnerWorkshopConnection[]>([]);

  const { data: templateUser, loading, error } = useGetUser(id);
  const navigate = useNavigate();
  const {
    data: groupedPartnerWorkshopConnections,
  } = useActiveGroupedPartnerWorkshopConnections({
    page: 0,
    pageSize: 999999,
  });
  const setError = useHandleError();
  const [createUserV2] = useCreateUserV2();
  const [providers, setProviders] = useState<RefurbishmentProviderData[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [userData, setUserData] = useState<User | null>(null);

  const onPartnerWorkshopConnectionChange = async (
    value: UserPartnerWorkshopConnectionInput,
  ) => {
    setUserPartnerWorkshopConnections(
      getUserPartnerWorkshopConnections(
        groupedPartnerWorkshopConnections,
        value,
      ),
    );
  };

  useEffect(() => {
    if (templateUser) {
      setUserData(userToDetail(templateUser));
      setProviders(templateUser.refurbishmentProvidersData || []);
    }
  }, [templateUser]);

  useEffect(() => {
    if (userData) {
      setGroups(userData.groups?.map(group => group.id) || []);
      setUserPartnerWorkshopConnections(
        userData.userPartnerWorkshopConnections?.filter(
          (group): group is UserPartnerWorkshopConnection => !!group,
        ) || [],
      );
    }
  }, [userData]);

  const handleSave = async (info: UserInfoForm) => {
    if (!info.email || !groups.length || !providers.length) {
      return;
    }

    const userCreateInput: UserCreateInputV2 = {
      ...formToUserCreateInfo(info),
      groups,
      userPartnerWorkshopConnections: {
        partnerWorkshopConnectionIds: Array.from(
          userPartnerWorkshopConnections.reduce((acc, cur) => {
            cur.partnerWorkshopConnections?.forEach(con => acc.add(con.id));
            return acc;
          }, new Set<string>()),
        ),
        userMainWorkshopIds: userPartnerWorkshopConnections
          .filter(con => con.isAllLocations)
          .map(con => con.mainWorkshop.id),
      },
    };
    try {
      const { data } = await createUserV2(userCreateInput);
      if (data) {
        navigate(getUrl('USER_DETAIL', { id: data?.createUserV2.id }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) return <LoadingIndicator />;

  if (error || !templateUser) {
    setError(ErrorType.UnexpectedError);
    logError({ error });
    return null;
  }

  const isMissingLocations = userPartnerWorkshopConnections.length === 0;

  return (
    <PageContainer>
      <PageHeader
        title={__('user.detail.duplicateTitle')}
        actions={
          <div className="ml-3 text-body">
            ( {__('user.detail.duplicatedFrom')}{' '}
            <span data-qa-selector="duplicated-from">
              {userData?.firstName} {userData?.lastName}
            </span>{' '}
            )
          </div>
        }
        className={cn.pageHeader}
      />
      <Info
        defaultValues={templateUserToForm(templateUser)}
        submitButton={() => <span />}
        onSubmit={handleSave}
      />

      <div className="my-4">
        <ProductionLocations
          partnerWorkshopConnections={userPartnerWorkshopConnections}
          onChange={onPartnerWorkshopConnectionChange}
        />
      </div>

      <Groups
        initialData={userData?.groups?.map(group => group.id)}
        onChange={setGroups}
      />

      <Container className={cn.footer}>
        <Divider className={cn.divider} />
        <div className={cn.content}>
          <Button
            as={Link}
            to={getUrl('USER_DETAIL', { id })}
            variant="link"
            data-qa-selector="form-cancel"
          >
            {__('action.cancel')}
          </Button>
          <Button
            type="submit"
            variant="secondary"
            data-qa-selector="form-submit"
            disabled={!groups.length || isMissingLocations}
            className={cn.saveButton}
            form="user-form"
          >
            {__('user.overview.newUser')}
          </Button>
        </div>
      </Container>
    </PageContainer>
  );
};

export default DuplicateRoute;
