import gql from 'graphql-tag';

export default gql`
  mutation submitEntryCheck($id: UUID!) {
    submitEntryCheck(id: $id) {
      id
      state
      refurbishment {
        id
        state
      }
    }
  }
`;
